import {all, call, put, takeEvery} from "redux-saga/effects"
import {getTransaction, getTransactions} from "services/transactions";
import * as types from 'app/actionTypes/transactions';

const getApi = async (filters = {}) => {
    return getTransactions(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getTransactionApi = async (id) => {
  return getTransaction(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchTransactionsDetails(action) {
  try {
    const data = yield call(() => getTransactionApi(action.payload));
    yield put({ type: types.GET_TRANSACTIONS_DETAILS_SUCCESS, itemsDetails: data });
  } catch (e) {
    yield put({ type: types.GET_TRANSACTIONS_DETAILS_FAILED, message: e?.response });
  }
}

function* fetchTransactions(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_TRANSACTIONS_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_TRANSACTIONS_FAILED, message: e?.response });
    }
}

function* transactionsSaga() {
  yield all([
    takeEvery(types.GET_TRANSACTIONS_REQUESTED, fetchTransactions),
    takeEvery(types.GET_TRANSACTIONS_DETAILS_REQUESTED, fetchTransactionsDetails),
  ]);
}

export default transactionsSaga;
