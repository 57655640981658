import * as types from 'app/actionTypes/storeAccounts';
import produce from "immer";

const initialState = {
  accounts: {
    data: null,
    meta: null
  },
  loading: false,
  error: null,
  accountDetails: null,
  isAccountDetailsLoading: false,
  accountID: null,
}

export default function storeAccounts(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_STORE_ACCOUNT_REQUESTED:
        draft.loading = true
        draft.accounts.data = null
        draft.accounts.meta = null
        break
      case types.GET_STORE_ACCOUNT_SUCCESS:
        draft.accounts = action.accounts
        draft.loading = false
        break
      case types.GET_STORE_ACCOUNT_FAILED:
        draft.error = action.message
        draft.loading = false
        break
      case types.GET_STORE_ACCOUNT_DETAILS_REQUEST:
        draft.isAccountDetailsLoading = true
        break
      case types.GET_STORE_ACCOUNT_DETAILS_SUCCESS:
        draft.accountDetails = action.items.data
        draft.isAccountDetailsLoading = false
        break
      case types.GET_STORE_ACCOUNT_DETAILS_FAILED:
        draft.error = action.message
        draft.isAccountDetailsLoading = false
        break
      case types.GET_SEARCHABLE_ACCOUNT_ID:
        draft.accountID = action.payload
        break
      case types.STORE_ACCOUNT_RESET:
        return initialState
      default:
        return state
    }
  })
}
