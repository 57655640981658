import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
  getDisputeOrderItems,
  getOrderItem,
  getOrderItems,
  getOrderItemsWithRelationship,
  getProcessableOrder
} from "services/order-items";
import * as types from 'app/actionTypes/orderItems';

const getApi = async (filter) => {
  return getOrderItems(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getDisputeApi = async (filter) => {
  return getDisputeOrderItems(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getWithRelationshipApi = async (filter) => {
  return getOrderItemsWithRelationship(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getProcessableOrderApi = async (filter) => {
  return getProcessableOrder(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getOrderItemDetailsAPI = async (id) => {
  return getOrderItem(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchOrderItems(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_ORDER_ITEMS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_ORDER_ITEMS_FAILED, message: e?.response });
  }
}

function* fetchDisputeOrderItems(action) {
  try {
    const data = yield call(() => getDisputeApi(action.payload));
    yield put({ type: types.GET_DISPUTE_ORDER_ITEMS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_DISPUTE_ORDER_ITEMS_FAILED, message: e?.response });
  }
}

function* fetchOrderItemsWithRelationship(action) {
  try {
    const data = yield call(() => getWithRelationshipApi(action.payload));
    yield put({ type: types.GET_ORDER_ITEMS_WITH_RELATIONSHIPS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_ORDER_ITEMS_WITH_RELATIONSHIPS_FAILED, message: e?.response });
  }
}

function* fetchProcessableOrder(action) {
  try {
    const data = yield call(() => getProcessableOrderApi(action.payload));
    yield put({ type: types.GET_PROCESSABLE_ORDER_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_PROCESSABLE_ORDER_FAILED, message: e?.response });
  }
}

function* fetchOrderItemDetails(action) {
  try {
    const data = yield call(() => getOrderItemDetailsAPI(action.payload));
    yield put({ type: types.GET_ORDER_ITEMS_DETAILS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_ORDER_ITEMS_DETAILS_FAILED, message: e?.response });
  }
}

function* orderItemsSaga() {
  yield all([
    takeEvery(types.GET_ORDER_ITEMS_REQUESTED, fetchOrderItems),
    takeEvery(types.GET_ORDER_ITEMS_WITH_RELATIONSHIPS_REQUESTED, fetchOrderItemsWithRelationship),
    takeEvery(types.GET_PROCESSABLE_ORDER_REQUESTED, fetchProcessableOrder),
    takeEvery(types.GET_ORDER_ITEMS_DETAILS_REQUESTED, fetchOrderItemDetails),
    takeEvery(types.GET_DISPUTE_ORDER_ITEMS_REQUESTED, fetchDisputeOrderItems)
  ]);
}

export default orderItemsSaga;
