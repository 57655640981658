import { call, put, takeEvery, all } from 'redux-saga/effects'
import {getAllShops, getShops} from "services/shops";
import * as type from 'app/actionTypes/shops';
import {getCurrencies} from "services/currencies";

const getApi = async (filter) => {
    return getShops(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getAllApi = async () => {
    return getAllShops()
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getCurrencyAPI = async (filter) => {
  return getCurrencies(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchShops(action) {
  try {
      const data = yield call(() => getApi(action.payload));
      yield put({ type: type.GET_SHOPS_SUCCESS, data: data });
  } catch (e) {
      yield put({ type: type.GET_SHOPS_FAILED, message: e?.response });
  }
}

function* fetchAllShops(action) {
    try {
        const data = yield call(() => getAllApi(action.payload));
        yield put({ type: type.GET_ALL_SHOPS_SUCCESS, data: data.data });
    } catch (e) {
        yield put({ type: type.GET_ALL_SHOPS_FAILED, message: e?.response });
    }
}

function* fetchCurrency(action) {
  try {
    const data = yield call(() => getCurrencyAPI(action.payload));
    yield put({ type: type.GET_CURRENCY_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: type.GET_CURRENCY_FAILED, message: e?.response });
  }
}

function* shopsSaga() {
    yield all([
        takeEvery(type.GET_ALL_SHOPS_REQUESTED, fetchAllShops),
        takeEvery(type.GET_SHOPS_REQUESTED, fetchShops),
        takeEvery(type.GET_CURRENCY_REQUESTED, fetchCurrency),
    ]);
}

export default shopsSaga;
