import { combineReducers } from 'redux';
import parcels from './parcels';
import order from './order';
import orders from './orders';
import customers from './customers';
import purchaseProducts from './purchaseProducts';
import liveCart from './liveCart';
import customerDetails from './customerDetails';
import cartItems from './cartItems';
import transactions from './transactions';
import orderItems from './orderItems';
import menus from './menus';
import gateways from './gateways';
import couriers from './couriers';
import configurations from './configurations';
import files from './files';
import requests from './requests';
import shops from './shops';
import shippingCategories from './shippingCategories';
import products from './products';
import campaigns from './campaigns';
import storeAccounts from './storeAccounts';
import itemRefunds from './itemRefunds';
import campaignProducts from './campaignProducts'
import desk from "./desk";
import contents from './contents';
import issues from './issues'
import rolesPermissions from './rolesPermissions';
import users from './users';
import importOrders from "./importOrders";
import purchasedOrders from "./purchasedOrders";
import resourceSearch from './resourceSearch';
import countries from './countries';
import currencies from './currencies';
import builder from './builder';
import profile from './profile';
import support from "./support";
import wallet from "./wallet";
import withdrawals from "./withdrawals";
import tags from './tags';
import shippingTags from './shippingTags';
import moveOnCategories from './moveOnCategories';
import productReceived from "./productReceived";
import productsDetails from "./productsDetails";

const rootReducer = combineReducers({
    parcels,
    order: order,
    orders,
    customers,
    purchaseProducts,
    liveCart,
    customerDetails,
    cartItems,
    transactions,
    orderItems,
    menus,
    gateways,
    couriers,
    configurations,
    files,
    requests,
    shops,
    shippingCategories,
    products,
    campaigns,
    storeAccounts,
    itemRefunds,
    campaignProducts,
    desk,
    contents,
    issues,
    rolesPermissions,
    users,
    importOrders,
    purchasedOrders,
    resourceSearch,
    currencies,
    countries,
    builder,
    profile,
    support,
    wallet,
    withdrawals,
    tags,
    shippingTags,
    moveOnCategories,
    productReceived,
    productsDetails,
});

export default rootReducer;
