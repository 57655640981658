import React from 'react'
import { Route, Switch, } from 'react-router-dom'
import DefaultLayout from './layouts/ProDefaultLayout'
import BuilderLayout from 'layouts/BuilderLayout';
import RouteWithBaseLayout from './layouts/RouteWithBaseLayout';
import Login from 'pages/Login';
import CustomerLayout from 'pages/customer_details/components/CustomerLayout';
import PageNotFound from "components/Generic/404/PageNotFound";
import Unauthorized from "components/Generic/403/Unauthorized";
import Homepage from "pages/Homepage";
import routePaths from 'configs/route-paths';
import EditCustomProduct from 'pages/products/CreateProduct/EditCustomProduct';

const Customers = React.lazy(() => import('pages/customers'));
const TransactionsPage = React.lazy(() => import('pages/Transactions'));
const TransactionsDetails = React.lazy(() => import('pages/Transactions/components/TransactionDetails'));
const CartItemList = React.lazy(() => import('pages/cart_items'));
const OrdersPage = React.lazy(() => import('pages/orders'));
const OrderDetails = React.lazy(() => import('pages/OrderDetails'));
const OrderEdit = React.lazy(() => import('pages/OrderEdit'));
const Parcels = React.lazy(() => import('pages/parcels'));
const PurchaseProductsPage = React.lazy(() => import('pages/purchase_products'));
const CustomerDetailsPage = React.lazy(() => import('pages/customer_details'));
const MenusPage = React.lazy(() => import('pages/menus'));
const Gateways = React.lazy(() => import('pages/gateways'));
const Couriers = React.lazy(() => import('pages/couriers'));
const Configurations = React.lazy(() => import('pages/configurations'));
const Files = React.lazy(() => import('pages/files'));
const Requests = React.lazy(() => import('pages/requests'));
const ShippingCategories = React.lazy(() => import('pages/shippingCategories'));
const Bulk = React.lazy(() => import('pages/bulk'));
const Products = React.lazy(() => import('pages/products'));
const Campaigns = React.lazy(() => import('pages/campaigns'));
const ShopAccount = React.lazy(() => import('pages/ShopAccount'));
const LiveCart = React.lazy(() => import('pages/LiveCart'));
const Refund = React.lazy(() => import('pages/refunds'));
const ParcelDetails = React.lazy(() => import('pages/parcels/components/ParcelDetails'));
const CampaignProducts = React.lazy(() => import('pages/campaigns/products'))
const Contents = React.lazy(() => import('pages/contents'));
const CreateContent = React.lazy(() => import('pages/contents/CreateContent'));
const UpdateContent = React.lazy(() => import('pages/contents/UpdateContent'));
const ParcelUpdater = React.lazy(() => import('pages/parcels/components/ParcelUpdater'));
const ParcelCODUpdater = React.lazy(() => import('pages/parcels/components/ParcelCODUpdater'));
const ParcelReceiver = React.lazy(() => import('pages/parcels/components/ParcelReceiver'));
const ParcelReports = React.lazy(() => import('pages/parcels/components/ParcelReports'));
const Support = React.lazy(() => import('pages/support'));
const CustomerOrderDetails = React.lazy(() => import('pages/support/CustomerOrderDetails'));
const SupportOrderDetails = React.lazy(() => import('pages/support/OrderDetails'));
const TransactionDetails = React.lazy(() => import('pages/support/TransactionDetails'))
const ParcelItems = React.lazy(() => import('pages/support/ParcelItems'))
const Shops = React.lazy(() => import('pages/Shops'));
const Currency = React.lazy(() => import('pages/Currency'));
const PrintOrderInvoice = React.lazy(() => import('pages/PrintOrderInvoice'));
const ParcelDeed = React.lazy(() => import('pages/parcels/components/ParcelDeed'));
const CustomerCartItems = React.lazy(() => import('pages/customer_details/CustomerCartItems'));
const CustomerTransactions = React.lazy(() => import('pages/customer_details/CustomerTransactions'));
const RequestedItem = React.lazy(() => import('pages/customer_details/RequestedItem'));
const RequestDetails = React.lazy(() => import('pages/requests/RequestDetails'));
const RolesPermissions = React.lazy(() => import('pages/RolesPermission'));
const Users = React.lazy(() => import('pages/users'));
const UserDetails = React.lazy(() => import('pages/users/UserDetails'));
const MakeTags = React.lazy(() => import('pages/shippingCategories/MakeTags'))
const TaggedCategories = React.lazy(() => import('pages/shippingCategories/TaggedCategories'))
const RefundDetails = React.lazy(() => import('pages/refunds/components/RefundDetails'));
const ImportOrders = React.lazy(() => import('pages/ImportOrders'));
const ImportOrdersDetails = React.lazy(() => import('pages/ImportOrders/components/ImportOrderDetails'));
const PurchasedOrder = React.lazy(() => import('pages/PurchasedOrder'));
const PurchasedOrderDetails = React.lazy(() => import('pages/PurchasedOrder/components/PurchasedOrderDetails'));
const ManualOrderProcess = React.lazy(() => import('pages/ManualOrderProcess'));
const ItemDetails = React.lazy(() => import('pages/ItemDetails'));
const ItemEdit = React.lazy(() => import('pages/ItemEdit'));
const HomepageEditor = React.lazy(() => import('pages/HomepageEditor'));
const BuilderView = React.lazy(() => import('pages/HomepageEditor/BuilderView'));
const IssueList = React.lazy(() => import('pages/IssueList'));
const CODOrder = React.lazy(() => import('pages/CODOrder'));
const ViewCharges = React.lazy(() => import('pages/ViewCharges'));
const Profile = React.lazy(() => import('pages/Profile'));
const Wallet = React.lazy(() => import('pages/Wallet'));
const WalletDetails = React.lazy(() => import('pages/Wallet/components/WalletDetails'));
const Withdraw = React.lazy(() => import('pages/Withdraw'));
const MoveOnAccount = React.lazy(() => import('pages/MoveOnAccount'));
const Roles = React.lazy(() => import('pages/Roles'));
const TagsView = React.lazy(() => import('pages/tags'));
const ShippingTagsView = React.lazy(() => import('pages/ShippingTags'))
const DisputeOrder = React.lazy(() => import('pages/DisputeOrder'));
const RefreshProduct = React.lazy(() => import('pages/support/RefreshProduct'))
const AddOrderItem = React.lazy(() => import('pages/ItemEdit/AddOrderItem'))
const ManualOrderPage = React.lazy(() => import('pages/CreateManualOrder/ManualOrderPage'))
const OrderReports = React.lazy(() => import('pages/reports/OrderReports'))

const Routes = () => {
	return (
		<Switch>
			<RouteWithBaseLayout
				component={Homepage}
				layout={DefaultLayout}
				exact
				path="/"
			/>

			<Route path="/auth/login">
				<Login />
			</Route>

			<RouteWithBaseLayout
				component={CartItemList}
				layout={DefaultLayout}
				exact
				path="/cart-items"
			/>

			<RouteWithBaseLayout
				component={Customers}
				layout={DefaultLayout}
				exact
				path="/customers"
			/>

			<RouteWithBaseLayout
				component={CustomerDetailsPage}
				layout={CustomerLayout}
				exact
				path="/customers/:id"
			/>

			<RouteWithBaseLayout
				component={CustomerCartItems}
				layout={CustomerLayout}
				exact
				path="/customers/:id/cart"
			/>

			<RouteWithBaseLayout
				component={CustomerTransactions}
				layout={CustomerLayout}
				exact
				path="/customers/:id/transactions"
			/>

			<RouteWithBaseLayout
				component={RequestedItem}
				layout={CustomerLayout}
				exact
				path="/customers/:id/requested"
			/>

			<RouteWithBaseLayout
				component={OrdersPage}
				layout={DefaultLayout}
				exact
				path="/orders"
			/>

			<RouteWithBaseLayout
				component={Requests}
				layout={DefaultLayout}
				exact
				path="/requests"
			/>

			<RouteWithBaseLayout
				component={RequestDetails}
				layout={DefaultLayout}
				exact
				path="/requests/:id"
			/>

			<RouteWithBaseLayout
				component={OrderEdit}
				layout={DefaultLayout}
				exact
				path={routePaths.orders.edit()}
			/>

			<RouteWithBaseLayout
				component={AddOrderItem}
				layout={DefaultLayout}
				exact
				path={routePaths.orders.addItem()}
			/>

			<RouteWithBaseLayout
				component={OrderDetails}
				layout={DefaultLayout}
				exact
				path={routePaths.orders.details()}
			/>

			<RouteWithBaseLayout
				component={PrintOrderInvoice}
				layout={DefaultLayout}
				exact
				path={routePaths.orders.invoice()}
			/>

			<RouteWithBaseLayout
				component={ShippingCategories}
				layout={DefaultLayout}
				exact
				path="/shipping-categories"
			/>

			<RouteWithBaseLayout
				component={TaggedCategories}
				layout={DefaultLayout}
				exact
				path="/shipping-categories/:id/tags"
			/>

			<RouteWithBaseLayout
				component={MakeTags}
				layout={DefaultLayout}
				exact
				path="/shipping-categories/:id/make-tags"
			/>

			<RouteWithBaseLayout
				component={ShippingTagsView}
				layout={DefaultLayout}
				exact
				path="/shipping-tags"
			/>

			<RouteWithBaseLayout
				component={TransactionsPage}
				layout={DefaultLayout}
				exact
				path="/transactions"
			/>

			<RouteWithBaseLayout
				component={TransactionsDetails}
				layout={DefaultLayout}
				exact
				path="/transactions/:id"
			/>

			<RouteWithBaseLayout
				component={Gateways}
				layout={DefaultLayout}
				exact
				path="/gateways"
			/>

			<RouteWithBaseLayout
				component={Couriers}
				layout={DefaultLayout}
				exact
				path="/couriers"
			/>

			<RouteWithBaseLayout
				component={Configurations}
				layout={DefaultLayout}
				exact
				path="/configurations"
			/>

			<RouteWithBaseLayout
				component={Parcels}
				layout={DefaultLayout}
				exact
				path="/parcels"
			/>

			<RouteWithBaseLayout
				component={ParcelUpdater}
				layout={DefaultLayout}
				exact
				path="/parcels/parcel-updater"
			/>

			<RouteWithBaseLayout
				component={ParcelCODUpdater}
				layout={DefaultLayout}
				exact
				path="/cod/parcels/parcel-updater"
			/>

			<RouteWithBaseLayout
				component={ParcelReceiver}
				layout={DefaultLayout}
				exact
				path="/product/receiver"
			/>

			<RouteWithBaseLayout
				component={ParcelReports}
				layout={DefaultLayout}
				exact
				path="/parcels/reports"
			/>

			<RouteWithBaseLayout
				component={PurchaseProductsPage}
				layout={DefaultLayout}
				exact
				path="/purchase-products"
			/>

			<RouteWithBaseLayout
				component={MenusPage}
				layout={DefaultLayout}
				exact
				path="/menus"
			/>

			<RouteWithBaseLayout
				component={Bulk}
				layout={DefaultLayout}
				exact
				path="/bulk"
			/>

			<RouteWithBaseLayout
				component={Refund}
				layout={DefaultLayout}
				exact
				path="/refunds"
			/>

			<RouteWithBaseLayout
				component={RefundDetails}
				layout={DefaultLayout}
				exact
				path="/refunds/:id"
			/>

			<RouteWithBaseLayout
				component={Files}
				layout={DefaultLayout}
				exact
				path="/files"
			/>

			<RouteWithBaseLayout
				component={Campaigns}
				layout={DefaultLayout}
				exact
				path="/campaigns"
			/>

			<RouteWithBaseLayout
				component={CampaignProducts}
				layout={DefaultLayout}
				exact
				path="/campaigns/:id/products"
			/>

			<RouteWithBaseLayout
				component={ShopAccount}
				layout={DefaultLayout}
				exact
				path="/shop-accounts"
			/>
			<RouteWithBaseLayout
				component={LiveCart}
				layout={DefaultLayout}
				exact
				path="/:id/live-cart"
			/>
			<RouteWithBaseLayout
				component={ParcelDetails}
				layout={DefaultLayout}
				exact
				path="/:id/parcels"
			/>
			<RouteWithBaseLayout
				component={ParcelDeed}
				layout={DefaultLayout}
				exact
				path="/parcels/:id/deed"
			/>
			<RouteWithBaseLayout
				component={Products}
				layout={DefaultLayout}
				exact
				path="/products"
			/>
      <RouteWithBaseLayout
				component={EditCustomProduct}
				layout={DefaultLayout}
				exact
				path={routePaths.products.add}
			/>
      <RouteWithBaseLayout
				component={EditCustomProduct}
				layout={DefaultLayout}
				exact
				path={routePaths.products.edit()}
			/>
			<RouteWithBaseLayout
				component={TagsView}
				layout={DefaultLayout}
				exact
				path="/tags"
			/>
			<RouteWithBaseLayout
				component={Support}
				layout={DefaultLayout}
				exact
				path="/support/find"
			/>

			<RouteWithBaseLayout
				component={CustomerOrderDetails}
				layout={DefaultLayout}
				exact
				path="/supports/customers"
			/>

			<RouteWithBaseLayout
				component={SupportOrderDetails}
				layout={DefaultLayout}
				exact
				path="/supports/orders"
			/>

			<RouteWithBaseLayout
				component={TransactionDetails}
				layout={DefaultLayout}
				exact
				path="/supports/transactions"
			/>
			<RouteWithBaseLayout
				component={RefreshProduct}
				layout={DefaultLayout}
				exact
				path="/support/refresh-product"
			/>
			<RouteWithBaseLayout
				component={ParcelItems}
				layout={DefaultLayout}
				exact
				path="/supports/customers/:customerId/parcels/:id/items" />

			<RouteWithBaseLayout
				component={Shops}
				layout={DefaultLayout}
				exact
				path="/shops"
			/>
			<RouteWithBaseLayout
				component={Currency}
				layout={DefaultLayout}
				exact
				path="/currency-rates"
			/>
			<RouteWithBaseLayout
				component={Contents}
				layout={DefaultLayout}
				exact
				path="/contents"
			/>
			<RouteWithBaseLayout
				component={CreateContent}
				layout={DefaultLayout}
				exact
				path="/contents/create"
			/>
			<RouteWithBaseLayout
				component={UpdateContent}
				layout={DefaultLayout}
				exact
				path="/contents/:id/edit"
			/>
			<RouteWithBaseLayout
				component={RolesPermissions}
				layout={DefaultLayout}
				exact
				path="/roles-permissions"
			/>
			<RouteWithBaseLayout
				component={Roles}
				layout={DefaultLayout}
				exact
				path="/roles"
			/>
			<RouteWithBaseLayout
				component={Users}
				layout={DefaultLayout}
				exact
				path="/users"
			/>
			<RouteWithBaseLayout
				component={UserDetails}
				layout={DefaultLayout}
				exact
				path="/users/:id"
			/>
			<RouteWithBaseLayout
				component={ImportOrders}
				layout={DefaultLayout}
				exact
				path="/import-orders"
			/>
			<RouteWithBaseLayout
				component={ImportOrdersDetails}
				layout={DefaultLayout}
				exact
				path="/:id/import-orders"
			/>

			<RouteWithBaseLayout
				component={PurchasedOrder}
				layout={DefaultLayout}
				exact
				path="/purchased-orders"
			/>

			<RouteWithBaseLayout
				component={PurchasedOrderDetails}
				layout={DefaultLayout}
				exact
				path="/:id/purchased-orders"
			/>

			<RouteWithBaseLayout
				component={ManualOrderProcess}
				layout={DefaultLayout}
				exact
				path="/:id/manual-order-process"
			/>

			<RouteWithBaseLayout
				component={ItemDetails}
				layout={DefaultLayout}
				exact
				path="/item/:id"
			/>

			<RouteWithBaseLayout
				component={ItemEdit}
				layout={DefaultLayout}
				exact
				path={routePaths.orders.itemEdit()}
			/>

			<RouteWithBaseLayout
				component={ItemEdit}
				layout={DefaultLayout}
				exact
				path="/cart-item/edit/:id"
			/>

			<RouteWithBaseLayout
				component={ManualOrderPage}
				layout={DefaultLayout}
				exact
				path={routePaths.orders.create}
			/>

			<RouteWithBaseLayout
				component={HomepageEditor}
				layout={DefaultLayout}
				exact
				path="/builders"
			/>
			<RouteWithBaseLayout
				component={BuilderView}
				layout={BuilderLayout}
				exact
				path="/builders/:id"
			/>

			<RouteWithBaseLayout
				component={IssueList}
				layout={DefaultLayout}
				exact
				path="/issues"
			/>

			<RouteWithBaseLayout
				component={CODOrder}
				layout={DefaultLayout}
				exact
				path="/cod-orders"
			/>

			<RouteWithBaseLayout
				component={ViewCharges}
				layout={DefaultLayout}
				exact
				path="/charges"
			/>

			<RouteWithBaseLayout
				component={Profile}
				layout={DefaultLayout}
				exact
				path="/profile"
			/>

			<RouteWithBaseLayout
				component={Wallet}
				layout={DefaultLayout}
				exact
				path="/wallet-transactions"
			/>

			<RouteWithBaseLayout
				component={WalletDetails}
				layout={DefaultLayout}
				exact
				path="/wallet-transactions/:id"
			/>

			<RouteWithBaseLayout
				component={Withdraw}
				layout={DefaultLayout}
				exact
				path="/withdraw-transactions"
			/>

			<RouteWithBaseLayout
				component={MoveOnAccount}
				layout={DefaultLayout}
				exact
				path="/moveon-account"
			/>

			<RouteWithBaseLayout
				component={OrderReports}
				layout={DefaultLayout}
				exact
				path="/reports/order-reports"
			/>

			<RouteWithBaseLayout
				component={DisputeOrder}
				layout={DefaultLayout}
				exact
				path="/dispute-orders"
			/>

			<RouteWithBaseLayout
				layout={DefaultLayout}
				component={Unauthorized}
				exact
				path="/403"
			/>

			<RouteWithBaseLayout
				layout={DefaultLayout}
				component={PageNotFound}
			/>
		</Switch>
	)
}

export default Routes;
