import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/storeAccounts';
import {getStoreAccounts, storeAccountsDetails} from "services/accounts";

const getApi = async (filter) => {
    return getStoreAccounts(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getStoreAccountDetails = async (id) => {
  return storeAccountsDetails(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchStoreAccounts(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_STORE_ACCOUNT_SUCCESS, accounts: data });
    } catch (e) {
        yield put({ type: types.GET_STORE_ACCOUNT_FAILED, message: e?.response });
    }
}

function* fetchStoreAccountDetails(action) {
  try {
    const data = yield call(() => getStoreAccountDetails(action.payload));
    yield put({ type: types.GET_STORE_ACCOUNT_DETAILS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_STORE_ACCOUNT_DETAILS_FAILED, message: e?.response });
  }
}

function* storeAccountSaga() {
    yield all([
        takeEvery(types.GET_STORE_ACCOUNT_REQUESTED, fetchStoreAccounts),
        takeEvery(types.GET_STORE_ACCOUNT_DETAILS_REQUEST, fetchStoreAccountDetails),
    ]);
}

export default storeAccountSaga;
