import React, { useState } from 'react';
import { Button, Card, message, Skeleton, Descriptions, Typography} from 'antd';
import { getAccessToken } from 'services/customer-client';

const {Text} = Typography;

const PanelAccess = (props) => {
  const {
    selectedCustomer,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [accessData, setAccessData] = useState(null);

  const handleGetAccess = () => {
    setIsLoading(true);

    getAccessToken(selectedCustomer?.id)
      .then((res) => {
        let data = res.data.data;
        if(data){
          data.customer_app_url = data.customer_app_url + "/auth/acting/" + data.access_key;
          data.mobile_web_url = data.mobile_web_url + "/auth/acting/" + data.access_key;
          setAccessData(data);
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err)
        message.error(err?.data?.message);
      }).finally(x => {
        setIsLoading(false);
    })
  };

  return (
    <Card
      title="Access dashboard"
      bordered={false}
    >
      <Skeleton loading={isLoading}>
        {
           accessData ?
          (
            <Descriptions
              title={<></>}
              bordered
              size="small"
              column={1}
            >
              <Descriptions.Item label="Web">
                <span>
                  <a href={accessData?.customer_app_url} target={"_blank"} rel="noreferrer" >
                    <Text strong style={{color: '#1890ff'}} copyable={{ text: accessData?.customer_app_url }}>{accessData?.customer_app_url}</Text>
                  </a>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Mobile">
                <span>
                  <a href={accessData?.mobile_web_url} target={"_blank"} rel="noreferrer" >
                    <Text strong style={{color: '#1890ff'}} copyable={{ text: accessData?.mobile_web_url }}>{accessData?.mobile_web_url}</Text>
                  </a>
                </span>
              </Descriptions.Item>
            </Descriptions>
          )
          :
             <Button
               type="primary"
               htmlType="submit"
               loading={isLoading}
               disabled={isLoading}
               onClick={() => handleGetAccess()}
               style={{
                 textAlign: "center"
               }}
             >
               Get Access
             </Button>
        }
      </Skeleton>
    </Card>
  );
};

export default PanelAccess;
