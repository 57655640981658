import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/wallet';
import {getWalletDetails, getWalletTransition} from "services/transactions";

const getApi = async (filter) => {
  return getWalletTransition(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getWalletApi = async (id) => {
  return getWalletDetails(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchWalletTransition(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_WALLET_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_WALLET_FAILED, message: e?.response });
  }
}

function* fetchWalletTransitionDetails(action) {
  try {
    const data = yield call(() => getWalletApi(action.payload));
    yield put({ type: types.GET_WALLET_DETAILS_SUCCESS, walletDetails: data });
  } catch (e) {
    yield put({ type: types.GET_WALLET_DETAILS_FAILED, message: e?.response });
  }
}

function* walletSaga() {
  yield all([
    takeEvery(types.GET_WALLET_REQUESTED, fetchWalletTransition),
    takeEvery(types.GET_WALLET_DETAILS_REQUESTED, fetchWalletTransitionDetails),
  ]);
}

export default walletSaga;
