import { message } from "antd";
import siteConfig from "consts/site-configs";
import { productSkuParseFromUrl } from "utils/data-parse";

const formatDate = (dateData) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new window.Date(dateData);
  let date = today.getDate().toString();
  if (date.length === 1) {
    date = `0${date}`;
  }
  return `${date} ${monthNames[today.getMonth()]}, ${today.getFullYear()}`;
};

const formatTime = (time) => {
  return new window.Date(time).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const formatDateTime = (dateTime) => {
  return `${formatDate(dateTime)} - ${formatTime(dateTime)}`;
};

const isMultiCheck = (cartProduct, order) => {
  let is_same = false
  if (cartProduct.sku.skuId && order.skuid) {
    if (cartProduct.sku.skuId === order.skuid) {
      is_same = true
    }
  } else if (cartProduct?.sku?.attrs?.length > 0 && order?.attrs?.length > 0) {
    let cartAttributes = cartProduct?.sku?.attrs?.map((el) => Object.values(el).map(item => String(item).replaceAll(/\s/g, ''))).flat().sort()
    let orderAttributesFilter = order?.attrs?.filter((el) => {
      return el?.key === "Shipping"
        || el?.key === "Shipping Method"
        || el?.key === "Freight Charge"
        || el?.key === "SKUID"
        || el?.key === "TAG";
    })
    let orderAttr = order?.attrs.filter(item => !orderAttributesFilter.includes(item))
    let orderAttrExceptImage = orderAttr.map((el) => {
      let objectWithoutImage = {};
      for (let [key, value] of Object.entries(el)) {
        if (key  !== 'image') {
          objectWithoutImage[key] = value
        }
      }
      return objectWithoutImage;
    })
    let orderAttributes = orderAttrExceptImage?.map((el) => Object.values(el).map(item => String(item).replaceAll(/\s/g, ''))).flat().sort()
    is_same = (orderAttributes.length === cartAttributes.length) && orderAttributes.every(function (element, index) {
      return cartAttributes.includes(element)
    });
  }

  return is_same
}

const matchOrderWithCart = (order, cart, matchedSkuIDs=[]) => {

  let cartItems = cart && cart?.items;
  if (Array.isArray(cartItems)) {
    for (const cartProduct of cartItems) {
      let productVidId = productSkuParseFromUrl(order.link)?.toString()
      let productQty = order.quantity
      let isSkuSame = isMultiCheck(cartProduct, order)

      let cartVidId = productSkuParseFromUrl(cartProduct.link)?.toString()
      let cartQty = cartProduct?.sku?.quantity

      if (order?.vpid && cartProduct?.productId) {
        productVidId = order?.vpid
        cartVidId = cartProduct?.productId
      }
      if (cartProduct?.sku?.attrs?.length > 0 && order?.attrs?.length > 0) {
        let skuId = order.skuid;
        if (productVidId === cartVidId && isSkuSame && !matchedSkuIDs?.includes(skuId)) {
          matchedSkuIDs.push(skuId)
          if (productQty === cartQty) {
            return cartProduct
          } else {
            return {
              ...cartProduct,
              quantityMismatch: "quantityMismatch",
            }
          }
        }
        // else if (productVidId === cartVidId && !isSkuSame && order?.shop_id === 1){
        //   return {
        //     ...cartProduct,
        //     skuMismatch: "skuMismatch",
        //   }
        // }
      } else {
        if (productVidId === cartVidId && productQty === cartQty) {
          return {
            ...cartProduct,
            noSkuProduct: "noSkuProduct",
          }
        }
      }
    }
  }
};

export const matchOrderWithCartForProcessable = (order, cart) => {
  let newOrder = []

  for (let i = 0; i < cart?.items?.length; i++) {
    let elementCart = cart?.items[i]

    let cartVidId = productSkuParseFromUrl(elementCart.link)?.toString()
    let cartQty = elementCart?.sku?.quantity

    for (let i = 0; i < order?.length; i++) {
      let elementOrder = order[i]

      let isSkuSame = isMultiCheck(elementCart, elementOrder)
      let productVidId = productSkuParseFromUrl(elementOrder.link)?.toString()
      let productQty = elementOrder.quantity

      if (elementOrder?.vpid && elementCart?.productId) {
        productVidId = elementOrder?.vpid
        cartVidId = elementCart?.productId
      }

      if (elementCart?.sku?.attrs?.length > 0 && elementOrder?.attrs?.length > 0) {
        if (productVidId === cartVidId && isSkuSame) {
          if (productQty === cartQty) {
            newOrder.push({
              ...elementCart,
              orderId: elementOrder.id,
            })
            break;
          } else {
            newOrder.push({
              ...elementCart,
              orderId: elementOrder.id,
              quantityMismatch: "quantityMismatch",
            })
          }
        }
        // else if (productVidId === cartVidId && !isSkuSame){
        //   newOrder.push({
        //     ...elementCart,
        //     orderId: elementOrder.id,
        //     quantityMismatch: "quantityMismatch",
        //   })
        // }
      } else {
        if (productVidId === cartVidId && productQty === cartQty) {
          newOrder.push({
            ...elementCart,
            orderId: elementOrder.id,
          })
          break;
        }
      }
    }
  }
  return newOrder
};

export const matchOrderWithCartForProcessableCopy = (order, cart) => {
  let newOrder = []
  // let newMissMatchOrder = []

  for (let i = 0; i < cart?.items?.length; i++) {
    let elementCart = cart?.items[i]

    let cartVidId = productSkuParseFromUrl(elementCart.link)?.toString()
    let cartQty = elementCart?.sku?.quantity

    for (let i = 0; i < order?.length; i++) {
      let elementOrder = order[i]

      let isSkuSame = isMultiCheck(elementCart, elementOrder)
      let productVidId = productSkuParseFromUrl(elementOrder.link)?.toString()
      let productQty = elementOrder.quantity

      if (elementOrder?.vpid && elementCart?.productId) {
        productVidId = elementOrder?.vpid
        cartVidId = elementCart?.productId
      }

      if (elementCart?.sku?.attrs?.length > 0 && elementOrder?.attrs?.length > 0) {
        if (productVidId === cartVidId && isSkuSame) {
          if (productQty === cartQty) {
            newOrder.push({
              ...elementCart,
              orderId: elementOrder.id,
              orderLink: elementOrder.link,
              orderCreatedAt: elementOrder?.created_at,
              orderPrice: elementOrder?.price,
              proOrderID: elementOrder?.order_id,
              shopID: elementOrder?.shop_id,
            })
            break;
          } else {
            newOrder.push({
              ...elementCart,
              orderId: elementOrder.id,
              quantityMismatch: "quantityMismatch",
              orderLink: elementOrder.link,
              orderCreatedAt: elementOrder?.created_at,
              orderPrice: elementOrder?.price,
              proOrderID: elementOrder?.order_id,
              shopID: elementOrder?.shop_id,
            })
          }
        }
        // else if (productVidId === cartVidId && !isSkuSame) {
        //   newMissMatchOrder.push({
        //     ...elementCart,
        //     orderId: elementOrder.id,
        //     skuMissMatch: "skuMissMatch",
        //     orderLink: elementOrder.link,
        //     orderCreatedAt: elementOrder?.created_at,
        //     orderPrice: elementOrder?.price,
        //     proOrderID: elementOrder?.order_id,
        //     shopID: elementOrder?.shop_id,
        //   })
        // }
      }
      else if (productVidId === cartVidId && productQty === cartQty) {
        newOrder.push({
          ...elementCart,
          orderId: elementOrder.id,
          orderLink: elementOrder.link,
          orderCreatedAt: elementOrder?.created_at,
          orderPrice: elementOrder?.price,
          proOrderID: elementOrder?.order_id,
          shopID: elementOrder?.shop_id,
          noArrMissMatch: "noArrMissMatch",
        })
        break;
      }
    }
  }
  return newOrder
};

export const matchOrderWithSKUData = (order, cart) => {
  let newOrder = []

  for (let i = 0; i < cart?.length; i++) {
    let elementCart = cart[i]

    let cartVidId = productSkuParseFromUrl(elementCart.link)?.toString()
    let cartQty = elementCart?.sku?.quantity

    for (let i = 0; i < order?.length; i++) {
      let elementOrder = order[i]

      let isSkuSame = isMultiCheck(elementCart, elementOrder)
      let productVidId = productSkuParseFromUrl(elementOrder.link)?.toString()
      let productQty = elementOrder.quantity

      if (elementOrder?.vpid && elementCart?.productId) {
        productVidId = elementOrder?.vpid
        cartVidId = elementCart?.productId
      }

      if (elementCart?.sku?.attrs?.length > 0 && elementOrder?.attrs?.length > 0) {
        if (productVidId === cartVidId && isSkuSame) {
          if (productQty === cartQty) {
            newOrder.push({
              ...elementOrder,
              cart: elementCart,
            })
            break;
          } else {
            newOrder.push({
              ...elementOrder,
              cart: elementCart,
              quantityMismatch: "quantityMismatch",
            })
          }
        }
        /*else if (productVidId === cartVidId){
          newOrder.push({
            ...elementOrder,
            cart: elementCart,
            skuMissMatch: "skuMissMatch",
          })
        }*/
      } else {
        if (productVidId === cartVidId && productQty === cartQty) {
          newOrder.push({
            ...elementOrder,
            cart: elementCart,
            productQtyMatch: "productQtyMatch",
          })
          break;
        }
      }
    }
  }
  return newOrder
};

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const formattedAmount = (price) => {
  if (price === 0) {
    return price
  }
  if (price) {
    return parseFloat(Number(price)?.toFixed(2))
  }
}

const formattedWithFloatAmount = (price) => {
  if (isNaN(price)) {
    return 0
  }
  return Math.round(price * 100) / 100
}

const getLocalData = (localKey) => {
  return JSON.parse(localStorage.getItem(localKey))
}

export const getFormattedURL = (url) => {
  const host = url ? new URL(url) : null;
  let formattedUrl = url;
  const hasOnHost = (shop) => host?.host?.includes(shop)
  let isOriginPath = hasOnHost('aliexpress')
  || hasOnHost('amazon')
  || hasOnHost('1688')
  || hasOnHost('flipkart')
  || hasOnHost('gearbest')

  if (hasOnHost('taobao')) {
    let regex = /[&|?]id=\d+&?/g;
    let itemIdWithKey = host.search?.match(regex)?.[0];
    formattedUrl = host.origin + host.pathname + `?${itemIdWithKey?.slice(1, itemIdWithKey?.length)}`
  }
  else if (isOriginPath) {
    formattedUrl = host.origin + host.pathname;
  }
  return formattedUrl;
}

export const toastErrors = (erorrs) => {
  if (erorrs?.errors) {
    let errors = erorrs.errors;
    Object.keys(errors)?.forEach(key => {
      message.error(errors?.[key]?.[0])
    })
  } else {
    message.error(erorrs?.message)
  }
}

const matchGrouping = (objectArray, property, unique) => {
  return objectArray?.reduce(function (acc, obj) {
    let key = obj[property];
    if (unique) {
      key = obj?.order_item[property];
    }
    if (!property) {
      key = obj["Mixed"];
    } else if (!key) {
      key = "Mixed";
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

function support_format_webp() {
  let elem = document.createElement("canvas");

  if (!!(elem.getContext && elem.getContext("2d"))) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    return false;
  }
}

export const changeImageOptimizeSize = (url) => {

  let addAdditionImage = "_80x80Q10.jpg_.webp"
  let addAdditionImageJpg = "_260x260Q50.jpg"

  if (support_format_webp) {
    return url + addAdditionImage
  } else {
    return url + addAdditionImageJpg;
  }
}

export const exportToCsv = (filename, rows) => {
  let processRow = function (row) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, "\"\"");
      if (result.search(/([",\n])/g) >= 0)
        result = "\"" + result + "\"";
      if (j > 0)
        finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  let link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// Quick and simple export target #table_id into a csv
export const download_table_as_csv = (table_id, separator = ",") => {
  // Select rows from table_id
  let rows = document.querySelectorAll("table#" + table_id + " tr");
  // Construct csv
  let csv = [];
  for (let i = 0; i < rows.length; i++) {
    let row = [], cols = rows[i].querySelectorAll("td, th");
    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      // @ts-ignore
      let data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ")
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, "\"\"");
      // Push escaped string
      row.push("\"" + data + "\"");
    }
    csv.push(row.join(separator));
  }
  let csv_string = csv.join("\n");
  // Download it
  let filename = "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
  let link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const slot = (slots) => {
  let minUnitValue;

  slots.length > 1 && slots.forEach((element) => {
    if(minUnitValue){
      if(Number(minUnitValue.from) > Number(element.from)){
        minUnitValue = element;
      }
    } else {
      minUnitValue = element;
    }
  });

  return slots.length > 1 ? minUnitValue : slots[0];
}

export const gramUnit = (amount, divisor= 10) => {
  return isNaN(amount) ? 0 : (amount / divisor);
}

export const freightRate = (price, unit) => {
  return unit === 'kg' ? {rate:gramUnit(price, 10), scale: '100 gram'}
    : {rate:price, scale:unit}
}

export const getFloatingPrice = (price) => {
  if (typeof price === 'string') {
      return Number(price)?.toFixed(2)
  }
  return price?.toFixed(2);
}

export const getShippingFeeByGram = (fee, perGram = 10) => {
  let feeWithFloat = getFloatingPrice(fee);
  return (feeWithFloat / perGram).toFixed(2);
}

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  // return dayFormat(result).format('ll');
  return result.toDateString();
}

export const getEstimatedDate = (time) => {
  let fromToDays = time.split('-');
  let fromDays = parseInt(fromToDays?.[0]);
  let toDays = parseInt(fromToDays?.[1]);
  let today = new Date();
  if (fromDays === toDays) {
      let estDate = addDays(today, toDays);
      return ` ${estDate}`
  } else {
      let estFromDate = addDays(today, fromDays);
      let estToDate = addDays(today, toDays);
      return ` ${estFromDate} to ${estToDate}`;
  }
}
/**
 *
 * @param {value: amount, number}
 * @param {locales: en-BD | en-AE etc}
 * @param {style: number | currency}
 * @param {currency: BDT | AED }
 * @param { currencyDisplay: code | narrowSymbol | name | symbol }
 */
export const getLocaleValue = ({
    value,
    locales=siteConfig?.locales || 'en-BD',
    style='currency',
    currency=siteConfig?.currency ||'BDT',
    currencyDisplay
  }) => {
  let options = {style, currency};

  if (currencyDisplay) {
    options['currencyDisplay'] = currencyDisplay;
  }

  return new Intl.NumberFormat(locales, options).format(value)
}

export const getCurrencyCode = (currencyCode) => {
  if (currencyCode) {
    return currencyCode;
  }

  return siteConfig?.currency || 'BDT';
}

export const ReasonableDetails = ({priceText, value, fx, Text}) => {
  return (
    <span>{priceText} <Text strong type="success">{formattedWithFloatAmount(value)} - {getLocaleValue({
      value: formattedWithFloatAmount(value * Number(fx)),
      currencyDisplay: 'narrowSymbol',
    })}</Text></span>
  )
}

export {
  formatTime,
  formatDateTime,
  formatDate,
  matchOrderWithCart,
  sleep,
  formattedAmount,
  getLocalData,
  isMultiCheck,
  formattedWithFloatAmount,
  matchGrouping
};
