import * as actionTypes from 'app/actionTypes/products';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchInventoryProducts, fetchProducts, fetchProduct, importProducts } from 'services/products';

const fetchProductsApi = async (filter = {}) => {
    try {
        const res = await fetchProducts(filter);
        return res.data;
    } catch (err) {
        throw err;
    }
}

function* fetchProductsData(action) {
    try {
        const data = yield call(() => fetchProductsApi(action.payload));
        yield put({type: actionTypes.GET_PRODUCTS_SUCCEDED, payload: data})
    }
    catch(e) {
        yield put({type: actionTypes.GET_PRODUCTS_FAILED, message: e?.response})
    }
}

const fetchProductApi = async (pid) => {
  try {
    const res = await fetchProduct(pid);
    return res.data;
  } catch (err) {
    throw err;
  }
}

function* fetchProductData(action) {
  try {
    const data = yield call(() => fetchProductApi(action.payload));
    yield put({type: actionTypes.GET_PRODUCT_SUCCEDED, payload: data})
  }
  catch(e) {
    yield put({type: actionTypes.GET_PRODUCT_FAILED, message: e?.response})
  }
}

const searchProductsApi = async (filter = {}) => {
    try {
        const res = await importProducts(filter)
        return res.data;
    } catch (err) {
        throw err;
    }
}

function* searchProductsData(action) {
    try {
        const data = yield call(() => searchProductsApi(action.payload));
        yield put({type: actionTypes.PRODUCTS_SEARCH_SUCCEEDED, payload: data})
    } catch (e) {
        yield put({type: actionTypes.PRODUCTS_SEARCH_FAILED, message: e?.response})
    }
}

const searchInventoryProductsApi = async (filter = {}) => {
    try {
        const res = await fetchInventoryProducts(filter)
        return res.data;
    } catch (err) {
        throw err;
    }
}

function* searchInventoryProductsData(action) {
    try {
        const data = yield call(() => searchInventoryProductsApi(action.payload));
        yield put({type: actionTypes.INV_PRODUCTS_SEARCH_SUCCEEDED, payload: data})
    } catch (e) {
        yield put({type: actionTypes.INV_PRODUCTS_SEARCH_FAILED, message: e?.response})
    }
}

function* productsSaga() {
    yield all([
        takeEvery(actionTypes.GET_PRODUCTS_REQUESTED, fetchProductsData),
        takeEvery(actionTypes.GET_PRODUCT_REQUESTED, fetchProductData),
        takeEvery(actionTypes.PRODUCTS_SEARCH_REQUESTED, searchProductsData),
        takeEvery(actionTypes.INV_PRODUCTS_SEARCH_REQUESTED, searchInventoryProductsData),
    ])
}

export default productsSaga;
