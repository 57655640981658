import produce from "immer";
import * as Types from 'app/actionTypes/itemRefunds';

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: true,
  error: null,
  itemsDetails: null,
  itemDetailsLoading: true,
  itemsDetailsError: true,
  itemIssues: null,
  itemIssueLoading: true
}

export default function itemRefunds(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_REFUNDS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_REFUNDS_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case Types.GET_REFUNDS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.RESET_REFUNDS:
        draft.items = {
          data: null,
          meta: null,
        }
        draft.loading = false
        break
      case Types.UPDATE_REFUND:
        const index = draft.items.data.findIndex(trnx => trnx.id === action.payload.id)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data }
        break
      case Types.GET_REFUNDS_DETAILS_REQUESTED:
        draft.itemDetailsLoading = true
        break
      case Types.GET_REFUNDS_DETAILS_SUCCESS:
        draft.itemsDetails = action.items.data
        draft.itemDetailsLoading = false
        break
      case Types.GET_REFUNDS_DETAILS_FAILED:
        draft.itemDetailsLoading = false
        draft.itemsDetailsError = action.message
        break
      case Types.UPDATE_REFUND_DETAILS:
        const indexDetails = draft.itemsDetails.id === action.payload.id
        if (indexDetails) draft.itemsDetails = { ...draft.itemsDetails, ...action.payload.data }
        break
      case Types.ISSUE_ABLE_DETAILS_REQUEST:
        draft.itemIssueLoading = true
        break
      case Types.ISSUE_ABLE_DETAILS_SUCCESS:
        draft.itemIssues = action.data
        draft.itemIssueLoading = false
        break
      case Types.ISSUE_ABLE_DETAILS_FAILED:
        draft.itemIssueLoading = false
        draft.error = action.message
        break
      case Types.UPDATE_ISSUE_ABLE_DETAILS:
        const indexIssue = draft.itemIssues.data.findIndex(trnx => trnx.id === action.payload.id)
        if (indexIssue !== -1) draft.itemIssues.data[indexIssue] = { ...draft.itemIssues.data[indexIssue], ...action.payload.data }
        break
      default:
        return state
    }
  })
}
