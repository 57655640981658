import * as types from "app/actionTypes/desk";
import produce from "immer";
import qs from "qs";

const initialState = {
    items: {
        data: null,
        meta: null,
    },
    searchResults: null,
    labels: localStorage.statusLabel ? qs.parse(localStorage.statusLabel): null,
    payable: null,
    packItems: null,
    orderParcels: null,
    loading: false,
    error: null,
}

export default function desk(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case types.SET_ORDER:
                draft.items   = action.payload.data
                draft.payable = action.payload.data.id
                draft.loading = false
                break
            case types.SET_TRANSACTION:
                draft.items.transactions.push(action.payload.data)
                break
            case types.UPDATE_ORDER_ITEM_ON_PACKAGE:
                const index = draft.items.data.findIndex(itm => itm.id === action.payload.packageId)
                const pack = draft.items.data[index]
                const position = pack.items.findIndex(item => item.id === action.payload.id)
                if (index !== -1) draft.items.data[index].items[position] = { ...draft.items.data[index].items[position], ...action.payload.data }
                break
            case types.SET_SEARCH_RESULT:
                draft.searchResults = action.payload.data
                break
            case types.SET_PAYABLE:
                draft.payable = action.payload.data
                break
            case types.SET_PACK_ITEMS:
                draft.packItems = action.payload.data
                break
            case types.SET_LABELS:
                draft.labels = action.payload.data
                break
          case types.SET_ORDER_PARCELS:
                draft.orderParcels = action.payload.data
                break
            default:
                return state
        }
    });
}
