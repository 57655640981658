import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getCouriers, getCourierPackages } from 'services/couriers-client';
import * as type from 'app/actionTypes/couriers';

const getApi = async (filter) => {
    return getCouriers(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getPackagesApi = async (courier_id) => {
    return getCourierPackages(courier_id)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchCouriers(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_COURIERS_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: type.GET_COURIERS_FAILED, message: e?.response });
    }
}

function* fetchPackages(action) {
    try {
        const data = yield call(() => getPackagesApi(action.payload));
        yield put({ type: type.GET_COURIER_PACKAGES_SUCCESS, data: data.data });
    } catch (e) {
        yield put({ type: type.GET_COURIER_PACKAGES_FAILED, message: e?.response });
    }
}

function* couriersSaga() {
    yield all([
        takeEvery(type.GET_COURIERS_REQUESTED, fetchCouriers),
        takeEvery(type.GET_COURIER_PACKAGES_REQUESTED, fetchPackages),
    ]);
}

export default couriersSaga;
