import * as actionTypes from 'app/actionTypes/rolesPermissions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {getAllRoles, getPermissionsWithRoles} from "services/rolesPermissons";

const fetchPermissionsApi = async () => {
    try {
        const res = await getPermissionsWithRoles();
        return res.data;
    } catch ({ response }) {
        return response;
    }
}

const getApi = async (filter) => {
  return getAllRoles(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchPermissions(action) {
    try {
        const data = yield call(() => fetchPermissionsApi());
        yield put({type: actionTypes.GET_PERMINSSIONS_SUCCEEDED, payload: data})
    }
    catch(e) {
        yield put({type: actionTypes.GET_PERMINSSIONS_FAILED, message: e?.response})
    }
}

function* fetchAllRoles(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: actionTypes.GET_ROLES_SUCCEEDED, items: data });
  } catch (e) {
    yield put({ type: actionTypes.GET_ROLES_FAILED, message: e?.response });
  }
}

function* rolesPermissions() {
    yield all([
        takeEvery(actionTypes.GET_PERMINSSIONS_REQUESTED, fetchPermissions),
        takeEvery(actionTypes.GET_ROLES_REQUESTED, fetchAllRoles),
    ])
}

export default rolesPermissions;

