export const GET_WIDGETS_REQUESTED = 'GET_WIDGETS_REQUESTED';
export const GET_WIDGETS_SUCCEEDED = 'GET_WIDGETS_SUCCEEDED';
export const GET_WIDGETS_FALIED = 'GET_WIDGETS_FALIED';

export const GET_BUILDER_LIST_REQUESTED = 'GET_BUILDER_LIST_REQUESTED';
export const GET_BUILDER_LIST_SUCCEEDED = 'GET_BUILDER_LIST_SSUCCEEDED';
export const GET_BUILDER_LIST_FAILED = 'GET_BUILDER_LIST_FAILSED';

export const GET_BUILDER_REQUESTED = 'GET_BUILDER_REQUESTED';
export const GET_BUILDER_SUCCEEDED = 'GET_BUILDER_SUCCEEDED';
export const GET_BUILDER_FAILED = 'GET_BUILDER_FAILED';

export const UPDATE_BUILDER = 'UPDATE_BUILDER';
export const REMOVE_BUILDER = 'REMOVE_BUILDER';
