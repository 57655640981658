import * as type from 'app/actionTypes/gateways';

const initialState = {
    items: {
        data: null,
        meta: null,
        filters: null,
        modules: null
    },
    loading: false,
    error: null
}

export default function gateways(state = initialState, action) {
    switch (action.type) {
        case type.GET_GATEWAYS_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case type.GET_GATEWAYS_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false
            }
        case type.GET_GATEWAYS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return state
    }
}
