import client from 'utils/api-client';
import Qs from 'qs';

export const getGateways = (filter = {}) => {
  return client.get('/gateways', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

/**
 * Get only id and names of gateways for minimal use.
 *
 * @returns Promise
 */
export const getBasicGateways = () => {
  return client.get('/gateways/basic');
};

export const createGateway = (data) => {
  return client.post(`/gateways`, data);
};

export const updateGateway = (gateway_id, data) => {
  return client.put(`/gateways/${gateway_id}`, data);
};
