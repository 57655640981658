import * as types from 'app/actionTypes/cartItems';
import produce from "immer";

const initialState = {
    items: {
        data: null,
        meta: null,
        filters: null
    },
    loading: true,
    error: null,
    cartDetails: null,
    cartLoading: true
}

export default function cartItems(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case types.GET_CART_ITEMS_REQUESTED:
                draft.loading = true
                break;
            case types.GET_CART_ITEMS_SUCCESS:
                draft.items = action.items
                draft.loading = false
                break
            case types.GET_CART_ITEMS_FAILED:
                draft.loading = false
                draft.error = action.message
                break
            case types.RESET_CART_ITEMS:
                draft.loading = false
                break
            case types.GET_CART_ITEMS_DETAILS_REQUESTED:
                draft.cartLoading = true
                break;
            case types.GET_CART_ITEMS_DETAILS_SUCCESS:
                draft.cartDetails = action.items.data
                draft.cartLoading = false
                break
            case types.GET_CART_ITEMS_DETAILS_FAILED:
                draft.cartLoading = false
                break
            default:
                return state
        }
    })
}
