import * as types from 'app/actionTypes/support';
import produce from "immer";

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null,
}

export default function support(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_SUPPORT_ORDER_REQUESTED:
        draft.loading = true
        break
      case types.GET_SUPPORT_ORDER_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case types.GET_SUPPORT_ORDER_FAILED:
        draft.error = action.message
        draft.loading = false
        break
      case types.UPDATE_SUPPORT_ORDERS:
        const index = draft.items.data.findIndex(itm => itm.id === action.payload.id)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data }
        break
      default:
        return state
    }
  })
}
