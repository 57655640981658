import * as actionTypes from 'app/actionTypes/builder';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getBuilder, getBuilders, getWidgets } from 'services/builder';

const getWidgetsApi = async (filter = {}) => {
    try {
        const res = await getWidgets(filter);
        return res.data;
    } catch ({ response }) {
        return response;
    }
}

function* fetchWidgets(action) {
    try {
        const data = yield call(() => getWidgetsApi());
        yield put({ type: actionTypes.GET_WIDGETS_SUCCEEDED, payload: data })
    }
    catch (e) {
        yield put({ type: actionTypes.GET_WIDGETS_FALIED })
    }
}

const getBuildersApi = async (filter = {}) => {
    try {
        const res = await getBuilders(filter);
        return res.data;
    } catch ({ response }) {
        return response;
    }
}

function* fetchBuilders(action) {
    try {
        const data = yield call(() => getBuildersApi());
        yield put({ type: actionTypes.GET_BUILDER_LIST_SUCCEEDED, payload: data })
    }
    catch (e) {
        yield put({ type: actionTypes.GET_BUILDER_LIST_FAILED })
    }
}

const getBuilderApi = async (id) => {
    try {
        const res = await getBuilder(id);
        return res.data.data;
    } catch ({ response }) {
        return response;
    }
}

function* fetchBuilder(action) {
    const { payload } = action;
    try {
        const data = yield call(() => getBuilderApi(payload));
        yield put({ type: actionTypes.GET_BUILDER_SUCCEEDED, payload: data })
    }
    catch (e) {
        yield put({ type: actionTypes.GET_BUILDER_FAILED })
    }
}

function* builderSaga() {
    yield all([
        takeEvery(actionTypes.GET_WIDGETS_REQUESTED, fetchWidgets),
        takeEvery(actionTypes.GET_BUILDER_REQUESTED, fetchBuilder),
        takeEvery(actionTypes.GET_BUILDER_LIST_REQUESTED, fetchBuilders)
    ])
}

export default builderSaga;
