import * as types from 'app/actionTypes/orderItems';
import produce from "immer";

const initialState = {
  items: {
    data: null,
    meta: null,
  },
  itemsWithRelations: {
    data: null,
    meta: null,
    filters: null,
  },
  itemsWithRelationsLoading: false,
  loading: false,
  error: null,
  processable: {
    data: null,
    filters: null,
    issue_types: null,
    accounts: null
  },
  isProcessableLoading: true,
  itemDetails: null,
  itemDetailsLoading: true,
  disputeItems: {
    data: null,
    meta: null,
    filters: null,
  },
  disputeItemsLoading: false,
  disputeItemsError: null,
}

export default function orderItems(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_ORDER_ITEMS_REQUESTED:
        draft.loading = true;
        break
      case types.GET_ORDER_ITEMS_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case types.GET_ORDER_ITEMS_FAILED:
        draft.error = action.message
        draft.loading = false
        break
      case types.RESET_ORDER_ITEMS:
        draft.items = {
          data: null,
          meta: null,
        }
        draft.loading = false
        break
      case types.GET_ORDER_ITEMS_WITH_RELATIONSHIPS_REQUESTED:
        draft.itemsWithRelationsLoading = true
        break
      case types.GET_ORDER_ITEMS_WITH_RELATIONSHIPS_SUCCESS:
        draft.itemsWithRelations = action.items
        draft.itemsWithRelationsLoading = false
        break
      case types.GET_ORDER_ITEMS_WITH_RELATIONSHIPS_FAILED:
        draft.itemsWithRelationsLoading = false
        draft.error = action.message
        break
      case types.RESET_ORDER_ITEMS_WITH_RELATIONSHIPS:
        draft.itemsWithRelations = {
          data: null,
          meta: null,
          filters: null
        }
        draft.loading = false
        break
      case types.DELETE_ORDER_ITEMS_WITH_RELATIONSHIPS:
        draft.itemsWithRelations.data = draft.itemsWithRelations.data.filter(item => !action.payload.includes(item.id));
        break;
      case types.GET_PROCESSABLE_ORDER_REQUESTED:
        draft.isProcessableLoading = true
        break
      case types.GET_PROCESSABLE_ORDER_SUCCESS:
        draft.processable = action.items
        draft.isProcessableLoading = false
        break
      case types.GET_PROCESSABLE_ORDER_FAILED:
        draft.isProcessableLoading = false
        draft.error = action.message
        break
      case types.UPDATE_ORDER_ITEMS_WITH_RELATIONSHIPS:
        const index = draft.itemsWithRelations.data.findIndex(itm => itm.id === action.payload.id)
        if (index !== -1) draft.itemsWithRelations.data[index] = { ...draft.itemsWithRelations.data[index], ...action.payload.data }
        break
      case types.DELETE_PROCESSABLE_ORDER:
        draft.processable.data = draft.processable.data.filter(item => !action.payload.includes(item.id));
        break;
      case types.GET_ORDER_ITEMS_DETAILS_REQUESTED:
        draft.itemDetailsLoading = true
        break
      case types.GET_ORDER_ITEMS_DETAILS_SUCCESS:
        draft.itemDetails = action.items.data
        draft.itemDetailsLoading = false
        break
      case types.GET_ORDER_ITEMS_DETAILS_FAILED:
        draft.itemDetailsLoading = false
        draft.error = action.message
        break
      case types.GET_DISPUTE_ORDER_ITEMS_REQUESTED:
        draft.disputeItemsLoading = true
        break
      case types.GET_DISPUTE_ORDER_ITEMS_SUCCESS:
        draft.disputeItems = action.items
        draft.disputeItemsLoading = false
        break
      case types.GET_DISPUTE_ORDER_ITEMS_FAILED:
        draft.disputeItemsLoading = false
        draft.disputeItemsError = action.message
        break
      default:
        return state
    }
  })
}
