import produce from "immer";
import * as Types from 'app/actionTypes/couriers';

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
    modules: null,
    types: null,
  },
  packages: null,
  courier_id: null,
  packages_loading: false,
  loading: false,
  error: null
}

export default function transactions(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_COURIERS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_COURIERS_SUCCESS:
        draft.items = action.data
        draft.loading = false
        break
      case Types.GET_COURIERS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.UPDATE_COURIER:
        const index = draft.items.data.findIndex(trnx => trnx.id === action.payload.id)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data }
        break
      case Types.GET_COURIER_PACKAGES_REQUESTED:
        draft.packages_loading = true
        draft.packages = null
        draft.courier_id = action.payload
        break
      case Types.GET_COURIER_PACKAGES_SUCCESS:
        draft.packages = action.data
        draft.packages_loading = false
        break
      case Types.GET_COURIER_PACKAGES_FAILED:
        draft.packages_loading = false
        break
      default:
        return state
    }
  })
}
