export const GET_MOVEON_CATS_REQUESTED = 'GET_MOVEON_CATS_REQUESTED';
export const GET_MOVEON_CATS_SUCCESS = 'GET_MOVEON_CATS_SUCCESS';
export const GET_MOVEON_CATS_FAILED = 'GET_MOVEON_CATS_FAILED';
export const UPDATE_MOVEON_CATS = 'UPDATE_MOVEON_CATS';
export const RESET_MOVEON_CATS = 'RESET_MOVEON_CATS';

export const GET_MOVEON_COST_CALC_REQUESTED = 'GET_MOVEON_COST_CALC_REQUESTED';
export const GET_MOVEON_COST_CALC_SUCCESS = 'GET_MOVEON_COST_CALC_SUCCESS';
export const GET_MOVEON_COST_CALC_FAILED = 'GET_MOVEON_COST_CALC_FAILED';

export const GET_MVN_WAREHOUSE_REQUESTED = 'GET_MVN_WAREHOUSE_REQUESTED';
export const GET_MVN_WAREHOUSE_SUCCESS = 'GET_MVN_WAREHOUSE_SUCCESS';
export const GET_MVN_WAREHOUSE_FAILED = 'GET_MVN_WAREHOUSE_FAILED';
