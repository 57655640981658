import client from 'utils/api-client';
import Qs from "qs";

export const getLiveCart = (id) => {
  return client.get(`/shop-carts/${id}`);
};

export const addLiveCart = (id, item) => {
  return client.post(`/shop-carts/${id}/add/${item}`);
};

export const manualAddLiveCart = (id, item) => {
  return client.post(`/shop-carts/${id}/add-manually`, item);
};

export const deleteLiveCart = (id, itemId) => {
  return client.delete(`/shop-carts/${id}/remove`, {data: {id: itemId}});
};

export const storeProcessable = (data) => {
  return client.post(`/order-items/processable`, data);
};

export const getImportOrder = (filter = {}) => {
  return client.get('/shop-orders', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getImportOrderDetails = (id) => {
  return client.get(`/shop-orders/${id}`);
};

export const getPurchasedOrder = (filter = {}) => {
  return client.get('/item-purchase', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getPurchasedOrderDetails = (id) => {
  return client.get(`/item-purchase/${id}`);
};

export const importOrder = (data) => {
  return client.post(`/shop-accounts/import-orders`, data);
};
