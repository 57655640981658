import client from 'utils/api-client';
import Qs from 'qs';

export const getOrders = (filter = {}) => {
  return client.get('/orders', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getOrder = (orderId) => client.get(`/orders/${orderId}`);

export const updateOrder = (orderId, data) => client.put(`/orders/${orderId}`, data);

export const printInvoice = (orderId) => client.get(`/orders/${orderId}/print-invoice`, {
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/pdf'
  }
});

export const getShippingDetails = (id) => client.get(`/order-items/shipping/${id}`);

export const resolvedRefund = (data) => client.post(`/orders/resolve-refund`, data);

export const addItemOnExistingOrder = (id, data) => {
  return client.post(`/orders/${id}/add-item`, data)
}

export const createOrder = (data) => client.post('/orders', data);
