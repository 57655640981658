import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import rootSaga from './saga/index';

const sagaMiddleware = createSagaMiddleware();

const store = compose(
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
)(createStore)(rootReducer);

sagaMiddleware.run(rootSaga);

export default store;
