import produce from "immer";
import * as Types from 'app/actionTypes/shippingTags';

const initialState = {
  list: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null
}

export default function shippingTags(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_SHIPPING_TAGS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_SHIPPING_TAGS_SUCCESS:
        let list = {
          ...action.payload,
          data: action.payload?.data?.map(item => {
            return {
              ...item,
              key: item.id,
              label: item.name,
            }
          })
        }
        draft.list = list;
        draft.loading = false
        break
      case Types.GET_SHIPPING_TAGS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      default:
        return state
    }
  })
}
