export const categories = [
  {
    "categoryId": 1902,
    "children": [
      {
        "categoryId": 10001930,
        "children": [
          {
            "categoryId": 10001958,
            "children": [],
            "leaf": true,
            "name": "Accessories",
            "var": false
          },
          {
            "categoryId": 10001957,
            "children": [],
            "leaf": true,
            "name": "Backpacks",
            "var": false
          },
          {
            "categoryId": 10001956,
            "children": [],
            "leaf": true,
            "name": "Backpacks Trolley",
            "var": false
          },
          {
            "categoryId": 10001955,
            "children": [],
            "leaf": true,
            "name": "Bags",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Kids Bags",
        "var": false
      },
      {
        "categoryId": 10001928,
        "children": [
          {
            "categoryId": 10001947,
            "children": [
              {
                "categoryId": 10001990,
                "children": [],
                "leaf": true,
                "name": "Fashion backpacks",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Backpacks",
            "var": false
          },
          {
            "categoryId": 10001946,
            "children": [],
            "leaf": true,
            "name": "Business Bags",
            "var": true
          },
          {
            "categoryId": 10001948,
            "children": [],
            "leaf": true,
            "name": "Crossbody Bags",
            "var": true
          },
          {
            "categoryId": 10001945,
            "children": [],
            "leaf": true,
            "name": "Messenger Bags",
            "var": true
          },
          {
            "categoryId": 10001949,
            "children": [],
            "leaf": true,
            "name": "Tote Bags",
            "var": true
          },
          {
            "categoryId": 10001950,
            "children": [
              {
                "categoryId": 10001993,
                "children": [],
                "leaf": true,
                "name": "Card Holders",
                "var": true
              },
              {
                "categoryId": 10001992,
                "children": [],
                "leaf": true,
                "name": "Coin Holders & Pouches",
                "var": true
              },
              {
                "categoryId": 10001994,
                "children": [],
                "leaf": true,
                "name": "Key Holders",
                "var": true
              },
              {
                "categoryId": 10001995,
                "children": [],
                "leaf": true,
                "name": "Money Clips",
                "var": true
              },
              {
                "categoryId": 10001991,
                "children": [
                  {
                    "categoryId": 10002040,
                    "children": [],
                    "leaf": true,
                    "name": "Fashion Wallets",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Wallets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Wallets & Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Men Bags",
        "var": false
      },
      {
        "categoryId": 10001931,
        "children": [
          {
            "categoryId": 10001961,
            "children": [
              {
                "categoryId": 10002017,
                "children": [],
                "leaf": true,
                "name": "Briefcases",
                "var": false
              },
              {
                "categoryId": 10002020,
                "children": [],
                "leaf": true,
                "name": "Laptop Backpacks",
                "var": false
              },
              {
                "categoryId": 10002019,
                "children": [],
                "leaf": true,
                "name": "Laptop cases",
                "var": false
              },
              {
                "categoryId": 10002018,
                "children": [],
                "leaf": true,
                "name": "Messenger Bags",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Laptop Bags",
            "var": false
          },
          {
            "categoryId": 10001959,
            "children": [
              {
                "categoryId": 10001998,
                "children": [],
                "leaf": true,
                "name": "Kids Luggage",
                "var": false
              },
              {
                "categoryId": 10001997,
                "children": [],
                "leaf": true,
                "name": "Luggage Sets",
                "var": false
              },
              {
                "categoryId": 10001996,
                "children": [],
                "leaf": true,
                "name": "Suitcases",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Luggage",
            "var": false
          },
          {
            "categoryId": 10001960,
            "children": [
              {
                "categoryId": 10002015,
                "children": [],
                "leaf": true,
                "name": "Compression Bags",
                "var": false
              },
              {
                "categoryId": 10002013,
                "children": [],
                "leaf": true,
                "name": "Garment Bags",
                "var": false
              },
              {
                "categoryId": 10001999,
                "children": [],
                "leaf": true,
                "name": "Luggage Carts",
                "var": false
              },
              {
                "categoryId": 10002000,
                "children": [],
                "leaf": true,
                "name": "Luggage Locks",
                "var": false
              },
              {
                "categoryId": 10002001,
                "children": [],
                "leaf": true,
                "name": "Luggage Scales",
                "var": false
              },
              {
                "categoryId": 10002005,
                "children": [
                  {
                    "categoryId": 10002041,
                    "children": [],
                    "leaf": true,
                    "name": "Luggage Straps",
                    "var": false
                  },
                  {
                    "categoryId": 10002042,
                    "children": [],
                    "leaf": true,
                    "name": "Luggage Tags",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Luggage Straps & Tags",
                "var": false
              },
              {
                "categoryId": 10002004,
                "children": [],
                "leaf": true,
                "name": "Luggage protectors & covers",
                "var": false
              },
              {
                "categoryId": 10002010,
                "children": [],
                "leaf": true,
                "name": "Organizer Sets",
                "var": false
              },
              {
                "categoryId": 10002016,
                "children": [],
                "leaf": true,
                "name": "Other Packing Organizers",
                "var": false
              },
              {
                "categoryId": 10002008,
                "children": [],
                "leaf": true,
                "name": "Other Travel Accessories",
                "var": false
              },
              {
                "categoryId": 10002002,
                "children": [],
                "leaf": true,
                "name": "Passport Covers",
                "var": false
              },
              {
                "categoryId": 10002012,
                "children": [],
                "leaf": true,
                "name": "Shoe Bags",
                "var": false
              },
              {
                "categoryId": 10002009,
                "children": [],
                "leaf": true,
                "name": "Toiletries & Cosmetics Bags",
                "var": false
              },
              {
                "categoryId": 10002014,
                "children": [],
                "leaf": true,
                "name": "Travel Size Bottles & Containers",
                "var": false
              },
              {
                "categoryId": 10002003,
                "children": [],
                "leaf": true,
                "name": "Travel Wallets",
                "var": false
              },
              {
                "categoryId": 10002006,
                "children": [],
                "leaf": true,
                "name": "Travel adapters & Converters",
                "var": false
              },
              {
                "categoryId": 10002007,
                "children": [
                  {
                    "categoryId": 10002046,
                    "children": [],
                    "leaf": true,
                    "name": "Ear plugs",
                    "var": false
                  },
                  {
                    "categoryId": 10002045,
                    "children": [],
                    "leaf": true,
                    "name": "Eye masks",
                    "var": false
                  },
                  {
                    "categoryId": 10002044,
                    "children": [],
                    "leaf": true,
                    "name": "Travel pillows",
                    "var": false
                  },
                  {
                    "categoryId": 10002043,
                    "children": [],
                    "leaf": true,
                    "name": "Travel sets",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Travel pillows & eye masks",
                "var": false
              },
              {
                "categoryId": 10002011,
                "children": [],
                "leaf": true,
                "name": "Underwear Organizers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Travel Accessories",
            "var": false
          },
          {
            "categoryId": 10001962,
            "children": [
              {
                "categoryId": 10002023,
                "children": [],
                "leaf": true,
                "name": "Foldable & Drawstring bags",
                "var": false
              },
              {
                "categoryId": 10002022,
                "children": [],
                "leaf": true,
                "name": "Waist Packs",
                "var": false
              },
              {
                "categoryId": 10002021,
                "children": [],
                "leaf": true,
                "name": "Weekender bags",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Travel Bags",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Travel",
        "var": false
      },
      {
        "categoryId": 10001929,
        "children": [
          {
            "categoryId": 10001951,
            "children": [],
            "leaf": true,
            "name": "Backpacks",
            "var": true
          },
          {
            "categoryId": 10001953,
            "children": [],
            "leaf": true,
            "name": "Card Holders",
            "var": true
          },
          {
            "categoryId": 10001952,
            "children": [],
            "leaf": true,
            "name": "Coin Purses & Pouches",
            "var": true
          },
          {
            "categoryId": 10001954,
            "children": [],
            "leaf": true,
            "name": "Key Holders",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Unisex Bags",
        "var": false
      },
      {
        "categoryId": 10001927,
        "children": [
          {
            "categoryId": 10001942,
            "children": [],
            "leaf": true,
            "name": "Backpacks",
            "var": true
          },
          {
            "categoryId": 10001941,
            "children": [],
            "leaf": true,
            "name": "Clutches",
            "var": true
          },
          {
            "categoryId": 10001939,
            "children": [],
            "leaf": true,
            "name": "Cross Body & Shoulder Bags",
            "var": true
          },
          {
            "categoryId": 10001940,
            "children": [],
            "leaf": true,
            "name": "Top-Handle Bags",
            "var": true
          },
          {
            "categoryId": 10001938,
            "children": [],
            "leaf": true,
            "name": "Tote Bags",
            "var": true
          },
          {
            "categoryId": 10001944,
            "children": [
              {
                "categoryId": 10001985,
                "children": [],
                "leaf": true,
                "name": "Bag Charms & Accessories",
                "var": true
              },
              {
                "categoryId": 10001988,
                "children": [],
                "leaf": true,
                "name": "Card Holders",
                "var": true
              },
              {
                "categoryId": 10001987,
                "children": [],
                "leaf": true,
                "name": "Coin Purses & Pouches",
                "var": true
              },
              {
                "categoryId": 10001989,
                "children": [],
                "leaf": true,
                "name": "Key Holders",
                "var": true
              },
              {
                "categoryId": 10001986,
                "children": [],
                "leaf": true,
                "name": "Wallets",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Wallets & Accessories",
            "var": false
          },
          {
            "categoryId": 10001943,
            "children": [],
            "leaf": true,
            "name": "Wristlets",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Women Bags",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Bags and Travel",
    "var": false
  },
  {
    "categoryId": 10000335,
    "children": [
      {
        "categoryId": 10000341,
        "children": [
          {
            "categoryId": 10000391,
            "children": [],
            "leaf": true,
            "name": "Bath Mats",
            "var": false
          },
          {
            "categoryId": 10000390,
            "children": [],
            "leaf": true,
            "name": "Bath Towels",
            "var": false
          },
          {
            "categoryId": 10000392,
            "children": [],
            "leaf": true,
            "name": "Bathrobes",
            "var": false
          },
          {
            "categoryId": 10000381,
            "children": [],
            "leaf": true,
            "name": "Bathroom Counter Storage",
            "var": false
          },
          {
            "categoryId": 10000382,
            "children": [],
            "leaf": true,
            "name": "Bathroom Mirrors",
            "var": false
          },
          {
            "categoryId": 10000384,
            "children": [],
            "leaf": true,
            "name": "Bathroom Scales",
            "var": false
          },
          {
            "categoryId": 10000394,
            "children": [],
            "leaf": true,
            "name": "Bathroom Shelving",
            "var": false
          },
          {
            "categoryId": 10000393,
            "children": [],
            "leaf": true,
            "name": "Linen Towers & Cabinets",
            "var": false
          },
          {
            "categoryId": 10000389,
            "children": [],
            "leaf": true,
            "name": "Shower Caddies & Hangers",
            "var": false
          },
          {
            "categoryId": 10000388,
            "children": [],
            "leaf": true,
            "name": "Shower Curtains",
            "var": false
          },
          {
            "categoryId": 10000383,
            "children": [],
            "leaf": true,
            "name": "Toilet Brushes",
            "var": false
          },
          {
            "categoryId": 10000387,
            "children": [],
            "leaf": true,
            "name": "Toilet Covers",
            "var": false
          },
          {
            "categoryId": 10000385,
            "children": [],
            "leaf": true,
            "name": "Toilet Roll Holders",
            "var": false
          },
          {
            "categoryId": 10000386,
            "children": [],
            "leaf": true,
            "name": "Towel Rails & Warmers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Bath",
        "var": false
      },
      {
        "categoryId": 10000342,
        "children": [
          {
            "categoryId": 10000401,
            "children": [],
            "leaf": true,
            "name": "Bed Runners & Skirts",
            "var": false
          },
          {
            "categoryId": 10000404,
            "children": [],
            "leaf": true,
            "name": "Bed Sheets",
            "var": false
          },
          {
            "categoryId": 10000402,
            "children": [],
            "leaf": true,
            "name": "Bedding Accessories",
            "var": false
          },
          {
            "categoryId": 10000403,
            "children": [],
            "leaf": true,
            "name": "Bedding Sets",
            "var": false
          },
          {
            "categoryId": 10000395,
            "children": [],
            "leaf": true,
            "name": "Blankets & Throws",
            "var": false
          },
          {
            "categoryId": 10000396,
            "children": [],
            "leaf": true,
            "name": "Comforters, Quilts & Duvets",
            "var": false
          },
          {
            "categoryId": 10000397,
            "children": [],
            "leaf": true,
            "name": "Electric Blankets",
            "var": false
          },
          {
            "categoryId": 10000405,
            "children": [],
            "leaf": true,
            "name": "Mattress Pads",
            "var": false
          },
          {
            "categoryId": 10000406,
            "children": [],
            "leaf": true,
            "name": "Mattress Protectors",
            "var": false
          },
          {
            "categoryId": 10000399,
            "children": [],
            "leaf": true,
            "name": "Pillow Cases",
            "var": false
          },
          {
            "categoryId": 10000400,
            "children": [],
            "leaf": true,
            "name": "Pillow Protectors",
            "var": false
          },
          {
            "categoryId": 10000398,
            "children": [],
            "leaf": true,
            "name": "Pillows & Bolsters",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Bedding",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Bedding & Bath",
    "var": false
  },
  {
    "categoryId": 240,
    "children": [
      {
        "categoryId": 242,
        "children": [],
        "leaf": true,
        "name": "Bridge",
        "var": false
      },
      {
        "categoryId": 256,
        "children": [
          {
            "categoryId": 257,
            "children": [],
            "leaf": true,
            "name": "Batteries",
            "var": false
          },
          {
            "categoryId": 258,
            "children": [],
            "leaf": true,
            "name": "Battery Chargers",
            "var": false
          },
          {
            "categoryId": 260,
            "children": [],
            "leaf": true,
            "name": "Camera Cases Covers and Bags",
            "var": false
          },
          {
            "categoryId": 261,
            "children": [],
            "leaf": true,
            "name": "Camera Remote Controls",
            "var": false
          },
          {
            "categoryId": 8204,
            "children": [],
            "leaf": true,
            "name": "Camera Screen Protector",
            "var": false
          },
          {
            "categoryId": 5878,
            "children": [],
            "leaf": true,
            "name": "Compact Photo Printers",
            "var": false
          },
          {
            "categoryId": 262,
            "children": [],
            "leaf": true,
            "name": "Dry Box",
            "var": false
          },
          {
            "categoryId": 274,
            "children": [],
            "leaf": true,
            "name": "Films",
            "var": false
          },
          {
            "categoryId": 264,
            "children": [],
            "leaf": true,
            "name": "Flashes",
            "var": false
          },
          {
            "categoryId": 10002900,
            "children": [],
            "leaf": true,
            "name": "Gimbals & Stabilizers",
            "var": false
          },
          {
            "categoryId": 10002901,
            "children": [],
            "leaf": true,
            "name": "Gimbals & Stabilizers Accessories",
            "var": false
          },
          {
            "categoryId": 269,
            "children": [
              {
                "categoryId": 9583,
                "children": [],
                "leaf": true,
                "name": "Light Meters & Color Calibrators",
                "var": false
              },
              {
                "categoryId": 9585,
                "children": [],
                "leaf": true,
                "name": "Photography & Studio Lighting",
                "var": false
              },
              {
                "categoryId": 9584,
                "children": [],
                "leaf": true,
                "name": "Studio Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Lighting & Studio Equipment",
            "var": false
          },
          {
            "categoryId": 270,
            "children": [
              {
                "categoryId": 9582,
                "children": [],
                "leaf": true,
                "name": "Card Readers",
                "var": false
              },
              {
                "categoryId": 8198,
                "children": [],
                "leaf": true,
                "name": "Compact Flash Cards",
                "var": false
              },
              {
                "categoryId": 8199,
                "children": [],
                "leaf": true,
                "name": "Micro SD Cards",
                "var": false
              },
              {
                "categoryId": 8197,
                "children": [],
                "leaf": true,
                "name": "SD & SDHC Cards",
                "var": false
              },
              {
                "categoryId": 9581,
                "children": [],
                "leaf": true,
                "name": "Wifi SD Cards",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Memory Cards",
            "var": false
          },
          {
            "categoryId": 8203,
            "children": [
              {
                "categoryId": 9586,
                "children": [],
                "leaf": true,
                "name": "Accessory Kits",
                "var": false
              },
              {
                "categoryId": 9589,
                "children": [],
                "leaf": true,
                "name": "Action Camera Bags & Cases",
                "var": false
              },
              {
                "categoryId": 9587,
                "children": [],
                "leaf": true,
                "name": "Mounts",
                "var": false
              },
              {
                "categoryId": 9592,
                "children": [],
                "leaf": true,
                "name": "Other Action Camera Accessories",
                "var": false
              },
              {
                "categoryId": 9588,
                "children": [],
                "leaf": true,
                "name": "Protective Lens",
                "var": false
              },
              {
                "categoryId": 9590,
                "children": [],
                "leaf": true,
                "name": "Straps & Harnesses",
                "var": false
              },
              {
                "categoryId": 9591,
                "children": [],
                "leaf": true,
                "name": "Waterproof Cases & Housing",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Sports & Action Camera Accessories",
            "var": false
          },
          {
            "categoryId": 272,
            "children": [],
            "leaf": true,
            "name": "Straps",
            "var": false
          },
          {
            "categoryId": 8200,
            "children": [
              {
                "categoryId": 8201,
                "children": [],
                "leaf": true,
                "name": "Monopods",
                "var": false
              },
              {
                "categoryId": 8202,
                "children": [],
                "leaf": true,
                "name": "Tripods",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Tripods & Monopods",
            "var": false
          },
          {
            "categoryId": 10002899,
            "children": [],
            "leaf": true,
            "name": "Viewfinders",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Camera Accessories",
        "var": false
      },
      {
        "categoryId": 10002952,
        "children": [],
        "leaf": true,
        "name": "Car Cameras",
        "var": false
      },
      {
        "categoryId": 243,
        "children": [
          {
            "categoryId": 5884,
            "children": [],
            "leaf": true,
            "name": "Body Only",
            "var": false
          },
          {
            "categoryId": 5883,
            "children": [],
            "leaf": true,
            "name": "Sets",
            "var": false
          }
        ],
        "leaf": false,
        "name": "DSLR",
        "var": false
      },
      {
        "categoryId": 9607,
        "children": [
          {
            "categoryId": 9609,
            "children": [
              {
                "categoryId": 10002945,
                "children": [],
                "leaf": true,
                "name": "Drone Bags and Cases",
                "var": false
              },
              {
                "categoryId": 10002946,
                "children": [],
                "leaf": true,
                "name": "Drone Batteries",
                "var": false
              },
              {
                "categoryId": 10002948,
                "children": [],
                "leaf": true,
                "name": "Propellers & Parts",
                "var": false
              },
              {
                "categoryId": 10002947,
                "children": [],
                "leaf": true,
                "name": "Remote Controllers & Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Drone Accessories",
            "var": false
          },
          {
            "categoryId": 9608,
            "children": [],
            "leaf": true,
            "name": "Drones",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Drones",
        "var": false
      },
      {
        "categoryId": 246,
        "children": [
          {
            "categoryId": 254,
            "children": [],
            "leaf": true,
            "name": "Lomography",
            "var": false
          },
          {
            "categoryId": 253,
            "children": [],
            "leaf": true,
            "name": "Mini Cameras",
            "var": false
          },
          {
            "categoryId": 3906,
            "children": [],
            "leaf": true,
            "name": "Spy Cameras",
            "var": false
          },
          {
            "categoryId": 251,
            "children": [],
            "leaf": true,
            "name": "Toy Cameras",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Gadgets & Other Cameras",
        "var": false
      },
      {
        "categoryId": 6135,
        "children": [],
        "leaf": false,
        "name": "Gopro Model",
        "var": false
      },
      {
        "categoryId": 244,
        "children": [
          {
            "categoryId": 9595,
            "children": [],
            "leaf": true,
            "name": "Instant Camera Accessories",
            "var": false
          },
          {
            "categoryId": 9594,
            "children": [],
            "leaf": true,
            "name": "Instant Camera Films",
            "var": false
          },
          {
            "categoryId": 9593,
            "children": [],
            "leaf": true,
            "name": "Instant Cameras",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Instant Camera",
        "var": false
      },
      {
        "categoryId": 8217,
        "children": [
          {
            "categoryId": 8218,
            "children": [],
            "leaf": true,
            "name": "DSLRs Lenses",
            "var": false
          },
          {
            "categoryId": 8220,
            "children": [
              {
                "categoryId": 9605,
                "children": [],
                "leaf": true,
                "name": "Extension Tubes",
                "var": false
              },
              {
                "categoryId": 8224,
                "children": [],
                "leaf": true,
                "name": "Filters",
                "var": false
              },
              {
                "categoryId": 9603,
                "children": [],
                "leaf": true,
                "name": "Follow - Focus Levers",
                "var": false
              },
              {
                "categoryId": 9602,
                "children": [],
                "leaf": true,
                "name": "Lens Adapters & Converters",
                "var": false
              },
              {
                "categoryId": 8223,
                "children": [],
                "leaf": true,
                "name": "Lens Caps",
                "var": false
              },
              {
                "categoryId": 9601,
                "children": [],
                "leaf": true,
                "name": "Lens Cases",
                "var": false
              },
              {
                "categoryId": 8222,
                "children": [],
                "leaf": true,
                "name": "Lens Cleaners",
                "var": false
              },
              {
                "categoryId": 8221,
                "children": [],
                "leaf": true,
                "name": "Lens Hoods",
                "var": false
              },
              {
                "categoryId": 9604,
                "children": [],
                "leaf": true,
                "name": "Matte Boxes",
                "var": false
              },
              {
                "categoryId": 9606,
                "children": [],
                "leaf": true,
                "name": "Other Lens Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Lens Accessories",
            "var": false
          },
          {
            "categoryId": 8219,
            "children": [],
            "leaf": true,
            "name": "Mirrorless Lenses",
            "var": false
          },
          {
            "categoryId": 8228,
            "children": [],
            "leaf": true,
            "name": "Other Lenses",
            "var": false
          },
          {
            "categoryId": 9600,
            "children": [],
            "leaf": true,
            "name": "Smartphone Lenses",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Lenses",
        "var": false
      },
      {
        "categoryId": 245,
        "children": [],
        "leaf": true,
        "name": "Mirrorless",
        "var": false
      },
      {
        "categoryId": 8213,
        "children": [
          {
            "categoryId": 8216,
            "children": [],
            "leaf": true,
            "name": "Binoculars",
            "var": false
          },
          {
            "categoryId": 8214,
            "children": [],
            "leaf": true,
            "name": "Monoculars",
            "var": false
          },
          {
            "categoryId": 9599,
            "children": [],
            "leaf": true,
            "name": "Optics Accessories",
            "var": false
          },
          {
            "categoryId": 8215,
            "children": [],
            "leaf": true,
            "name": "Telescopes",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Optics",
        "var": false
      },
      {
        "categoryId": 241,
        "children": [
          {
            "categoryId": 8207,
            "children": [],
            "leaf": true,
            "name": "Point & Shoot",
            "var": false
          },
          {
            "categoryId": 8208,
            "children": [],
            "leaf": true,
            "name": "Underwater Digital Cameras",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Point & Shoot",
        "var": false
      },
      {
        "categoryId": 10002953,
        "children": [
          {
            "categoryId": 10002904,
            "children": [],
            "leaf": true,
            "name": "CCTV Security Cameras",
            "var": false
          },
          {
            "categoryId": 10002906,
            "children": [],
            "leaf": true,
            "name": "CCTV Security Systems",
            "var": false
          },
          {
            "categoryId": 10002949,
            "children": [],
            "leaf": true,
            "name": "Dummy Cameras",
            "var": false
          },
          {
            "categoryId": 10002903,
            "children": [],
            "leaf": true,
            "name": "IP Security Cameras",
            "var": false
          },
          {
            "categoryId": 10002905,
            "children": [],
            "leaf": true,
            "name": "IP Security Systems",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Security Cameras & Systems",
        "var": false
      },
      {
        "categoryId": 255,
        "children": [
          {
            "categoryId": 10002902,
            "children": [],
            "leaf": true,
            "name": "360 Cameras",
            "var": false
          },
          {
            "categoryId": 8205,
            "children": [],
            "leaf": true,
            "name": "Professional Video Camera",
            "var": false
          },
          {
            "categoryId": 5880,
            "children": [],
            "leaf": true,
            "name": "Sports & Action Camera",
            "var": false
          },
          {
            "categoryId": 8206,
            "children": [],
            "leaf": false,
            "name": "Sports & Action Camera Accessories",
            "var": false
          },
          {
            "categoryId": 5882,
            "children": [],
            "leaf": true,
            "name": "Video Camera",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Video & Action Camcorder",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Cameras",
    "var": false
  },
  {
    "categoryId": 10003104,
    "children": [
      {
        "categoryId": 20000019,
        "children": [],
        "leaf": true,
        "name": "Campaign",
        "var": false
      },
      {
        "categoryId": 10003107,
        "children": [],
        "leaf": true,
        "name": "Donate to Educate",
        "var": false
      },
      {
        "categoryId": 10003105,
        "children": [],
        "leaf": true,
        "name": "Donate to Healthcare",
        "var": false
      },
      {
        "categoryId": 10003106,
        "children": [],
        "leaf": true,
        "name": "Donate to Shelter",
        "var": false
      },
      {
        "categoryId": 10003108,
        "children": [],
        "leaf": true,
        "name": "Others",
        "var": false
      },
      {
        "categoryId": 20000014,
        "children": [],
        "leaf": true,
        "name": "Zakat",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Charity and Donation",
    "var": false
  },
  {
    "categoryId": 54,
    "children": [
      {
        "categoryId": 5890,
        "children": [],
        "leaf": true,
        "name": "Clearance",
        "var": false
      },
      {
        "categoryId": 78,
        "children": [
          {
            "categoryId": 10451,
            "children": [
              {
                "categoryId": 10517,
                "children": [],
                "leaf": true,
                "name": "Cable Tester",
                "var": false
              },
              {
                "categoryId": 10496,
                "children": [],
                "leaf": true,
                "name": "DVI Cables",
                "var": false
              },
              {
                "categoryId": 10480,
                "children": [],
                "leaf": true,
                "name": "Ethernet Cables",
                "var": false
              },
              {
                "categoryId": 10484,
                "children": [],
                "leaf": true,
                "name": "Firewire Cables",
                "var": false
              },
              {
                "categoryId": 10516,
                "children": [],
                "leaf": true,
                "name": "IDE Ribbon Cables",
                "var": false
              },
              {
                "categoryId": 10463,
                "children": [],
                "leaf": true,
                "name": "Mini Display-Ethernet Adapters",
                "var": false
              },
              {
                "categoryId": 10467,
                "children": [],
                "leaf": true,
                "name": "Mini Display-Video Adapters",
                "var": false
              },
              {
                "categoryId": 10503,
                "children": [],
                "leaf": true,
                "name": "Mini-SAS Cables",
                "var": false
              },
              {
                "categoryId": 10500,
                "children": [],
                "leaf": true,
                "name": "PS/2 Cables",
                "var": false
              },
              {
                "categoryId": 10507,
                "children": [],
                "leaf": true,
                "name": "Parallel Cables",
                "var": false
              },
              {
                "categoryId": 10511,
                "children": [],
                "leaf": true,
                "name": "SATA Cables",
                "var": false
              },
              {
                "categoryId": 10506,
                "children": [],
                "leaf": true,
                "name": "SCSI Cables",
                "var": false
              },
              {
                "categoryId": 10509,
                "children": [],
                "leaf": true,
                "name": "Serial Cables",
                "var": false
              },
              {
                "categoryId": 10488,
                "children": [],
                "leaf": true,
                "name": "ThunderBolt Cables",
                "var": false
              },
              {
                "categoryId": 10471,
                "children": [],
                "leaf": true,
                "name": "Type C-Ethernet Adapters",
                "var": false
              },
              {
                "categoryId": 10474,
                "children": [],
                "leaf": true,
                "name": "Type C-Video Adapters",
                "var": false
              },
              {
                "categoryId": 10455,
                "children": [],
                "leaf": true,
                "name": "USB-Ethernet Adapters",
                "var": false
              },
              {
                "categoryId": 10459,
                "children": [],
                "leaf": true,
                "name": "USB-Video Adapters",
                "var": false
              },
              {
                "categoryId": 10492,
                "children": [],
                "leaf": true,
                "name": "VGA Cables",
                "var": false
              },
              {
                "categoryId": 10512,
                "children": [],
                "leaf": true,
                "name": "eSATA Cables",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Adapters & Cables",
            "var": false
          },
          {
            "categoryId": 88,
            "children": [],
            "leaf": true,
            "name": "Blank Media",
            "var": false
          },
          {
            "categoryId": 10002885,
            "children": [],
            "leaf": true,
            "name": "Bluetooth Adapters",
            "var": false
          },
          {
            "categoryId": 10002886,
            "children": [],
            "leaf": true,
            "name": "Card Reader",
            "var": false
          },
          {
            "categoryId": 89,
            "children": [],
            "leaf": true,
            "name": "Cooling PadsCooling Stands",
            "var": false
          },
          {
            "categoryId": 10432,
            "children": [
              {
                "categoryId": 10446,
                "children": [],
                "leaf": true,
                "name": "Display Screen Protectors",
                "var": false
              },
              {
                "categoryId": 10436,
                "children": [],
                "leaf": true,
                "name": "Drawing Display",
                "var": false
              },
              {
                "categoryId": 10440,
                "children": [],
                "leaf": true,
                "name": "Drawing Pad",
                "var": false
              },
              {
                "categoryId": 10444,
                "children": [],
                "leaf": true,
                "name": "Drawing Stylus",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Drawing Tools",
            "var": false
          },
          {
            "categoryId": 90,
            "children": [],
            "leaf": true,
            "name": "External DVD Writers",
            "var": false
          },
          {
            "categoryId": 10002884,
            "children": [],
            "leaf": true,
            "name": "Fingerprint Reader",
            "var": false
          },
          {
            "categoryId": 79,
            "children": [],
            "leaf": true,
            "name": "Gadgets",
            "var": false
          },
          {
            "categoryId": 7845,
            "children": [],
            "leaf": true,
            "name": "Gaming Headphones",
            "var": false
          },
          {
            "categoryId": 98,
            "children": [
              {
                "categoryId": 7846,
                "children": [],
                "leaf": true,
                "name": "Basic",
                "var": false
              },
              {
                "categoryId": 7847,
                "children": [],
                "leaf": true,
                "name": "Gaming",
                "var": false
              },
              {
                "categoryId": 10002911,
                "children": [],
                "leaf": true,
                "name": "Keyboard Accessories",
                "var": false
              },
              {
                "categoryId": 10002910,
                "children": [],
                "leaf": true,
                "name": "Mice & Keyboard Combos",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Keyboards",
            "var": false
          },
          {
            "categoryId": 87,
            "children": [],
            "leaf": true,
            "name": "Laptop Batteries",
            "var": false
          },
          {
            "categoryId": 80,
            "children": [],
            "leaf": false,
            "name": "Laptop Screen Filters",
            "var": false
          },
          {
            "categoryId": 99,
            "children": [],
            "leaf": true,
            "name": "Laptop stands",
            "var": false
          },
          {
            "categoryId": 10428,
            "children": [
              {
                "categoryId": 10449,
                "children": [],
                "leaf": true,
                "name": "Airport Routers",
                "var": false
              },
              {
                "categoryId": 10477,
                "children": [],
                "leaf": true,
                "name": "Firewire Cables",
                "var": false
              },
              {
                "categoryId": 10431,
                "children": [],
                "leaf": true,
                "name": "Hard Covers",
                "var": false
              },
              {
                "categoryId": 10438,
                "children": [],
                "leaf": true,
                "name": "Power Adapters",
                "var": false
              },
              {
                "categoryId": 10442,
                "children": [],
                "leaf": true,
                "name": "Screen Filters",
                "var": false
              },
              {
                "categoryId": 10435,
                "children": [],
                "leaf": true,
                "name": "Skin & Decal Stickers",
                "var": false
              },
              {
                "categoryId": 10445,
                "children": [],
                "leaf": true,
                "name": "Storage for Mac",
                "var": false
              },
              {
                "categoryId": 10481,
                "children": [],
                "leaf": true,
                "name": "ThunderBolt Cables",
                "var": false
              },
              {
                "categoryId": 10469,
                "children": [],
                "leaf": true,
                "name": "Type C-Ethernet Adapters",
                "var": false
              },
              {
                "categoryId": 10473,
                "children": [],
                "leaf": true,
                "name": "Type C-Video Adapters",
                "var": false
              },
              {
                "categoryId": 10453,
                "children": [],
                "leaf": true,
                "name": "USB-Ethernet Adapters",
                "var": false
              },
              {
                "categoryId": 10457,
                "children": [],
                "leaf": true,
                "name": "USB-Video Adapters",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Mac Accessories",
            "var": false
          },
          {
            "categoryId": 101,
            "children": [
              {
                "categoryId": 7848,
                "children": [],
                "leaf": true,
                "name": "Basic",
                "var": false
              },
              {
                "categoryId": 7849,
                "children": [],
                "leaf": true,
                "name": "Gaming",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Mice",
            "var": false
          },
          {
            "categoryId": 10002882,
            "children": [],
            "leaf": true,
            "name": "Monitor Screen Filters",
            "var": false
          },
          {
            "categoryId": 10002881,
            "children": [],
            "leaf": true,
            "name": "Monitor Stands",
            "var": false
          },
          {
            "categoryId": 102,
            "children": [],
            "leaf": true,
            "name": "Monitors",
            "var": false
          },
          {
            "categoryId": 7850,
            "children": [
              {
                "categoryId": 7851,
                "children": [],
                "leaf": true,
                "name": "Basic",
                "var": false
              },
              {
                "categoryId": 7852,
                "children": [],
                "leaf": true,
                "name": "Gaming",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Mousepads",
            "var": false
          },
          {
            "categoryId": 10002880,
            "children": [
              {
                "categoryId": 10002907,
                "children": [],
                "leaf": true,
                "name": "Gaming Headsets",
                "var": false
              },
              {
                "categoryId": 10002908,
                "children": [],
                "leaf": true,
                "name": "PC Soundbars",
                "var": false
              },
              {
                "categoryId": 10002909,
                "children": [],
                "leaf": true,
                "name": "PC Speaker Systems",
                "var": false
              }
            ],
            "leaf": false,
            "name": "PC Audio",
            "var": false
          },
          {
            "categoryId": 81,
            "children": [],
            "leaf": true,
            "name": "Power Cord & Adaptors",
            "var": false
          },
          {
            "categoryId": 104,
            "children": [],
            "leaf": true,
            "name": "Speakers",
            "var": false
          },
          {
            "categoryId": 5646,
            "children": [],
            "leaf": true,
            "name": "Surge Protector",
            "var": false
          },
          {
            "categoryId": 105,
            "children": [],
            "leaf": true,
            "name": "TV Tuners",
            "var": false
          },
          {
            "categoryId": 10002888,
            "children": [],
            "leaf": true,
            "name": "USB Fans",
            "var": false
          },
          {
            "categoryId": 10002883,
            "children": [],
            "leaf": true,
            "name": "USB Hubs",
            "var": false
          },
          {
            "categoryId": 10002887,
            "children": [],
            "leaf": true,
            "name": "USB Lighting",
            "var": false
          },
          {
            "categoryId": 10002879,
            "children": [],
            "leaf": true,
            "name": "Uninterrupted Power Supply",
            "var": false
          },
          {
            "categoryId": 106,
            "children": [],
            "leaf": true,
            "name": "Webcams",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Computer Accessories",
        "var": false
      },
      {
        "categoryId": 107,
        "children": [
          {
            "categoryId": 108,
            "children": [],
            "leaf": true,
            "name": "Desktop Casings",
            "var": false
          },
          {
            "categoryId": 110,
            "children": [
              {
                "categoryId": 111,
                "children": [],
                "leaf": true,
                "name": "Cooling Fans",
                "var": false
              },
              {
                "categoryId": 112,
                "children": [],
                "leaf": true,
                "name": "Heatsinks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Fans & Heatsinks",
            "var": false
          },
          {
            "categoryId": 115,
            "children": [],
            "leaf": true,
            "name": "Front Bay Devices",
            "var": false
          },
          {
            "categoryId": 113,
            "children": [],
            "leaf": true,
            "name": "Graphic Cards",
            "var": false
          },
          {
            "categoryId": 116,
            "children": [],
            "leaf": true,
            "name": "Motherboards",
            "var": false
          },
          {
            "categoryId": 117,
            "children": [],
            "leaf": true,
            "name": "Power Supply Units",
            "var": false
          },
          {
            "categoryId": 118,
            "children": [],
            "leaf": true,
            "name": "Processors",
            "var": false
          },
          {
            "categoryId": 119,
            "children": [],
            "leaf": true,
            "name": "RAM",
            "var": false
          },
          {
            "categoryId": 10002890,
            "children": [],
            "leaf": true,
            "name": "Single Board Computer",
            "var": false
          },
          {
            "categoryId": 7853,
            "children": [],
            "leaf": true,
            "name": "Sound cards",
            "var": false
          },
          {
            "categoryId": 120,
            "children": [],
            "leaf": true,
            "name": "TV Tuners",
            "var": false
          },
          {
            "categoryId": 10002889,
            "children": [],
            "leaf": true,
            "name": "Water Cooling System",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Computer Components",
        "var": false
      },
      {
        "categoryId": 61,
        "children": [
          {
            "categoryId": 5638,
            "children": [],
            "leaf": true,
            "name": "All-In-One",
            "var": false
          },
          {
            "categoryId": 5642,
            "children": [],
            "leaf": true,
            "name": "DIY",
            "var": false
          },
          {
            "categoryId": 5640,
            "children": [],
            "leaf": true,
            "name": "Gaming",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Desktops Computers",
        "var": false
      },
      {
        "categoryId": 55,
        "children": [
          {
            "categoryId": 7836,
            "children": [],
            "leaf": true,
            "name": "2-in-1s",
            "var": false
          },
          {
            "categoryId": 7833,
            "children": [
              {
                "categoryId": 7835,
                "children": [],
                "leaf": true,
                "name": "Non-touchscreen",
                "var": false
              },
              {
                "categoryId": 7834,
                "children": [],
                "leaf": true,
                "name": "Touchscreen",
                "var": false
              }
            ],
            "leaf": false,
            "name": "All-purpose",
            "var": false
          },
          {
            "categoryId": 60,
            "children": [],
            "leaf": true,
            "name": "Basic",
            "var": false
          },
          {
            "categoryId": 7901,
            "children": [],
            "leaf": true,
            "name": "Chromebooks",
            "var": false
          },
          {
            "categoryId": 58,
            "children": [],
            "leaf": true,
            "name": "Gaming",
            "var": false
          },
          {
            "categoryId": 7902,
            "children": [],
            "leaf": true,
            "name": "Macbooks",
            "var": false
          },
          {
            "categoryId": 57,
            "children": [],
            "leaf": true,
            "name": "Traditional Laptops",
            "var": false
          },
          {
            "categoryId": 56,
            "children": [
              {
                "categoryId": 7832,
                "children": [],
                "leaf": true,
                "name": "Non-touchscreen",
                "var": false
              },
              {
                "categoryId": 7831,
                "children": [],
                "leaf": true,
                "name": "Touchscreen",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Ultrabooks",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Laptops",
        "var": false
      },
      {
        "categoryId": 121,
        "children": [
          {
            "categoryId": 124,
            "children": [],
            "leaf": true,
            "name": "Access Points",
            "var": false
          },
          {
            "categoryId": 10498,
            "children": [],
            "leaf": true,
            "name": "Airport Routers",
            "var": false
          },
          {
            "categoryId": 5658,
            "children": [
              {
                "categoryId": 5660,
                "children": [],
                "leaf": true,
                "name": "Mobile Wi-Fi Hotspots",
                "var": false
              },
              {
                "categoryId": 5661,
                "children": [],
                "leaf": true,
                "name": "USB Modems",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Mobile Broadband",
            "var": false
          },
          {
            "categoryId": 7868,
            "children": [],
            "leaf": true,
            "name": "Modems",
            "var": false
          },
          {
            "categoryId": 7875,
            "children": [],
            "leaf": true,
            "name": "NAS",
            "var": false
          },
          {
            "categoryId": 122,
            "children": [],
            "leaf": true,
            "name": "Network Gadgets",
            "var": false
          },
          {
            "categoryId": 127,
            "children": [],
            "leaf": true,
            "name": "Network Interface Cards",
            "var": false
          },
          {
            "categoryId": 7870,
            "children": [
              {
                "categoryId": 7873,
                "children": [],
                "leaf": true,
                "name": "Bluetooth network adapters",
                "var": false
              },
              {
                "categoryId": 7871,
                "children": [],
                "leaf": true,
                "name": "Laptop Network adapters",
                "var": false
              },
              {
                "categoryId": 7874,
                "children": [],
                "leaf": true,
                "name": "Powerline network adapter",
                "var": false
              },
              {
                "categoryId": 7872,
                "children": [],
                "leaf": true,
                "name": "USB network adapters",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Network adaptors",
            "var": false
          },
          {
            "categoryId": 7869,
            "children": [],
            "leaf": true,
            "name": "Range extender",
            "var": false
          },
          {
            "categoryId": 128,
            "children": [],
            "leaf": true,
            "name": "Routers",
            "var": false
          },
          {
            "categoryId": 129,
            "children": [],
            "leaf": true,
            "name": "Switches",
            "var": false
          },
          {
            "categoryId": 130,
            "children": [],
            "leaf": true,
            "name": "Wireless USB Adapters",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Network Components",
        "var": false
      },
      {
        "categoryId": 7890,
        "children": [
          {
            "categoryId": 7897,
            "children": [],
            "leaf": true,
            "name": "PC Games",
            "var": false
          }
        ],
        "leaf": false,
        "name": "PC Gaming",
        "var": false
      },
      {
        "categoryId": 131,
        "children": [
          {
            "categoryId": 7882,
            "children": [
              {
                "categoryId": 7884,
                "children": [],
                "leaf": true,
                "name": "3D Printer Parts",
                "var": false
              },
              {
                "categoryId": 7883,
                "children": [],
                "leaf": true,
                "name": "3D Printers",
                "var": false
              },
              {
                "categoryId": 7885,
                "children": [],
                "leaf": true,
                "name": "3D Printing Materials",
                "var": false
              }
            ],
            "leaf": false,
            "name": "3D Printing",
            "var": false
          },
          {
            "categoryId": 5673,
            "children": [],
            "leaf": true,
            "name": "Fax machines",
            "var": false
          },
          {
            "categoryId": 7878,
            "children": [
              {
                "categoryId": 7879,
                "children": [],
                "leaf": true,
                "name": "Inkjets Inks",
                "var": false
              },
              {
                "categoryId": 7880,
                "children": [],
                "leaf": true,
                "name": "Laser Toners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Ink",
            "var": false
          },
          {
            "categoryId": 7886,
            "children": [],
            "leaf": true,
            "name": "Printer cutter",
            "var": false
          },
          {
            "categoryId": 7887,
            "children": [],
            "leaf": true,
            "name": "Printer memory modules",
            "var": false
          },
          {
            "categoryId": 136,
            "children": [],
            "leaf": true,
            "name": "Printer stands",
            "var": false
          },
          {
            "categoryId": 134,
            "children": [
              {
                "categoryId": 5668,
                "children": [],
                "leaf": true,
                "name": "Business",
                "var": false
              },
              {
                "categoryId": 5669,
                "children": [],
                "leaf": true,
                "name": "Dot matrix Printer",
                "var": false
              },
              {
                "categoryId": 5664,
                "children": [],
                "leaf": true,
                "name": "Ink Jet",
                "var": false
              },
              {
                "categoryId": 7881,
                "children": [],
                "leaf": true,
                "name": "Label Printer",
                "var": false
              },
              {
                "categoryId": 5662,
                "children": [],
                "leaf": true,
                "name": "Laser Jet",
                "var": false
              },
              {
                "categoryId": 5666,
                "children": [],
                "leaf": true,
                "name": "Photo",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Printers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Printers & Accessories",
        "var": false
      },
      {
        "categoryId": 20000178,
        "children": [
          {
            "categoryId": 20000180,
            "children": [],
            "leaf": true,
            "name": "Refurbished Laptops",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Refurbished",
        "var": false
      },
      {
        "categoryId": 141,
        "children": [],
        "leaf": true,
        "name": "Scanners",
        "var": false
      },
      {
        "categoryId": 142,
        "children": [
          {
            "categoryId": 143,
            "children": [],
            "leaf": true,
            "name": "Educational Media",
            "var": false
          },
          {
            "categoryId": 145,
            "children": [],
            "leaf": true,
            "name": "Operating System",
            "var": false
          },
          {
            "categoryId": 7888,
            "children": [],
            "leaf": true,
            "name": "PC Games",
            "var": false
          },
          {
            "categoryId": 144,
            "children": [],
            "leaf": true,
            "name": "Productivity",
            "var": false
          },
          {
            "categoryId": 146,
            "children": [],
            "leaf": true,
            "name": "Security Software",
            "var": false
          },
          {
            "categoryId": 10002891,
            "children": [],
            "leaf": true,
            "name": "VR Games",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Software",
        "var": false
      },
      {
        "categoryId": 5676,
        "children": [
          {
            "categoryId": 91,
            "children": [],
            "leaf": true,
            "name": "External Hard Drives",
            "var": false
          },
          {
            "categoryId": 10002893,
            "children": [],
            "leaf": true,
            "name": "External Solid State Drives",
            "var": false
          },
          {
            "categoryId": 10490,
            "children": [],
            "leaf": true,
            "name": "Flash Drives",
            "var": false
          },
          {
            "categoryId": 114,
            "children": [],
            "leaf": true,
            "name": "Internal Hard Drives",
            "var": false
          },
          {
            "categoryId": 10002892,
            "children": [],
            "leaf": true,
            "name": "Internal Solid State Drives",
            "var": false
          },
          {
            "categoryId": 100,
            "children": [],
            "leaf": true,
            "name": "Memory Cards",
            "var": false
          },
          {
            "categoryId": 7889,
            "children": [],
            "leaf": true,
            "name": "NAS",
            "var": false
          },
          {
            "categoryId": 10486,
            "children": [],
            "leaf": true,
            "name": "OTG Drives",
            "var": false
          },
          {
            "categoryId": 5682,
            "children": [],
            "leaf": true,
            "name": "Solid State Drives",
            "var": false
          },
          {
            "categoryId": 10494,
            "children": [],
            "leaf": true,
            "name": "Storage for Mac",
            "var": false
          },
          {
            "categoryId": 103,
            "children": [],
            "leaf": true,
            "name": "USB Flash Drives",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Storage",
        "var": false
      },
      {
        "categoryId": 20000179,
        "children": [
          {
            "categoryId": 20000187,
            "children": [],
            "leaf": true,
            "name": "Used Desktops",
            "var": false
          },
          {
            "categoryId": 20000189,
            "children": [],
            "leaf": true,
            "name": "Used Graphic Cards",
            "var": false
          },
          {
            "categoryId": 20000185,
            "children": [],
            "leaf": true,
            "name": "Used Laptops",
            "var": false
          },
          {
            "categoryId": 20000186,
            "children": [],
            "leaf": true,
            "name": "Used Monitors",
            "var": false
          },
          {
            "categoryId": 20000188,
            "children": [],
            "leaf": true,
            "name": "Used Printers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Used",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Computers & Laptops",
    "var": false
  },
  {
    "categoryId": 6684,
    "children": [
      {
        "categoryId": 6705,
        "children": [],
        "leaf": true,
        "name": "Daraz Gift Cards",
        "var": false
      },
      {
        "categoryId": 20000090,
        "children": [],
        "leaf": true,
        "name": "Education",
        "var": true
      },
      {
        "categoryId": 20000091,
        "children": [],
        "leaf": true,
        "name": "Fuel",
        "var": true
      },
      {
        "categoryId": 10002682,
        "children": [
          {
            "categoryId": 10002736,
            "children": [
              {
                "categoryId": 10002814,
                "children": [],
                "leaf": true,
                "name": "Game Codes",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Digital Games",
            "var": true
          },
          {
            "categoryId": 10003096,
            "children": [],
            "leaf": true,
            "name": "Game Keys & Codes",
            "var": true
          },
          {
            "categoryId": 10003097,
            "children": [],
            "leaf": true,
            "name": "Gift Cards",
            "var": true
          },
          {
            "categoryId": 10002737,
            "children": [],
            "leaf": true,
            "name": "Softwares",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Games Gift Cards & Software",
        "var": true
      },
      {
        "categoryId": 10002683,
        "children": [
          {
            "categoryId": 10002739,
            "children": [],
            "leaf": true,
            "name": "Maintenance",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Home Services",
        "var": true
      },
      {
        "categoryId": 10002681,
        "children": [
          {
            "categoryId": 10003101,
            "children": [],
            "leaf": true,
            "name": "Activities & Entertainment",
            "var": true
          },
          {
            "categoryId": 10003100,
            "children": [],
            "leaf": true,
            "name": "Beauty & Wellness",
            "var": true
          },
          {
            "categoryId": 10002732,
            "children": [
              {
                "categoryId": 10002799,
                "children": [
                  {
                    "categoryId": 10002848,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002847,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Fitness",
                "var": true
              },
              {
                "categoryId": 10002801,
                "children": [],
                "leaf": true,
                "name": "Gift Cards",
                "var": true
              },
              {
                "categoryId": 10002798,
                "children": [
                  {
                    "categoryId": 10002846,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002845,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Massages",
                "var": true
              },
              {
                "categoryId": 10002797,
                "children": [
                  {
                    "categoryId": 10002844,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002843,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Medical Aesthetics",
                "var": true
              },
              {
                "categoryId": 10002795,
                "children": [
                  {
                    "categoryId": 10002840,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002839,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Salon",
                "var": true
              },
              {
                "categoryId": 10002796,
                "children": [
                  {
                    "categoryId": 10002842,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002841,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Skin Care",
                "var": true
              },
              {
                "categoryId": 10002800,
                "children": [],
                "leaf": true,
                "name": "eVouchers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Beauty and Wellness",
            "var": true
          },
          {
            "categoryId": 20000198,
            "children": [],
            "leaf": true,
            "name": "Charity",
            "var": true
          },
          {
            "categoryId": 10002731,
            "children": [
              {
                "categoryId": 10002791,
                "children": [
                  {
                    "categoryId": 10002836,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002835,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Coffee and Tea",
                "var": true
              },
              {
                "categoryId": 10002790,
                "children": [
                  {
                    "categoryId": 10002834,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002833,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Desserts and Snacks",
                "var": true
              },
              {
                "categoryId": 10002793,
                "children": [],
                "leaf": true,
                "name": "Gift Cards",
                "var": true
              },
              {
                "categoryId": 10002789,
                "children": [
                  {
                    "categoryId": 10002832,
                    "children": [],
                    "leaf": true,
                    "name": "Gift Cards",
                    "var": true
                  },
                  {
                    "categoryId": 10002831,
                    "children": [],
                    "leaf": true,
                    "name": "eVouchers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Meals",
                "var": true
              },
              {
                "categoryId": 10002792,
                "children": [],
                "leaf": false,
                "name": "Pubs, Bars & Breweries",
                "var": true
              },
              {
                "categoryId": 10002794,
                "children": [],
                "leaf": true,
                "name": "eVouchers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Food and Beverages",
            "var": true
          },
          {
            "categoryId": 20000023,
            "children": [],
            "leaf": true,
            "name": "Health & Fitness",
            "var": true
          },
          {
            "categoryId": 10003102,
            "children": [],
            "leaf": true,
            "name": "Hotel and Travel",
            "var": true
          },
          {
            "categoryId": 20000177,
            "children": [],
            "leaf": true,
            "name": "Linehaul Service",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Local Vouchers",
        "var": true
      },
      {
        "categoryId": 20000190,
        "children": [],
        "leaf": true,
        "name": "One Rupee Game",
        "var": true
      },
      {
        "categoryId": 10002966,
        "children": [
          {
            "categoryId": 20000016,
            "children": [],
            "leaf": true,
            "name": "Digital Advertising",
            "var": true
          },
          {
            "categoryId": 20000015,
            "children": [],
            "leaf": true,
            "name": "Photography & Editing",
            "var": true
          },
          {
            "categoryId": 20000017,
            "children": [
              {
                "categoryId": 20000018,
                "children": [],
                "leaf": true,
                "name": "Product Listing",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shop Management",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Seller Services",
        "var": true
      }
    ],
    "leaf": false,
    "name": "Digital Goods",
    "var": true
  },
  {
    "categoryId": 1819,
    "children": [
      {
        "categoryId": 6593,
        "children": [
          {
            "categoryId": 6612,
            "children": [
              {
                "categoryId": 6614,
                "children": [],
                "leaf": true,
                "name": "Belts",
                "var": true
              },
              {
                "categoryId": 6618,
                "children": [],
                "leaf": true,
                "name": "Gloves, Scarves & Cold Weather",
                "var": true
              },
              {
                "categoryId": 6615,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": true
              },
              {
                "categoryId": 6617,
                "children": [],
                "leaf": true,
                "name": "Others",
                "var": true
              },
              {
                "categoryId": 6616,
                "children": [],
                "leaf": true,
                "name": "Umbrellas & Rainwear",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": true
          },
          {
            "categoryId": 6619,
            "children": [
              {
                "categoryId": 10002987,
                "children": [],
                "leaf": true,
                "name": "Boys Kurtas",
                "var": true
              },
              {
                "categoryId": 9698,
                "children": [],
                "leaf": true,
                "name": "Hoodies",
                "var": true
              },
              {
                "categoryId": 6623,
                "children": [],
                "leaf": true,
                "name": "Jackets & Coats",
                "var": true
              },
              {
                "categoryId": 6621,
                "children": [],
                "leaf": true,
                "name": "Pants & Jeans",
                "var": true
              },
              {
                "categoryId": 7654,
                "children": [],
                "leaf": true,
                "name": "Shorts",
                "var": true
              },
              {
                "categoryId": 6627,
                "children": [],
                "leaf": true,
                "name": "Sleepwear",
                "var": true
              },
              {
                "categoryId": 6622,
                "children": [],
                "leaf": true,
                "name": "Sweaters & Cardigans",
                "var": true
              },
              {
                "categoryId": 9699,
                "children": [],
                "leaf": true,
                "name": "Swimwear",
                "var": true
              },
              {
                "categoryId": 6620,
                "children": [],
                "leaf": true,
                "name": "T-Shirts & Shirts",
                "var": true
              },
              {
                "categoryId": 6625,
                "children": [
                  {
                    "categoryId": 7653,
                    "children": [],
                    "leaf": true,
                    "name": "Socks",
                    "var": true
                  },
                  {
                    "categoryId": 7652,
                    "children": [],
                    "leaf": true,
                    "name": "Underwear",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Underwear & Socks",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Clothing",
            "var": true
          },
          {
            "categoryId": 6594,
            "children": [
              {
                "categoryId": 6599,
                "children": [],
                "leaf": true,
                "name": "Boots",
                "var": true
              },
              {
                "categoryId": 9697,
                "children": [],
                "leaf": true,
                "name": "Clogs & Mules",
                "var": true
              },
              {
                "categoryId": 6603,
                "children": [],
                "leaf": true,
                "name": "Flip Flops, Slides & Sandals",
                "var": true
              },
              {
                "categoryId": 6596,
                "children": [],
                "leaf": true,
                "name": "Formal Shoes",
                "var": true
              },
              {
                "categoryId": 6600,
                "children": [],
                "leaf": true,
                "name": "Shoes Accessories",
                "var": true
              },
              {
                "categoryId": 6597,
                "children": [],
                "leaf": true,
                "name": "Slip-Ons & Loafers",
                "var": true
              },
              {
                "categoryId": 6595,
                "children": [],
                "leaf": true,
                "name": "Sneakers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shoes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Boys",
        "var": true
      },
      {
        "categoryId": 6554,
        "children": [
          {
            "categoryId": 6574,
            "children": [
              {
                "categoryId": 6575,
                "children": [],
                "leaf": true,
                "name": "Belts",
                "var": true
              },
              {
                "categoryId": 6578,
                "children": [],
                "leaf": true,
                "name": "Gloves, Scarves & Cold Weather",
                "var": true
              },
              {
                "categoryId": 9695,
                "children": [],
                "leaf": true,
                "name": "Hair Accessories",
                "var": true
              },
              {
                "categoryId": 6576,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": true
              },
              {
                "categoryId": 6579,
                "children": [],
                "leaf": true,
                "name": "Others",
                "var": true
              },
              {
                "categoryId": 6577,
                "children": [],
                "leaf": true,
                "name": "Umbrellas & Rainwear",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": true
          },
          {
            "categoryId": 6580,
            "children": [
              {
                "categoryId": 6582,
                "children": [],
                "leaf": true,
                "name": "Bottoms",
                "var": true
              },
              {
                "categoryId": 6583,
                "children": [],
                "leaf": true,
                "name": "Dresses",
                "var": true
              },
              {
                "categoryId": 10001970,
                "children": [],
                "leaf": true,
                "name": "Hoodies",
                "var": true
              },
              {
                "categoryId": 6586,
                "children": [],
                "leaf": true,
                "name": "Jackets & Coats",
                "var": true
              },
              {
                "categoryId": 6588,
                "children": [],
                "leaf": true,
                "name": "Socks & Tights",
                "var": true
              },
              {
                "categoryId": 9696,
                "children": [],
                "leaf": true,
                "name": "Swimsuits",
                "var": true
              },
              {
                "categoryId": 6581,
                "children": [],
                "leaf": true,
                "name": "Tops",
                "var": true
              },
              {
                "categoryId": 6590,
                "children": [],
                "leaf": true,
                "name": "Underwear & Sleepwear",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Clothing",
            "var": true
          },
          {
            "categoryId": 6555,
            "children": [
              {
                "categoryId": 6561,
                "children": [],
                "leaf": true,
                "name": "Boots",
                "var": true
              },
              {
                "categoryId": 9694,
                "children": [],
                "leaf": true,
                "name": "Clogs & Mules",
                "var": true
              },
              {
                "categoryId": 6559,
                "children": [],
                "leaf": true,
                "name": "Flats & Slip-Ons",
                "var": true
              },
              {
                "categoryId": 6564,
                "children": [],
                "leaf": true,
                "name": "Flip Flops, Slides & Sandals",
                "var": true
              },
              {
                "categoryId": 6557,
                "children": [],
                "leaf": true,
                "name": "Formal Shoes",
                "var": true
              },
              {
                "categoryId": 6562,
                "children": [],
                "leaf": true,
                "name": "Shoes Accessories",
                "var": true
              },
              {
                "categoryId": 6556,
                "children": [],
                "leaf": true,
                "name": "Sneakers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shoes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Girls",
        "var": true
      },
      {
        "categoryId": 10001926,
        "children": [
          {
            "categoryId": 10001936,
            "children": [
              {
                "categoryId": 10001980,
                "children": [],
                "leaf": true,
                "name": "Belts",
                "var": true
              },
              {
                "categoryId": 10001982,
                "children": [],
                "leaf": true,
                "name": "Gloves, Scarves & Cold Weather",
                "var": true
              },
              {
                "categoryId": 10001981,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": true
              },
              {
                "categoryId": 10001983,
                "children": [],
                "leaf": true,
                "name": "Umbrellas & Rainwear",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": true
          },
          {
            "categoryId": 10001937,
            "children": [
              {
                "categoryId": 10001984,
                "children": [],
                "leaf": true,
                "name": "Hoodies",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Clothing",
            "var": true
          },
          {
            "categoryId": 10001935,
            "children": [
              {
                "categoryId": 10001979,
                "children": [],
                "leaf": true,
                "name": "Flip Flops Slides & Sandals",
                "var": true
              },
              {
                "categoryId": 10001978,
                "children": [],
                "leaf": true,
                "name": "Shoes Accessories",
                "var": true
              },
              {
                "categoryId": 10001977,
                "children": [],
                "leaf": true,
                "name": "Sneakers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shoes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Kids Unisex",
        "var": true
      },
      {
        "categoryId": 1854,
        "children": [
          {
            "categoryId": 1873,
            "children": [
              {
                "categoryId": 1885,
                "children": [],
                "leaf": true,
                "name": "Belts",
                "var": true
              },
              {
                "categoryId": 4155,
                "children": [],
                "leaf": true,
                "name": "Braces",
                "var": true
              },
              {
                "categoryId": 1879,
                "children": [],
                "leaf": false,
                "name": "Cufflinks",
                "var": true
              },
              {
                "categoryId": 4154,
                "children": [],
                "leaf": true,
                "name": "Gloves",
                "var": true
              },
              {
                "categoryId": 1886,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": true
              },
              {
                "categoryId": 1877,
                "children": [],
                "leaf": true,
                "name": "Scarves",
                "var": true
              },
              {
                "categoryId": 1874,
                "children": [
                  {
                    "categoryId": 1876,
                    "children": [],
                    "leaf": true,
                    "name": "Bow Ties",
                    "var": true
                  },
                  {
                    "categoryId": 1875,
                    "children": [],
                    "leaf": true,
                    "name": "Ties",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Ties & Bow Ties",
                "var": true
              },
              {
                "categoryId": 1878,
                "children": [],
                "leaf": true,
                "name": "Umbrellas",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": true
          },
          {
            "categoryId": 1782,
            "children": [
              {
                "categoryId": 1803,
                "children": [
                  {
                    "categoryId": 4195,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 9686,
                    "children": [],
                    "leaf": true,
                    "name": "Tanks",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Casual Tops",
                "var": true
              },
              {
                "categoryId": 9701,
                "children": [],
                "leaf": true,
                "name": "Hoodies & Sweatshirts",
                "var": true
              },
              {
                "categoryId": 1786,
                "children": [
                  {
                    "categoryId": 4272,
                    "children": [],
                    "leaf": true,
                    "name": "Bomber Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4314,
                    "children": [],
                    "leaf": true,
                    "name": "Coats",
                    "var": true
                  },
                  {
                    "categoryId": 4266,
                    "children": [],
                    "leaf": true,
                    "name": "Denim Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10002030,
                    "children": [],
                    "leaf": true,
                    "name": "Down Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4269,
                    "children": [],
                    "leaf": true,
                    "name": "Leather Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4277,
                    "children": [],
                    "leaf": true,
                    "name": "Lightweight Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4307,
                    "children": [],
                    "leaf": true,
                    "name": "Rain Coats & Trenches",
                    "var": true
                  },
                  {
                    "categoryId": 4299,
                    "children": [],
                    "leaf": true,
                    "name": "Winter Jackets & Coats",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Jackets & Coats",
                "var": true
              },
              {
                "categoryId": 1789,
                "children": [],
                "leaf": true,
                "name": "Jeans",
                "var": true
              },
              {
                "categoryId": 1800,
                "children": [
                  {
                    "categoryId": 4337,
                    "children": [],
                    "leaf": true,
                    "name": "Cargo",
                    "var": true
                  },
                  {
                    "categoryId": 1802,
                    "children": [],
                    "leaf": true,
                    "name": "Chinos",
                    "var": true
                  },
                  {
                    "categoryId": 9688,
                    "children": [],
                    "leaf": false,
                    "name": "Formal",
                    "var": true
                  },
                  {
                    "categoryId": 4341,
                    "children": [],
                    "leaf": true,
                    "name": "Joggers & Sweats",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Pants",
                "var": true
              },
              {
                "categoryId": 10001966,
                "children": [],
                "leaf": true,
                "name": "Polo Shirts",
                "var": true
              },
              {
                "categoryId": 4211,
                "children": [
                  {
                    "categoryId": 4213,
                    "children": [],
                    "leaf": true,
                    "name": "Casual Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 4214,
                    "children": [],
                    "leaf": true,
                    "name": "Formal Shirts",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shirts",
                "var": true
              },
              {
                "categoryId": 9689,
                "children": [
                  {
                    "categoryId": 9690,
                    "children": [],
                    "leaf": true,
                    "name": "Chino",
                    "var": true
                  },
                  {
                    "categoryId": 9691,
                    "children": [],
                    "leaf": true,
                    "name": "Denim",
                    "var": true
                  },
                  {
                    "categoryId": 9692,
                    "children": [],
                    "leaf": true,
                    "name": "Jersey",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shorts",
                "var": true
              },
              {
                "categoryId": 9693,
                "children": [],
                "leaf": true,
                "name": "Socks",
                "var": true
              },
              {
                "categoryId": 1796,
                "children": [
                  {
                    "categoryId": 1798,
                    "children": [],
                    "leaf": true,
                    "name": "Blazers",
                    "var": true
                  },
                  {
                    "categoryId": 4369,
                    "children": [],
                    "leaf": true,
                    "name": "Suit Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4370,
                    "children": [],
                    "leaf": true,
                    "name": "Suit Pants",
                    "var": true
                  },
                  {
                    "categoryId": 1797,
                    "children": [],
                    "leaf": true,
                    "name": "Suits",
                    "var": true
                  },
                  {
                    "categoryId": 4385,
                    "children": [],
                    "leaf": true,
                    "name": "Waistcoats & Vests",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Suits",
                "var": true
              },
              {
                "categoryId": 1783,
                "children": [
                  {
                    "categoryId": 4249,
                    "children": [],
                    "leaf": true,
                    "name": "Cardigans",
                    "var": true
                  },
                  {
                    "categoryId": 4251,
                    "children": [],
                    "leaf": true,
                    "name": "Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 1785,
                    "children": [],
                    "leaf": true,
                    "name": "Sweaters",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Sweaters & Cardigans",
                "var": true
              },
              {
                "categoryId": 1799,
                "children": [],
                "leaf": true,
                "name": "Swimwear",
                "var": true
              },
              {
                "categoryId": 10002979,
                "children": [
                  {
                    "categoryId": 10002980,
                    "children": [],
                    "leaf": true,
                    "name": "Kurtas",
                    "var": true
                  },
                  {
                    "categoryId": 10003000,
                    "children": [],
                    "leaf": true,
                    "name": "Lungi",
                    "var": true
                  },
                  {
                    "categoryId": 10003011,
                    "children": [],
                    "leaf": true,
                    "name": "Shawls",
                    "var": true
                  },
                  {
                    "categoryId": 10002981,
                    "children": [],
                    "leaf": true,
                    "name": "Sherwani",
                    "var": true
                  },
                  {
                    "categoryId": 10003010,
                    "children": [],
                    "leaf": true,
                    "name": "Unstitched Fabric",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Traditional Clothing",
                "var": true
              },
              {
                "categoryId": 1804,
                "children": [
                  {
                    "categoryId": 1805,
                    "children": [],
                    "leaf": true,
                    "name": "Briefs",
                    "var": true
                  },
                  {
                    "categoryId": 4347,
                    "children": [],
                    "leaf": true,
                    "name": "Nightwear",
                    "var": true
                  },
                  {
                    "categoryId": 20000005,
                    "children": [],
                    "leaf": true,
                    "name": "Thermal",
                    "var": true
                  },
                  {
                    "categoryId": 10002032,
                    "children": [],
                    "leaf": true,
                    "name": "Thongs & Others",
                    "var": true
                  },
                  {
                    "categoryId": 10002031,
                    "children": [],
                    "leaf": true,
                    "name": "Trunks & Boxers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Underwear",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Clothing",
            "var": true
          },
          {
            "categoryId": 6634,
            "children": [],
            "leaf": true,
            "name": "Ethnic Wear",
            "var": true
          },
          {
            "categoryId": 4108,
            "children": [
              {
                "categoryId": 4480,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": true
              },
              {
                "categoryId": 10001968,
                "children": [],
                "leaf": true,
                "name": "Cekak Musang",
                "var": true
              },
              {
                "categoryId": 4456,
                "children": [],
                "leaf": true,
                "name": "Jubahs",
                "var": true
              },
              {
                "categoryId": 10001967,
                "children": [],
                "leaf": true,
                "name": "Perlengkapan Shalat",
                "var": true
              },
              {
                "categoryId": 4416,
                "children": [
                  {
                    "categoryId": 4422,
                    "children": [],
                    "leaf": true,
                    "name": "Batik Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 4424,
                    "children": [],
                    "leaf": true,
                    "name": "Muslimin Shirts",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shirts",
                "var": true
              },
              {
                "categoryId": 10001969,
                "children": [],
                "leaf": true,
                "name": "Telok Belanga",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Muslim Wear",
            "var": true
          },
          {
            "categoryId": 1807,
            "children": [
              {
                "categoryId": 1817,
                "children": [
                  {
                    "categoryId": 4121,
                    "children": [],
                    "leaf": true,
                    "name": "Ankle Boots",
                    "var": true
                  },
                  {
                    "categoryId": 4122,
                    "children": [],
                    "leaf": true,
                    "name": "Cowboy & Biker Boots",
                    "var": true
                  },
                  {
                    "categoryId": 4123,
                    "children": [],
                    "leaf": true,
                    "name": "Rain Boots",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Boots",
                "var": true
              },
              {
                "categoryId": 4111,
                "children": [
                  {
                    "categoryId": 4113,
                    "children": [],
                    "leaf": true,
                    "name": "Espadrilles",
                    "var": true
                  },
                  {
                    "categoryId": 1809,
                    "children": [
                      {
                        "categoryId": 1810,
                        "children": [],
                        "leaf": true,
                        "name": "Derbies",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Lace-Ups",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Flat Shoes",
                "var": true
              },
              {
                "categoryId": 1816,
                "children": [
                  {
                    "categoryId": 9667,
                    "children": [],
                    "leaf": true,
                    "name": "Flip Flops",
                    "var": true
                  },
                  {
                    "categoryId": 10002029,
                    "children": [],
                    "leaf": true,
                    "name": "House Slippers",
                    "var": true
                  },
                  {
                    "categoryId": 9668,
                    "children": [],
                    "leaf": true,
                    "name": "Sandals",
                    "var": true
                  },
                  {
                    "categoryId": 9669,
                    "children": [],
                    "leaf": true,
                    "name": "Slides",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Flip Flops & Sandals",
                "var": true
              },
              {
                "categoryId": 4124,
                "children": [],
                "leaf": true,
                "name": "Formal Shoes",
                "var": true
              },
              {
                "categoryId": 10003001,
                "children": [
                  {
                    "categoryId": 10003007,
                    "children": [],
                    "leaf": true,
                    "name": "Kohlapuris",
                    "var": true
                  },
                  {
                    "categoryId": 10003008,
                    "children": [],
                    "leaf": true,
                    "name": "Peshawari",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Khusa & Kolapuri",
                "var": true
              },
              {
                "categoryId": 1818,
                "children": [],
                "leaf": true,
                "name": "Shoes Accessories",
                "var": true
              },
              {
                "categoryId": 9670,
                "children": [],
                "leaf": true,
                "name": "Slip-Ons & Loafers",
                "var": true
              },
              {
                "categoryId": 1811,
                "children": [],
                "leaf": true,
                "name": "Sneakers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shoes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Men",
        "var": true
      },
      {
        "categoryId": 10001925,
        "children": [
          {
            "categoryId": 10001933,
            "children": [
              {
                "categoryId": 20000352,
                "children": [],
                "leaf": true,
                "name": "Fashion Masks",
                "var": true
              },
              {
                "categoryId": 10001973,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": true
          },
          {
            "categoryId": 10001932,
            "children": [
              {
                "categoryId": 10001972,
                "children": [
                  {
                    "categoryId": 10002037,
                    "children": [],
                    "leaf": true,
                    "name": "Beachwear",
                    "var": true
                  },
                  {
                    "categoryId": 10002035,
                    "children": [],
                    "leaf": true,
                    "name": "Cardigans & Sweaters",
                    "var": true
                  },
                  {
                    "categoryId": 10002036,
                    "children": [],
                    "leaf": true,
                    "name": "Hoodies & Sweatshirts",
                    "var": true
                  },
                  {
                    "categoryId": 10002034,
                    "children": [],
                    "leaf": true,
                    "name": "Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 10002033,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Couple & Family Sets",
                "var": true
              },
              {
                "categoryId": 10001971,
                "children": [],
                "leaf": true,
                "name": "Hoodies & Sweatshirts",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Clothing",
            "var": true
          },
          {
            "categoryId": 10001934,
            "children": [
              {
                "categoryId": 10001975,
                "children": [
                  {
                    "categoryId": 10002038,
                    "children": [],
                    "leaf": true,
                    "name": "Flip Flops",
                    "var": true
                  },
                  {
                    "categoryId": 10002039,
                    "children": [],
                    "leaf": true,
                    "name": "House Slippers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Flip Flops & Sandals",
                "var": true
              },
              {
                "categoryId": 10001974,
                "children": [],
                "leaf": true,
                "name": "Shoes Accessories",
                "var": true
              },
              {
                "categoryId": 10001976,
                "children": [],
                "leaf": true,
                "name": "Sneakers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shoes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Unisex",
        "var": true
      },
      {
        "categoryId": 1820,
        "children": [
          {
            "categoryId": 1848,
            "children": [
              {
                "categoryId": 1849,
                "children": [],
                "leaf": true,
                "name": "Belts",
                "var": true
              },
              {
                "categoryId": 4160,
                "children": [],
                "leaf": true,
                "name": "Gloves",
                "var": true
              },
              {
                "categoryId": 1852,
                "children": [],
                "leaf": true,
                "name": "Hair Accessories",
                "var": true
              },
              {
                "categoryId": 1851,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": true
              },
              {
                "categoryId": 1850,
                "children": [],
                "leaf": false,
                "name": "Hats & Hair Accessories",
                "var": true
              },
              {
                "categoryId": 4152,
                "children": [],
                "leaf": true,
                "name": "Others",
                "var": true
              },
              {
                "categoryId": 3898,
                "children": [],
                "leaf": true,
                "name": "Scarves",
                "var": true
              },
              {
                "categoryId": 1853,
                "children": [],
                "leaf": true,
                "name": "Umbrellas",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": true
          },
          {
            "categoryId": 1738,
            "children": [
              {
                "categoryId": 4390,
                "children": [
                  {
                    "categoryId": 4397,
                    "children": [],
                    "leaf": true,
                    "name": "Shirts",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Blouse & Shirts",
                "var": true
              },
              {
                "categoryId": 1740,
                "children": [],
                "leaf": true,
                "name": "Dresses",
                "var": true
              },
              {
                "categoryId": 1741,
                "children": [
                  {
                    "categoryId": 1743,
                    "children": [],
                    "leaf": true,
                    "name": "Sweatshirts",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Hoodies & Sweat shirts",
                "var": true
              },
              {
                "categoryId": 10001964,
                "children": [],
                "leaf": true,
                "name": "Hoodies & Sweatshirts",
                "var": true
              },
              {
                "categoryId": 4466,
                "children": [
                  {
                    "categoryId": 1761,
                    "children": [],
                    "leaf": true,
                    "name": "Blazers & Vests",
                    "var": true
                  },
                  {
                    "categoryId": 9644,
                    "children": [],
                    "leaf": true,
                    "name": "Bomber Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4478,
                    "children": [],
                    "leaf": true,
                    "name": "Capes & Gilets",
                    "var": true
                  },
                  {
                    "categoryId": 4469,
                    "children": [],
                    "leaf": true,
                    "name": "Denim Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10002026,
                    "children": [],
                    "leaf": true,
                    "name": "Down Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10002025,
                    "children": [],
                    "leaf": true,
                    "name": "Fur & Faux Fur Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4472,
                    "children": [],
                    "leaf": true,
                    "name": "Leather & Faux Leather Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4485,
                    "children": [],
                    "leaf": true,
                    "name": "Lightweight Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 4484,
                    "children": [],
                    "leaf": true,
                    "name": "Rain Coats & Trenches",
                    "var": true
                  },
                  {
                    "categoryId": 4483,
                    "children": [],
                    "leaf": true,
                    "name": "Winter Jackets & Coats",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Jackets & Coats",
                "var": true
              },
              {
                "categoryId": 4339,
                "children": [],
                "leaf": true,
                "name": "Jeans",
                "var": true
              },
              {
                "categoryId": 10001963,
                "children": [],
                "leaf": true,
                "name": "Jumpsuits & Playsuits",
                "var": true
              },
              {
                "categoryId": 1753,
                "children": [
                  {
                    "categoryId": 9651,
                    "children": [],
                    "leaf": true,
                    "name": "Bottoms",
                    "var": true
                  },
                  {
                    "categoryId": 9649,
                    "children": [],
                    "leaf": true,
                    "name": "Dresses",
                    "var": true
                  },
                  {
                    "categoryId": 9652,
                    "children": [],
                    "leaf": true,
                    "name": "Intimates",
                    "var": true
                  },
                  {
                    "categoryId": 9653,
                    "children": [],
                    "leaf": true,
                    "name": "Swimwear",
                    "var": true
                  },
                  {
                    "categoryId": 9650,
                    "children": [],
                    "leaf": true,
                    "name": "Tops",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Maternity",
                "var": true
              },
              {
                "categoryId": 4428,
                "children": [
                  {
                    "categoryId": 4431,
                    "children": [],
                    "leaf": true,
                    "name": "Chinos",
                    "var": true
                  },
                  {
                    "categoryId": 9639,
                    "children": [],
                    "leaf": true,
                    "name": "Jeggings",
                    "var": true
                  },
                  {
                    "categoryId": 4451,
                    "children": [],
                    "leaf": true,
                    "name": "Joggers",
                    "var": true
                  },
                  {
                    "categoryId": 4442,
                    "children": [],
                    "leaf": true,
                    "name": "Leggings",
                    "var": true
                  },
                  {
                    "categoryId": 4434,
                    "children": [],
                    "leaf": true,
                    "name": "Pants",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Pants & Leggings",
                "var": true
              },
              {
                "categoryId": 9654,
                "children": [
                  {
                    "categoryId": 9657,
                    "children": [],
                    "leaf": true,
                    "name": "Bottoms",
                    "var": true
                  },
                  {
                    "categoryId": 9655,
                    "children": [],
                    "leaf": true,
                    "name": "Dresses",
                    "var": true
                  },
                  {
                    "categoryId": 9658,
                    "children": [],
                    "leaf": true,
                    "name": "Intimates",
                    "var": true
                  },
                  {
                    "categoryId": 9659,
                    "children": [],
                    "leaf": true,
                    "name": "Swimwear",
                    "var": true
                  },
                  {
                    "categoryId": 9656,
                    "children": [],
                    "leaf": true,
                    "name": "Tops",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Plus Size",
                "var": true
              },
              {
                "categoryId": 9640,
                "children": [
                  {
                    "categoryId": 9643,
                    "children": [],
                    "leaf": true,
                    "name": "Casual",
                    "var": true
                  },
                  {
                    "categoryId": 9641,
                    "children": [],
                    "leaf": true,
                    "name": "Denim",
                    "var": true
                  },
                  {
                    "categoryId": 9642,
                    "children": [],
                    "leaf": true,
                    "name": "Tailored",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shorts",
                "var": true
              },
              {
                "categoryId": 20000003,
                "children": [],
                "leaf": true,
                "name": "Shrugs",
                "var": true
              },
              {
                "categoryId": 1755,
                "children": [],
                "leaf": true,
                "name": "Skirts",
                "var": true
              },
              {
                "categoryId": 1756,
                "children": [],
                "leaf": true,
                "name": "Socks & Tights",
                "var": true
              },
              {
                "categoryId": 4405,
                "children": [],
                "leaf": true,
                "name": "Sweaters & Cardigans",
                "var": true
              },
              {
                "categoryId": 1763,
                "children": [
                  {
                    "categoryId": 1764,
                    "children": [],
                    "leaf": true,
                    "name": "Beachwear & Accessories",
                    "var": true
                  },
                  {
                    "categoryId": 4510,
                    "children": [],
                    "leaf": true,
                    "name": "Bikinis",
                    "var": true
                  },
                  {
                    "categoryId": 10002027,
                    "children": [],
                    "leaf": true,
                    "name": "Swimsuits",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Swimwear & Beachwear",
                "var": true
              },
              {
                "categoryId": 4372,
                "children": [
                  {
                    "categoryId": 9637,
                    "children": [],
                    "leaf": true,
                    "name": "Blouses & Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 4383,
                    "children": [],
                    "leaf": true,
                    "name": "Long Sleeve T-Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 4381,
                    "children": [],
                    "leaf": true,
                    "name": "Polo Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 4375,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts",
                    "var": true
                  },
                  {
                    "categoryId": 1765,
                    "children": [],
                    "leaf": true,
                    "name": "Tanks & Camisoles",
                    "var": true
                  },
                  {
                    "categoryId": 9638,
                    "children": [],
                    "leaf": true,
                    "name": "Tunics",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Tops",
                "var": true
              },
              {
                "categoryId": 10002970,
                "children": [
                  {
                    "categoryId": 20000053,
                    "children": [],
                    "leaf": true,
                    "name": "Bulk Deals",
                    "var": true
                  },
                  {
                    "categoryId": 10002973,
                    "children": [],
                    "leaf": true,
                    "name": "Dupattas, Stoles & Shawls",
                    "var": true
                  },
                  {
                    "categoryId": 10002974,
                    "children": [
                      {
                        "categoryId": 10003004,
                        "children": [],
                        "leaf": true,
                        "name": "Bridal Dress",
                        "var": true
                      },
                      {
                        "categoryId": 10003006,
                        "children": [],
                        "leaf": true,
                        "name": "Party Wear",
                        "var": true
                      },
                      {
                        "categoryId": 10002978,
                        "children": [],
                        "leaf": true,
                        "name": "Sarees",
                        "var": true
                      },
                      {
                        "categoryId": 10003005,
                        "children": [],
                        "leaf": true,
                        "name": "Wedding Wear",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Formal Wear",
                    "var": true
                  },
                  {
                    "categoryId": 10002972,
                    "children": [
                      {
                        "categoryId": 10002977,
                        "children": [],
                        "leaf": true,
                        "name": "Kurtis",
                        "var": true
                      },
                      {
                        "categoryId": 10003009,
                        "children": [],
                        "leaf": true,
                        "name": "Shalwar & Pyjamas",
                        "var": true
                      },
                      {
                        "categoryId": 10002976,
                        "children": [],
                        "leaf": true,
                        "name": "Shalwar Kameez",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Kurtas & Shalwar Kameez",
                    "var": true
                  },
                  {
                    "categoryId": 10002975,
                    "children": [],
                    "leaf": true,
                    "name": "Palazzo Pants & Culottes",
                    "var": true
                  },
                  {
                    "categoryId": 10003002,
                    "children": [],
                    "leaf": true,
                    "name": "Pants, Palazzos & Capris",
                    "var": true
                  },
                  {
                    "categoryId": 10002971,
                    "children": [],
                    "leaf": true,
                    "name": "Unstitched Fabric ",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Traditional Clothing",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Clothing",
            "var": true
          },
          {
            "categoryId": 6629,
            "children": [],
            "leaf": true,
            "name": "Ethnic Wear",
            "var": true
          },
          {
            "categoryId": 10253,
            "children": [
              {
                "categoryId": 10254,
                "children": [],
                "leaf": true,
                "name": "Bras",
                "var": true
              },
              {
                "categoryId": 10260,
                "children": [],
                "leaf": true,
                "name": "Lingerie Sets",
                "var": true
              },
              {
                "categoryId": 10255,
                "children": [],
                "leaf": true,
                "name": "Panties",
                "var": true
              },
              {
                "categoryId": 10259,
                "children": [],
                "leaf": true,
                "name": "Robes & Bodysuits",
                "var": true
              },
              {
                "categoryId": 10258,
                "children": [],
                "leaf": true,
                "name": "Sleep & Loungewear",
                "var": true
              },
              {
                "categoryId": 10261,
                "children": [],
                "leaf": true,
                "name": "Tank Tops & Slips",
                "var": true
              },
              {
                "categoryId": 10257,
                "children": [],
                "leaf": true,
                "name": "Thermal & Shapewear",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Lingerie, Sleep & Lounge",
            "var": true
          },
          {
            "categoryId": 4167,
            "children": [
              {
                "categoryId": 4207,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": true
              },
              {
                "categoryId": 10001965,
                "children": [],
                "leaf": true,
                "name": "Baju Kurung",
                "var": true
              },
              {
                "categoryId": 4198,
                "children": [],
                "leaf": true,
                "name": "Bottoms",
                "var": true
              },
              {
                "categoryId": 4193,
                "children": [],
                "leaf": true,
                "name": "Dresses & Jumpsuits",
                "var": true
              },
              {
                "categoryId": 4184,
                "children": [],
                "leaf": true,
                "name": "Hijabs",
                "var": true
              },
              {
                "categoryId": 4206,
                "children": [],
                "leaf": true,
                "name": "Outerwear",
                "var": true
              },
              {
                "categoryId": 4169,
                "children": [],
                "leaf": true,
                "name": "Perlengkapan Shalat",
                "var": true
              },
              {
                "categoryId": 9660,
                "children": [],
                "leaf": true,
                "name": "Swimwear",
                "var": true
              },
              {
                "categoryId": 4191,
                "children": [],
                "leaf": true,
                "name": "Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Muslim Wear",
            "var": true
          },
          {
            "categoryId": 1766,
            "children": [
              {
                "categoryId": 1779,
                "children": [
                  {
                    "categoryId": 9620,
                    "children": [],
                    "leaf": true,
                    "name": "Fashion Boots",
                    "var": true
                  },
                  {
                    "categoryId": 4203,
                    "children": [],
                    "leaf": true,
                    "name": "Rain Boots",
                    "var": true
                  },
                  {
                    "categoryId": 4201,
                    "children": [],
                    "leaf": true,
                    "name": "Winter Boots",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Boots",
                "var": true
              },
              {
                "categoryId": 1774,
                "children": [
                  {
                    "categoryId": 4135,
                    "children": [],
                    "leaf": true,
                    "name": "Ballet Flats",
                    "var": true
                  },
                  {
                    "categoryId": 4136,
                    "children": [],
                    "leaf": true,
                    "name": "Boat Shoes & Loafers",
                    "var": true
                  },
                  {
                    "categoryId": 9615,
                    "children": [],
                    "leaf": true,
                    "name": "Mary Janes",
                    "var": true
                  },
                  {
                    "categoryId": 4140,
                    "children": [],
                    "leaf": true,
                    "name": "Oxfords & Lace-Ups",
                    "var": true
                  },
                  {
                    "categoryId": 4137,
                    "children": [],
                    "leaf": true,
                    "name": "Slip-Ons",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Flat Shoes",
                "var": true
              },
              {
                "categoryId": 1767,
                "children": [
                  {
                    "categoryId": 4144,
                    "children": [],
                    "leaf": true,
                    "name": "Heeled Sandals",
                    "var": true
                  },
                  {
                    "categoryId": 9617,
                    "children": [],
                    "leaf": true,
                    "name": "Mules",
                    "var": true
                  },
                  {
                    "categoryId": 4143,
                    "children": [],
                    "leaf": true,
                    "name": "Pumps",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Heels",
                "var": true
              },
              {
                "categoryId": 1769,
                "children": [
                  {
                    "categoryId": 9621,
                    "children": [],
                    "leaf": true,
                    "name": "Flat Sandals",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Sandals",
                "var": true
              },
              {
                "categoryId": 1780,
                "children": [],
                "leaf": true,
                "name": "Shoes Accessories",
                "var": true
              },
              {
                "categoryId": 1770,
                "children": [
                  {
                    "categoryId": 9623,
                    "children": [],
                    "leaf": true,
                    "name": "Flip Flops",
                    "var": true
                  },
                  {
                    "categoryId": 10002024,
                    "children": [],
                    "leaf": true,
                    "name": "House Slippers",
                    "var": true
                  },
                  {
                    "categoryId": 9622,
                    "children": [],
                    "leaf": true,
                    "name": "Slides",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Slides & Flip Flops",
                "var": true
              },
              {
                "categoryId": 1772,
                "children": [],
                "leaf": true,
                "name": "Sneakers",
                "var": true
              },
              {
                "categoryId": 1777,
                "children": [
                  {
                    "categoryId": 4182,
                    "children": [],
                    "leaf": true,
                    "name": "Closed-Toe Wedges",
                    "var": true
                  },
                  {
                    "categoryId": 4183,
                    "children": [],
                    "leaf": true,
                    "name": "Slides & Mules",
                    "var": true
                  },
                  {
                    "categoryId": 4180,
                    "children": [],
                    "leaf": true,
                    "name": "Wedge Sandals",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Wedges",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Shoes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Women",
        "var": true
      }
    ],
    "leaf": false,
    "name": "Fashion",
    "var": true
  },
  {
    "categoryId": 10000336,
    "children": [
      {
        "categoryId": 10000343,
        "children": [
          {
            "categoryId": 10000407,
            "children": [
              {
                "categoryId": 10000735,
                "children": [],
                "leaf": true,
                "name": "Bed Ends",
                "var": false
              },
              {
                "categoryId": 10000737,
                "children": [],
                "leaf": true,
                "name": "Bed Headboards",
                "var": false
              },
              {
                "categoryId": 10000736,
                "children": [],
                "leaf": true,
                "name": "Bedroom Ensembles & Sets",
                "var": false
              },
              {
                "categoryId": 10000731,
                "children": [],
                "leaf": true,
                "name": "Beds",
                "var": false
              },
              {
                "categoryId": 10000732,
                "children": [],
                "leaf": true,
                "name": "Bedside Tables",
                "var": false
              },
              {
                "categoryId": 10000733,
                "children": [],
                "leaf": true,
                "name": "Dressers & Drawers",
                "var": false
              },
              {
                "categoryId": 10000738,
                "children": [],
                "leaf": true,
                "name": "Mattresses",
                "var": false
              },
              {
                "categoryId": 10000734,
                "children": [],
                "leaf": true,
                "name": "Wardrobes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Bedroom Furniture",
            "var": false
          },
          {
            "categoryId": 10000415,
            "children": [],
            "leaf": true,
            "name": "Furniture Protectors & Parts",
            "var": false
          },
          {
            "categoryId": 10000413,
            "children": [
              {
                "categoryId": 10000812,
                "children": [],
                "leaf": true,
                "name": "Gaming Chairs",
                "var": false
              },
              {
                "categoryId": 10000772,
                "children": [],
                "leaf": true,
                "name": "Gaming Tables",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Gaming Furniture",
            "var": false
          },
          {
            "categoryId": 10000412,
            "children": [
              {
                "categoryId": 10000769,
                "children": [],
                "leaf": true,
                "name": "Coat Racks & Umbrella Stands",
                "var": false
              },
              {
                "categoryId": 10000770,
                "children": [],
                "leaf": true,
                "name": "Hall Stands & Shelving",
                "var": false
              },
              {
                "categoryId": 10000771,
                "children": [],
                "leaf": true,
                "name": "Storage Benches",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hallway & Entry Furniture",
            "var": false
          },
          {
            "categoryId": 10000411,
            "children": [
              {
                "categoryId": 10000766,
                "children": [],
                "leaf": true,
                "name": "Ergonomic Accessories",
                "var": false
              },
              {
                "categoryId": 10000767,
                "children": [],
                "leaf": true,
                "name": "Filing Cabinets & Office Stands",
                "var": false
              },
              {
                "categoryId": 10000765,
                "children": [],
                "leaf": true,
                "name": "Home Office Chairs",
                "var": false
              },
              {
                "categoryId": 10000764,
                "children": [],
                "leaf": true,
                "name": "Home Office Desks",
                "var": false
              },
              {
                "categoryId": 10000768,
                "children": [],
                "leaf": true,
                "name": "Office Sets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Home Office Furniture",
            "var": false
          },
          {
            "categoryId": 10000410,
            "children": [
              {
                "categoryId": 10000763,
                "children": [],
                "leaf": true,
                "name": "Kids Beds",
                "var": false
              },
              {
                "categoryId": 10000760,
                "children": [],
                "leaf": true,
                "name": "Kids Bookcases & Shelving",
                "var": false
              },
              {
                "categoryId": 10000759,
                "children": [],
                "leaf": true,
                "name": "Kids Chairs",
                "var": false
              },
              {
                "categoryId": 10000758,
                "children": [],
                "leaf": true,
                "name": "Kids Tables & Sets",
                "var": false
              },
              {
                "categoryId": 10000762,
                "children": [],
                "leaf": true,
                "name": "Kids Wardrobes",
                "var": false
              },
              {
                "categoryId": 10000761,
                "children": [],
                "leaf": true,
                "name": "Toy Boxes & Organisers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kids Furniture",
            "var": false
          },
          {
            "categoryId": 10000409,
            "children": [
              {
                "categoryId": 10000752,
                "children": [],
                "leaf": true,
                "name": "Bar Stools",
                "var": false
              },
              {
                "categoryId": 10000751,
                "children": [],
                "leaf": true,
                "name": "Bar Tables & Sets",
                "var": false
              },
              {
                "categoryId": 10000754,
                "children": [],
                "leaf": true,
                "name": "Dining Chairs",
                "var": false
              },
              {
                "categoryId": 10000755,
                "children": [],
                "leaf": true,
                "name": "Dining Room Sets",
                "var": false
              },
              {
                "categoryId": 10000750,
                "children": [],
                "leaf": true,
                "name": "Dining Tables",
                "var": false
              },
              {
                "categoryId": 10000757,
                "children": [],
                "leaf": true,
                "name": "Kitchen Islands",
                "var": false
              },
              {
                "categoryId": 10000756,
                "children": [],
                "leaf": true,
                "name": "Sideboards & Buffets",
                "var": false
              },
              {
                "categoryId": 10000753,
                "children": [],
                "leaf": true,
                "name": "Wine & Drinks Cabinets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kitchen & Dining Furniture",
            "var": false
          },
          {
            "categoryId": 10000408,
            "children": [
              {
                "categoryId": 10000740,
                "children": [],
                "leaf": true,
                "name": "Beanbag",
                "var": false
              },
              {
                "categoryId": 10000748,
                "children": [],
                "leaf": true,
                "name": "Bookcases & Shelving",
                "var": false
              },
              {
                "categoryId": 10000739,
                "children": [],
                "leaf": true,
                "name": "Chairs",
                "var": false
              },
              {
                "categoryId": 10000744,
                "children": [],
                "leaf": true,
                "name": "Coffee Tables",
                "var": false
              },
              {
                "categoryId": 10000746,
                "children": [],
                "leaf": true,
                "name": "Console Tables",
                "var": false
              },
              {
                "categoryId": 10000743,
                "children": [],
                "leaf": true,
                "name": "Futons & Daybeds",
                "var": false
              },
              {
                "categoryId": 10000741,
                "children": [],
                "leaf": true,
                "name": "Massage Chairs",
                "var": false
              },
              {
                "categoryId": 10000747,
                "children": [],
                "leaf": true,
                "name": "Media & TV Storage",
                "var": false
              },
              {
                "categoryId": 10000749,
                "children": [],
                "leaf": true,
                "name": "Ottomans",
                "var": false
              },
              {
                "categoryId": 10000745,
                "children": [],
                "leaf": true,
                "name": "Side Tables",
                "var": false
              },
              {
                "categoryId": 10000811,
                "children": [],
                "leaf": true,
                "name": "Sofa Covers & Slips",
                "var": false
              },
              {
                "categoryId": 10000742,
                "children": [],
                "leaf": true,
                "name": "Sofas",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Living Room Furniture",
            "var": false
          },
          {
            "categoryId": 10000414,
            "children": [
              {
                "categoryId": 10000774,
                "children": [],
                "leaf": true,
                "name": "Outdoor Seating",
                "var": false
              },
              {
                "categoryId": 10000775,
                "children": [],
                "leaf": true,
                "name": "Outdoor Sets",
                "var": false
              },
              {
                "categoryId": 10000773,
                "children": [],
                "leaf": true,
                "name": "Outdoor Tables",
                "var": false
              },
              {
                "categoryId": 10000776,
                "children": [],
                "leaf": true,
                "name": "Shades & Awnings",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Outdoor Furniture",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Furniture",
        "var": false
      },
      {
        "categoryId": 10000345,
        "children": [
          {
            "categoryId": 10000436,
            "children": [],
            "leaf": true,
            "name": "Aromatherapy and Home Fragrance",
            "var": false
          },
          {
            "categoryId": 10000435,
            "children": [],
            "leaf": true,
            "name": "Artificial Flowers & Plants",
            "var": false
          },
          {
            "categoryId": 10000434,
            "children": [],
            "leaf": true,
            "name": "Candles & Candleholders",
            "var": false
          },
          {
            "categoryId": 10000439,
            "children": [],
            "leaf": true,
            "name": "Clocks",
            "var": false
          },
          {
            "categoryId": 10000429,
            "children": [],
            "leaf": true,
            "name": "Curtains",
            "var": false
          },
          {
            "categoryId": 10000423,
            "children": [],
            "leaf": true,
            "name": "Cushions & Covers",
            "var": false
          },
          {
            "categoryId": 10000430,
            "children": [],
            "leaf": true,
            "name": "Decorative Accents",
            "var": false
          },
          {
            "categoryId": 10000432,
            "children": [],
            "leaf": true,
            "name": "Decorative Bowls",
            "var": false
          },
          {
            "categoryId": 10000438,
            "children": [],
            "leaf": true,
            "name": "Decorative Door Stops",
            "var": false
          },
          {
            "categoryId": 10000424,
            "children": [],
            "leaf": true,
            "name": "Mirrors",
            "var": false
          },
          {
            "categoryId": 10000433,
            "children": [],
            "leaf": true,
            "name": "Picture Frames",
            "var": false
          },
          {
            "categoryId": 10000437,
            "children": [],
            "leaf": true,
            "name": "Room Dividers & Screens",
            "var": false
          },
          {
            "categoryId": 10000425,
            "children": [],
            "leaf": true,
            "name": "Rugs & Carpets",
            "var": false
          },
          {
            "categoryId": 10000440,
            "children": [],
            "leaf": true,
            "name": "Seasonal",
            "var": false
          },
          {
            "categoryId": 10000428,
            "children": [],
            "leaf": true,
            "name": "Tapestries & Hangings",
            "var": false
          },
          {
            "categoryId": 10000431,
            "children": [],
            "leaf": true,
            "name": "Vases & Vessels",
            "var": false
          },
          {
            "categoryId": 10000426,
            "children": [],
            "leaf": true,
            "name": "Wall Décor",
            "var": false
          },
          {
            "categoryId": 10000427,
            "children": [],
            "leaf": true,
            "name": "Wall Stickers & Decals",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Home Décor",
        "var": false
      },
      {
        "categoryId": 10000372,
        "children": [
          {
            "categoryId": 10000646,
            "children": [],
            "leaf": true,
            "name": "Bathroom Lighting",
            "var": false
          },
          {
            "categoryId": 10000638,
            "children": [],
            "leaf": true,
            "name": "Ceiling Lights",
            "var": false
          },
          {
            "categoryId": 10000641,
            "children": [],
            "leaf": true,
            "name": "Floor Lamps",
            "var": false
          },
          {
            "categoryId": 10000645,
            "children": [],
            "leaf": true,
            "name": "Lamp Shades",
            "var": false
          },
          {
            "categoryId": 10000643,
            "children": [],
            "leaf": true,
            "name": "Light Bulbs",
            "var": false
          },
          {
            "categoryId": 10000644,
            "children": [],
            "leaf": true,
            "name": "Lighting Fixtures & Components",
            "var": false
          },
          {
            "categoryId": 10000647,
            "children": [],
            "leaf": true,
            "name": "Outdoor Lighting",
            "var": false
          },
          {
            "categoryId": 10000642,
            "children": [
              {
                "categoryId": 10000809,
                "children": [],
                "leaf": true,
                "name": "Billiard & Gaming Lights",
                "var": false
              },
              {
                "categoryId": 10000806,
                "children": [],
                "leaf": true,
                "name": "Book Lights",
                "var": false
              },
              {
                "categoryId": 10000804,
                "children": [],
                "leaf": true,
                "name": "Fairy Lights",
                "var": false
              },
              {
                "categoryId": 10000810,
                "children": [],
                "leaf": true,
                "name": "LED Strip Lighting",
                "var": false
              },
              {
                "categoryId": 10000805,
                "children": [],
                "leaf": true,
                "name": "Night Lights",
                "var": false
              },
              {
                "categoryId": 10000808,
                "children": [],
                "leaf": true,
                "name": "Picture & Display Lights",
                "var": false
              },
              {
                "categoryId": 10000807,
                "children": [],
                "leaf": true,
                "name": "Seasonal & Decorative",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Specialty Lighting",
            "var": false
          },
          {
            "categoryId": 10000640,
            "children": [],
            "leaf": true,
            "name": "Table Lamps",
            "var": false
          },
          {
            "categoryId": 10000639,
            "children": [],
            "leaf": true,
            "name": "Wall Lights & Sconces",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Lighting",
        "var": false
      },
      {
        "categoryId": 10000344,
        "children": [
          {
            "categoryId": 10000421,
            "children": [],
            "leaf": true,
            "name": "Bike & Sports Racks",
            "var": false
          },
          {
            "categoryId": 10000422,
            "children": [],
            "leaf": true,
            "name": "Deck Boxes & Balcony Storage",
            "var": false
          },
          {
            "categoryId": 10000420,
            "children": [],
            "leaf": true,
            "name": "Medicine & First Aid Storage",
            "var": false
          },
          {
            "categoryId": 10000417,
            "children": [],
            "leaf": true,
            "name": "Shoe Organisers",
            "var": false
          },
          {
            "categoryId": 10000418,
            "children": [],
            "leaf": true,
            "name": "Space Savers",
            "var": false
          },
          {
            "categoryId": 10000416,
            "children": [],
            "leaf": true,
            "name": "Storage Bins & Baskets",
            "var": false
          },
          {
            "categoryId": 10000419,
            "children": [],
            "leaf": true,
            "name": "Wardrobe Organisers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Storage & Organisation",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Furniture & Décor",
    "var": false
  },
  {
    "categoryId": 3752,
    "children": [
      {
        "categoryId": 10002054,
        "children": [
          {
            "categoryId": 10002164,
            "children": [
              {
                "categoryId": 10002494,
                "children": [],
                "leaf": true,
                "name": "Bagels & Pretzels",
                "var": true
              },
              {
                "categoryId": 10002495,
                "children": [],
                "leaf": true,
                "name": "Bread Rolls &Buns",
                "var": true
              },
              {
                "categoryId": 10002496,
                "children": [],
                "leaf": true,
                "name": "Cream Rolls",
                "var": true
              },
              {
                "categoryId": 10002497,
                "children": [],
                "leaf": true,
                "name": "Loaves & Artisanal Breads",
                "var": true
              },
              {
                "categoryId": 10002498,
                "children": [],
                "leaf": true,
                "name": "Prebaked Bread",
                "var": true
              },
              {
                "categoryId": 10002499,
                "children": [],
                "leaf": true,
                "name": "Sliced Bread",
                "var": true
              },
              {
                "categoryId": 10002500,
                "children": [],
                "leaf": true,
                "name": "Variety Buns",
                "var": true
              },
              {
                "categoryId": 10002501,
                "children": [],
                "leaf": true,
                "name": "Wraps, Pitta & Naan",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Bread",
            "var": true
          },
          {
            "categoryId": 10002163,
            "children": [
              {
                "categoryId": 10002490,
                "children": [],
                "leaf": true,
                "name": "Croissants & Brioche",
                "var": true
              },
              {
                "categoryId": 10002491,
                "children": [],
                "leaf": true,
                "name": "Muffins",
                "var": true
              },
              {
                "categoryId": 10002492,
                "children": [],
                "leaf": true,
                "name": "Pancakes",
                "var": true
              },
              {
                "categoryId": 10002493,
                "children": [],
                "leaf": true,
                "name": "Pastries & Scones",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Breakfast & Treats",
            "var": true
          },
          {
            "categoryId": 10002162,
            "children": [
              {
                "categoryId": 10002486,
                "children": [],
                "leaf": true,
                "name": "Cakes",
                "var": true
              },
              {
                "categoryId": 10002488,
                "children": [],
                "leaf": true,
                "name": "Cookies & Brownies",
                "var": true
              },
              {
                "categoryId": 10002487,
                "children": [],
                "leaf": true,
                "name": "Fruit Loaves & Scones",
                "var": true
              },
              {
                "categoryId": 10002489,
                "children": [],
                "leaf": true,
                "name": "SweetPies",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Cakes & Sweet Pies",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Bakery",
        "var": true
      },
      {
        "categoryId": 7195,
        "children": [
          {
            "categoryId": 10002955,
            "children": [
              {
                "categoryId": 10002431,
                "children": [],
                "leaf": true,
                "name": "BBQ Sauce",
                "var": true
              },
              {
                "categoryId": 10002432,
                "children": [],
                "leaf": true,
                "name": "Chilli Sauce",
                "var": true
              },
              {
                "categoryId": 10002433,
                "children": [],
                "leaf": true,
                "name": "Condiments",
                "var": true
              },
              {
                "categoryId": 10002438,
                "children": [],
                "leaf": true,
                "name": "Ketchup",
                "var": true
              },
              {
                "categoryId": 10002434,
                "children": [],
                "leaf": true,
                "name": "Marinades",
                "var": true
              },
              {
                "categoryId": 10002435,
                "children": [],
                "leaf": true,
                "name": "Mayonnaise",
                "var": true
              },
              {
                "categoryId": 10002436,
                "children": [],
                "leaf": true,
                "name": "Mustard",
                "var": true
              },
              {
                "categoryId": 10002437,
                "children": [],
                "leaf": true,
                "name": "Salad Dressing",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Condiment Dressing",
            "var": true
          },
          {
            "categoryId": 10002136,
            "children": [
              {
                "categoryId": 10002418,
                "children": [],
                "leaf": true,
                "name": "Coconut Milk",
                "var": true
              },
              {
                "categoryId": 10002419,
                "children": [],
                "leaf": true,
                "name": "Cooking Sauces & Kits",
                "var": true
              },
              {
                "categoryId": 10002420,
                "children": [],
                "leaf": true,
                "name": "Fish Sauce",
                "var": true
              },
              {
                "categoryId": 10002430,
                "children": [],
                "leaf": true,
                "name": "Herbs & Spices",
                "var": true
              },
              {
                "categoryId": 10002422,
                "children": [],
                "leaf": true,
                "name": "Lemon & Lime Juice",
                "var": true
              },
              {
                "categoryId": 10002423,
                "children": [],
                "leaf": true,
                "name": "Oil",
                "var": true
              },
              {
                "categoryId": 10002424,
                "children": [],
                "leaf": true,
                "name": "Pepper",
                "var": true
              },
              {
                "categoryId": 10002425,
                "children": [],
                "leaf": true,
                "name": "Salt",
                "var": true
              },
              {
                "categoryId": 10002426,
                "children": [],
                "leaf": true,
                "name": "Sauce",
                "var": true
              },
              {
                "categoryId": 10002427,
                "children": [],
                "leaf": true,
                "name": "Seasoning",
                "var": true
              },
              {
                "categoryId": 10002421,
                "children": [],
                "leaf": true,
                "name": "Soy Sauce",
                "var": true
              },
              {
                "categoryId": 10002428,
                "children": [],
                "leaf": true,
                "name": "Stock Gravy Broth",
                "var": true
              },
              {
                "categoryId": 10002429,
                "children": [],
                "leaf": true,
                "name": "Vinegar & Cooking Wine",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Cooking Ingredients",
            "var": true
          },
          {
            "categoryId": 10002135,
            "children": [
              {
                "categoryId": 10002408,
                "children": [],
                "leaf": true,
                "name": "Baking Accessories",
                "var": true
              },
              {
                "categoryId": 10002409,
                "children": [],
                "leaf": true,
                "name": "Baking Chocolates & Cocoa",
                "var": true
              },
              {
                "categoryId": 10002410,
                "children": [],
                "leaf": true,
                "name": "Baking Needs",
                "var": true
              },
              {
                "categoryId": 10002413,
                "children": [],
                "leaf": true,
                "name": "Bread, Cake, Cookie & Mixes",
                "var": true
              },
              {
                "categoryId": 10002411,
                "children": [],
                "leaf": true,
                "name": "Dried Fruits & Raisins",
                "var": true
              },
              {
                "categoryId": 10002412,
                "children": [],
                "leaf": true,
                "name": "Extract & Flavoring",
                "var": true
              },
              {
                "categoryId": 10002414,
                "children": [],
                "leaf": true,
                "name": "Flour",
                "var": true
              },
              {
                "categoryId": 10002415,
                "children": [],
                "leaf": true,
                "name": "Natural Sweeteners",
                "var": true
              },
              {
                "categoryId": 10002416,
                "children": [],
                "leaf": true,
                "name": "Sugar",
                "var": true
              },
              {
                "categoryId": 10002417,
                "children": [],
                "leaf": true,
                "name": "Sugar Cubes, Sticks & Coffee Crystals",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Home Baking & Sugar",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Baking & Cooking",
        "var": true
      },
      {
        "categoryId": 7238,
        "children": [
          {
            "categoryId": 10002161,
            "children": [],
            "leaf": true,
            "name": "Asian Health Drinks",
            "var": true
          },
          {
            "categoryId": 7240,
            "children": [],
            "leaf": true,
            "name": "Coffee",
            "var": true
          },
          {
            "categoryId": 7244,
            "children": [],
            "leaf": true,
            "name": "Energy Drink",
            "var": true
          },
          {
            "categoryId": 7245,
            "children": [],
            "leaf": true,
            "name": "Flavoring Syrups",
            "var": true
          },
          {
            "categoryId": 7242,
            "children": [],
            "leaf": true,
            "name": "Hot Chocolate and Nutrition Drinks",
            "var": true
          },
          {
            "categoryId": 10002160,
            "children": [
              {
                "categoryId": 10002485,
                "children": [],
                "leaf": true,
                "name": "Coconut Water",
                "var": true
              },
              {
                "categoryId": 10002484,
                "children": [],
                "leaf": true,
                "name": "Juice Drinks",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Juices",
            "var": true
          },
          {
            "categoryId": 7243,
            "children": [],
            "leaf": true,
            "name": "Powdered drink mixes",
            "var": true
          },
          {
            "categoryId": 10002159,
            "children": [
              {
                "categoryId": 10002479,
                "children": [],
                "leaf": true,
                "name": "Asian Health Drinks",
                "var": true
              },
              {
                "categoryId": 10002481,
                "children": [],
                "leaf": true,
                "name": "Carbonated Soft Drinks",
                "var": true
              },
              {
                "categoryId": 10002480,
                "children": [],
                "leaf": true,
                "name": "Cordials & Powder Mixes",
                "var": true
              },
              {
                "categoryId": 10002482,
                "children": [],
                "leaf": true,
                "name": "Iced Tea",
                "var": true
              },
              {
                "categoryId": 10002483,
                "children": [],
                "leaf": true,
                "name": "Sports & Energy Drinks",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Soft Drinks",
            "var": true
          },
          {
            "categoryId": 7241,
            "children": [],
            "leaf": true,
            "name": "Tea",
            "var": true
          },
          {
            "categoryId": 10002158,
            "children": [
              {
                "categoryId": 10002471,
                "children": [],
                "leaf": true,
                "name": "Condensed Milk",
                "var": true
              },
              {
                "categoryId": 10002472,
                "children": [],
                "leaf": true,
                "name": "Creamer",
                "var": true
              },
              {
                "categoryId": 10002473,
                "children": [],
                "leaf": true,
                "name": "Flavoured Milk",
                "var": true
              },
              {
                "categoryId": 10002474,
                "children": [],
                "leaf": true,
                "name": "Milk Powder",
                "var": true
              },
              {
                "categoryId": 10002475,
                "children": [],
                "leaf": true,
                "name": "Soy Powder",
                "var": true
              },
              {
                "categoryId": 10002476,
                "children": [],
                "leaf": true,
                "name": "UHT Dairy Free",
                "var": true
              },
              {
                "categoryId": 10002477,
                "children": [],
                "leaf": true,
                "name": "UHT Milk",
                "var": true
              },
              {
                "categoryId": 10002478,
                "children": [],
                "leaf": true,
                "name": "UHT Soy",
                "var": true
              }
            ],
            "leaf": false,
            "name": "UHT, Milk & Milk Powder",
            "var": true
          },
          {
            "categoryId": 10002157,
            "children": [
              {
                "categoryId": 10002468,
                "children": [],
                "leaf": true,
                "name": "Flavoured Water",
                "var": true
              },
              {
                "categoryId": 10002469,
                "children": [],
                "leaf": true,
                "name": "Sparkling Water",
                "var": true
              },
              {
                "categoryId": 10002470,
                "children": [],
                "leaf": true,
                "name": "Still Water",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Water",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Beverages",
        "var": true
      },
      {
        "categoryId": 7205,
        "children": [
          {
            "categoryId": 7210,
            "children": [],
            "leaf": true,
            "name": "Bars",
            "var": true
          },
          {
            "categoryId": 7206,
            "children": [],
            "leaf": true,
            "name": "Breakfast Cereals",
            "var": true
          },
          {
            "categoryId": 7208,
            "children": [],
            "leaf": true,
            "name": "Granola",
            "var": false
          },
          {
            "categoryId": 7211,
            "children": [],
            "leaf": true,
            "name": "Instant Breakfast Drinks",
            "var": true
          },
          {
            "categoryId": 10002137,
            "children": [
              {
                "categoryId": 10002439,
                "children": [],
                "leaf": true,
                "name": "Chocolate Sweet",
                "var": true
              },
              {
                "categoryId": 10002440,
                "children": [],
                "leaf": true,
                "name": "Honey",
                "var": true
              },
              {
                "categoryId": 10002441,
                "children": [],
                "leaf": true,
                "name": "Jam Marmalade",
                "var": true
              },
              {
                "categoryId": 10002442,
                "children": [],
                "leaf": true,
                "name": "Kaya",
                "var": true
              },
              {
                "categoryId": 10002443,
                "children": [],
                "leaf": true,
                "name": "Nut Butter",
                "var": true
              },
              {
                "categoryId": 10002444,
                "children": [],
                "leaf": true,
                "name": "Savoury Spread",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Jams, Honey & Spreads",
            "var": true
          },
          {
            "categoryId": 7207,
            "children": [],
            "leaf": true,
            "name": "Oatmeals",
            "var": true
          },
          {
            "categoryId": 7209,
            "children": [],
            "leaf": true,
            "name": "Pancake & Waffle Mixes",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Breakfast",
        "var": true
      },
      {
        "categoryId": 7213,
        "children": [
          {
            "categoryId": 7215,
            "children": [],
            "leaf": true,
            "name": "Bars",
            "var": true
          },
          {
            "categoryId": 7218,
            "children": [],
            "leaf": true,
            "name": "Candy Assortments",
            "var": true
          },
          {
            "categoryId": 7216,
            "children": [],
            "leaf": true,
            "name": "Caramels & Toffee",
            "var": true
          },
          {
            "categoryId": 7219,
            "children": [],
            "leaf": true,
            "name": "Chewing Gum & Mints",
            "var": true
          },
          {
            "categoryId": 7214,
            "children": [],
            "leaf": true,
            "name": "Chocolate",
            "var": true
          },
          {
            "categoryId": 10002138,
            "children": [],
            "leaf": true,
            "name": "Festive Boxed",
            "var": true
          },
          {
            "categoryId": 7220,
            "children": [],
            "leaf": true,
            "name": "Marshamallows",
            "var": true
          },
          {
            "categoryId": 7217,
            "children": [],
            "leaf": true,
            "name": "Nuts & Fruits",
            "var": true
          },
          {
            "categoryId": 10002139,
            "children": [],
            "leaf": true,
            "name": "Other Sweets",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Candy & Chocolate",
        "var": true
      },
      {
        "categoryId": 7221,
        "children": [
          {
            "categoryId": 10002145,
            "children": [
              {
                "categoryId": 10002463,
                "children": [],
                "leaf": true,
                "name": "Canned Fish",
                "var": true
              },
              {
                "categoryId": 10002464,
                "children": [],
                "leaf": true,
                "name": "Canned Fruit",
                "var": true
              },
              {
                "categoryId": 10002465,
                "children": [],
                "leaf": true,
                "name": "Canned Meat",
                "var": true
              },
              {
                "categoryId": 10002466,
                "children": [],
                "leaf": true,
                "name": "Canned Soup & Pasta",
                "var": true
              },
              {
                "categoryId": 10002467,
                "children": [],
                "leaf": true,
                "name": "Canned Vegetables",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Canned Food",
            "var": true
          },
          {
            "categoryId": 7223,
            "children": [],
            "leaf": true,
            "name": "Condiments & Pickles",
            "var": true
          },
          {
            "categoryId": 10002144,
            "children": [
              {
                "categoryId": 10002459,
                "children": [],
                "leaf": true,
                "name": "Chinese Dried Goods",
                "var": true
              },
              {
                "categoryId": 10002460,
                "children": [],
                "leaf": true,
                "name": "Cooked Meat",
                "var": true
              },
              {
                "categoryId": 10002461,
                "children": [],
                "leaf": true,
                "name": "Dried Fruit Nuts",
                "var": true
              },
              {
                "categoryId": 10002462,
                "children": [],
                "leaf": true,
                "name": "Seaweed",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Dried Goods",
            "var": true
          },
          {
            "categoryId": 10002143,
            "children": [
              {
                "categoryId": 10002455,
                "children": [],
                "leaf": true,
                "name": "Beans & Chickpeas",
                "var": true
              },
              {
                "categoryId": 10002456,
                "children": [],
                "leaf": true,
                "name": "Cous Cous",
                "var": true
              },
              {
                "categoryId": 10002457,
                "children": [],
                "leaf": true,
                "name": "Grains",
                "var": true
              },
              {
                "categoryId": 10002458,
                "children": [],
                "leaf": true,
                "name": "Lentils",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Grains, Beans &Pulses",
            "var": true
          },
          {
            "categoryId": 10002142,
            "children": [
              {
                "categoryId": 10002450,
                "children": [],
                "leaf": true,
                "name": "Instant Noodles",
                "var": true
              },
              {
                "categoryId": 10002451,
                "children": [],
                "leaf": true,
                "name": "Instant Pasta",
                "var": true
              },
              {
                "categoryId": 10002453,
                "children": [],
                "leaf": true,
                "name": "Instant Porridge",
                "var": true
              },
              {
                "categoryId": 10002452,
                "children": [],
                "leaf": true,
                "name": "Instant Soup",
                "var": true
              },
              {
                "categoryId": 10002454,
                "children": [],
                "leaf": true,
                "name": "Ready to Eat",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Instant & Ready to Eat",
            "var": true
          },
          {
            "categoryId": 10002141,
            "children": [
              {
                "categoryId": 10002448,
                "children": [],
                "leaf": true,
                "name": "Olives Pickles Chutneys",
                "var": true
              },
              {
                "categoryId": 10002449,
                "children": [],
                "leaf": true,
                "name": "Other Jarred Food",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Jarred Food",
            "var": true
          },
          {
            "categoryId": 10002140,
            "children": [
              {
                "categoryId": 10002445,
                "children": [],
                "leaf": true,
                "name": "Noodles",
                "var": true
              },
              {
                "categoryId": 10002446,
                "children": [],
                "leaf": true,
                "name": "Pasta",
                "var": true
              },
              {
                "categoryId": 10002447,
                "children": [],
                "leaf": true,
                "name": "Rice",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Noodles, Pasta & Rice",
            "var": true
          },
          {
            "categoryId": 7228,
            "children": [],
            "leaf": true,
            "name": "Sauces, Gravies, Marinades",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Canned, Dry & Packaged Foods",
        "var": true
      },
      {
        "categoryId": 3776,
        "children": [
          {
            "categoryId": 3779,
            "children": [],
            "leaf": true,
            "name": "Lighters",
            "var": true
          },
          {
            "categoryId": 3780,
            "children": [],
            "leaf": true,
            "name": "Matches",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Cigars & Cigarettes",
        "var": true
      },
      {
        "categoryId": 10002055,
        "children": [
          {
            "categoryId": 10002173,
            "children": [],
            "leaf": true,
            "name": "Cheese",
            "var": true
          },
          {
            "categoryId": 10002168,
            "children": [
              {
                "categoryId": 10002511,
                "children": [],
                "leaf": true,
                "name": "Chilled Coffee",
                "var": true
              },
              {
                "categoryId": 10002512,
                "children": [],
                "leaf": true,
                "name": "Chilled Tea",
                "var": true
              },
              {
                "categoryId": 10002513,
                "children": [],
                "leaf": true,
                "name": "Coconut",
                "var": true
              },
              {
                "categoryId": 10002514,
                "children": [],
                "leaf": true,
                "name": "Cold Pressed Juice",
                "var": true
              },
              {
                "categoryId": 10002515,
                "children": [],
                "leaf": true,
                "name": "Fresh Apple Juice",
                "var": true
              },
              {
                "categoryId": 10002516,
                "children": [],
                "leaf": true,
                "name": "Fresh Orange Juice",
                "var": true
              },
              {
                "categoryId": 10002517,
                "children": [],
                "leaf": true,
                "name": "Other Drinks",
                "var": true
              },
              {
                "categoryId": 10002518,
                "children": [],
                "leaf": true,
                "name": "Other Fresh Fruit Juice",
                "var": true
              },
              {
                "categoryId": 10002519,
                "children": [],
                "leaf": true,
                "name": "Shakes And Smoothies",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Chilled Drinks",
            "var": true
          },
          {
            "categoryId": 10002172,
            "children": [
              {
                "categoryId": 10002540,
                "children": [],
                "leaf": true,
                "name": "Dim Sum",
                "var": true
              },
              {
                "categoryId": 10002541,
                "children": [],
                "leaf": true,
                "name": "Fresh Pasta & Sauce",
                "var": true
              },
              {
                "categoryId": 10002542,
                "children": [],
                "leaf": true,
                "name": "Mock Meat & Seafood",
                "var": true
              },
              {
                "categoryId": 10002543,
                "children": [],
                "leaf": true,
                "name": "Noodles",
                "var": true
              },
              {
                "categoryId": 10002545,
                "children": [],
                "leaf": true,
                "name": "Pickles & Chutneys",
                "var": true
              },
              {
                "categoryId": 10002544,
                "children": [],
                "leaf": true,
                "name": "Ready Meals",
                "var": true
              },
              {
                "categoryId": 10002546,
                "children": [],
                "leaf": true,
                "name": "Salad Dressing",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Convenience Foods",
            "var": true
          },
          {
            "categoryId": 10002171,
            "children": [
              {
                "categoryId": 10002533,
                "children": [],
                "leaf": true,
                "name": "Asian Pastes & Sauces",
                "var": true
              },
              {
                "categoryId": 10002534,
                "children": [],
                "leaf": true,
                "name": "Coconut",
                "var": true
              },
              {
                "categoryId": 10002535,
                "children": [],
                "leaf": true,
                "name": "Garlic & Shallots",
                "var": true
              },
              {
                "categoryId": 10002536,
                "children": [],
                "leaf": true,
                "name": "Home Baking",
                "var": true
              },
              {
                "categoryId": 10002537,
                "children": [],
                "leaf": true,
                "name": "Ready to Roll Pastry",
                "var": true
              },
              {
                "categoryId": 10002538,
                "children": [],
                "leaf": true,
                "name": "Seasoning",
                "var": true
              },
              {
                "categoryId": 10002539,
                "children": [],
                "leaf": true,
                "name": "Stock, Gravy & Broth",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Cooking Ingredients",
            "var": true
          },
          {
            "categoryId": 10002170,
            "children": [
              {
                "categoryId": 10002529,
                "children": [],
                "leaf": true,
                "name": "Antipasti",
                "var": true
              },
              {
                "categoryId": 10002530,
                "children": [],
                "leaf": true,
                "name": "Deli Meat",
                "var": true
              },
              {
                "categoryId": 10002531,
                "children": [],
                "leaf": true,
                "name": "Dips",
                "var": true
              },
              {
                "categoryId": 10002532,
                "children": [],
                "leaf": true,
                "name": "Ethnic",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Deli",
            "var": true
          },
          {
            "categoryId": 10002169,
            "children": [
              {
                "categoryId": 10002520,
                "children": [],
                "leaf": true,
                "name": "Cakes",
                "var": true
              },
              {
                "categoryId": 10002521,
                "children": [],
                "leaf": true,
                "name": "Cheese Cakes",
                "var": true
              },
              {
                "categoryId": 10002522,
                "children": [],
                "leaf": true,
                "name": "Chocolates",
                "var": true
              },
              {
                "categoryId": 10002523,
                "children": [],
                "leaf": true,
                "name": "Jelly Pudding & Custard",
                "var": true
              },
              {
                "categoryId": 10002528,
                "children": [],
                "leaf": true,
                "name": "Others",
                "var": true
              },
              {
                "categoryId": 10002524,
                "children": [],
                "leaf": true,
                "name": "Ready-to-Roll Pastry",
                "var": true
              },
              {
                "categoryId": 10002525,
                "children": [],
                "leaf": true,
                "name": "Soy Dessert",
                "var": true
              },
              {
                "categoryId": 10002526,
                "children": [],
                "leaf": true,
                "name": "Sweet Pastries",
                "var": true
              },
              {
                "categoryId": 10002527,
                "children": [],
                "leaf": true,
                "name": "Sweet Soups",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Desserts",
            "var": true
          },
          {
            "categoryId": 10002167,
            "children": [
              {
                "categoryId": 10002504,
                "children": [],
                "leaf": true,
                "name": "Butter",
                "var": true
              },
              {
                "categoryId": 10002506,
                "children": [],
                "leaf": true,
                "name": "Cream",
                "var": true
              },
              {
                "categoryId": 10002507,
                "children": [],
                "leaf": true,
                "name": "Cultured Milk",
                "var": true
              },
              {
                "categoryId": 10002508,
                "children": [],
                "leaf": true,
                "name": "Eggs",
                "var": true
              },
              {
                "categoryId": 10002509,
                "children": [],
                "leaf": true,
                "name": "Fresh Milk",
                "var": true
              },
              {
                "categoryId": 10002505,
                "children": [],
                "leaf": true,
                "name": "Margarine & Spread",
                "var": true
              },
              {
                "categoryId": 10002510,
                "children": [],
                "leaf": true,
                "name": "Soy & Dairy Free Milk",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Milk, Butter & Eggs",
            "var": true
          },
          {
            "categoryId": 10002166,
            "children": [],
            "leaf": true,
            "name": "Tofu",
            "var": true
          },
          {
            "categoryId": 10002165,
            "children": [
              {
                "categoryId": 10002503,
                "children": [],
                "leaf": true,
                "name": "Yoghurt Drinks",
                "var": true
              },
              {
                "categoryId": 10002502,
                "children": [],
                "leaf": true,
                "name": "Yogurt",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Yoghurt",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Dairy & Chilled",
        "var": true
      },
      {
        "categoryId": 10002056,
        "children": [
          {
            "categoryId": 10002179,
            "children": [
              {
                "categoryId": 10002607,
                "children": [],
                "leaf": true,
                "name": "Chye Sim/CaiXin",
                "var": true
              },
              {
                "categoryId": 10002608,
                "children": [],
                "leaf": true,
                "name": "Leaves",
                "var": true
              },
              {
                "categoryId": 10002609,
                "children": [],
                "leaf": true,
                "name": "Pak Choi",
                "var": true
              },
              {
                "categoryId": 10002610,
                "children": [],
                "leaf": true,
                "name": "Spinach",
                "var": true
              },
              {
                "categoryId": 10002611,
                "children": [],
                "leaf": true,
                "name": "Sprouts Microgreens",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Asian Vegetables",
            "var": true
          },
          {
            "categoryId": 10002176,
            "children": [
              {
                "categoryId": 10002592,
                "children": [],
                "leaf": true,
                "name": "Apples",
                "var": true
              },
              {
                "categoryId": 10002593,
                "children": [],
                "leaf": true,
                "name": "Bananas",
                "var": true
              },
              {
                "categoryId": 10002597,
                "children": [],
                "leaf": true,
                "name": "Berries",
                "var": true
              },
              {
                "categoryId": 10002594,
                "children": [],
                "leaf": true,
                "name": "Citrus",
                "var": true
              },
              {
                "categoryId": 10002598,
                "children": [],
                "leaf": true,
                "name": "Coconut",
                "var": true
              },
              {
                "categoryId": 10002599,
                "children": [],
                "leaf": true,
                "name": "Exotic Fruits",
                "var": true
              },
              {
                "categoryId": 10002600,
                "children": [],
                "leaf": true,
                "name": "Grapes",
                "var": true
              },
              {
                "categoryId": 10002601,
                "children": [],
                "leaf": true,
                "name": "Mangoes",
                "var": true
              },
              {
                "categoryId": 10002602,
                "children": [],
                "leaf": true,
                "name": "Melons",
                "var": true
              },
              {
                "categoryId": 10002603,
                "children": [],
                "leaf": true,
                "name": "Papaya",
                "var": true
              },
              {
                "categoryId": 10002595,
                "children": [],
                "leaf": true,
                "name": "Pears",
                "var": true
              },
              {
                "categoryId": 10002604,
                "children": [],
                "leaf": true,
                "name": "Pineapple",
                "var": true
              },
              {
                "categoryId": 10002596,
                "children": [],
                "leaf": true,
                "name": "Stonefruits",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Fresh Fruit",
            "var": true
          },
          {
            "categoryId": 10002175,
            "children": [],
            "leaf": true,
            "name": "Fresh Herbs & Spices",
            "var": true
          },
          {
            "categoryId": 10002174,
            "children": [
              {
                "categoryId": 10002547,
                "children": [],
                "leaf": true,
                "name": "Aloe Vera",
                "var": true
              },
              {
                "categoryId": 10002548,
                "children": [],
                "leaf": true,
                "name": "Asparagus",
                "var": true
              },
              {
                "categoryId": 10002549,
                "children": [],
                "leaf": true,
                "name": "Avocados",
                "var": true
              },
              {
                "categoryId": 10002550,
                "children": [],
                "leaf": true,
                "name": "Baby Vegetables & Edible Flowers",
                "var": true
              },
              {
                "categoryId": 10002551,
                "children": [],
                "leaf": true,
                "name": "Beans",
                "var": true
              },
              {
                "categoryId": 10002574,
                "children": [],
                "leaf": true,
                "name": "Beetroot",
                "var": true
              },
              {
                "categoryId": 10002552,
                "children": [],
                "leaf": true,
                "name": "Brinjal",
                "var": true
              },
              {
                "categoryId": 10002553,
                "children": [],
                "leaf": true,
                "name": "Broccoli",
                "var": true
              },
              {
                "categoryId": 10002554,
                "children": [],
                "leaf": true,
                "name": "Brussel Sprouts",
                "var": true
              },
              {
                "categoryId": 10002555,
                "children": [],
                "leaf": true,
                "name": "Cabbage",
                "var": true
              },
              {
                "categoryId": 10002556,
                "children": [],
                "leaf": true,
                "name": "Capsicums",
                "var": true
              },
              {
                "categoryId": 10002589,
                "children": [],
                "leaf": true,
                "name": "Carrots & Root Vegetables",
                "var": true
              },
              {
                "categoryId": 10002557,
                "children": [],
                "leaf": true,
                "name": "Cauliflower",
                "var": true
              },
              {
                "categoryId": 10002558,
                "children": [],
                "leaf": true,
                "name": "Celery",
                "var": true
              },
              {
                "categoryId": 10002559,
                "children": [],
                "leaf": true,
                "name": "Chillies",
                "var": true
              },
              {
                "categoryId": 10002560,
                "children": [],
                "leaf": true,
                "name": "Courgettes",
                "var": true
              },
              {
                "categoryId": 10002561,
                "children": [],
                "leaf": true,
                "name": "Cucumber",
                "var": true
              },
              {
                "categoryId": 10002562,
                "children": [],
                "leaf": true,
                "name": "Endives",
                "var": true
              },
              {
                "categoryId": 10002563,
                "children": [],
                "leaf": true,
                "name": "Fennel",
                "var": true
              },
              {
                "categoryId": 10002575,
                "children": [],
                "leaf": true,
                "name": "Garlic",
                "var": true
              },
              {
                "categoryId": 10002576,
                "children": [],
                "leaf": true,
                "name": "Ginger",
                "var": true
              },
              {
                "categoryId": 10002577,
                "children": [],
                "leaf": true,
                "name": "Gourds",
                "var": true
              },
              {
                "categoryId": 10002590,
                "children": [],
                "leaf": true,
                "name": "Leafy Vegetables",
                "var": true
              },
              {
                "categoryId": 10002565,
                "children": [],
                "leaf": true,
                "name": "Leeks",
                "var": true
              },
              {
                "categoryId": 10002566,
                "children": [],
                "leaf": true,
                "name": "Lettuce",
                "var": true
              },
              {
                "categoryId": 10002578,
                "children": [],
                "leaf": true,
                "name": "LotusRoot",
                "var": true
              },
              {
                "categoryId": 10002567,
                "children": [],
                "leaf": true,
                "name": "Mixed",
                "var": true
              },
              {
                "categoryId": 10002579,
                "children": [],
                "leaf": true,
                "name": "Nuts",
                "var": true
              },
              {
                "categoryId": 10002564,
                "children": [],
                "leaf": true,
                "name": "Okra",
                "var": true
              },
              {
                "categoryId": 10002580,
                "children": [],
                "leaf": true,
                "name": "Onions",
                "var": true
              },
              {
                "categoryId": 10002581,
                "children": [],
                "leaf": true,
                "name": "Parsnips",
                "var": true
              },
              {
                "categoryId": 10002568,
                "children": [],
                "leaf": true,
                "name": "Peas",
                "var": true
              },
              {
                "categoryId": 10002591,
                "children": [],
                "leaf": true,
                "name": "Peas, Beans & Sweetcorn",
                "var": true
              },
              {
                "categoryId": 10002582,
                "children": [],
                "leaf": true,
                "name": "Potatoes",
                "var": true
              },
              {
                "categoryId": 10002569,
                "children": [],
                "leaf": true,
                "name": "Preserved Vegetables",
                "var": true
              },
              {
                "categoryId": 10002583,
                "children": [],
                "leaf": true,
                "name": "Pumpkin & Squash",
                "var": true
              },
              {
                "categoryId": 10002584,
                "children": [],
                "leaf": true,
                "name": "Radish",
                "var": true
              },
              {
                "categoryId": 10002585,
                "children": [],
                "leaf": true,
                "name": "Roots",
                "var": true
              },
              {
                "categoryId": 10002570,
                "children": [],
                "leaf": true,
                "name": "Spring Onions",
                "var": true
              },
              {
                "categoryId": 10002571,
                "children": [],
                "leaf": true,
                "name": "Sprouts Microgreens",
                "var": true
              },
              {
                "categoryId": 10002572,
                "children": [],
                "leaf": true,
                "name": "Sweet Corn",
                "var": true
              },
              {
                "categoryId": 10002586,
                "children": [],
                "leaf": true,
                "name": "Sweet Potatoes",
                "var": true
              },
              {
                "categoryId": 10002573,
                "children": [],
                "leaf": true,
                "name": "Tomatoes",
                "var": true
              },
              {
                "categoryId": 10002587,
                "children": [],
                "leaf": true,
                "name": "Turnip",
                "var": true
              },
              {
                "categoryId": 10002588,
                "children": [],
                "leaf": true,
                "name": "Yams",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Fresh Vegetables",
            "var": true
          },
          {
            "categoryId": 10002178,
            "children": [],
            "leaf": true,
            "name": "Mushrooms",
            "var": true
          },
          {
            "categoryId": 10002180,
            "children": [],
            "leaf": true,
            "name": "Other Vegetables",
            "var": true
          },
          {
            "categoryId": 10002177,
            "children": [
              {
                "categoryId": 10002606,
                "children": [],
                "leaf": true,
                "name": "Packaged Salad",
                "var": true
              },
              {
                "categoryId": 10002605,
                "children": [],
                "leaf": true,
                "name": "Ready-to Eat Salad",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Salad",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Fresh Produce",
        "var": true
      },
      {
        "categoryId": 10002057,
        "children": [
          {
            "categoryId": 10002188,
            "children": [
              {
                "categoryId": 10002646,
                "children": [],
                "leaf": true,
                "name": "Frozen Bread",
                "var": true
              },
              {
                "categoryId": 10002647,
                "children": [],
                "leaf": true,
                "name": "Panckaes & Waffles",
                "var": true
              },
              {
                "categoryId": 10002648,
                "children": [],
                "leaf": true,
                "name": "Speciality Bread",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Bread, Bagels & Pancakes",
            "var": true
          },
          {
            "categoryId": 10002187,
            "children": [
              {
                "categoryId": 10002639,
                "children": [],
                "leaf": true,
                "name": "Chilled Soup",
                "var": true
              },
              {
                "categoryId": 10002640,
                "children": [],
                "leaf": true,
                "name": "Dim Sum",
                "var": true
              },
              {
                "categoryId": 10002641,
                "children": [],
                "leaf": true,
                "name": "Noodles",
                "var": true
              },
              {
                "categoryId": 10002642,
                "children": [],
                "leaf": true,
                "name": "Pizza",
                "var": true
              },
              {
                "categoryId": 10002643,
                "children": [],
                "leaf": true,
                "name": "Ready Meals",
                "var": true
              },
              {
                "categoryId": 10002644,
                "children": [],
                "leaf": true,
                "name": "Ready-to-Eat Meals",
                "var": true
              },
              {
                "categoryId": 10002645,
                "children": [],
                "leaf": true,
                "name": "Soups",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Convenience Foods",
            "var": true
          },
          {
            "categoryId": 10002186,
            "children": [
              {
                "categoryId": 10002637,
                "children": [],
                "leaf": true,
                "name": "Cakes",
                "var": true
              },
              {
                "categoryId": 10002635,
                "children": [],
                "leaf": true,
                "name": "Jelly Pudding",
                "var": true
              },
              {
                "categoryId": 10002636,
                "children": [],
                "leaf": true,
                "name": "Soy Dessert",
                "var": true
              },
              {
                "categoryId": 10002638,
                "children": [],
                "leaf": true,
                "name": "Sweet Pastries",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Frozen Desserts",
            "var": true
          },
          {
            "categoryId": 10002184,
            "children": [
              {
                "categoryId": 10002628,
                "children": [],
                "leaf": true,
                "name": "Frozen Yogurt",
                "var": true
              },
              {
                "categoryId": 10002627,
                "children": [],
                "leaf": true,
                "name": "Ice Cream",
                "var": true
              },
              {
                "categoryId": 10002629,
                "children": [],
                "leaf": true,
                "name": "Sorbet",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Ice Cream",
            "var": true
          },
          {
            "categoryId": 10002183,
            "children": [
              {
                "categoryId": 10002616,
                "children": [],
                "leaf": true,
                "name": "Beef",
                "var": true
              },
              {
                "categoryId": 10002617,
                "children": [],
                "leaf": true,
                "name": "Burger & Sausage",
                "var": true
              },
              {
                "categoryId": 10002618,
                "children": [],
                "leaf": true,
                "name": "Chicken",
                "var": true
              },
              {
                "categoryId": 10002619,
                "children": [],
                "leaf": true,
                "name": "Chicken Franks Nuggets",
                "var": true
              },
              {
                "categoryId": 10002620,
                "children": [],
                "leaf": true,
                "name": "Duck",
                "var": true
              },
              {
                "categoryId": 10002621,
                "children": [],
                "leaf": true,
                "name": "Lamb",
                "var": true
              },
              {
                "categoryId": 10002622,
                "children": [],
                "leaf": true,
                "name": "Pork",
                "var": true
              },
              {
                "categoryId": 10002623,
                "children": [],
                "leaf": true,
                "name": "Poultry",
                "var": true
              },
              {
                "categoryId": 10002624,
                "children": [],
                "leaf": true,
                "name": "Satay",
                "var": true
              },
              {
                "categoryId": 10002625,
                "children": [],
                "leaf": true,
                "name": "Sliced Meat",
                "var": true
              },
              {
                "categoryId": 10002626,
                "children": [],
                "leaf": true,
                "name": "Turkey",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Meat",
            "var": true
          },
          {
            "categoryId": 10002182,
            "children": [],
            "leaf": true,
            "name": "Mock Meat & Seafood",
            "var": true
          },
          {
            "categoryId": 10002185,
            "children": [
              {
                "categoryId": 10002630,
                "children": [],
                "leaf": true,
                "name": "Breaded Seafood",
                "var": true
              },
              {
                "categoryId": 10002631,
                "children": [],
                "leaf": true,
                "name": "Crabsticks & Fishballs",
                "var": true
              },
              {
                "categoryId": 10002632,
                "children": [],
                "leaf": true,
                "name": "Fish & Fingers Cakes",
                "var": true
              },
              {
                "categoryId": 10002633,
                "children": [],
                "leaf": true,
                "name": "Frozen Fish Fillet",
                "var": true
              },
              {
                "categoryId": 10002634,
                "children": [],
                "leaf": true,
                "name": "Frozen Shellfish",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Seafood",
            "var": true
          },
          {
            "categoryId": 10002181,
            "children": [
              {
                "categoryId": 10002612,
                "children": [],
                "leaf": true,
                "name": "Fruits",
                "var": true
              },
              {
                "categoryId": 10002613,
                "children": [],
                "leaf": true,
                "name": "Vegetables",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Vegetables & Fruits",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Frozen",
        "var": true
      },
      {
        "categoryId": 7236,
        "children": [],
        "leaf": true,
        "name": "Gourmet Food and Gifts",
        "var": true
      },
      {
        "categoryId": 7185,
        "children": [
          {
            "categoryId": 10002134,
            "children": [
              {
                "categoryId": 10002406,
                "children": [],
                "leaf": true,
                "name": "Air Fresheners",
                "var": true
              },
              {
                "categoryId": 10002407,
                "children": [],
                "leaf": true,
                "name": "Dehumidifiers",
                "var": true
              }
            ],
            "leaf": false,
            "name": "AirCare",
            "var": true
          },
          {
            "categoryId": 10002133,
            "children": [
              {
                "categoryId": 10002394,
                "children": [],
                "leaf": true,
                "name": "Bathroom",
                "var": true
              },
              {
                "categoryId": 10002395,
                "children": [],
                "leaf": true,
                "name": "Bleach",
                "var": true
              },
              {
                "categoryId": 10002396,
                "children": [],
                "leaf": true,
                "name": "Cleaning Accessories",
                "var": true
              },
              {
                "categoryId": 10002397,
                "children": [],
                "leaf": true,
                "name": "Drain Cleaners",
                "var": true
              },
              {
                "categoryId": 10002398,
                "children": [],
                "leaf": true,
                "name": "Fabric Upholstery",
                "var": true
              },
              {
                "categoryId": 10002399,
                "children": [],
                "leaf": true,
                "name": "Floors",
                "var": true
              },
              {
                "categoryId": 10002400,
                "children": [],
                "leaf": true,
                "name": "Glass Cleaner",
                "var": true
              },
              {
                "categoryId": 10002401,
                "children": [],
                "leaf": true,
                "name": "Kitchen",
                "var": true
              },
              {
                "categoryId": 10002402,
                "children": [],
                "leaf": true,
                "name": "Multipurpose Cleaner",
                "var": true
              },
              {
                "categoryId": 10002405,
                "children": [],
                "leaf": true,
                "name": "Outdoor",
                "var": true
              },
              {
                "categoryId": 10002403,
                "children": [],
                "leaf": true,
                "name": "Polish And Wax",
                "var": true
              },
              {
                "categoryId": 10002404,
                "children": [],
                "leaf": true,
                "name": "Polish Wax",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Cleaning",
            "var": true
          },
          {
            "categoryId": 7186,
            "children": [],
            "leaf": true,
            "name": "Detergent",
            "var": true
          },
          {
            "categoryId": 10002132,
            "children": [
              {
                "categoryId": 10002392,
                "children": [],
                "leaf": true,
                "name": "Automatic Dishwashing",
                "var": true
              },
              {
                "categoryId": 10002393,
                "children": [],
                "leaf": true,
                "name": "Hand Dishwashing",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Dishwashing",
            "var": true
          },
          {
            "categoryId": 10002128,
            "children": [
              {
                "categoryId": 10002373,
                "children": [],
                "leaf": true,
                "name": "Bleach",
                "var": true
              },
              {
                "categoryId": 10002374,
                "children": [],
                "leaf": true,
                "name": "Delicate Care",
                "var": true
              },
              {
                "categoryId": 10002375,
                "children": [],
                "leaf": true,
                "name": "Fabric Conditioners",
                "var": true
              },
              {
                "categoryId": 10002376,
                "children": [],
                "leaf": true,
                "name": "Fresheners & Ironing",
                "var": true
              },
              {
                "categoryId": 10002377,
                "children": [],
                "leaf": true,
                "name": "Stain Remover",
                "var": true
              },
              {
                "categoryId": 10002378,
                "children": [],
                "leaf": true,
                "name": "Washing Liquid",
                "var": true
              },
              {
                "categoryId": 10002379,
                "children": [],
                "leaf": true,
                "name": "Washing Machine Cleaner",
                "var": true
              },
              {
                "categoryId": 10002380,
                "children": [],
                "leaf": true,
                "name": "Washing Powder",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Laundry",
            "var": true
          },
          {
            "categoryId": 10002131,
            "children": [
              {
                "categoryId": 10002389,
                "children": [],
                "leaf": true,
                "name": "Disposable Utensils & Gadgets",
                "var": true
              },
              {
                "categoryId": 10002391,
                "children": [],
                "leaf": true,
                "name": "Food Storage & Party",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Laundry & Household Sundries",
            "var": true
          },
          {
            "categoryId": 10002130,
            "children": [
              {
                "categoryId": 10002386,
                "children": [],
                "leaf": true,
                "name": "Kitchen Roll",
                "var": true
              },
              {
                "categoryId": 10002387,
                "children": [],
                "leaf": true,
                "name": "Tissues",
                "var": true
              },
              {
                "categoryId": 10002388,
                "children": [],
                "leaf": true,
                "name": "Toilet Paper",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Paper",
            "var": true
          },
          {
            "categoryId": 10002129,
            "children": [
              {
                "categoryId": 10002381,
                "children": [],
                "leaf": true,
                "name": "Insect Baits",
                "var": true
              },
              {
                "categoryId": 10002382,
                "children": [],
                "leaf": true,
                "name": "Insecticide Coil",
                "var": true
              },
              {
                "categoryId": 10002383,
                "children": [],
                "leaf": true,
                "name": "Insecticide Devices",
                "var": true
              },
              {
                "categoryId": 10002384,
                "children": [],
                "leaf": true,
                "name": "Insecticide Sprays",
                "var": true
              },
              {
                "categoryId": 10002385,
                "children": [],
                "leaf": true,
                "name": "Others",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Pest Control",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Laundry & Household",
        "var": true
      },
      {
        "categoryId": 7229,
        "children": [
          {
            "categoryId": 7235,
            "children": [],
            "leaf": true,
            "name": "Biscuit",
            "var": true
          },
          {
            "categoryId": 7230,
            "children": [],
            "leaf": true,
            "name": "Breadsticks",
            "var": true
          },
          {
            "categoryId": 10002146,
            "children": [],
            "leaf": true,
            "name": "Chips",
            "var": true
          },
          {
            "categoryId": 7231,
            "children": [],
            "leaf": true,
            "name": "Chips & Crisps",
            "var": true
          },
          {
            "categoryId": 7232,
            "children": [],
            "leaf": true,
            "name": "Cookies",
            "var": true
          },
          {
            "categoryId": 7233,
            "children": [],
            "leaf": true,
            "name": "Crackers",
            "var": true
          },
          {
            "categoryId": 10002147,
            "children": [],
            "leaf": true,
            "name": "Dips",
            "var": true
          },
          {
            "categoryId": 10002148,
            "children": [],
            "leaf": true,
            "name": "Dried Fruit & Vegetable Chips",
            "var": true
          },
          {
            "categoryId": 10002149,
            "children": [],
            "leaf": true,
            "name": "Indian Snacks",
            "var": true
          },
          {
            "categoryId": 10002154,
            "children": [],
            "leaf": true,
            "name": "Local Specialty",
            "var": true
          },
          {
            "categoryId": 7234,
            "children": [],
            "leaf": true,
            "name": "Nuts",
            "var": true
          },
          {
            "categoryId": 10002156,
            "children": [],
            "leaf": true,
            "name": "Other Snacks",
            "var": true
          },
          {
            "categoryId": 10002150,
            "children": [],
            "leaf": true,
            "name": "Popcorn",
            "var": true
          },
          {
            "categoryId": 10002151,
            "children": [],
            "leaf": true,
            "name": "PrawnFish",
            "var": true
          },
          {
            "categoryId": 9402,
            "children": [],
            "leaf": true,
            "name": "Pretzels",
            "var": true
          },
          {
            "categoryId": 10002152,
            "children": [],
            "leaf": true,
            "name": "Sauces & Syrup",
            "var": true
          },
          {
            "categoryId": 10002153,
            "children": [],
            "leaf": true,
            "name": "Seaweed Laver",
            "var": true
          },
          {
            "categoryId": 10002155,
            "children": [],
            "leaf": true,
            "name": "Wafers",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Snacks",
        "var": true
      }
    ],
    "leaf": false,
    "name": "Groceries",
    "var": true
  },
  {
    "categoryId": 1438,
    "children": [
      {
        "categoryId": 1439,
        "children": [
          {
            "categoryId": 1440,
            "children": [],
            "leaf": true,
            "name": "Bath & Body Accessories",
            "var": false
          },
          {
            "categoryId": 1468,
            "children": [],
            "leaf": true,
            "name": "Body & Massage Oils",
            "var": false
          },
          {
            "categoryId": 1478,
            "children": [],
            "leaf": true,
            "name": "Body Moisturizers",
            "var": false
          },
          {
            "categoryId": 1470,
            "children": [],
            "leaf": true,
            "name": "Body Scrubs",
            "var": false
          },
          {
            "categoryId": 1471,
            "children": [],
            "leaf": true,
            "name": "Body Soaps & Shower Gels",
            "var": false
          },
          {
            "categoryId": 1685,
            "children": [],
            "leaf": true,
            "name": "Breast Care",
            "var": false
          },
          {
            "categoryId": 10002060,
            "children": [
              {
                "categoryId": 10002195,
                "children": [],
                "leaf": true,
                "name": "Foot Deodorant",
                "var": false
              },
              {
                "categoryId": 10002197,
                "children": [],
                "leaf": true,
                "name": "Foot Masks",
                "var": false
              },
              {
                "categoryId": 10002196,
                "children": [],
                "leaf": true,
                "name": "Lotion, Cream and Scrubs",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Foot Care",
            "var": false
          },
          {
            "categoryId": 1467,
            "children": [],
            "leaf": true,
            "name": "Gifts & Value Sets",
            "var": false
          },
          {
            "categoryId": 1475,
            "children": [],
            "leaf": true,
            "name": "Hair Removal",
            "var": false
          },
          {
            "categoryId": 10002058,
            "children": [
              {
                "categoryId": 10002194,
                "children": [],
                "leaf": true,
                "name": "Hand Masks",
                "var": false
              },
              {
                "categoryId": 20000200,
                "children": [],
                "leaf": true,
                "name": "Hand Sanitizers",
                "var": true
              },
              {
                "categoryId": 10002192,
                "children": [],
                "leaf": true,
                "name": "Hand Washes",
                "var": false
              },
              {
                "categoryId": 10002193,
                "children": [],
                "leaf": true,
                "name": "Lotion, Cream and Scrubs",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hand Care",
            "var": false
          },
          {
            "categoryId": 1480,
            "children": [
              {
                "categoryId": 5245,
                "children": [],
                "leaf": true,
                "name": "Sunscreen & After Sun",
                "var": false
              },
              {
                "categoryId": 10002191,
                "children": [],
                "leaf": true,
                "name": "Tanning Oil & Self Tanners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Sun Care for Body",
            "var": false
          },
          {
            "categoryId": 10002059,
            "children": [],
            "leaf": true,
            "name": "Talcum Powder",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Bath & Body",
        "var": false
      },
      {
        "categoryId": 1628,
        "children": [
          {
            "categoryId": 1643,
            "children": [],
            "leaf": true,
            "name": "Body Slimming & Electric Massagers",
            "var": false
          },
          {
            "categoryId": 10002067,
            "children": [],
            "leaf": true,
            "name": "Foot Relief Accessories & Tools",
            "var": false
          },
          {
            "categoryId": 10002068,
            "children": [],
            "leaf": true,
            "name": "Hair Removal Accessories",
            "var": false
          },
          {
            "categoryId": 1637,
            "children": [],
            "leaf": true,
            "name": "Hair Removal Appliances",
            "var": false
          },
          {
            "categoryId": 1632,
            "children": [
              {
                "categoryId": 1633,
                "children": [],
                "leaf": true,
                "name": "Curling Irons & Wands",
                "var": false
              },
              {
                "categoryId": 1635,
                "children": [],
                "leaf": true,
                "name": "Flat Irons",
                "var": false
              },
              {
                "categoryId": 1634,
                "children": [],
                "leaf": true,
                "name": "Hair Dryers",
                "var": false
              },
              {
                "categoryId": 5264,
                "children": [],
                "leaf": true,
                "name": "Hair Styling Sets",
                "var": false
              },
              {
                "categoryId": 1636,
                "children": [],
                "leaf": true,
                "name": "Multi-stylers",
                "var": false
              },
              {
                "categoryId": 10002209,
                "children": [],
                "leaf": true,
                "name": "Straightening Combs & Brushes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hair Styling Appliances",
            "var": false
          },
          {
            "categoryId": 5272,
            "children": [],
            "leaf": true,
            "name": "Portable Saunas",
            "var": false
          },
          {
            "categoryId": 1629,
            "children": [
              {
                "categoryId": 1630,
                "children": [],
                "leaf": true,
                "name": "Body",
                "var": false
              },
              {
                "categoryId": 1631,
                "children": [],
                "leaf": true,
                "name": "Face",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Skin Care Tools",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Beauty Tools",
        "var": false
      },
      {
        "categoryId": 1565,
        "children": [
          {
            "categoryId": 4771,
            "children": [
              {
                "categoryId": 7298,
                "children": [],
                "leaf": true,
                "name": "Acne Care",
                "var": false
              },
              {
                "categoryId": 7299,
                "children": [],
                "leaf": true,
                "name": "Breast Enlargement",
                "var": false
              },
              {
                "categoryId": 7300,
                "children": [],
                "leaf": true,
                "name": "Gifts & Value Sets",
                "var": false
              },
              {
                "categoryId": 4773,
                "children": [],
                "leaf": true,
                "name": "Hair & Nail",
                "var": false
              },
              {
                "categoryId": 4774,
                "children": [],
                "leaf": true,
                "name": "Skin Nourishment",
                "var": false
              },
              {
                "categoryId": 4775,
                "children": [],
                "leaf": true,
                "name": "Whitening",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Beauty Supplements",
            "var": false
          },
          {
            "categoryId": 4795,
            "children": [
              {
                "categoryId": 4802,
                "children": [],
                "leaf": true,
                "name": "Post-Workout and Recovery",
                "var": false
              },
              {
                "categoryId": 4803,
                "children": [],
                "leaf": true,
                "name": "Pre-Workout",
                "var": false
              },
              {
                "categoryId": 10002207,
                "children": [],
                "leaf": true,
                "name": "Protein",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Sports Nutrition",
            "var": false
          },
          {
            "categoryId": 1575,
            "children": [
              {
                "categoryId": 1576,
                "children": [],
                "leaf": true,
                "name": "Appetite Suppressant",
                "var": false
              },
              {
                "categoryId": 4761,
                "children": [],
                "leaf": true,
                "name": "Carb Blockers",
                "var": false
              },
              {
                "categoryId": 10002203,
                "children": [],
                "leaf": true,
                "name": "Fat Blockers & Burners",
                "var": false
              },
              {
                "categoryId": 7297,
                "children": [],
                "leaf": true,
                "name": "Gifts & Value Sets",
                "var": false
              },
              {
                "categoryId": 4767,
                "children": [],
                "leaf": true,
                "name": "Meal Replacement",
                "var": false
              },
              {
                "categoryId": 10002204,
                "children": [],
                "leaf": true,
                "name": "Slimming Beverages",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Weight Management",
            "var": false
          },
          {
            "categoryId": 4776,
            "children": [
              {
                "categoryId": 4777,
                "children": [],
                "leaf": true,
                "name": "Anti-Aging",
                "var": false
              },
              {
                "categoryId": 4779,
                "children": [],
                "leaf": true,
                "name": "Bone & Joint Support",
                "var": false
              },
              {
                "categoryId": 4780,
                "children": [],
                "leaf": true,
                "name": "Brain & Memory",
                "var": false
              },
              {
                "categoryId": 4787,
                "children": [],
                "leaf": true,
                "name": "Detoxification",
                "var": false
              },
              {
                "categoryId": 7301,
                "children": [],
                "leaf": true,
                "name": "Diabetic Support",
                "var": false
              },
              {
                "categoryId": 4781,
                "children": [],
                "leaf": true,
                "name": "Digestive Care",
                "var": false
              },
              {
                "categoryId": 7307,
                "children": [],
                "leaf": true,
                "name": "Gifts & Value Sets",
                "var": false
              },
              {
                "categoryId": 4784,
                "children": [],
                "leaf": true,
                "name": "Heart & Blood Pressure",
                "var": false
              },
              {
                "categoryId": 1574,
                "children": [],
                "leaf": true,
                "name": "Herbal Supplements",
                "var": false
              },
              {
                "categoryId": 10002205,
                "children": [],
                "leaf": true,
                "name": "Herbs & Traditional Medicine",
                "var": false
              },
              {
                "categoryId": 4785,
                "children": [],
                "leaf": true,
                "name": "Immunity",
                "var": false
              },
              {
                "categoryId": 7306,
                "children": [],
                "leaf": true,
                "name": "Men's Health",
                "var": false
              },
              {
                "categoryId": 7302,
                "children": [],
                "leaf": true,
                "name": "Multivitamins",
                "var": false
              },
              {
                "categoryId": 10002206,
                "children": [],
                "leaf": true,
                "name": "Nutritional Foods & Drinks",
                "var": false
              },
              {
                "categoryId": 7303,
                "children": [],
                "leaf": true,
                "name": "Pregnancy Care",
                "var": false
              },
              {
                "categoryId": 4794,
                "children": [],
                "leaf": false,
                "name": "Sexual wellness",
                "var": false
              },
              {
                "categoryId": 4791,
                "children": [],
                "leaf": true,
                "name": "Stress, Sleep, and Anxiety",
                "var": false
              },
              {
                "categoryId": 7305,
                "children": [],
                "leaf": true,
                "name": "Vision Care",
                "var": false
              },
              {
                "categoryId": 4793,
                "children": [],
                "leaf": true,
                "name": "Women's Health",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Well Being",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Food Supplements",
        "var": false
      },
      {
        "categoryId": 1580,
        "children": [
          {
            "categoryId": 10002064,
            "children": [],
            "leaf": true,
            "name": "Men",
            "var": false
          },
          {
            "categoryId": 1584,
            "children": [
              {
                "categoryId": 1585,
                "children": [],
                "leaf": true,
                "name": "Body Spray",
                "var": false
              },
              {
                "categoryId": 1586,
                "children": [],
                "leaf": true,
                "name": "Eau de Cologne",
                "var": false
              },
              {
                "categoryId": 1587,
                "children": [],
                "leaf": true,
                "name": "Eau de Parfum",
                "var": false
              },
              {
                "categoryId": 1588,
                "children": [],
                "leaf": true,
                "name": "Eau de Toilette",
                "var": false
              },
              {
                "categoryId": 1589,
                "children": [],
                "leaf": true,
                "name": "Fragrance Sets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Men's Fragrance",
            "var": false
          },
          {
            "categoryId": 10002065,
            "children": [],
            "leaf": true,
            "name": "Unisex",
            "var": false
          },
          {
            "categoryId": 10002063,
            "children": [],
            "leaf": true,
            "name": "Women",
            "var": false
          },
          {
            "categoryId": 1590,
            "children": [
              {
                "categoryId": 1591,
                "children": [],
                "leaf": true,
                "name": "Body Spray & Mist",
                "var": false
              },
              {
                "categoryId": 1592,
                "children": [],
                "leaf": true,
                "name": "Eau De Cologne",
                "var": false
              },
              {
                "categoryId": 1593,
                "children": [],
                "leaf": true,
                "name": "Eau De Parfum",
                "var": false
              },
              {
                "categoryId": 1594,
                "children": [],
                "leaf": true,
                "name": "Eau De Toilette",
                "var": false
              },
              {
                "categoryId": 1595,
                "children": [],
                "leaf": true,
                "name": "Fragrance Sets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Women's Fragrance",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Fragrances",
        "var": false
      },
      {
        "categoryId": 1581,
        "children": [
          {
            "categoryId": 5286,
            "children": [],
            "leaf": false,
            "name": "Bath & Body Sets",
            "var": false
          },
          {
            "categoryId": 5280,
            "children": [],
            "leaf": true,
            "name": "Facial Sets",
            "var": false
          },
          {
            "categoryId": 5274,
            "children": [
              {
                "categoryId": 1582,
                "children": [],
                "leaf": false,
                "name": "Men",
                "var": false
              },
              {
                "categoryId": 1583,
                "children": [],
                "leaf": false,
                "name": "Women",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Fragrances",
            "var": false
          },
          {
            "categoryId": 5287,
            "children": [],
            "leaf": false,
            "name": "Hair Care Sets",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Gift Sets",
        "var": false
      },
      {
        "categoryId": 1604,
        "children": [
          {
            "categoryId": 10002066,
            "children": [],
            "leaf": true,
            "name": "Conditioner",
            "var": false
          },
          {
            "categoryId": 5261,
            "children": [],
            "leaf": true,
            "name": "Gifts & Value Sets",
            "var": false
          },
          {
            "categoryId": 1606,
            "children": [
              {
                "categoryId": 5244,
                "children": [],
                "leaf": true,
                "name": "Hair Accessories",
                "var": false
              },
              {
                "categoryId": 1608,
                "children": [],
                "leaf": true,
                "name": "Hair Brushes & Combs",
                "var": false
              },
              {
                "categoryId": 1609,
                "children": [],
                "leaf": false,
                "name": "Hair Combs",
                "var": false
              },
              {
                "categoryId": 1607,
                "children": [],
                "leaf": true,
                "name": "Wig & Hair Extensions & Pads",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hair Care Accessories",
            "var": false
          },
          {
            "categoryId": 1610,
            "children": [],
            "leaf": true,
            "name": "Hair Coloring",
            "var": false
          },
          {
            "categoryId": 1611,
            "children": [],
            "leaf": true,
            "name": "Hair Oils",
            "var": false
          },
          {
            "categoryId": 1612,
            "children": [],
            "leaf": true,
            "name": "Hair Styling",
            "var": false
          },
          {
            "categoryId": 1618,
            "children": [],
            "leaf": true,
            "name": "Hair Treatments",
            "var": false
          },
          {
            "categoryId": 1605,
            "children": [],
            "leaf": true,
            "name": "Shampoo",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Hair Care",
        "var": false
      },
      {
        "categoryId": 1492,
        "children": [
          {
            "categoryId": 1493,
            "children": [
              {
                "categoryId": 1494,
                "children": [],
                "leaf": true,
                "name": "Bronzers",
                "var": false
              },
              {
                "categoryId": 1496,
                "children": [],
                "leaf": true,
                "name": "Shimmers and Glitters",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Body",
            "var": false
          },
          {
            "categoryId": 20000092,
            "children": [],
            "leaf": true,
            "name": "Bulk Deals",
            "var": false
          },
          {
            "categoryId": 1497,
            "children": [
              {
                "categoryId": 1499,
                "children": [],
                "leaf": true,
                "name": "Eye Primers",
                "var": false
              },
              {
                "categoryId": 1500,
                "children": [],
                "leaf": true,
                "name": "Eye Shadow",
                "var": false
              },
              {
                "categoryId": 1501,
                "children": [],
                "leaf": true,
                "name": "Eyebrows",
                "var": false
              },
              {
                "categoryId": 1498,
                "children": [],
                "leaf": true,
                "name": "Eyeliners",
                "var": false
              },
              {
                "categoryId": 1503,
                "children": [],
                "leaf": true,
                "name": "False Eyelashes",
                "var": false
              },
              {
                "categoryId": 1504,
                "children": [],
                "leaf": true,
                "name": "Mascaras",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Eyes",
            "var": false
          },
          {
            "categoryId": 1505,
            "children": [
              {
                "categoryId": 5241,
                "children": [],
                "leaf": true,
                "name": "BB & CC",
                "var": false
              },
              {
                "categoryId": 1506,
                "children": [],
                "leaf": true,
                "name": "Blushes",
                "var": false
              },
              {
                "categoryId": 1507,
                "children": [],
                "leaf": true,
                "name": "Bronzers",
                "var": false
              },
              {
                "categoryId": 1508,
                "children": [],
                "leaf": true,
                "name": "Compacts & Powder",
                "var": false
              },
              {
                "categoryId": 1511,
                "children": [],
                "leaf": true,
                "name": "Concealer",
                "var": false
              },
              {
                "categoryId": 1512,
                "children": [],
                "leaf": true,
                "name": "Foundation",
                "var": false
              },
              {
                "categoryId": 1515,
                "children": [],
                "leaf": true,
                "name": "Highlighters & Contour",
                "var": false
              },
              {
                "categoryId": 1513,
                "children": [],
                "leaf": true,
                "name": "Makeup Base & Primer",
                "var": false
              },
              {
                "categoryId": 10002210,
                "children": [],
                "leaf": true,
                "name": "Setting & Finishing Spray",
                "var": false
              },
              {
                "categoryId": 1514,
                "children": [],
                "leaf": true,
                "name": "Tinted Moisturizer",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Face",
            "var": false
          },
          {
            "categoryId": 1517,
            "children": [
              {
                "categoryId": 1518,
                "children": [],
                "leaf": true,
                "name": "Lip Balm and Treatment",
                "var": false
              },
              {
                "categoryId": 1519,
                "children": [],
                "leaf": true,
                "name": "Lip Gloss",
                "var": false
              },
              {
                "categoryId": 1520,
                "children": [],
                "leaf": true,
                "name": "Lip Liners",
                "var": false
              },
              {
                "categoryId": 10002212,
                "children": [],
                "leaf": true,
                "name": "Lip Tint",
                "var": false
              },
              {
                "categoryId": 1522,
                "children": [],
                "leaf": true,
                "name": "Lipsticks",
                "var": false
              },
              {
                "categoryId": 10002211,
                "children": [],
                "leaf": true,
                "name": "Liquid Lipsticks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Lips",
            "var": false
          },
          {
            "categoryId": 1523,
            "children": [
              {
                "categoryId": 1525,
                "children": [],
                "leaf": true,
                "name": "Cotton Balls, Pads and Buds",
                "var": false
              },
              {
                "categoryId": 1526,
                "children": [],
                "leaf": true,
                "name": "Eyelash Curlers",
                "var": false
              },
              {
                "categoryId": 10002214,
                "children": [],
                "leaf": true,
                "name": "Eyelid Tape & Glue",
                "var": false
              },
              {
                "categoryId": 10002215,
                "children": [],
                "leaf": true,
                "name": "False Eyelashes ",
                "var": false
              },
              {
                "categoryId": 1528,
                "children": [],
                "leaf": true,
                "name": "Makeup Bags & Organizers",
                "var": false
              },
              {
                "categoryId": 10002213,
                "children": [],
                "leaf": true,
                "name": "Makeup Brush Cleaners",
                "var": false
              },
              {
                "categoryId": 1524,
                "children": [],
                "leaf": true,
                "name": "Makeup Brushes & Sets",
                "var": false
              },
              {
                "categoryId": 1529,
                "children": [],
                "leaf": true,
                "name": "Mirrors",
                "var": false
              },
              {
                "categoryId": 1527,
                "children": [],
                "leaf": true,
                "name": "Oil Blotters",
                "var": false
              },
              {
                "categoryId": 1531,
                "children": [],
                "leaf": true,
                "name": "Pencil Sharpeners",
                "var": false
              },
              {
                "categoryId": 1530,
                "children": [],
                "leaf": true,
                "name": "Sponges & Applicators",
                "var": false
              },
              {
                "categoryId": 1532,
                "children": [],
                "leaf": true,
                "name": "Tweezers & Razors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Makeup Accessories",
            "var": false
          },
          {
            "categoryId": 1533,
            "children": [],
            "leaf": true,
            "name": "Makeup Palettes & Sets",
            "var": false
          },
          {
            "categoryId": 1537,
            "children": [],
            "leaf": true,
            "name": "Makeup Removers",
            "var": false
          },
          {
            "categoryId": 1538,
            "children": [
              {
                "categoryId": 1539,
                "children": [],
                "leaf": true,
                "name": "Artificial Nails",
                "var": false
              },
              {
                "categoryId": 1540,
                "children": [],
                "leaf": true,
                "name": "Manicure Kits & Accessories",
                "var": false
              },
              {
                "categoryId": 10002216,
                "children": [],
                "leaf": true,
                "name": "Nail & Cuticle Care",
                "var": false
              },
              {
                "categoryId": 1541,
                "children": [],
                "leaf": true,
                "name": "Nail Art & Stickers",
                "var": false
              },
              {
                "categoryId": 1542,
                "children": [],
                "leaf": true,
                "name": "Nail Polish",
                "var": false
              },
              {
                "categoryId": 1543,
                "children": [],
                "leaf": true,
                "name": "Nail Polish Remover",
                "var": false
              },
              {
                "categoryId": 5257,
                "children": [],
                "leaf": true,
                "name": "Nail Polish Sets",
                "var": false
              },
              {
                "categoryId": 5256,
                "children": [],
                "leaf": true,
                "name": "Top Coat & Base Coat",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Nails",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Makeup",
        "var": false
      },
      {
        "categoryId": 1687,
        "children": [
          {
            "categoryId": 1689,
            "children": [],
            "leaf": true,
            "name": "Compression Stockings",
            "var": false
          },
          {
            "categoryId": 1690,
            "children": [],
            "leaf": true,
            "name": "First Aid Supplies",
            "var": false
          },
          {
            "categoryId": 1692,
            "children": [],
            "leaf": true,
            "name": "Health Accessories",
            "var": false
          },
          {
            "categoryId": 5289,
            "children": [
              {
                "categoryId": 1691,
                "children": [],
                "leaf": true,
                "name": "Blood Glucose Monitor",
                "var": false
              },
              {
                "categoryId": 1688,
                "children": [],
                "leaf": true,
                "name": "Blood Pressure Monitors",
                "var": false
              },
              {
                "categoryId": 5290,
                "children": [],
                "leaf": true,
                "name": "Cholesterol Monitors",
                "var": false
              },
              {
                "categoryId": 1696,
                "children": [],
                "leaf": true,
                "name": "Pulse Oximeters",
                "var": false
              },
              {
                "categoryId": 5291,
                "children": [],
                "leaf": true,
                "name": "Stress Monitors",
                "var": false
              },
              {
                "categoryId": 7324,
                "children": [],
                "leaf": true,
                "name": "Thermometers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Health Monitors & Tests",
            "var": false
          },
          {
            "categoryId": 7326,
            "children": [],
            "leaf": true,
            "name": "Hospital Beds",
            "var": false
          },
          {
            "categoryId": 1698,
            "children": [],
            "leaf": true,
            "name": "Injury Support and Braces",
            "var": true
          },
          {
            "categoryId": 5294,
            "children": [],
            "leaf": true,
            "name": "Medical Tests",
            "var": false
          },
          {
            "categoryId": 1693,
            "children": [],
            "leaf": true,
            "name": "Nebulizer & Aspirators",
            "var": false
          },
          {
            "categoryId": 1694,
            "children": [],
            "leaf": true,
            "name": "Ointments and Creams",
            "var": false
          },
          {
            "categoryId": 20000199,
            "children": [],
            "leaf": true,
            "name": "Oxygen Cylinder",
            "var": false
          },
          {
            "categoryId": 1695,
            "children": [],
            "leaf": true,
            "name": "Pedometers",
            "var": false
          },
          {
            "categoryId": 1683,
            "children": [],
            "leaf": true,
            "name": "Scale & Body Fat Analyzers",
            "var": false
          },
          {
            "categoryId": 10002073,
            "children": [],
            "leaf": true,
            "name": "Stethoscopes",
            "var": false
          },
          {
            "categoryId": 7325,
            "children": [],
            "leaf": true,
            "name": "Wheelchairs",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Medical Supplies",
        "var": false
      },
      {
        "categoryId": 1646,
        "children": [
          {
            "categoryId": 10002072,
            "children": [
              {
                "categoryId": 10002232,
                "children": [],
                "leaf": true,
                "name": "Body Moisturizers",
                "var": false
              },
              {
                "categoryId": 10002233,
                "children": [],
                "leaf": true,
                "name": "Body Scrub",
                "var": false
              },
              {
                "categoryId": 10002234,
                "children": [],
                "leaf": true,
                "name": "Body Soap & Shower Gel",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Bath & Body",
            "var": false
          },
          {
            "categoryId": 10002069,
            "children": [],
            "leaf": true,
            "name": "Deodorants",
            "var": false
          },
          {
            "categoryId": 1656,
            "children": [
              {
                "categoryId": 10002217,
                "children": [],
                "leaf": true,
                "name": "Conditioner",
                "var": false
              },
              {
                "categoryId": 10002218,
                "children": [],
                "leaf": true,
                "name": "Gifts & Value Sets",
                "var": false
              },
              {
                "categoryId": 1658,
                "children": [],
                "leaf": true,
                "name": "Hair Coloring",
                "var": false
              },
              {
                "categoryId": 1660,
                "children": [],
                "leaf": true,
                "name": "Hair Oils",
                "var": false
              },
              {
                "categoryId": 1667,
                "children": [],
                "leaf": true,
                "name": "Hair Treatments",
                "var": false
              },
              {
                "categoryId": 1661,
                "children": [],
                "leaf": true,
                "name": "Men Hair Styling",
                "var": false
              },
              {
                "categoryId": 1657,
                "children": [],
                "leaf": true,
                "name": "Shampoo",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hair Care",
            "var": false
          },
          {
            "categoryId": 10002071,
            "children": [
              {
                "categoryId": 10002226,
                "children": [],
                "leaf": true,
                "name": "Aftershave",
                "var": false
              },
              {
                "categoryId": 10002230,
                "children": [],
                "leaf": true,
                "name": "Creams, Foams & Gels",
                "var": false
              },
              {
                "categoryId": 10002227,
                "children": [
                  {
                    "categoryId": 10002674,
                    "children": [],
                    "leaf": true,
                    "name": "Blades & Cartridges",
                    "var": false
                  },
                  {
                    "categoryId": 10002673,
                    "children": [],
                    "leaf": true,
                    "name": "Razors",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Razors & Blades",
                "var": false
              },
              {
                "categoryId": 10002228,
                "children": [],
                "leaf": true,
                "name": "Shavers",
                "var": false
              },
              {
                "categoryId": 10002229,
                "children": [],
                "leaf": true,
                "name": "Shaving Brushes",
                "var": false
              },
              {
                "categoryId": 10002231,
                "children": [],
                "leaf": true,
                "name": "Trimmers, Groomers & Clippers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Shaving & Grooming",
            "var": false
          },
          {
            "categoryId": 10002070,
            "children": [
              {
                "categoryId": 10002221,
                "children": [],
                "leaf": true,
                "name": "Face Mask & Packs",
                "var": false
              },
              {
                "categoryId": 10002220,
                "children": [],
                "leaf": true,
                "name": "Face Scrubs & Exfoliators",
                "var": false
              },
              {
                "categoryId": 10002219,
                "children": [],
                "leaf": true,
                "name": "Facial Cleanser",
                "var": false
              },
              {
                "categoryId": 10002225,
                "children": [],
                "leaf": true,
                "name": "Gifts & Value Sets",
                "var": false
              },
              {
                "categoryId": 10002222,
                "children": [
                  {
                    "categoryId": 10002672,
                    "children": [],
                    "leaf": true,
                    "name": "Facial Oils",
                    "var": false
                  },
                  {
                    "categoryId": 10002670,
                    "children": [],
                    "leaf": true,
                    "name": "Moisturizers",
                    "var": false
                  },
                  {
                    "categoryId": 10002671,
                    "children": [],
                    "leaf": true,
                    "name": "Night Cream",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Moisturizers and Cream",
                "var": false
              },
              {
                "categoryId": 10002224,
                "children": [],
                "leaf": true,
                "name": "Serum & Essence",
                "var": false
              },
              {
                "categoryId": 10002223,
                "children": [],
                "leaf": true,
                "name": "Toners & Mists",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Skin Care",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Men's Care",
        "var": false
      },
      {
        "categoryId": 10002047,
        "children": [
          {
            "categoryId": 10002075,
            "children": [],
            "leaf": true,
            "name": "Anti-Inflammatory",
            "var": false
          },
          {
            "categoryId": 10002074,
            "children": [],
            "leaf": true,
            "name": "Antibacterial & Antifungal",
            "var": false
          },
          {
            "categoryId": 10002982,
            "children": [],
            "leaf": true,
            "name": "Antidote",
            "var": false
          },
          {
            "categoryId": 10002983,
            "children": [],
            "leaf": true,
            "name": "Antiseptics",
            "var": false
          },
          {
            "categoryId": 10003014,
            "children": [],
            "leaf": true,
            "name": "Blood Glucose Control",
            "var": false
          },
          {
            "categoryId": 10002076,
            "children": [],
            "leaf": true,
            "name": "Constipation",
            "var": false
          },
          {
            "categoryId": 10002077,
            "children": [],
            "leaf": true,
            "name": "Coughs, Colds & Flu",
            "var": false
          },
          {
            "categoryId": 10002984,
            "children": [],
            "leaf": true,
            "name": "Dehydration",
            "var": false
          },
          {
            "categoryId": 10003015,
            "children": [],
            "leaf": true,
            "name": "Diabetic",
            "var": false
          },
          {
            "categoryId": 10003016,
            "children": [],
            "leaf": true,
            "name": "Diagnostic Kits",
            "var": false
          },
          {
            "categoryId": 10002985,
            "children": [],
            "leaf": true,
            "name": "Diarrhea & Vomiting",
            "var": false
          },
          {
            "categoryId": 10002078,
            "children": [],
            "leaf": true,
            "name": "Eye, Nose, Throat Care",
            "var": false
          },
          {
            "categoryId": 10002986,
            "children": [],
            "leaf": true,
            "name": "Herbal",
            "var": false
          },
          {
            "categoryId": 10002081,
            "children": [],
            "leaf": true,
            "name": "Indigestion & Heartburn",
            "var": false
          },
          {
            "categoryId": 10002079,
            "children": [],
            "leaf": true,
            "name": "Pain Relief & Fever",
            "var": false
          },
          {
            "categoryId": 10002082,
            "children": [],
            "leaf": true,
            "name": "Scar Care",
            "var": false
          },
          {
            "categoryId": 10002080,
            "children": [],
            "leaf": true,
            "name": "Topical Analgesics",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Over The Counter Medicine",
        "var": false
      },
      {
        "categoryId": 1682,
        "children": [
          {
            "categoryId": 5278,
            "children": [],
            "leaf": true,
            "name": "Adult Diapers & Incontinence",
            "var": false
          },
          {
            "categoryId": 1699,
            "children": [],
            "leaf": true,
            "name": "Deodorants",
            "var": false
          },
          {
            "categoryId": 10003017,
            "children": [],
            "leaf": true,
            "name": "Ear Care",
            "var": false
          },
          {
            "categoryId": 1703,
            "children": [
              {
                "categoryId": 1704,
                "children": [
                  {
                    "categoryId": 1705,
                    "children": [],
                    "leaf": true,
                    "name": "Clear Lens",
                    "var": false
                  },
                  {
                    "categoryId": 1706,
                    "children": [],
                    "leaf": true,
                    "name": "Color Lens",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Contact Lens",
                "var": false
              },
              {
                "categoryId": 1707,
                "children": [],
                "leaf": true,
                "name": "Contact Lens Care & Accessories",
                "var": false
              },
              {
                "categoryId": 5270,
                "children": [],
                "leaf": true,
                "name": "Eye Drops",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Eye Care",
            "var": false
          },
          {
            "categoryId": 1718,
            "children": [
              {
                "categoryId": 1719,
                "children": [],
                "leaf": true,
                "name": "Feminine Wash",
                "var": false
              },
              {
                "categoryId": 1721,
                "children": [],
                "leaf": true,
                "name": "Panty Liners",
                "var": false
              },
              {
                "categoryId": 1722,
                "children": [],
                "leaf": true,
                "name": "Pregnancy & Fertility",
                "var": false
              },
              {
                "categoryId": 1723,
                "children": [],
                "leaf": true,
                "name": "Sanitary Napkins",
                "var": false
              },
              {
                "categoryId": 1724,
                "children": [],
                "leaf": true,
                "name": "Tampons",
                "var": false
              },
              {
                "categoryId": 1720,
                "children": [],
                "leaf": true,
                "name": "Vaginal Cream",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Feminine Care",
            "var": false
          },
          {
            "categoryId": 1709,
            "children": [
              {
                "categoryId": 1710,
                "children": [],
                "leaf": true,
                "name": "Dental Flosses & Sticks",
                "var": false
              },
              {
                "categoryId": 10002236,
                "children": [],
                "leaf": true,
                "name": "Denture Care",
                "var": false
              },
              {
                "categoryId": 1711,
                "children": [],
                "leaf": true,
                "name": "Electric Toothbrushes",
                "var": false
              },
              {
                "categoryId": 1712,
                "children": [],
                "leaf": true,
                "name": "Mouthwash",
                "var": false
              },
              {
                "categoryId": 1713,
                "children": [],
                "leaf": true,
                "name": "Oral Care Accessories",
                "var": false
              },
              {
                "categoryId": 1714,
                "children": [],
                "leaf": true,
                "name": "Teeth whitening",
                "var": false
              },
              {
                "categoryId": 10002235,
                "children": [],
                "leaf": true,
                "name": "Toothbrush Replacement Heads",
                "var": false
              },
              {
                "categoryId": 1715,
                "children": [],
                "leaf": true,
                "name": "Toothbrushes",
                "var": false
              },
              {
                "categoryId": 1716,
                "children": [],
                "leaf": true,
                "name": "Toothpaste",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Oral Care",
            "var": false
          },
          {
            "categoryId": 7405,
            "children": [],
            "leaf": true,
            "name": "Personal Safety & Security",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Personal Care",
        "var": false
      },
      {
        "categoryId": 1725,
        "children": [
          {
            "categoryId": 5284,
            "children": [],
            "leaf": true,
            "name": "Performance Enhancement Supplement",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Personal Pleasure",
        "var": false
      },
      {
        "categoryId": 10002992,
        "children": [
          {
            "categoryId": 10002993,
            "children": [
              {
                "categoryId": 10002995,
                "children": [],
                "leaf": true,
                "name": "Salon Services ",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Beauty Services",
            "var": false
          },
          {
            "categoryId": 10002999,
            "children": [],
            "leaf": true,
            "name": "Event Management",
            "var": false
          },
          {
            "categoryId": 10002996,
            "children": [],
            "leaf": true,
            "name": "Freelancer Services ",
            "var": false
          },
          {
            "categoryId": 10002998,
            "children": [],
            "leaf": true,
            "name": "Photography Services",
            "var": false
          },
          {
            "categoryId": 10002994,
            "children": [
              {
                "categoryId": 10002997,
                "children": [],
                "leaf": true,
                "name": "Tattoo Chittagong",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Tattoo",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Services",
        "var": false
      },
      {
        "categoryId": 10518,
        "children": [
          {
            "categoryId": 10564,
            "children": [],
            "leaf": true,
            "name": "Condoms",
            "var": false
          },
          {
            "categoryId": 10567,
            "children": [],
            "leaf": true,
            "name": "Lubricants",
            "var": false
          },
          {
            "categoryId": 10570,
            "children": [],
            "leaf": true,
            "name": "Sensual Toys",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Sexual Wellness",
        "var": false
      },
      {
        "categoryId": 1545,
        "children": [
          {
            "categoryId": 7287,
            "children": [
              {
                "categoryId": 7288,
                "children": [],
                "leaf": true,
                "name": "Gifts & Value Sets",
                "var": false
              },
              {
                "categoryId": 10002198,
                "children": [
                  {
                    "categoryId": 10002668,
                    "children": [],
                    "leaf": true,
                    "name": "Facial Oils",
                    "var": false
                  },
                  {
                    "categoryId": 10002666,
                    "children": [],
                    "leaf": true,
                    "name": "Moisturizers",
                    "var": false
                  },
                  {
                    "categoryId": 10002669,
                    "children": [],
                    "leaf": true,
                    "name": "Neck & Decollete",
                    "var": false
                  },
                  {
                    "categoryId": 10002667,
                    "children": [],
                    "leaf": true,
                    "name": "Night Cream",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Moisturizers and Cream",
                "var": false
              },
              {
                "categoryId": 7293,
                "children": [],
                "leaf": true,
                "name": "Serum & Essence",
                "var": false
              },
              {
                "categoryId": 7291,
                "children": [],
                "leaf": true,
                "name": "Sunscreen and Aftersun",
                "var": false
              },
              {
                "categoryId": 7292,
                "children": [],
                "leaf": true,
                "name": "Toner & Mists",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dermacare",
            "var": false
          },
          {
            "categoryId": 7296,
            "children": [],
            "leaf": true,
            "name": "Eye Care",
            "var": false
          },
          {
            "categoryId": 7278,
            "children": [],
            "leaf": true,
            "name": "Face Mask & Packs",
            "var": false
          },
          {
            "categoryId": 7279,
            "children": [],
            "leaf": true,
            "name": "Face Scrubs & Exfoliators",
            "var": false
          },
          {
            "categoryId": 7280,
            "children": [],
            "leaf": true,
            "name": "Facial Cleansers",
            "var": false
          },
          {
            "categoryId": 7281,
            "children": [],
            "leaf": true,
            "name": "Gifts & Value Sets",
            "var": false
          },
          {
            "categoryId": 10002062,
            "children": [],
            "leaf": true,
            "name": "Lip Balm and Treatment",
            "var": false
          },
          {
            "categoryId": 10002061,
            "children": [
              {
                "categoryId": 10002201,
                "children": [],
                "leaf": true,
                "name": "Facial Oils",
                "var": false
              },
              {
                "categoryId": 10002199,
                "children": [],
                "leaf": true,
                "name": "Moisturizers",
                "var": false
              },
              {
                "categoryId": 10002202,
                "children": [],
                "leaf": true,
                "name": "Neck & Decollete",
                "var": false
              },
              {
                "categoryId": 10002200,
                "children": [],
                "leaf": true,
                "name": "Night Cream",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moisturizers and Cream",
            "var": false
          },
          {
            "categoryId": 7282,
            "children": [],
            "leaf": true,
            "name": "Moisturizers and Cream",
            "var": false
          },
          {
            "categoryId": 7283,
            "children": [],
            "leaf": true,
            "name": "Scar Cream",
            "var": false
          },
          {
            "categoryId": 7286,
            "children": [],
            "leaf": true,
            "name": "Serum & Essence",
            "var": false
          },
          {
            "categoryId": 7284,
            "children": [],
            "leaf": true,
            "name": "Sunscreen and Aftersun",
            "var": false
          },
          {
            "categoryId": 7285,
            "children": [],
            "leaf": true,
            "name": "Toner & Mists",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Skin Care",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Health & Beauty",
    "var": false
  },
  {
    "categoryId": 275,
    "children": [
      {
        "categoryId": 9475,
        "children": [
          {
            "categoryId": 9476,
            "children": [],
            "leaf": true,
            "name": "Air Conditioner Accessories",
            "var": false
          },
          {
            "categoryId": 9477,
            "children": [],
            "leaf": true,
            "name": "Air Purifier Accessories",
            "var": false
          },
          {
            "categoryId": 9493,
            "children": [],
            "leaf": true,
            "name": "Coffee Machine Accessories",
            "var": false
          },
          {
            "categoryId": 9486,
            "children": [],
            "leaf": true,
            "name": "Cooktop Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9483,
            "children": [],
            "leaf": true,
            "name": "Dishwasher Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9478,
            "children": [],
            "leaf": true,
            "name": "Fan Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9484,
            "children": [],
            "leaf": true,
            "name": "Freezer Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9497,
            "children": [],
            "leaf": true,
            "name": "Fryer and Air Fryer Accessories",
            "var": false
          },
          {
            "categoryId": 9489,
            "children": [],
            "leaf": true,
            "name": "Gas Stove Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9487,
            "children": [],
            "leaf": true,
            "name": "Microwave Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9485,
            "children": [],
            "leaf": true,
            "name": "Range Hood Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9490,
            "children": [],
            "leaf": true,
            "name": "Refrigerator Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9494,
            "children": [],
            "leaf": true,
            "name": "Rice Cookers and Steamers Accessories",
            "var": false
          },
          {
            "categoryId": 9481,
            "children": [],
            "leaf": true,
            "name": "Sewing Machine Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9492,
            "children": [],
            "leaf": true,
            "name": "Small Kitchen Appliance Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9499,
            "children": [],
            "leaf": true,
            "name": "Specialty Cookware Accessories",
            "var": false
          },
          {
            "categoryId": 9498,
            "children": [],
            "leaf": true,
            "name": "Toasters and Sandwich Makers Accessories",
            "var": false
          },
          {
            "categoryId": 9482,
            "children": [],
            "leaf": true,
            "name": "Vacuum Cleaners Accessories",
            "var": false
          },
          {
            "categoryId": 9480,
            "children": [],
            "leaf": true,
            "name": "Ventilation Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9488,
            "children": [],
            "leaf": true,
            "name": "Wall Oven Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9491,
            "children": [],
            "leaf": true,
            "name": "Washer & Dryer Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9495,
            "children": [],
            "leaf": true,
            "name": "Water Dispenser Accessories",
            "var": false
          },
          {
            "categoryId": 9479,
            "children": [],
            "leaf": true,
            "name": "Water Heater Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 9496,
            "children": [],
            "leaf": true,
            "name": "Water Purifiers & Filters Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Appliances Parts & Accessories",
        "var": false
      },
      {
        "categoryId": 357,
        "children": [
          {
            "categoryId": 358,
            "children": [
              {
                "categoryId": 10000302,
                "children": [],
                "leaf": true,
                "name": "Air Conditioner Parts & Accessories",
                "var": false
              },
              {
                "categoryId": 10000301,
                "children": [],
                "leaf": true,
                "name": "Air Conditioners",
                "var": false
              },
              {
                "categoryId": 10000303,
                "children": [],
                "leaf": true,
                "name": "Air Coolers",
                "var": false
              },
              {
                "categoryId": 10000304,
                "children": [],
                "leaf": true,
                "name": "Air Curtain",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Air Conditioning",
            "var": false
          },
          {
            "categoryId": 359,
            "children": [
              {
                "categoryId": 10000306,
                "children": [],
                "leaf": true,
                "name": "Air Purifier Parts & Accessories",
                "var": false
              },
              {
                "categoryId": 360,
                "children": [],
                "leaf": true,
                "name": "Air Purifiers",
                "var": false
              },
              {
                "categoryId": 361,
                "children": [],
                "leaf": true,
                "name": "Dehumidifiers",
                "var": false
              },
              {
                "categoryId": 10000305,
                "children": [],
                "leaf": true,
                "name": "Humidifer Parts & Accessories",
                "var": false
              },
              {
                "categoryId": 362,
                "children": [],
                "leaf": true,
                "name": "Humidifiers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Air Treatment",
            "var": false
          },
          {
            "categoryId": 5684,
            "children": [],
            "leaf": true,
            "name": "Exhaust Fans",
            "var": false
          },
          {
            "categoryId": 363,
            "children": [],
            "leaf": true,
            "name": "Fans",
            "var": false
          },
          {
            "categoryId": 370,
            "children": [],
            "leaf": true,
            "name": "Heaters",
            "var": false
          },
          {
            "categoryId": 371,
            "children": [],
            "leaf": true,
            "name": "Water Heaters",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Cooling & Heating",
        "var": false
      },
      {
        "categoryId": 372,
        "children": [
          {
            "categoryId": 10000307,
            "children": [],
            "leaf": true,
            "name": "Garment Presses",
            "var": false
          },
          {
            "categoryId": 376,
            "children": [],
            "leaf": true,
            "name": "Garment Steamers",
            "var": false
          },
          {
            "categoryId": 373,
            "children": [],
            "leaf": true,
            "name": "Irons",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Irons & Garment Steamers",
        "var": false
      },
      {
        "categoryId": 10000272,
        "children": [
          {
            "categoryId": 10000321,
            "children": [],
            "leaf": true,
            "name": "Cooktops & Ranges",
            "var": false
          },
          {
            "categoryId": 10000319,
            "children": [
              {
                "categoryId": 10000329,
                "children": [],
                "leaf": true,
                "name": "Dish Dryers & Sterilizers",
                "var": false
              },
              {
                "categoryId": 10000328,
                "children": [],
                "leaf": true,
                "name": "Dishwasher Parts & Accessories",
                "var": false
              },
              {
                "categoryId": 10000327,
                "children": [],
                "leaf": true,
                "name": "Dishwashers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dishwashers & Parts",
            "var": false
          },
          {
            "categoryId": 10000317,
            "children": [],
            "leaf": true,
            "name": "Freezers",
            "var": false
          },
          {
            "categoryId": 10000326,
            "children": [],
            "leaf": true,
            "name": "Gas Stoves",
            "var": false
          },
          {
            "categoryId": 10000322,
            "children": [
              {
                "categoryId": 10000331,
                "children": [],
                "leaf": true,
                "name": "Microwave Parts",
                "var": false
              },
              {
                "categoryId": 10000330,
                "children": [],
                "leaf": true,
                "name": "Microwaves",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Microwaves & Parts",
            "var": false
          },
          {
            "categoryId": 10000323,
            "children": [],
            "leaf": true,
            "name": "Ovens",
            "var": false
          },
          {
            "categoryId": 10000324,
            "children": [],
            "leaf": true,
            "name": "Range Hoods",
            "var": false
          },
          {
            "categoryId": 10000316,
            "children": [],
            "leaf": true,
            "name": "Refrigerators",
            "var": false
          },
          {
            "categoryId": 10000320,
            "children": [],
            "leaf": true,
            "name": "Warming Drawers",
            "var": false
          },
          {
            "categoryId": 10000325,
            "children": [
              {
                "categoryId": 10000334,
                "children": [],
                "leaf": true,
                "name": "Replacement Filters & Parts",
                "var": false
              },
              {
                "categoryId": 10000332,
                "children": [],
                "leaf": true,
                "name": "Water Dispensers",
                "var": false
              },
              {
                "categoryId": 10000333,
                "children": [],
                "leaf": true,
                "name": "Water Purifiers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Water Dispensers, Purifiers & Filters",
            "var": false
          },
          {
            "categoryId": 10000318,
            "children": [],
            "leaf": true,
            "name": "Wine Cellars",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Kitchen Appliances",
        "var": false
      },
      {
        "categoryId": 332,
        "children": [
          {
            "categoryId": 338,
            "children": [
              {
                "categoryId": 8348,
                "children": [],
                "leaf": false,
                "name": "Cooktop Accessories",
                "var": false
              },
              {
                "categoryId": 339,
                "children": [],
                "leaf": true,
                "name": "Electric Stoves",
                "var": false
              },
              {
                "categoryId": 340,
                "children": [],
                "leaf": true,
                "name": "Gas Stoves",
                "var": false
              },
              {
                "categoryId": 345,
                "children": [],
                "leaf": true,
                "name": "Induction Cooktops",
                "var": false
              },
              {
                "categoryId": 5769,
                "children": [],
                "leaf": true,
                "name": "Infrared Cooktops",
                "var": false
              },
              {
                "categoryId": 5780,
                "children": [],
                "leaf": true,
                "name": "Other Cooktops",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cooktops",
            "var": false
          },
          {
            "categoryId": 333,
            "children": [
              {
                "categoryId": 8347,
                "children": [],
                "leaf": true,
                "name": "Dish Dryers",
                "var": false
              },
              {
                "categoryId": 5729,
                "children": [],
                "leaf": false,
                "name": "Dishwasher Accessories",
                "var": false
              },
              {
                "categoryId": 5727,
                "children": [],
                "leaf": true,
                "name": "Upright Dishwashers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dish Washers & Dryers",
            "var": false
          },
          {
            "categoryId": 335,
            "children": [],
            "leaf": false,
            "name": "Electric Grills",
            "var": false
          },
          {
            "categoryId": 336,
            "children": [
              {
                "categoryId": 5732,
                "children": [],
                "leaf": true,
                "name": "Built-in Freezers",
                "var": false
              },
              {
                "categoryId": 5730,
                "children": [],
                "leaf": true,
                "name": "Chest Freezers",
                "var": false
              },
              {
                "categoryId": 5733,
                "children": [],
                "leaf": true,
                "name": "Freezer Accessories",
                "var": false
              },
              {
                "categoryId": 5731,
                "children": [],
                "leaf": true,
                "name": "Upright Freezers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Freezers",
            "var": false
          },
          {
            "categoryId": 337,
            "children": [
              {
                "categoryId": 5736,
                "children": [],
                "leaf": true,
                "name": "Ceiling Vent Hoods",
                "var": false
              },
              {
                "categoryId": 5735,
                "children": [],
                "leaf": true,
                "name": "Chimney Hoods",
                "var": false
              },
              {
                "categoryId": 5737,
                "children": [],
                "leaf": false,
                "name": "Range Hood Accessories",
                "var": false
              },
              {
                "categoryId": 5734,
                "children": [],
                "leaf": true,
                "name": "Slim Hoods",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hoods",
            "var": false
          },
          {
            "categoryId": 346,
            "children": [
              {
                "categoryId": 347,
                "children": [
                  {
                    "categoryId": 8350,
                    "children": [],
                    "leaf": true,
                    "name": "Built-in Microwaves",
                    "var": false
                  },
                  {
                    "categoryId": 5794,
                    "children": [],
                    "leaf": true,
                    "name": "Convection Microwaves",
                    "var": false
                  },
                  {
                    "categoryId": 5784,
                    "children": [],
                    "leaf": true,
                    "name": "Grill Microwaves",
                    "var": false
                  },
                  {
                    "categoryId": 5796,
                    "children": [],
                    "leaf": true,
                    "name": "Microwave Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 5785,
                    "children": [],
                    "leaf": true,
                    "name": "Microwave Ovens",
                    "var": false
                  },
                  {
                    "categoryId": 8349,
                    "children": [],
                    "leaf": true,
                    "name": "Steam Microwaves",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Microwaves",
                "var": false
              },
              {
                "categoryId": 348,
                "children": [
                  {
                    "categoryId": 5806,
                    "children": [],
                    "leaf": true,
                    "name": "Breakfast Makers",
                    "var": false
                  },
                  {
                    "categoryId": 5805,
                    "children": [],
                    "leaf": true,
                    "name": "Built-In Ovens",
                    "var": false
                  },
                  {
                    "categoryId": 5801,
                    "children": [],
                    "leaf": true,
                    "name": "Electric Ovens",
                    "var": false
                  },
                  {
                    "categoryId": 5808,
                    "children": [],
                    "leaf": true,
                    "name": "Oven Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 5803,
                    "children": [],
                    "leaf": true,
                    "name": "Toaster Ovens",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Ovens",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Microwaves & Ovens",
            "var": false
          },
          {
            "categoryId": 349,
            "children": [
              {
                "categoryId": 5818,
                "children": [],
                "leaf": true,
                "name": "Electric-Hob Ranges",
                "var": false
              },
              {
                "categoryId": 5814,
                "children": [],
                "leaf": true,
                "name": "Gas-Hob Ranges",
                "var": false
              },
              {
                "categoryId": 5822,
                "children": [],
                "leaf": true,
                "name": "Induction-Hob Ranges",
                "var": false
              },
              {
                "categoryId": 5824,
                "children": [],
                "leaf": false,
                "name": "Range Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Ranges & Hobs",
            "var": false
          },
          {
            "categoryId": 350,
            "children": [
              {
                "categoryId": 5827,
                "children": [],
                "leaf": true,
                "name": "1-Door Refrigerators",
                "var": false
              },
              {
                "categoryId": 5828,
                "children": [],
                "leaf": true,
                "name": "2-Door Refrigerators",
                "var": false
              },
              {
                "categoryId": 5829,
                "children": [],
                "leaf": true,
                "name": "3-Door Up Refrigerators",
                "var": false
              },
              {
                "categoryId": 5837,
                "children": [],
                "leaf": true,
                "name": "Compact Fridges",
                "var": false
              },
              {
                "categoryId": 5838,
                "children": [],
                "leaf": true,
                "name": "Mini Fridges",
                "var": false
              },
              {
                "categoryId": 5846,
                "children": [],
                "leaf": true,
                "name": "Refrigerator Accessories",
                "var": false
              },
              {
                "categoryId": 5844,
                "children": [],
                "leaf": true,
                "name": "Refrigerator Water Filters",
                "var": false
              },
              {
                "categoryId": 5843,
                "children": [],
                "leaf": true,
                "name": "Showcase Refrigerator",
                "var": false
              },
              {
                "categoryId": 5834,
                "children": [],
                "leaf": true,
                "name": "Side-by-Side Refrigerators",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Refrigerators",
            "var": false
          },
          {
            "categoryId": 5857,
            "children": [
              {
                "categoryId": 334,
                "children": [
                  {
                    "categoryId": 5863,
                    "children": [],
                    "leaf": true,
                    "name": "Dryers",
                    "var": false
                  },
                  {
                    "categoryId": 5862,
                    "children": [],
                    "leaf": true,
                    "name": "Portable Dryers",
                    "var": false
                  },
                  {
                    "categoryId": 5865,
                    "children": [],
                    "leaf": false,
                    "name": "Top-Load Dryers",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Dryers",
                "var": false
              },
              {
                "categoryId": 5872,
                "children": [],
                "leaf": false,
                "name": "Washer & Dryer Accessories",
                "var": false
              },
              {
                "categoryId": 5867,
                "children": [],
                "leaf": true,
                "name": "Washer-Dryers Combo",
                "var": false
              },
              {
                "categoryId": 355,
                "children": [
                  {
                    "categoryId": 5859,
                    "children": [],
                    "leaf": true,
                    "name": "Front-Load Washers",
                    "var": false
                  },
                  {
                    "categoryId": 8352,
                    "children": [],
                    "leaf": true,
                    "name": "Single Tub Washers",
                    "var": false
                  },
                  {
                    "categoryId": 5858,
                    "children": [],
                    "leaf": true,
                    "name": "Top-Load Washers",
                    "var": false
                  },
                  {
                    "categoryId": 5861,
                    "children": [],
                    "leaf": true,
                    "name": "Twin Tub Washers",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Washing Machines",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Washers & Dryers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Large Appliances",
        "var": false
      },
      {
        "categoryId": 10000270,
        "children": [],
        "leaf": true,
        "name": "Sewing Machines",
        "var": false
      },
      {
        "categoryId": 276,
        "children": [
          {
            "categoryId": 282,
            "children": [
              {
                "categoryId": 286,
                "children": [],
                "leaf": true,
                "name": "Coffee Grinders",
                "var": false
              },
              {
                "categoryId": 287,
                "children": [],
                "leaf": true,
                "name": "Coffee Machine Parts & Accessories",
                "var": false
              },
              {
                "categoryId": 10000284,
                "children": [],
                "leaf": true,
                "name": "Coffee Machines",
                "var": false
              },
              {
                "categoryId": 5905,
                "children": [],
                "leaf": true,
                "name": "Milk Frothers",
                "var": false
              },
              {
                "categoryId": 5904,
                "children": [],
                "leaf": true,
                "name": "Tea Makers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Coffee Machines & Accessories",
            "var": false
          },
          {
            "categoryId": 10000276,
            "children": [],
            "leaf": true,
            "name": "Electric Contact Grills",
            "var": false
          },
          {
            "categoryId": 10000273,
            "children": [],
            "leaf": true,
            "name": "Electric Food Steamers",
            "var": false
          },
          {
            "categoryId": 288,
            "children": [
              {
                "categoryId": 289,
                "children": [],
                "leaf": true,
                "name": "Electric Kettles",
                "var": false
              },
              {
                "categoryId": 290,
                "children": [],
                "leaf": true,
                "name": "Thermo Pots",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Electric Kettles & Thermo Pots",
            "var": false
          },
          {
            "categoryId": 5908,
            "children": [
              {
                "categoryId": 291,
                "children": [],
                "leaf": true,
                "name": "Electric Knives",
                "var": false
              },
              {
                "categoryId": 5910,
                "children": [],
                "leaf": true,
                "name": "Knife Sharpeners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Electric Knives & Sharpeners",
            "var": false
          },
          {
            "categoryId": 10000275,
            "children": [],
            "leaf": true,
            "name": "Electric Multi cookers",
            "var": false
          },
          {
            "categoryId": 10000274,
            "children": [],
            "leaf": true,
            "name": "Electric Pans",
            "var": false
          },
          {
            "categoryId": 10000279,
            "children": [],
            "leaf": true,
            "name": "Electric Pressure cookers",
            "var": false
          },
          {
            "categoryId": 10000277,
            "children": [],
            "leaf": true,
            "name": "Electric Sandwich Makers & Presses",
            "var": false
          },
          {
            "categoryId": 10000283,
            "children": [],
            "leaf": true,
            "name": "Electric Slow Cookers",
            "var": false
          },
          {
            "categoryId": 10000280,
            "children": [],
            "leaf": true,
            "name": "Electric Woks",
            "var": false
          },
          {
            "categoryId": 277,
            "children": [
              {
                "categoryId": 278,
                "children": [],
                "leaf": true,
                "name": "Blenders",
                "var": false
              },
              {
                "categoryId": 5921,
                "children": [],
                "leaf": true,
                "name": "Food Processors",
                "var": false
              },
              {
                "categoryId": 281,
                "children": [],
                "leaf": true,
                "name": "Grinders",
                "var": false
              },
              {
                "categoryId": 280,
                "children": [],
                "leaf": true,
                "name": "Mixers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Food Preparation",
            "var": false
          },
          {
            "categoryId": 8359,
            "children": [
              {
                "categoryId": 8361,
                "children": [],
                "leaf": true,
                "name": "Air Fryers",
                "var": false
              },
              {
                "categoryId": 8360,
                "children": [],
                "leaf": true,
                "name": "Deep Fryers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Fryers",
            "var": false
          },
          {
            "categoryId": 305,
            "children": [],
            "leaf": true,
            "name": "Juicers & Fruit Extractors",
            "var": false
          },
          {
            "categoryId": 10000278,
            "children": [],
            "leaf": true,
            "name": "Rice Cookers",
            "var": false
          },
          {
            "categoryId": 320,
            "children": [
              {
                "categoryId": 8356,
                "children": [],
                "leaf": true,
                "name": "Bean Sprout Maker",
                "var": false
              },
              {
                "categoryId": 321,
                "children": [],
                "leaf": true,
                "name": "Bread Makers",
                "var": false
              },
              {
                "categoryId": 8357,
                "children": [],
                "leaf": true,
                "name": "Breakfast Makers",
                "var": false
              },
              {
                "categoryId": 10000292,
                "children": [],
                "leaf": true,
                "name": "Chocolate Fondue Makers",
                "var": false
              },
              {
                "categoryId": 10000294,
                "children": [],
                "leaf": true,
                "name": "Cotton Candy Maker",
                "var": false
              },
              {
                "categoryId": 10000288,
                "children": [],
                "leaf": true,
                "name": "Crepe Maker",
                "var": false
              },
              {
                "categoryId": 10000298,
                "children": [],
                "leaf": true,
                "name": "Cupcake makers",
                "var": false
              },
              {
                "categoryId": 10000297,
                "children": [],
                "leaf": true,
                "name": "Donut Makers",
                "var": false
              },
              {
                "categoryId": 10000285,
                "children": [],
                "leaf": true,
                "name": "Egg Boilers",
                "var": false
              },
              {
                "categoryId": 8354,
                "children": [],
                "leaf": true,
                "name": "Egg Master",
                "var": false
              },
              {
                "categoryId": 6003,
                "children": [],
                "leaf": true,
                "name": "Electric Lunch Boxes",
                "var": false
              },
              {
                "categoryId": 10000289,
                "children": [],
                "leaf": true,
                "name": "Food Dehydrators",
                "var": false
              },
              {
                "categoryId": 6004,
                "children": [],
                "leaf": true,
                "name": "Food Purifiers",
                "var": false
              },
              {
                "categoryId": 10000295,
                "children": [],
                "leaf": true,
                "name": "Ice Makers",
                "var": false
              },
              {
                "categoryId": 10000299,
                "children": [],
                "leaf": true,
                "name": "Ice-Cream Makers",
                "var": false
              },
              {
                "categoryId": 326,
                "children": [],
                "leaf": true,
                "name": "Other Cookware",
                "var": false
              },
              {
                "categoryId": 10000296,
                "children": [],
                "leaf": true,
                "name": "Pancake Makers",
                "var": false
              },
              {
                "categoryId": 324,
                "children": [],
                "leaf": true,
                "name": "Pasta & Noodle Makers",
                "var": false
              },
              {
                "categoryId": 10000286,
                "children": [],
                "leaf": true,
                "name": "Pizza Maker",
                "var": false
              },
              {
                "categoryId": 10000291,
                "children": [],
                "leaf": true,
                "name": "Popcorn Makers",
                "var": false
              },
              {
                "categoryId": 8355,
                "children": [],
                "leaf": true,
                "name": "Soya Milk Maker",
                "var": false
              },
              {
                "categoryId": 10000290,
                "children": [],
                "leaf": true,
                "name": "Sparkling Beverage & Soda Makers",
                "var": false
              },
              {
                "categoryId": 10000287,
                "children": [],
                "leaf": true,
                "name": "Takoyaki Maker",
                "var": false
              },
              {
                "categoryId": 10000300,
                "children": [],
                "leaf": true,
                "name": "Waffle Makers",
                "var": false
              },
              {
                "categoryId": 10000293,
                "children": [],
                "leaf": true,
                "name": "Yogurt Makers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Specialty Cookware",
            "var": false
          },
          {
            "categoryId": 10000281,
            "children": [],
            "leaf": true,
            "name": "Suki Pots",
            "var": false
          },
          {
            "categoryId": 10000282,
            "children": [],
            "leaf": true,
            "name": "Toasters",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Small Kitchen Appliances",
        "var": false
      },
      {
        "categoryId": 380,
        "children": [
          {
            "categoryId": 10000309,
            "children": [],
            "leaf": true,
            "name": "Electric Brooms",
            "var": false
          },
          {
            "categoryId": 10000310,
            "children": [],
            "leaf": true,
            "name": "Floor Polishers",
            "var": false
          },
          {
            "categoryId": 10000308,
            "children": [],
            "leaf": true,
            "name": "Steam Mops",
            "var": false
          },
          {
            "categoryId": 381,
            "children": [
              {
                "categoryId": 10000312,
                "children": [],
                "leaf": true,
                "name": "Vacuum Cleaner Parts & Accessories",
                "var": false
              },
              {
                "categoryId": 10000311,
                "children": [],
                "leaf": true,
                "name": "Vacuum Cleaners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Vacuum Cleaners & Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Vacuums & Floor Care",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Home Appliances",
    "var": false
  },
  {
    "categoryId": 10000339,
    "children": [
      {
        "categoryId": 10000361,
        "children": [
          {
            "categoryId": 10000549,
            "children": [],
            "leaf": true,
            "name": "Bakeware Dishes",
            "var": false
          },
          {
            "categoryId": 10000552,
            "children": [],
            "leaf": true,
            "name": "Bakeware Sets",
            "var": false
          },
          {
            "categoryId": 10000550,
            "children": [],
            "leaf": true,
            "name": "Baking Tools & Accessories",
            "var": false
          },
          {
            "categoryId": 10000718,
            "children": [],
            "leaf": true,
            "name": "Baking Trays & Pans",
            "var": false
          },
          {
            "categoryId": 10000551,
            "children": [],
            "leaf": true,
            "name": "Cake Decorating Tools",
            "var": false
          },
          {
            "categoryId": 10000553,
            "children": [],
            "leaf": true,
            "name": "Wire Racks",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Bakeware",
        "var": false
      },
      {
        "categoryId": 10000366,
        "children": [
          {
            "categoryId": 10000593,
            "children": [],
            "leaf": true,
            "name": "Coffee Makers & Grinders",
            "var": false
          },
          {
            "categoryId": 10000594,
            "children": [],
            "leaf": true,
            "name": "Coffee Making Accessories",
            "var": false
          },
          {
            "categoryId": 10000599,
            "children": [],
            "leaf": true,
            "name": "Coffee, Tea & Espresso",
            "var": false
          },
          {
            "categoryId": 10000598,
            "children": [],
            "leaf": true,
            "name": "Creamer & Sugar Bowls",
            "var": false
          },
          {
            "categoryId": 10000597,
            "children": [],
            "leaf": true,
            "name": "Milk Frothers",
            "var": false
          },
          {
            "categoryId": 10000595,
            "children": [],
            "leaf": true,
            "name": "Tea Making Accessories",
            "var": false
          },
          {
            "categoryId": 10000596,
            "children": [],
            "leaf": true,
            "name": "Teapots & Coffee Servers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Coffee & Tea",
        "var": false
      },
      {
        "categoryId": 10000362,
        "children": [
          {
            "categoryId": 10000562,
            "children": [],
            "leaf": true,
            "name": "Casserole Pots",
            "var": false
          },
          {
            "categoryId": 10000559,
            "children": [],
            "leaf": true,
            "name": "Cookware Sets",
            "var": false
          },
          {
            "categoryId": 10000557,
            "children": [],
            "leaf": true,
            "name": "Dutch Oven & Braziers",
            "var": false
          },
          {
            "categoryId": 10000560,
            "children": [],
            "leaf": true,
            "name": "Griddle & Grills",
            "var": false
          },
          {
            "categoryId": 10000554,
            "children": [],
            "leaf": true,
            "name": "Pots & Pans",
            "var": false
          },
          {
            "categoryId": 10000561,
            "children": [],
            "leaf": true,
            "name": "Roasting Trays",
            "var": false
          },
          {
            "categoryId": 10000564,
            "children": [],
            "leaf": true,
            "name": "Specialty Cookware",
            "var": false
          },
          {
            "categoryId": 10000556,
            "children": [],
            "leaf": true,
            "name": "Steamers, Stock & Pasta Pots",
            "var": false
          },
          {
            "categoryId": 10000563,
            "children": [],
            "leaf": true,
            "name": "Stove Kettles",
            "var": false
          },
          {
            "categoryId": 10000558,
            "children": [],
            "leaf": true,
            "name": "Stovetop Pressure Cookers",
            "var": false
          },
          {
            "categoryId": 10000555,
            "children": [],
            "leaf": true,
            "name": "Woks & Stir-Fry Pans",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Cookware",
        "var": false
      },
      {
        "categoryId": 10000363,
        "children": [
          {
            "categoryId": 10000567,
            "children": [],
            "leaf": true,
            "name": "Bowls",
            "var": false
          },
          {
            "categoryId": 10000565,
            "children": [],
            "leaf": true,
            "name": "Cutlery",
            "var": false
          },
          {
            "categoryId": 10000568,
            "children": [],
            "leaf": true,
            "name": "Dining Sets",
            "var": false
          },
          {
            "categoryId": 10000569,
            "children": [],
            "leaf": true,
            "name": "Disposable Dinnerware",
            "var": false
          },
          {
            "categoryId": 10000566,
            "children": [],
            "leaf": true,
            "name": "Plates",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Dinnerware",
        "var": false
      },
      {
        "categoryId": 10000364,
        "children": [
          {
            "categoryId": 10000577,
            "children": [],
            "leaf": true,
            "name": "Bar & Wine Tools",
            "var": false
          },
          {
            "categoryId": 10000575,
            "children": [],
            "leaf": true,
            "name": "Cups, Mugs & Saucers",
            "var": false
          },
          {
            "categoryId": 10000573,
            "children": [],
            "leaf": true,
            "name": "Decanters",
            "var": false
          },
          {
            "categoryId": 10000570,
            "children": [],
            "leaf": true,
            "name": "Everyday Glassware",
            "var": false
          },
          {
            "categoryId": 10000576,
            "children": [],
            "leaf": true,
            "name": "Gravity Water Dispenser",
            "var": false
          },
          {
            "categoryId": 10000574,
            "children": [],
            "leaf": true,
            "name": "Jugs & Pitchers",
            "var": false
          },
          {
            "categoryId": 10000572,
            "children": [],
            "leaf": true,
            "name": "Specialty Glassware",
            "var": false
          },
          {
            "categoryId": 10000571,
            "children": [],
            "leaf": true,
            "name": "Wine & Champagne Glasses",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Drinkware",
        "var": false
      },
      {
        "categoryId": 10000368,
        "children": [
          {
            "categoryId": 10000622,
            "children": [],
            "leaf": true,
            "name": "Aprons",
            "var": false
          },
          {
            "categoryId": 10000620,
            "children": [],
            "leaf": true,
            "name": "Cloth Napkins",
            "var": false
          },
          {
            "categoryId": 10000619,
            "children": [],
            "leaf": true,
            "name": "Dish Cloth & Towels",
            "var": false
          },
          {
            "categoryId": 10000617,
            "children": [],
            "leaf": true,
            "name": "Placemats & Coasters",
            "var": false
          },
          {
            "categoryId": 10000621,
            "children": [],
            "leaf": true,
            "name": "Potholders, Mitts & Cozies",
            "var": false
          },
          {
            "categoryId": 10000618,
            "children": [],
            "leaf": true,
            "name": "Table Cloths & Runners",
            "var": false
          },
          {
            "categoryId": 10000623,
            "children": [],
            "leaf": true,
            "name": "Table Linen Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Kitchen & Table Linen",
        "var": false
      },
      {
        "categoryId": 10000369,
        "children": [
          {
            "categoryId": 10000633,
            "children": [],
            "leaf": true,
            "name": "Condiment & Spice Racks",
            "var": false
          },
          {
            "categoryId": 10000627,
            "children": [],
            "leaf": true,
            "name": "Dishracks & Sink accessories",
            "var": false
          },
          {
            "categoryId": 10000626,
            "children": [],
            "leaf": true,
            "name": "Drink Bottles",
            "var": false
          },
          {
            "categoryId": 10000629,
            "children": [],
            "leaf": true,
            "name": "Food Storage & Dispensers",
            "var": false
          },
          {
            "categoryId": 10000628,
            "children": [],
            "leaf": true,
            "name": "Kitchen Canisters & Jars",
            "var": false
          },
          {
            "categoryId": 10000631,
            "children": [],
            "leaf": true,
            "name": "Kitchen Organizers",
            "var": false
          },
          {
            "categoryId": 10000625,
            "children": [],
            "leaf": true,
            "name": "Lunch Bags & Boxes",
            "var": false
          },
          {
            "categoryId": 10000632,
            "children": [],
            "leaf": true,
            "name": "Pot Racks",
            "var": false
          },
          {
            "categoryId": 10000624,
            "children": [],
            "leaf": true,
            "name": "Shopping Trolleys & Carriers",
            "var": false
          },
          {
            "categoryId": 10000634,
            "children": [],
            "leaf": true,
            "name": "Thermal Flasks & Containers",
            "var": false
          },
          {
            "categoryId": 10000630,
            "children": [],
            "leaf": true,
            "name": "Wine Racks",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Kitchen Storage & Accessories",
        "var": false
      },
      {
        "categoryId": 10000367,
        "children": [
          {
            "categoryId": 10000601,
            "children": [],
            "leaf": true,
            "name": "Can Openers",
            "var": false
          },
          {
            "categoryId": 10000606,
            "children": [],
            "leaf": true,
            "name": "Cheese Tools",
            "var": false
          },
          {
            "categoryId": 10000603,
            "children": [],
            "leaf": true,
            "name": "Colanders & Food Strainers",
            "var": false
          },
          {
            "categoryId": 10000610,
            "children": [],
            "leaf": true,
            "name": "Cooking Utensils",
            "var": false
          },
          {
            "categoryId": 10000604,
            "children": [],
            "leaf": true,
            "name": "Fruit & Vegetable Tools",
            "var": false
          },
          {
            "categoryId": 10000602,
            "children": [],
            "leaf": true,
            "name": "Graters, Peelers & Slicers",
            "var": false
          },
          {
            "categoryId": 10000616,
            "children": [
              {
                "categoryId": 10000789,
                "children": [],
                "leaf": true,
                "name": "Cooking Knife Sets",
                "var": false
              },
              {
                "categoryId": 10000788,
                "children": [],
                "leaf": true,
                "name": "Cooking Knives",
                "var": false
              },
              {
                "categoryId": 10000790,
                "children": [],
                "leaf": true,
                "name": "Knife Sharpeners & Blocks",
                "var": false
              },
              {
                "categoryId": 10000787,
                "children": [],
                "leaf": true,
                "name": "Steak Knives",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Knives & Accessories",
            "var": false
          },
          {
            "categoryId": 10000600,
            "children": [],
            "leaf": true,
            "name": "Measuring Tools & Scales",
            "var": false
          },
          {
            "categoryId": 10000609,
            "children": [],
            "leaf": true,
            "name": "Meat & Poultry Tools",
            "var": false
          },
          {
            "categoryId": 10000612,
            "children": [],
            "leaf": true,
            "name": "Mixing Bowls",
            "var": false
          },
          {
            "categoryId": 10000605,
            "children": [],
            "leaf": true,
            "name": "Mortar & Pestle",
            "var": false
          },
          {
            "categoryId": 10000613,
            "children": [],
            "leaf": true,
            "name": "Oil Sprayers & Dispensers",
            "var": false
          },
          {
            "categoryId": 10000608,
            "children": [],
            "leaf": true,
            "name": "Pasta, Noodle & Pizza Tools",
            "var": false
          },
          {
            "categoryId": 10000607,
            "children": [],
            "leaf": true,
            "name": "Preparation & Cutting Boards",
            "var": false
          },
          {
            "categoryId": 10000614,
            "children": [
              {
                "categoryId": 10000785,
                "children": [],
                "leaf": true,
                "name": "Cooking Dredges & Shakers",
                "var": false
              },
              {
                "categoryId": 10000784,
                "children": [],
                "leaf": true,
                "name": "Garlic Tools",
                "var": false
              },
              {
                "categoryId": 10000783,
                "children": [],
                "leaf": true,
                "name": "Salt & Pepper Tools",
                "var": false
              },
              {
                "categoryId": 10000786,
                "children": [],
                "leaf": true,
                "name": "Spice Tools",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Seasoning & Spice Tools",
            "var": false
          },
          {
            "categoryId": 10000615,
            "children": [],
            "leaf": true,
            "name": "Specialty Kitchen Tools",
            "var": false
          },
          {
            "categoryId": 10000611,
            "children": [],
            "leaf": true,
            "name": "Thermometers & Timers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Kitchen Utensils",
        "var": false
      },
      {
        "categoryId": 10000365,
        "children": [
          {
            "categoryId": 10000585,
            "children": [],
            "leaf": true,
            "name": "Bread Baskets & Trays",
            "var": false
          },
          {
            "categoryId": 10000587,
            "children": [],
            "leaf": true,
            "name": "Butter Dishes",
            "var": false
          },
          {
            "categoryId": 10000580,
            "children": [],
            "leaf": true,
            "name": "Cake & Tiered Stands",
            "var": false
          },
          {
            "categoryId": 10000813,
            "children": [],
            "leaf": true,
            "name": "Cheese Boards & Platters",
            "var": false
          },
          {
            "categoryId": 10000590,
            "children": [],
            "leaf": true,
            "name": "Cruets & Condiment Sets",
            "var": false
          },
          {
            "categoryId": 10000584,
            "children": [],
            "leaf": true,
            "name": "Divided Serving Dishes",
            "var": false
          },
          {
            "categoryId": 10000592,
            "children": [],
            "leaf": true,
            "name": "Food Warmers",
            "var": false
          },
          {
            "categoryId": 10000588,
            "children": [],
            "leaf": true,
            "name": "Gravy Boats",
            "var": false
          },
          {
            "categoryId": 10000589,
            "children": [],
            "leaf": true,
            "name": "Lazy Susans",
            "var": false
          },
          {
            "categoryId": 10000581,
            "children": [],
            "leaf": true,
            "name": "Salad Bowls",
            "var": false
          },
          {
            "categoryId": 10000578,
            "children": [],
            "leaf": true,
            "name": "Serving Bowls",
            "var": false
          },
          {
            "categoryId": 10000582,
            "children": [],
            "leaf": true,
            "name": "Serving Platters",
            "var": false
          },
          {
            "categoryId": 10000583,
            "children": [],
            "leaf": true,
            "name": "Serving Trays",
            "var": false
          },
          {
            "categoryId": 10000579,
            "children": [],
            "leaf": true,
            "name": "Serving Utensils",
            "var": false
          },
          {
            "categoryId": 10000591,
            "children": [],
            "leaf": true,
            "name": "Trivets",
            "var": false
          },
          {
            "categoryId": 10000586,
            "children": [],
            "leaf": true,
            "name": "Tureens",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Serveware",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Kitchen & Dining",
    "var": false
  },
  {
    "categoryId": 10000338,
    "children": [
      {
        "categoryId": 10000359,
        "children": [
          {
            "categoryId": 10000537,
            "children": [],
            "leaf": true,
            "name": "Brooms, Mops & Sweepers",
            "var": false
          },
          {
            "categoryId": 10000535,
            "children": [],
            "leaf": true,
            "name": "Brushes, Sponges & Wipers",
            "var": false
          },
          {
            "categoryId": 10000536,
            "children": [],
            "leaf": true,
            "name": "Cleaning Buckets & Tubs",
            "var": false
          },
          {
            "categoryId": 10000538,
            "children": [],
            "leaf": true,
            "name": "Cleaning Gloves",
            "var": false
          },
          {
            "categoryId": 10000541,
            "children": [],
            "leaf": true,
            "name": "Cleaning Products",
            "var": false
          },
          {
            "categoryId": 10000539,
            "children": [],
            "leaf": true,
            "name": "Dusters & Dust Cloths",
            "var": false
          },
          {
            "categoryId": 10000540,
            "children": [],
            "leaf": true,
            "name": "Garbage & Recycling Bins",
            "var": false
          },
          {
            "categoryId": 10000542,
            "children": [],
            "leaf": true,
            "name": "Trash Bags & Liners",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Cleaning",
        "var": false
      },
      {
        "categoryId": 10000360,
        "children": [
          {
            "categoryId": 10000545,
            "children": [],
            "leaf": true,
            "name": "Clothes Hangers & Pegs",
            "var": false
          },
          {
            "categoryId": 10000544,
            "children": [],
            "leaf": true,
            "name": "Clothes Line & Drying Racks",
            "var": false
          },
          {
            "categoryId": 10000546,
            "children": [],
            "leaf": true,
            "name": "Ironing Boards",
            "var": false
          },
          {
            "categoryId": 10000548,
            "children": [],
            "leaf": true,
            "name": "Laundry & Ironing Tools",
            "var": false
          },
          {
            "categoryId": 10000547,
            "children": [],
            "leaf": true,
            "name": "Laundry Bags & Wash Balls",
            "var": false
          },
          {
            "categoryId": 10000543,
            "children": [],
            "leaf": true,
            "name": "Laundry Baskets & Hampers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Laundry",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Laundry & Cleaning",
    "var": false
  },
  {
    "categoryId": 10002990,
    "children": [
      {
        "categoryId": 10002991,
        "children": [],
        "leaf": true,
        "name": "Cow",
        "var": true
      },
      {
        "categoryId": 20000202,
        "children": [],
        "leaf": true,
        "name": "Goat",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Livestock",
    "var": false
  },
  {
    "categoryId": 3374,
    "children": [
      {
        "categoryId": 3375,
        "children": [
          {
            "categoryId": 7571,
            "children": [
              {
                "categoryId": 7573,
                "children": [],
                "leaf": true,
                "name": "Mini wall calendars",
                "var": false
              },
              {
                "categoryId": 7572,
                "children": [],
                "leaf": true,
                "name": "Wall calendars",
                "var": false
              },
              {
                "categoryId": 7574,
                "children": [],
                "leaf": true,
                "name": "box calendars",
                "var": false
              },
              {
                "categoryId": 7575,
                "children": [],
                "leaf": true,
                "name": "planners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Calendars",
            "var": false
          },
          {
            "categoryId": 3460,
            "children": [
              {
                "categoryId": 3482,
                "children": [],
                "leaf": true,
                "name": "Art Books",
                "var": false
              },
              {
                "categoryId": 3467,
                "children": [],
                "leaf": true,
                "name": "Babies' Books",
                "var": false
              },
              {
                "categoryId": 3464,
                "children": [],
                "leaf": true,
                "name": "Biography Books",
                "var": false
              },
              {
                "categoryId": 3465,
                "children": [],
                "leaf": true,
                "name": "Business Books",
                "var": false
              },
              {
                "categoryId": 3466,
                "children": [],
                "leaf": true,
                "name": "Calendars",
                "var": false
              },
              {
                "categoryId": 3470,
                "children": [],
                "leaf": true,
                "name": "Children's Books",
                "var": false
              },
              {
                "categoryId": 3472,
                "children": [],
                "leaf": true,
                "name": "Comic Books",
                "var": false
              },
              {
                "categoryId": 3474,
                "children": [],
                "leaf": true,
                "name": "Cooking Books",
                "var": false
              },
              {
                "categoryId": 3477,
                "children": [],
                "leaf": true,
                "name": "Education Books",
                "var": false
              },
              {
                "categoryId": 3480,
                "children": [],
                "leaf": true,
                "name": "Entertainment Books",
                "var": false
              },
              {
                "categoryId": 3478,
                "children": [],
                "leaf": true,
                "name": "Fitness Books",
                "var": false
              },
              {
                "categoryId": 3479,
                "children": [],
                "leaf": true,
                "name": "History Books",
                "var": false
              },
              {
                "categoryId": 7563,
                "children": [
                  {
                    "categoryId": 7567,
                    "children": [],
                    "leaf": true,
                    "name": "Dictionaries",
                    "var": false
                  },
                  {
                    "categoryId": 7566,
                    "children": [],
                    "leaf": true,
                    "name": "Grammar",
                    "var": false
                  },
                  {
                    "categoryId": 7564,
                    "children": [],
                    "leaf": true,
                    "name": "Language Learning & Teaching",
                    "var": false
                  },
                  {
                    "categoryId": 7565,
                    "children": [],
                    "leaf": true,
                    "name": "Linguistics",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Language, Linguistics & Writing",
                "var": false
              },
              {
                "categoryId": 3481,
                "children": [],
                "leaf": true,
                "name": "Law Books",
                "var": false
              },
              {
                "categoryId": 3462,
                "children": [],
                "leaf": true,
                "name": "Lifestyle Books",
                "var": false
              },
              {
                "categoryId": 3461,
                "children": [],
                "leaf": true,
                "name": "Literature",
                "var": false
              },
              {
                "categoryId": 3483,
                "children": [],
                "leaf": true,
                "name": "Medical Books",
                "var": false
              },
              {
                "categoryId": 3469,
                "children": [],
                "leaf": true,
                "name": "Parent's Books",
                "var": false
              },
              {
                "categoryId": 3476,
                "children": [],
                "leaf": true,
                "name": "Pre Sell Books",
                "var": false
              },
              {
                "categoryId": 3487,
                "children": [],
                "leaf": true,
                "name": "Professional Books",
                "var": false
              },
              {
                "categoryId": 3485,
                "children": [],
                "leaf": true,
                "name": "Relationship Books",
                "var": false
              },
              {
                "categoryId": 3471,
                "children": [],
                "leaf": true,
                "name": "Religion Books",
                "var": false
              },
              {
                "categoryId": 3488,
                "children": [],
                "leaf": true,
                "name": "Romance",
                "var": false
              },
              {
                "categoryId": 3489,
                "children": [],
                "leaf": true,
                "name": "Science Books",
                "var": false
              },
              {
                "categoryId": 3490,
                "children": [],
                "leaf": true,
                "name": "Science Fiction Books",
                "var": false
              },
              {
                "categoryId": 3491,
                "children": [],
                "leaf": true,
                "name": "Self Help",
                "var": false
              },
              {
                "categoryId": 3486,
                "children": [],
                "leaf": true,
                "name": "Social Books",
                "var": false
              },
              {
                "categoryId": 3492,
                "children": [],
                "leaf": true,
                "name": "Sport Books",
                "var": false
              },
              {
                "categoryId": 7562,
                "children": [],
                "leaf": true,
                "name": "Study Aids & Exam Preparations",
                "var": false
              },
              {
                "categoryId": 3473,
                "children": [],
                "leaf": true,
                "name": "Technology Books",
                "var": false
              },
              {
                "categoryId": 3463,
                "children": [],
                "leaf": true,
                "name": "Teens Books",
                "var": false
              },
              {
                "categoryId": 7568,
                "children": [
                  {
                    "categoryId": 7569,
                    "children": [],
                    "leaf": true,
                    "name": "High School",
                    "var": false
                  },
                  {
                    "categoryId": 7570,
                    "children": [],
                    "leaf": true,
                    "name": "University",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Text books",
                "var": false
              },
              {
                "categoryId": 3484,
                "children": [],
                "leaf": true,
                "name": "Thrillers",
                "var": false
              },
              {
                "categoryId": 3468,
                "children": [],
                "leaf": true,
                "name": "Toddlers' Books",
                "var": false
              },
              {
                "categoryId": 3493,
                "children": [],
                "leaf": true,
                "name": "Travel Books",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Chinese Books",
            "var": false
          },
          {
            "categoryId": 3411,
            "children": [
              {
                "categoryId": 3432,
                "children": [],
                "leaf": true,
                "name": "Art Books",
                "var": false
              },
              {
                "categoryId": 3418,
                "children": [],
                "leaf": true,
                "name": "Babies' Books",
                "var": false
              },
              {
                "categoryId": 3415,
                "children": [],
                "leaf": true,
                "name": "Biography Books",
                "var": false
              },
              {
                "categoryId": 3444,
                "children": [],
                "leaf": true,
                "name": "Business Books",
                "var": false
              },
              {
                "categoryId": 3417,
                "children": [],
                "leaf": true,
                "name": "Calendars",
                "var": false
              },
              {
                "categoryId": 3421,
                "children": [],
                "leaf": true,
                "name": "Children's Books",
                "var": false
              },
              {
                "categoryId": 3423,
                "children": [],
                "leaf": true,
                "name": "Comic Books",
                "var": false
              },
              {
                "categoryId": 3425,
                "children": [],
                "leaf": true,
                "name": "Cooking Books",
                "var": false
              },
              {
                "categoryId": 3427,
                "children": [],
                "leaf": true,
                "name": "Education Books",
                "var": false
              },
              {
                "categoryId": 3430,
                "children": [],
                "leaf": true,
                "name": "Entertainment Books",
                "var": false
              },
              {
                "categoryId": 3416,
                "children": [],
                "leaf": false,
                "name": "Finance & Economics",
                "var": false
              },
              {
                "categoryId": 3428,
                "children": [],
                "leaf": true,
                "name": "Fitness Books",
                "var": false
              },
              {
                "categoryId": 3429,
                "children": [],
                "leaf": true,
                "name": "History Books",
                "var": false
              },
              {
                "categoryId": 7554,
                "children": [
                  {
                    "categoryId": 7558,
                    "children": [],
                    "leaf": true,
                    "name": "Dictionaries",
                    "var": false
                  },
                  {
                    "categoryId": 7557,
                    "children": [],
                    "leaf": true,
                    "name": "Grammar",
                    "var": false
                  },
                  {
                    "categoryId": 7555,
                    "children": [],
                    "leaf": true,
                    "name": "Language Learning & Teaching",
                    "var": false
                  },
                  {
                    "categoryId": 7556,
                    "children": [],
                    "leaf": true,
                    "name": "Linguistics",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Language, Linguistics & Writing",
                "var": false
              },
              {
                "categoryId": 3431,
                "children": [],
                "leaf": true,
                "name": "Law Books",
                "var": false
              },
              {
                "categoryId": 3413,
                "children": [],
                "leaf": true,
                "name": "Lifestyle Books",
                "var": false
              },
              {
                "categoryId": 3442,
                "children": [],
                "leaf": true,
                "name": "Literature",
                "var": false
              },
              {
                "categoryId": 3433,
                "children": [],
                "leaf": true,
                "name": "Medical Books",
                "var": false
              },
              {
                "categoryId": 3420,
                "children": [],
                "leaf": true,
                "name": "Parent's Books",
                "var": false
              },
              {
                "categoryId": 3426,
                "children": [],
                "leaf": true,
                "name": "Pre Sell Books",
                "var": false
              },
              {
                "categoryId": 3437,
                "children": [],
                "leaf": true,
                "name": "Professional Books",
                "var": false
              },
              {
                "categoryId": 3435,
                "children": [],
                "leaf": true,
                "name": "Relationship Books",
                "var": false
              },
              {
                "categoryId": 3422,
                "children": [],
                "leaf": true,
                "name": "Religion Books",
                "var": false
              },
              {
                "categoryId": 3438,
                "children": [],
                "leaf": true,
                "name": "Romance",
                "var": false
              },
              {
                "categoryId": 3439,
                "children": [],
                "leaf": true,
                "name": "Science Books",
                "var": false
              },
              {
                "categoryId": 3440,
                "children": [],
                "leaf": true,
                "name": "Science Fiction Books",
                "var": false
              },
              {
                "categoryId": 3441,
                "children": [],
                "leaf": true,
                "name": "Self Help",
                "var": false
              },
              {
                "categoryId": 3436,
                "children": [],
                "leaf": true,
                "name": "Social Books",
                "var": false
              },
              {
                "categoryId": 4597,
                "children": [],
                "leaf": true,
                "name": "Sport Books",
                "var": false
              },
              {
                "categoryId": 7552,
                "children": [],
                "leaf": true,
                "name": "Study Aids & Exam Preparations",
                "var": false
              },
              {
                "categoryId": 3424,
                "children": [],
                "leaf": true,
                "name": "Technology Books",
                "var": false
              },
              {
                "categoryId": 3414,
                "children": [],
                "leaf": true,
                "name": "Teens Books",
                "var": false
              },
              {
                "categoryId": 7559,
                "children": [
                  {
                    "categoryId": 7560,
                    "children": [],
                    "leaf": true,
                    "name": "High School",
                    "var": false
                  },
                  {
                    "categoryId": 7561,
                    "children": [],
                    "leaf": true,
                    "name": "University",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Text books",
                "var": false
              },
              {
                "categoryId": 3434,
                "children": [],
                "leaf": true,
                "name": "Thrillers",
                "var": false
              },
              {
                "categoryId": 3419,
                "children": [],
                "leaf": true,
                "name": "Toddlers' Books",
                "var": false
              },
              {
                "categoryId": 3443,
                "children": [],
                "leaf": true,
                "name": "Travel Books",
                "var": false
              }
            ],
            "leaf": false,
            "name": "English Books",
            "var": false
          },
          {
            "categoryId": 3376,
            "children": [
              {
                "categoryId": 4596,
                "children": [],
                "leaf": true,
                "name": "Art Books",
                "var": false
              },
              {
                "categoryId": 3383,
                "children": [],
                "leaf": true,
                "name": "Babies' Books",
                "var": false
              },
              {
                "categoryId": 3380,
                "children": [],
                "leaf": true,
                "name": "Biography Books",
                "var": false
              },
              {
                "categoryId": 3381,
                "children": [],
                "leaf": true,
                "name": "Business Books",
                "var": false
              },
              {
                "categoryId": 3382,
                "children": [],
                "leaf": true,
                "name": "Calendars",
                "var": false
              },
              {
                "categoryId": 3386,
                "children": [],
                "leaf": true,
                "name": "Children's Books",
                "var": false
              },
              {
                "categoryId": 3388,
                "children": [],
                "leaf": true,
                "name": "Comic Books",
                "var": false
              },
              {
                "categoryId": 3390,
                "children": [],
                "leaf": true,
                "name": "Cooking Books",
                "var": false
              },
              {
                "categoryId": 3393,
                "children": [],
                "leaf": true,
                "name": "Education Books",
                "var": false
              },
              {
                "categoryId": 3396,
                "children": [],
                "leaf": true,
                "name": "Entertainment Books",
                "var": false
              },
              {
                "categoryId": 3394,
                "children": [],
                "leaf": true,
                "name": "Fitness Books",
                "var": false
              },
              {
                "categoryId": 3395,
                "children": [],
                "leaf": true,
                "name": "History Books",
                "var": false
              },
              {
                "categoryId": 7543,
                "children": [
                  {
                    "categoryId": 7548,
                    "children": [],
                    "leaf": true,
                    "name": "Dictionaries",
                    "var": false
                  },
                  {
                    "categoryId": 7546,
                    "children": [],
                    "leaf": true,
                    "name": "Grammar",
                    "var": false
                  },
                  {
                    "categoryId": 7544,
                    "children": [],
                    "leaf": true,
                    "name": "Language Learning & Teaching",
                    "var": false
                  },
                  {
                    "categoryId": 7545,
                    "children": [],
                    "leaf": true,
                    "name": "Linguistics",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Language, Linguistics & Writing",
                "var": false
              },
              {
                "categoryId": 3397,
                "children": [],
                "leaf": true,
                "name": "Law Books",
                "var": false
              },
              {
                "categoryId": 3378,
                "children": [],
                "leaf": true,
                "name": "Lifestyle Books",
                "var": false
              },
              {
                "categoryId": 3377,
                "children": [],
                "leaf": true,
                "name": "Literature",
                "var": false
              },
              {
                "categoryId": 3399,
                "children": [],
                "leaf": true,
                "name": "Medical Books",
                "var": false
              },
              {
                "categoryId": 3409,
                "children": [],
                "leaf": true,
                "name": "Other Books",
                "var": false
              },
              {
                "categoryId": 3385,
                "children": [],
                "leaf": true,
                "name": "Parent's Books",
                "var": false
              },
              {
                "categoryId": 3398,
                "children": [],
                "leaf": false,
                "name": "Photography  Books",
                "var": false
              },
              {
                "categoryId": 3392,
                "children": [],
                "leaf": true,
                "name": "Pre Sell Books",
                "var": false
              },
              {
                "categoryId": 3403,
                "children": [],
                "leaf": true,
                "name": "Professional Books",
                "var": false
              },
              {
                "categoryId": 3401,
                "children": [],
                "leaf": true,
                "name": "Relationship Books",
                "var": false
              },
              {
                "categoryId": 3387,
                "children": [],
                "leaf": true,
                "name": "Religion Books",
                "var": false
              },
              {
                "categoryId": 3404,
                "children": [],
                "leaf": true,
                "name": "Romance",
                "var": false
              },
              {
                "categoryId": 3405,
                "children": [],
                "leaf": true,
                "name": "Science Books",
                "var": false
              },
              {
                "categoryId": 3406,
                "children": [],
                "leaf": true,
                "name": "Science Fiction Books",
                "var": false
              },
              {
                "categoryId": 3407,
                "children": [],
                "leaf": true,
                "name": "Self Help",
                "var": false
              },
              {
                "categoryId": 3402,
                "children": [],
                "leaf": true,
                "name": "Social Books",
                "var": false
              },
              {
                "categoryId": 3408,
                "children": [],
                "leaf": true,
                "name": "Sport Books",
                "var": false
              },
              {
                "categoryId": 7542,
                "children": [],
                "leaf": true,
                "name": "Study Aids & Exam Preparations",
                "var": false
              },
              {
                "categoryId": 3389,
                "children": [],
                "leaf": true,
                "name": "Technology Books",
                "var": false
              },
              {
                "categoryId": 3379,
                "children": [],
                "leaf": true,
                "name": "Teens Books",
                "var": false
              },
              {
                "categoryId": 7549,
                "children": [
                  {
                    "categoryId": 7550,
                    "children": [],
                    "leaf": true,
                    "name": "High School",
                    "var": false
                  },
                  {
                    "categoryId": 7551,
                    "children": [],
                    "leaf": true,
                    "name": "University",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Text books",
                "var": false
              },
              {
                "categoryId": 3400,
                "children": [],
                "leaf": true,
                "name": "Thrillers",
                "var": false
              },
              {
                "categoryId": 3384,
                "children": [],
                "leaf": true,
                "name": "Toddlers' Books",
                "var": false
              },
              {
                "categoryId": 3410,
                "children": [],
                "leaf": true,
                "name": "Travel Books",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Local Books",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Books",
        "var": false
      },
      {
        "categoryId": 3586,
        "children": [
          {
            "categoryId": 3597,
            "children": [],
            "leaf": true,
            "name": "Automotive Magazines",
            "var": false
          },
          {
            "categoryId": 3613,
            "children": [],
            "leaf": true,
            "name": "Children Magazines",
            "var": false
          },
          {
            "categoryId": 3602,
            "children": [],
            "leaf": true,
            "name": "Culinary Magazines",
            "var": false
          },
          {
            "categoryId": 3600,
            "children": [],
            "leaf": true,
            "name": "Design Magazines",
            "var": false
          },
          {
            "categoryId": 3608,
            "children": [],
            "leaf": true,
            "name": "Education Magazines",
            "var": false
          },
          {
            "categoryId": 3593,
            "children": [],
            "leaf": true,
            "name": "Entertainment Magazines",
            "var": false
          },
          {
            "categoryId": 3587,
            "children": [],
            "leaf": true,
            "name": "Fashion Magazines",
            "var": false
          },
          {
            "categoryId": 3604,
            "children": [],
            "leaf": true,
            "name": "Films Magazines",
            "var": false
          },
          {
            "categoryId": 3607,
            "children": [],
            "leaf": true,
            "name": "Financial Magazines",
            "var": false
          },
          {
            "categoryId": 7576,
            "children": [],
            "leaf": true,
            "name": "Food Magazines",
            "var": false
          },
          {
            "categoryId": 3615,
            "children": [],
            "leaf": true,
            "name": "Gaming Magazines",
            "var": false
          },
          {
            "categoryId": 3591,
            "children": [],
            "leaf": true,
            "name": "Health and Beauty Magazines",
            "var": false
          },
          {
            "categoryId": 3595,
            "children": [],
            "leaf": true,
            "name": "Home Deco Magazines",
            "var": false
          },
          {
            "categoryId": 3599,
            "children": [],
            "leaf": true,
            "name": "IT Magazines",
            "var": false
          },
          {
            "categoryId": 3611,
            "children": [],
            "leaf": true,
            "name": "Jewelry Magazines",
            "var": false
          },
          {
            "categoryId": 3606,
            "children": [],
            "leaf": true,
            "name": "Knowledge Magazines",
            "var": false
          },
          {
            "categoryId": 3596,
            "children": [],
            "leaf": true,
            "name": "Land and House Magazines",
            "var": false
          },
          {
            "categoryId": 3594,
            "children": [],
            "leaf": true,
            "name": "Life Magazines",
            "var": false
          },
          {
            "categoryId": 3612,
            "children": [],
            "leaf": true,
            "name": "Lifestyle Magazines",
            "var": false
          },
          {
            "categoryId": 3589,
            "children": [],
            "leaf": true,
            "name": "Men's Lifestyle Magazines",
            "var": false
          },
          {
            "categoryId": 3601,
            "children": [],
            "leaf": true,
            "name": "Mom and Babies Magazines",
            "var": false
          },
          {
            "categoryId": 3605,
            "children": [],
            "leaf": true,
            "name": "Music Magazines",
            "var": false
          },
          {
            "categoryId": 3609,
            "children": [],
            "leaf": true,
            "name": "Nature Magazines",
            "var": false
          },
          {
            "categoryId": 3603,
            "children": [],
            "leaf": true,
            "name": "Pets Magazines",
            "var": false
          },
          {
            "categoryId": 3610,
            "children": [],
            "leaf": true,
            "name": "Sports Magazines",
            "var": false
          },
          {
            "categoryId": 3614,
            "children": [],
            "leaf": true,
            "name": "TV Drama Magazines",
            "var": false
          },
          {
            "categoryId": 3598,
            "children": [],
            "leaf": true,
            "name": "Technology Magazines",
            "var": false
          },
          {
            "categoryId": 3590,
            "children": [],
            "leaf": true,
            "name": "Teen Magazines",
            "var": false
          },
          {
            "categoryId": 3592,
            "children": [],
            "leaf": true,
            "name": "Tourism Magazines",
            "var": false
          },
          {
            "categoryId": 3588,
            "children": [],
            "leaf": true,
            "name": "Women's Lifestyle Magazines",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Magazines",
        "var": false
      },
      {
        "categoryId": 3616,
        "children": [
          {
            "categoryId": 3617,
            "children": [],
            "leaf": true,
            "name": "Action",
            "var": false
          },
          {
            "categoryId": 7577,
            "children": [],
            "leaf": true,
            "name": "Box Sets",
            "var": false
          },
          {
            "categoryId": 3618,
            "children": [],
            "leaf": true,
            "name": "Comedy",
            "var": false
          },
          {
            "categoryId": 3619,
            "children": [],
            "leaf": true,
            "name": "Drama",
            "var": false
          },
          {
            "categoryId": 3620,
            "children": [],
            "leaf": true,
            "name": "Educational",
            "var": false
          },
          {
            "categoryId": 3621,
            "children": [],
            "leaf": true,
            "name": "Horror",
            "var": false
          },
          {
            "categoryId": 3622,
            "children": [],
            "leaf": true,
            "name": "Romance",
            "var": false
          },
          {
            "categoryId": 3623,
            "children": [],
            "leaf": true,
            "name": "Romantic Comedy",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Movies",
        "var": false
      },
      {
        "categoryId": 3628,
        "children": [
          {
            "categoryId": 3633,
            "children": [],
            "leaf": true,
            "name": "Alternative Rock",
            "var": false
          },
          {
            "categoryId": 20000197,
            "children": [],
            "leaf": true,
            "name": "Band Merchandise",
            "var": false
          },
          {
            "categoryId": 3634,
            "children": [],
            "leaf": true,
            "name": "Blues",
            "var": false
          },
          {
            "categoryId": 3644,
            "children": [],
            "leaf": true,
            "name": "Celtic",
            "var": false
          },
          {
            "categoryId": 3629,
            "children": [],
            "leaf": true,
            "name": "Children",
            "var": false
          },
          {
            "categoryId": 3636,
            "children": [],
            "leaf": true,
            "name": "Classical",
            "var": false
          },
          {
            "categoryId": 3637,
            "children": [],
            "leaf": true,
            "name": "Country",
            "var": false
          },
          {
            "categoryId": 3638,
            "children": [],
            "leaf": true,
            "name": "Electronic",
            "var": false
          },
          {
            "categoryId": 3639,
            "children": [],
            "leaf": true,
            "name": "Folk",
            "var": false
          },
          {
            "categoryId": 3640,
            "children": [],
            "leaf": true,
            "name": "Gospel",
            "var": false
          },
          {
            "categoryId": 3649,
            "children": [],
            "leaf": true,
            "name": "Hip-Hop",
            "var": false
          },
          {
            "categoryId": 3630,
            "children": [],
            "leaf": true,
            "name": "Inspirational",
            "var": false
          },
          {
            "categoryId": 3643,
            "children": [],
            "leaf": true,
            "name": "Jazz",
            "var": false
          },
          {
            "categoryId": 3642,
            "children": [],
            "leaf": true,
            "name": "Local Music",
            "var": false
          },
          {
            "categoryId": 3641,
            "children": [],
            "leaf": true,
            "name": "Metal & Hard Rock",
            "var": false
          },
          {
            "categoryId": 3650,
            "children": [],
            "leaf": true,
            "name": "Movie Soundtracks",
            "var": false
          },
          {
            "categoryId": 3631,
            "children": [],
            "leaf": true,
            "name": "OPM",
            "var": false
          },
          {
            "categoryId": 3645,
            "children": [],
            "leaf": true,
            "name": "Opera",
            "var": false
          },
          {
            "categoryId": 3646,
            "children": [],
            "leaf": true,
            "name": "Pop",
            "var": false
          },
          {
            "categoryId": 3647,
            "children": [],
            "leaf": true,
            "name": "R&B",
            "var": false
          },
          {
            "categoryId": 3648,
            "children": [],
            "leaf": true,
            "name": "Rap",
            "var": false
          },
          {
            "categoryId": 3651,
            "children": [],
            "leaf": true,
            "name": "Reggae",
            "var": false
          },
          {
            "categoryId": 3635,
            "children": [],
            "leaf": true,
            "name": "Religion",
            "var": false
          },
          {
            "categoryId": 3632,
            "children": [],
            "leaf": true,
            "name": "Rock",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Music",
        "var": false
      },
      {
        "categoryId": 3652,
        "children": [
          {
            "categoryId": 3700,
            "children": [
              {
                "categoryId": 3706,
                "children": [],
                "leaf": true,
                "name": "Brass Accessories",
                "var": false
              },
              {
                "categoryId": 3701,
                "children": [],
                "leaf": true,
                "name": "Brass Instruments",
                "var": false
              },
              {
                "categoryId": 3702,
                "children": [],
                "leaf": true,
                "name": "Concert Percussion",
                "var": false
              },
              {
                "categoryId": 3709,
                "children": [],
                "leaf": true,
                "name": "Concert Percussion Accessories",
                "var": false
              },
              {
                "categoryId": 3703,
                "children": [],
                "leaf": true,
                "name": "Marching Percussion",
                "var": false
              },
              {
                "categoryId": 3710,
                "children": [],
                "leaf": true,
                "name": "Marching Percussion Accessories",
                "var": false
              },
              {
                "categoryId": 3711,
                "children": [],
                "leaf": true,
                "name": "Musical Fun for Kids",
                "var": false
              },
              {
                "categoryId": 3708,
                "children": [],
                "leaf": true,
                "name": "Orchestral Strings Accessories",
                "var": false
              },
              {
                "categoryId": 3704,
                "children": [],
                "leaf": true,
                "name": "Violins",
                "var": false
              },
              {
                "categoryId": 3707,
                "children": [],
                "leaf": true,
                "name": "Woodwind Accessories",
                "var": false
              },
              {
                "categoryId": 3705,
                "children": [],
                "leaf": true,
                "name": "Woodwinds",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Band & Orchestra",
            "var": false
          },
          {
            "categoryId": 3665,
            "children": [
              {
                "categoryId": 3667,
                "children": [],
                "leaf": true,
                "name": "Acoustic Bass Guitars",
                "var": false
              },
              {
                "categoryId": 3668,
                "children": [],
                "leaf": true,
                "name": "Bass Amplifiers",
                "var": false
              },
              {
                "categoryId": 3666,
                "children": [],
                "leaf": true,
                "name": "Electric Bass Guitars",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Bass Guitars",
            "var": false
          },
          {
            "categoryId": 3691,
            "children": [
              {
                "categoryId": 3692,
                "children": [],
                "leaf": true,
                "name": "DJ Equipment",
                "var": false
              },
              {
                "categoryId": 3695,
                "children": [],
                "leaf": true,
                "name": "Electronic Music",
                "var": false
              },
              {
                "categoryId": 3693,
                "children": [],
                "leaf": true,
                "name": "Karaoke",
                "var": false
              },
              {
                "categoryId": 3694,
                "children": [],
                "leaf": true,
                "name": "Lighting",
                "var": false
              }
            ],
            "leaf": false,
            "name": "DJ, Karaoke & Electronic Music",
            "var": false
          },
          {
            "categoryId": 3679,
            "children": [
              {
                "categoryId": 3683,
                "children": [],
                "leaf": true,
                "name": "Cymbals",
                "var": false
              },
              {
                "categoryId": 3682,
                "children": [],
                "leaf": true,
                "name": "Drum Accessories",
                "var": false
              },
              {
                "categoryId": 3680,
                "children": [],
                "leaf": true,
                "name": "Drum Sets",
                "var": false
              },
              {
                "categoryId": 3681,
                "children": [],
                "leaf": true,
                "name": "Electronic Drums",
                "var": false
              },
              {
                "categoryId": 3684,
                "children": [],
                "leaf": true,
                "name": "Folk & World Percussion",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Drums & Percussion",
            "var": false
          },
          {
            "categoryId": 3656,
            "children": [
              {
                "categoryId": 3658,
                "children": [],
                "leaf": true,
                "name": "Acoustic Guitars",
                "var": false
              },
              {
                "categoryId": 3659,
                "children": [],
                "leaf": true,
                "name": "Acoustic-Electric Guitars",
                "var": false
              },
              {
                "categoryId": 3660,
                "children": [],
                "leaf": true,
                "name": "Amps",
                "var": false
              },
              {
                "categoryId": 3661,
                "children": [],
                "leaf": true,
                "name": "Effects Pedals",
                "var": false
              },
              {
                "categoryId": 3657,
                "children": [],
                "leaf": true,
                "name": "Electric Guitars",
                "var": false
              },
              {
                "categoryId": 3663,
                "children": [],
                "leaf": true,
                "name": "Folk & World Strings",
                "var": false
              },
              {
                "categoryId": 3662,
                "children": [],
                "leaf": true,
                "name": "Guitar & Bass Accessories",
                "var": false
              },
              {
                "categoryId": 6451,
                "children": [],
                "leaf": true,
                "name": "Premium Guitars",
                "var": false
              },
              {
                "categoryId": 3664,
                "children": [],
                "leaf": true,
                "name": "Ukulele",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Guitars",
            "var": false
          },
          {
            "categoryId": 3712,
            "children": [
              {
                "categoryId": 3716,
                "children": [],
                "leaf": true,
                "name": "Brass Accessories",
                "var": false
              },
              {
                "categoryId": 3719,
                "children": [],
                "leaf": true,
                "name": "Concert Percussion Accessories",
                "var": false
              },
              {
                "categoryId": 3714,
                "children": [],
                "leaf": true,
                "name": "Drum & Percussion Accessories",
                "var": false
              },
              {
                "categoryId": 3721,
                "children": [],
                "leaf": true,
                "name": "Folk & World Accessories",
                "var": false
              },
              {
                "categoryId": 3722,
                "children": [],
                "leaf": true,
                "name": "General Accessories",
                "var": false
              },
              {
                "categoryId": 3713,
                "children": [],
                "leaf": true,
                "name": "Guitar & Bass Accessories",
                "var": false
              },
              {
                "categoryId": 3715,
                "children": [],
                "leaf": true,
                "name": "Keyboard Accessories",
                "var": false
              },
              {
                "categoryId": 3720,
                "children": [],
                "leaf": true,
                "name": "Marching Percussion Accessories",
                "var": false
              },
              {
                "categoryId": 3718,
                "children": [],
                "leaf": true,
                "name": "Orchestral Strings Accessories",
                "var": false
              },
              {
                "categoryId": 3717,
                "children": [],
                "leaf": true,
                "name": "Woodwind Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Instrument Accessories",
            "var": false
          },
          {
            "categoryId": 3685,
            "children": [
              {
                "categoryId": 9861,
                "children": [],
                "leaf": true,
                "name": "Acoustic Pianos",
                "var": false
              },
              {
                "categoryId": 3687,
                "children": [],
                "leaf": true,
                "name": "Digital Pianos",
                "var": false
              },
              {
                "categoryId": 3690,
                "children": [],
                "leaf": true,
                "name": "Folk & World Keyboards",
                "var": false
              },
              {
                "categoryId": 3689,
                "children": [],
                "leaf": true,
                "name": "MIDI Controllers",
                "var": false
              },
              {
                "categoryId": 3686,
                "children": [],
                "leaf": true,
                "name": "Portable Keyboards",
                "var": false
              },
              {
                "categoryId": 3688,
                "children": [],
                "leaf": true,
                "name": "Synthesizers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Keyboards & Pianos",
            "var": false
          },
          {
            "categoryId": 4603,
            "children": [],
            "leaf": true,
            "name": "Others",
            "var": false
          },
          {
            "categoryId": 3653,
            "children": [
              {
                "categoryId": 3654,
                "children": [],
                "leaf": true,
                "name": "DJ Headphones",
                "var": false
              },
              {
                "categoryId": 3655,
                "children": [],
                "leaf": true,
                "name": "Studio Headphones",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Pro & DJ Headphones",
            "var": false
          },
          {
            "categoryId": 3723,
            "children": [],
            "leaf": true,
            "name": "Traditional instruments",
            "var": false
          },
          {
            "categoryId": 3669,
            "children": [
              {
                "categoryId": 3671,
                "children": [],
                "leaf": true,
                "name": "Clarinet",
                "var": false
              },
              {
                "categoryId": 3670,
                "children": [],
                "leaf": true,
                "name": "Flute",
                "var": false
              },
              {
                "categoryId": 3672,
                "children": [],
                "leaf": true,
                "name": "Oboe",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Woodwind",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Musical Instruments",
        "var": false
      },
      {
        "categoryId": 3624,
        "children": [
          {
            "categoryId": 3625,
            "children": [],
            "leaf": true,
            "name": "Action",
            "var": false
          },
          {
            "categoryId": 3626,
            "children": [],
            "leaf": true,
            "name": "Comedy",
            "var": false
          },
          {
            "categoryId": 3627,
            "children": [],
            "leaf": true,
            "name": "Drama",
            "var": false
          }
        ],
        "leaf": false,
        "name": "TV Series",
        "var": false
      },
      {
        "categoryId": 3495,
        "children": [
          {
            "categoryId": 3556,
            "children": [
              {
                "categoryId": 3574,
                "children": [],
                "leaf": true,
                "name": "Art eBooks",
                "var": false
              },
              {
                "categoryId": 3560,
                "children": [],
                "leaf": true,
                "name": "Biography eBooks",
                "var": false
              },
              {
                "categoryId": 3561,
                "children": [],
                "leaf": true,
                "name": "Business eBooks",
                "var": false
              },
              {
                "categoryId": 3562,
                "children": [],
                "leaf": true,
                "name": "Calendars",
                "var": false
              },
              {
                "categoryId": 3563,
                "children": [],
                "leaf": true,
                "name": "Children's eBooks",
                "var": false
              },
              {
                "categoryId": 3565,
                "children": [],
                "leaf": true,
                "name": "Comic eBooks",
                "var": false
              },
              {
                "categoryId": 3567,
                "children": [],
                "leaf": true,
                "name": "Cooking eBooks",
                "var": false
              },
              {
                "categoryId": 3569,
                "children": [],
                "leaf": true,
                "name": "Education eBooks",
                "var": false
              },
              {
                "categoryId": 3572,
                "children": [],
                "leaf": true,
                "name": "Entertainment eBooks",
                "var": false
              },
              {
                "categoryId": 3570,
                "children": [],
                "leaf": true,
                "name": "Fitness eBooks",
                "var": false
              },
              {
                "categoryId": 3571,
                "children": [],
                "leaf": true,
                "name": "History eBooks",
                "var": false
              },
              {
                "categoryId": 3573,
                "children": [],
                "leaf": true,
                "name": "Law eBooks",
                "var": false
              },
              {
                "categoryId": 3558,
                "children": [],
                "leaf": true,
                "name": "Lifestyle eBooks",
                "var": false
              },
              {
                "categoryId": 3557,
                "children": [],
                "leaf": true,
                "name": "Literature",
                "var": false
              },
              {
                "categoryId": 3575,
                "children": [],
                "leaf": true,
                "name": "Medical eBooks",
                "var": false
              },
              {
                "categoryId": 3568,
                "children": [],
                "leaf": true,
                "name": "Pre Sell eBooks",
                "var": false
              },
              {
                "categoryId": 3579,
                "children": [],
                "leaf": true,
                "name": "Professional eBooks",
                "var": false
              },
              {
                "categoryId": 3577,
                "children": [],
                "leaf": true,
                "name": "Relationship eBooks",
                "var": false
              },
              {
                "categoryId": 3564,
                "children": [],
                "leaf": true,
                "name": "Religion eBooks",
                "var": false
              },
              {
                "categoryId": 3580,
                "children": [],
                "leaf": true,
                "name": "Romance",
                "var": false
              },
              {
                "categoryId": 3582,
                "children": [],
                "leaf": true,
                "name": "Science Fiction eBooks",
                "var": false
              },
              {
                "categoryId": 3581,
                "children": [],
                "leaf": true,
                "name": "Science eBooks",
                "var": false
              },
              {
                "categoryId": 3583,
                "children": [],
                "leaf": true,
                "name": "Self Help",
                "var": false
              },
              {
                "categoryId": 3578,
                "children": [],
                "leaf": true,
                "name": "Social eBooks",
                "var": false
              },
              {
                "categoryId": 3584,
                "children": [],
                "leaf": true,
                "name": "Sport eBooks",
                "var": false
              },
              {
                "categoryId": 3566,
                "children": [],
                "leaf": true,
                "name": "Technology eBooks",
                "var": false
              },
              {
                "categoryId": 3559,
                "children": [],
                "leaf": true,
                "name": "Teens eBooks",
                "var": false
              },
              {
                "categoryId": 3576,
                "children": [],
                "leaf": true,
                "name": "Thrillers",
                "var": false
              },
              {
                "categoryId": 3585,
                "children": [],
                "leaf": true,
                "name": "Travel eBooks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Chinese eBooks",
            "var": false
          },
          {
            "categoryId": 3526,
            "children": [
              {
                "categoryId": 3544,
                "children": [],
                "leaf": true,
                "name": "Art eBooks",
                "var": false
              },
              {
                "categoryId": 3530,
                "children": [],
                "leaf": true,
                "name": "Biography eBooks",
                "var": false
              },
              {
                "categoryId": 3531,
                "children": [],
                "leaf": true,
                "name": "Business eBooks",
                "var": false
              },
              {
                "categoryId": 3532,
                "children": [],
                "leaf": true,
                "name": "Calendars",
                "var": false
              },
              {
                "categoryId": 3533,
                "children": [],
                "leaf": true,
                "name": "Childrens eBooks",
                "var": false
              },
              {
                "categoryId": 3535,
                "children": [],
                "leaf": true,
                "name": "Comic eBooks",
                "var": false
              },
              {
                "categoryId": 3537,
                "children": [],
                "leaf": true,
                "name": "Cooking eBooks",
                "var": false
              },
              {
                "categoryId": 3539,
                "children": [],
                "leaf": true,
                "name": "Education eBooks",
                "var": false
              },
              {
                "categoryId": 3542,
                "children": [],
                "leaf": true,
                "name": "Entertainment eBooks",
                "var": false
              },
              {
                "categoryId": 3540,
                "children": [],
                "leaf": true,
                "name": "Fitness eBooks",
                "var": false
              },
              {
                "categoryId": 3541,
                "children": [],
                "leaf": true,
                "name": "History eBooks",
                "var": false
              },
              {
                "categoryId": 3543,
                "children": [],
                "leaf": true,
                "name": "Law eBooks",
                "var": false
              },
              {
                "categoryId": 3528,
                "children": [],
                "leaf": true,
                "name": "Lifestyle eBooks",
                "var": false
              },
              {
                "categoryId": 3527,
                "children": [],
                "leaf": true,
                "name": "Literature",
                "var": false
              },
              {
                "categoryId": 3545,
                "children": [],
                "leaf": true,
                "name": "Medical eBooks",
                "var": false
              },
              {
                "categoryId": 3538,
                "children": [],
                "leaf": false,
                "name": "Other eBooks",
                "var": false
              },
              {
                "categoryId": 5450,
                "children": [],
                "leaf": true,
                "name": "Pre Sell eBooks",
                "var": false
              },
              {
                "categoryId": 3549,
                "children": [],
                "leaf": true,
                "name": "Professional eBooks",
                "var": false
              },
              {
                "categoryId": 3547,
                "children": [],
                "leaf": true,
                "name": "Relationship eBooks",
                "var": false
              },
              {
                "categoryId": 3534,
                "children": [],
                "leaf": true,
                "name": "Religion eBooks",
                "var": false
              },
              {
                "categoryId": 3550,
                "children": [],
                "leaf": true,
                "name": "Romance",
                "var": false
              },
              {
                "categoryId": 3552,
                "children": [],
                "leaf": true,
                "name": "Science Fiction eBooks",
                "var": false
              },
              {
                "categoryId": 3551,
                "children": [],
                "leaf": true,
                "name": "Science eBooks",
                "var": false
              },
              {
                "categoryId": 3553,
                "children": [],
                "leaf": true,
                "name": "Self Help",
                "var": false
              },
              {
                "categoryId": 3548,
                "children": [],
                "leaf": true,
                "name": "Social eBooks",
                "var": false
              },
              {
                "categoryId": 3554,
                "children": [],
                "leaf": true,
                "name": "Sport eBooks",
                "var": false
              },
              {
                "categoryId": 3536,
                "children": [],
                "leaf": true,
                "name": "Technology eBooks",
                "var": false
              },
              {
                "categoryId": 3529,
                "children": [],
                "leaf": true,
                "name": "Teens eBooks",
                "var": false
              },
              {
                "categoryId": 3546,
                "children": [],
                "leaf": true,
                "name": "Thrillers",
                "var": false
              },
              {
                "categoryId": 3555,
                "children": [],
                "leaf": true,
                "name": "Travel eBooks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "English eBooks",
            "var": false
          },
          {
            "categoryId": 3496,
            "children": [
              {
                "categoryId": 3514,
                "children": [],
                "leaf": true,
                "name": "Art eBooks",
                "var": false
              },
              {
                "categoryId": 3500,
                "children": [],
                "leaf": true,
                "name": "Biography eBooks",
                "var": false
              },
              {
                "categoryId": 3501,
                "children": [],
                "leaf": true,
                "name": "Business eBooks",
                "var": false
              },
              {
                "categoryId": 3502,
                "children": [],
                "leaf": true,
                "name": "Calendars",
                "var": false
              },
              {
                "categoryId": 3503,
                "children": [],
                "leaf": true,
                "name": "Children's eBooks",
                "var": false
              },
              {
                "categoryId": 3505,
                "children": [],
                "leaf": true,
                "name": "Comic eBooks",
                "var": false
              },
              {
                "categoryId": 3507,
                "children": [],
                "leaf": true,
                "name": "Cooking eBooks",
                "var": false
              },
              {
                "categoryId": 3509,
                "children": [],
                "leaf": true,
                "name": "Education eBooks",
                "var": false
              },
              {
                "categoryId": 3512,
                "children": [],
                "leaf": true,
                "name": "Entertainment eBooks",
                "var": false
              },
              {
                "categoryId": 3510,
                "children": [],
                "leaf": true,
                "name": "Fitness eBooks",
                "var": false
              },
              {
                "categoryId": 3511,
                "children": [],
                "leaf": true,
                "name": "History eBooks",
                "var": false
              },
              {
                "categoryId": 3513,
                "children": [],
                "leaf": true,
                "name": "Law eBooks",
                "var": false
              },
              {
                "categoryId": 3498,
                "children": [],
                "leaf": true,
                "name": "Lifestyle eBooks",
                "var": false
              },
              {
                "categoryId": 3497,
                "children": [],
                "leaf": true,
                "name": "Literature",
                "var": false
              },
              {
                "categoryId": 3515,
                "children": [],
                "leaf": true,
                "name": "Medical eBooks",
                "var": false
              },
              {
                "categoryId": 3508,
                "children": [],
                "leaf": true,
                "name": "Pre Sell eBooks",
                "var": false
              },
              {
                "categoryId": 3519,
                "children": [],
                "leaf": true,
                "name": "Professional eBooks",
                "var": false
              },
              {
                "categoryId": 3517,
                "children": [],
                "leaf": true,
                "name": "Relationship eBooks",
                "var": false
              },
              {
                "categoryId": 3504,
                "children": [],
                "leaf": true,
                "name": "Religion eBooks",
                "var": false
              },
              {
                "categoryId": 3520,
                "children": [],
                "leaf": true,
                "name": "Romance",
                "var": false
              },
              {
                "categoryId": 3522,
                "children": [],
                "leaf": true,
                "name": "Science Fiction eBooks",
                "var": false
              },
              {
                "categoryId": 3521,
                "children": [],
                "leaf": true,
                "name": "Science eBooks",
                "var": false
              },
              {
                "categoryId": 3523,
                "children": [],
                "leaf": true,
                "name": "Self Help",
                "var": false
              },
              {
                "categoryId": 3518,
                "children": [],
                "leaf": true,
                "name": "Social eBooks",
                "var": false
              },
              {
                "categoryId": 3524,
                "children": [],
                "leaf": true,
                "name": "Sport eBooks",
                "var": false
              },
              {
                "categoryId": 3506,
                "children": [],
                "leaf": true,
                "name": "Technology eBooks",
                "var": false
              },
              {
                "categoryId": 3499,
                "children": [],
                "leaf": true,
                "name": "Teens eBooks",
                "var": false
              },
              {
                "categoryId": 3516,
                "children": [],
                "leaf": true,
                "name": "Thrillers",
                "var": false
              },
              {
                "categoryId": 3525,
                "children": [],
                "leaf": true,
                "name": "Travel eBooks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Local eBooks",
            "var": false
          }
        ],
        "leaf": false,
        "name": "eBooks",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Media, Music & Books",
    "var": false
  },
  {
    "categoryId": 20000096,
    "children": [
      {
        "categoryId": 20000170,
        "children": [],
        "leaf": true,
        "name": "Consumer Products",
        "var": false
      },
      {
        "categoryId": 20000169,
        "children": [],
        "leaf": true,
        "name": "Medical Devices & Accessories",
        "var": false
      },
      {
        "categoryId": 20000171,
        "children": [],
        "leaf": true,
        "name": "Natural Remedies",
        "var": false
      },
      {
        "categoryId": 20000097,
        "children": [],
        "leaf": true,
        "name": "Over The Counter Drugs",
        "var": false
      },
      {
        "categoryId": 20000098,
        "children": [],
        "leaf": true,
        "name": "Prescription Drugs",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Medicine",
    "var": false
  },
  {
    "categoryId": 2,
    "children": [
      {
        "categoryId": 20000299,
        "children": [],
        "leaf": true,
        "name": "Feature Phones",
        "var": true
      },
      {
        "categoryId": 49,
        "children": [],
        "leaf": true,
        "name": "Landline Phones",
        "var": false
      },
      {
        "categoryId": 9536,
        "children": [
          {
            "categoryId": 9537,
            "children": [],
            "leaf": true,
            "name": "Cables & Converters",
            "var": false
          },
          {
            "categoryId": 10002870,
            "children": [],
            "leaf": true,
            "name": "Car Chargers",
            "var": false
          },
          {
            "categoryId": 9540,
            "children": [],
            "leaf": true,
            "name": "Car Mounts",
            "var": false
          },
          {
            "categoryId": 10002869,
            "children": [],
            "leaf": true,
            "name": "Docks & Stands",
            "var": false
          },
          {
            "categoryId": 9548,
            "children": [],
            "leaf": true,
            "name": "Fashion Accessories",
            "var": false
          },
          {
            "categoryId": 10002871,
            "children": [],
            "leaf": true,
            "name": "Fashion Mobile Accessories",
            "var": false
          },
          {
            "categoryId": 9545,
            "children": [
              {
                "categoryId": 9546,
                "children": [],
                "leaf": true,
                "name": "Replacement Parts",
                "var": false
              },
              {
                "categoryId": 9547,
                "children": [],
                "leaf": true,
                "name": "SIM Tools",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Parts & Tools",
            "var": false
          },
          {
            "categoryId": 9554,
            "children": [],
            "leaf": true,
            "name": "Phone Batteries",
            "var": false
          },
          {
            "categoryId": 10002878,
            "children": [],
            "leaf": true,
            "name": "Phone Camera Flash Lights",
            "var": false
          },
          {
            "categoryId": 9543,
            "children": [],
            "leaf": true,
            "name": "Phone Cases",
            "var": false
          },
          {
            "categoryId": 9562,
            "children": [],
            "leaf": true,
            "name": "Power Banks",
            "var": false
          },
          {
            "categoryId": 9549,
            "children": [],
            "leaf": true,
            "name": "Prepaid Cards",
            "var": false
          },
          {
            "categoryId": 9544,
            "children": [],
            "leaf": true,
            "name": "Screen Protectors",
            "var": false
          },
          {
            "categoryId": 10002877,
            "children": [],
            "leaf": true,
            "name": "Selfie Sticks",
            "var": false
          },
          {
            "categoryId": 10002875,
            "children": [],
            "leaf": true,
            "name": "Wall Chargers",
            "var": false
          },
          {
            "categoryId": 10002876,
            "children": [],
            "leaf": true,
            "name": "Wireless Chargers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Mobile Accessories",
        "var": false
      },
      {
        "categoryId": 3,
        "children": [],
        "leaf": true,
        "name": "Mobiles",
        "var": false
      },
      {
        "categoryId": 10002950,
        "children": [
          {
            "categoryId": 10002873,
            "children": [],
            "leaf": true,
            "name": "Cases & Covers",
            "var": false
          },
          {
            "categoryId": 10002874,
            "children": [],
            "leaf": true,
            "name": "Keyboards",
            "var": false
          },
          {
            "categoryId": 10002872,
            "children": [],
            "leaf": true,
            "name": "Stylus Pens",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Tablet Accessories",
        "var": false
      },
      {
        "categoryId": 7,
        "children": [],
        "leaf": true,
        "name": "Tablets",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Mobiles & Tablets",
    "var": false
  },
  {
    "categoryId": 8706,
    "children": [
      {
        "categoryId": 8887,
        "children": [
          {
            "categoryId": 8904,
            "children": [
              {
                "categoryId": 8905,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 8906,
                "children": [],
                "leaf": true,
                "name": "Backpacks",
                "var": false
              },
              {
                "categoryId": 8907,
                "children": [],
                "leaf": true,
                "name": "Slings",
                "var": false
              },
              {
                "categoryId": 8908,
                "children": [],
                "leaf": true,
                "name": "Soft Carriers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Backpacks & Carriers",
            "var": false
          },
          {
            "categoryId": 8909,
            "children": [],
            "leaf": true,
            "name": "Bicycle Child Seats & Trailers",
            "var": false
          },
          {
            "categoryId": 8888,
            "children": [
              {
                "categoryId": 8894,
                "children": [
                  {
                    "categoryId": 8895,
                    "children": [],
                    "leaf": true,
                    "name": "Bunting Bags",
                    "var": false
                  },
                  {
                    "categoryId": 8896,
                    "children": [],
                    "leaf": true,
                    "name": "Rear Facing Mirrors",
                    "var": false
                  },
                  {
                    "categoryId": 8897,
                    "children": [],
                    "leaf": true,
                    "name": "Seat Back Kick Protectors",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 8891,
                "children": [],
                "leaf": true,
                "name": "Booster",
                "var": false
              },
              {
                "categoryId": 8892,
                "children": [],
                "leaf": true,
                "name": "Convertible",
                "var": false
              },
              {
                "categoryId": 8889,
                "children": [],
                "leaf": true,
                "name": "Infant",
                "var": false
              },
              {
                "categoryId": 8890,
                "children": [],
                "leaf": true,
                "name": "Toddler",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Car Seats",
            "var": false
          },
          {
            "categoryId": 8910,
            "children": [],
            "leaf": true,
            "name": "Harnesses & Leashes",
            "var": false
          },
          {
            "categoryId": 10002719,
            "children": [
              {
                "categoryId": 10002765,
                "children": [],
                "leaf": true,
                "name": "Kids Bag",
                "var": false
              },
              {
                "categoryId": 10002766,
                "children": [],
                "leaf": true,
                "name": "Kids Luggage",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kids Bags & Luggage",
            "var": false
          },
          {
            "categoryId": 8911,
            "children": [],
            "leaf": true,
            "name": "Playards & Playpens",
            "var": false
          },
          {
            "categoryId": 8898,
            "children": [
              {
                "categoryId": 8903,
                "children": [
                  {
                    "categoryId": 10002825,
                    "children": [],
                    "leaf": true,
                    "name": "Cooling Fans",
                    "var": false
                  },
                  {
                    "categoryId": 10002828,
                    "children": [],
                    "leaf": true,
                    "name": "Organiser & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10002826,
                    "children": [],
                    "leaf": true,
                    "name": "Seat Liner",
                    "var": false
                  },
                  {
                    "categoryId": 10002827,
                    "children": [],
                    "leaf": true,
                    "name": "Shield & Netting",
                    "var": false
                  },
                  {
                    "categoryId": 10002824,
                    "children": [],
                    "leaf": true,
                    "name": "Stroller Toys",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 8900,
                "children": [],
                "leaf": true,
                "name": "Doubles & Triple",
                "var": false
              },
              {
                "categoryId": 8899,
                "children": [],
                "leaf": true,
                "name": "Joggers",
                "var": false
              },
              {
                "categoryId": 8901,
                "children": [],
                "leaf": true,
                "name": "Prams",
                "var": false
              },
              {
                "categoryId": 8902,
                "children": [],
                "leaf": true,
                "name": "Tandems",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Strollers",
            "var": false
          },
          {
            "categoryId": 8912,
            "children": [
              {
                "categoryId": 8913,
                "children": [],
                "leaf": true,
                "name": "Bouncers",
                "var": false
              },
              {
                "categoryId": 8914,
                "children": [],
                "leaf": true,
                "name": "Jumpers",
                "var": false
              },
              {
                "categoryId": 8915,
                "children": [],
                "leaf": true,
                "name": "Swings",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Swings, Jumpers & Bouncers",
            "var": false
          },
          {
            "categoryId": 9408,
            "children": [],
            "leaf": true,
            "name": "Travel Beds",
            "var": false
          },
          {
            "categoryId": 10002718,
            "children": [],
            "leaf": true,
            "name": "Travel Systems",
            "var": false
          },
          {
            "categoryId": 8916,
            "children": [],
            "leaf": true,
            "name": "Walkers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Baby Gear",
        "var": false
      },
      {
        "categoryId": 10002678,
        "children": [
          {
            "categoryId": 10002699,
            "children": [],
            "leaf": true,
            "name": "Colic & Gas Relief",
            "var": false
          },
          {
            "categoryId": 10002705,
            "children": [],
            "leaf": true,
            "name": "Masks & Repellents",
            "var": false
          },
          {
            "categoryId": 10002700,
            "children": [],
            "leaf": true,
            "name": "Nail Care",
            "var": false
          },
          {
            "categoryId": 10002701,
            "children": [],
            "leaf": true,
            "name": "Nasal Aspirators",
            "var": false
          },
          {
            "categoryId": 10002702,
            "children": [],
            "leaf": true,
            "name": "Teethers",
            "var": false
          },
          {
            "categoryId": 10002703,
            "children": [],
            "leaf": true,
            "name": "Teething Relief",
            "var": false
          },
          {
            "categoryId": 10002704,
            "children": [],
            "leaf": true,
            "name": "Thermometers",
            "var": false
          },
          {
            "categoryId": 10002706,
            "children": [],
            "leaf": true,
            "name": "Vitamins & Supplements",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Baby Health Care",
        "var": false
      },
      {
        "categoryId": 10002677,
        "children": [
          {
            "categoryId": 10002686,
            "children": [],
            "leaf": true,
            "name": "Aromatherapy",
            "var": false
          },
          {
            "categoryId": 10002694,
            "children": [],
            "leaf": true,
            "name": "Baby Colognes & Frangrances",
            "var": false
          },
          {
            "categoryId": 10002954,
            "children": [],
            "leaf": true,
            "name": "Baby Detergent",
            "var": false
          },
          {
            "categoryId": 10002687,
            "children": [],
            "leaf": true,
            "name": "Bathing Tubs & Seats",
            "var": false
          },
          {
            "categoryId": 10002688,
            "children": [],
            "leaf": true,
            "name": "Bubble Bath",
            "var": false
          },
          {
            "categoryId": 10002690,
            "children": [],
            "leaf": true,
            "name": "Grooming & Healthcare Kits",
            "var": false
          },
          {
            "categoryId": 10002691,
            "children": [],
            "leaf": true,
            "name": "Non-Slip Bath Mats",
            "var": false
          },
          {
            "categoryId": 10002689,
            "children": [],
            "leaf": true,
            "name": "Shampoo & Conditioners",
            "var": false
          },
          {
            "categoryId": 10002692,
            "children": [
              {
                "categoryId": 10002755,
                "children": [],
                "leaf": true,
                "name": "Lotions & Creams",
                "var": false
              },
              {
                "categoryId": 10002756,
                "children": [],
                "leaf": true,
                "name": "Oils",
                "var": false
              },
              {
                "categoryId": 10002757,
                "children": [],
                "leaf": true,
                "name": "Powders",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Skin Care",
            "var": false
          },
          {
            "categoryId": 10002693,
            "children": [
              {
                "categoryId": 10002758,
                "children": [],
                "leaf": true,
                "name": "Bar Soaps",
                "var": false
              },
              {
                "categoryId": 10002759,
                "children": [],
                "leaf": true,
                "name": "Body Wash",
                "var": false
              },
              {
                "categoryId": 10002760,
                "children": [],
                "leaf": true,
                "name": "Foaming Soaps",
                "var": false
              },
              {
                "categoryId": 10002761,
                "children": [],
                "leaf": true,
                "name": "Hypoallergenic Cleansers",
                "var": false
              },
              {
                "categoryId": 10002762,
                "children": [],
                "leaf": true,
                "name": "No-Rinse Cleansers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Soaps, Cleansers & Bodywash",
            "var": false
          },
          {
            "categoryId": 10002698,
            "children": [],
            "leaf": true,
            "name": "Sun Protection",
            "var": false
          },
          {
            "categoryId": 10002697,
            "children": [],
            "leaf": true,
            "name": "Toothbrushes & Toothpaste",
            "var": false
          },
          {
            "categoryId": 10002695,
            "children": [],
            "leaf": true,
            "name": "Travel Bathing Kits",
            "var": false
          },
          {
            "categoryId": 10002696,
            "children": [
              {
                "categoryId": 10002763,
                "children": [],
                "leaf": true,
                "name": "Hooded Towels",
                "var": false
              },
              {
                "categoryId": 10002764,
                "children": [],
                "leaf": true,
                "name": "Washcloths",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Washcloths & Towels",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Baby Personal Care",
        "var": false
      },
      {
        "categoryId": 10002679,
        "children": [
          {
            "categoryId": 10002708,
            "children": [],
            "leaf": true,
            "name": "Bathroom Safety",
            "var": false
          },
          {
            "categoryId": 10002709,
            "children": [],
            "leaf": true,
            "name": "Cabinet Locks & Straps",
            "var": false
          },
          {
            "categoryId": 10002710,
            "children": [],
            "leaf": true,
            "name": "Crib Netting",
            "var": false
          },
          {
            "categoryId": 10002711,
            "children": [],
            "leaf": true,
            "name": "Edge & Corner Guards",
            "var": false
          },
          {
            "categoryId": 10002712,
            "children": [],
            "leaf": true,
            "name": "Electrical Safety",
            "var": false
          },
          {
            "categoryId": 10002713,
            "children": [],
            "leaf": true,
            "name": "Gates & Doorways",
            "var": false
          },
          {
            "categoryId": 10002714,
            "children": [],
            "leaf": true,
            "name": "Monitors",
            "var": false
          },
          {
            "categoryId": 10002715,
            "children": [],
            "leaf": true,
            "name": "Rails & Rail Guards",
            "var": false
          },
          {
            "categoryId": 10002716,
            "children": [],
            "leaf": true,
            "name": "Safety Caps",
            "var": false
          },
          {
            "categoryId": 10002707,
            "children": [],
            "leaf": true,
            "name": "Sanitizers",
            "var": false
          },
          {
            "categoryId": 10002717,
            "children": [],
            "leaf": true,
            "name": "Sleep Positioners",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Baby Safety",
        "var": false
      },
      {
        "categoryId": 8965,
        "children": [
          {
            "categoryId": 9021,
            "children": [
              {
                "categoryId": 9061,
                "children": [
                  {
                    "categoryId": 9066,
                    "children": [],
                    "leaf": true,
                    "name": "Christening",
                    "var": false
                  },
                  {
                    "categoryId": 9062,
                    "children": [],
                    "leaf": true,
                    "name": "Gloves & Mittens",
                    "var": false
                  },
                  {
                    "categoryId": 9063,
                    "children": [],
                    "leaf": true,
                    "name": "Hats & Caps",
                    "var": false
                  },
                  {
                    "categoryId": 9064,
                    "children": [],
                    "leaf": true,
                    "name": "Socks",
                    "var": false
                  },
                  {
                    "categoryId": 9065,
                    "children": [],
                    "leaf": true,
                    "name": "Sunglasses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 9022,
                "children": [
                  {
                    "categoryId": 9023,
                    "children": [],
                    "leaf": true,
                    "name": "Body Suits",
                    "var": false
                  },
                  {
                    "categoryId": 9030,
                    "children": [
                      {
                        "categoryId": 9031,
                        "children": [],
                        "leaf": true,
                        "name": "Jeans",
                        "var": false
                      },
                      {
                        "categoryId": 9032,
                        "children": [],
                        "leaf": true,
                        "name": "Leggings",
                        "var": false
                      },
                      {
                        "categoryId": 9033,
                        "children": [],
                        "leaf": true,
                        "name": "Overalls",
                        "var": false
                      },
                      {
                        "categoryId": 9034,
                        "children": [],
                        "leaf": true,
                        "name": "Pants",
                        "var": false
                      },
                      {
                        "categoryId": 9035,
                        "children": [],
                        "leaf": true,
                        "name": "Shorts",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Bottoms",
                    "var": false
                  },
                  {
                    "categoryId": 9024,
                    "children": [],
                    "leaf": true,
                    "name": "Clothing Sets",
                    "var": false
                  },
                  {
                    "categoryId": 9036,
                    "children": [
                      {
                        "categoryId": 9041,
                        "children": [],
                        "leaf": true,
                        "name": "Coats",
                        "var": false
                      },
                      {
                        "categoryId": 9037,
                        "children": [],
                        "leaf": true,
                        "name": "Fleece",
                        "var": false
                      },
                      {
                        "categoryId": 9038,
                        "children": [],
                        "leaf": true,
                        "name": "Jackets",
                        "var": false
                      },
                      {
                        "categoryId": 9039,
                        "children": [],
                        "leaf": true,
                        "name": "Rain Wear",
                        "var": false
                      },
                      {
                        "categoryId": 9040,
                        "children": [],
                        "leaf": true,
                        "name": "Vests",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Jackets & Coats",
                    "var": false
                  },
                  {
                    "categoryId": 9042,
                    "children": [
                      {
                        "categoryId": 9044,
                        "children": [],
                        "leaf": true,
                        "name": "Blanket Sleepers",
                        "var": false
                      },
                      {
                        "categoryId": 9045,
                        "children": [],
                        "leaf": true,
                        "name": "Nightgown & Robes",
                        "var": false
                      },
                      {
                        "categoryId": 9043,
                        "children": [],
                        "leaf": true,
                        "name": "Pyjama sets",
                        "var": false
                      },
                      {
                        "categoryId": 9046,
                        "children": [],
                        "leaf": true,
                        "name": "Sleepsacks",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Sleepwear",
                    "var": false
                  },
                  {
                    "categoryId": 9025,
                    "children": [
                      {
                        "categoryId": 9029,
                        "children": [],
                        "leaf": true,
                        "name": "Hoodies",
                        "var": false
                      },
                      {
                        "categoryId": 9027,
                        "children": [],
                        "leaf": true,
                        "name": "Polos",
                        "var": false
                      },
                      {
                        "categoryId": 9028,
                        "children": [],
                        "leaf": true,
                        "name": "Sweaters & Fleece",
                        "var": false
                      },
                      {
                        "categoryId": 9026,
                        "children": [],
                        "leaf": true,
                        "name": "Tops & Tank Tees",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Tops & Tees",
                    "var": false
                  },
                  {
                    "categoryId": 9048,
                    "children": [],
                    "leaf": true,
                    "name": "Training pants",
                    "var": false
                  },
                  {
                    "categoryId": 9047,
                    "children": [],
                    "leaf": true,
                    "name": "Undershirts & camisoles",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 9054,
                "children": [
                  {
                    "categoryId": 9056,
                    "children": [],
                    "leaf": true,
                    "name": "Boots",
                    "var": false
                  },
                  {
                    "categoryId": 9057,
                    "children": [],
                    "leaf": true,
                    "name": "Flats",
                    "var": false
                  },
                  {
                    "categoryId": 9058,
                    "children": [],
                    "leaf": true,
                    "name": "Sandals",
                    "var": false
                  },
                  {
                    "categoryId": 9059,
                    "children": [],
                    "leaf": true,
                    "name": "Slippers",
                    "var": false
                  },
                  {
                    "categoryId": 9060,
                    "children": [],
                    "leaf": true,
                    "name": "Sneakers",
                    "var": false
                  },
                  {
                    "categoryId": 9055,
                    "children": [],
                    "leaf": true,
                    "name": "Sports",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              },
              {
                "categoryId": 9049,
                "children": [
                  {
                    "categoryId": 9052,
                    "children": [],
                    "leaf": true,
                    "name": "Cover-ups",
                    "var": false
                  },
                  {
                    "categoryId": 9050,
                    "children": [],
                    "leaf": true,
                    "name": "One-Piece",
                    "var": false
                  },
                  {
                    "categoryId": 9053,
                    "children": [],
                    "leaf": true,
                    "name": "Sun-suits",
                    "var": false
                  },
                  {
                    "categoryId": 9051,
                    "children": [],
                    "leaf": true,
                    "name": "Two-Piece",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Swim Wear",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Boys",
            "var": false
          },
          {
            "categoryId": 8972,
            "children": [
              {
                "categoryId": 9013,
                "children": [
                  {
                    "categoryId": 9020,
                    "children": [],
                    "leaf": true,
                    "name": "Christening",
                    "var": false
                  },
                  {
                    "categoryId": 9014,
                    "children": [],
                    "leaf": true,
                    "name": "Gloves & Mittens",
                    "var": false
                  },
                  {
                    "categoryId": 9016,
                    "children": [],
                    "leaf": true,
                    "name": "Hair accessories",
                    "var": false
                  },
                  {
                    "categoryId": 9015,
                    "children": [],
                    "leaf": true,
                    "name": "Hats & Caps",
                    "var": false
                  },
                  {
                    "categoryId": 9017,
                    "children": [],
                    "leaf": true,
                    "name": "Socks",
                    "var": false
                  },
                  {
                    "categoryId": 9019,
                    "children": [],
                    "leaf": true,
                    "name": "Sunglasses",
                    "var": false
                  },
                  {
                    "categoryId": 9018,
                    "children": [],
                    "leaf": true,
                    "name": "Tights & Leg Warmers",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 8973,
                "children": [
                  {
                    "categoryId": 8974,
                    "children": [],
                    "leaf": true,
                    "name": "Body Suits",
                    "var": false
                  },
                  {
                    "categoryId": 8981,
                    "children": [
                      {
                        "categoryId": 8982,
                        "children": [],
                        "leaf": true,
                        "name": "Jeans",
                        "var": false
                      },
                      {
                        "categoryId": 8983,
                        "children": [],
                        "leaf": true,
                        "name": "Leggings",
                        "var": false
                      },
                      {
                        "categoryId": 8984,
                        "children": [],
                        "leaf": true,
                        "name": "Overalls",
                        "var": false
                      },
                      {
                        "categoryId": 8985,
                        "children": [],
                        "leaf": true,
                        "name": "Pants",
                        "var": false
                      },
                      {
                        "categoryId": 8986,
                        "children": [],
                        "leaf": true,
                        "name": "Shorts",
                        "var": false
                      },
                      {
                        "categoryId": 8987,
                        "children": [],
                        "leaf": true,
                        "name": "Skirts",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Bottoms",
                    "var": false
                  },
                  {
                    "categoryId": 8975,
                    "children": [],
                    "leaf": true,
                    "name": "Clothing Sets",
                    "var": false
                  },
                  {
                    "categoryId": 8976,
                    "children": [],
                    "leaf": true,
                    "name": "Dresses",
                    "var": false
                  },
                  {
                    "categoryId": 8988,
                    "children": [
                      {
                        "categoryId": 8993,
                        "children": [],
                        "leaf": true,
                        "name": "Coats",
                        "var": false
                      },
                      {
                        "categoryId": 8989,
                        "children": [],
                        "leaf": true,
                        "name": "Fleece",
                        "var": false
                      },
                      {
                        "categoryId": 8990,
                        "children": [],
                        "leaf": true,
                        "name": "Jackets",
                        "var": false
                      },
                      {
                        "categoryId": 8991,
                        "children": [],
                        "leaf": true,
                        "name": "Rain Wear",
                        "var": false
                      },
                      {
                        "categoryId": 8992,
                        "children": [],
                        "leaf": true,
                        "name": "Vests",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Jackets & Coats",
                    "var": false
                  },
                  {
                    "categoryId": 8994,
                    "children": [
                      {
                        "categoryId": 8996,
                        "children": [],
                        "leaf": true,
                        "name": "Blanket Sleepers",
                        "var": false
                      },
                      {
                        "categoryId": 8997,
                        "children": [],
                        "leaf": true,
                        "name": "Nightgown & Robes",
                        "var": false
                      },
                      {
                        "categoryId": 8995,
                        "children": [],
                        "leaf": true,
                        "name": "Pyjama sets",
                        "var": false
                      },
                      {
                        "categoryId": 8998,
                        "children": [],
                        "leaf": true,
                        "name": "Sleepsacks",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Sleepwear",
                    "var": false
                  },
                  {
                    "categoryId": 8977,
                    "children": [
                      {
                        "categoryId": 8979,
                        "children": [],
                        "leaf": true,
                        "name": "Blouses & Polos",
                        "var": false
                      },
                      {
                        "categoryId": 9409,
                        "children": [],
                        "leaf": true,
                        "name": "Hoodies",
                        "var": false
                      },
                      {
                        "categoryId": 8980,
                        "children": [],
                        "leaf": true,
                        "name": "Sweaters & Fleece",
                        "var": false
                      },
                      {
                        "categoryId": 8978,
                        "children": [],
                        "leaf": true,
                        "name": "Tops & Tank Tees",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Tops & Tees",
                    "var": false
                  },
                  {
                    "categoryId": 9000,
                    "children": [],
                    "leaf": true,
                    "name": "Training pants",
                    "var": false
                  },
                  {
                    "categoryId": 8999,
                    "children": [],
                    "leaf": true,
                    "name": "Undershirt & camisoles",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 9006,
                "children": [
                  {
                    "categoryId": 9008,
                    "children": [],
                    "leaf": true,
                    "name": "Boots",
                    "var": false
                  },
                  {
                    "categoryId": 9009,
                    "children": [],
                    "leaf": true,
                    "name": "Flats",
                    "var": false
                  },
                  {
                    "categoryId": 9010,
                    "children": [],
                    "leaf": true,
                    "name": "Sandals",
                    "var": false
                  },
                  {
                    "categoryId": 9011,
                    "children": [],
                    "leaf": true,
                    "name": "Slippers",
                    "var": false
                  },
                  {
                    "categoryId": 9012,
                    "children": [],
                    "leaf": true,
                    "name": "Sneakers",
                    "var": false
                  },
                  {
                    "categoryId": 9007,
                    "children": [],
                    "leaf": true,
                    "name": "Sports",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              },
              {
                "categoryId": 9001,
                "children": [
                  {
                    "categoryId": 9004,
                    "children": [],
                    "leaf": true,
                    "name": "Cover-ups",
                    "var": false
                  },
                  {
                    "categoryId": 9002,
                    "children": [],
                    "leaf": true,
                    "name": "One-Piece",
                    "var": false
                  },
                  {
                    "categoryId": 9005,
                    "children": [],
                    "leaf": true,
                    "name": "Sun-suits",
                    "var": false
                  },
                  {
                    "categoryId": 9003,
                    "children": [],
                    "leaf": true,
                    "name": "Two-Piece",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Swim Wear",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Girls",
            "var": false
          },
          {
            "categoryId": 8966,
            "children": [
              {
                "categoryId": 8971,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 8967,
                "children": [],
                "leaf": true,
                "name": "Bodysuits & One-Pieces",
                "var": false
              },
              {
                "categoryId": 8969,
                "children": [],
                "leaf": true,
                "name": "Bottoms",
                "var": false
              },
              {
                "categoryId": 8970,
                "children": [],
                "leaf": true,
                "name": "Sets & Packs",
                "var": false
              },
              {
                "categoryId": 8968,
                "children": [],
                "leaf": true,
                "name": "Sweaters & Fleece",
                "var": false
              }
            ],
            "leaf": false,
            "name": "New Born Unisex (0 - 6 mnths)",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Clothing & Accessories",
        "var": false
      },
      {
        "categoryId": 8757,
        "children": [
          {
            "categoryId": 8758,
            "children": [
              {
                "categoryId": 8759,
                "children": [],
                "leaf": true,
                "name": "Changing Tables",
                "var": false
              },
              {
                "categoryId": 8761,
                "children": [],
                "leaf": true,
                "name": "Kits",
                "var": false
              },
              {
                "categoryId": 8760,
                "children": [],
                "leaf": true,
                "name": "Pads & Covers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Changing Tables, Pads & Kits",
            "var": false
          },
          {
            "categoryId": 8762,
            "children": [
              {
                "categoryId": 8763,
                "children": [],
                "leaf": true,
                "name": "Cloth Diapers",
                "var": false
              },
              {
                "categoryId": 8769,
                "children": [],
                "leaf": true,
                "name": "Covers",
                "var": false
              },
              {
                "categoryId": 8765,
                "children": [],
                "leaf": true,
                "name": "Inserts & Liners",
                "var": false
              },
              {
                "categoryId": 8764,
                "children": [],
                "leaf": true,
                "name": "Pins & Fasteners",
                "var": false
              },
              {
                "categoryId": 8767,
                "children": [],
                "leaf": true,
                "name": "Sprayers",
                "var": false
              },
              {
                "categoryId": 8768,
                "children": [],
                "leaf": true,
                "name": "Wet Bags",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cloth Diapers & Accessories",
            "var": false
          },
          {
            "categoryId": 8774,
            "children": [],
            "leaf": true,
            "name": "Diaper Bags",
            "var": false
          },
          {
            "categoryId": 8780,
            "children": [
              {
                "categoryId": 8781,
                "children": [],
                "leaf": true,
                "name": "Creams & Ointments",
                "var": false
              },
              {
                "categoryId": 8782,
                "children": [],
                "leaf": true,
                "name": "Pails & Refills",
                "var": false
              },
              {
                "categoryId": 8784,
                "children": [],
                "leaf": true,
                "name": "Powders",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Diapering Care",
            "var": false
          },
          {
            "categoryId": 8770,
            "children": [],
            "leaf": true,
            "name": "Disposable Diapers",
            "var": false
          },
          {
            "categoryId": 10002685,
            "children": [
              {
                "categoryId": 10002752,
                "children": [],
                "leaf": true,
                "name": "Potty Chair",
                "var": false
              },
              {
                "categoryId": 10002753,
                "children": [],
                "leaf": true,
                "name": "Potty Seats",
                "var": false
              },
              {
                "categoryId": 10002754,
                "children": [],
                "leaf": true,
                "name": "Step Stools",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Potty Training",
            "var": false
          },
          {
            "categoryId": 10002684,
            "children": [],
            "leaf": true,
            "name": "Stackers & Caddies",
            "var": false
          },
          {
            "categoryId": 8785,
            "children": [
              {
                "categoryId": 8787,
                "children": [],
                "leaf": true,
                "name": "Wipe Holders & Warmers",
                "var": false
              },
              {
                "categoryId": 8786,
                "children": [],
                "leaf": true,
                "name": "Wipes & Refills",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Wipes & Holders",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Diapering & Potty",
        "var": false
      },
      {
        "categoryId": 8707,
        "children": [
          {
            "categoryId": 8718,
            "children": [
              {
                "categoryId": 8719,
                "children": [],
                "leaf": true,
                "name": "Beverages",
                "var": false
              },
              {
                "categoryId": 8720,
                "children": [],
                "leaf": true,
                "name": "Cereal",
                "var": false
              },
              {
                "categoryId": 8721,
                "children": [],
                "leaf": true,
                "name": "Crackers & Biscuits",
                "var": false
              },
              {
                "categoryId": 10002746,
                "children": [],
                "leaf": true,
                "name": "Puree",
                "var": false
              },
              {
                "categoryId": 8722,
                "children": [],
                "leaf": true,
                "name": "Snack Foods",
                "var": false
              },
              {
                "categoryId": 8723,
                "children": [],
                "leaf": true,
                "name": "Toddler Food",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Baby & Toddler Foods",
            "var": false
          },
          {
            "categoryId": 8725,
            "children": [
              {
                "categoryId": 10002747,
                "children": [],
                "leaf": true,
                "name": "Bottle Care & Cleaning",
                "var": false
              },
              {
                "categoryId": 8726,
                "children": [],
                "leaf": true,
                "name": "Bottle Nipples & Accessories",
                "var": false
              },
              {
                "categoryId": 8727,
                "children": [],
                "leaf": true,
                "name": "Bottle Tote Bags",
                "var": false
              },
              {
                "categoryId": 8728,
                "children": [],
                "leaf": true,
                "name": "Bottles",
                "var": false
              },
              {
                "categoryId": 8729,
                "children": [],
                "leaf": true,
                "name": "Formula Dispensers & Mixers",
                "var": false
              },
              {
                "categoryId": 8730,
                "children": [],
                "leaf": true,
                "name": "Warmers & Sterilizers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Bottle-Feeding",
            "var": false
          },
          {
            "categoryId": 8731,
            "children": [
              {
                "categoryId": 8739,
                "children": [],
                "leaf": true,
                "name": "Breast Pads & Nipple Shield",
                "var": false
              },
              {
                "categoryId": 10002748,
                "children": [],
                "leaf": true,
                "name": "Breast Pump Accessories",
                "var": false
              },
              {
                "categoryId": 8732,
                "children": [],
                "leaf": true,
                "name": "Breast Pumps",
                "var": false
              },
              {
                "categoryId": 10002749,
                "children": [],
                "leaf": true,
                "name": "Breast Shells",
                "var": false
              },
              {
                "categoryId": 10002750,
                "children": [],
                "leaf": true,
                "name": "Nipple Care",
                "var": false
              },
              {
                "categoryId": 8738,
                "children": [],
                "leaf": true,
                "name": "Nursing Covers",
                "var": false
              },
              {
                "categoryId": 10002751,
                "children": [],
                "leaf": true,
                "name": "Wipes & Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Breastfeeding",
            "var": false
          },
          {
            "categoryId": 8740,
            "children": [],
            "leaf": true,
            "name": "Food Blenders",
            "var": false
          },
          {
            "categoryId": 8741,
            "children": [
              {
                "categoryId": 8742,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 8743,
                "children": [],
                "leaf": true,
                "name": "Booster & Hook-On Seats",
                "var": false
              },
              {
                "categoryId": 8744,
                "children": [],
                "leaf": true,
                "name": "Highchairs",
                "var": false
              },
              {
                "categoryId": 8745,
                "children": [],
                "leaf": true,
                "name": "Pillow and covers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Highchairs & Booster Seats",
            "var": false
          },
          {
            "categoryId": 8708,
            "children": [
              {
                "categoryId": 8711,
                "children": [],
                "leaf": true,
                "name": "Follow On (6 - 12 mnths)",
                "var": false
              },
              {
                "categoryId": 8712,
                "children": [],
                "leaf": true,
                "name": "Growing-up Milk (3yrs +)",
                "var": false
              },
              {
                "categoryId": 8710,
                "children": [],
                "leaf": true,
                "name": "Infant (0 - 6 mnths)",
                "var": false
              },
              {
                "categoryId": 8709,
                "children": [],
                "leaf": true,
                "name": "Maternal",
                "var": false
              },
              {
                "categoryId": 8713,
                "children": [],
                "leaf": true,
                "name": "Tailored Nutrition",
                "var": false
              },
              {
                "categoryId": 10199,
                "children": [],
                "leaf": true,
                "name": "Toddler Milk (1 - under 3 yrs)",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Milk Formula",
            "var": false
          },
          {
            "categoryId": 8746,
            "children": [
              {
                "categoryId": 8748,
                "children": [],
                "leaf": true,
                "name": "Foot Stools",
                "var": false
              },
              {
                "categoryId": 8747,
                "children": [],
                "leaf": true,
                "name": "Pillows & Covers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Pillows & Stools",
            "var": false
          },
          {
            "categoryId": 8749,
            "children": [
              {
                "categoryId": 8751,
                "children": [],
                "leaf": true,
                "name": "Bowl, Fork & Spoon Sets",
                "var": false
              },
              {
                "categoryId": 8750,
                "children": [],
                "leaf": true,
                "name": "Cups",
                "var": false
              },
              {
                "categoryId": 8752,
                "children": [],
                "leaf": true,
                "name": "Food Storage",
                "var": false
              },
              {
                "categoryId": 8753,
                "children": [],
                "leaf": true,
                "name": "Place Mats",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Utensils",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Feeding",
        "var": false
      },
      {
        "categoryId": 8936,
        "children": [
          {
            "categoryId": 8937,
            "children": [
              {
                "categoryId": 8938,
                "children": [],
                "leaf": true,
                "name": "Albums",
                "var": false
              },
              {
                "categoryId": 8939,
                "children": [],
                "leaf": true,
                "name": "Frames",
                "var": false
              },
              {
                "categoryId": 8940,
                "children": [],
                "leaf": true,
                "name": "Journals",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Albums, Frames & Journals",
            "var": false
          },
          {
            "categoryId": 8941,
            "children": [],
            "leaf": true,
            "name": "Gift Baskets",
            "var": false
          },
          {
            "categoryId": 8942,
            "children": [
              {
                "categoryId": 8943,
                "children": [],
                "leaf": true,
                "name": "Bathing",
                "var": false
              },
              {
                "categoryId": 8944,
                "children": [],
                "leaf": true,
                "name": "Bedding",
                "var": false
              },
              {
                "categoryId": 8945,
                "children": [],
                "leaf": true,
                "name": "Diapering",
                "var": false
              },
              {
                "categoryId": 8947,
                "children": [],
                "leaf": true,
                "name": "Feeding",
                "var": false
              },
              {
                "categoryId": 8948,
                "children": [],
                "leaf": true,
                "name": "Nursery Décor",
                "var": false
              },
              {
                "categoryId": 8949,
                "children": [],
                "leaf": true,
                "name": "Toy Sets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Gift Sets",
            "var": false
          },
          {
            "categoryId": 8950,
            "children": [
              {
                "categoryId": 8951,
                "children": [],
                "leaf": true,
                "name": "Bronzed Baby Shoes",
                "var": false
              },
              {
                "categoryId": 8957,
                "children": [],
                "leaf": true,
                "name": "Christening",
                "var": false
              },
              {
                "categoryId": 8952,
                "children": [],
                "leaf": true,
                "name": "Hand & Footprint Makers",
                "var": false
              },
              {
                "categoryId": 8953,
                "children": [],
                "leaf": true,
                "name": "Keepsake Boxes & Tins",
                "var": false
              },
              {
                "categoryId": 8954,
                "children": [],
                "leaf": true,
                "name": "Keepsake Frames",
                "var": false
              },
              {
                "categoryId": 8955,
                "children": [],
                "leaf": true,
                "name": "Keepsake Rattles",
                "var": false
              },
              {
                "categoryId": 8956,
                "children": [],
                "leaf": true,
                "name": "Silver Baby Spoons",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Keepsakes",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Gifts",
        "var": false
      },
      {
        "categoryId": 8958,
        "children": [
          {
            "categoryId": 8959,
            "children": [
              {
                "categoryId": 8960,
                "children": [],
                "leaf": true,
                "name": "Supporting Belts",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Maternity Accessories",
            "var": false
          },
          {
            "categoryId": 10002721,
            "children": [],
            "leaf": false,
            "name": "Maternity Health",
            "var": false
          },
          {
            "categoryId": 10002720,
            "children": [
              {
                "categoryId": 10002769,
                "children": [],
                "leaf": true,
                "name": "Matenity Bottoms",
                "var": true
              },
              {
                "categoryId": 10002767,
                "children": [],
                "leaf": true,
                "name": "Maternity Dresses",
                "var": true
              },
              {
                "categoryId": 10002770,
                "children": [],
                "leaf": true,
                "name": "Maternity Intimates",
                "var": true
              },
              {
                "categoryId": 10002771,
                "children": [],
                "leaf": true,
                "name": "Maternity Swimwear",
                "var": true
              },
              {
                "categoryId": 10002768,
                "children": [],
                "leaf": true,
                "name": "Maternity Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Maternity Wear",
            "var": false
          },
          {
            "categoryId": 8962,
            "children": [],
            "leaf": true,
            "name": "Moisturizers & Creams",
            "var": false
          },
          {
            "categoryId": 8963,
            "children": [],
            "leaf": true,
            "name": "Pregnancy Pillows",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Maternity Care",
        "var": false
      },
      {
        "categoryId": 8788,
        "children": [
          {
            "categoryId": 8813,
            "children": [
              {
                "categoryId": 8814,
                "children": [],
                "leaf": true,
                "name": "Armoires",
                "var": false
              },
              {
                "categoryId": 8817,
                "children": [],
                "leaf": true,
                "name": "Bassinets",
                "var": false
              },
              {
                "categoryId": 8815,
                "children": [],
                "leaf": true,
                "name": "Bed Rails",
                "var": false
              },
              {
                "categoryId": 8816,
                "children": [],
                "leaf": true,
                "name": "Chests & Dressers",
                "var": false
              },
              {
                "categoryId": 8818,
                "children": [],
                "leaf": true,
                "name": "Cradles",
                "var": false
              },
              {
                "categoryId": 8819,
                "children": [],
                "leaf": true,
                "name": "Cribs",
                "var": false
              },
              {
                "categoryId": 8821,
                "children": [],
                "leaf": true,
                "name": "Furniture Sets",
                "var": false
              },
              {
                "categoryId": 8822,
                "children": [],
                "leaf": true,
                "name": "Gliders, Ottomans & Rocking Chairs",
                "var": false
              },
              {
                "categoryId": 8820,
                "children": [],
                "leaf": true,
                "name": "Moses",
                "var": false
              },
              {
                "categoryId": 8823,
                "children": [],
                "leaf": true,
                "name": "Toddler Beds",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Baby Furniture",
            "var": false
          },
          {
            "categoryId": 8789,
            "children": [
              {
                "categoryId": 8790,
                "children": [],
                "leaf": true,
                "name": "Blankets & Wrappers",
                "var": false
              },
              {
                "categoryId": 8791,
                "children": [
                  {
                    "categoryId": 8792,
                    "children": [],
                    "leaf": true,
                    "name": "Bed Skirts",
                    "var": false
                  },
                  {
                    "categoryId": 8793,
                    "children": [],
                    "leaf": true,
                    "name": "Bedding Sets",
                    "var": false
                  },
                  {
                    "categoryId": 8794,
                    "children": [],
                    "leaf": true,
                    "name": "Bumpers",
                    "var": false
                  },
                  {
                    "categoryId": 8795,
                    "children": [],
                    "leaf": true,
                    "name": "Mattress Pads",
                    "var": false
                  },
                  {
                    "categoryId": 8796,
                    "children": [],
                    "leaf": true,
                    "name": "Sheets",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Cradle Bedding",
                "var": false
              },
              {
                "categoryId": 8797,
                "children": [],
                "leaf": true,
                "name": "Crib Bedding",
                "var": false
              },
              {
                "categoryId": 8809,
                "children": [
                  {
                    "categoryId": 8810,
                    "children": [],
                    "leaf": true,
                    "name": "Cradle Mattresses",
                    "var": false
                  },
                  {
                    "categoryId": 8811,
                    "children": [],
                    "leaf": true,
                    "name": "Crib Mattresses",
                    "var": false
                  },
                  {
                    "categoryId": 8812,
                    "children": [],
                    "leaf": true,
                    "name": "Portable Crib Mattresses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Mattresses",
                "var": false
              },
              {
                "categoryId": 8798,
                "children": [],
                "leaf": true,
                "name": "Pillows, Protector & Cases",
                "var": false
              },
              {
                "categoryId": 8802,
                "children": [],
                "leaf": true,
                "name": "Playard Bedding",
                "var": false
              },
              {
                "categoryId": 8803,
                "children": [],
                "leaf": true,
                "name": "Quilts & Bed Covers",
                "var": false
              },
              {
                "categoryId": 8804,
                "children": [
                  {
                    "categoryId": 8805,
                    "children": [],
                    "leaf": true,
                    "name": "Bed Skirts",
                    "var": false
                  },
                  {
                    "categoryId": 8806,
                    "children": [],
                    "leaf": true,
                    "name": "Bedding Sets",
                    "var": false
                  },
                  {
                    "categoryId": 8807,
                    "children": [],
                    "leaf": true,
                    "name": "Mattress Pads",
                    "var": false
                  },
                  {
                    "categoryId": 8808,
                    "children": [],
                    "leaf": true,
                    "name": "Sheets",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Toddler Bedding",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Mattresses & Bedding",
            "var": false
          },
          {
            "categoryId": 8830,
            "children": [
              {
                "categoryId": 8831,
                "children": [],
                "leaf": true,
                "name": "Baskets & Liners",
                "var": false
              },
              {
                "categoryId": 8832,
                "children": [],
                "leaf": true,
                "name": "Bookends",
                "var": false
              },
              {
                "categoryId": 8833,
                "children": [],
                "leaf": true,
                "name": "Clocks",
                "var": false
              },
              {
                "categoryId": 8834,
                "children": [],
                "leaf": true,
                "name": "Lamps & Shades",
                "var": false
              },
              {
                "categoryId": 8835,
                "children": [],
                "leaf": true,
                "name": "Mobiles",
                "var": false
              },
              {
                "categoryId": 8836,
                "children": [],
                "leaf": true,
                "name": "Night Lights",
                "var": false
              },
              {
                "categoryId": 8837,
                "children": [],
                "leaf": true,
                "name": "Picture Frames",
                "var": false
              },
              {
                "categoryId": 8838,
                "children": [],
                "leaf": true,
                "name": "Pillows",
                "var": false
              },
              {
                "categoryId": 8839,
                "children": [],
                "leaf": true,
                "name": "Rugs",
                "var": false
              },
              {
                "categoryId": 8840,
                "children": [],
                "leaf": true,
                "name": "Slipcovers",
                "var": false
              },
              {
                "categoryId": 8841,
                "children": [],
                "leaf": true,
                "name": "Wall Décor & Switch Plates",
                "var": false
              },
              {
                "categoryId": 8842,
                "children": [],
                "leaf": true,
                "name": "Waste Bins",
                "var": false
              },
              {
                "categoryId": 8843,
                "children": [],
                "leaf": true,
                "name": "Window Treatments",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Nursery Décor",
            "var": false
          },
          {
            "categoryId": 8824,
            "children": [
              {
                "categoryId": 8825,
                "children": [],
                "leaf": true,
                "name": "Hampers",
                "var": false
              },
              {
                "categoryId": 8826,
                "children": [],
                "leaf": true,
                "name": "Hanging Organizers",
                "var": false
              },
              {
                "categoryId": 8827,
                "children": [],
                "leaf": true,
                "name": "Step Stools",
                "var": false
              },
              {
                "categoryId": 8828,
                "children": [],
                "leaf": true,
                "name": "Storage Baskets",
                "var": false
              },
              {
                "categoryId": 8829,
                "children": [],
                "leaf": true,
                "name": "Toy Chests",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Storage & Organization",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Nursery",
        "var": false
      },
      {
        "categoryId": 8754,
        "children": [
          {
            "categoryId": 8755,
            "children": [],
            "leaf": true,
            "name": "Leashes & Cases",
            "var": false
          },
          {
            "categoryId": 8756,
            "children": [],
            "leaf": true,
            "name": "Pacifiers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Pacifiers & Accessories",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Mother & Baby",
    "var": false
  },
  {
    "categoryId": 3214,
    "children": [
      {
        "categoryId": 3215,
        "children": [
          {
            "categoryId": 3257,
            "children": [
              {
                "categoryId": 10001050,
                "children": [],
                "leaf": true,
                "name": "Additives",
                "var": false
              },
              {
                "categoryId": 10001052,
                "children": [],
                "leaf": true,
                "name": "Adhesives",
                "var": false
              },
              {
                "categoryId": 10001051,
                "children": [],
                "leaf": true,
                "name": "Antifreezes & Coolants",
                "var": false
              },
              {
                "categoryId": 10001053,
                "children": [],
                "leaf": true,
                "name": "Brake Fluids",
                "var": false
              },
              {
                "categoryId": 10001054,
                "children": [],
                "leaf": true,
                "name": "Cleaners",
                "var": false
              },
              {
                "categoryId": 10001055,
                "children": [],
                "leaf": true,
                "name": "Flushes",
                "var": false
              },
              {
                "categoryId": 10001056,
                "children": [],
                "leaf": true,
                "name": "Greases & Lubricants",
                "var": false
              },
              {
                "categoryId": 10001057,
                "children": [],
                "leaf": true,
                "name": "Oils",
                "var": false
              },
              {
                "categoryId": 10001058,
                "children": [],
                "leaf": true,
                "name": "Power Steering Fluids",
                "var": false
              },
              {
                "categoryId": 10001059,
                "children": [],
                "leaf": true,
                "name": "Radiator Conditioners & Protectants",
                "var": false
              },
              {
                "categoryId": 10001060,
                "children": [],
                "leaf": true,
                "name": "Refrigerants",
                "var": false
              },
              {
                "categoryId": 10001062,
                "children": [],
                "leaf": true,
                "name": "Transmission Fluids",
                "var": false
              },
              {
                "categoryId": 10001063,
                "children": [],
                "leaf": true,
                "name": "Windshield Washer Fluids",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Auto Oils & Fluids",
            "var": false
          },
          {
            "categoryId": 10000964,
            "children": [
              {
                "categoryId": 10001115,
                "children": [
                  {
                    "categoryId": 10001565,
                    "children": [],
                    "leaf": true,
                    "name": "Alternators",
                    "var": false
                  },
                  {
                    "categoryId": 10001566,
                    "children": [],
                    "leaf": true,
                    "name": "Bearings",
                    "var": false
                  },
                  {
                    "categoryId": 10001567,
                    "children": [],
                    "leaf": true,
                    "name": "Brackets",
                    "var": false
                  },
                  {
                    "categoryId": 10001568,
                    "children": [],
                    "leaf": true,
                    "name": "Brush",
                    "var": false
                  },
                  {
                    "categoryId": 10001569,
                    "children": [],
                    "leaf": true,
                    "name": "Condensers",
                    "var": false
                  },
                  {
                    "categoryId": 10001570,
                    "children": [],
                    "leaf": true,
                    "name": "Diodes",
                    "var": false
                  },
                  {
                    "categoryId": 10001571,
                    "children": [],
                    "leaf": true,
                    "name": "Generators",
                    "var": false
                  },
                  {
                    "categoryId": 10001572,
                    "children": [],
                    "leaf": true,
                    "name": "Pulleys",
                    "var": false
                  },
                  {
                    "categoryId": 10001573,
                    "children": [],
                    "leaf": true,
                    "name": "Rectifiers",
                    "var": false
                  },
                  {
                    "categoryId": 10001574,
                    "children": [],
                    "leaf": true,
                    "name": "Repair & Upgrade Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001575,
                    "children": [],
                    "leaf": true,
                    "name": "Resistors",
                    "var": false
                  },
                  {
                    "categoryId": 10001576,
                    "children": [],
                    "leaf": true,
                    "name": "Rotors & Armatures",
                    "var": false
                  },
                  {
                    "categoryId": 10001577,
                    "children": [],
                    "leaf": true,
                    "name": "Stators & Winding",
                    "var": false
                  },
                  {
                    "categoryId": 10001578,
                    "children": [],
                    "leaf": true,
                    "name": "Voltage Regulators",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Alternators & Generators",
                "var": false
              },
              {
                "categoryId": 10001100,
                "children": [],
                "leaf": true,
                "name": "Bearings & Seals",
                "var": false
              },
              {
                "categoryId": 10001101,
                "children": [],
                "leaf": true,
                "name": "Belts, Hoses & Pulleys",
                "var": false
              },
              {
                "categoryId": 10001103,
                "children": [
                  {
                    "categoryId": 10001336,
                    "children": [],
                    "leaf": true,
                    "name": "Air Dams",
                    "var": false
                  },
                  {
                    "categoryId": 10001337,
                    "children": [],
                    "leaf": true,
                    "name": "Automotive Glass",
                    "var": false
                  },
                  {
                    "categoryId": 10001339,
                    "children": [],
                    "leaf": true,
                    "name": "Clips",
                    "var": false
                  },
                  {
                    "categoryId": 10001340,
                    "children": [],
                    "leaf": true,
                    "name": "Doors",
                    "var": false
                  },
                  {
                    "categoryId": 10001341,
                    "children": [],
                    "leaf": true,
                    "name": "Evaporator Housing",
                    "var": false
                  },
                  {
                    "categoryId": 10001342,
                    "children": [],
                    "leaf": true,
                    "name": "Exterior Mirrors",
                    "var": false
                  },
                  {
                    "categoryId": 10001343,
                    "children": [],
                    "leaf": true,
                    "name": "Fan Shrouds",
                    "var": false
                  },
                  {
                    "categoryId": 10001344,
                    "children": [],
                    "leaf": true,
                    "name": "Fender",
                    "var": false
                  },
                  {
                    "categoryId": 10001345,
                    "children": [],
                    "leaf": true,
                    "name": "Floor Pans",
                    "var": false
                  },
                  {
                    "categoryId": 10001347,
                    "children": [],
                    "leaf": true,
                    "name": "Grilles",
                    "var": false
                  },
                  {
                    "categoryId": 10001348,
                    "children": [],
                    "leaf": true,
                    "name": "Hatchbacks",
                    "var": false
                  },
                  {
                    "categoryId": 10001349,
                    "children": [],
                    "leaf": true,
                    "name": "Header Panels",
                    "var": false
                  },
                  {
                    "categoryId": 10001350,
                    "children": [],
                    "leaf": true,
                    "name": "Hood Scoops",
                    "var": false
                  },
                  {
                    "categoryId": 10001351,
                    "children": [],
                    "leaf": true,
                    "name": "Hoods",
                    "var": false
                  },
                  {
                    "categoryId": 10001352,
                    "children": [],
                    "leaf": true,
                    "name": "Insulation",
                    "var": false
                  },
                  {
                    "categoryId": 10001353,
                    "children": [],
                    "leaf": true,
                    "name": "Lighting Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001354,
                    "children": [],
                    "leaf": true,
                    "name": "Moldings",
                    "var": false
                  },
                  {
                    "categoryId": 10001355,
                    "children": [],
                    "leaf": true,
                    "name": "Reservoirs",
                    "var": false
                  },
                  {
                    "categoryId": 10001356,
                    "children": [],
                    "leaf": true,
                    "name": "Sunroofs",
                    "var": false
                  },
                  {
                    "categoryId": 10001357,
                    "children": [],
                    "leaf": true,
                    "name": "Tops & Roofs",
                    "var": false
                  },
                  {
                    "categoryId": 10001358,
                    "children": [],
                    "leaf": true,
                    "name": "Truck Beds & Tailgates",
                    "var": false
                  },
                  {
                    "categoryId": 10001359,
                    "children": [],
                    "leaf": true,
                    "name": "Trunks",
                    "var": false
                  },
                  {
                    "categoryId": 10001360,
                    "children": [],
                    "leaf": true,
                    "name": "Valances",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Body Parts",
                "var": false
              },
              {
                "categoryId": 10001105,
                "children": [
                  {
                    "categoryId": 10001361,
                    "children": [],
                    "leaf": true,
                    "name": "ABS",
                    "var": false
                  },
                  {
                    "categoryId": 10001363,
                    "children": [],
                    "leaf": true,
                    "name": "Bottom Cups",
                    "var": false
                  },
                  {
                    "categoryId": 10001364,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001365,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Pads",
                    "var": false
                  },
                  {
                    "categoryId": 10001366,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Shoes",
                    "var": false
                  },
                  {
                    "categoryId": 10001367,
                    "children": [],
                    "leaf": true,
                    "name": "Bushings",
                    "var": false
                  },
                  {
                    "categoryId": 10001368,
                    "children": [],
                    "leaf": true,
                    "name": "Cables",
                    "var": false
                  },
                  {
                    "categoryId": 10001369,
                    "children": [],
                    "leaf": true,
                    "name": "Cadmium-Plated Tees",
                    "var": false
                  },
                  {
                    "categoryId": 10001370,
                    "children": [],
                    "leaf": true,
                    "name": "Calipers",
                    "var": false
                  },
                  {
                    "categoryId": 10001371,
                    "children": [],
                    "leaf": true,
                    "name": "Combination Axle Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001372,
                    "children": [],
                    "leaf": true,
                    "name": "Disc Hardware Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001373,
                    "children": [],
                    "leaf": true,
                    "name": "Drums and Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001374,
                    "children": [],
                    "leaf": true,
                    "name": "Fluid Reservoirs",
                    "var": false
                  },
                  {
                    "categoryId": 10001375,
                    "children": [],
                    "leaf": true,
                    "name": "Hold-Down Parts Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001376,
                    "children": [],
                    "leaf": true,
                    "name": "Hose, Lines & Fittings",
                    "var": false
                  },
                  {
                    "categoryId": 10001377,
                    "children": [],
                    "leaf": true,
                    "name": "Hydraulic Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001378,
                    "children": [],
                    "leaf": true,
                    "name": "Hydrovac Units",
                    "var": false
                  },
                  {
                    "categoryId": 10001379,
                    "children": [],
                    "leaf": true,
                    "name": "Levers",
                    "var": false
                  },
                  {
                    "categoryId": 10001380,
                    "children": [],
                    "leaf": true,
                    "name": "Master Cylinders & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001381,
                    "children": [],
                    "leaf": true,
                    "name": "Pad Shims and Pedal Pads",
                    "var": false
                  },
                  {
                    "categoryId": 10001382,
                    "children": [],
                    "leaf": true,
                    "name": "Parking Brake Components",
                    "var": false
                  },
                  {
                    "categoryId": 10001383,
                    "children": [],
                    "leaf": true,
                    "name": "Pin Links",
                    "var": false
                  },
                  {
                    "categoryId": 10001384,
                    "children": [],
                    "leaf": true,
                    "name": "Power Brake Systems",
                    "var": false
                  },
                  {
                    "categoryId": 10001385,
                    "children": [],
                    "leaf": true,
                    "name": "Rotors",
                    "var": false
                  },
                  {
                    "categoryId": 10001387,
                    "children": [],
                    "leaf": true,
                    "name": "Self-Adjusting Repair Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001386,
                    "children": [],
                    "leaf": true,
                    "name": "Shoe Clips",
                    "var": false
                  },
                  {
                    "categoryId": 10001388,
                    "children": [],
                    "leaf": true,
                    "name": "Sleeve Stabilizers",
                    "var": false
                  },
                  {
                    "categoryId": 10001362,
                    "children": [],
                    "leaf": true,
                    "name": "Springs",
                    "var": false
                  },
                  {
                    "categoryId": 10001389,
                    "children": [],
                    "leaf": true,
                    "name": "Support Keys",
                    "var": false
                  },
                  {
                    "categoryId": 10001390,
                    "children": [],
                    "leaf": true,
                    "name": "Top Cups",
                    "var": false
                  },
                  {
                    "categoryId": 10001391,
                    "children": [],
                    "leaf": true,
                    "name": "Trailer Brakes",
                    "var": false
                  },
                  {
                    "categoryId": 10001392,
                    "children": [],
                    "leaf": true,
                    "name": "Valves",
                    "var": false
                  },
                  {
                    "categoryId": 10001393,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Cylinder",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Brake System",
                "var": false
              },
              {
                "categoryId": 10001106,
                "children": [],
                "leaf": true,
                "name": "Cables",
                "var": false
              },
              {
                "categoryId": 10001107,
                "children": [],
                "leaf": true,
                "name": "Caps",
                "var": false
              },
              {
                "categoryId": 10001102,
                "children": [
                  {
                    "categoryId": 10001317,
                    "children": [],
                    "leaf": true,
                    "name": "Axle",
                    "var": false
                  },
                  {
                    "categoryId": 10001318,
                    "children": [],
                    "leaf": true,
                    "name": "Axle flange gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001319,
                    "children": [],
                    "leaf": true,
                    "name": "Bell Housings",
                    "var": false
                  },
                  {
                    "categoryId": 10001321,
                    "children": [],
                    "leaf": true,
                    "name": "Center Support Cushions and assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001322,
                    "children": [],
                    "leaf": true,
                    "name": "Clutches & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001320,
                    "children": [],
                    "leaf": true,
                    "name": "Constant Velocity",
                    "var": false
                  },
                  {
                    "categoryId": 10001323,
                    "children": [],
                    "leaf": true,
                    "name": "Differential Covers",
                    "var": false
                  },
                  {
                    "categoryId": 10001324,
                    "children": [],
                    "leaf": true,
                    "name": "Drive Shaft Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001325,
                    "children": [],
                    "leaf": true,
                    "name": "Flexplates",
                    "var": false
                  },
                  {
                    "categoryId": 10001326,
                    "children": [],
                    "leaf": true,
                    "name": "Flywheel & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001327,
                    "children": [],
                    "leaf": true,
                    "name": "Lock Nut Kits & Spindles",
                    "var": false
                  },
                  {
                    "categoryId": 10001328,
                    "children": [],
                    "leaf": true,
                    "name": "Ring & Pinion Gears",
                    "var": false
                  },
                  {
                    "categoryId": 10001329,
                    "children": [],
                    "leaf": true,
                    "name": "Shifters & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001330,
                    "children": [],
                    "leaf": true,
                    "name": "Splined Drives",
                    "var": false
                  },
                  {
                    "categoryId": 10001331,
                    "children": [],
                    "leaf": true,
                    "name": "Torque Converters",
                    "var": false
                  },
                  {
                    "categoryId": 10001332,
                    "children": [],
                    "leaf": true,
                    "name": "Transfer Case Components",
                    "var": false
                  },
                  {
                    "categoryId": 10001333,
                    "children": [],
                    "leaf": true,
                    "name": "Transmission & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001334,
                    "children": [],
                    "leaf": true,
                    "name": "Transmission Mounts",
                    "var": false
                  },
                  {
                    "categoryId": 10001335,
                    "children": [],
                    "leaf": true,
                    "name": "Universal Joints & Parts",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Drive Train",
                "var": false
              },
              {
                "categoryId": 10001108,
                "children": [
                  {
                    "categoryId": 10001394,
                    "children": [],
                    "leaf": true,
                    "name": "Air Conditioning",
                    "var": false
                  },
                  {
                    "categoryId": 10001395,
                    "children": [],
                    "leaf": true,
                    "name": "Coolant Recovery Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001396,
                    "children": [],
                    "leaf": true,
                    "name": "Coolers & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001397,
                    "children": [],
                    "leaf": true,
                    "name": "Fans & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001399,
                    "children": [],
                    "leaf": true,
                    "name": "HVAC Motors",
                    "var": false
                  },
                  {
                    "categoryId": 10001398,
                    "children": [],
                    "leaf": true,
                    "name": "Heating",
                    "var": false
                  },
                  {
                    "categoryId": 10001400,
                    "children": [],
                    "leaf": true,
                    "name": "Radiator",
                    "var": false
                  },
                  {
                    "categoryId": 10001401,
                    "children": [],
                    "leaf": true,
                    "name": "Thermostat",
                    "var": false
                  },
                  {
                    "categoryId": 10001402,
                    "children": [],
                    "leaf": true,
                    "name": "Water Pumps",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Engine Cooling & Climate Control",
                "var": false
              },
              {
                "categoryId": 10001109,
                "children": [
                  {
                    "categoryId": 10001414,
                    "children": [],
                    "leaf": true,
                    "name": "Air Cleaner Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001403,
                    "children": [],
                    "leaf": true,
                    "name": "Air Filters",
                    "var": false
                  },
                  {
                    "categoryId": 10001421,
                    "children": [],
                    "leaf": true,
                    "name": "Balance Shaft Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001423,
                    "children": [],
                    "leaf": true,
                    "name": "Buttons, Followers & Plugs",
                    "var": false
                  },
                  {
                    "categoryId": 10001422,
                    "children": [],
                    "leaf": true,
                    "name": "Cam",
                    "var": false
                  },
                  {
                    "categoryId": 10001413,
                    "children": [],
                    "leaf": true,
                    "name": "Carburetor Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001404,
                    "children": [],
                    "leaf": true,
                    "name": "Coolant Filter",
                    "var": false
                  },
                  {
                    "categoryId": 10001412,
                    "children": [],
                    "leaf": true,
                    "name": "Crankcase Cover Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001424,
                    "children": [],
                    "leaf": true,
                    "name": "Crankshafts",
                    "var": false
                  },
                  {
                    "categoryId": 10001411,
                    "children": [],
                    "leaf": true,
                    "name": "Cylinder Head Shim Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001425,
                    "children": [],
                    "leaf": true,
                    "name": "Cylinder Heads",
                    "var": false
                  },
                  {
                    "categoryId": 10001426,
                    "children": [],
                    "leaf": true,
                    "name": "Dipsticks & Tubes",
                    "var": false
                  },
                  {
                    "categoryId": 10001410,
                    "children": [],
                    "leaf": true,
                    "name": "Distributor Mounting Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001427,
                    "children": [],
                    "leaf": true,
                    "name": "Dowel Pins",
                    "var": false
                  },
                  {
                    "categoryId": 10001415,
                    "children": [],
                    "leaf": true,
                    "name": "Dress-Up Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001416,
                    "children": [],
                    "leaf": true,
                    "name": "Dress-Up Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001417,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Blocks",
                    "var": false
                  },
                  {
                    "categoryId": 10001418,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Heaters & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001419,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001420,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Management Systems",
                    "var": false
                  },
                  {
                    "categoryId": 10001428,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Mounts",
                    "var": false
                  },
                  {
                    "categoryId": 10001429,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Rev Limiters",
                    "var": false
                  },
                  {
                    "categoryId": 10001430,
                    "children": [],
                    "leaf": true,
                    "name": "Expansion Plugs",
                    "var": false
                  },
                  {
                    "categoryId": 10001405,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Filters",
                    "var": false
                  },
                  {
                    "categoryId": 10001409,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Pump Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001431,
                    "children": [],
                    "leaf": true,
                    "name": "Harmonic Balancers",
                    "var": false
                  },
                  {
                    "categoryId": 10001432,
                    "children": [],
                    "leaf": true,
                    "name": "Head Bolt Sets",
                    "var": false
                  },
                  {
                    "categoryId": 10001433,
                    "children": [],
                    "leaf": true,
                    "name": "Intake Manifolds & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001434,
                    "children": [],
                    "leaf": true,
                    "name": "Knurled Head Bolts",
                    "var": false
                  },
                  {
                    "categoryId": 10001435,
                    "children": [],
                    "leaf": true,
                    "name": "Lifters",
                    "var": false
                  },
                  {
                    "categoryId": 10001436,
                    "children": [],
                    "leaf": true,
                    "name": "Main Bearings",
                    "var": false
                  },
                  {
                    "categoryId": 10001437,
                    "children": [],
                    "leaf": true,
                    "name": "Main Bolts & Studs",
                    "var": false
                  },
                  {
                    "categoryId": 10001438,
                    "children": [],
                    "leaf": true,
                    "name": "Oil Drain Plugs",
                    "var": false
                  },
                  {
                    "categoryId": 10001406,
                    "children": [],
                    "leaf": true,
                    "name": "Oil Filters",
                    "var": false
                  },
                  {
                    "categoryId": 10001439,
                    "children": [],
                    "leaf": true,
                    "name": "Oil Pans",
                    "var": false
                  },
                  {
                    "categoryId": 10001440,
                    "children": [],
                    "leaf": true,
                    "name": "Oil Pumps & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001441,
                    "children": [],
                    "leaf": true,
                    "name": "Oil Restrictors",
                    "var": false
                  },
                  {
                    "categoryId": 10001442,
                    "children": [],
                    "leaf": true,
                    "name": "Pistons & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001443,
                    "children": [],
                    "leaf": true,
                    "name": "Push Rods",
                    "var": false
                  },
                  {
                    "categoryId": 10001444,
                    "children": [],
                    "leaf": true,
                    "name": "Rocker Arms & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001445,
                    "children": [],
                    "leaf": true,
                    "name": "Roller Rockers",
                    "var": false
                  },
                  {
                    "categoryId": 10001446,
                    "children": [],
                    "leaf": true,
                    "name": "Roto Caps & Spring Retainers",
                    "var": false
                  },
                  {
                    "categoryId": 10001447,
                    "children": [],
                    "leaf": true,
                    "name": "Superchargers",
                    "var": false
                  },
                  {
                    "categoryId": 10001448,
                    "children": [],
                    "leaf": true,
                    "name": "Thrust Washers",
                    "var": false
                  },
                  {
                    "categoryId": 10001449,
                    "children": [],
                    "leaf": true,
                    "name": "Timing Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001450,
                    "children": [],
                    "leaf": true,
                    "name": "Torque Struts",
                    "var": false
                  },
                  {
                    "categoryId": 10001408,
                    "children": [],
                    "leaf": true,
                    "name": "Transaxle & Transmission Gasket",
                    "var": false
                  },
                  {
                    "categoryId": 10001407,
                    "children": [],
                    "leaf": true,
                    "name": "Transmission Filters",
                    "var": false
                  },
                  {
                    "categoryId": 10001451,
                    "children": [],
                    "leaf": true,
                    "name": "Turbochargers",
                    "var": false
                  },
                  {
                    "categoryId": 10001452,
                    "children": [],
                    "leaf": true,
                    "name": "Valves",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Engine Parts",
                "var": false
              },
              {
                "categoryId": 10001110,
                "children": [
                  {
                    "categoryId": 10001456,
                    "children": [],
                    "leaf": true,
                    "name": "Cat-Back Systems",
                    "var": false
                  },
                  {
                    "categoryId": 10001453,
                    "children": [],
                    "leaf": true,
                    "name": "Catalytic Converters & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001457,
                    "children": [],
                    "leaf": true,
                    "name": "Control Units",
                    "var": false
                  },
                  {
                    "categoryId": 10001458,
                    "children": [],
                    "leaf": true,
                    "name": "Dash Pots",
                    "var": false
                  },
                  {
                    "categoryId": 10001459,
                    "children": [],
                    "leaf": true,
                    "name": "EGR",
                    "var": false
                  },
                  {
                    "categoryId": 10001460,
                    "children": [],
                    "leaf": true,
                    "name": "Exhaust Coatings",
                    "var": false
                  },
                  {
                    "categoryId": 10001461,
                    "children": [],
                    "leaf": true,
                    "name": "Exhaust Pipes & Tips",
                    "var": false
                  },
                  {
                    "categoryId": 10001462,
                    "children": [],
                    "leaf": true,
                    "name": "Extension Pipes",
                    "var": false
                  },
                  {
                    "categoryId": 10001463,
                    "children": [],
                    "leaf": true,
                    "name": "Hangers, Clamps & Flanges",
                    "var": false
                  },
                  {
                    "categoryId": 10001464,
                    "children": [],
                    "leaf": true,
                    "name": "Headers & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001465,
                    "children": [],
                    "leaf": true,
                    "name": "Heat Risers",
                    "var": false
                  },
                  {
                    "categoryId": 10001466,
                    "children": [],
                    "leaf": true,
                    "name": "Heat Shields",
                    "var": false
                  },
                  {
                    "categoryId": 10001467,
                    "children": [],
                    "leaf": true,
                    "name": "Heat Wrap, Matting & Sleeving",
                    "var": false
                  },
                  {
                    "categoryId": 10001468,
                    "children": [],
                    "leaf": true,
                    "name": "Manifold & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001469,
                    "children": [],
                    "leaf": true,
                    "name": "Mufflers",
                    "var": false
                  },
                  {
                    "categoryId": 10001455,
                    "children": [],
                    "leaf": true,
                    "name": "Pipes",
                    "var": false
                  },
                  {
                    "categoryId": 10001470,
                    "children": [],
                    "leaf": true,
                    "name": "Resonators",
                    "var": false
                  },
                  {
                    "categoryId": 10001471,
                    "children": [],
                    "leaf": true,
                    "name": "Secondary Diaphragms",
                    "var": false
                  },
                  {
                    "categoryId": 10001472,
                    "children": [],
                    "leaf": true,
                    "name": "Smog Pumps",
                    "var": false
                  },
                  {
                    "categoryId": 10001454,
                    "children": [],
                    "leaf": true,
                    "name": "Valves",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Exhaust & Emissions",
                "var": false
              },
              {
                "categoryId": 10001111,
                "children": [
                  {
                    "categoryId": 10001473,
                    "children": [],
                    "leaf": true,
                    "name": "Carburetors & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001474,
                    "children": [],
                    "leaf": true,
                    "name": "Electronic Speed Governors",
                    "var": false
                  },
                  {
                    "categoryId": 10001475,
                    "children": [],
                    "leaf": true,
                    "name": "Fittings",
                    "var": false
                  },
                  {
                    "categoryId": 10001476,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel & Water Separators",
                    "var": false
                  },
                  {
                    "categoryId": 10001477,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Cells",
                    "var": false
                  },
                  {
                    "categoryId": 10001478,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Injection",
                    "var": false
                  },
                  {
                    "categoryId": 10001479,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Pumps & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001480,
                    "children": [],
                    "leaf": true,
                    "name": "Glow Plug Indicators",
                    "var": false
                  },
                  {
                    "categoryId": 10001481,
                    "children": [],
                    "leaf": true,
                    "name": "Glow Plug Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001482,
                    "children": [],
                    "leaf": true,
                    "name": "Lines",
                    "var": false
                  },
                  {
                    "categoryId": 10001483,
                    "children": [],
                    "leaf": true,
                    "name": "Power Steering In-Line",
                    "var": false
                  },
                  {
                    "categoryId": 10001484,
                    "children": [],
                    "leaf": true,
                    "name": "Ram Air Kit",
                    "var": false
                  },
                  {
                    "categoryId": 10001485,
                    "children": [],
                    "leaf": true,
                    "name": "Throttle Bodies",
                    "var": false
                  },
                  {
                    "categoryId": 10001486,
                    "children": [],
                    "leaf": true,
                    "name": "Throttle Body Spacers & Adapters",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Fuel System",
                "var": false
              },
              {
                "categoryId": 10001112,
                "children": [
                  {
                    "categoryId": 10001487,
                    "children": [],
                    "leaf": true,
                    "name": "Armature",
                    "var": false
                  },
                  {
                    "categoryId": 10001488,
                    "children": [],
                    "leaf": true,
                    "name": "Ballast Resistors",
                    "var": false
                  },
                  {
                    "categoryId": 10001489,
                    "children": [],
                    "leaf": true,
                    "name": "Capacitors",
                    "var": false
                  },
                  {
                    "categoryId": 10001490,
                    "children": [],
                    "leaf": true,
                    "name": "Coils",
                    "var": false
                  },
                  {
                    "categoryId": 10001491,
                    "children": [],
                    "leaf": true,
                    "name": "Control Units",
                    "var": false
                  },
                  {
                    "categoryId": 10001492,
                    "children": [],
                    "leaf": true,
                    "name": "Dielectric Compounds",
                    "var": false
                  },
                  {
                    "categoryId": 10001493,
                    "children": [],
                    "leaf": true,
                    "name": "Distributors & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001494,
                    "children": [],
                    "leaf": true,
                    "name": "Glow Plugs",
                    "var": false
                  },
                  {
                    "categoryId": 10001496,
                    "children": [],
                    "leaf": true,
                    "name": "HEI",
                    "var": false
                  },
                  {
                    "categoryId": 10001495,
                    "children": [],
                    "leaf": true,
                    "name": "Hall Effect Pickups",
                    "var": false
                  },
                  {
                    "categoryId": 10001497,
                    "children": [],
                    "leaf": true,
                    "name": "Igniters",
                    "var": false
                  },
                  {
                    "categoryId": 10001498,
                    "children": [],
                    "leaf": true,
                    "name": "Lock Cylinders",
                    "var": false
                  },
                  {
                    "categoryId": 10001499,
                    "children": [],
                    "leaf": true,
                    "name": "Magnetic Pickups",
                    "var": false
                  },
                  {
                    "categoryId": 10001500,
                    "children": [],
                    "leaf": true,
                    "name": "Plates with Sensors",
                    "var": false
                  },
                  {
                    "categoryId": 10001501,
                    "children": [],
                    "leaf": true,
                    "name": "Plug Connectors",
                    "var": false
                  },
                  {
                    "categoryId": 10001502,
                    "children": [],
                    "leaf": true,
                    "name": "Points & Condensers",
                    "var": false
                  },
                  {
                    "categoryId": 10001503,
                    "children": [],
                    "leaf": true,
                    "name": "Pole Piece",
                    "var": false
                  },
                  {
                    "categoryId": 10001504,
                    "children": [],
                    "leaf": true,
                    "name": "Reluctors",
                    "var": false
                  },
                  {
                    "categoryId": 10001505,
                    "children": [],
                    "leaf": true,
                    "name": "Retard Devices",
                    "var": false
                  },
                  {
                    "categoryId": 10001506,
                    "children": [],
                    "leaf": true,
                    "name": "Spark Plugs & Wires",
                    "var": false
                  },
                  {
                    "categoryId": 10001507,
                    "children": [],
                    "leaf": true,
                    "name": "Stators",
                    "var": false
                  },
                  {
                    "categoryId": 10001508,
                    "children": [],
                    "leaf": true,
                    "name": "Systems & Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001509,
                    "children": [],
                    "leaf": true,
                    "name": "Tolerance Rings",
                    "var": false
                  },
                  {
                    "categoryId": 10001510,
                    "children": [],
                    "leaf": true,
                    "name": "Trigger Wheel Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001511,
                    "children": [],
                    "leaf": true,
                    "name": "Tune-Up Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001512,
                    "children": [],
                    "leaf": true,
                    "name": "Vacuum Advances",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Ignition & Electrical",
                "var": false
              },
              {
                "categoryId": 10001113,
                "children": [
                  {
                    "categoryId": 10001513,
                    "children": [],
                    "leaf": true,
                    "name": "Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001514,
                    "children": [],
                    "leaf": true,
                    "name": "Air Bag Modules",
                    "var": false
                  },
                  {
                    "categoryId": 10001515,
                    "children": [],
                    "leaf": true,
                    "name": "Cigarette Lighter & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001516,
                    "children": [],
                    "leaf": true,
                    "name": "Clocks",
                    "var": false
                  },
                  {
                    "categoryId": 10001517,
                    "children": [],
                    "leaf": true,
                    "name": "Flashers",
                    "var": false
                  },
                  {
                    "categoryId": 10001518,
                    "children": [],
                    "leaf": true,
                    "name": "Fuses & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001519,
                    "children": [],
                    "leaf": true,
                    "name": "Fusible Links",
                    "var": false
                  },
                  {
                    "categoryId": 10001520,
                    "children": [],
                    "leaf": true,
                    "name": "Gauges",
                    "var": false
                  },
                  {
                    "categoryId": 10001521,
                    "children": [],
                    "leaf": true,
                    "name": "Global Positioning Systems",
                    "var": false
                  },
                  {
                    "categoryId": 10001522,
                    "children": [],
                    "leaf": true,
                    "name": "Heat Sinks",
                    "var": false
                  },
                  {
                    "categoryId": 10001523,
                    "children": [],
                    "leaf": true,
                    "name": "Information displayModules",
                    "var": false
                  },
                  {
                    "categoryId": 10001524,
                    "children": [],
                    "leaf": true,
                    "name": "Navigation Systems",
                    "var": false
                  },
                  {
                    "categoryId": 10001525,
                    "children": [],
                    "leaf": true,
                    "name": "Pigtails & Sockets",
                    "var": false
                  },
                  {
                    "categoryId": 10001526,
                    "children": [],
                    "leaf": true,
                    "name": "Radio Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001527,
                    "children": [],
                    "leaf": true,
                    "name": "Stoplight Monitor Units",
                    "var": false
                  },
                  {
                    "categoryId": 10001528,
                    "children": [],
                    "leaf": true,
                    "name": "Turn Signal Directional Cams",
                    "var": false
                  },
                  {
                    "categoryId": 10001529,
                    "children": [],
                    "leaf": true,
                    "name": "Wiring Harnesses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Lighting & Electrical",
                "var": false
              },
              {
                "categoryId": 10001118,
                "children": [],
                "leaf": true,
                "name": "Relays",
                "var": false
              },
              {
                "categoryId": 10001114,
                "children": [
                  {
                    "categoryId": 10001530,
                    "children": [],
                    "leaf": true,
                    "name": "Air Suspension Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001531,
                    "children": [],
                    "leaf": true,
                    "name": "Anti-Sway Bars",
                    "var": false
                  },
                  {
                    "categoryId": 10001532,
                    "children": [],
                    "leaf": true,
                    "name": "Axle Damper & Kicker Shocks",
                    "var": false
                  },
                  {
                    "categoryId": 10001533,
                    "children": [],
                    "leaf": true,
                    "name": "Ball Joints",
                    "var": false
                  },
                  {
                    "categoryId": 10001534,
                    "children": [],
                    "leaf": true,
                    "name": "Body Bushings",
                    "var": false
                  },
                  {
                    "categoryId": 10001535,
                    "children": [],
                    "leaf": true,
                    "name": "Body Control Computers",
                    "var": false
                  },
                  {
                    "categoryId": 10001536,
                    "children": [],
                    "leaf": true,
                    "name": "Boots & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001538,
                    "children": [],
                    "leaf": true,
                    "name": "Camber Caster Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001539,
                    "children": [],
                    "leaf": true,
                    "name": "Cartridges",
                    "var": false
                  },
                  {
                    "categoryId": 10001540,
                    "children": [],
                    "leaf": true,
                    "name": "Center Links",
                    "var": false
                  },
                  {
                    "categoryId": 10001541,
                    "children": [],
                    "leaf": true,
                    "name": "Chassis",
                    "var": false
                  },
                  {
                    "categoryId": 10001542,
                    "children": [],
                    "leaf": true,
                    "name": "Coil Springs",
                    "var": false
                  },
                  {
                    "categoryId": 10001543,
                    "children": [],
                    "leaf": true,
                    "name": "Complete Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001544,
                    "children": [],
                    "leaf": true,
                    "name": "Control Arms & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001545,
                    "children": [],
                    "leaf": true,
                    "name": "Cushions & Mounts",
                    "var": false
                  },
                  {
                    "categoryId": 10001546,
                    "children": [],
                    "leaf": true,
                    "name": "Drag Links",
                    "var": false
                  },
                  {
                    "categoryId": 10001547,
                    "children": [],
                    "leaf": true,
                    "name": "Dual Shock Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001548,
                    "children": [],
                    "leaf": true,
                    "name": "Idler Arms & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001549,
                    "children": [],
                    "leaf": true,
                    "name": "King Pin Sets",
                    "var": false
                  },
                  {
                    "categoryId": 10001550,
                    "children": [],
                    "leaf": true,
                    "name": "Leaf Springs & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001551,
                    "children": [],
                    "leaf": true,
                    "name": "Lift Supports",
                    "var": false
                  },
                  {
                    "categoryId": 10001552,
                    "children": [],
                    "leaf": true,
                    "name": "Lowering Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001553,
                    "children": [],
                    "leaf": true,
                    "name": "Mounting Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001554,
                    "children": [],
                    "leaf": true,
                    "name": "Pitman Arms",
                    "var": false
                  },
                  {
                    "categoryId": 10001555,
                    "children": [],
                    "leaf": true,
                    "name": "Rear Traction Bars",
                    "var": false
                  },
                  {
                    "categoryId": 10001556,
                    "children": [],
                    "leaf": true,
                    "name": "Self-Leveling Units",
                    "var": false
                  },
                  {
                    "categoryId": 10001557,
                    "children": [],
                    "leaf": true,
                    "name": "Shock",
                    "var": false
                  },
                  {
                    "categoryId": 10001558,
                    "children": [],
                    "leaf": true,
                    "name": "Stabilizers",
                    "var": false
                  },
                  {
                    "categoryId": 10001559,
                    "children": [],
                    "leaf": true,
                    "name": "Strut",
                    "var": false
                  },
                  {
                    "categoryId": 10001560,
                    "children": [],
                    "leaf": true,
                    "name": "Suspension Control Modules",
                    "var": false
                  },
                  {
                    "categoryId": 10001561,
                    "children": [],
                    "leaf": true,
                    "name": "Sway Bars & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001562,
                    "children": [],
                    "leaf": true,
                    "name": "Tie Rod Ends & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001563,
                    "children": [],
                    "leaf": true,
                    "name": "Torsion Bars",
                    "var": false
                  },
                  {
                    "categoryId": 10001564,
                    "children": [],
                    "leaf": true,
                    "name": "Triple Shock Kits",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Shocks, Struts & Suspension",
                "var": false
              },
              {
                "categoryId": 10001116,
                "children": [
                  {
                    "categoryId": 10001579,
                    "children": [],
                    "leaf": true,
                    "name": "Brushes",
                    "var": false
                  },
                  {
                    "categoryId": 10001580,
                    "children": [],
                    "leaf": true,
                    "name": "Bushings & Bearings",
                    "var": false
                  },
                  {
                    "categoryId": 10001581,
                    "children": [],
                    "leaf": true,
                    "name": "Drives",
                    "var": false
                  },
                  {
                    "categoryId": 10001582,
                    "children": [],
                    "leaf": true,
                    "name": "Repair Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001583,
                    "children": [],
                    "leaf": true,
                    "name": "Solenoids",
                    "var": false
                  },
                  {
                    "categoryId": 10001584,
                    "children": [],
                    "leaf": true,
                    "name": "Starters",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Starters & Parts",
                "var": false
              },
              {
                "categoryId": 10001117,
                "children": [
                  {
                    "categoryId": 10001585,
                    "children": [],
                    "leaf": true,
                    "name": "Power Steering",
                    "var": false
                  },
                  {
                    "categoryId": 10001586,
                    "children": [],
                    "leaf": true,
                    "name": "Rack & Pinion",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Steering System",
                "var": false
              },
              {
                "categoryId": 10001119,
                "children": [],
                "leaf": true,
                "name": "Switches",
                "var": false
              },
              {
                "categoryId": 10001104,
                "children": [],
                "leaf": true,
                "name": "Trim",
                "var": false
              },
              {
                "categoryId": 10001120,
                "children": [],
                "leaf": true,
                "name": "Window Regulators & Motors",
                "var": false
              },
              {
                "categoryId": 10001121,
                "children": [],
                "leaf": true,
                "name": "Windshield Wipers & Washers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Auto Parts & Spares",
            "var": false
          },
          {
            "categoryId": 3282,
            "children": [
              {
                "categoryId": 10529,
                "children": [
                  {
                    "categoryId": 10560,
                    "children": [],
                    "leaf": true,
                    "name": "Hubcaps, Trim Rings & Hub Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10563,
                    "children": [],
                    "leaf": true,
                    "name": "Lug Nuts & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10531,
                    "children": [],
                    "leaf": true,
                    "name": "Lug Wrenches",
                    "var": false
                  },
                  {
                    "categoryId": 10547,
                    "children": [
                      {
                        "categoryId": 10549,
                        "children": [],
                        "leaf": true,
                        "name": "Air Compressors & Inflators",
                        "var": false
                      },
                      {
                        "categoryId": 10551,
                        "children": [],
                        "leaf": true,
                        "name": "Snow Chains",
                        "var": false
                      },
                      {
                        "categoryId": 10554,
                        "children": [],
                        "leaf": true,
                        "name": "Tire Chucks",
                        "var": false
                      },
                      {
                        "categoryId": 10556,
                        "children": [],
                        "leaf": true,
                        "name": "Tire Covers",
                        "var": false
                      },
                      {
                        "categoryId": 10558,
                        "children": [],
                        "leaf": true,
                        "name": "Valve Stems, Caps & Adapters",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Tire Accessories & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10533,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Bead Breakers",
                    "var": false
                  },
                  {
                    "categoryId": 10535,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Gauges",
                    "var": false
                  },
                  {
                    "categoryId": 10537,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Repair Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10539,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Repair Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10541,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Valve Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10542,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Alignment & Balancing Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10545,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Chocks",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Tire & Wheel Tools",
                "var": false
              },
              {
                "categoryId": 10001096,
                "children": [
                  {
                    "categoryId": 10001315,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Pressure Monitoring Systems",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Tire Accessories & Parts",
                "var": false
              },
              {
                "categoryId": 10528,
                "children": [
                  {
                    "categoryId": 10534,
                    "children": [],
                    "leaf": true,
                    "name": "ATV & UTV",
                    "var": false
                  },
                  {
                    "categoryId": 10536,
                    "children": [],
                    "leaf": true,
                    "name": "Agricultural Tractor & Farm Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 10538,
                    "children": [],
                    "leaf": true,
                    "name": "Commercial Truck",
                    "var": false
                  },
                  {
                    "categoryId": 10540,
                    "children": [],
                    "leaf": true,
                    "name": "Go Kart & Kart Racer",
                    "var": false
                  },
                  {
                    "categoryId": 10532,
                    "children": [],
                    "leaf": true,
                    "name": "Light Truck & SUV",
                    "var": false
                  },
                  {
                    "categoryId": 10543,
                    "children": [],
                    "leaf": true,
                    "name": "Motor Home & RV",
                    "var": false
                  },
                  {
                    "categoryId": 10530,
                    "children": [],
                    "leaf": true,
                    "name": "Passenger Car",
                    "var": false
                  },
                  {
                    "categoryId": 10544,
                    "children": [],
                    "leaf": true,
                    "name": "Racing",
                    "var": false
                  },
                  {
                    "categoryId": 10546,
                    "children": [],
                    "leaf": true,
                    "name": "Trailer",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Tires",
                "var": false
              },
              {
                "categoryId": 10548,
                "children": [
                  {
                    "categoryId": 10550,
                    "children": [],
                    "leaf": true,
                    "name": "Center Caps",
                    "var": false
                  },
                  {
                    "categoryId": 10552,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel & Brake Dust Shields",
                    "var": false
                  },
                  {
                    "categoryId": 10553,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Adapters & Spacers",
                    "var": false
                  },
                  {
                    "categoryId": 10555,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Bearing Seals",
                    "var": false
                  },
                  {
                    "categoryId": 10566,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Covers",
                    "var": false
                  },
                  {
                    "categoryId": 10559,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Hubs & Bearings",
                    "var": false
                  },
                  {
                    "categoryId": 10561,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Locks",
                    "var": false
                  },
                  {
                    "categoryId": 10562,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Studs",
                    "var": false
                  },
                  {
                    "categoryId": 10565,
                    "children": [],
                    "leaf": true,
                    "name": "Wheel Weights",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Wheel Accessories & Parts",
                "var": false
              },
              {
                "categoryId": 10519,
                "children": [
                  {
                    "categoryId": 10520,
                    "children": [],
                    "leaf": true,
                    "name": "Car",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Wheels",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Auto Tires & Wheels",
            "var": false
          },
          {
            "categoryId": 3275,
            "children": [
              {
                "categoryId": 10001064,
                "children": [],
                "leaf": true,
                "name": "Air Dryers, Blowers & Blades",
                "var": false
              },
              {
                "categoryId": 10001070,
                "children": [
                  {
                    "categoryId": 10001285,
                    "children": [],
                    "leaf": true,
                    "name": "Body Hammers & Dollies",
                    "var": false
                  },
                  {
                    "categoryId": 10001286,
                    "children": [],
                    "leaf": true,
                    "name": "Buffing & Polishing Pads",
                    "var": false
                  },
                  {
                    "categoryId": 10001287,
                    "children": [],
                    "leaf": true,
                    "name": "Chains, Clamps & Hooks",
                    "var": false
                  },
                  {
                    "categoryId": 10001288,
                    "children": [],
                    "leaf": true,
                    "name": "Collision Repair Sets",
                    "var": false
                  },
                  {
                    "categoryId": 10001289,
                    "children": [],
                    "leaf": true,
                    "name": "Dent Removal Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001290,
                    "children": [],
                    "leaf": true,
                    "name": "Grinders & Polishers",
                    "var": false
                  },
                  {
                    "categoryId": 10001291,
                    "children": [],
                    "leaf": true,
                    "name": "Paint Tools & Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 10001292,
                    "children": [],
                    "leaf": true,
                    "name": "Panel Flange Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001293,
                    "children": [],
                    "leaf": true,
                    "name": "Putty",
                    "var": false
                  },
                  {
                    "categoryId": 10001294,
                    "children": [],
                    "leaf": true,
                    "name": "Sanders",
                    "var": false
                  },
                  {
                    "categoryId": 10001295,
                    "children": [],
                    "leaf": true,
                    "name": "Slide Hammers",
                    "var": false
                  },
                  {
                    "categoryId": 10001296,
                    "children": [],
                    "leaf": true,
                    "name": "Upholstery & Trim Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001297,
                    "children": [],
                    "leaf": true,
                    "name": "Windshield & Glass Repair Tools",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Body Repair Tools",
                "var": false
              },
              {
                "categoryId": 10001071,
                "children": [],
                "leaf": true,
                "name": "Brake & Repair Tools",
                "var": false
              },
              {
                "categoryId": 10001065,
                "children": [],
                "leaf": true,
                "name": "Brushes & Dusters",
                "var": false
              },
              {
                "categoryId": 10001072,
                "children": [],
                "leaf": true,
                "name": "Bushing Tools",
                "var": false
              },
              {
                "categoryId": 10001066,
                "children": [],
                "leaf": true,
                "name": "Cloths & Towels",
                "var": false
              },
              {
                "categoryId": 10001073,
                "children": [],
                "leaf": true,
                "name": "Clutch Alignments Tools",
                "var": false
              },
              {
                "categoryId": 10001074,
                "children": [],
                "leaf": true,
                "name": "Cooling & Water Pump Tools",
                "var": false
              },
              {
                "categoryId": 3276,
                "children": [
                  {
                    "categoryId": 10001263,
                    "children": [],
                    "leaf": true,
                    "name": "Air Bag Scan Tools & Simulators",
                    "var": false
                  },
                  {
                    "categoryId": 10001264,
                    "children": [],
                    "leaf": true,
                    "name": "Battery Testers",
                    "var": false
                  },
                  {
                    "categoryId": 10001265,
                    "children": [],
                    "leaf": true,
                    "name": "Code Readers & Scan Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001266,
                    "children": [],
                    "leaf": true,
                    "name": "Electrical Testers & Test Leads",
                    "var": false
                  },
                  {
                    "categoryId": 10001267,
                    "children": [],
                    "leaf": true,
                    "name": "Emissions Analyzers",
                    "var": false
                  },
                  {
                    "categoryId": 10001268,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Pressure Testers",
                    "var": false
                  },
                  {
                    "categoryId": 10001269,
                    "children": [],
                    "leaf": true,
                    "name": "Gas Analyzers",
                    "var": false
                  },
                  {
                    "categoryId": 10001270,
                    "children": [],
                    "leaf": true,
                    "name": "Ignition Testers",
                    "var": false
                  },
                  {
                    "categoryId": 10001271,
                    "children": [],
                    "leaf": true,
                    "name": "Lab Scopes",
                    "var": false
                  },
                  {
                    "categoryId": 10001272,
                    "children": [],
                    "leaf": true,
                    "name": "Mechanical Testers",
                    "var": false
                  },
                  {
                    "categoryId": 10001273,
                    "children": [],
                    "leaf": true,
                    "name": "Multimeters & Analyzers",
                    "var": false
                  },
                  {
                    "categoryId": 10001274,
                    "children": [],
                    "leaf": true,
                    "name": "Pressure & Vacuum Testers",
                    "var": false
                  },
                  {
                    "categoryId": 10001275,
                    "children": [],
                    "leaf": true,
                    "name": "Software",
                    "var": false
                  },
                  {
                    "categoryId": 10001276,
                    "children": [],
                    "leaf": true,
                    "name": "Thermometers",
                    "var": false
                  },
                  {
                    "categoryId": 10001277,
                    "children": [],
                    "leaf": true,
                    "name": "Timing Lights",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Diagnostic & Test Tools",
                "var": false
              },
              {
                "categoryId": 10001075,
                "children": [],
                "leaf": true,
                "name": "Drive Train Tools",
                "var": false
              },
              {
                "categoryId": 10001076,
                "children": [],
                "leaf": true,
                "name": "Electrical System Tools",
                "var": false
              },
              {
                "categoryId": 3277,
                "children": [
                  {
                    "categoryId": 10001278,
                    "children": [],
                    "leaf": true,
                    "name": "Compression Gauges",
                    "var": false
                  },
                  {
                    "categoryId": 10001279,
                    "children": [],
                    "leaf": true,
                    "name": "Engine Tilters",
                    "var": false
                  },
                  {
                    "categoryId": 10001280,
                    "children": [],
                    "leaf": true,
                    "name": "Exhaust Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001281,
                    "children": [],
                    "leaf": true,
                    "name": "Flywheel & Pulley Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001282,
                    "children": [],
                    "leaf": true,
                    "name": "Oil Pressure Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001283,
                    "children": [],
                    "leaf": true,
                    "name": "Timing Belt Tools",
                    "var": false
                  },
                  {
                    "categoryId": 10001284,
                    "children": [],
                    "leaf": true,
                    "name": "Valve Train Tools",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Engine Tools",
                "var": false
              },
              {
                "categoryId": 10001077,
                "children": [],
                "leaf": true,
                "name": "Flywheel Locks",
                "var": false
              },
              {
                "categoryId": 10001078,
                "children": [],
                "leaf": true,
                "name": "Fuel System Tools",
                "var": false
              },
              {
                "categoryId": 3278,
                "children": [],
                "leaf": true,
                "name": "Garage & Shop",
                "var": false
              },
              {
                "categoryId": 10001079,
                "children": [
                  {
                    "categoryId": 10001298,
                    "children": [],
                    "leaf": true,
                    "name": "Barrel & Hand Pumps",
                    "var": false
                  },
                  {
                    "categoryId": 10001299,
                    "children": [],
                    "leaf": true,
                    "name": "Dollies",
                    "var": false
                  },
                  {
                    "categoryId": 10001300,
                    "children": [],
                    "leaf": true,
                    "name": "Electric Vehicle Charging Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 10001301,
                    "children": [],
                    "leaf": true,
                    "name": "Emergency & Safety Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 10001302,
                    "children": [],
                    "leaf": true,
                    "name": "Floor & Parking Mats",
                    "var": false
                  },
                  {
                    "categoryId": 10001303,
                    "children": [],
                    "leaf": true,
                    "name": "Fluid Evacuators",
                    "var": false
                  },
                  {
                    "categoryId": 10001304,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Transfer & Lubrication",
                    "var": false
                  },
                  {
                    "categoryId": 10001305,
                    "children": [],
                    "leaf": true,
                    "name": "Hand Cleaners",
                    "var": false
                  },
                  {
                    "categoryId": 10001306,
                    "children": [],
                    "leaf": true,
                    "name": "Lube Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 10001307,
                    "children": [],
                    "leaf": true,
                    "name": "Paint Guns & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001308,
                    "children": [],
                    "leaf": true,
                    "name": "Parking Gadgets",
                    "var": false
                  },
                  {
                    "categoryId": 10001309,
                    "children": [],
                    "leaf": true,
                    "name": "Power Converters",
                    "var": false
                  },
                  {
                    "categoryId": 10001310,
                    "children": [],
                    "leaf": true,
                    "name": "Wet-Dry Vacuums",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Garage DIY",
                "var": false
              },
              {
                "categoryId": 3279,
                "children": [],
                "leaf": true,
                "name": "Hand Tools",
                "var": false
              },
              {
                "categoryId": 10001080,
                "children": [],
                "leaf": true,
                "name": "Hose Repair Kits",
                "var": false
              },
              {
                "categoryId": 10001081,
                "children": [],
                "leaf": true,
                "name": "Impact Wrenches",
                "var": false
              },
              {
                "categoryId": 3280,
                "children": [],
                "leaf": true,
                "name": "Jump Starters, Battery Chargers & Portable Power",
                "var": false
              },
              {
                "categoryId": 10001082,
                "children": [],
                "leaf": true,
                "name": "Lockout Kits",
                "var": false
              },
              {
                "categoryId": 10001068,
                "children": [],
                "leaf": true,
                "name": "Machine Polishing Equipment",
                "var": false
              },
              {
                "categoryId": 10001083,
                "children": [],
                "leaf": true,
                "name": "Measuring Tools",
                "var": false
              },
              {
                "categoryId": 10001084,
                "children": [],
                "leaf": true,
                "name": "Muffler Tools",
                "var": false
              },
              {
                "categoryId": 10001085,
                "children": [],
                "leaf": true,
                "name": "Oil System Tools",
                "var": false
              },
              {
                "categoryId": 10001086,
                "children": [],
                "leaf": true,
                "name": "Oxygen Sensor Removers",
                "var": false
              },
              {
                "categoryId": 10001087,
                "children": [],
                "leaf": true,
                "name": "Parts Washers",
                "var": false
              },
              {
                "categoryId": 10001088,
                "children": [],
                "leaf": true,
                "name": "Pullers",
                "var": false
              },
              {
                "categoryId": 10001089,
                "children": [],
                "leaf": true,
                "name": "Riveters",
                "var": false
              },
              {
                "categoryId": 10001090,
                "children": [],
                "leaf": true,
                "name": "Spark Plug & Ignition Tools",
                "var": false
              },
              {
                "categoryId": 10001091,
                "children": [],
                "leaf": true,
                "name": "Steering & Suspension Tools",
                "var": false
              },
              {
                "categoryId": 10001092,
                "children": [],
                "leaf": true,
                "name": "Strut Compressors",
                "var": false
              },
              {
                "categoryId": 10001093,
                "children": [],
                "leaf": true,
                "name": "Thread Repair Kits",
                "var": false
              },
              {
                "categoryId": 10001069,
                "children": [],
                "leaf": true,
                "name": "Vinyl Cleaners",
                "var": false
              },
              {
                "categoryId": 10001094,
                "children": [],
                "leaf": true,
                "name": "Welding Tools",
                "var": false
              },
              {
                "categoryId": 3281,
                "children": [],
                "leaf": true,
                "name": "Wheel & Tire Tools",
                "var": false
              },
              {
                "categoryId": 10001095,
                "children": [],
                "leaf": true,
                "name": "Windshield Wiper Tools",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Auto Tools & Equipment",
            "var": false
          },
          {
            "categoryId": 10000963,
            "children": [
              {
                "categoryId": 10001098,
                "children": [],
                "leaf": true,
                "name": "Batteries",
                "var": false
              },
              {
                "categoryId": 10001099,
                "children": [],
                "leaf": true,
                "name": "Battery Accessories",
                "var": false
              },
              {
                "categoryId": 10001097,
                "children": [],
                "leaf": true,
                "name": "Starters, Battery Chargers & Portable Power",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Batteries & Accessories",
            "var": false
          },
          {
            "categoryId": 10000968,
            "children": [
              {
                "categoryId": 10001139,
                "children": [],
                "leaf": true,
                "name": "Amplifiers",
                "var": false
              },
              {
                "categoryId": 10001142,
                "children": [],
                "leaf": true,
                "name": "Car Stereo Receivers",
                "var": false
              },
              {
                "categoryId": 10001145,
                "children": [],
                "leaf": true,
                "name": "Changers",
                "var": false
              },
              {
                "categoryId": 10001146,
                "children": [],
                "leaf": true,
                "name": "Digital Media Receivers",
                "var": false
              },
              {
                "categoryId": 10001144,
                "children": [],
                "leaf": true,
                "name": "Equalizers",
                "var": false
              },
              {
                "categoryId": 10001143,
                "children": [],
                "leaf": true,
                "name": "Satellite Radio",
                "var": false
              },
              {
                "categoryId": 10001140,
                "children": [],
                "leaf": true,
                "name": "Speakers",
                "var": false
              },
              {
                "categoryId": 10001141,
                "children": [],
                "leaf": true,
                "name": "Subwoofers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Car Audio",
            "var": false
          },
          {
            "categoryId": 3216,
            "children": [
              {
                "categoryId": 3218,
                "children": [],
                "leaf": true,
                "name": "Exterior Care",
                "var": false
              },
              {
                "categoryId": 3220,
                "children": [],
                "leaf": true,
                "name": "Interior Care",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Car Care",
            "var": false
          },
          {
            "categoryId": 10000971,
            "children": [
              {
                "categoryId": 10001167,
                "children": [],
                "leaf": true,
                "name": "Audio & Video Accessories",
                "var": false
              },
              {
                "categoryId": 10001165,
                "children": [],
                "leaf": true,
                "name": "CB Radios & Scanners",
                "var": false
              },
              {
                "categoryId": 10001168,
                "children": [],
                "leaf": true,
                "name": "Car Dash Mounting Kits",
                "var": false
              },
              {
                "categoryId": 10001169,
                "children": [],
                "leaf": true,
                "name": "Cell Phone Accessories",
                "var": false
              },
              {
                "categoryId": 10001170,
                "children": [],
                "leaf": true,
                "name": "GPS System Accessories",
                "var": false
              },
              {
                "categoryId": 10001172,
                "children": [],
                "leaf": true,
                "name": "Other Electronic Gadgets",
                "var": false
              },
              {
                "categoryId": 10001164,
                "children": [],
                "leaf": true,
                "name": "Radar detectors",
                "var": false
              },
              {
                "categoryId": 10001163,
                "children": [],
                "leaf": true,
                "name": "Tracking and Monitoring Modules",
                "var": false
              },
              {
                "categoryId": 10001166,
                "children": [],
                "leaf": true,
                "name": "Two- Way Radios",
                "var": false
              },
              {
                "categoryId": 10001171,
                "children": [],
                "leaf": true,
                "name": "Vehicle Audio & Video Installation",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Car Electronics Accessories",
            "var": false
          },
          {
            "categoryId": 10000970,
            "children": [
              {
                "categoryId": 10001158,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10001159,
                "children": [],
                "leaf": true,
                "name": "Alarm Systems & Accessories",
                "var": false
              },
              {
                "categoryId": 10001160,
                "children": [],
                "leaf": true,
                "name": "Keyless Entry",
                "var": false
              },
              {
                "categoryId": 10001161,
                "children": [],
                "leaf": true,
                "name": "Remote Starters",
                "var": false
              },
              {
                "categoryId": 10001162,
                "children": [],
                "leaf": true,
                "name": "Steering Wheel Locking Devices",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Car Safety & Security",
            "var": false
          },
          {
            "categoryId": 10000969,
            "children": [
              {
                "categoryId": 10001147,
                "children": [],
                "leaf": true,
                "name": "Car Headphones",
                "var": false
              },
              {
                "categoryId": 10001149,
                "children": [],
                "leaf": true,
                "name": "Headrest Video",
                "var": false
              },
              {
                "categoryId": 10001148,
                "children": [],
                "leaf": true,
                "name": "In-Dash DVD & Video Receivers",
                "var": false
              },
              {
                "categoryId": 10001150,
                "children": [],
                "leaf": true,
                "name": "In-Dash Navigation",
                "var": false
              },
              {
                "categoryId": 10001154,
                "children": [],
                "leaf": true,
                "name": "Overhead Video",
                "var": false
              },
              {
                "categoryId": 10001155,
                "children": [],
                "leaf": true,
                "name": "Surround Processors",
                "var": false
              },
              {
                "categoryId": 10001156,
                "children": [],
                "leaf": true,
                "name": "TV Tuners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Car Video",
            "var": false
          },
          {
            "categoryId": 10000955,
            "children": [],
            "leaf": true,
            "name": "Convertibles",
            "var": true
          },
          {
            "categoryId": 10000956,
            "children": [],
            "leaf": true,
            "name": "Coupes",
            "var": true
          },
          {
            "categoryId": 3221,
            "children": [
              {
                "categoryId": 10001013,
                "children": [],
                "leaf": true,
                "name": "Antenna Toppers",
                "var": false
              },
              {
                "categoryId": 10001014,
                "children": [],
                "leaf": true,
                "name": "Body Armor",
                "var": false
              },
              {
                "categoryId": 10001015,
                "children": [],
                "leaf": true,
                "name": "Bumper Stickers, Decals & Magnets",
                "var": false
              },
              {
                "categoryId": 10001016,
                "children": [],
                "leaf": true,
                "name": "Bumpers & Bumper Accessories",
                "var": false
              },
              {
                "categoryId": 10001017,
                "children": [],
                "leaf": true,
                "name": "Cargo Management",
                "var": false
              },
              {
                "categoryId": 10001018,
                "children": [],
                "leaf": true,
                "name": "Chrome Trim & Accessories",
                "var": false
              },
              {
                "categoryId": 3222,
                "children": [],
                "leaf": true,
                "name": "Covers",
                "var": false
              },
              {
                "categoryId": 3223,
                "children": [],
                "leaf": true,
                "name": "Deflectors & Shields",
                "var": false
              },
              {
                "categoryId": 10001019,
                "children": [],
                "leaf": true,
                "name": "Emblems",
                "var": false
              },
              {
                "categoryId": 10001020,
                "children": [],
                "leaf": true,
                "name": "Fender Flares & Trim",
                "var": false
              },
              {
                "categoryId": 10001021,
                "children": [],
                "leaf": true,
                "name": "Gas Caps",
                "var": false
              },
              {
                "categoryId": 10001022,
                "children": [],
                "leaf": true,
                "name": "Gas Tank Doors",
                "var": false
              },
              {
                "categoryId": 3224,
                "children": [],
                "leaf": true,
                "name": "Grilles & Grille Guards",
                "var": false
              },
              {
                "categoryId": 10001023,
                "children": [],
                "leaf": true,
                "name": "Hood Scoops & Vents",
                "var": false
              },
              {
                "categoryId": 10001024,
                "children": [],
                "leaf": true,
                "name": "Horns & Accessories",
                "var": false
              },
              {
                "categoryId": 10001025,
                "children": [],
                "leaf": true,
                "name": "License Plate Covers & Frames",
                "var": false
              },
              {
                "categoryId": 10001026,
                "children": [],
                "leaf": true,
                "name": "Mirrors",
                "var": false
              },
              {
                "categoryId": 10001027,
                "children": [],
                "leaf": true,
                "name": "Mud Flaps & Splash Guards",
                "var": false
              },
              {
                "categoryId": 10001028,
                "children": [],
                "leaf": true,
                "name": "Roll Bars, Roll Cages & Covers",
                "var": false
              },
              {
                "categoryId": 3226,
                "children": [],
                "leaf": true,
                "name": "Running Boards & Steps",
                "var": false
              },
              {
                "categoryId": 10001029,
                "children": [],
                "leaf": true,
                "name": "Spare Tire Carriers",
                "var": false
              },
              {
                "categoryId": 10001030,
                "children": [],
                "leaf": true,
                "name": "Spoilers, Wings & Styling Kits",
                "var": false
              },
              {
                "categoryId": 3227,
                "children": [],
                "leaf": true,
                "name": "Towing Products & Winches",
                "var": false
              },
              {
                "categoryId": 10001031,
                "children": [],
                "leaf": true,
                "name": "Trailer Accessories",
                "var": false
              },
              {
                "categoryId": 10001032,
                "children": [],
                "leaf": true,
                "name": "Truck Bed & Tailgate Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Exterior Accessories",
            "var": false
          },
          {
            "categoryId": 10000965,
            "children": [
              {
                "categoryId": 10001123,
                "children": [],
                "leaf": true,
                "name": "Car Polishes & Waxes",
                "var": false
              },
              {
                "categoryId": 10001124,
                "children": [],
                "leaf": true,
                "name": "Cleaners and Kits",
                "var": false
              },
              {
                "categoryId": 10001130,
                "children": [],
                "leaf": true,
                "name": "Finishing",
                "var": false
              },
              {
                "categoryId": 10001131,
                "children": [],
                "leaf": true,
                "name": "Glass Care",
                "var": false
              },
              {
                "categoryId": 10001122,
                "children": [],
                "leaf": true,
                "name": "Paints & Primers",
                "var": false
              },
              {
                "categoryId": 10001125,
                "children": [],
                "leaf": true,
                "name": "Polishing & Rubbing Compounds",
                "var": false
              },
              {
                "categoryId": 10001126,
                "children": [],
                "leaf": true,
                "name": "Polishing & Waxing Kits",
                "var": false
              },
              {
                "categoryId": 10001127,
                "children": [],
                "leaf": true,
                "name": "Sealants",
                "var": false
              },
              {
                "categoryId": 10001132,
                "children": [],
                "leaf": true,
                "name": "Solvents",
                "var": false
              },
              {
                "categoryId": 10001133,
                "children": [],
                "leaf": true,
                "name": "Tire & Wheel Care",
                "var": false
              },
              {
                "categoryId": 10001129,
                "children": [],
                "leaf": true,
                "name": "Undercoatings",
                "var": false
              },
              {
                "categoryId": 10001128,
                "children": [],
                "leaf": true,
                "name": "Water Blades",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Exterior Vehicle Care",
            "var": false
          },
          {
            "categoryId": 10000967,
            "children": [],
            "leaf": true,
            "name": "GPS",
            "var": false
          },
          {
            "categoryId": 10000962,
            "children": [],
            "leaf": true,
            "name": "Hybrids",
            "var": true
          },
          {
            "categoryId": 3228,
            "children": [
              {
                "categoryId": 3229,
                "children": [],
                "leaf": true,
                "name": "Air Fresheners",
                "var": false
              },
              {
                "categoryId": 10001033,
                "children": [],
                "leaf": true,
                "name": "Antitheft",
                "var": false
              },
              {
                "categoryId": 10001034,
                "children": [],
                "leaf": true,
                "name": "Compasses",
                "var": false
              },
              {
                "categoryId": 10001035,
                "children": [],
                "leaf": true,
                "name": "Consoles & Organizers",
                "var": false
              },
              {
                "categoryId": 10001036,
                "children": [],
                "leaf": true,
                "name": "Covers",
                "var": false
              },
              {
                "categoryId": 3232,
                "children": [],
                "leaf": true,
                "name": "Dash Mats",
                "var": false
              },
              {
                "categoryId": 10001037,
                "children": [],
                "leaf": true,
                "name": "Electrical Appliances",
                "var": false
              },
              {
                "categoryId": 10001038,
                "children": [],
                "leaf": true,
                "name": "Floor Mats & Cargo Liners",
                "var": false
              },
              {
                "categoryId": 3235,
                "children": [],
                "leaf": true,
                "name": "Gauges",
                "var": false
              },
              {
                "categoryId": 10001039,
                "children": [],
                "leaf": true,
                "name": "Grab Handles",
                "var": false
              },
              {
                "categoryId": 10001040,
                "children": [],
                "leaf": true,
                "name": "Key Chains",
                "var": false
              },
              {
                "categoryId": 10001041,
                "children": [],
                "leaf": true,
                "name": "Mirrors",
                "var": false
              },
              {
                "categoryId": 10001042,
                "children": [],
                "leaf": true,
                "name": "Pedals & Pedal Accessories",
                "var": false
              },
              {
                "categoryId": 10001043,
                "children": [],
                "leaf": true,
                "name": "Seat Covers & Accessories",
                "var": false
              },
              {
                "categoryId": 10001044,
                "children": [],
                "leaf": true,
                "name": "Shift Boots & Knobs",
                "var": false
              },
              {
                "categoryId": 3238,
                "children": [],
                "leaf": true,
                "name": "Steering Wheels & Accessories",
                "var": false
              },
              {
                "categoryId": 10001045,
                "children": [],
                "leaf": true,
                "name": "Sun Protection",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Interior Accessories",
            "var": false
          },
          {
            "categoryId": 10000966,
            "children": [
              {
                "categoryId": 10001134,
                "children": [],
                "leaf": true,
                "name": "Carpet Cleaners",
                "var": false
              },
              {
                "categoryId": 10001135,
                "children": [],
                "leaf": true,
                "name": "Leather Care",
                "var": false
              },
              {
                "categoryId": 10001136,
                "children": [],
                "leaf": true,
                "name": "Protectants",
                "var": false
              },
              {
                "categoryId": 10001137,
                "children": [],
                "leaf": true,
                "name": "Upholstery Care",
                "var": false
              },
              {
                "categoryId": 10001138,
                "children": [],
                "leaf": true,
                "name": "Vacuums",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Interior Vehicle Care",
            "var": false
          },
          {
            "categoryId": 3259,
            "children": [
              {
                "categoryId": 3260,
                "children": [],
                "leaf": true,
                "name": "Brake System",
                "var": false
              },
              {
                "categoryId": 3261,
                "children": [],
                "leaf": true,
                "name": "Exhaust System",
                "var": false
              },
              {
                "categoryId": 3262,
                "children": [],
                "leaf": true,
                "name": "Filters",
                "var": false
              },
              {
                "categoryId": 3263,
                "children": [],
                "leaf": true,
                "name": "Fuel System",
                "var": false
              },
              {
                "categoryId": 3264,
                "children": [],
                "leaf": true,
                "name": "Ignition Parts",
                "var": false
              },
              {
                "categoryId": 3265,
                "children": [],
                "leaf": true,
                "name": "Shocks & Struts",
                "var": false
              },
              {
                "categoryId": 3266,
                "children": [],
                "leaf": true,
                "name": "Suspension",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Performance Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 10000957,
            "children": [],
            "leaf": true,
            "name": "SUVs",
            "var": true
          },
          {
            "categoryId": 10000958,
            "children": [],
            "leaf": true,
            "name": "Sedans",
            "var": true
          },
          {
            "categoryId": 3239,
            "children": [],
            "leaf": false,
            "name": "Truck Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 10000959,
            "children": [],
            "leaf": true,
            "name": "Trucks",
            "var": true
          },
          {
            "categoryId": 10000960,
            "children": [],
            "leaf": true,
            "name": "Vans",
            "var": true
          },
          {
            "categoryId": 10000961,
            "children": [],
            "leaf": true,
            "name": "Wagons",
            "var": true
          }
        ],
        "leaf": false,
        "name": "Automotive",
        "var": false
      },
      {
        "categoryId": 20000006,
        "children": [
          {
            "categoryId": 20000007,
            "children": [],
            "leaf": true,
            "name": "Automatic",
            "var": false
          },
          {
            "categoryId": 20000009,
            "children": [],
            "leaf": true,
            "name": "Imported Cars",
            "var": false
          },
          {
            "categoryId": 20000008,
            "children": [],
            "leaf": true,
            "name": "Manual",
            "var": false
          },
          {
            "categoryId": 20000010,
            "children": [],
            "leaf": true,
            "name": "Used Cars",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Cars",
        "var": false
      },
      {
        "categoryId": 10569,
        "children": [
          {
            "categoryId": 10000982,
            "children": [],
            "leaf": true,
            "name": "ATVs & UTVs",
            "var": false
          },
          {
            "categoryId": 10000974,
            "children": [],
            "leaf": true,
            "name": "Cruiser Bikes",
            "var": false
          },
          {
            "categoryId": 10000979,
            "children": [],
            "leaf": true,
            "name": "Custom Bikes",
            "var": false
          },
          {
            "categoryId": 10000977,
            "children": [],
            "leaf": true,
            "name": "Electric Bikes",
            "var": false
          },
          {
            "categoryId": 10000981,
            "children": [],
            "leaf": true,
            "name": "Hyperunderbone",
            "var": false
          },
          {
            "categoryId": 10000984,
            "children": [
              {
                "categoryId": 10001174,
                "children": [],
                "leaf": true,
                "name": "Batteries & Parts",
                "var": false
              },
              {
                "categoryId": 10001175,
                "children": [],
                "leaf": true,
                "name": "Motorcycle Battery Accessories",
                "var": false
              },
              {
                "categoryId": 10001176,
                "children": [],
                "leaf": true,
                "name": "Starters, Battery Chargers & Portable Power",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto Batteries & Accessories",
            "var": false
          },
          {
            "categoryId": 10000990,
            "children": [
              {
                "categoryId": 10001235,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10001230,
                "children": [],
                "leaf": true,
                "name": "Alarms & Anti-Theft",
                "var": false
              },
              {
                "categoryId": 10001231,
                "children": [],
                "leaf": true,
                "name": "Bluetooth Headsets",
                "var": false
              },
              {
                "categoryId": 10001232,
                "children": [],
                "leaf": true,
                "name": "Electrical Device Mounts",
                "var": false
              },
              {
                "categoryId": 10001233,
                "children": [],
                "leaf": true,
                "name": "GPS Units",
                "var": false
              },
              {
                "categoryId": 10001234,
                "children": [],
                "leaf": true,
                "name": "Speaker & Communication Systems",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto Electronics",
            "var": false
          },
          {
            "categoryId": 10000988,
            "children": [
              {
                "categoryId": 10001206,
                "children": [],
                "leaf": true,
                "name": "Arm Rests",
                "var": false
              },
              {
                "categoryId": 10001202,
                "children": [],
                "leaf": true,
                "name": "Covers",
                "var": false
              },
              {
                "categoryId": 10001205,
                "children": [],
                "leaf": true,
                "name": "Racks",
                "var": false
              },
              {
                "categoryId": 10001204,
                "children": [],
                "leaf": true,
                "name": "Replacements & Mounting harware",
                "var": false
              },
              {
                "categoryId": 10001207,
                "children": [],
                "leaf": true,
                "name": "Seats & Pads",
                "var": false
              },
              {
                "categoryId": 10001208,
                "children": [],
                "leaf": true,
                "name": "Sissy Bars",
                "var": false
              },
              {
                "categoryId": 10001203,
                "children": [],
                "leaf": true,
                "name": "Windshields & Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto Exterior Accessories",
            "var": false
          },
          {
            "categoryId": 10000985,
            "children": [
              {
                "categoryId": 10001177,
                "children": [
                  {
                    "categoryId": 10001587,
                    "children": [],
                    "leaf": true,
                    "name": "Air Filters",
                    "var": false
                  },
                  {
                    "categoryId": 10001588,
                    "children": [],
                    "leaf": true,
                    "name": "Air Pipes & Hoses",
                    "var": false
                  },
                  {
                    "categoryId": 10001589,
                    "children": [],
                    "leaf": true,
                    "name": "Carburetors & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001590,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Caps",
                    "var": false
                  },
                  {
                    "categoryId": 10001591,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Filters",
                    "var": false
                  },
                  {
                    "categoryId": 10001592,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Injectors & Main Jets",
                    "var": false
                  },
                  {
                    "categoryId": 10001595,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Petcocks & Taps",
                    "var": false
                  },
                  {
                    "categoryId": 10001593,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Pipes & Hoses",
                    "var": false
                  },
                  {
                    "categoryId": 10001594,
                    "children": [],
                    "leaf": true,
                    "name": "Fuel Pumps",
                    "var": false
                  },
                  {
                    "categoryId": 10001596,
                    "children": [],
                    "leaf": true,
                    "name": "Gaskets & Seals",
                    "var": false
                  },
                  {
                    "categoryId": 10001597,
                    "children": [],
                    "leaf": true,
                    "name": "Idle & Cruise Cables",
                    "var": false
                  },
                  {
                    "categoryId": 10001598,
                    "children": [],
                    "leaf": true,
                    "name": "Intake Covers & Fairings",
                    "var": false
                  },
                  {
                    "categoryId": 10001599,
                    "children": [],
                    "leaf": true,
                    "name": "Intake Ducts & Scoops",
                    "var": false
                  },
                  {
                    "categoryId": 10001601,
                    "children": [],
                    "leaf": true,
                    "name": "Other Intake & Fuel Systems",
                    "var": false
                  },
                  {
                    "categoryId": 10001600,
                    "children": [],
                    "leaf": true,
                    "name": "Throttle & Choke Cables",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Air Intake & Fuel Delivery",
                "var": false
              },
              {
                "categoryId": 10001178,
                "children": [
                  {
                    "categoryId": 10001602,
                    "children": [],
                    "leaf": true,
                    "name": "Fenders",
                    "var": false
                  },
                  {
                    "categoryId": 10001603,
                    "children": [],
                    "leaf": true,
                    "name": "Frames",
                    "var": false
                  },
                  {
                    "categoryId": 10001604,
                    "children": [],
                    "leaf": true,
                    "name": "Gas Tanks",
                    "var": false
                  },
                  {
                    "categoryId": 10001605,
                    "children": [],
                    "leaf": true,
                    "name": "Grips",
                    "var": false
                  },
                  {
                    "categoryId": 10001606,
                    "children": [],
                    "leaf": true,
                    "name": "Handle Bars, Levers, Mirrors",
                    "var": false
                  },
                  {
                    "categoryId": 10001607,
                    "children": [],
                    "leaf": true,
                    "name": "Pedals & Pegs",
                    "var": false
                  },
                  {
                    "categoryId": 10001608,
                    "children": [],
                    "leaf": true,
                    "name": "Seats",
                    "var": false
                  },
                  {
                    "categoryId": 10001609,
                    "children": [],
                    "leaf": true,
                    "name": "Windshields",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Body & Frame",
                "var": false
              },
              {
                "categoryId": 10001179,
                "children": [
                  {
                    "categoryId": 10001619,
                    "children": [],
                    "leaf": true,
                    "name": "Bearings & Bushings",
                    "var": false
                  },
                  {
                    "categoryId": 10001610,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Cables",
                    "var": false
                  },
                  {
                    "categoryId": 10001620,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Cylinders",
                    "var": false
                  },
                  {
                    "categoryId": 10001621,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Drums",
                    "var": false
                  },
                  {
                    "categoryId": 10001622,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Lines & Hoses",
                    "var": false
                  },
                  {
                    "categoryId": 10001623,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Pads",
                    "var": false
                  },
                  {
                    "categoryId": 10001624,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Rotors",
                    "var": false
                  },
                  {
                    "categoryId": 10001611,
                    "children": [],
                    "leaf": true,
                    "name": "Brake Shoes",
                    "var": false
                  },
                  {
                    "categoryId": 10001612,
                    "children": [],
                    "leaf": true,
                    "name": "Calipers & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001613,
                    "children": [],
                    "leaf": true,
                    "name": "Complete Suspension Units",
                    "var": false
                  },
                  {
                    "categoryId": 10001614,
                    "children": [],
                    "leaf": true,
                    "name": "Fork Seals",
                    "var": false
                  },
                  {
                    "categoryId": 10001615,
                    "children": [],
                    "leaf": true,
                    "name": "Fork Tubes",
                    "var": false
                  },
                  {
                    "categoryId": 10001618,
                    "children": [],
                    "leaf": true,
                    "name": "Other Brakes & Suspension",
                    "var": false
                  },
                  {
                    "categoryId": 10001616,
                    "children": [],
                    "leaf": true,
                    "name": "Shocks",
                    "var": false
                  },
                  {
                    "categoryId": 10001617,
                    "children": [],
                    "leaf": true,
                    "name": "Triple Trees",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Brakes & Suspension",
                "var": false
              },
              {
                "categoryId": 10001180,
                "children": [
                  {
                    "categoryId": 10001625,
                    "children": [],
                    "leaf": true,
                    "name": "Accessories",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Decals & Emblems",
                "var": false
              },
              {
                "categoryId": 10001181,
                "children": [
                  {
                    "categoryId": 10001628,
                    "children": [],
                    "leaf": true,
                    "name": "Bearings",
                    "var": false
                  },
                  {
                    "categoryId": 10001647,
                    "children": [],
                    "leaf": true,
                    "name": "Bulbs, LEDs & HIDs",
                    "var": false
                  },
                  {
                    "categoryId": 10001629,
                    "children": [],
                    "leaf": true,
                    "name": "Chain & Belt Guards, Guides",
                    "var": false
                  },
                  {
                    "categoryId": 10001630,
                    "children": [],
                    "leaf": true,
                    "name": "Chains, Sprockets & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001631,
                    "children": [],
                    "leaf": true,
                    "name": "Clutch Cables",
                    "var": false
                  },
                  {
                    "categoryId": 10001632,
                    "children": [],
                    "leaf": true,
                    "name": "Clutch Covers",
                    "var": false
                  },
                  {
                    "categoryId": 10001633,
                    "children": [],
                    "leaf": true,
                    "name": "Clutch Master Cylinders",
                    "var": false
                  },
                  {
                    "categoryId": 10001635,
                    "children": [],
                    "leaf": true,
                    "name": "Clutch Plates",
                    "var": false
                  },
                  {
                    "categoryId": 10001634,
                    "children": [],
                    "leaf": true,
                    "name": "Clutch Slave Cylinders",
                    "var": false
                  },
                  {
                    "categoryId": 10001636,
                    "children": [],
                    "leaf": true,
                    "name": "Complete Clutches & Kits",
                    "var": false
                  },
                  {
                    "categoryId": 10001638,
                    "children": [],
                    "leaf": true,
                    "name": "Differentials & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001639,
                    "children": [],
                    "leaf": true,
                    "name": "Drive Belts & Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001640,
                    "children": [],
                    "leaf": true,
                    "name": "Drive Shafts",
                    "var": false
                  },
                  {
                    "categoryId": 10001641,
                    "children": [],
                    "leaf": true,
                    "name": "Flywheels & Flywheel Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001649,
                    "children": [],
                    "leaf": true,
                    "name": "Fog Light Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001642,
                    "children": [],
                    "leaf": true,
                    "name": "Gaskets & Seals",
                    "var": false
                  },
                  {
                    "categoryId": 10001643,
                    "children": [],
                    "leaf": true,
                    "name": "Gearboxes & Gearbox Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001650,
                    "children": [],
                    "leaf": true,
                    "name": "Headlight Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001651,
                    "children": [],
                    "leaf": true,
                    "name": "Indicator Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001652,
                    "children": [],
                    "leaf": true,
                    "name": "Lenses & Projectors",
                    "var": false
                  },
                  {
                    "categoryId": 10001653,
                    "children": [],
                    "leaf": true,
                    "name": "License Plate Lights",
                    "var": false
                  },
                  {
                    "categoryId": 10001637,
                    "children": [],
                    "leaf": true,
                    "name": "Other Clutch Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001655,
                    "children": [],
                    "leaf": true,
                    "name": "Other Lighting Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001646,
                    "children": [],
                    "leaf": true,
                    "name": "Other Transmission Parts",
                    "var": false
                  },
                  {
                    "categoryId": 10001644,
                    "children": [],
                    "leaf": true,
                    "name": "Primary Drive Belts",
                    "var": false
                  },
                  {
                    "categoryId": 10001645,
                    "children": [],
                    "leaf": true,
                    "name": "Pulleys & Tensioners",
                    "var": false
                  },
                  {
                    "categoryId": 10001654,
                    "children": [],
                    "leaf": true,
                    "name": "Rear & Brake Light Assemblies",
                    "var": false
                  },
                  {
                    "categoryId": 10001648,
                    "children": [],
                    "leaf": true,
                    "name": "displayLights",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Drivetrain & Transmission",
                "var": false
              },
              {
                "categoryId": 10002968,
                "children": [],
                "leaf": true,
                "name": "Exhaust & Accessories",
                "var": false
              },
              {
                "categoryId": 10001182,
                "children": [],
                "leaf": true,
                "name": "Luggage & Saddlebags",
                "var": false
              },
              {
                "categoryId": 10001183,
                "children": [],
                "leaf": true,
                "name": "Mirrors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto Parts & Spares",
            "var": false
          },
          {
            "categoryId": 10572,
            "children": [
              {
                "categoryId": 10575,
                "children": [],
                "leaf": true,
                "name": "Axles, Bearings & Seals",
                "var": false
              },
              {
                "categoryId": 10001173,
                "children": [],
                "leaf": true,
                "name": "Other Tire & wheel Parts",
                "var": false
              },
              {
                "categoryId": 10577,
                "children": [],
                "leaf": true,
                "name": "Tape & Trim",
                "var": false
              },
              {
                "categoryId": 10582,
                "children": [
                  {
                    "categoryId": 10585,
                    "children": [],
                    "leaf": true,
                    "name": "Pressure Gauges",
                    "var": false
                  },
                  {
                    "categoryId": 10587,
                    "children": [],
                    "leaf": true,
                    "name": "Tire Repair",
                    "var": false
                  },
                  {
                    "categoryId": 10583,
                    "children": [],
                    "leaf": true,
                    "name": "Tools & Supplies",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Tire Care",
                "var": false
              },
              {
                "categoryId": 10580,
                "children": [],
                "leaf": true,
                "name": "Tire Studs",
                "var": false
              },
              {
                "categoryId": 10578,
                "children": [],
                "leaf": true,
                "name": "Tires & Tubes",
                "var": false
              },
              {
                "categoryId": 10589,
                "children": [],
                "leaf": true,
                "name": "Valve Caps & Stems",
                "var": false
              },
              {
                "categoryId": 10594,
                "children": [],
                "leaf": true,
                "name": "Wheel Bearings, Hardware & Spacers",
                "var": false
              },
              {
                "categoryId": 10591,
                "children": [],
                "leaf": true,
                "name": "Wheel Hub Assemblies",
                "var": false
              },
              {
                "categoryId": 10592,
                "children": [],
                "leaf": true,
                "name": "Wheels & Rims",
                "var": false
              },
              {
                "categoryId": 10596,
                "children": [],
                "leaf": true,
                "name": "Wheels & Tire Packages",
                "var": false
              },
              {
                "categoryId": 10599,
                "children": [],
                "leaf": true,
                "name": "Wheels & Tires Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto Tires & Wheels",
            "var": false
          },
          {
            "categoryId": 10000987,
            "children": [
              {
                "categoryId": 10001189,
                "children": [],
                "leaf": true,
                "name": "Adhesives",
                "var": false
              },
              {
                "categoryId": 10001190,
                "children": [],
                "leaf": true,
                "name": "Air & Oil Filters",
                "var": false
              },
              {
                "categoryId": 10001198,
                "children": [],
                "leaf": true,
                "name": "Banding",
                "var": false
              },
              {
                "categoryId": 10001191,
                "children": [],
                "leaf": true,
                "name": "Batteries & Charges",
                "var": false
              },
              {
                "categoryId": 10001199,
                "children": [],
                "leaf": true,
                "name": "Bearing",
                "var": false
              },
              {
                "categoryId": 10001200,
                "children": [],
                "leaf": true,
                "name": "Brake",
                "var": false
              },
              {
                "categoryId": 10001201,
                "children": [],
                "leaf": true,
                "name": "Carburetor",
                "var": false
              },
              {
                "categoryId": 10001192,
                "children": [],
                "leaf": true,
                "name": "Chain Maintenance",
                "var": false
              },
              {
                "categoryId": 10001193,
                "children": [],
                "leaf": true,
                "name": "Cleaning Supplies",
                "var": false
              },
              {
                "categoryId": 10001194,
                "children": [],
                "leaf": true,
                "name": "Hardware",
                "var": false
              },
              {
                "categoryId": 10001195,
                "children": [],
                "leaf": true,
                "name": "Stands & Accessories",
                "var": false
              },
              {
                "categoryId": 10001196,
                "children": [],
                "leaf": true,
                "name": "Straps & Cargo Accessories",
                "var": false
              },
              {
                "categoryId": 10001197,
                "children": [],
                "leaf": true,
                "name": "Utility Jugs",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto Tools & Maintenance",
            "var": false
          },
          {
            "categoryId": 10000986,
            "children": [
              {
                "categoryId": 10001186,
                "children": [],
                "leaf": true,
                "name": "Brake Fluid",
                "var": false
              },
              {
                "categoryId": 10001184,
                "children": [],
                "leaf": true,
                "name": "Cycle Engine Oil",
                "var": false
              },
              {
                "categoryId": 10001187,
                "children": [],
                "leaf": true,
                "name": "Gear Oils",
                "var": false
              },
              {
                "categoryId": 10001188,
                "children": [],
                "leaf": true,
                "name": "Lubricants & Solvents",
                "var": false
              },
              {
                "categoryId": 10001185,
                "children": [],
                "leaf": true,
                "name": "Racing Oils",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Moto oils & Fluids",
            "var": false
          },
          {
            "categoryId": 10000975,
            "children": [],
            "leaf": true,
            "name": "Offroad Bikes",
            "var": false
          },
          {
            "categoryId": 10000989,
            "children": [
              {
                "categoryId": 10001227,
                "children": [],
                "leaf": true,
                "name": "Balaclavas",
                "var": false
              },
              {
                "categoryId": 10001228,
                "children": [],
                "leaf": true,
                "name": "Bandannas",
                "var": false
              },
              {
                "categoryId": 10001209,
                "children": [],
                "leaf": true,
                "name": "Base Layers",
                "var": false
              },
              {
                "categoryId": 10001210,
                "children": [],
                "leaf": true,
                "name": "Chest & Back Protectors",
                "var": false
              },
              {
                "categoryId": 10001211,
                "children": [],
                "leaf": true,
                "name": "Eyewear",
                "var": false
              },
              {
                "categoryId": 10001229,
                "children": [],
                "leaf": true,
                "name": "Face Masks",
                "var": false
              },
              {
                "categoryId": 10001212,
                "children": [],
                "leaf": true,
                "name": "Footwear",
                "var": false
              },
              {
                "categoryId": 10001213,
                "children": [],
                "leaf": true,
                "name": "Gloves",
                "var": false
              },
              {
                "categoryId": 10001214,
                "children": [],
                "leaf": true,
                "name": "Helmet",
                "var": false
              },
              {
                "categoryId": 10001215,
                "children": [],
                "leaf": true,
                "name": "Helmets Accessories",
                "var": false
              },
              {
                "categoryId": 10001216,
                "children": [],
                "leaf": true,
                "name": "Jackets & Vests",
                "var": false
              },
              {
                "categoryId": 10001217,
                "children": [],
                "leaf": true,
                "name": "Jerseys",
                "var": false
              },
              {
                "categoryId": 10001218,
                "children": [],
                "leaf": true,
                "name": "Kidney Belts",
                "var": false
              },
              {
                "categoryId": 10001219,
                "children": [],
                "leaf": true,
                "name": "Knee & Shin Protection",
                "var": false
              },
              {
                "categoryId": 10001220,
                "children": [],
                "leaf": true,
                "name": "Neck Protection",
                "var": false
              },
              {
                "categoryId": 10001221,
                "children": [],
                "leaf": true,
                "name": "Pants & Chaps",
                "var": false
              },
              {
                "categoryId": 10001222,
                "children": [],
                "leaf": true,
                "name": "Racing Suits",
                "var": false
              },
              {
                "categoryId": 10001226,
                "children": [],
                "leaf": true,
                "name": "Rain Boot Covers",
                "var": false
              },
              {
                "categoryId": 10001224,
                "children": [],
                "leaf": true,
                "name": "Rain Jackets",
                "var": false
              },
              {
                "categoryId": 10001225,
                "children": [],
                "leaf": true,
                "name": "Rain Pants",
                "var": false
              },
              {
                "categoryId": 10001223,
                "children": [],
                "leaf": true,
                "name": "Rainwear",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Riding Gear",
            "var": false
          },
          {
            "categoryId": 10000972,
            "children": [],
            "leaf": true,
            "name": "Scooters",
            "var": false
          },
          {
            "categoryId": 10000973,
            "children": [],
            "leaf": true,
            "name": "Sportbikes",
            "var": false
          },
          {
            "categoryId": 10000978,
            "children": [],
            "leaf": true,
            "name": "Standard Bikes",
            "var": false
          },
          {
            "categoryId": 10000976,
            "children": [],
            "leaf": true,
            "name": "Touring Bikes",
            "var": false
          },
          {
            "categoryId": 10000983,
            "children": [],
            "leaf": true,
            "name": "Trailers for Bikes",
            "var": false
          },
          {
            "categoryId": 10000980,
            "children": [],
            "leaf": true,
            "name": "Underbone",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Motorcycle",
        "var": false
      },
      {
        "categoryId": 10000954,
        "children": [
          {
            "categoryId": 10000992,
            "children": [],
            "leaf": true,
            "name": "Accessory Installation",
            "var": false
          },
          {
            "categoryId": 10000993,
            "children": [],
            "leaf": true,
            "name": "Alignment & Steering",
            "var": false
          },
          {
            "categoryId": 10000994,
            "children": [],
            "leaf": true,
            "name": "Appraisal & Inspection",
            "var": false
          },
          {
            "categoryId": 10000995,
            "children": [],
            "leaf": true,
            "name": "Battery & Electronic",
            "var": false
          },
          {
            "categoryId": 10000996,
            "children": [],
            "leaf": true,
            "name": "Body & Paint",
            "var": false
          },
          {
            "categoryId": 10000997,
            "children": [],
            "leaf": true,
            "name": "Brakes",
            "var": false
          },
          {
            "categoryId": 10001000,
            "children": [],
            "leaf": true,
            "name": "Cleaning Sevices",
            "var": false
          },
          {
            "categoryId": 10000998,
            "children": [],
            "leaf": true,
            "name": "Cooling System & Radiator",
            "var": false
          },
          {
            "categoryId": 10001004,
            "children": [],
            "leaf": true,
            "name": "Door-to-Door services",
            "var": false
          },
          {
            "categoryId": 10001005,
            "children": [],
            "leaf": true,
            "name": "Electronics Services",
            "var": false
          },
          {
            "categoryId": 10000999,
            "children": [],
            "leaf": true,
            "name": "Engine services",
            "var": false
          },
          {
            "categoryId": 10001003,
            "children": [],
            "leaf": true,
            "name": "Exhaust & Muffler",
            "var": false
          },
          {
            "categoryId": 10001006,
            "children": [],
            "leaf": true,
            "name": "Transmission services",
            "var": false
          },
          {
            "categoryId": 10001001,
            "children": [],
            "leaf": true,
            "name": "Vehicle Insurance",
            "var": false
          },
          {
            "categoryId": 10001002,
            "children": [],
            "leaf": true,
            "name": "Vehicle Warranty",
            "var": false
          },
          {
            "categoryId": 10000991,
            "children": [],
            "leaf": true,
            "name": "Wheels & Tires Installations",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Service & Installations",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Motors",
    "var": false
  },
  {
    "categoryId": 10282,
    "children": [
      {
        "categoryId": 10002050,
        "children": [
          {
            "categoryId": 10002110,
            "children": [],
            "leaf": true,
            "name": "Cages & Accessories",
            "var": false
          },
          {
            "categoryId": 10002109,
            "children": [],
            "leaf": true,
            "name": "Food",
            "var": false
          },
          {
            "categoryId": 10002111,
            "children": [],
            "leaf": true,
            "name": "Grooming & Health",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Bird",
        "var": false
      },
      {
        "categoryId": 10367,
        "children": [
          {
            "categoryId": 10368,
            "children": [],
            "leaf": true,
            "name": "Beds & Feeding Accessories",
            "var": false
          },
          {
            "categoryId": 10002094,
            "children": [
              {
                "categoryId": 10002292,
                "children": [],
                "leaf": true,
                "name": "Beds",
                "var": false
              },
              {
                "categoryId": 10002294,
                "children": [],
                "leaf": true,
                "name": "Houses",
                "var": false
              },
              {
                "categoryId": 10002293,
                "children": [],
                "leaf": true,
                "name": "Mats",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Beds, Mats & Houses",
            "var": false
          },
          {
            "categoryId": 10002096,
            "children": [
              {
                "categoryId": 10002300,
                "children": [],
                "leaf": true,
                "name": "Automatic Feeders",
                "var": false
              },
              {
                "categoryId": 10002298,
                "children": [],
                "leaf": true,
                "name": "Bowls & Diners",
                "var": false
              },
              {
                "categoryId": 10002301,
                "children": [],
                "leaf": true,
                "name": "Feeders & Wateners",
                "var": false
              },
              {
                "categoryId": 10002299,
                "children": [],
                "leaf": true,
                "name": "Feeding Mats",
                "var": false
              },
              {
                "categoryId": 10002303,
                "children": [],
                "leaf": true,
                "name": "Food Storage",
                "var": false
              },
              {
                "categoryId": 10002302,
                "children": [],
                "leaf": true,
                "name": "Fountains",
                "var": false
              },
              {
                "categoryId": 10002304,
                "children": [],
                "leaf": true,
                "name": "Nursing Supplies",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Bowls & Feeders",
            "var": false
          },
          {
            "categoryId": 10002095,
            "children": [
              {
                "categoryId": 10002295,
                "children": [],
                "leaf": true,
                "name": "Cages & Crates",
                "var": false
              },
              {
                "categoryId": 10002296,
                "children": [],
                "leaf": true,
                "name": "Pens",
                "var": false
              },
              {
                "categoryId": 10002297,
                "children": [],
                "leaf": true,
                "name": "Pet Doors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cages, Crates & Doors",
            "var": false
          },
          {
            "categoryId": 10002101,
            "children": [
              {
                "categoryId": 10002319,
                "children": [],
                "leaf": true,
                "name": "Bicycle Accessories",
                "var": false
              },
              {
                "categoryId": 10002318,
                "children": [],
                "leaf": true,
                "name": "Car Accessories",
                "var": false
              },
              {
                "categoryId": 10002317,
                "children": [],
                "leaf": true,
                "name": "Carrier Backpacks",
                "var": false
              },
              {
                "categoryId": 10002316,
                "children": [],
                "leaf": true,
                "name": "Carriers Purses",
                "var": false
              },
              {
                "categoryId": 10002320,
                "children": [],
                "leaf": true,
                "name": "Strollers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Carriers & Travel",
            "var": false
          },
          {
            "categoryId": 10394,
            "children": [],
            "leaf": true,
            "name": "Cat Carriers",
            "var": false
          },
          {
            "categoryId": 10002100,
            "children": [
              {
                "categoryId": 10002312,
                "children": [],
                "leaf": true,
                "name": "Collars",
                "var": false
              },
              {
                "categoryId": 10002313,
                "children": [],
                "leaf": true,
                "name": "Oral Treatments",
                "var": false
              },
              {
                "categoryId": 10002315,
                "children": [],
                "leaf": true,
                "name": "Shampoos",
                "var": false
              },
              {
                "categoryId": 10002311,
                "children": [],
                "leaf": true,
                "name": "Spot Treatments",
                "var": false
              },
              {
                "categoryId": 10002314,
                "children": [],
                "leaf": true,
                "name": "Sprays",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cat Flea & Tick",
            "var": false
          },
          {
            "categoryId": 10370,
            "children": [
              {
                "categoryId": 10371,
                "children": [],
                "leaf": true,
                "name": "Cat Dry Food",
                "var": false
              },
              {
                "categoryId": 10375,
                "children": [],
                "leaf": true,
                "name": "Cat Milk Replacers",
                "var": false
              },
              {
                "categoryId": 10376,
                "children": [],
                "leaf": true,
                "name": "Cat Veterinary Diets",
                "var": false
              },
              {
                "categoryId": 10372,
                "children": [
                  {
                    "categoryId": 10373,
                    "children": [],
                    "leaf": true,
                    "name": "Canned",
                    "var": false
                  },
                  {
                    "categoryId": 10374,
                    "children": [],
                    "leaf": true,
                    "name": "Pouches",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Cat Wet Food",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cat Food",
            "var": false
          },
          {
            "categoryId": 10383,
            "children": [],
            "leaf": true,
            "name": "Cat Toys",
            "var": false
          },
          {
            "categoryId": 10377,
            "children": [
              {
                "categoryId": 10381,
                "children": [],
                "leaf": true,
                "name": "Catnip",
                "var": false
              },
              {
                "categoryId": 10379,
                "children": [],
                "leaf": true,
                "name": "Snacks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cat Treats",
            "var": false
          },
          {
            "categoryId": 10002097,
            "children": [],
            "leaf": true,
            "name": "Clothing, Shoes & Accessories",
            "var": false
          },
          {
            "categoryId": 10369,
            "children": [],
            "leaf": true,
            "name": "Collars & Harnesses",
            "var": false
          },
          {
            "categoryId": 10002099,
            "children": [
              {
                "categoryId": 10002309,
                "children": [],
                "leaf": true,
                "name": "Dental Care",
                "var": false
              },
              {
                "categoryId": 10002310,
                "children": [],
                "leaf": true,
                "name": "Supplements & Vitamins",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dental & Healthcare",
            "var": false
          },
          {
            "categoryId": 10378,
            "children": [
              {
                "categoryId": 10380,
                "children": [],
                "leaf": true,
                "name": "Brushes & Combs",
                "var": false
              },
              {
                "categoryId": 10382,
                "children": [],
                "leaf": true,
                "name": "Claw Care",
                "var": false
              },
              {
                "categoryId": 10386,
                "children": [],
                "leaf": true,
                "name": "Electric Clippers",
                "var": false
              },
              {
                "categoryId": 10384,
                "children": [],
                "leaf": true,
                "name": "Perfume / Deodorizers",
                "var": false
              },
              {
                "categoryId": 10388,
                "children": [],
                "leaf": true,
                "name": "Scissors & Blades",
                "var": false
              },
              {
                "categoryId": 10002288,
                "children": [],
                "leaf": true,
                "name": "Shampoos & Bath Accessories",
                "var": false
              },
              {
                "categoryId": 10392,
                "children": [],
                "leaf": true,
                "name": "Shower Gel & Bath Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Grooming",
            "var": false
          },
          {
            "categoryId": 10385,
            "children": [
              {
                "categoryId": 10391,
                "children": [],
                "leaf": true,
                "name": "Ear Care",
                "var": false
              },
              {
                "categoryId": 10393,
                "children": [],
                "leaf": true,
                "name": "Eye Care",
                "var": false
              },
              {
                "categoryId": 10416,
                "children": [],
                "leaf": true,
                "name": "Flea, Lice & Tick Control",
                "var": false
              },
              {
                "categoryId": 10395,
                "children": [],
                "leaf": true,
                "name": "Hairball Remedies",
                "var": false
              },
              {
                "categoryId": 10399,
                "children": [],
                "leaf": true,
                "name": "Itch Remedies",
                "var": false
              },
              {
                "categoryId": 10403,
                "children": [],
                "leaf": true,
                "name": "Recovery Collars & Cones",
                "var": false
              },
              {
                "categoryId": 10405,
                "children": [],
                "leaf": true,
                "name": "Supplements & Vitamins",
                "var": false
              },
              {
                "categoryId": 10401,
                "children": [],
                "leaf": true,
                "name": "Wormers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Health Supplies",
            "var": false
          },
          {
            "categoryId": 10002098,
            "children": [
              {
                "categoryId": 10002305,
                "children": [],
                "leaf": true,
                "name": "Collars",
                "var": false
              },
              {
                "categoryId": 10002308,
                "children": [],
                "leaf": true,
                "name": "GPS tracker",
                "var": false
              },
              {
                "categoryId": 10002307,
                "children": [],
                "leaf": true,
                "name": "ID tags",
                "var": false
              },
              {
                "categoryId": 10002306,
                "children": [],
                "leaf": true,
                "name": "Leashes & Harnesses",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Leashes & Collars",
            "var": false
          },
          {
            "categoryId": 10396,
            "children": [
              {
                "categoryId": 10398,
                "children": [],
                "leaf": true,
                "name": "Diapers",
                "var": false
              },
              {
                "categoryId": 10402,
                "children": [],
                "leaf": true,
                "name": "Litter Boxes",
                "var": false
              },
              {
                "categoryId": 10404,
                "children": [],
                "leaf": true,
                "name": "Litter Scoops",
                "var": false
              },
              {
                "categoryId": 10400,
                "children": [],
                "leaf": true,
                "name": "Litters",
                "var": true
              },
              {
                "categoryId": 10406,
                "children": [],
                "leaf": true,
                "name": "Odor & Strain Removers",
                "var": false
              },
              {
                "categoryId": 10408,
                "children": [],
                "leaf": true,
                "name": "Urine Detectors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Litter & Toilet",
            "var": false
          },
          {
            "categoryId": 10002102,
            "children": [
              {
                "categoryId": 10002321,
                "children": [],
                "leaf": true,
                "name": "Indoor Cameras",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Technology",
            "var": false
          },
          {
            "categoryId": 10002093,
            "children": [
              {
                "categoryId": 10002291,
                "children": [],
                "leaf": true,
                "name": "Ramps",
                "var": false
              },
              {
                "categoryId": 10002290,
                "children": [],
                "leaf": true,
                "name": "Scratchers Pads & Posts",
                "var": false
              },
              {
                "categoryId": 10002289,
                "children": [],
                "leaf": true,
                "name": "Trees & Condos",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Trees, Condos & Scratchers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Cat",
        "var": false
      },
      {
        "categoryId": 10286,
        "children": [
          {
            "categoryId": 10289,
            "children": [],
            "leaf": true,
            "name": "Beds & Feeding Accessories",
            "var": false
          },
          {
            "categoryId": 10002083,
            "children": [
              {
                "categoryId": 10002239,
                "children": [],
                "leaf": true,
                "name": "Beds",
                "var": false
              },
              {
                "categoryId": 10002241,
                "children": [],
                "leaf": true,
                "name": "Houses",
                "var": false
              },
              {
                "categoryId": 10002240,
                "children": [],
                "leaf": true,
                "name": "Mats",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Beds, Mats & Houses",
            "var": false
          },
          {
            "categoryId": 10002086,
            "children": [
              {
                "categoryId": 10002257,
                "children": [],
                "leaf": true,
                "name": "Automatic Feeders",
                "var": false
              },
              {
                "categoryId": 10002255,
                "children": [],
                "leaf": true,
                "name": "Bowls & Diners",
                "var": false
              },
              {
                "categoryId": 10002258,
                "children": [],
                "leaf": true,
                "name": "Feeders & Wateners",
                "var": false
              },
              {
                "categoryId": 10002256,
                "children": [],
                "leaf": true,
                "name": "Feeding Mats",
                "var": false
              },
              {
                "categoryId": 10002260,
                "children": [],
                "leaf": true,
                "name": "Food Storage",
                "var": false
              },
              {
                "categoryId": 10002259,
                "children": [],
                "leaf": true,
                "name": "Fountains",
                "var": false
              },
              {
                "categoryId": 10002261,
                "children": [],
                "leaf": true,
                "name": "Nursing Supplies",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Bowls & Feeders",
            "var": false
          },
          {
            "categoryId": 10002084,
            "children": [
              {
                "categoryId": 10002242,
                "children": [],
                "leaf": true,
                "name": "Cages & Crates",
                "var": false
              },
              {
                "categoryId": 10002243,
                "children": [],
                "leaf": true,
                "name": "Pens",
                "var": false
              },
              {
                "categoryId": 10002244,
                "children": [],
                "leaf": true,
                "name": "Pet Doors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cages, Crates & Doors",
            "var": false
          },
          {
            "categoryId": 10002090,
            "children": [
              {
                "categoryId": 10002280,
                "children": [],
                "leaf": true,
                "name": "Bicycle Accessories",
                "var": false
              },
              {
                "categoryId": 10002279,
                "children": [],
                "leaf": true,
                "name": "Car Accessories",
                "var": false
              },
              {
                "categoryId": 10002278,
                "children": [],
                "leaf": true,
                "name": "Carrier Backpacks",
                "var": false
              },
              {
                "categoryId": 10002277,
                "children": [],
                "leaf": true,
                "name": "Carriers Purses",
                "var": false
              },
              {
                "categoryId": 10002281,
                "children": [],
                "leaf": true,
                "name": "Strollers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Carriers & Travel",
            "var": false
          },
          {
            "categoryId": 10361,
            "children": [
              {
                "categoryId": 10362,
                "children": [],
                "leaf": true,
                "name": "Diapers",
                "var": false
              },
              {
                "categoryId": 10363,
                "children": [],
                "leaf": true,
                "name": "Odor & Strain Removers",
                "var": false
              },
              {
                "categoryId": 10366,
                "children": [],
                "leaf": true,
                "name": "Pooper Scoopers & Bags",
                "var": false
              },
              {
                "categoryId": 10364,
                "children": [],
                "leaf": true,
                "name": "Training Pads & Trays",
                "var": false
              },
              {
                "categoryId": 10365,
                "children": [],
                "leaf": true,
                "name": "Urine Detectors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Clean up & Toilet",
            "var": false
          },
          {
            "categoryId": 10002085,
            "children": [
              {
                "categoryId": 10002245,
                "children": [],
                "leaf": true,
                "name": "Backpacks",
                "var": false
              },
              {
                "categoryId": 10002246,
                "children": [],
                "leaf": true,
                "name": "Boots, Socks & Paw Protectors",
                "var": false
              },
              {
                "categoryId": 10002247,
                "children": [],
                "leaf": true,
                "name": "Coats",
                "var": false
              },
              {
                "categoryId": 10002248,
                "children": [],
                "leaf": true,
                "name": "Costumes",
                "var": false
              },
              {
                "categoryId": 10002249,
                "children": [],
                "leaf": true,
                "name": "Dresses",
                "var": false
              },
              {
                "categoryId": 10002250,
                "children": [],
                "leaf": true,
                "name": "Hair Accessories",
                "var": false
              },
              {
                "categoryId": 10002251,
                "children": [],
                "leaf": true,
                "name": "Hats",
                "var": false
              },
              {
                "categoryId": 10002252,
                "children": [],
                "leaf": true,
                "name": "Necklaces",
                "var": false
              },
              {
                "categoryId": 10002253,
                "children": [],
                "leaf": true,
                "name": "Shirts",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Clothing, Shoes & Accessories",
            "var": false
          },
          {
            "categoryId": 10294,
            "children": [],
            "leaf": true,
            "name": "Collars & Harnesses",
            "var": false
          },
          {
            "categoryId": 10002088,
            "children": [
              {
                "categoryId": 10002270,
                "children": [
                  {
                    "categoryId": 10002676,
                    "children": [],
                    "leaf": true,
                    "name": "Mouthwash",
                    "var": false
                  },
                  {
                    "categoryId": 10002675,
                    "children": [],
                    "leaf": true,
                    "name": "Toothbrush",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Dental Care",
                "var": false
              },
              {
                "categoryId": 10002271,
                "children": [],
                "leaf": true,
                "name": "Supplements & Vitamins",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dental & Healthcare",
            "var": false
          },
          {
            "categoryId": 10360,
            "children": [],
            "leaf": true,
            "name": "Dog Carriers",
            "var": false
          },
          {
            "categoryId": 10002089,
            "children": [
              {
                "categoryId": 10002273,
                "children": [],
                "leaf": true,
                "name": "Collars",
                "var": false
              },
              {
                "categoryId": 10002274,
                "children": [],
                "leaf": true,
                "name": "Oral Treatments",
                "var": false
              },
              {
                "categoryId": 10002276,
                "children": [],
                "leaf": true,
                "name": "Shampoos",
                "var": false
              },
              {
                "categoryId": 10002272,
                "children": [],
                "leaf": true,
                "name": "Spot Treatments",
                "var": false
              },
              {
                "categoryId": 10002275,
                "children": [],
                "leaf": true,
                "name": "Sprays",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dog Flea & Tick",
            "var": false
          },
          {
            "categoryId": 10298,
            "children": [
              {
                "categoryId": 10301,
                "children": [],
                "leaf": true,
                "name": "Dog Dry Food",
                "var": false
              },
              {
                "categoryId": 10318,
                "children": [],
                "leaf": true,
                "name": "Dog Food Toppers",
                "var": false
              },
              {
                "categoryId": 10002237,
                "children": [],
                "leaf": true,
                "name": "Dog Milk Replacers",
                "var": false
              },
              {
                "categoryId": 10320,
                "children": [],
                "leaf": true,
                "name": "Dog Veterinary Diets",
                "var": false
              },
              {
                "categoryId": 10311,
                "children": [
                  {
                    "categoryId": 10313,
                    "children": [],
                    "leaf": true,
                    "name": "Canned",
                    "var": false
                  },
                  {
                    "categoryId": 10314,
                    "children": [],
                    "leaf": true,
                    "name": "Pouches",
                    "var": false
                  },
                  {
                    "categoryId": 10316,
                    "children": [],
                    "leaf": true,
                    "name": "Rolls",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Dog Wet Food",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dog Food",
            "var": false
          },
          {
            "categoryId": 10336,
            "children": [],
            "leaf": true,
            "name": "Dog Toys",
            "var": false
          },
          {
            "categoryId": 10321,
            "children": [
              {
                "categoryId": 10332,
                "children": [],
                "leaf": true,
                "name": "Bones",
                "var": false
              },
              {
                "categoryId": 10322,
                "children": [],
                "leaf": true,
                "name": "Cookies, Biscuits & Snacks",
                "var": false
              },
              {
                "categoryId": 10323,
                "children": [],
                "leaf": true,
                "name": "Dental Chews",
                "var": false
              },
              {
                "categoryId": 10324,
                "children": [],
                "leaf": true,
                "name": "Hooves",
                "var": false
              },
              {
                "categoryId": 10328,
                "children": [],
                "leaf": true,
                "name": "Jerky",
                "var": false
              },
              {
                "categoryId": 10331,
                "children": [],
                "leaf": true,
                "name": "Rawhides",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Dog Treats",
            "var": false
          },
          {
            "categoryId": 10337,
            "children": [
              {
                "categoryId": 10338,
                "children": [],
                "leaf": true,
                "name": "Brushes & Combs",
                "var": false
              },
              {
                "categoryId": 10339,
                "children": [],
                "leaf": true,
                "name": "Claw Care",
                "var": false
              },
              {
                "categoryId": 10341,
                "children": [],
                "leaf": true,
                "name": "Electric Clippers",
                "var": false
              },
              {
                "categoryId": 10340,
                "children": [],
                "leaf": true,
                "name": "Perfume / Deodorizers",
                "var": false
              },
              {
                "categoryId": 10342,
                "children": [],
                "leaf": true,
                "name": "Scissors & Blades",
                "var": false
              },
              {
                "categoryId": 10002238,
                "children": [],
                "leaf": true,
                "name": "Shampoos & Bath Accessories",
                "var": false
              },
              {
                "categoryId": 10344,
                "children": [],
                "leaf": true,
                "name": "Shower Gel & Bath Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Grooming",
            "var": false
          },
          {
            "categoryId": 10345,
            "children": [
              {
                "categoryId": 10348,
                "children": [],
                "leaf": true,
                "name": "Ear Care",
                "var": false
              },
              {
                "categoryId": 10349,
                "children": [],
                "leaf": true,
                "name": "Eye Care",
                "var": false
              },
              {
                "categoryId": 10356,
                "children": [],
                "leaf": true,
                "name": "Flea, Lice & Tick Control",
                "var": false
              },
              {
                "categoryId": 10351,
                "children": [],
                "leaf": true,
                "name": "Itch Remedies",
                "var": false
              },
              {
                "categoryId": 10352,
                "children": [],
                "leaf": true,
                "name": "Medications",
                "var": false
              },
              {
                "categoryId": 10357,
                "children": [],
                "leaf": true,
                "name": "Supplements & Vitamins",
                "var": false
              },
              {
                "categoryId": 10359,
                "children": [],
                "leaf": true,
                "name": "Wormers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Health Supplies",
            "var": false
          },
          {
            "categoryId": 10002087,
            "children": [
              {
                "categoryId": 10002262,
                "children": [],
                "leaf": true,
                "name": "Collars",
                "var": false
              },
              {
                "categoryId": 10002269,
                "children": [],
                "leaf": true,
                "name": "GPS tracker",
                "var": false
              },
              {
                "categoryId": 10002265,
                "children": [],
                "leaf": true,
                "name": "Harnesses",
                "var": false
              },
              {
                "categoryId": 10002268,
                "children": [],
                "leaf": true,
                "name": "Head Collars",
                "var": false
              },
              {
                "categoryId": 10002264,
                "children": [],
                "leaf": true,
                "name": "Leashes",
                "var": false
              },
              {
                "categoryId": 10002267,
                "children": [],
                "leaf": true,
                "name": "Muzzles",
                "var": false
              },
              {
                "categoryId": 10002263,
                "children": [],
                "leaf": true,
                "name": "Training Collars",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Leashes Collars & Muzzles",
            "var": false
          },
          {
            "categoryId": 10002092,
            "children": [
              {
                "categoryId": 10002287,
                "children": [],
                "leaf": true,
                "name": "Indoor Cameras",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Technology",
            "var": false
          },
          {
            "categoryId": 10002091,
            "children": [
              {
                "categoryId": 10002282,
                "children": [],
                "leaf": true,
                "name": "Bark Control & Remote Training",
                "var": false
              },
              {
                "categoryId": 10002284,
                "children": [],
                "leaf": true,
                "name": "Calming Aids",
                "var": false
              },
              {
                "categoryId": 10002285,
                "children": [],
                "leaf": true,
                "name": "Fence Systems",
                "var": false
              },
              {
                "categoryId": 10002283,
                "children": [],
                "leaf": true,
                "name": "Leashes & Collars",
                "var": false
              },
              {
                "categoryId": 10002286,
                "children": [],
                "leaf": true,
                "name": "Sport Training",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Training Aids",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Dog",
        "var": false
      },
      {
        "categoryId": 10002052,
        "children": [
          {
            "categoryId": 10002123,
            "children": [],
            "leaf": false,
            "name": "Bowls & Feeders",
            "var": false
          },
          {
            "categoryId": 10002124,
            "children": [],
            "leaf": false,
            "name": "Carriers & Travel",
            "var": false
          },
          {
            "categoryId": 10002119,
            "children": [],
            "leaf": false,
            "name": "Dog & Cat Beds, Mats & Houses",
            "var": false
          },
          {
            "categoryId": 10002120,
            "children": [],
            "leaf": false,
            "name": "Dog & Cat Cages, Crates & Doors",
            "var": false
          },
          {
            "categoryId": 10002122,
            "children": [],
            "leaf": false,
            "name": "Grooming",
            "var": false
          },
          {
            "categoryId": 10002121,
            "children": [],
            "leaf": false,
            "name": "Leashes, Collars & Muzzles",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Dog & Cat",
        "var": false
      },
      {
        "categoryId": 10002049,
        "children": [
          {
            "categoryId": 10002108,
            "children": [
              {
                "categoryId": 10002328,
                "children": [],
                "leaf": true,
                "name": "Air Pumps & Accessories",
                "var": false
              },
              {
                "categoryId": 10002332,
                "children": [],
                "leaf": true,
                "name": "CO2 Equipment",
                "var": false
              },
              {
                "categoryId": 10002331,
                "children": [],
                "leaf": true,
                "name": "Cleaning tools",
                "var": false
              },
              {
                "categoryId": 10002326,
                "children": [],
                "leaf": true,
                "name": "Decorations",
                "var": false
              },
              {
                "categoryId": 10002333,
                "children": [],
                "leaf": true,
                "name": "Feeders",
                "var": false
              },
              {
                "categoryId": 10002325,
                "children": [],
                "leaf": true,
                "name": "Filters & Accessories",
                "var": false
              },
              {
                "categoryId": 10002327,
                "children": [],
                "leaf": true,
                "name": "Lightings",
                "var": false
              },
              {
                "categoryId": 10002330,
                "children": [],
                "leaf": true,
                "name": "Temperature control supplies",
                "var": false
              },
              {
                "categoryId": 10002329,
                "children": [],
                "leaf": true,
                "name": "Water pumps",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Aquariums Accessories",
            "var": false
          },
          {
            "categoryId": 10002106,
            "children": [
              {
                "categoryId": 10002322,
                "children": [],
                "leaf": true,
                "name": "Aquariums",
                "var": false
              },
              {
                "categoryId": 10002324,
                "children": [],
                "leaf": true,
                "name": "Bowls",
                "var": false
              },
              {
                "categoryId": 10002323,
                "children": [],
                "leaf": true,
                "name": "Starter Kits",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Aquariums, Tanks & Bowls",
            "var": false
          },
          {
            "categoryId": 10002107,
            "children": [],
            "leaf": true,
            "name": "Food",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Fish",
        "var": false
      },
      {
        "categoryId": 10002053,
        "children": [],
        "leaf": true,
        "name": "Others",
        "var": false
      },
      {
        "categoryId": 10002051,
        "children": [
          {
            "categoryId": 10002116,
            "children": [],
            "leaf": true,
            "name": "Bedding",
            "var": false
          },
          {
            "categoryId": 10002115,
            "children": [],
            "leaf": true,
            "name": "Cleaning & Maintenance",
            "var": false
          },
          {
            "categoryId": 10002112,
            "children": [],
            "leaf": true,
            "name": "Food",
            "var": false
          },
          {
            "categoryId": 10002113,
            "children": [],
            "leaf": true,
            "name": "Habitats & Accessories",
            "var": false
          },
          {
            "categoryId": 10002117,
            "children": [],
            "leaf": true,
            "name": "Health & Wellness",
            "var": false
          },
          {
            "categoryId": 10002114,
            "children": [],
            "leaf": true,
            "name": "Heating & Lighting",
            "var": false
          },
          {
            "categoryId": 10002118,
            "children": [],
            "leaf": true,
            "name": "Nesting & Breeding equipments",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Reptile",
        "var": false
      },
      {
        "categoryId": 10002048,
        "children": [
          {
            "categoryId": 10002103,
            "children": [],
            "leaf": true,
            "name": "Food",
            "var": false
          },
          {
            "categoryId": 10002105,
            "children": [],
            "leaf": true,
            "name": "Grooming & Health",
            "var": false
          },
          {
            "categoryId": 10002104,
            "children": [],
            "leaf": true,
            "name": "Habitats & Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Small Pet",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Pet Supplies",
    "var": false
  },
  {
    "categoryId": 1970,
    "children": [
      {
        "categoryId": 4648,
        "children": [
          {
            "categoryId": 4689,
            "children": [],
            "leaf": true,
            "name": "Air Pumps",
            "var": false
          },
          {
            "categoryId": 10000819,
            "children": [],
            "leaf": true,
            "name": "First Aid Kits",
            "var": false
          },
          {
            "categoryId": 2063,
            "children": [],
            "leaf": true,
            "name": "Headbands",
            "var": false
          },
          {
            "categoryId": 4655,
            "children": [
              {
                "categoryId": 10000852,
                "children": [],
                "leaf": true,
                "name": "Cycling",
                "var": false
              },
              {
                "categoryId": 10000853,
                "children": [],
                "leaf": true,
                "name": "Fitness",
                "var": false
              },
              {
                "categoryId": 10000854,
                "children": [],
                "leaf": true,
                "name": "Hiking",
                "var": false
              },
              {
                "categoryId": 10000856,
                "children": [],
                "leaf": true,
                "name": "Running",
                "var": false
              },
              {
                "categoryId": 10000857,
                "children": [],
                "leaf": true,
                "name": "Straps & Accessories",
                "var": false
              },
              {
                "categoryId": 10000855,
                "children": [],
                "leaf": true,
                "name": "Swimming",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Performance & GPS Trackers",
            "var": false
          },
          {
            "categoryId": 10000820,
            "children": [],
            "leaf": true,
            "name": "Supports & Braces",
            "var": false
          },
          {
            "categoryId": 4688,
            "children": [],
            "leaf": true,
            "name": "Water Bottles",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Accessories",
        "var": false
      },
      {
        "categoryId": 10186,
        "children": [
          {
            "categoryId": 10191,
            "children": [],
            "leaf": true,
            "name": "Boxing Gym Equipment",
            "var": false
          },
          {
            "categoryId": 10192,
            "children": [],
            "leaf": true,
            "name": "Boxing Pads",
            "var": false
          },
          {
            "categoryId": 10187,
            "children": [
              {
                "categoryId": 10188,
                "children": [],
                "leaf": true,
                "name": "Boxing Gloves",
                "var": false
              },
              {
                "categoryId": 10189,
                "children": [],
                "leaf": true,
                "name": "MMA Gloves",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Gloves",
            "var": false
          },
          {
            "categoryId": 10190,
            "children": [],
            "leaf": true,
            "name": "Hand Wraps",
            "var": false
          },
          {
            "categoryId": 10193,
            "children": [],
            "leaf": true,
            "name": "Kicking Targets",
            "var": false
          },
          {
            "categoryId": 10196,
            "children": [],
            "leaf": true,
            "name": "Martial Art Equipment",
            "var": false
          },
          {
            "categoryId": 10197,
            "children": [],
            "leaf": true,
            "name": "Martial Art Uniform",
            "var": false
          },
          {
            "categoryId": 10194,
            "children": [],
            "leaf": true,
            "name": "Protective Gear",
            "var": false
          },
          {
            "categoryId": 10195,
            "children": [],
            "leaf": true,
            "name": "Punching Bags & Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Boxing, Martial Arts & MMA",
        "var": false
      },
      {
        "categoryId": 1971,
        "children": [
          {
            "categoryId": 1987,
            "children": [
              {
                "categoryId": 1988,
                "children": [],
                "leaf": true,
                "name": "Elliptical Trainers",
                "var": false
              },
              {
                "categoryId": 1989,
                "children": [],
                "leaf": true,
                "name": "Exercise Bikes",
                "var": false
              },
              {
                "categoryId": 1990,
                "children": [],
                "leaf": true,
                "name": "Rowing Machines",
                "var": false
              },
              {
                "categoryId": 1991,
                "children": [],
                "leaf": true,
                "name": "Step Machines",
                "var": false
              },
              {
                "categoryId": 1992,
                "children": [],
                "leaf": true,
                "name": "Treadmills",
                "var": false
              },
              {
                "categoryId": 9868,
                "children": [],
                "leaf": true,
                "name": "Vibration Machines",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cardio Equipment",
            "var": false
          },
          {
            "categoryId": 2017,
            "children": [
              {
                "categoryId": 2018,
                "children": [],
                "leaf": true,
                "name": "Aquatic Fitness Equipment",
                "var": false
              },
              {
                "categoryId": 2019,
                "children": [],
                "leaf": true,
                "name": "Balance trainers",
                "var": false
              },
              {
                "categoryId": 2021,
                "children": [],
                "leaf": true,
                "name": "Exercise Balls",
                "var": false
              },
              {
                "categoryId": 2022,
                "children": [],
                "leaf": true,
                "name": "Exercise Bands",
                "var": false
              },
              {
                "categoryId": 2023,
                "children": [],
                "leaf": true,
                "name": "Exercise Mats",
                "var": false
              },
              {
                "categoryId": 2025,
                "children": [],
                "leaf": true,
                "name": "Fitness Gloves",
                "var": false
              },
              {
                "categoryId": 2028,
                "children": [],
                "leaf": true,
                "name": "Jump Ropes",
                "var": false
              },
              {
                "categoryId": 2029,
                "children": [],
                "leaf": true,
                "name": "Jumping Trainers",
                "var": false
              },
              {
                "categoryId": 2032,
                "children": [],
                "leaf": true,
                "name": "Protective Flooring",
                "var": false
              },
              {
                "categoryId": 2045,
                "children": [],
                "leaf": true,
                "name": "Trampolines",
                "var": false
              },
              {
                "categoryId": 9874,
                "children": [],
                "leaf": true,
                "name": "Twister Plates",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Fitness Accessories",
            "var": false
          },
          {
            "categoryId": 1980,
            "children": [
              {
                "categoryId": 1981,
                "children": [],
                "leaf": true,
                "name": "Chairs",
                "var": false
              },
              {
                "categoryId": 1982,
                "children": [],
                "leaf": true,
                "name": "Fitness Circles",
                "var": false
              },
              {
                "categoryId": 1983,
                "children": [],
                "leaf": true,
                "name": "Flexbands",
                "var": false
              },
              {
                "categoryId": 1985,
                "children": [],
                "leaf": true,
                "name": "Reformers",
                "var": false
              },
              {
                "categoryId": 1986,
                "children": [],
                "leaf": true,
                "name": "Spine Supporters",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Pilates",
            "var": false
          },
          {
            "categoryId": 10000816,
            "children": [
              {
                "categoryId": 10000827,
                "children": [],
                "leaf": true,
                "name": "Arm bands",
                "var": false
              },
              {
                "categoryId": 10000828,
                "children": [],
                "leaf": true,
                "name": "Hydration & Waist Pack",
                "var": false
              },
              {
                "categoryId": 10000830,
                "children": [],
                "leaf": true,
                "name": "Pedometers",
                "var": false
              },
              {
                "categoryId": 10000829,
                "children": [],
                "leaf": true,
                "name": "Reflective Gear",
                "var": false
              },
              {
                "categoryId": 10000826,
                "children": [],
                "leaf": true,
                "name": "Running Belt",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Running",
            "var": false
          },
          {
            "categoryId": 1994,
            "children": [
              {
                "categoryId": 9869,
                "children": [],
                "leaf": true,
                "name": "Benches",
                "var": false
              },
              {
                "categoryId": 9870,
                "children": [],
                "leaf": true,
                "name": "Core & Abdominal Trainers",
                "var": false
              },
              {
                "categoryId": 9871,
                "children": [],
                "leaf": true,
                "name": "Hand Strengtheners",
                "var": false
              },
              {
                "categoryId": 9872,
                "children": [],
                "leaf": true,
                "name": "Home Gyms & Fitness Equipment",
                "var": false
              },
              {
                "categoryId": 9873,
                "children": [],
                "leaf": true,
                "name": "Pull-Up & Push-Up Bars",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Strength Training Equipment",
            "var": false
          },
          {
            "categoryId": 9876,
            "children": [
              {
                "categoryId": 9879,
                "children": [],
                "leaf": true,
                "name": "Barbells",
                "var": false
              },
              {
                "categoryId": 9877,
                "children": [],
                "leaf": true,
                "name": "Body Weights",
                "var": false
              },
              {
                "categoryId": 9883,
                "children": [],
                "leaf": true,
                "name": "Collars",
                "var": false
              },
              {
                "categoryId": 9880,
                "children": [],
                "leaf": true,
                "name": "Dumbbells",
                "var": false
              },
              {
                "categoryId": 9881,
                "children": [],
                "leaf": true,
                "name": "Kettlebells",
                "var": false
              },
              {
                "categoryId": 9882,
                "children": [],
                "leaf": true,
                "name": "Medicine Balls",
                "var": false
              },
              {
                "categoryId": 9878,
                "children": [],
                "leaf": true,
                "name": "Weight Plates",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Weight",
            "var": false
          },
          {
            "categoryId": 1972,
            "children": [
              {
                "categoryId": 1973,
                "children": [],
                "leaf": true,
                "name": "Blocks",
                "var": false
              },
              {
                "categoryId": 1974,
                "children": [],
                "leaf": true,
                "name": "Foam Rollers",
                "var": false
              },
              {
                "categoryId": 1979,
                "children": [],
                "leaf": true,
                "name": "Straps",
                "var": false
              },
              {
                "categoryId": 1975,
                "children": [],
                "leaf": true,
                "name": "Yoga Mat Bags",
                "var": false
              },
              {
                "categoryId": 1976,
                "children": [],
                "leaf": true,
                "name": "Yoga Mats",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Yoga",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Exercise & Fitness",
        "var": false
      },
      {
        "categoryId": 10000815,
        "children": [
          {
            "categoryId": 10000824,
            "children": [
              {
                "categoryId": 10000884,
                "children": [],
                "leaf": true,
                "name": "Accessories & Gifts",
                "var": false
              },
              {
                "categoryId": 10000886,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 10000882,
                "children": [],
                "leaf": true,
                "name": "Caps",
                "var": true
              },
              {
                "categoryId": 10000883,
                "children": [],
                "leaf": true,
                "name": "Footwear",
                "var": true
              },
              {
                "categoryId": 10000881,
                "children": [],
                "leaf": true,
                "name": "Jackets",
                "var": true
              },
              {
                "categoryId": 10000885,
                "children": [],
                "leaf": true,
                "name": "Jerseys",
                "var": true
              },
              {
                "categoryId": 10000880,
                "children": [],
                "leaf": true,
                "name": "T-shirts & Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Basketball",
            "var": false
          },
          {
            "categoryId": 10000823,
            "children": [
              {
                "categoryId": 10000876,
                "children": [],
                "leaf": true,
                "name": "Accessories & Gifts",
                "var": true
              },
              {
                "categoryId": 10000878,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 10000875,
                "children": [],
                "leaf": true,
                "name": "Caps",
                "var": true
              },
              {
                "categoryId": 10000879,
                "children": [],
                "leaf": true,
                "name": "Footwear",
                "var": true
              },
              {
                "categoryId": 10000874,
                "children": [],
                "leaf": true,
                "name": "Jackets",
                "var": true
              },
              {
                "categoryId": 10000877,
                "children": [],
                "leaf": true,
                "name": "Jerseys",
                "var": true
              },
              {
                "categoryId": 10000873,
                "children": [],
                "leaf": true,
                "name": "T-shirts & Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Formula 1",
            "var": false
          },
          {
            "categoryId": 10000821,
            "children": [
              {
                "categoryId": 10000863,
                "children": [],
                "leaf": true,
                "name": "Accessories & Gifts",
                "var": false
              },
              {
                "categoryId": 10000864,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 10000861,
                "children": [],
                "leaf": true,
                "name": "Caps",
                "var": true
              },
              {
                "categoryId": 10000862,
                "children": [],
                "leaf": true,
                "name": "Footwear",
                "var": true
              },
              {
                "categoryId": 10000860,
                "children": [],
                "leaf": true,
                "name": "Jackets",
                "var": true
              },
              {
                "categoryId": 10000865,
                "children": [],
                "leaf": true,
                "name": "Jerseys",
                "var": true
              },
              {
                "categoryId": 10000859,
                "children": [],
                "leaf": true,
                "name": "T-shirts & Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "International Football Clubs",
            "var": false
          },
          {
            "categoryId": 10000822,
            "children": [
              {
                "categoryId": 10000870,
                "children": [],
                "leaf": true,
                "name": "Accessories & Gifts",
                "var": true
              },
              {
                "categoryId": 10000872,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 10000868,
                "children": [],
                "leaf": true,
                "name": "Caps",
                "var": true
              },
              {
                "categoryId": 10000869,
                "children": [],
                "leaf": true,
                "name": "Footwear",
                "var": true
              },
              {
                "categoryId": 10000867,
                "children": [],
                "leaf": true,
                "name": "Jackets",
                "var": true
              },
              {
                "categoryId": 10000871,
                "children": [],
                "leaf": true,
                "name": "Jerseys",
                "var": true
              },
              {
                "categoryId": 10000866,
                "children": [],
                "leaf": true,
                "name": "T-shirts & Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "National Football Teams",
            "var": false
          },
          {
            "categoryId": 10000825,
            "children": [
              {
                "categoryId": 10000890,
                "children": [],
                "leaf": true,
                "name": "Accessories & Gifts",
                "var": false
              },
              {
                "categoryId": 10000892,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 10000889,
                "children": [],
                "leaf": true,
                "name": "Caps",
                "var": true
              },
              {
                "categoryId": 10000893,
                "children": [],
                "leaf": true,
                "name": "Footwear",
                "var": true
              },
              {
                "categoryId": 10000888,
                "children": [],
                "leaf": true,
                "name": "Jackets",
                "var": true
              },
              {
                "categoryId": 10000891,
                "children": [],
                "leaf": true,
                "name": "Jerseys",
                "var": true
              },
              {
                "categoryId": 10000887,
                "children": [],
                "leaf": true,
                "name": "T-shirts & Tops",
                "var": true
              }
            ],
            "leaf": false,
            "name": "Tennis",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Fan Shop",
        "var": false
      },
      {
        "categoryId": 3023,
        "children": [
          {
            "categoryId": 3024,
            "children": [
              {
                "categoryId": 3030,
                "children": [
                  {
                    "categoryId": 3032,
                    "children": [],
                    "leaf": true,
                    "name": "Dartboards",
                    "var": false
                  },
                  {
                    "categoryId": 3031,
                    "children": [],
                    "leaf": true,
                    "name": "Darts",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Darts & Dartboards",
                "var": false
              },
              {
                "categoryId": 3040,
                "children": [],
                "leaf": true,
                "name": "Foosball Tables",
                "var": false
              },
              {
                "categoryId": 4679,
                "children": [],
                "leaf": true,
                "name": "Pool & Billiard",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Pool, Darts & Foosball",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Games",
        "var": false
      },
      {
        "categoryId": 2620,
        "children": [
          {
            "categoryId": 2621,
            "children": [
              {
                "categoryId": 9934,
                "children": [
                  {
                    "categoryId": 9938,
                    "children": [],
                    "leaf": true,
                    "name": "Backpack Covers",
                    "var": false
                  },
                  {
                    "categoryId": 9935,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Backpacks",
                    "var": false
                  },
                  {
                    "categoryId": 9936,
                    "children": [],
                    "leaf": true,
                    "name": "Hydration Packs",
                    "var": false
                  },
                  {
                    "categoryId": 9937,
                    "children": [],
                    "leaf": true,
                    "name": "Waist Packs",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Backpacks",
                "var": false
              },
              {
                "categoryId": 9940,
                "children": [
                  {
                    "categoryId": 9941,
                    "children": [],
                    "leaf": true,
                    "name": "Camp Tables",
                    "var": false
                  },
                  {
                    "categoryId": 9942,
                    "children": [],
                    "leaf": true,
                    "name": "Portable Chairs",
                    "var": false
                  },
                  {
                    "categoryId": 9943,
                    "children": [],
                    "leaf": true,
                    "name": "Storage Trunks",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Camp Furniture",
                "var": false
              },
              {
                "categoryId": 9944,
                "children": [
                  {
                    "categoryId": 9946,
                    "children": [],
                    "leaf": true,
                    "name": "Camping Grills",
                    "var": false
                  },
                  {
                    "categoryId": 9945,
                    "children": [],
                    "leaf": true,
                    "name": "Camping Stoves",
                    "var": false
                  },
                  {
                    "categoryId": 9947,
                    "children": [],
                    "leaf": true,
                    "name": "Cookware & Utensils",
                    "var": false
                  },
                  {
                    "categoryId": 9948,
                    "children": [],
                    "leaf": true,
                    "name": "Coolers",
                    "var": false
                  },
                  {
                    "categoryId": 9949,
                    "children": [],
                    "leaf": true,
                    "name": "Hydration & Filtration",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Camp Kitchen",
                "var": false
              },
              {
                "categoryId": 2646,
                "children": [
                  {
                    "categoryId": 2648,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Boots",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Footwear",
                "var": false
              },
              {
                "categoryId": 2649,
                "children": [
                  {
                    "categoryId": 2651,
                    "children": [],
                    "leaf": true,
                    "name": "Fleece Jackets",
                    "var": false
                  },
                  {
                    "categoryId": 2650,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Chino Shorts",
                    "var": false
                  },
                  {
                    "categoryId": 2652,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking T-Shirts",
                    "var": false
                  },
                  {
                    "categoryId": 2653,
                    "children": [],
                    "leaf": true,
                    "name": "Rain Coats",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Hiking Apparel",
                "var": false
              },
              {
                "categoryId": 9950,
                "children": [
                  {
                    "categoryId": 9954,
                    "children": [],
                    "leaf": true,
                    "name": "Emergency Light Sticks",
                    "var": false
                  },
                  {
                    "categoryId": 9951,
                    "children": [],
                    "leaf": true,
                    "name": "Flashlights",
                    "var": false
                  },
                  {
                    "categoryId": 9953,
                    "children": [],
                    "leaf": true,
                    "name": "Headlamps",
                    "var": false
                  },
                  {
                    "categoryId": 9952,
                    "children": [],
                    "leaf": true,
                    "name": "Lanterns",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Lighting",
                "var": false
              },
              {
                "categoryId": 9955,
                "children": [],
                "leaf": true,
                "name": "Navigation & Electronics",
                "var": false
              },
              {
                "categoryId": 9939,
                "children": [],
                "leaf": true,
                "name": "Shelters & Canopies",
                "var": false
              },
              {
                "categoryId": 2641,
                "children": [
                  {
                    "categoryId": 2643,
                    "children": [],
                    "leaf": true,
                    "name": "Air Mattresses",
                    "var": false
                  },
                  {
                    "categoryId": 9932,
                    "children": [],
                    "leaf": true,
                    "name": "Cots",
                    "var": false
                  },
                  {
                    "categoryId": 9933,
                    "children": [],
                    "leaf": true,
                    "name": "Hammocks",
                    "var": false
                  },
                  {
                    "categoryId": 2645,
                    "children": [],
                    "leaf": true,
                    "name": "Pillows & Accessories",
                    "var": false
                  },
                  {
                    "categoryId": 2642,
                    "children": [],
                    "leaf": true,
                    "name": "Sleeping Bags",
                    "var": true
                  },
                  {
                    "categoryId": 2644,
                    "children": [],
                    "leaf": true,
                    "name": "Sleeping Pads",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Sleeping Gear",
                "var": false
              },
              {
                "categoryId": 9958,
                "children": [],
                "leaf": true,
                "name": "Survival kits",
                "var": false
              },
              {
                "categoryId": 2655,
                "children": [],
                "leaf": true,
                "name": "Tents",
                "var": false
              },
              {
                "categoryId": 9956,
                "children": [],
                "leaf": true,
                "name": "Trekking Poles",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Camping & Hiking",
            "var": false
          },
          {
            "categoryId": 2764,
            "children": [
              {
                "categoryId": 10022,
                "children": [],
                "leaf": true,
                "name": "Belay Devices",
                "var": false
              },
              {
                "categoryId": 10023,
                "children": [],
                "leaf": true,
                "name": "Carabiners & Quickdraws",
                "var": false
              },
              {
                "categoryId": 10024,
                "children": [],
                "leaf": true,
                "name": "Chalk & Bags",
                "var": false
              },
              {
                "categoryId": 10025,
                "children": [],
                "leaf": true,
                "name": "Climbing Gloves",
                "var": false
              },
              {
                "categoryId": 10027,
                "children": [],
                "leaf": true,
                "name": "Climbing Holds",
                "var": false
              },
              {
                "categoryId": 10026,
                "children": [],
                "leaf": true,
                "name": "Crash Pads",
                "var": false
              },
              {
                "categoryId": 10021,
                "children": [],
                "leaf": true,
                "name": "Harnesses",
                "var": false
              },
              {
                "categoryId": 10020,
                "children": [],
                "leaf": true,
                "name": "Ropes, Cords & Slings",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Climbing",
            "var": false
          },
          {
            "categoryId": 2661,
            "children": [
              {
                "categoryId": 2674,
                "children": [
                  {
                    "categoryId": 2706,
                    "children": [],
                    "leaf": true,
                    "name": "Air Pumps",
                    "var": false
                  },
                  {
                    "categoryId": 2680,
                    "children": [],
                    "leaf": true,
                    "name": "Batteries",
                    "var": false
                  },
                  {
                    "categoryId": 2681,
                    "children": [],
                    "leaf": false,
                    "name": "Bells & Horns",
                    "var": false
                  },
                  {
                    "categoryId": 10000894,
                    "children": [],
                    "leaf": true,
                    "name": "Bike Computers",
                    "var": false
                  },
                  {
                    "categoryId": 2685,
                    "children": [],
                    "leaf": true,
                    "name": "Bike Storage",
                    "var": false
                  },
                  {
                    "categoryId": 2686,
                    "children": [],
                    "leaf": true,
                    "name": "Child Seats",
                    "var": false
                  },
                  {
                    "categoryId": 9963,
                    "children": [],
                    "leaf": true,
                    "name": "Covers & Cases",
                    "var": false
                  },
                  {
                    "categoryId": 9964,
                    "children": [],
                    "leaf": true,
                    "name": "Cycling Bags",
                    "var": false
                  },
                  {
                    "categoryId": 2693,
                    "children": [
                      {
                        "categoryId": 2696,
                        "children": [],
                        "leaf": true,
                        "name": "Body Armour & Pads",
                        "var": false
                      },
                      {
                        "categoryId": 2697,
                        "children": [],
                        "leaf": true,
                        "name": "First Aid Kits",
                        "var": false
                      },
                      {
                        "categoryId": 2694,
                        "children": [],
                        "leaf": true,
                        "name": "Helmets",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Helmets & Pads",
                    "var": false
                  },
                  {
                    "categoryId": 2700,
                    "children": [],
                    "leaf": true,
                    "name": "Lights & Reflectors",
                    "var": false
                  },
                  {
                    "categoryId": 2701,
                    "children": [],
                    "leaf": true,
                    "name": "Locks",
                    "var": false
                  },
                  {
                    "categoryId": 2702,
                    "children": [],
                    "leaf": true,
                    "name": "Mount Holders",
                    "var": false
                  },
                  {
                    "categoryId": 2705,
                    "children": [],
                    "leaf": true,
                    "name": "Mudguards",
                    "var": false
                  },
                  {
                    "categoryId": 2712,
                    "children": [],
                    "leaf": true,
                    "name": "Other",
                    "var": false
                  },
                  {
                    "categoryId": 2682,
                    "children": [],
                    "leaf": true,
                    "name": "Racks & Storage",
                    "var": false
                  },
                  {
                    "categoryId": 2711,
                    "children": [],
                    "leaf": true,
                    "name": "Water Bottles & Cages",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Bike Accessories",
                "var": false
              },
              {
                "categoryId": 2662,
                "children": [
                  {
                    "categoryId": 2664,
                    "children": [],
                    "leaf": true,
                    "name": "BMX",
                    "var": false
                  },
                  {
                    "categoryId": 2665,
                    "children": [],
                    "leaf": true,
                    "name": "Comfort & Cruiser Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 9960,
                    "children": [],
                    "leaf": true,
                    "name": "Electric Bicycles",
                    "var": false
                  },
                  {
                    "categoryId": 9959,
                    "children": [],
                    "leaf": true,
                    "name": "Fixed Gear Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 2666,
                    "children": [],
                    "leaf": true,
                    "name": "Folding Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 2667,
                    "children": [],
                    "leaf": true,
                    "name": "Hybrid Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 2673,
                    "children": [],
                    "leaf": true,
                    "name": "Kids Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 2668,
                    "children": [],
                    "leaf": true,
                    "name": "Mountain Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 2671,
                    "children": [],
                    "leaf": true,
                    "name": "Road Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 9962,
                    "children": [],
                    "leaf": true,
                    "name": "Tandem Bikes",
                    "var": false
                  },
                  {
                    "categoryId": 9961,
                    "children": [],
                    "leaf": true,
                    "name": "Unicycles",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Bikes",
                "var": false
              },
              {
                "categoryId": 2730,
                "children": [
                  {
                    "categoryId": 9972,
                    "children": [],
                    "leaf": true,
                    "name": "Baskets",
                    "var": false
                  },
                  {
                    "categoryId": 9973,
                    "children": [],
                    "leaf": true,
                    "name": "Bells & Horns",
                    "var": false
                  },
                  {
                    "categoryId": 9965,
                    "children": [],
                    "leaf": true,
                    "name": "Frames",
                    "var": false
                  },
                  {
                    "categoryId": 9966,
                    "children": [],
                    "leaf": true,
                    "name": "Handlebars",
                    "var": false
                  },
                  {
                    "categoryId": 9974,
                    "children": [],
                    "leaf": true,
                    "name": "Mirrors",
                    "var": false
                  },
                  {
                    "categoryId": 9975,
                    "children": [],
                    "leaf": true,
                    "name": "Other Bike parts",
                    "var": false
                  },
                  {
                    "categoryId": 9967,
                    "children": [],
                    "leaf": true,
                    "name": "Pedals",
                    "var": false
                  },
                  {
                    "categoryId": 10000896,
                    "children": [],
                    "leaf": true,
                    "name": "Saddle Covers",
                    "var": false
                  },
                  {
                    "categoryId": 10000895,
                    "children": [],
                    "leaf": true,
                    "name": "Saddles",
                    "var": false
                  },
                  {
                    "categoryId": 9971,
                    "children": [],
                    "leaf": true,
                    "name": "Tires",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Bikes Parts",
                "var": false
              },
              {
                "categoryId": 2713,
                "children": [
                  {
                    "categoryId": 2719,
                    "children": [],
                    "leaf": true,
                    "name": "Glasses & Goggles",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Cycling Apparel",
                "var": false
              },
              {
                "categoryId": 10000837,
                "children": [
                  {
                    "categoryId": 10000899,
                    "children": [],
                    "leaf": true,
                    "name": "Bike Helmets",
                    "var": false
                  },
                  {
                    "categoryId": 10000897,
                    "children": [],
                    "leaf": true,
                    "name": "Cycling Gloves",
                    "var": false
                  },
                  {
                    "categoryId": 10000898,
                    "children": [],
                    "leaf": true,
                    "name": "Face Mask",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Cycling Clothing",
                "var": false
              },
              {
                "categoryId": 10014,
                "children": [
                  {
                    "categoryId": 10016,
                    "children": [],
                    "leaf": true,
                    "name": "Arm Sleeves",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Cycling Shoes & Clothing",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cycling",
            "var": false
          },
          {
            "categoryId": 2921,
            "children": [
              {
                "categoryId": 10048,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10043,
                "children": [],
                "leaf": true,
                "name": "Fishing Lines",
                "var": false
              },
              {
                "categoryId": 10047,
                "children": [],
                "leaf": true,
                "name": "Fishing Nets",
                "var": false
              },
              {
                "categoryId": 10041,
                "children": [],
                "leaf": true,
                "name": "Fishing Reels",
                "var": false
              },
              {
                "categoryId": 10042,
                "children": [],
                "leaf": true,
                "name": "Fishing Rod & Reel Sets",
                "var": false
              },
              {
                "categoryId": 10040,
                "children": [],
                "leaf": true,
                "name": "Fishing Rods",
                "var": false
              },
              {
                "categoryId": 10045,
                "children": [],
                "leaf": true,
                "name": "Fishing Tackles",
                "var": false
              },
              {
                "categoryId": 10046,
                "children": [],
                "leaf": true,
                "name": "GPS & Fishfinders",
                "var": false
              },
              {
                "categoryId": 10044,
                "children": [],
                "leaf": true,
                "name": "Lures & Baits",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Fishing",
            "var": false
          },
          {
            "categoryId": 9976,
            "children": [
              {
                "categoryId": 9989,
                "children": [
                  {
                    "categoryId": 9992,
                    "children": [],
                    "leaf": true,
                    "name": "Ball markers",
                    "var": false
                  },
                  {
                    "categoryId": 9993,
                    "children": [],
                    "leaf": true,
                    "name": "Ball retrievers",
                    "var": false
                  },
                  {
                    "categoryId": 9994,
                    "children": [],
                    "leaf": true,
                    "name": "Divot tools",
                    "var": false
                  },
                  {
                    "categoryId": 9990,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Carts",
                    "var": false
                  },
                  {
                    "categoryId": 9995,
                    "children": [],
                    "leaf": true,
                    "name": "Headcovers",
                    "var": false
                  },
                  {
                    "categoryId": 9991,
                    "children": [],
                    "leaf": true,
                    "name": "Tees",
                    "var": false
                  },
                  {
                    "categoryId": 9996,
                    "children": [],
                    "leaf": true,
                    "name": "Towels",
                    "var": false
                  },
                  {
                    "categoryId": 9997,
                    "children": [],
                    "leaf": true,
                    "name": "Umbrellas",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 9987,
                "children": [],
                "leaf": true,
                "name": "Bags & Covers",
                "var": false
              },
              {
                "categoryId": 9986,
                "children": [],
                "leaf": true,
                "name": "Balls",
                "var": false
              },
              {
                "categoryId": 9977,
                "children": [
                  {
                    "categoryId": 9985,
                    "children": [],
                    "leaf": true,
                    "name": "Chippers",
                    "var": false
                  },
                  {
                    "categoryId": 9984,
                    "children": [],
                    "leaf": true,
                    "name": "Complete Sets",
                    "var": false
                  },
                  {
                    "categoryId": 9982,
                    "children": [],
                    "leaf": true,
                    "name": "Drivers",
                    "var": false
                  },
                  {
                    "categoryId": 9983,
                    "children": [],
                    "leaf": true,
                    "name": "Fairway Woods",
                    "var": false
                  },
                  {
                    "categoryId": 9981,
                    "children": [],
                    "leaf": true,
                    "name": "Hybrids",
                    "var": false
                  },
                  {
                    "categoryId": 9978,
                    "children": [],
                    "leaf": true,
                    "name": "Irons",
                    "var": false
                  },
                  {
                    "categoryId": 9980,
                    "children": [],
                    "leaf": true,
                    "name": "Putters",
                    "var": false
                  },
                  {
                    "categoryId": 9979,
                    "children": [],
                    "leaf": true,
                    "name": "Wedges",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Clubs",
                "var": false
              },
              {
                "categoryId": 9988,
                "children": [],
                "leaf": true,
                "name": "GPS & Range finder",
                "var": false
              },
              {
                "categoryId": 9998,
                "children": [],
                "leaf": true,
                "name": "Gloves",
                "var": false
              },
              {
                "categoryId": 9999,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Golf",
            "var": false
          },
          {
            "categoryId": 2849,
            "children": [
              {
                "categoryId": 10000840,
                "children": [],
                "leaf": true,
                "name": "Helmets",
                "var": false
              },
              {
                "categoryId": 10036,
                "children": [],
                "leaf": true,
                "name": "Ice Skates",
                "var": false
              },
              {
                "categoryId": 2852,
                "children": [],
                "leaf": true,
                "name": "Inline Skates",
                "var": false
              },
              {
                "categoryId": 10000841,
                "children": [],
                "leaf": true,
                "name": "Protective Pads",
                "var": false
              },
              {
                "categoryId": 4815,
                "children": [],
                "leaf": true,
                "name": "Roller Skates",
                "var": false
              },
              {
                "categoryId": 4816,
                "children": [],
                "leaf": true,
                "name": "Skates Parts",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Inline & Roller Skates",
            "var": false
          },
          {
            "categoryId": 2786,
            "children": [
              {
                "categoryId": 10029,
                "children": [],
                "leaf": true,
                "name": "Electric Scooters",
                "var": false
              },
              {
                "categoryId": 10031,
                "children": [],
                "leaf": true,
                "name": "Electric Unicycles",
                "var": false
              },
              {
                "categoryId": 10028,
                "children": [],
                "leaf": true,
                "name": "Kick Scooters",
                "var": false
              },
              {
                "categoryId": 10030,
                "children": [],
                "leaf": true,
                "name": "Self Balancing Scooters",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Scooters",
            "var": false
          },
          {
            "categoryId": 10000,
            "children": [
              {
                "categoryId": 10002,
                "children": [],
                "leaf": false,
                "name": "Air Guns",
                "var": false
              },
              {
                "categoryId": 10005,
                "children": [],
                "leaf": false,
                "name": "Airsoft Guns",
                "var": false
              },
              {
                "categoryId": 10013,
                "children": [],
                "leaf": true,
                "name": "Archery",
                "var": false
              },
              {
                "categoryId": 10008,
                "children": [],
                "leaf": true,
                "name": "Optics",
                "var": false
              },
              {
                "categoryId": 10009,
                "children": [
                  {
                    "categoryId": 10010,
                    "children": [],
                    "leaf": true,
                    "name": "Guns",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Paintball",
                "var": false
              },
              {
                "categoryId": 10001,
                "children": [],
                "leaf": true,
                "name": "Protective gear",
                "var": false
              },
              {
                "categoryId": 10012,
                "children": [],
                "leaf": true,
                "name": "Slingshots",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Shooting",
            "var": false
          },
          {
            "categoryId": 2810,
            "children": [
              {
                "categoryId": 2811,
                "children": [],
                "leaf": false,
                "name": "Helmets",
                "var": false
              },
              {
                "categoryId": 10000839,
                "children": [],
                "leaf": true,
                "name": "Protective Pads",
                "var": false
              },
              {
                "categoryId": 10032,
                "children": [],
                "leaf": true,
                "name": "Ramps & Rails",
                "var": false
              },
              {
                "categoryId": 2826,
                "children": [],
                "leaf": true,
                "name": "Skateboard Parts",
                "var": false
              },
              {
                "categoryId": 2842,
                "children": [],
                "leaf": true,
                "name": "Skateboards",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Skateboards",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Outdoor Recreation",
        "var": false
      },
      {
        "categoryId": 2059,
        "children": [
          {
            "categoryId": 2153,
            "children": [
              {
                "categoryId": 2176,
                "children": [],
                "leaf": true,
                "name": "Bags & Covers",
                "var": false
              },
              {
                "categoryId": 9889,
                "children": [],
                "leaf": true,
                "name": "Grips & Tapes",
                "var": false
              },
              {
                "categoryId": 2178,
                "children": [],
                "leaf": true,
                "name": "Nets",
                "var": false
              },
              {
                "categoryId": 2173,
                "children": [],
                "leaf": true,
                "name": "Rackets",
                "var": false
              },
              {
                "categoryId": 9887,
                "children": [],
                "leaf": true,
                "name": "Shuttlecocks",
                "var": false
              },
              {
                "categoryId": 10000832,
                "children": [],
                "leaf": true,
                "name": "Strings",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Badminton",
            "var": false
          },
          {
            "categoryId": 2375,
            "children": [
              {
                "categoryId": 2384,
                "children": [],
                "leaf": true,
                "name": "Bags & Covers",
                "var": false
              },
              {
                "categoryId": 2376,
                "children": [],
                "leaf": true,
                "name": "Balls",
                "var": false
              },
              {
                "categoryId": 2380,
                "children": [],
                "leaf": true,
                "name": "Rackets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Squash",
            "var": false
          },
          {
            "categoryId": 2123,
            "children": [
              {
                "categoryId": 2139,
                "children": [],
                "leaf": true,
                "name": "Bags & Covers",
                "var": false
              },
              {
                "categoryId": 2130,
                "children": [],
                "leaf": true,
                "name": "Balls",
                "var": false
              },
              {
                "categoryId": 2141,
                "children": [],
                "leaf": true,
                "name": "Bats",
                "var": false
              },
              {
                "categoryId": 2132,
                "children": [],
                "leaf": true,
                "name": "Nets",
                "var": false
              },
              {
                "categoryId": 2148,
                "children": [],
                "leaf": true,
                "name": "Tables",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Table Tennis",
            "var": false
          },
          {
            "categoryId": 2060,
            "children": [
              {
                "categoryId": 2103,
                "children": [],
                "leaf": true,
                "name": "Balls",
                "var": false
              },
              {
                "categoryId": 9885,
                "children": [],
                "leaf": true,
                "name": "Court Equipment",
                "var": false
              },
              {
                "categoryId": 2107,
                "children": [],
                "leaf": true,
                "name": "Equipment Bags",
                "var": false
              },
              {
                "categoryId": 2061,
                "children": [],
                "leaf": true,
                "name": "Grips & Tapes",
                "var": false
              },
              {
                "categoryId": 2110,
                "children": [],
                "leaf": true,
                "name": "Rackets",
                "var": false
              },
              {
                "categoryId": 10000831,
                "children": [],
                "leaf": true,
                "name": "Strings",
                "var": false
              },
              {
                "categoryId": 2117,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Tennis",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Racket Sports",
        "var": false
      },
      {
        "categoryId": 4645,
        "children": [
          {
            "categoryId": 4875,
            "children": [
              {
                "categoryId": 10218,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": true
              },
              {
                "categoryId": 10000847,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 4878,
                "children": [
                  {
                    "categoryId": 10206,
                    "children": [],
                    "leaf": true,
                    "name": "Compression",
                    "var": true
                  },
                  {
                    "categoryId": 10000932,
                    "children": [],
                    "leaf": true,
                    "name": "Hoodies",
                    "var": true
                  },
                  {
                    "categoryId": 10215,
                    "children": [],
                    "leaf": true,
                    "name": "Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10208,
                    "children": [
                      {
                        "categoryId": 10000953,
                        "children": [],
                        "leaf": true,
                        "name": "Basketball",
                        "var": true
                      },
                      {
                        "categoryId": 10000952,
                        "children": [],
                        "leaf": true,
                        "name": "Football",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Jerseys",
                    "var": true
                  },
                  {
                    "categoryId": 10212,
                    "children": [],
                    "leaf": true,
                    "name": "Pants",
                    "var": true
                  },
                  {
                    "categoryId": 10214,
                    "children": [],
                    "leaf": true,
                    "name": "Shorts",
                    "var": true
                  },
                  {
                    "categoryId": 10216,
                    "children": [],
                    "leaf": true,
                    "name": "Swimwear",
                    "var": true
                  },
                  {
                    "categoryId": 10210,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts & Tops",
                    "var": true
                  },
                  {
                    "categoryId": 10217,
                    "children": [],
                    "leaf": true,
                    "name": "Uniforms",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 4876,
                "children": [
                  {
                    "categoryId": 10148,
                    "children": [],
                    "leaf": true,
                    "name": "Badminton Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10150,
                    "children": [],
                    "leaf": true,
                    "name": "Basketball Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10152,
                    "children": [],
                    "leaf": true,
                    "name": "Cycling Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10168,
                    "children": [],
                    "leaf": true,
                    "name": "Football Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10170,
                    "children": [],
                    "leaf": true,
                    "name": "Futsal Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10171,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10172,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10173,
                    "children": [],
                    "leaf": true,
                    "name": "Running Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10174,
                    "children": [],
                    "leaf": true,
                    "name": "Skateboard Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10200,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sandals",
                    "var": true
                  },
                  {
                    "categoryId": 10198,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sneakers",
                    "var": true
                  },
                  {
                    "categoryId": 10203,
                    "children": [],
                    "leaf": true,
                    "name": "Tennis Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10166,
                    "children": [],
                    "leaf": true,
                    "name": "Training Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10204,
                    "children": [],
                    "leaf": true,
                    "name": "Walking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10205,
                    "children": [],
                    "leaf": true,
                    "name": "Water Shoes",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Boys",
            "var": false
          },
          {
            "categoryId": 4871,
            "children": [
              {
                "categoryId": 10145,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": true
              },
              {
                "categoryId": 10000846,
                "children": [],
                "leaf": true,
                "name": "Bags",
                "var": true
              },
              {
                "categoryId": 4874,
                "children": [
                  {
                    "categoryId": 10132,
                    "children": [],
                    "leaf": true,
                    "name": "Compression",
                    "var": true
                  },
                  {
                    "categoryId": 10139,
                    "children": [],
                    "leaf": true,
                    "name": "Dresses",
                    "var": true
                  },
                  {
                    "categoryId": 10000930,
                    "children": [],
                    "leaf": true,
                    "name": "Hoodies",
                    "var": true
                  },
                  {
                    "categoryId": 10138,
                    "children": [],
                    "leaf": true,
                    "name": "Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10000931,
                    "children": [
                      {
                        "categoryId": 10000951,
                        "children": [],
                        "leaf": true,
                        "name": "Basketball",
                        "var": true
                      },
                      {
                        "categoryId": 10000950,
                        "children": [],
                        "leaf": true,
                        "name": "Football",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Jerseys",
                    "var": false
                  },
                  {
                    "categoryId": 10136,
                    "children": [],
                    "leaf": true,
                    "name": "Pants",
                    "var": true
                  },
                  {
                    "categoryId": 10137,
                    "children": [],
                    "leaf": true,
                    "name": "Shorts",
                    "var": true
                  },
                  {
                    "categoryId": 10140,
                    "children": [],
                    "leaf": true,
                    "name": "Skirts",
                    "var": true
                  },
                  {
                    "categoryId": 10133,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Bras",
                    "var": true
                  },
                  {
                    "categoryId": 10141,
                    "children": [],
                    "leaf": true,
                    "name": "Swimwear",
                    "var": true
                  },
                  {
                    "categoryId": 10134,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts & Tops",
                    "var": true
                  },
                  {
                    "categoryId": 10143,
                    "children": [],
                    "leaf": true,
                    "name": "Uniforms",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 4872,
                "children": [
                  {
                    "categoryId": 10099,
                    "children": [],
                    "leaf": true,
                    "name": "Badminton Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10000929,
                    "children": [],
                    "leaf": true,
                    "name": "Ballet & Dance",
                    "var": true
                  },
                  {
                    "categoryId": 10102,
                    "children": [],
                    "leaf": true,
                    "name": "Basketball Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10104,
                    "children": [],
                    "leaf": true,
                    "name": "Cycling Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10110,
                    "children": [],
                    "leaf": true,
                    "name": "Football Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10112,
                    "children": [],
                    "leaf": true,
                    "name": "Futsal Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10114,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10116,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10118,
                    "children": [],
                    "leaf": true,
                    "name": "Running Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10120,
                    "children": [],
                    "leaf": true,
                    "name": "Skateboard Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10122,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sandals",
                    "var": true
                  },
                  {
                    "categoryId": 10121,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sneakers",
                    "var": true
                  },
                  {
                    "categoryId": 10124,
                    "children": [],
                    "leaf": true,
                    "name": "Tennis Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10105,
                    "children": [],
                    "leaf": true,
                    "name": "Training Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10125,
                    "children": [],
                    "leaf": true,
                    "name": "Walking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10130,
                    "children": [],
                    "leaf": true,
                    "name": "Water Shoes",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Girls",
            "var": false
          },
          {
            "categoryId": 4844,
            "children": [
              {
                "categoryId": 10000844,
                "children": [
                  {
                    "categoryId": 10000924,
                    "children": [],
                    "leaf": true,
                    "name": "Belts",
                    "var": true
                  },
                  {
                    "categoryId": 10000917,
                    "children": [],
                    "leaf": true,
                    "name": "Hats & Caps",
                    "var": true
                  },
                  {
                    "categoryId": 10000921,
                    "children": [],
                    "leaf": true,
                    "name": "Headbands",
                    "var": true
                  },
                  {
                    "categoryId": 10000919,
                    "children": [],
                    "leaf": true,
                    "name": "Sleeves",
                    "var": true
                  },
                  {
                    "categoryId": 10000923,
                    "children": [],
                    "leaf": true,
                    "name": "Socks",
                    "var": true
                  },
                  {
                    "categoryId": 10000918,
                    "children": [],
                    "leaf": true,
                    "name": "Sunglasses",
                    "var": true
                  },
                  {
                    "categoryId": 10000922,
                    "children": [],
                    "leaf": true,
                    "name": "Underwears",
                    "var": true
                  },
                  {
                    "categoryId": 10000920,
                    "children": [],
                    "leaf": true,
                    "name": "Wristbands",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10000845,
                "children": [
                  {
                    "categoryId": 10000926,
                    "children": [],
                    "leaf": true,
                    "name": "Drawstring Bags",
                    "var": true
                  },
                  {
                    "categoryId": 10000928,
                    "children": [],
                    "leaf": true,
                    "name": "Gym Totes",
                    "var": true
                  },
                  {
                    "categoryId": 10000925,
                    "children": [],
                    "leaf": true,
                    "name": "Sport Backpacks",
                    "var": true
                  },
                  {
                    "categoryId": 10000927,
                    "children": [],
                    "leaf": true,
                    "name": "Sport Duffels",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Bags",
                "var": false
              },
              {
                "categoryId": 4846,
                "children": [
                  {
                    "categoryId": 10090,
                    "children": [],
                    "leaf": true,
                    "name": "Compression",
                    "var": true
                  },
                  {
                    "categoryId": 10000915,
                    "children": [],
                    "leaf": true,
                    "name": "Hoodies",
                    "var": true
                  },
                  {
                    "categoryId": 4862,
                    "children": [],
                    "leaf": true,
                    "name": "Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10091,
                    "children": [
                      {
                        "categoryId": 10093,
                        "children": [],
                        "leaf": true,
                        "name": "Basketball",
                        "var": true
                      },
                      {
                        "categoryId": 10095,
                        "children": [],
                        "leaf": true,
                        "name": "Cycling",
                        "var": true
                      },
                      {
                        "categoryId": 10092,
                        "children": [],
                        "leaf": true,
                        "name": "Football",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Jerseys",
                    "var": false
                  },
                  {
                    "categoryId": 4859,
                    "children": [],
                    "leaf": true,
                    "name": "Pants",
                    "var": true
                  },
                  {
                    "categoryId": 10000916,
                    "children": [],
                    "leaf": true,
                    "name": "Rashguards",
                    "var": true
                  },
                  {
                    "categoryId": 10096,
                    "children": [],
                    "leaf": true,
                    "name": "Shorts",
                    "var": true
                  },
                  {
                    "categoryId": 4864,
                    "children": [
                      {
                        "categoryId": 4867,
                        "children": [],
                        "leaf": true,
                        "name": "Swim Shorts",
                        "var": true
                      },
                      {
                        "categoryId": 4866,
                        "children": [],
                        "leaf": true,
                        "name": "Swim Trunks",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Swimwear",
                    "var": false
                  },
                  {
                    "categoryId": 4861,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts & Tops",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 4845,
                "children": [
                  {
                    "categoryId": 10074,
                    "children": [],
                    "leaf": true,
                    "name": "Badminton Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4851,
                    "children": [],
                    "leaf": true,
                    "name": "Basketball Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4856,
                    "children": [],
                    "leaf": true,
                    "name": "Cycling Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4854,
                    "children": [],
                    "leaf": true,
                    "name": "Football Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4850,
                    "children": [],
                    "leaf": true,
                    "name": "Futsal Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4849,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4857,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4847,
                    "children": [],
                    "leaf": true,
                    "name": "Running Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10076,
                    "children": [],
                    "leaf": true,
                    "name": "Skateboard Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10078,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sandals",
                    "var": true
                  },
                  {
                    "categoryId": 10077,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sneakers",
                    "var": true
                  },
                  {
                    "categoryId": 10079,
                    "children": [],
                    "leaf": true,
                    "name": "Tennis Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10075,
                    "children": [],
                    "leaf": true,
                    "name": "Training Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10080,
                    "children": [],
                    "leaf": true,
                    "name": "Walking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4855,
                    "children": [],
                    "leaf": true,
                    "name": "Water Shoes",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Men",
            "var": false
          },
          {
            "categoryId": 10000818,
            "children": [
              {
                "categoryId": 10000850,
                "children": [
                  {
                    "categoryId": 10000936,
                    "children": [],
                    "leaf": true,
                    "name": "Caps & Hats",
                    "var": false
                  },
                  {
                    "categoryId": 10000940,
                    "children": [],
                    "leaf": true,
                    "name": "Headbands",
                    "var": true
                  },
                  {
                    "categoryId": 10000938,
                    "children": [],
                    "leaf": true,
                    "name": "Sleeves",
                    "var": true
                  },
                  {
                    "categoryId": 10000941,
                    "children": [],
                    "leaf": true,
                    "name": "Socks",
                    "var": true
                  },
                  {
                    "categoryId": 10000937,
                    "children": [],
                    "leaf": true,
                    "name": "Sunglasses",
                    "var": false
                  },
                  {
                    "categoryId": 10000939,
                    "children": [],
                    "leaf": true,
                    "name": "Wristbands",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10000851,
                "children": [
                  {
                    "categoryId": 10000943,
                    "children": [],
                    "leaf": true,
                    "name": "Drawstring Bags",
                    "var": false
                  },
                  {
                    "categoryId": 10000945,
                    "children": [],
                    "leaf": true,
                    "name": "Gym Totes",
                    "var": false
                  },
                  {
                    "categoryId": 10000942,
                    "children": [],
                    "leaf": true,
                    "name": "Sport Backpacks",
                    "var": false
                  },
                  {
                    "categoryId": 10000944,
                    "children": [],
                    "leaf": true,
                    "name": "Sport Duffels",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Bags",
                "var": false
              },
              {
                "categoryId": 10000849,
                "children": [
                  {
                    "categoryId": 10000935,
                    "children": [],
                    "leaf": true,
                    "name": "Hoodies",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 10000848,
                "children": [
                  {
                    "categoryId": 10000933,
                    "children": [],
                    "leaf": true,
                    "name": "Skateboard Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10000934,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sneakers",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Unisex",
            "var": false
          },
          {
            "categoryId": 4811,
            "children": [
              {
                "categoryId": 10000842,
                "children": [
                  {
                    "categoryId": 10000910,
                    "children": [],
                    "leaf": true,
                    "name": "Belts",
                    "var": true
                  },
                  {
                    "categoryId": 10000903,
                    "children": [],
                    "leaf": true,
                    "name": "Hats & Caps",
                    "var": true
                  },
                  {
                    "categoryId": 10000907,
                    "children": [],
                    "leaf": true,
                    "name": "Headbands",
                    "var": true
                  },
                  {
                    "categoryId": 10000905,
                    "children": [],
                    "leaf": true,
                    "name": "Sleeves",
                    "var": true
                  },
                  {
                    "categoryId": 10000909,
                    "children": [],
                    "leaf": true,
                    "name": "Socks",
                    "var": true
                  },
                  {
                    "categoryId": 10000904,
                    "children": [],
                    "leaf": true,
                    "name": "Sunglasses",
                    "var": true
                  },
                  {
                    "categoryId": 10000908,
                    "children": [],
                    "leaf": true,
                    "name": "Underwears",
                    "var": true
                  },
                  {
                    "categoryId": 10000906,
                    "children": [],
                    "leaf": true,
                    "name": "Wristbands",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10000843,
                "children": [
                  {
                    "categoryId": 10000912,
                    "children": [],
                    "leaf": true,
                    "name": "Drawstring Bags",
                    "var": true
                  },
                  {
                    "categoryId": 10000914,
                    "children": [],
                    "leaf": true,
                    "name": "Gym Totes",
                    "var": true
                  },
                  {
                    "categoryId": 10000911,
                    "children": [],
                    "leaf": true,
                    "name": "Sport Backpacks",
                    "var": true
                  },
                  {
                    "categoryId": 10000913,
                    "children": [],
                    "leaf": true,
                    "name": "Sport Duffels",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Bags",
                "var": false
              },
              {
                "categoryId": 4820,
                "children": [
                  {
                    "categoryId": 10066,
                    "children": [],
                    "leaf": true,
                    "name": "Compression",
                    "var": true
                  },
                  {
                    "categoryId": 10000900,
                    "children": [],
                    "leaf": true,
                    "name": "Hoodies",
                    "var": true
                  },
                  {
                    "categoryId": 4830,
                    "children": [],
                    "leaf": true,
                    "name": "Jackets",
                    "var": true
                  },
                  {
                    "categoryId": 10067,
                    "children": [
                      {
                        "categoryId": 10069,
                        "children": [],
                        "leaf": true,
                        "name": "Basketball",
                        "var": true
                      },
                      {
                        "categoryId": 10070,
                        "children": [],
                        "leaf": true,
                        "name": "Cycling",
                        "var": true
                      },
                      {
                        "categoryId": 10068,
                        "children": [],
                        "leaf": true,
                        "name": "Football",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Jerseys",
                    "var": false
                  },
                  {
                    "categoryId": 4822,
                    "children": [],
                    "leaf": true,
                    "name": "Pants",
                    "var": true
                  },
                  {
                    "categoryId": 10000901,
                    "children": [],
                    "leaf": true,
                    "name": "Rashguards",
                    "var": true
                  },
                  {
                    "categoryId": 10072,
                    "children": [],
                    "leaf": true,
                    "name": "Shorts",
                    "var": true
                  },
                  {
                    "categoryId": 10073,
                    "children": [],
                    "leaf": true,
                    "name": "Skirts & Dresses",
                    "var": true
                  },
                  {
                    "categoryId": 10071,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Bras",
                    "var": true
                  },
                  {
                    "categoryId": 10000902,
                    "children": [],
                    "leaf": true,
                    "name": "Swimwear",
                    "var": true
                  },
                  {
                    "categoryId": 4826,
                    "children": [],
                    "leaf": true,
                    "name": "T-Shirts & Tops",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Clothing",
                "var": false
              },
              {
                "categoryId": 4812,
                "children": [
                  {
                    "categoryId": 4825,
                    "children": [],
                    "leaf": true,
                    "name": "Badminton Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4821,
                    "children": [],
                    "leaf": true,
                    "name": "Basketball Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4831,
                    "children": [],
                    "leaf": true,
                    "name": "Cycling Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4827,
                    "children": [],
                    "leaf": true,
                    "name": "Football Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4819,
                    "children": [],
                    "leaf": true,
                    "name": "Futsal Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4818,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4832,
                    "children": [],
                    "leaf": true,
                    "name": "Hiking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4813,
                    "children": [],
                    "leaf": true,
                    "name": "Running Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10050,
                    "children": [],
                    "leaf": true,
                    "name": "Skateboard Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10052,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sandals",
                    "var": true
                  },
                  {
                    "categoryId": 10051,
                    "children": [],
                    "leaf": true,
                    "name": "Sports Sneakers",
                    "var": true
                  },
                  {
                    "categoryId": 10053,
                    "children": [],
                    "leaf": true,
                    "name": "Tennis Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10049,
                    "children": [],
                    "leaf": true,
                    "name": "Training Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 10054,
                    "children": [],
                    "leaf": true,
                    "name": "Walking Shoes",
                    "var": true
                  },
                  {
                    "categoryId": 4829,
                    "children": [],
                    "leaf": true,
                    "name": "Water Shoes",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Shoes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Women",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Shoes & Clothing",
        "var": false
      },
      {
        "categoryId": 2394,
        "children": [
          {
            "categoryId": 9912,
            "children": [
              {
                "categoryId": 9915,
                "children": [],
                "leaf": true,
                "name": "Baseballs",
                "var": false
              },
              {
                "categoryId": 9913,
                "children": [],
                "leaf": true,
                "name": "Bats",
                "var": false
              },
              {
                "categoryId": 9916,
                "children": [],
                "leaf": true,
                "name": "Batting Gloves",
                "var": false
              },
              {
                "categoryId": 9919,
                "children": [],
                "leaf": true,
                "name": "Equipment Bags",
                "var": false
              },
              {
                "categoryId": 9918,
                "children": [],
                "leaf": true,
                "name": "Protective Gear",
                "var": false
              },
              {
                "categoryId": 9917,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Baseball",
            "var": false
          },
          {
            "categoryId": 2457,
            "children": [
              {
                "categoryId": 9900,
                "children": [],
                "leaf": true,
                "name": "Basketball Hoops",
                "var": false
              },
              {
                "categoryId": 2497,
                "children": [],
                "leaf": true,
                "name": "Basketballs",
                "var": false
              },
              {
                "categoryId": 9906,
                "children": [],
                "leaf": true,
                "name": "Equipment Bags",
                "var": false
              },
              {
                "categoryId": 9902,
                "children": [],
                "leaf": true,
                "name": "Protective Gear",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Basketball",
            "var": false
          },
          {
            "categoryId": 10000817,
            "children": [
              {
                "categoryId": 10000836,
                "children": [],
                "leaf": true,
                "name": "Banners & Suppliers",
                "var": false
              },
              {
                "categoryId": 10000833,
                "children": [],
                "leaf": true,
                "name": "Mascot Costumes",
                "var": false
              },
              {
                "categoryId": 10000834,
                "children": [],
                "leaf": true,
                "name": "Megaphones",
                "var": false
              },
              {
                "categoryId": 10000835,
                "children": [],
                "leaf": true,
                "name": "Poms & Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cheerleading",
            "var": false
          },
          {
            "categoryId": 2556,
            "children": [
              {
                "categoryId": 2558,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 2557,
                "children": [],
                "leaf": true,
                "name": "Apparel",
                "var": false
              },
              {
                "categoryId": 2560,
                "children": [],
                "leaf": true,
                "name": "Balls",
                "var": false
              },
              {
                "categoryId": 2561,
                "children": [],
                "leaf": true,
                "name": "Bats",
                "var": false
              },
              {
                "categoryId": 2562,
                "children": [],
                "leaf": true,
                "name": "Gloves",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Cricket",
            "var": false
          },
          {
            "categoryId": 2395,
            "children": [
              {
                "categoryId": 9898,
                "children": [],
                "leaf": true,
                "name": "Equipment Bags",
                "var": false
              },
              {
                "categoryId": 9899,
                "children": [],
                "leaf": true,
                "name": "Fan shop & Collectibles",
                "var": false
              },
              {
                "categoryId": 5453,
                "children": [],
                "leaf": true,
                "name": "Footballs",
                "var": false
              },
              {
                "categoryId": 9890,
                "children": [],
                "leaf": true,
                "name": "Goalkeeper Gloves",
                "var": false
              },
              {
                "categoryId": 9892,
                "children": [],
                "leaf": true,
                "name": "Goals & Nets",
                "var": false
              },
              {
                "categoryId": 2445,
                "children": [
                  {
                    "categoryId": 2448,
                    "children": [
                      {
                        "categoryId": 2449,
                        "children": [],
                        "leaf": true,
                        "name": "Ankle Protecting Guards",
                        "var": true
                      }
                    ],
                    "leaf": false,
                    "name": "Shin Guards",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Protective Gear",
                "var": false
              },
              {
                "categoryId": 9891,
                "children": [],
                "leaf": true,
                "name": "Shin Guards",
                "var": false
              },
              {
                "categoryId": 9893,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Football",
            "var": false
          },
          {
            "categoryId": 9921,
            "children": [
              {
                "categoryId": 9922,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 9923,
                "children": [],
                "leaf": true,
                "name": "Mats & Flooring",
                "var": false
              },
              {
                "categoryId": 9924,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              },
              {
                "categoryId": 9925,
                "children": [],
                "leaf": true,
                "name": "Uniform",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Gymnastics",
            "var": false
          },
          {
            "categoryId": 9926,
            "children": [
              {
                "categoryId": 9928,
                "children": [],
                "leaf": true,
                "name": "Balls & Pucks",
                "var": false
              },
              {
                "categoryId": 9927,
                "children": [],
                "leaf": true,
                "name": "Hockey Sticks",
                "var": false
              },
              {
                "categoryId": 9929,
                "children": [],
                "leaf": true,
                "name": "Protective Gear",
                "var": false
              },
              {
                "categoryId": 9930,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Hockey",
            "var": false
          },
          {
            "categoryId": 9931,
            "children": [],
            "leaf": true,
            "name": "Lacrosse",
            "var": false
          },
          {
            "categoryId": 2569,
            "children": [],
            "leaf": true,
            "name": "Rugby",
            "var": false
          },
          {
            "categoryId": 2574,
            "children": [
              {
                "categoryId": 2577,
                "children": [],
                "leaf": true,
                "name": "Balls",
                "var": false
              },
              {
                "categoryId": 2578,
                "children": [],
                "leaf": true,
                "name": "Nets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Sepak Takraw",
            "var": false
          },
          {
            "categoryId": 2506,
            "children": [
              {
                "categoryId": 9910,
                "children": [],
                "leaf": true,
                "name": "Carts & Ball Racks",
                "var": false
              },
              {
                "categoryId": 9911,
                "children": [],
                "leaf": true,
                "name": "Equipment Bags",
                "var": false
              },
              {
                "categoryId": 9908,
                "children": [],
                "leaf": true,
                "name": "Nets & Accessories",
                "var": false
              },
              {
                "categoryId": 9909,
                "children": [],
                "leaf": true,
                "name": "Protective Gear",
                "var": false
              },
              {
                "categoryId": 2538,
                "children": [],
                "leaf": true,
                "name": "Training Equipment",
                "var": false
              },
              {
                "categoryId": 2546,
                "children": [],
                "leaf": true,
                "name": "Volleyballs",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Volleyball",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Team Sports",
        "var": false
      },
      {
        "categoryId": 10094,
        "children": [
          {
            "categoryId": 10182,
            "children": [
              {
                "categoryId": 10183,
                "children": [],
                "leaf": true,
                "name": "Dry Bags",
                "var": false
              },
              {
                "categoryId": 10185,
                "children": [],
                "leaf": true,
                "name": "Life Jackets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": false
          },
          {
            "categoryId": 10165,
            "children": [
              {
                "categoryId": 10180,
                "children": [],
                "leaf": true,
                "name": "Kitesurf",
                "var": false
              },
              {
                "categoryId": 10176,
                "children": [],
                "leaf": true,
                "name": "Surfboards",
                "var": false
              },
              {
                "categoryId": 10167,
                "children": [],
                "leaf": true,
                "name": "Wakeboards",
                "var": false
              },
              {
                "categoryId": 10179,
                "children": [],
                "leaf": true,
                "name": "Wakeskate",
                "var": false
              },
              {
                "categoryId": 10177,
                "children": [],
                "leaf": true,
                "name": "Water Ski",
                "var": false
              },
              {
                "categoryId": 10178,
                "children": [],
                "leaf": true,
                "name": "Windsurf",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Boarding",
            "var": false
          },
          {
            "categoryId": 10097,
            "children": [
              {
                "categoryId": 10103,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10098,
                "children": [],
                "leaf": true,
                "name": "Canoes & Kayaks",
                "var": false
              },
              {
                "categoryId": 10100,
                "children": [],
                "leaf": true,
                "name": "Inflatable Boats",
                "var": false
              },
              {
                "categoryId": 10101,
                "children": [],
                "leaf": true,
                "name": "Paddles",
                "var": false
              },
              {
                "categoryId": 10106,
                "children": [],
                "leaf": true,
                "name": "Racks & Storage",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Boating",
            "var": false
          },
          {
            "categoryId": 10107,
            "children": [
              {
                "categoryId": 10131,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10119,
                "children": [],
                "leaf": true,
                "name": "Dive Computers",
                "var": false
              },
              {
                "categoryId": 10123,
                "children": [],
                "leaf": true,
                "name": "Diving Flashlights",
                "var": false
              },
              {
                "categoryId": 10108,
                "children": [
                  {
                    "categoryId": 10117,
                    "children": [],
                    "leaf": true,
                    "name": "Diving Boots",
                    "var": false
                  },
                  {
                    "categoryId": 10113,
                    "children": [],
                    "leaf": true,
                    "name": "Diving Gloves",
                    "var": false
                  },
                  {
                    "categoryId": 10115,
                    "children": [],
                    "leaf": true,
                    "name": "Diving Hoods",
                    "var": false
                  },
                  {
                    "categoryId": 10109,
                    "children": [],
                    "leaf": true,
                    "name": "Wetsuits",
                    "var": true
                  }
                ],
                "leaf": false,
                "name": "Diving Suits",
                "var": false
              },
              {
                "categoryId": 10127,
                "children": [],
                "leaf": true,
                "name": "Fins",
                "var": false
              },
              {
                "categoryId": 10000858,
                "children": [
                  {
                    "categoryId": 10000947,
                    "children": [],
                    "leaf": true,
                    "name": "BCD",
                    "var": false
                  },
                  {
                    "categoryId": 10000949,
                    "children": [],
                    "leaf": true,
                    "name": "Gauge",
                    "var": false
                  },
                  {
                    "categoryId": 10000948,
                    "children": [],
                    "leaf": true,
                    "name": "Octopus",
                    "var": false
                  },
                  {
                    "categoryId": 10000946,
                    "children": [],
                    "leaf": true,
                    "name": "Regulators",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Instruments",
                "var": false
              },
              {
                "categoryId": 10126,
                "children": [],
                "leaf": true,
                "name": "Masks",
                "var": false
              },
              {
                "categoryId": 10129,
                "children": [],
                "leaf": true,
                "name": "Snorkeling Sets",
                "var": false
              },
              {
                "categoryId": 10128,
                "children": [],
                "leaf": true,
                "name": "Snorkels",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Diving & Snorkeling",
            "var": false
          },
          {
            "categoryId": 10135,
            "children": [
              {
                "categoryId": 10146,
                "children": [
                  {
                    "categoryId": 10147,
                    "children": [],
                    "leaf": true,
                    "name": "Earplugs",
                    "var": false
                  },
                  {
                    "categoryId": 10149,
                    "children": [],
                    "leaf": true,
                    "name": "Nose Clips",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10158,
                "children": [],
                "leaf": true,
                "name": "Floaties",
                "var": false
              },
              {
                "categoryId": 10142,
                "children": [],
                "leaf": true,
                "name": "Goggles",
                "var": false
              },
              {
                "categoryId": 10144,
                "children": [],
                "leaf": true,
                "name": "Swim Caps",
                "var": false
              },
              {
                "categoryId": 10159,
                "children": [],
                "leaf": false,
                "name": "Swimwear",
                "var": false
              },
              {
                "categoryId": 10151,
                "children": [
                  {
                    "categoryId": 10157,
                    "children": [],
                    "leaf": true,
                    "name": "Aquatic Gloves",
                    "var": false
                  },
                  {
                    "categoryId": 10153,
                    "children": [],
                    "leaf": true,
                    "name": "Hand Paddles",
                    "var": false
                  },
                  {
                    "categoryId": 10154,
                    "children": [],
                    "leaf": true,
                    "name": "Kickboards",
                    "var": false
                  },
                  {
                    "categoryId": 10155,
                    "children": [],
                    "leaf": true,
                    "name": "Swim Belts",
                    "var": false
                  },
                  {
                    "categoryId": 10156,
                    "children": [],
                    "leaf": true,
                    "name": "Training Fins",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Training Equipment",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Swimming",
            "var": false
          },
          {
            "categoryId": 10181,
            "children": [],
            "leaf": true,
            "name": "Tubing & Towables",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Water Sports",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Sports & Outdoors",
    "var": false
  },
  {
    "categoryId": 10000340,
    "children": [
      {
        "categoryId": 10000376,
        "children": [
          {
            "categoryId": 10000674,
            "children": [],
            "leaf": true,
            "name": "Art Easels",
            "var": false
          },
          {
            "categoryId": 10000673,
            "children": [],
            "leaf": true,
            "name": "Art Pads, Diaries & Folios",
            "var": false
          },
          {
            "categoryId": 10000671,
            "children": [],
            "leaf": true,
            "name": "Art Sets",
            "var": false
          },
          {
            "categoryId": 10000669,
            "children": [],
            "leaf": true,
            "name": "Artist Brushes & Tools",
            "var": false
          },
          {
            "categoryId": 10000670,
            "children": [],
            "leaf": true,
            "name": "Artist Inks & Paints",
            "var": false
          },
          {
            "categoryId": 10000672,
            "children": [],
            "leaf": true,
            "name": "Artist Mediums & Varnishes",
            "var": false
          },
          {
            "categoryId": 10000675,
            "children": [],
            "leaf": true,
            "name": "Book Coverings",
            "var": false
          },
          {
            "categoryId": 10000679,
            "children": [],
            "leaf": true,
            "name": "Canvases",
            "var": false
          },
          {
            "categoryId": 10000676,
            "children": [],
            "leaf": true,
            "name": "Colouring & Copic Art Range",
            "var": false
          },
          {
            "categoryId": 10000677,
            "children": [],
            "leaf": true,
            "name": "Drawing & Drafting",
            "var": false
          },
          {
            "categoryId": 10000678,
            "children": [],
            "leaf": true,
            "name": "Modelling & Sculpting",
            "var": false
          },
          {
            "categoryId": 10000680,
            "children": [],
            "leaf": true,
            "name": "Paper Sheets",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Art Supplies",
        "var": false
      },
      {
        "categoryId": 10000378,
        "children": [
          {
            "categoryId": 10000703,
            "children": [],
            "leaf": true,
            "name": "Craft Sets",
            "var": false
          },
          {
            "categoryId": 10000704,
            "children": [],
            "leaf": true,
            "name": "General Craft",
            "var": false
          },
          {
            "categoryId": 10000701,
            "children": [],
            "leaf": true,
            "name": "Papercraft",
            "var": false
          },
          {
            "categoryId": 10000702,
            "children": [],
            "leaf": true,
            "name": "Scrapbooking",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Craft Supplies",
        "var": false
      },
      {
        "categoryId": 10000380,
        "children": [
          {
            "categoryId": 10000717,
            "children": [],
            "leaf": true,
            "name": "Book Plates & Markers",
            "var": false
          },
          {
            "categoryId": 10000712,
            "children": [],
            "leaf": true,
            "name": "Gift Cards & Tags",
            "var": false
          },
          {
            "categoryId": 10000713,
            "children": [],
            "leaf": true,
            "name": "Gift Wrapping & Bags",
            "var": false
          },
          {
            "categoryId": 10000715,
            "children": [],
            "leaf": true,
            "name": "Holiday & Celebration Gifts",
            "var": false
          },
          {
            "categoryId": 10000714,
            "children": [],
            "leaf": true,
            "name": "Novelty Gifts",
            "var": false
          },
          {
            "categoryId": 10000716,
            "children": [],
            "leaf": true,
            "name": "Photo Albums",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Gifts & Wrapping",
        "var": false
      },
      {
        "categoryId": 10000375,
        "children": [
          {
            "categoryId": 10000665,
            "children": [],
            "leaf": true,
            "name": "Bubble Wrap",
            "var": false
          },
          {
            "categoryId": 10000664,
            "children": [],
            "leaf": true,
            "name": "Cartons",
            "var": false
          },
          {
            "categoryId": 10000668,
            "children": [],
            "leaf": true,
            "name": "Corrugated Cardboard",
            "var": false
          },
          {
            "categoryId": 10003089,
            "children": [],
            "leaf": true,
            "name": "Daraz Packaging Material",
            "var": false
          },
          {
            "categoryId": 10000666,
            "children": [],
            "leaf": true,
            "name": "Shipping Bags",
            "var": false
          },
          {
            "categoryId": 10000667,
            "children": [],
            "leaf": true,
            "name": "Shrink Wrap",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Packaging & Cartons",
        "var": false
      },
      {
        "categoryId": 10000374,
        "children": [
          {
            "categoryId": 10000663,
            "children": [],
            "leaf": true,
            "name": "Calendars",
            "var": false
          },
          {
            "categoryId": 10000660,
            "children": [],
            "leaf": true,
            "name": "Computer Form Paper",
            "var": false
          },
          {
            "categoryId": 10000658,
            "children": [],
            "leaf": true,
            "name": "Copier Paper",
            "var": false
          },
          {
            "categoryId": 10000655,
            "children": [],
            "leaf": true,
            "name": "Diaries & Year Planners",
            "var": false
          },
          {
            "categoryId": 10000656,
            "children": [],
            "leaf": true,
            "name": "Envelopes & Mailers",
            "var": false
          },
          {
            "categoryId": 10000659,
            "children": [],
            "leaf": true,
            "name": "Fax Paper Rolls",
            "var": false
          },
          {
            "categoryId": 10000662,
            "children": [],
            "leaf": true,
            "name": "Index Cards",
            "var": false
          },
          {
            "categoryId": 10000657,
            "children": [],
            "leaf": true,
            "name": "Labels",
            "var": false
          },
          {
            "categoryId": 10000654,
            "children": [],
            "leaf": true,
            "name": "Note Books & Pads",
            "var": false
          },
          {
            "categoryId": 10000661,
            "children": [],
            "leaf": true,
            "name": "Post-it Notes, Flags & Tabs",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Paper Products",
        "var": false
      },
      {
        "categoryId": 10002989,
        "children": [],
        "leaf": true,
        "name": "Religious Items",
        "var": false
      },
      {
        "categoryId": 10000377,
        "children": [
          {
            "categoryId": 10000693,
            "children": [],
            "leaf": true,
            "name": "Binders & Binder Accessories",
            "var": false
          },
          {
            "categoryId": 10000690,
            "children": [],
            "leaf": true,
            "name": "Boards & Flipcharts",
            "var": false
          },
          {
            "categoryId": 10000688,
            "children": [],
            "leaf": true,
            "name": "Calculators",
            "var": false
          },
          {
            "categoryId": 10000691,
            "children": [],
            "leaf": true,
            "name": "Clips, Pins & Tacks",
            "var": false
          },
          {
            "categoryId": 10000692,
            "children": [],
            "leaf": true,
            "name": "Desk Organisers",
            "var": false
          },
          {
            "categoryId": 10000694,
            "children": [],
            "leaf": true,
            "name": "Filing & Document Presentation",
            "var": false
          },
          {
            "categoryId": 10000700,
            "children": [],
            "leaf": true,
            "name": "Geometry Sets",
            "var": false
          },
          {
            "categoryId": 10000684,
            "children": [],
            "leaf": true,
            "name": "Glues & Adhesive",
            "var": false
          },
          {
            "categoryId": 10000689,
            "children": [],
            "leaf": true,
            "name": "Label Maker & Tape",
            "var": false
          },
          {
            "categoryId": 10000695,
            "children": [],
            "leaf": true,
            "name": "Library Bag",
            "var": false
          },
          {
            "categoryId": 10000697,
            "children": [],
            "leaf": true,
            "name": "Paper Shredders",
            "var": false
          },
          {
            "categoryId": 10000682,
            "children": [],
            "leaf": true,
            "name": "Pencil Cases & Boxes",
            "var": false
          },
          {
            "categoryId": 10000699,
            "children": [],
            "leaf": true,
            "name": "Pencil Sharpeners",
            "var": false
          },
          {
            "categoryId": 10000698,
            "children": [],
            "leaf": true,
            "name": "Rulers & Stencils",
            "var": false
          },
          {
            "categoryId": 10000681,
            "children": [],
            "leaf": true,
            "name": "School Sets",
            "var": false
          },
          {
            "categoryId": 10000685,
            "children": [],
            "leaf": true,
            "name": "Scissors & Cutters",
            "var": false
          },
          {
            "categoryId": 10000686,
            "children": [],
            "leaf": true,
            "name": "Staplers & Punches",
            "var": false
          },
          {
            "categoryId": 10000683,
            "children": [],
            "leaf": true,
            "name": "Stationery Fasteners",
            "var": false
          },
          {
            "categoryId": 10000696,
            "children": [],
            "leaf": true,
            "name": "Stickers & Labels",
            "var": false
          },
          {
            "categoryId": 10000687,
            "children": [],
            "leaf": true,
            "name": "Tapes & Dispensers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "School & Office Equipment",
        "var": false
      },
      {
        "categoryId": 20000011,
        "children": [
          {
            "categoryId": 20000012,
            "children": [],
            "leaf": true,
            "name": "Boys",
            "var": false
          },
          {
            "categoryId": 20000013,
            "children": [],
            "leaf": true,
            "name": "Girls",
            "var": false
          }
        ],
        "leaf": false,
        "name": "School Uniforms",
        "var": false
      },
      {
        "categoryId": 10000379,
        "children": [
          {
            "categoryId": 10000705,
            "children": [],
            "leaf": true,
            "name": "Haberdashery",
            "var": false
          },
          {
            "categoryId": 10000706,
            "children": [],
            "leaf": true,
            "name": "Knitting & Crochet",
            "var": false
          },
          {
            "categoryId": 10000711,
            "children": [],
            "leaf": true,
            "name": "Millinery",
            "var": false
          },
          {
            "categoryId": 10000707,
            "children": [],
            "leaf": true,
            "name": "Needlework",
            "var": false
          },
          {
            "categoryId": 10000709,
            "children": [],
            "leaf": true,
            "name": "Patterns & Books",
            "var": false
          },
          {
            "categoryId": 10000708,
            "children": [],
            "leaf": true,
            "name": "Quilting",
            "var": false
          },
          {
            "categoryId": 10000710,
            "children": [],
            "leaf": true,
            "name": "Trims",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Sewing",
        "var": false
      },
      {
        "categoryId": 10000373,
        "children": [
          {
            "categoryId": 10000650,
            "children": [],
            "leaf": true,
            "name": "Correction Liquid & Pens",
            "var": false
          },
          {
            "categoryId": 10000653,
            "children": [],
            "leaf": true,
            "name": "Digital Pens",
            "var": false
          },
          {
            "categoryId": 10000651,
            "children": [],
            "leaf": true,
            "name": "Erasers",
            "var": false
          },
          {
            "categoryId": 10000649,
            "children": [],
            "leaf": true,
            "name": "Highlighters & Markers",
            "var": false
          },
          {
            "categoryId": 10000652,
            "children": [],
            "leaf": true,
            "name": "Inks & Refills",
            "var": false
          },
          {
            "categoryId": 10000648,
            "children": [],
            "leaf": true,
            "name": "Pencils",
            "var": false
          },
          {
            "categoryId": 10000719,
            "children": [],
            "leaf": true,
            "name": "Pens",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Writing & Correction",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Stationery & Craft",
    "var": false
  },
  {
    "categoryId": 147,
    "children": [
      {
        "categoryId": 9817,
        "children": [],
        "leaf": true,
        "name": "App-enabled Accessories",
        "var": false
      },
      {
        "categoryId": 148,
        "children": [
          {
            "categoryId": 149,
            "children": [
              {
                "categoryId": 7478,
                "children": [],
                "leaf": true,
                "name": "Armband",
                "var": false
              },
              {
                "categoryId": 150,
                "children": [],
                "leaf": true,
                "name": "Cables",
                "var": false
              },
              {
                "categoryId": 151,
                "children": [],
                "leaf": true,
                "name": "Cases and Covers",
                "var": false
              },
              {
                "categoryId": 7480,
                "children": [],
                "leaf": true,
                "name": "Chargers",
                "var": false
              },
              {
                "categoryId": 152,
                "children": [],
                "leaf": true,
                "name": "Microphones",
                "var": false
              },
              {
                "categoryId": 7479,
                "children": [],
                "leaf": true,
                "name": "Mounting kit",
                "var": false
              },
              {
                "categoryId": 5695,
                "children": [],
                "leaf": true,
                "name": "Others",
                "var": false
              },
              {
                "categoryId": 153,
                "children": [],
                "leaf": true,
                "name": "Screen Guards",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Audio Accessories",
            "var": false
          },
          {
            "categoryId": 7501,
            "children": [],
            "leaf": false,
            "name": "Boomboxes",
            "var": false
          },
          {
            "categoryId": 7500,
            "children": [],
            "leaf": false,
            "name": "Docking Stations",
            "var": false
          },
          {
            "categoryId": 154,
            "children": [
              {
                "categoryId": 158,
                "children": [],
                "leaf": true,
                "name": "Bluetooth Headsets",
                "var": false
              },
              {
                "categoryId": 9709,
                "children": [],
                "leaf": true,
                "name": "Headphones & Headsets Accessories",
                "var": false
              },
              {
                "categoryId": 155,
                "children": [],
                "leaf": true,
                "name": "In-Ear Headphones",
                "var": false
              },
              {
                "categoryId": 9707,
                "children": [],
                "leaf": true,
                "name": "Mono Headsets",
                "var": false
              },
              {
                "categoryId": 157,
                "children": [],
                "leaf": true,
                "name": "On-Ear Headphones",
                "var": false
              },
              {
                "categoryId": 156,
                "children": [],
                "leaf": true,
                "name": "Over-the-Ear Headphones",
                "var": false
              },
              {
                "categoryId": 159,
                "children": [],
                "leaf": true,
                "name": "Wired Headsets",
                "var": false
              },
              {
                "categoryId": 9708,
                "children": [],
                "leaf": true,
                "name": "Wireless Earbuds",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Headphones & Headsets",
            "var": false
          },
          {
            "categoryId": 9717,
            "children": [
              {
                "categoryId": 9724,
                "children": [
                  {
                    "categoryId": 9725,
                    "children": [],
                    "leaf": true,
                    "name": "Bookshelf",
                    "var": false
                  },
                  {
                    "categoryId": 9726,
                    "children": [],
                    "leaf": true,
                    "name": "Center",
                    "var": false
                  },
                  {
                    "categoryId": 9727,
                    "children": [],
                    "leaf": true,
                    "name": "Floor",
                    "var": false
                  },
                  {
                    "categoryId": 9728,
                    "children": [],
                    "leaf": true,
                    "name": "Subwoofer",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Component Speakers",
                "var": false
              },
              {
                "categoryId": 9719,
                "children": [],
                "leaf": true,
                "name": "Hi-Fi Systems",
                "var": false
              },
              {
                "categoryId": 9729,
                "children": [
                  {
                    "categoryId": 9730,
                    "children": [],
                    "leaf": true,
                    "name": "BT Transmitters & Receivers",
                    "var": false
                  },
                  {
                    "categoryId": 9731,
                    "children": [],
                    "leaf": true,
                    "name": "Cables",
                    "var": false
                  },
                  {
                    "categoryId": 9733,
                    "children": [],
                    "leaf": true,
                    "name": "Others",
                    "var": false
                  },
                  {
                    "categoryId": 9732,
                    "children": [],
                    "leaf": true,
                    "name": "Speaker Stands",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Home Audio Accessories",
                "var": false
              },
              {
                "categoryId": 9722,
                "children": [],
                "leaf": true,
                "name": "Home Entertainment Receivers & Amplifiers",
                "var": false
              },
              {
                "categoryId": 9720,
                "children": [],
                "leaf": true,
                "name": "Home Theater Systems",
                "var": false
              },
              {
                "categoryId": 9721,
                "children": [],
                "leaf": true,
                "name": "Karaoke Systems",
                "var": false
              },
              {
                "categoryId": 9718,
                "children": [],
                "leaf": true,
                "name": "Soundbars",
                "var": false
              },
              {
                "categoryId": 9723,
                "children": [],
                "leaf": true,
                "name": "Turntables",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Home Entertainment",
            "var": false
          },
          {
            "categoryId": 7485,
            "children": [
              {
                "categoryId": 10002912,
                "children": [],
                "leaf": true,
                "name": "Desktop DAC Headphone Amplifier",
                "var": false
              },
              {
                "categoryId": 9715,
                "children": [],
                "leaf": true,
                "name": "Digital to Analog Converters",
                "var": false
              },
              {
                "categoryId": 9716,
                "children": [],
                "leaf": true,
                "name": "Live, Sound & Stage Accessories",
                "var": false
              },
              {
                "categoryId": 9713,
                "children": [],
                "leaf": true,
                "name": "Microphone Accessories",
                "var": false
              },
              {
                "categoryId": 7488,
                "children": [],
                "leaf": true,
                "name": "Microphones",
                "var": false
              },
              {
                "categoryId": 7489,
                "children": [],
                "leaf": true,
                "name": "Mixers & Audio Interfaces",
                "var": false
              },
              {
                "categoryId": 9714,
                "children": [],
                "leaf": true,
                "name": "PA Systems",
                "var": false
              },
              {
                "categoryId": 10002913,
                "children": [],
                "leaf": true,
                "name": "Portable DAC Headphone Amplifier",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Live Sound & Stage Equipment",
            "var": false
          },
          {
            "categoryId": 5725,
            "children": [],
            "leaf": false,
            "name": "Musical Instruments",
            "var": false
          },
          {
            "categoryId": 5696,
            "children": [],
            "leaf": false,
            "name": "Other portable Audio",
            "var": false
          },
          {
            "categoryId": 181,
            "children": [
              {
                "categoryId": 182,
                "children": [
                  {
                    "categoryId": 183,
                    "children": [],
                    "leaf": false,
                    "name": "Cases and Covers",
                    "var": false
                  },
                  {
                    "categoryId": 184,
                    "children": [],
                    "leaf": false,
                    "name": "Screen Guards",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "MP3 Player Accessories",
                "var": false
              },
              {
                "categoryId": 186,
                "children": [],
                "leaf": true,
                "name": "MP3/MP4",
                "var": false
              },
              {
                "categoryId": 187,
                "children": [],
                "leaf": false,
                "name": "MP4 / Video MP3 Players",
                "var": false
              },
              {
                "categoryId": 9712,
                "children": [],
                "leaf": true,
                "name": "Portable Audio Accessories",
                "var": false
              },
              {
                "categoryId": 9710,
                "children": [],
                "leaf": true,
                "name": "Radio & CD players",
                "var": false
              },
              {
                "categoryId": 9711,
                "children": [],
                "leaf": true,
                "name": "Voice Recorders",
                "var": false
              },
              {
                "categoryId": 185,
                "children": [],
                "leaf": false,
                "name": "iPods",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Portable Players",
            "var": false
          },
          {
            "categoryId": 7496,
            "children": [],
            "leaf": true,
            "name": "Portable Speakers",
            "var": false
          },
          {
            "categoryId": 10002894,
            "children": [],
            "leaf": true,
            "name": "Smart Speakers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Audio",
        "var": false
      },
      {
        "categoryId": 10002951,
        "children": [
          {
            "categoryId": 10002896,
            "children": [
              {
                "categoryId": 10002931,
                "children": [],
                "leaf": true,
                "name": "Cables & Chargers",
                "var": false
              },
              {
                "categoryId": 10002927,
                "children": [],
                "leaf": true,
                "name": "Cases & Covers",
                "var": false
              },
              {
                "categoryId": 10002924,
                "children": [],
                "leaf": true,
                "name": "Consoles",
                "var": false
              },
              {
                "categoryId": 10002926,
                "children": [],
                "leaf": true,
                "name": "Digital Downloads",
                "var": false
              },
              {
                "categoryId": 10002928,
                "children": [],
                "leaf": true,
                "name": "Docks & Stands",
                "var": false
              },
              {
                "categoryId": 10002925,
                "children": [],
                "leaf": true,
                "name": "Games",
                "var": false
              },
              {
                "categoryId": 10002932,
                "children": [],
                "leaf": true,
                "name": "Interactive Figurines",
                "var": false
              },
              {
                "categoryId": 10002930,
                "children": [],
                "leaf": true,
                "name": "Joy-Con & Controllers",
                "var": false
              },
              {
                "categoryId": 10002933,
                "children": [],
                "leaf": true,
                "name": "Other Accessories",
                "var": false
              },
              {
                "categoryId": 10002929,
                "children": [],
                "leaf": true,
                "name": "Screen Protectors",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Nintendo",
            "var": false
          },
          {
            "categoryId": 10002898,
            "children": [
              {
                "categoryId": 10002943,
                "children": [],
                "leaf": true,
                "name": "Console Accessories",
                "var": false
              },
              {
                "categoryId": 10002942,
                "children": [],
                "leaf": true,
                "name": "Consoles",
                "var": false
              },
              {
                "categoryId": 10002944,
                "children": [],
                "leaf": true,
                "name": "Games",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Other Gaming",
            "var": false
          },
          {
            "categoryId": 10002895,
            "children": [
              {
                "categoryId": 10002921,
                "children": [],
                "leaf": true,
                "name": "Cables & Chargers",
                "var": false
              },
              {
                "categoryId": 10002919,
                "children": [],
                "leaf": true,
                "name": "Cases & Covers",
                "var": false
              },
              {
                "categoryId": 10002914,
                "children": [],
                "leaf": true,
                "name": "Consoles",
                "var": false
              },
              {
                "categoryId": 10002918,
                "children": [],
                "leaf": true,
                "name": "Controllers",
                "var": false
              },
              {
                "categoryId": 10002916,
                "children": [],
                "leaf": true,
                "name": "Digital Downloads",
                "var": false
              },
              {
                "categoryId": 10002915,
                "children": [],
                "leaf": true,
                "name": "Games",
                "var": false
              },
              {
                "categoryId": 10002922,
                "children": [],
                "leaf": true,
                "name": "Interactive Figurines",
                "var": false
              },
              {
                "categoryId": 10002920,
                "children": [],
                "leaf": true,
                "name": "Mounts & Brackets",
                "var": false
              },
              {
                "categoryId": 10002923,
                "children": [],
                "leaf": true,
                "name": "Other Accessories",
                "var": false
              },
              {
                "categoryId": 10002917,
                "children": [],
                "leaf": true,
                "name": "PlayStation VR Headsets",
                "var": false
              }
            ],
            "leaf": false,
            "name": "PlayStation",
            "var": false
          },
          {
            "categoryId": 10002897,
            "children": [
              {
                "categoryId": 10002940,
                "children": [],
                "leaf": true,
                "name": "Cables & Chargers",
                "var": false
              },
              {
                "categoryId": 10002938,
                "children": [],
                "leaf": true,
                "name": "Cases & Covers",
                "var": false
              },
              {
                "categoryId": 10002934,
                "children": [],
                "leaf": true,
                "name": "Consoles",
                "var": false
              },
              {
                "categoryId": 10002937,
                "children": [],
                "leaf": true,
                "name": "Controllers",
                "var": false
              },
              {
                "categoryId": 10002936,
                "children": [],
                "leaf": true,
                "name": "Digital Downloads",
                "var": false
              },
              {
                "categoryId": 10002935,
                "children": [],
                "leaf": true,
                "name": "Games",
                "var": false
              },
              {
                "categoryId": 10002939,
                "children": [],
                "leaf": true,
                "name": "Mounts & Brackets",
                "var": false
              },
              {
                "categoryId": 10002941,
                "children": [],
                "leaf": true,
                "name": "Other Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Xbox",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Console Gaming",
        "var": false
      },
      {
        "categoryId": 210,
        "children": [
          {
            "categoryId": 7505,
            "children": [],
            "leaf": true,
            "name": "Dictionaries & Translators",
            "var": false
          },
          {
            "categoryId": 7503,
            "children": [],
            "leaf": true,
            "name": "Laser Pointers",
            "var": false
          },
          {
            "categoryId": 7504,
            "children": [],
            "leaf": true,
            "name": "Metal Detectors",
            "var": false
          },
          {
            "categoryId": 9779,
            "children": [],
            "leaf": true,
            "name": "Universal Chargers",
            "var": false
          },
          {
            "categoryId": 9780,
            "children": [],
            "leaf": true,
            "name": "Walkie-Talkies",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Gadgets",
        "var": false
      },
      {
        "categoryId": 9809,
        "children": [
          {
            "categoryId": 9814,
            "children": [],
            "leaf": true,
            "name": "3D Glasses",
            "var": false
          },
          {
            "categoryId": 9811,
            "children": [],
            "leaf": true,
            "name": "Antennas",
            "var": false
          },
          {
            "categoryId": 9815,
            "children": [],
            "leaf": true,
            "name": "Cables",
            "var": false
          },
          {
            "categoryId": 9816,
            "children": [],
            "leaf": true,
            "name": "TV Adapters",
            "var": false
          },
          {
            "categoryId": 9810,
            "children": [],
            "leaf": true,
            "name": "TV Receivers",
            "var": false
          },
          {
            "categoryId": 9813,
            "children": [],
            "leaf": true,
            "name": "TV Remote Controllers",
            "var": false
          },
          {
            "categoryId": 9812,
            "children": [],
            "leaf": true,
            "name": "Wall Mounts & Protectors",
            "var": false
          }
        ],
        "leaf": false,
        "name": "TV Accessories",
        "var": false
      },
      {
        "categoryId": 198,
        "children": [
          {
            "categoryId": 200,
            "children": [],
            "leaf": true,
            "name": "CRT Televisions",
            "var": false
          },
          {
            "categoryId": 201,
            "children": [],
            "leaf": true,
            "name": "LCD Televisions",
            "var": false
          },
          {
            "categoryId": 202,
            "children": [],
            "leaf": true,
            "name": "LED Televisions",
            "var": false
          },
          {
            "categoryId": 20000172,
            "children": [],
            "leaf": true,
            "name": "Laser Televisions",
            "var": false
          },
          {
            "categoryId": 9788,
            "children": [],
            "leaf": true,
            "name": "OLED Televisions",
            "var": false
          },
          {
            "categoryId": 203,
            "children": [],
            "leaf": true,
            "name": "Plasma Televisions",
            "var": false
          },
          {
            "categoryId": 10000269,
            "children": [],
            "leaf": true,
            "name": "QLED Televisions",
            "var": false
          },
          {
            "categoryId": 204,
            "children": [],
            "leaf": true,
            "name": "Smart Televisions",
            "var": false
          },
          {
            "categoryId": 20000173,
            "children": [],
            "leaf": true,
            "name": "ULED Televisions",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Televisions",
        "var": false
      },
      {
        "categoryId": 188,
        "children": [
          {
            "categoryId": 190,
            "children": [],
            "leaf": true,
            "name": "Blu-Ray/DVD Players",
            "var": false
          },
          {
            "categoryId": 5897,
            "children": [
              {
                "categoryId": 9782,
                "children": [],
                "leaf": true,
                "name": "Hard Drive Media Players",
                "var": false
              },
              {
                "categoryId": 9781,
                "children": [],
                "leaf": true,
                "name": "Streaming Media Players",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Media Players",
            "var": false
          },
          {
            "categoryId": 9783,
            "children": [
              {
                "categoryId": 9787,
                "children": [],
                "leaf": true,
                "name": "Other Projector Accessories",
                "var": false
              },
              {
                "categoryId": 9785,
                "children": [],
                "leaf": true,
                "name": "Projector Cases & Bags",
                "var": false
              },
              {
                "categoryId": 9786,
                "children": [],
                "leaf": true,
                "name": "Projector Mounts",
                "var": false
              },
              {
                "categoryId": 9784,
                "children": [],
                "leaf": true,
                "name": "Projector Screens",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Projector Accessories",
            "var": false
          },
          {
            "categoryId": 194,
            "children": [],
            "leaf": true,
            "name": "Projectors",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Video",
        "var": false
      },
      {
        "categoryId": 7536,
        "children": [
          {
            "categoryId": 9805,
            "children": [
              {
                "categoryId": 9807,
                "children": [],
                "leaf": true,
                "name": "Cables & Chargers",
                "var": false
              },
              {
                "categoryId": 9806,
                "children": [],
                "leaf": true,
                "name": "Straps",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Fitness & Activity Tracker Accessories",
            "var": false
          },
          {
            "categoryId": 7538,
            "children": [],
            "leaf": true,
            "name": "Fitness & Activity Trackers",
            "var": false
          },
          {
            "categoryId": 9808,
            "children": [],
            "leaf": true,
            "name": "Gesture Control",
            "var": false
          },
          {
            "categoryId": 7539,
            "children": [],
            "leaf": true,
            "name": "Smart Glasses",
            "var": false
          },
          {
            "categoryId": 7540,
            "children": [],
            "leaf": true,
            "name": "Smart Trackers",
            "var": false
          },
          {
            "categoryId": 7537,
            "children": [],
            "leaf": true,
            "name": "Smartwatches",
            "var": false
          },
          {
            "categoryId": 9789,
            "children": [
              {
                "categoryId": 9793,
                "children": [],
                "leaf": true,
                "name": "Cases",
                "var": false
              },
              {
                "categoryId": 9790,
                "children": [],
                "leaf": true,
                "name": "Docks & Chargers",
                "var": false
              },
              {
                "categoryId": 9791,
                "children": [],
                "leaf": true,
                "name": "Screen Protectors",
                "var": false
              },
              {
                "categoryId": 9792,
                "children": [],
                "leaf": true,
                "name": "Straps",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Smartwatches Accessories",
            "var": false
          },
          {
            "categoryId": 9794,
            "children": [
              {
                "categoryId": 9795,
                "children": [],
                "leaf": true,
                "name": "Mobile VR",
                "var": false
              },
              {
                "categoryId": 9797,
                "children": [],
                "leaf": true,
                "name": "PC VR",
                "var": false
              },
              {
                "categoryId": 9796,
                "children": [
                  {
                    "categoryId": 9798,
                    "children": [],
                    "leaf": true,
                    "name": "Motion Controllers",
                    "var": false
                  },
                  {
                    "categoryId": 9801,
                    "children": [],
                    "leaf": true,
                    "name": "VR Cases & Covers",
                    "var": false
                  },
                  {
                    "categoryId": 9799,
                    "children": [],
                    "leaf": true,
                    "name": "VR Remotes",
                    "var": false
                  },
                  {
                    "categoryId": 9800,
                    "children": [],
                    "leaf": true,
                    "name": "VR Sensors",
                    "var": false
                  },
                  {
                    "categoryId": 9803,
                    "children": [],
                    "leaf": true,
                    "name": "VR Skins",
                    "var": false
                  },
                  {
                    "categoryId": 9802,
                    "children": [],
                    "leaf": true,
                    "name": "VR Straps",
                    "var": false
                  },
                  {
                    "categoryId": 9804,
                    "children": [],
                    "leaf": true,
                    "name": "VR Wires",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "PC VR Accessories",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Virtual Reality",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Wearable Technology",
        "var": false
      }
    ],
    "leaf": false,
    "name": "TV, Audio / Video, Gaming & Wearables",
    "var": false
  },
  {
    "categoryId": 10000261,
    "children": [],
    "leaf": false,
    "name": "Test ID",
    "var": false
  },
  {
    "categoryId": 10003091,
    "children": [
      {
        "categoryId": 20000201,
        "children": [],
        "leaf": true,
        "name": "Test AC Installation Attribute",
        "var": true
      },
      {
        "categoryId": 10003093,
        "children": [],
        "leaf": true,
        "name": "Test digital",
        "var": true
      },
      {
        "categoryId": 10003092,
        "children": [],
        "leaf": true,
        "name": "Test normal product",
        "var": false
      },
      {
        "categoryId": 10003095,
        "children": [],
        "leaf": true,
        "name": "Test others",
        "var": true
      },
      {
        "categoryId": 10003094,
        "children": [],
        "leaf": true,
        "name": "Test service",
        "var": true
      }
    ],
    "leaf": false,
    "name": "Test Product",
    "var": false
  },
  {
    "categoryId": 10000337,
    "children": [
      {
        "categoryId": 10000353,
        "children": [
          {
            "categoryId": 10000488,
            "children": [],
            "leaf": true,
            "name": "Batteries",
            "var": false
          },
          {
            "categoryId": 10000485,
            "children": [],
            "leaf": true,
            "name": "Cables, Leads & Adapters",
            "var": false
          },
          {
            "categoryId": 10000489,
            "children": [],
            "leaf": true,
            "name": "Electrical Circuitry & Parts",
            "var": false
          },
          {
            "categoryId": 10000487,
            "children": [],
            "leaf": true,
            "name": "Electrical Trade Tools & Testers",
            "var": false
          },
          {
            "categoryId": 10000486,
            "children": [],
            "leaf": true,
            "name": "Powerpoints, Switches & Savers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Electrical",
        "var": false
      },
      {
        "categoryId": 10000351,
        "children": [
          {
            "categoryId": 10000471,
            "children": [],
            "leaf": true,
            "name": "Fire Alarms & Releases",
            "var": false
          },
          {
            "categoryId": 10000473,
            "children": [],
            "leaf": true,
            "name": "Fire Blanket",
            "var": false
          },
          {
            "categoryId": 10000474,
            "children": [],
            "leaf": true,
            "name": "Fire Escape Ladders",
            "var": false
          },
          {
            "categoryId": 10000472,
            "children": [],
            "leaf": true,
            "name": "Fire Extinguishers",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Fire Safety",
        "var": false
      },
      {
        "categoryId": 10000346,
        "children": [
          {
            "categoryId": 10000443,
            "children": [],
            "leaf": true,
            "name": "Bathroom Fittings",
            "var": false
          },
          {
            "categoryId": 10000444,
            "children": [],
            "leaf": true,
            "name": "Bathroom Tapware",
            "var": false
          },
          {
            "categoryId": 10000447,
            "children": [],
            "leaf": true,
            "name": "Fixture Parts & Components",
            "var": false
          },
          {
            "categoryId": 10000449,
            "children": [],
            "leaf": true,
            "name": "Hoses & Pipes",
            "var": false
          },
          {
            "categoryId": 10000441,
            "children": [],
            "leaf": true,
            "name": "Kitchen Fittings",
            "var": false
          },
          {
            "categoryId": 10000442,
            "children": [],
            "leaf": true,
            "name": "Kitchen Tapware",
            "var": false
          },
          {
            "categoryId": 10000446,
            "children": [],
            "leaf": true,
            "name": "Traps & Drains",
            "var": false
          },
          {
            "categoryId": 10000448,
            "children": [],
            "leaf": true,
            "name": "Valves",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Fixtures & Plumbing",
        "var": false
      },
      {
        "categoryId": 10000357,
        "children": [
          {
            "categoryId": 10000503,
            "children": [],
            "leaf": true,
            "name": "Caulking Guns",
            "var": false
          },
          {
            "categoryId": 10000491,
            "children": [],
            "leaf": true,
            "name": "Clamps",
            "var": false
          },
          {
            "categoryId": 10000504,
            "children": [],
            "leaf": true,
            "name": "Cleaning Tools",
            "var": false
          },
          {
            "categoryId": 10000505,
            "children": [],
            "leaf": true,
            "name": "Fastening, Gluing & Soldering",
            "var": false
          },
          {
            "categoryId": 10000508,
            "children": [],
            "leaf": true,
            "name": "Files",
            "var": false
          },
          {
            "categoryId": 10000512,
            "children": [],
            "leaf": true,
            "name": "Fuel Cans & Drums",
            "var": false
          },
          {
            "categoryId": 10000490,
            "children": [],
            "leaf": true,
            "name": "Hammers",
            "var": false
          },
          {
            "categoryId": 10000511,
            "children": [],
            "leaf": true,
            "name": "Hand Tool Parts & Accessories",
            "var": false
          },
          {
            "categoryId": 10000513,
            "children": [],
            "leaf": true,
            "name": "Hand Trucks & Dollies",
            "var": false
          },
          {
            "categoryId": 10000509,
            "children": [],
            "leaf": true,
            "name": "Hex Keys & Spline Keys",
            "var": false
          },
          {
            "categoryId": 10000514,
            "children": [],
            "leaf": true,
            "name": "Industrial Supplies",
            "var": false
          },
          {
            "categoryId": 10000492,
            "children": [],
            "leaf": true,
            "name": "Inspection Tools",
            "var": false
          },
          {
            "categoryId": 10000494,
            "children": [],
            "leaf": true,
            "name": "Pliers",
            "var": false
          },
          {
            "categoryId": 10000510,
            "children": [],
            "leaf": true,
            "name": "Pneumatic Grease Gun",
            "var": false
          },
          {
            "categoryId": 10000495,
            "children": [],
            "leaf": true,
            "name": "Prying Tools",
            "var": false
          },
          {
            "categoryId": 10000496,
            "children": [],
            "leaf": true,
            "name": "Ratchets & Sockets",
            "var": false
          },
          {
            "categoryId": 10000497,
            "children": [],
            "leaf": true,
            "name": "Saws",
            "var": false
          },
          {
            "categoryId": 10000498,
            "children": [],
            "leaf": true,
            "name": "Scissors & Snips",
            "var": false
          },
          {
            "categoryId": 10000499,
            "children": [],
            "leaf": true,
            "name": "Screw & Nut Drivers",
            "var": false
          },
          {
            "categoryId": 10000493,
            "children": [],
            "leaf": true,
            "name": "Speciality Knives & Multi-Tools",
            "var": false
          },
          {
            "categoryId": 10000500,
            "children": [],
            "leaf": true,
            "name": "Swiss Knives",
            "var": false
          },
          {
            "categoryId": 10000501,
            "children": [],
            "leaf": true,
            "name": "Tool Sets",
            "var": false
          },
          {
            "categoryId": 10000502,
            "children": [],
            "leaf": true,
            "name": "Trowels",
            "var": false
          },
          {
            "categoryId": 10000506,
            "children": [],
            "leaf": true,
            "name": "Winches",
            "var": false
          },
          {
            "categoryId": 10000507,
            "children": [],
            "leaf": true,
            "name": "Wrenches",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Hand Tools",
        "var": false
      },
      {
        "categoryId": 10000348,
        "children": [
          {
            "categoryId": 10000453,
            "children": [],
            "leaf": true,
            "name": "Mailboxes",
            "var": false
          },
          {
            "categoryId": 10000450,
            "children": [],
            "leaf": true,
            "name": "Nails, Screws & Fasteners",
            "var": false
          },
          {
            "categoryId": 10000452,
            "children": [],
            "leaf": true,
            "name": "Picture Hangers & Hooks",
            "var": false
          },
          {
            "categoryId": 10000451,
            "children": [],
            "leaf": true,
            "name": "Tarps & Tie-Downs",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Hardware",
        "var": false
      },
      {
        "categoryId": 20000024,
        "children": [
          {
            "categoryId": 20000027,
            "children": [],
            "leaf": true,
            "name": "Bricks",
            "var": false
          },
          {
            "categoryId": 20000025,
            "children": [],
            "leaf": true,
            "name": "Cement",
            "var": false
          },
          {
            "categoryId": 20000029,
            "children": [],
            "leaf": true,
            "name": "Gravel",
            "var": false
          },
          {
            "categoryId": 20000191,
            "children": [],
            "leaf": true,
            "name": "Pavers",
            "var": false
          },
          {
            "categoryId": 20000026,
            "children": [],
            "leaf": true,
            "name": "Sand",
            "var": false
          },
          {
            "categoryId": 20000028,
            "children": [],
            "leaf": true,
            "name": "Steel",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Home Build Up",
        "var": false
      },
      {
        "categoryId": 10000355,
        "children": [],
        "leaf": true,
        "name": "Ladders & Workbenches",
        "var": false
      },
      {
        "categoryId": 10000371,
        "children": [
          {
            "categoryId": 10000729,
            "children": [],
            "leaf": true,
            "name": "Arbours & Trellises",
            "var": false
          },
          {
            "categoryId": 10000724,
            "children": [],
            "leaf": true,
            "name": "Garden Décor & Ornaments",
            "var": false
          },
          {
            "categoryId": 10000730,
            "children": [],
            "leaf": true,
            "name": "Garden Shed & Greenhouses",
            "var": false
          },
          {
            "categoryId": 10000723,
            "children": [],
            "leaf": true,
            "name": "Garden Soil & Fertilizers",
            "var": false
          },
          {
            "categoryId": 10000721,
            "children": [],
            "leaf": true,
            "name": "Gardening Tools",
            "var": false
          },
          {
            "categoryId": 10000720,
            "children": [
              {
                "categoryId": 10000797,
                "children": [],
                "leaf": true,
                "name": "Generators & Portable Power",
                "var": false
              },
              {
                "categoryId": 10000794,
                "children": [],
                "leaf": true,
                "name": "Lawn Mowers",
                "var": false
              },
              {
                "categoryId": 10000799,
                "children": [],
                "leaf": true,
                "name": "Outdoor Power Tool Accessories",
                "var": false
              },
              {
                "categoryId": 10000795,
                "children": [],
                "leaf": true,
                "name": "Outdoor Power Tools",
                "var": false
              },
              {
                "categoryId": 10000796,
                "children": [],
                "leaf": true,
                "name": "Pressure Washers",
                "var": false
              },
              {
                "categoryId": 10000798,
                "children": [],
                "leaf": true,
                "name": "Tillers & Cultivation",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Outdoor & Gardening Power Tools",
            "var": false
          },
          {
            "categoryId": 10000727,
            "children": [],
            "leaf": true,
            "name": "Plants, Seeds & Trees",
            "var": false
          },
          {
            "categoryId": 10000725,
            "children": [],
            "leaf": true,
            "name": "Ponds & Water Features",
            "var": false
          },
          {
            "categoryId": 10000726,
            "children": [],
            "leaf": true,
            "name": "Pots, Planters & Urns",
            "var": false
          },
          {
            "categoryId": 10000722,
            "children": [],
            "leaf": true,
            "name": "Watering Systems & Garden Hoses",
            "var": false
          },
          {
            "categoryId": 10000728,
            "children": [],
            "leaf": true,
            "name": "Weeds & Pest Control",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Lawn & Garden",
        "var": false
      },
      {
        "categoryId": 10000356,
        "children": [],
        "leaf": true,
        "name": "Measuring & Levelling",
        "var": false
      },
      {
        "categoryId": 10000370,
        "children": [
          {
            "categoryId": 10000635,
            "children": [
              {
                "categoryId": 10000791,
                "children": [],
                "leaf": true,
                "name": "BBQ & Grills",
                "var": false
              },
              {
                "categoryId": 10000792,
                "children": [],
                "leaf": true,
                "name": "BBQ Accessories",
                "var": false
              },
              {
                "categoryId": 10000793,
                "children": [],
                "leaf": true,
                "name": "Outdoor Diningware",
                "var": false
              }
            ],
            "leaf": false,
            "name": "BBQ & Outdoor Dining",
            "var": false
          },
          {
            "categoryId": 10000637,
            "children": [],
            "leaf": true,
            "name": "Electric Insect Killers",
            "var": false
          },
          {
            "categoryId": 10000814,
            "children": [],
            "leaf": true,
            "name": "Patio Furniture",
            "var": false
          },
          {
            "categoryId": 10000636,
            "children": [
              {
                "categoryId": 10000802,
                "children": [],
                "leaf": true,
                "name": "Pool Accessories",
                "var": false
              },
              {
                "categoryId": 10000800,
                "children": [],
                "leaf": true,
                "name": "Pools",
                "var": false
              },
              {
                "categoryId": 10000803,
                "children": [],
                "leaf": true,
                "name": "Pools & Spa Cleaning",
                "var": false
              },
              {
                "categoryId": 10000801,
                "children": [],
                "leaf": true,
                "name": "Spas",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Pools & Spas",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Outdoor",
        "var": false
      },
      {
        "categoryId": 10000347,
        "children": [
          {
            "categoryId": 10000478,
            "children": [],
            "leaf": true,
            "name": "Adhesives & Tape",
            "var": false
          },
          {
            "categoryId": 10000479,
            "children": [],
            "leaf": true,
            "name": "Caulking",
            "var": false
          },
          {
            "categoryId": 10002957,
            "children": [],
            "leaf": true,
            "name": "Cement Mix",
            "var": false
          },
          {
            "categoryId": 10000445,
            "children": [
              {
                "categoryId": 10000781,
                "children": [],
                "leaf": true,
                "name": "Carpet Flooring",
                "var": false
              },
              {
                "categoryId": 10000782,
                "children": [],
                "leaf": true,
                "name": "Flooring Accessories & Adhesives",
                "var": false
              },
              {
                "categoryId": 10000780,
                "children": [],
                "leaf": true,
                "name": "Laminate Flooring",
                "var": false
              },
              {
                "categoryId": 10000777,
                "children": [],
                "leaf": true,
                "name": "Tile Flooring",
                "var": false
              },
              {
                "categoryId": 10000778,
                "children": [],
                "leaf": true,
                "name": "Timber & Natural Flooring",
                "var": false
              },
              {
                "categoryId": 10000779,
                "children": [],
                "leaf": true,
                "name": "Vinyl Flooring",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Flooring",
            "var": false
          },
          {
            "categoryId": 10000476,
            "children": [],
            "leaf": true,
            "name": "Paint & Primers",
            "var": true
          },
          {
            "categoryId": 10000477,
            "children": [],
            "leaf": true,
            "name": "Paint Tools & Accessories",
            "var": false
          },
          {
            "categoryId": 10000480,
            "children": [],
            "leaf": true,
            "name": "Sealants",
            "var": false
          },
          {
            "categoryId": 10000482,
            "children": [],
            "leaf": true,
            "name": "Wallpaper",
            "var": false
          },
          {
            "categoryId": 10000481,
            "children": [],
            "leaf": true,
            "name": "Waterproofing",
            "var": false
          },
          {
            "categoryId": 10000475,
            "children": [],
            "leaf": true,
            "name": "Window Treatments",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Painting & Decorating",
        "var": false
      },
      {
        "categoryId": 10000358,
        "children": [
          {
            "categoryId": 10000515,
            "children": [],
            "leaf": true,
            "name": "Combo Kits",
            "var": false
          },
          {
            "categoryId": 10000516,
            "children": [],
            "leaf": true,
            "name": "Demolition Hammers",
            "var": false
          },
          {
            "categoryId": 10000517,
            "children": [],
            "leaf": true,
            "name": "Drills & Drivers",
            "var": false
          },
          {
            "categoryId": 10000529,
            "children": [],
            "leaf": true,
            "name": "Dust Extractors",
            "var": false
          },
          {
            "categoryId": 10000533,
            "children": [],
            "leaf": true,
            "name": "Grease Guns",
            "var": false
          },
          {
            "categoryId": 10000518,
            "children": [],
            "leaf": true,
            "name": "Grinders",
            "var": false
          },
          {
            "categoryId": 10000519,
            "children": [],
            "leaf": true,
            "name": "Heat Guns",
            "var": false
          },
          {
            "categoryId": 10000520,
            "children": [],
            "leaf": true,
            "name": "Impact Drivers",
            "var": false
          },
          {
            "categoryId": 10000521,
            "children": [],
            "leaf": true,
            "name": "Impact Wrenches",
            "var": false
          },
          {
            "categoryId": 10000522,
            "children": [],
            "leaf": true,
            "name": "Nail Guns",
            "var": false
          },
          {
            "categoryId": 10000532,
            "children": [],
            "leaf": true,
            "name": "Oscillating Tools",
            "var": false
          },
          {
            "categoryId": 10000524,
            "children": [],
            "leaf": true,
            "name": "Planers & Jointers",
            "var": false
          },
          {
            "categoryId": 10000523,
            "children": [],
            "leaf": true,
            "name": "Polishers",
            "var": false
          },
          {
            "categoryId": 10000525,
            "children": [],
            "leaf": true,
            "name": "Power Routers",
            "var": false
          },
          {
            "categoryId": 10000526,
            "children": [],
            "leaf": true,
            "name": "Power Sanders",
            "var": false
          },
          {
            "categoryId": 10000527,
            "children": [],
            "leaf": true,
            "name": "Power Saws",
            "var": false
          },
          {
            "categoryId": 10000534,
            "children": [],
            "leaf": true,
            "name": "Power Tool Accessory",
            "var": false
          },
          {
            "categoryId": 10000531,
            "children": [],
            "leaf": true,
            "name": "Rotary Tools",
            "var": false
          },
          {
            "categoryId": 10000528,
            "children": [],
            "leaf": true,
            "name": "Shears & Nibbles",
            "var": false
          },
          {
            "categoryId": 10000530,
            "children": [],
            "leaf": true,
            "name": "Welding Tools",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Power Tools",
        "var": false
      },
      {
        "categoryId": 10000349,
        "children": [
          {
            "categoryId": 10000454,
            "children": [],
            "leaf": true,
            "name": "Ear Protection",
            "var": false
          },
          {
            "categoryId": 10000455,
            "children": [],
            "leaf": true,
            "name": "Eye Protection",
            "var": false
          },
          {
            "categoryId": 10000456,
            "children": [],
            "leaf": true,
            "name": "Head Protection",
            "var": false
          },
          {
            "categoryId": 10000457,
            "children": [],
            "leaf": true,
            "name": "Knee & Elbow Pads",
            "var": false
          },
          {
            "categoryId": 10000458,
            "children": [],
            "leaf": true,
            "name": "Masks & Filters",
            "var": false
          },
          {
            "categoryId": 10000459,
            "children": [],
            "leaf": true,
            "name": "Safety Cones & Tape",
            "var": false
          },
          {
            "categoryId": 10000460,
            "children": [],
            "leaf": true,
            "name": "Safety Gloves",
            "var": false
          },
          {
            "categoryId": 10000466,
            "children": [],
            "leaf": true,
            "name": "Safety Harnesses & Supports",
            "var": false
          },
          {
            "categoryId": 10000462,
            "children": [],
            "leaf": true,
            "name": "Work Coveralls & Scrubs",
            "var": false
          },
          {
            "categoryId": 10000464,
            "children": [],
            "leaf": true,
            "name": "Work Jackets & Outerware",
            "var": false
          },
          {
            "categoryId": 10000461,
            "children": [],
            "leaf": true,
            "name": "Work Pants & Shorts",
            "var": false
          },
          {
            "categoryId": 10000465,
            "children": [],
            "leaf": true,
            "name": "Work Shoes",
            "var": false
          },
          {
            "categoryId": 10000463,
            "children": [],
            "leaf": true,
            "name": "Work Tops & Shirts",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Protective Clothing & Equipment",
        "var": false
      },
      {
        "categoryId": 10000350,
        "children": [
          {
            "categoryId": 10000467,
            "children": [],
            "leaf": true,
            "name": "Door hardware & locks",
            "var": false
          },
          {
            "categoryId": 10000468,
            "children": [],
            "leaf": true,
            "name": "Gate Hardware",
            "var": false
          },
          {
            "categoryId": 10000470,
            "children": [],
            "leaf": true,
            "name": "Household Security Systems",
            "var": false
          },
          {
            "categoryId": 10000469,
            "children": [],
            "leaf": true,
            "name": "Security Safes",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Security",
        "var": false
      },
      {
        "categoryId": 10000354,
        "children": [],
        "leaf": true,
        "name": "Tool Storage & Shelving",
        "var": false
      },
      {
        "categoryId": 10000352,
        "children": [
          {
            "categoryId": 10000483,
            "children": [],
            "leaf": true,
            "name": "Rechargeable & Flashlights",
            "var": false
          },
          {
            "categoryId": 10000484,
            "children": [],
            "leaf": true,
            "name": "Work & Safety Lights",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Work Lights",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Tools, DIY & Outdoor",
    "var": false
  },
  {
    "categoryId": 9067,
    "children": [
      {
        "categoryId": 9068,
        "children": [
          {
            "categoryId": 9069,
            "children": [],
            "leaf": true,
            "name": "Action Figures",
            "var": false
          },
          {
            "categoryId": 9070,
            "children": [],
            "leaf": true,
            "name": "Collectibles",
            "var": false
          },
          {
            "categoryId": 9071,
            "children": [],
            "leaf": true,
            "name": "Mini - Figures",
            "var": false
          },
          {
            "categoryId": 9072,
            "children": [],
            "leaf": true,
            "name": "Video Game Characters",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Action Figures & Collectibles",
        "var": false
      },
      {
        "categoryId": 9073,
        "children": [
          {
            "categoryId": 9074,
            "children": [],
            "leaf": true,
            "name": "Beads",
            "var": false
          },
          {
            "categoryId": 9075,
            "children": [],
            "leaf": true,
            "name": "Blackboards & Whiteboards",
            "var": false
          },
          {
            "categoryId": 9077,
            "children": [],
            "leaf": true,
            "name": "Clay & Dough",
            "var": false
          },
          {
            "categoryId": 9078,
            "children": [],
            "leaf": true,
            "name": "Craft Kits",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Arts & Crafts for Kids",
        "var": false
      },
      {
        "categoryId": 9088,
        "children": [
          {
            "categoryId": 9089,
            "children": [],
            "leaf": true,
            "name": "Activity Gym & Playmats",
            "var": false
          },
          {
            "categoryId": 9090,
            "children": [],
            "leaf": true,
            "name": "Balls",
            "var": false
          },
          {
            "categoryId": 9091,
            "children": [],
            "leaf": true,
            "name": "Bath Toys",
            "var": false
          },
          {
            "categoryId": 9093,
            "children": [],
            "leaf": true,
            "name": "Crib Toys & Attachments",
            "var": false
          },
          {
            "categoryId": 9094,
            "children": [],
            "leaf": true,
            "name": "Early Learning",
            "var": false
          },
          {
            "categoryId": 9095,
            "children": [],
            "leaf": true,
            "name": "Hammering & Pounding Toys",
            "var": false
          },
          {
            "categoryId": 9096,
            "children": [],
            "leaf": true,
            "name": "Indoor Climbers & Play Structures",
            "var": false
          },
          {
            "categoryId": 9097,
            "children": [],
            "leaf": true,
            "name": "Mirrors",
            "var": false
          },
          {
            "categoryId": 9098,
            "children": [],
            "leaf": true,
            "name": "Music & Sound",
            "var": false
          },
          {
            "categoryId": 9099,
            "children": [],
            "leaf": true,
            "name": "Push & Pull Toys",
            "var": false
          },
          {
            "categoryId": 9100,
            "children": [],
            "leaf": true,
            "name": "Rattles",
            "var": false
          },
          {
            "categoryId": 9101,
            "children": [],
            "leaf": true,
            "name": "Rocking & Spring Ride-Ons",
            "var": false
          },
          {
            "categoryId": 9102,
            "children": [],
            "leaf": true,
            "name": "Shape Sorters",
            "var": false
          },
          {
            "categoryId": 9103,
            "children": [],
            "leaf": true,
            "name": "Spinning Tops",
            "var": false
          },
          {
            "categoryId": 9104,
            "children": [],
            "leaf": true,
            "name": "Stacking & Nesting Toys",
            "var": false
          },
          {
            "categoryId": 9105,
            "children": [],
            "leaf": true,
            "name": "Stick Horses",
            "var": false
          },
          {
            "categoryId": 9106,
            "children": [],
            "leaf": true,
            "name": "Teaching Clocks",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Baby & Toddler Toys",
        "var": false
      },
      {
        "categoryId": 9225,
        "children": [],
        "leaf": true,
        "name": "Ball Pits & Accessories",
        "var": false
      },
      {
        "categoryId": 9226,
        "children": [
          {
            "categoryId": 9227,
            "children": [],
            "leaf": true,
            "name": "Bean Bag Game Sets",
            "var": false
          },
          {
            "categoryId": 9228,
            "children": [],
            "leaf": true,
            "name": "Foot Bags",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Beanbags & Foot Bags",
        "var": false
      },
      {
        "categoryId": 9107,
        "children": [
          {
            "categoryId": 9108,
            "children": [],
            "leaf": true,
            "name": "Building Sets",
            "var": false
          },
          {
            "categoryId": 9109,
            "children": [],
            "leaf": true,
            "name": "Gear Sets",
            "var": false
          },
          {
            "categoryId": 9110,
            "children": [],
            "leaf": true,
            "name": "Marble Runs",
            "var": false
          },
          {
            "categoryId": 9111,
            "children": [],
            "leaf": true,
            "name": "Stacking Blocks",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Blocks & Building Toys",
        "var": false
      },
      {
        "categoryId": 9112,
        "children": [
          {
            "categoryId": 9113,
            "children": [],
            "leaf": true,
            "name": "Doll Accessories",
            "var": false
          },
          {
            "categoryId": 9114,
            "children": [],
            "leaf": true,
            "name": "Dollhouse Accessories",
            "var": false
          },
          {
            "categoryId": 9115,
            "children": [],
            "leaf": true,
            "name": "Dollhouses",
            "var": false
          },
          {
            "categoryId": 9116,
            "children": [],
            "leaf": true,
            "name": "Dolls",
            "var": false
          },
          {
            "categoryId": 9117,
            "children": [],
            "leaf": true,
            "name": "Playsets",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Dolls & Accessories",
        "var": false
      },
      {
        "categoryId": 9118,
        "children": [
          {
            "categoryId": 9119,
            "children": [
              {
                "categoryId": 9120,
                "children": [],
                "leaf": true,
                "name": "Bracelets",
                "var": false
              },
              {
                "categoryId": 9121,
                "children": [],
                "leaf": true,
                "name": "Jewelry Music Boxes",
                "var": false
              },
              {
                "categoryId": 9122,
                "children": [],
                "leaf": true,
                "name": "Makeup",
                "var": false
              },
              {
                "categoryId": 9123,
                "children": [],
                "leaf": true,
                "name": "Necklaces",
                "var": false
              },
              {
                "categoryId": 9124,
                "children": [],
                "leaf": true,
                "name": "Purses",
                "var": false
              },
              {
                "categoryId": 9125,
                "children": [],
                "leaf": true,
                "name": "Rings",
                "var": false
              },
              {
                "categoryId": 9126,
                "children": [],
                "leaf": true,
                "name": "Vanity Cases",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Beauty & Fashion",
            "var": false
          },
          {
            "categoryId": 9127,
            "children": [
              {
                "categoryId": 9138,
                "children": [],
                "leaf": true,
                "name": "Beards & Moustache",
                "var": false
              },
              {
                "categoryId": 9134,
                "children": [],
                "leaf": true,
                "name": "Colour Contact Lenses",
                "var": false
              },
              {
                "categoryId": 9135,
                "children": [],
                "leaf": true,
                "name": "Eyelashes & Eyeglasses",
                "var": false
              },
              {
                "categoryId": 9133,
                "children": [],
                "leaf": true,
                "name": "Face paint",
                "var": false
              },
              {
                "categoryId": 9140,
                "children": [],
                "leaf": true,
                "name": "Gift bags",
                "var": false
              },
              {
                "categoryId": 9137,
                "children": [],
                "leaf": true,
                "name": "Hair & Nails",
                "var": false
              },
              {
                "categoryId": 9131,
                "children": [],
                "leaf": true,
                "name": "Hats & Caps",
                "var": false
              },
              {
                "categoryId": 9128,
                "children": [],
                "leaf": true,
                "name": "Kids & Baby",
                "var": false
              },
              {
                "categoryId": 9129,
                "children": [],
                "leaf": true,
                "name": "Men",
                "var": false
              },
              {
                "categoryId": 9132,
                "children": [],
                "leaf": true,
                "name": "Shoes",
                "var": false
              },
              {
                "categoryId": 9139,
                "children": [],
                "leaf": true,
                "name": "Tableware & Serving",
                "var": false
              },
              {
                "categoryId": 9136,
                "children": [],
                "leaf": true,
                "name": "Wigs & Masks",
                "var": false
              },
              {
                "categoryId": 9130,
                "children": [],
                "leaf": true,
                "name": "Women",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Costumes",
            "var": false
          },
          {
            "categoryId": 10002722,
            "children": [
              {
                "categoryId": 10002772,
                "children": [],
                "leaf": true,
                "name": "Construction Tools",
                "var": false
              },
              {
                "categoryId": 10002773,
                "children": [
                  {
                    "categoryId": 10002829,
                    "children": [],
                    "leaf": true,
                    "name": "Cash Registers",
                    "var": false
                  },
                  {
                    "categoryId": 10002830,
                    "children": [],
                    "leaf": true,
                    "name": "Shopping Carts",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Grocery Shopping",
                "var": false
              },
              {
                "categoryId": 10002774,
                "children": [],
                "leaf": true,
                "name": "Housekeeping",
                "var": false
              },
              {
                "categoryId": 10002775,
                "children": [],
                "leaf": true,
                "name": "Kitchen Toys",
                "var": false
              },
              {
                "categoryId": 10002776,
                "children": [],
                "leaf": true,
                "name": "Magnet & Felt Playboards",
                "var": false
              },
              {
                "categoryId": 10002777,
                "children": [],
                "leaf": true,
                "name": "Medical Kits",
                "var": false
              },
              {
                "categoryId": 10002778,
                "children": [],
                "leaf": true,
                "name": "Money & Banking",
                "var": false
              },
              {
                "categoryId": 10002779,
                "children": [],
                "leaf": true,
                "name": "Paper & Magnetic Dolls",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Playsets",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Dress Up & Pretend Play",
        "var": false
      },
      {
        "categoryId": 9158,
        "children": [
          {
            "categoryId": 10002723,
            "children": [],
            "leaf": true,
            "name": "App-Enabled Toys",
            "var": false
          },
          {
            "categoryId": 9164,
            "children": [],
            "leaf": true,
            "name": "Calculator, Dictionairies & Translators",
            "var": false
          },
          {
            "categoryId": 9159,
            "children": [],
            "leaf": true,
            "name": "Cameras & Camcorders",
            "var": false
          },
          {
            "categoryId": 9163,
            "children": [],
            "leaf": true,
            "name": "Computers",
            "var": false
          },
          {
            "categoryId": 9160,
            "children": [],
            "leaf": true,
            "name": "Dance Mats",
            "var": false
          },
          {
            "categoryId": 9165,
            "children": [],
            "leaf": true,
            "name": "Music Players & Karaoke",
            "var": false
          },
          {
            "categoryId": 9166,
            "children": [],
            "leaf": true,
            "name": "Personal Video Players & Accessories",
            "var": false
          },
          {
            "categoryId": 9167,
            "children": [],
            "leaf": true,
            "name": "Plug & Play Video Games",
            "var": false
          },
          {
            "categoryId": 9168,
            "children": [],
            "leaf": true,
            "name": "RC Figures & Robots",
            "var": false
          },
          {
            "categoryId": 9162,
            "children": [],
            "leaf": true,
            "name": "Tablets",
            "var": false
          },
          {
            "categoryId": 9169,
            "children": [],
            "leaf": true,
            "name": "Walkie Talkies",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Electronic Toys",
        "var": false
      },
      {
        "categoryId": 9170,
        "children": [
          {
            "categoryId": 9171,
            "children": [],
            "leaf": true,
            "name": "Basic & Life Skills Toys",
            "var": false
          },
          {
            "categoryId": 9172,
            "children": [
              {
                "categoryId": 9173,
                "children": [],
                "leaf": true,
                "name": "Activity Gym & Playmats",
                "var": false
              },
              {
                "categoryId": 9174,
                "children": [],
                "leaf": true,
                "name": "Shapes & Colors",
                "var": false
              },
              {
                "categoryId": 9175,
                "children": [],
                "leaf": true,
                "name": "Sorting & Stacking",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Early Development Toys",
            "var": false
          },
          {
            "categoryId": 9176,
            "children": [
              {
                "categoryId": 9177,
                "children": [],
                "leaf": true,
                "name": "Cartridges & Books",
                "var": false
              },
              {
                "categoryId": 9178,
                "children": [],
                "leaf": true,
                "name": "Electronic Learning Toys",
                "var": false
              },
              {
                "categoryId": 9179,
                "children": [],
                "leaf": true,
                "name": "Electronic Software & Books",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Electronics",
            "var": false
          },
          {
            "categoryId": 9180,
            "children": [],
            "leaf": true,
            "name": "Flash Cards",
            "var": false
          },
          {
            "categoryId": 9182,
            "children": [],
            "leaf": true,
            "name": "Habitats",
            "var": false
          },
          {
            "categoryId": 9183,
            "children": [],
            "leaf": true,
            "name": "History",
            "var": false
          },
          {
            "categoryId": 9185,
            "children": [
              {
                "categoryId": 9187,
                "children": [],
                "leaf": true,
                "name": "Drum & Percussion",
                "var": false
              },
              {
                "categoryId": 9186,
                "children": [],
                "leaf": true,
                "name": "Guitars",
                "var": false
              },
              {
                "categoryId": 9188,
                "children": [],
                "leaf": true,
                "name": "Keyboards & Pianos",
                "var": false
              },
              {
                "categoryId": 9190,
                "children": [],
                "leaf": true,
                "name": "Musical Toys",
                "var": false
              },
              {
                "categoryId": 9189,
                "children": [],
                "leaf": true,
                "name": "Wind Instruments",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Musical Instruments",
            "var": false
          },
          {
            "categoryId": 9191,
            "children": [
              {
                "categoryId": 9192,
                "children": [],
                "leaf": true,
                "name": "Diaries, Journals & Notebooks",
                "var": false
              },
              {
                "categoryId": 9193,
                "children": [],
                "leaf": true,
                "name": "Magnetic Letters & Words",
                "var": false
              },
              {
                "categoryId": 10002780,
                "children": [],
                "leaf": true,
                "name": "Stationery",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Reading & Writing",
            "var": false
          },
          {
            "categoryId": 10002725,
            "children": [],
            "leaf": true,
            "name": "STEM Toys",
            "var": false
          },
          {
            "categoryId": 9194,
            "children": [
              {
                "categoryId": 9195,
                "children": [],
                "leaf": true,
                "name": "Archaeology & Paleontology ",
                "var": false
              },
              {
                "categoryId": 9196,
                "children": [],
                "leaf": true,
                "name": "Biology ",
                "var": false
              },
              {
                "categoryId": 9197,
                "children": [],
                "leaf": true,
                "name": "Chemistry ",
                "var": false
              },
              {
                "categoryId": 9198,
                "children": [],
                "leaf": true,
                "name": "Geology & Earth Sciences ",
                "var": false
              },
              {
                "categoryId": 9199,
                "children": [],
                "leaf": true,
                "name": "Microscopes ",
                "var": false
              },
              {
                "categoryId": 9200,
                "children": [],
                "leaf": true,
                "name": "Physics ",
                "var": false
              },
              {
                "categoryId": 9201,
                "children": [],
                "leaf": true,
                "name": "Rock Tumblers ",
                "var": false
              },
              {
                "categoryId": 9202,
                "children": [],
                "leaf": true,
                "name": "Telescopes ",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Science ",
            "var": false
          },
          {
            "categoryId": 9203,
            "children": [],
            "leaf": true,
            "name": "Solar",
            "var": false
          },
          {
            "categoryId": 10002724,
            "children": [],
            "leaf": true,
            "name": "Wooden Toys",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Learning & Education",
        "var": false
      },
      {
        "categoryId": 9204,
        "children": [
          {
            "categoryId": 9208,
            "children": [],
            "leaf": true,
            "name": "Aprons",
            "var": false
          },
          {
            "categoryId": 9205,
            "children": [],
            "leaf": true,
            "name": "Balloons",
            "var": false
          },
          {
            "categoryId": 9206,
            "children": [],
            "leaf": true,
            "name": "Banners, Streamers & Confetti",
            "var": false
          },
          {
            "categoryId": 9207,
            "children": [],
            "leaf": true,
            "name": "Cake & Cupcake Toppers",
            "var": false
          },
          {
            "categoryId": 9209,
            "children": [],
            "leaf": true,
            "name": "Invitations & Cards",
            "var": false
          },
          {
            "categoryId": 9210,
            "children": [],
            "leaf": true,
            "name": "Party & Games Crafts",
            "var": false
          },
          {
            "categoryId": 9211,
            "children": [],
            "leaf": true,
            "name": "Party Hats",
            "var": false
          },
          {
            "categoryId": 9212,
            "children": [],
            "leaf": true,
            "name": "Party Packs & Sets",
            "var": false
          },
          {
            "categoryId": 9213,
            "children": [],
            "leaf": true,
            "name": "Party Tableware",
            "var": false
          },
          {
            "categoryId": 9214,
            "children": [],
            "leaf": true,
            "name": "Pinatas",
            "var": false
          },
          {
            "categoryId": 9215,
            "children": [],
            "leaf": true,
            "name": "Table covers & Centre Pieces",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Party Supplies",
        "var": false
      },
      {
        "categoryId": 9216,
        "children": [
          {
            "categoryId": 9217,
            "children": [],
            "leaf": true,
            "name": "3-D Puzzles",
            "var": false
          },
          {
            "categoryId": 9218,
            "children": [],
            "leaf": true,
            "name": "Brain Teasers",
            "var": false
          },
          {
            "categoryId": 9219,
            "children": [],
            "leaf": true,
            "name": "Floor Puzzles",
            "var": false
          },
          {
            "categoryId": 9220,
            "children": [],
            "leaf": true,
            "name": "Jigsaw Puzzles",
            "var": false
          },
          {
            "categoryId": 9221,
            "children": [],
            "leaf": true,
            "name": "Pegged Puzzles",
            "var": false
          },
          {
            "categoryId": 9222,
            "children": [],
            "leaf": true,
            "name": "Puzzle Accessories",
            "var": false
          },
          {
            "categoryId": 9223,
            "children": [],
            "leaf": true,
            "name": "Puzzle Play Mats",
            "var": false
          },
          {
            "categoryId": 9224,
            "children": [],
            "leaf": true,
            "name": "Sudoku Puzzles",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Puzzle",
        "var": false
      },
      {
        "categoryId": 9350,
        "children": [
          {
            "categoryId": 9351,
            "children": [],
            "leaf": true,
            "name": "Die-Cast Vehicles",
            "var": false
          },
          {
            "categoryId": 9352,
            "children": [
              {
                "categoryId": 9354,
                "children": [],
                "leaf": true,
                "name": "Accessories & Batteries",
                "var": false
              },
              {
                "categoryId": 9353,
                "children": [],
                "leaf": true,
                "name": "Drones & Quadcopters",
                "var": false
              },
              {
                "categoryId": 10002788,
                "children": [],
                "leaf": true,
                "name": "Starter Drones",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Drones & Accessories",
            "var": false
          },
          {
            "categoryId": 10002727,
            "children": [],
            "leaf": true,
            "name": "Helicopters",
            "var": false
          },
          {
            "categoryId": 9355,
            "children": [
              {
                "categoryId": 9358,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 9356,
                "children": [],
                "leaf": true,
                "name": "Buidings & Scenery",
                "var": false
              },
              {
                "categoryId": 9359,
                "children": [],
                "leaf": true,
                "name": "Train Cars & Sets",
                "var": false
              },
              {
                "categoryId": 9357,
                "children": [],
                "leaf": true,
                "name": "Train Tracks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Play Trains & Railway Sets",
            "var": false
          },
          {
            "categoryId": 9360,
            "children": [
              {
                "categoryId": 9363,
                "children": [],
                "leaf": true,
                "name": "Pull-Backs",
                "var": false
              },
              {
                "categoryId": 9362,
                "children": [],
                "leaf": true,
                "name": "Vehicle Playsets",
                "var": false
              },
              {
                "categoryId": 9361,
                "children": [],
                "leaf": true,
                "name": "Vehicles",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Play Vehicles",
            "var": false
          },
          {
            "categoryId": 9364,
            "children": [
              {
                "categoryId": 9365,
                "children": [],
                "leaf": true,
                "name": "Battery Packs & Chargers",
                "var": false
              },
              {
                "categoryId": 9366,
                "children": [],
                "leaf": true,
                "name": "RC Vehicles",
                "var": false
              }
            ],
            "leaf": false,
            "name": "RC Vehicles & Batteries",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Remote Control & Play Vehicles",
        "var": false
      },
      {
        "categoryId": 10002680,
        "children": [
          {
            "categoryId": 10002729,
            "children": [],
            "leaf": true,
            "name": "Slime Toys",
            "var": false
          },
          {
            "categoryId": 10002730,
            "children": [],
            "leaf": true,
            "name": "Squishy Toys",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Slime & Squishy Toys",
        "var": false
      },
      {
        "categoryId": 9229,
        "children": [
          {
            "categoryId": 10002728,
            "children": [],
            "leaf": true,
            "name": "Fidget Spinners & Cubes",
            "var": false
          },
          {
            "categoryId": 9230,
            "children": [
              {
                "categoryId": 9231,
                "children": [],
                "leaf": true,
                "name": "Airplane Construction Kits",
                "var": false
              },
              {
                "categoryId": 9232,
                "children": [],
                "leaf": true,
                "name": "Toy Parachute Figures",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Flying Toys",
            "var": false
          },
          {
            "categoryId": 9233,
            "children": [],
            "leaf": true,
            "name": "Inflatable Bouncers",
            "var": false
          },
          {
            "categoryId": 9234,
            "children": [
              {
                "categoryId": 9237,
                "children": [],
                "leaf": true,
                "name": "Wind Spinners",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kites & Wind Spinners",
            "var": false
          },
          {
            "categoryId": 9238,
            "children": [
              {
                "categoryId": 9239,
                "children": [],
                "leaf": true,
                "name": "Badminton",
                "var": false
              },
              {
                "categoryId": 9240,
                "children": [],
                "leaf": true,
                "name": "Bocce",
                "var": false
              },
              {
                "categoryId": 9241,
                "children": [],
                "leaf": true,
                "name": "Croquet",
                "var": false
              },
              {
                "categoryId": 9242,
                "children": [],
                "leaf": true,
                "name": "Flying Discs",
                "var": false
              },
              {
                "categoryId": 9243,
                "children": [],
                "leaf": true,
                "name": "Horseshoes",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Lawn Games",
            "var": false
          },
          {
            "categoryId": 9244,
            "children": [],
            "leaf": true,
            "name": "Marble Games",
            "var": false
          },
          {
            "categoryId": 9245,
            "children": [
              {
                "categoryId": 9265,
                "children": [
                  {
                    "categoryId": 9266,
                    "children": [],
                    "leaf": true,
                    "name": "Blaster Guns & Slingshots",
                    "var": false
                  },
                  {
                    "categoryId": 9267,
                    "children": [],
                    "leaf": true,
                    "name": "Parts and Refills",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Blaster toys",
                "var": false
              },
              {
                "categoryId": 9264,
                "children": [],
                "leaf": true,
                "name": "Foam Play",
                "var": false
              },
              {
                "categoryId": 9263,
                "children": [],
                "leaf": true,
                "name": "Inflatable Bouncer",
                "var": false
              },
              {
                "categoryId": 9248,
                "children": [],
                "leaf": true,
                "name": "Kids Bikes & Accessories",
                "var": false
              },
              {
                "categoryId": 9247,
                "children": [],
                "leaf": true,
                "name": "Kids Scooters",
                "var": false
              },
              {
                "categoryId": 9249,
                "children": [],
                "leaf": true,
                "name": "Kids Skateboards",
                "var": false
              },
              {
                "categoryId": 9250,
                "children": [],
                "leaf": true,
                "name": "Kids Skates",
                "var": false
              },
              {
                "categoryId": 9246,
                "children": [],
                "leaf": true,
                "name": "Kids Tricycles",
                "var": false
              },
              {
                "categoryId": 9252,
                "children": [],
                "leaf": true,
                "name": "Pedal Cars",
                "var": false
              },
              {
                "categoryId": 9251,
                "children": [],
                "leaf": true,
                "name": "Ride-on",
                "var": false
              },
              {
                "categoryId": 9262,
                "children": [],
                "leaf": true,
                "name": "Trampoline & Accessories",
                "var": false
              },
              {
                "categoryId": 9253,
                "children": [],
                "leaf": true,
                "name": "Wagons",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Outdoor Toys",
            "var": false
          },
          {
            "categoryId": 9268,
            "children": [
              {
                "categoryId": 9269,
                "children": [],
                "leaf": true,
                "name": "Freestanding Climbers",
                "var": false
              },
              {
                "categoryId": 9270,
                "children": [],
                "leaf": true,
                "name": "Freestanding Slides",
                "var": false
              },
              {
                "categoryId": 9271,
                "children": [],
                "leaf": true,
                "name": "Parts & Hardware",
                "var": false
              },
              {
                "categoryId": 9272,
                "children": [],
                "leaf": true,
                "name": "Play & Swing Sets",
                "var": false
              },
              {
                "categoryId": 9273,
                "children": [],
                "leaf": true,
                "name": "Play Set Attachments",
                "var": false
              },
              {
                "categoryId": 9274,
                "children": [],
                "leaf": true,
                "name": "Seesaws",
                "var": false
              },
              {
                "categoryId": 9275,
                "children": [],
                "leaf": true,
                "name": "Tetherball",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Play Sets & Playground Equipment",
            "var": false
          },
          {
            "categoryId": 9276,
            "children": [],
            "leaf": true,
            "name": "Play Tents & Tunnels",
            "var": false
          },
          {
            "categoryId": 9277,
            "children": [],
            "leaf": true,
            "name": "Playhouses",
            "var": false
          },
          {
            "categoryId": 9278,
            "children": [],
            "leaf": true,
            "name": "Pogo Sticks & Hoppers",
            "var": false
          },
          {
            "categoryId": 9279,
            "children": [],
            "leaf": true,
            "name": "Sleeping Bags",
            "var": false
          },
          {
            "categoryId": 9280,
            "children": [
              {
                "categoryId": 9281,
                "children": [
                  {
                    "categoryId": 9282,
                    "children": [],
                    "leaf": true,
                    "name": "Baseball Bats",
                    "var": false
                  },
                  {
                    "categoryId": 9283,
                    "children": [],
                    "leaf": true,
                    "name": "Baseballs",
                    "var": false
                  },
                  {
                    "categoryId": 9284,
                    "children": [],
                    "leaf": true,
                    "name": "Batting Gloves",
                    "var": false
                  },
                  {
                    "categoryId": 9285,
                    "children": [],
                    "leaf": true,
                    "name": "Gloves & Mitts",
                    "var": false
                  },
                  {
                    "categoryId": 9286,
                    "children": [],
                    "leaf": true,
                    "name": "Helmets",
                    "var": false
                  },
                  {
                    "categoryId": 9287,
                    "children": [],
                    "leaf": true,
                    "name": "Plastic Balls",
                    "var": false
                  },
                  {
                    "categoryId": 9288,
                    "children": [],
                    "leaf": true,
                    "name": "Protective Gear",
                    "var": false
                  },
                  {
                    "categoryId": 9289,
                    "children": [],
                    "leaf": true,
                    "name": "Softball Bats",
                    "var": false
                  },
                  {
                    "categoryId": 9290,
                    "children": [],
                    "leaf": true,
                    "name": "Softballs",
                    "var": false
                  },
                  {
                    "categoryId": 9291,
                    "children": [],
                    "leaf": true,
                    "name": "T-Ball",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Baseball & Softball",
                "var": false
              },
              {
                "categoryId": 9292,
                "children": [
                  {
                    "categoryId": 9293,
                    "children": [],
                    "leaf": true,
                    "name": "Backboards",
                    "var": false
                  },
                  {
                    "categoryId": 9294,
                    "children": [],
                    "leaf": true,
                    "name": "Basketballs",
                    "var": false
                  },
                  {
                    "categoryId": 9295,
                    "children": [],
                    "leaf": true,
                    "name": "Sets",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Basketball",
                "var": false
              },
              {
                "categoryId": 9296,
                "children": [
                  {
                    "categoryId": 9297,
                    "children": [],
                    "leaf": true,
                    "name": "Boxing Gloves",
                    "var": false
                  },
                  {
                    "categoryId": 9298,
                    "children": [],
                    "leaf": true,
                    "name": "Protective Gear",
                    "var": false
                  },
                  {
                    "categoryId": 9299,
                    "children": [],
                    "leaf": true,
                    "name": "Punching Bags",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Boxing",
                "var": false
              },
              {
                "categoryId": 9300,
                "children": [
                  {
                    "categoryId": 9301,
                    "children": [],
                    "leaf": true,
                    "name": "Footballs",
                    "var": false
                  },
                  {
                    "categoryId": 9302,
                    "children": [],
                    "leaf": true,
                    "name": "Protective gear",
                    "var": false
                  },
                  {
                    "categoryId": 9303,
                    "children": [],
                    "leaf": true,
                    "name": "Sets",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Football",
                "var": false
              },
              {
                "categoryId": 9304,
                "children": [
                  {
                    "categoryId": 9305,
                    "children": [],
                    "leaf": true,
                    "name": "Complete Sets",
                    "var": false
                  },
                  {
                    "categoryId": 9306,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Balls",
                    "var": false
                  },
                  {
                    "categoryId": 9307,
                    "children": [],
                    "leaf": true,
                    "name": "Golf Clubs",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Golf",
                "var": false
              },
              {
                "categoryId": 9308,
                "children": [
                  {
                    "categoryId": 9309,
                    "children": [],
                    "leaf": true,
                    "name": "Competition Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 9310,
                    "children": [],
                    "leaf": true,
                    "name": "Skill Shapes",
                    "var": false
                  },
                  {
                    "categoryId": 9311,
                    "children": [],
                    "leaf": true,
                    "name": "Tumbling Mats",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Gymnastics",
                "var": false
              },
              {
                "categoryId": 9312,
                "children": [
                  {
                    "categoryId": 9313,
                    "children": [],
                    "leaf": true,
                    "name": "Goalie Equipment",
                    "var": false
                  },
                  {
                    "categoryId": 9314,
                    "children": [],
                    "leaf": true,
                    "name": "Hockey Sticks",
                    "var": false
                  },
                  {
                    "categoryId": 9315,
                    "children": [],
                    "leaf": true,
                    "name": "Ice Hockey Skates",
                    "var": false
                  },
                  {
                    "categoryId": 9316,
                    "children": [],
                    "leaf": true,
                    "name": "Protective Gear",
                    "var": false
                  },
                  {
                    "categoryId": 9317,
                    "children": [],
                    "leaf": true,
                    "name": "Roller Hockey Skates",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Hockey",
                "var": false
              },
              {
                "categoryId": 9318,
                "children": [
                  {
                    "categoryId": 9319,
                    "children": [],
                    "leaf": true,
                    "name": "Ice Skates",
                    "var": false
                  },
                  {
                    "categoryId": 9320,
                    "children": [],
                    "leaf": true,
                    "name": "Snow Sleds",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Snow Sports",
                "var": false
              },
              {
                "categoryId": 9321,
                "children": [
                  {
                    "categoryId": 9323,
                    "children": [],
                    "leaf": true,
                    "name": "Protective Gear",
                    "var": false
                  },
                  {
                    "categoryId": 9324,
                    "children": [],
                    "leaf": true,
                    "name": "Soccer Balls",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Soccer",
                "var": false
              },
              {
                "categoryId": 9325,
                "children": [
                  {
                    "categoryId": 9326,
                    "children": [],
                    "leaf": true,
                    "name": "Racquets",
                    "var": false
                  },
                  {
                    "categoryId": 9327,
                    "children": [],
                    "leaf": true,
                    "name": "Tennis Balls",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Tennis",
                "var": false
              },
              {
                "categoryId": 9328,
                "children": [
                  {
                    "categoryId": 9330,
                    "children": [],
                    "leaf": true,
                    "name": "Protective Gear",
                    "var": false
                  },
                  {
                    "categoryId": 9331,
                    "children": [],
                    "leaf": true,
                    "name": "Sets",
                    "var": false
                  },
                  {
                    "categoryId": 9329,
                    "children": [],
                    "leaf": true,
                    "name": "Volleyballs",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Volleyball",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Sports Play",
            "var": false
          },
          {
            "categoryId": 10002726,
            "children": [
              {
                "categoryId": 10002786,
                "children": [],
                "leaf": true,
                "name": "Body Boards & Kneeboards",
                "var": false
              },
              {
                "categoryId": 10002783,
                "children": [],
                "leaf": true,
                "name": "Sand & Water Tables",
                "var": false
              },
              {
                "categoryId": 10002782,
                "children": [],
                "leaf": true,
                "name": "Sprinklers & Water Slides",
                "var": false
              },
              {
                "categoryId": 10002781,
                "children": [],
                "leaf": true,
                "name": "Swimming Pools",
                "var": false
              },
              {
                "categoryId": 10002787,
                "children": [],
                "leaf": true,
                "name": "Water Blaster & Soakers",
                "var": false
              },
              {
                "categoryId": 10002785,
                "children": [],
                "leaf": true,
                "name": "Water Toys & Pool Games",
                "var": false
              },
              {
                "categoryId": 10002784,
                "children": [],
                "leaf": true,
                "name": "Water Tubes, Pool Loungers & Rafts",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Swimming Pool & Water Toys",
            "var": false
          },
          {
            "categoryId": 9332,
            "children": [],
            "leaf": true,
            "name": "Toys Sports",
            "var": false
          },
          {
            "categoryId": 9339,
            "children": [],
            "leaf": true,
            "name": "Trampolines",
            "var": false
          },
          {
            "categoryId": 9340,
            "children": [],
            "leaf": true,
            "name": "Yo-yos & Kendamas",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Sports & Outdoor Play",
        "var": false
      },
      {
        "categoryId": 9341,
        "children": [],
        "leaf": true,
        "name": "Stuffed Toys",
        "var": false
      },
      {
        "categoryId": 9367,
        "children": [
          {
            "categoryId": 9368,
            "children": [],
            "leaf": true,
            "name": "Battling Tops",
            "var": false
          },
          {
            "categoryId": 9369,
            "children": [],
            "leaf": true,
            "name": "Board Games",
            "var": false
          },
          {
            "categoryId": 9370,
            "children": [],
            "leaf": true,
            "name": "Card Games",
            "var": false
          },
          {
            "categoryId": 9374,
            "children": [],
            "leaf": true,
            "name": "Dice & Gaming Dice",
            "var": false
          },
          {
            "categoryId": 9375,
            "children": [],
            "leaf": true,
            "name": "Floor Games",
            "var": false
          },
          {
            "categoryId": 9376,
            "children": [],
            "leaf": true,
            "name": "Game Collections",
            "var": false
          },
          {
            "categoryId": 9377,
            "children": [
              {
                "categoryId": 9378,
                "children": [],
                "leaf": true,
                "name": "Air Hockey",
                "var": false
              },
              {
                "categoryId": 9379,
                "children": [],
                "leaf": true,
                "name": "Billiards",
                "var": false
              },
              {
                "categoryId": 9380,
                "children": [],
                "leaf": true,
                "name": "Combination Table Games",
                "var": false
              },
              {
                "categoryId": 9381,
                "children": [],
                "leaf": true,
                "name": "Darts",
                "var": false
              },
              {
                "categoryId": 9382,
                "children": [],
                "leaf": true,
                "name": "Dome Hockey",
                "var": false
              },
              {
                "categoryId": 9383,
                "children": [],
                "leaf": true,
                "name": "Electronic Basketball",
                "var": false
              },
              {
                "categoryId": 9384,
                "children": [],
                "leaf": true,
                "name": "Foosball",
                "var": false
              },
              {
                "categoryId": 9385,
                "children": [],
                "leaf": true,
                "name": "Indoor Bowling",
                "var": false
              },
              {
                "categoryId": 9386,
                "children": [],
                "leaf": true,
                "name": "Mini Table Games",
                "var": false
              },
              {
                "categoryId": 9387,
                "children": [],
                "leaf": true,
                "name": "Pinball",
                "var": false
              },
              {
                "categoryId": 9388,
                "children": [],
                "leaf": true,
                "name": "Table Tennis",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Game Room Games",
            "var": false
          },
          {
            "categoryId": 9390,
            "children": [],
            "leaf": true,
            "name": "Tile Games",
            "var": false
          },
          {
            "categoryId": 9391,
            "children": [
              {
                "categoryId": 9395,
                "children": [],
                "leaf": true,
                "name": "Booster Packs",
                "var": false
              },
              {
                "categoryId": 9393,
                "children": [],
                "leaf": true,
                "name": "Cases",
                "var": false
              },
              {
                "categoryId": 9396,
                "children": [],
                "leaf": true,
                "name": "Counters",
                "var": false
              },
              {
                "categoryId": 9399,
                "children": [],
                "leaf": true,
                "name": "Decks & Sets",
                "var": false
              },
              {
                "categoryId": 9397,
                "children": [],
                "leaf": true,
                "name": "Gameplay Accessories",
                "var": false
              },
              {
                "categoryId": 9400,
                "children": [],
                "leaf": true,
                "name": "Lots",
                "var": false
              },
              {
                "categoryId": 9398,
                "children": [],
                "leaf": true,
                "name": "Playmats",
                "var": false
              },
              {
                "categoryId": 9394,
                "children": [],
                "leaf": true,
                "name": "Protective Sleeves",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Trading Card Games",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Traditional Games",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Toys & Games",
    "var": false
  },
  {
    "categoryId": 7686,
    "children": [
      {
        "categoryId": 7687,
        "children": [
          {
            "categoryId": 10001012,
            "children": [
              {
                "categoryId": 10001262,
                "children": [],
                "leaf": true,
                "name": "Cleaning solution",
                "var": false
              },
              {
                "categoryId": 10001259,
                "children": [],
                "leaf": true,
                "name": "Eyewear case",
                "var": false
              },
              {
                "categoryId": 10001260,
                "children": [],
                "leaf": true,
                "name": "Eyewear screwdriver",
                "var": false
              },
              {
                "categoryId": 10001261,
                "children": [],
                "leaf": true,
                "name": "Mirror cloth",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Accessories",
            "var": false
          },
          {
            "categoryId": 10001011,
            "children": [
              {
                "categoryId": 10001257,
                "children": [],
                "leaf": true,
                "name": "Clear",
                "var": false
              },
              {
                "categoryId": 10001258,
                "children": [],
                "leaf": true,
                "name": "Coloured",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Contact lens",
            "var": false
          },
          {
            "categoryId": 10001010,
            "children": [
              {
                "categoryId": 10001256,
                "children": [
                  {
                    "categoryId": 10001725,
                    "children": [],
                    "leaf": true,
                    "name": "Computer glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001727,
                    "children": [],
                    "leaf": true,
                    "name": "Fashion glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001728,
                    "children": [],
                    "leaf": true,
                    "name": "Lenses",
                    "var": false
                  },
                  {
                    "categoryId": 10001726,
                    "children": [],
                    "leaf": true,
                    "name": "Night vision glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001724,
                    "children": [],
                    "leaf": true,
                    "name": "Prescription glasses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Kids",
                "var": false
              },
              {
                "categoryId": 10001254,
                "children": [
                  {
                    "categoryId": 10001713,
                    "children": [],
                    "leaf": true,
                    "name": "Computer glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001716,
                    "children": [],
                    "leaf": true,
                    "name": "Fashion glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001717,
                    "children": [],
                    "leaf": true,
                    "name": "Lenses",
                    "var": false
                  },
                  {
                    "categoryId": 10001715,
                    "children": [],
                    "leaf": true,
                    "name": "Night vision glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001712,
                    "children": [],
                    "leaf": true,
                    "name": "Prescription glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001714,
                    "children": [],
                    "leaf": true,
                    "name": "Reading glasses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Men",
                "var": false
              },
              {
                "categoryId": 10001255,
                "children": [
                  {
                    "categoryId": 10001719,
                    "children": [],
                    "leaf": true,
                    "name": "Computer glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001722,
                    "children": [],
                    "leaf": true,
                    "name": "Fashion glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001723,
                    "children": [],
                    "leaf": true,
                    "name": "Lenses",
                    "var": false
                  },
                  {
                    "categoryId": 10001721,
                    "children": [],
                    "leaf": true,
                    "name": "Night vision glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001718,
                    "children": [],
                    "leaf": true,
                    "name": "Prescription glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001720,
                    "children": [],
                    "leaf": true,
                    "name": "Reading glasses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Unisex",
                "var": false
              },
              {
                "categoryId": 10001253,
                "children": [
                  {
                    "categoryId": 10001707,
                    "children": [],
                    "leaf": true,
                    "name": "Computer glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001710,
                    "children": [],
                    "leaf": true,
                    "name": "Fashion glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001711,
                    "children": [],
                    "leaf": true,
                    "name": "Lenses",
                    "var": false
                  },
                  {
                    "categoryId": 10001709,
                    "children": [],
                    "leaf": true,
                    "name": "Night vision glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001706,
                    "children": [],
                    "leaf": true,
                    "name": "Prescription glasses",
                    "var": false
                  },
                  {
                    "categoryId": 10001708,
                    "children": [],
                    "leaf": true,
                    "name": "Reading glasses",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Women",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Eyeglasses",
            "var": false
          },
          {
            "categoryId": 7694,
            "children": [
              {
                "categoryId": 7696,
                "children": [],
                "leaf": true,
                "name": "Boys",
                "var": false
              },
              {
                "categoryId": 7695,
                "children": [],
                "leaf": true,
                "name": "Girls",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kids",
            "var": false
          },
          {
            "categoryId": 7691,
            "children": [
              {
                "categoryId": 7693,
                "children": [],
                "leaf": true,
                "name": "Eyeglasses",
                "var": false
              },
              {
                "categoryId": 7692,
                "children": [],
                "leaf": true,
                "name": "Sunglasses",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Men",
            "var": false
          },
          {
            "categoryId": 10001009,
            "children": [
              {
                "categoryId": 10001252,
                "children": [],
                "leaf": true,
                "name": "Kids",
                "var": false
              },
              {
                "categoryId": 10001249,
                "children": [],
                "leaf": true,
                "name": "Men",
                "var": false
              },
              {
                "categoryId": 10001251,
                "children": [],
                "leaf": true,
                "name": "Unisex",
                "var": false
              },
              {
                "categoryId": 10001250,
                "children": [],
                "leaf": true,
                "name": "Women",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Sunglasses",
            "var": false
          },
          {
            "categoryId": 7688,
            "children": [
              {
                "categoryId": 7690,
                "children": [],
                "leaf": true,
                "name": "Eyeglasses",
                "var": false
              },
              {
                "categoryId": 7689,
                "children": [],
                "leaf": true,
                "name": "Sunglasses",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Women",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Eyewear",
        "var": false
      },
      {
        "categoryId": 7697,
        "children": [
          {
            "categoryId": 7752,
            "children": [
              {
                "categoryId": 7753,
                "children": [],
                "leaf": true,
                "name": "Boys",
                "var": false
              },
              {
                "categoryId": 10001241,
                "children": [],
                "leaf": true,
                "name": "Fashion Jewellery",
                "var": false
              },
              {
                "categoryId": 7754,
                "children": [],
                "leaf": true,
                "name": "Girls",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kids",
            "var": false
          },
          {
            "categoryId": 7742,
            "children": [
              {
                "categoryId": 7744,
                "children": [],
                "leaf": true,
                "name": "Bracelets",
                "var": false
              },
              {
                "categoryId": 7745,
                "children": [],
                "leaf": true,
                "name": "Earrings",
                "var": false
              },
              {
                "categoryId": 10001239,
                "children": [
                  {
                    "categoryId": 10001688,
                    "children": [],
                    "leaf": true,
                    "name": "Bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 10001689,
                    "children": [],
                    "leaf": true,
                    "name": "Earrings",
                    "var": false
                  },
                  {
                    "categoryId": 10001686,
                    "children": [],
                    "leaf": true,
                    "name": "Necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 10001687,
                    "children": [],
                    "leaf": true,
                    "name": "Pendants",
                    "var": false
                  },
                  {
                    "categoryId": 10001690,
                    "children": [],
                    "leaf": true,
                    "name": "Rings",
                    "var": false
                  },
                  {
                    "categoryId": 10001691,
                    "children": [],
                    "leaf": true,
                    "name": "Shirt accessories and others",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Fashion Jewellery",
                "var": false
              },
              {
                "categoryId": 7743,
                "children": [],
                "leaf": true,
                "name": "Necklaces & Pendants",
                "var": false
              },
              {
                "categoryId": 7746,
                "children": [],
                "leaf": true,
                "name": "Rings",
                "var": false
              },
              {
                "categoryId": 7747,
                "children": [
                  {
                    "categoryId": 7749,
                    "children": [],
                    "leaf": true,
                    "name": "Cufflinks",
                    "var": false
                  },
                  {
                    "categoryId": 7750,
                    "children": [],
                    "leaf": true,
                    "name": "Key chains",
                    "var": false
                  },
                  {
                    "categoryId": 7748,
                    "children": [],
                    "leaf": true,
                    "name": "Tie clips",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Shirt accessories & others",
                "var": false
              },
              {
                "categoryId": 7751,
                "children": [],
                "leaf": true,
                "name": "Wedding Rings",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Men",
            "var": false
          },
          {
            "categoryId": 10001007,
            "children": [
              {
                "categoryId": 10001243,
                "children": [
                  {
                    "categoryId": 10001703,
                    "children": [],
                    "leaf": true,
                    "name": "Bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 10001704,
                    "children": [],
                    "leaf": true,
                    "name": "Earrings",
                    "var": false
                  },
                  {
                    "categoryId": 10001701,
                    "children": [],
                    "leaf": true,
                    "name": "Necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 10001702,
                    "children": [],
                    "leaf": true,
                    "name": "Pendants",
                    "var": false
                  },
                  {
                    "categoryId": 10001705,
                    "children": [],
                    "leaf": true,
                    "name": "Rings",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Fashion Jewellery",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Unisex",
            "var": false
          },
          {
            "categoryId": 7698,
            "children": [
              {
                "categoryId": 7731,
                "children": [
                  {
                    "categoryId": 7732,
                    "children": [],
                    "leaf": true,
                    "name": "Beads",
                    "var": false
                  },
                  {
                    "categoryId": 7734,
                    "children": [],
                    "leaf": true,
                    "name": "Charms",
                    "var": false
                  },
                  {
                    "categoryId": 7737,
                    "children": [],
                    "leaf": true,
                    "name": "Gemstones",
                    "var": false
                  },
                  {
                    "categoryId": 7733,
                    "children": [],
                    "leaf": true,
                    "name": "Jewellery Components",
                    "var": false
                  },
                  {
                    "categoryId": 7735,
                    "children": [],
                    "leaf": true,
                    "name": "Jewellery Packaging & Display",
                    "var": false
                  },
                  {
                    "categoryId": 7736,
                    "children": [],
                    "leaf": true,
                    "name": "Jewellery Tools & Equipments",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Beads & Jewellery Making",
                "var": false
              },
              {
                "categoryId": 7699,
                "children": [
                  {
                    "categoryId": 7702,
                    "children": [],
                    "leaf": true,
                    "name": "Bangles",
                    "var": true
                  },
                  {
                    "categoryId": 7701,
                    "children": [],
                    "leaf": true,
                    "name": "Chain & Link Bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7700,
                    "children": [],
                    "leaf": true,
                    "name": "Charm bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7705,
                    "children": [],
                    "leaf": true,
                    "name": "Cuff bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7706,
                    "children": [],
                    "leaf": true,
                    "name": "Hologram bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7707,
                    "children": [],
                    "leaf": true,
                    "name": "ID bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7704,
                    "children": [],
                    "leaf": true,
                    "name": "Strand bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7703,
                    "children": [],
                    "leaf": true,
                    "name": "Wrap bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 7708,
                    "children": [],
                    "leaf": true,
                    "name": "others",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Bracelets & Bangles",
                "var": false
              },
              {
                "categoryId": 7709,
                "children": [
                  {
                    "categoryId": 7713,
                    "children": [],
                    "leaf": true,
                    "name": "Clip earrings",
                    "var": false
                  },
                  {
                    "categoryId": 7711,
                    "children": [],
                    "leaf": true,
                    "name": "Drop earrings",
                    "var": false
                  },
                  {
                    "categoryId": 7712,
                    "children": [],
                    "leaf": true,
                    "name": "Hoop earrings",
                    "var": false
                  },
                  {
                    "categoryId": 7710,
                    "children": [],
                    "leaf": true,
                    "name": "Stud earrings",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Earrings",
                "var": false
              },
              {
                "categoryId": 10001237,
                "children": [
                  {
                    "categoryId": 10001671,
                    "children": [],
                    "leaf": true,
                    "name": "Anklets",
                    "var": false
                  },
                  {
                    "categoryId": 10001673,
                    "children": [],
                    "leaf": true,
                    "name": "Body jewellery",
                    "var": false
                  },
                  {
                    "categoryId": 10001667,
                    "children": [],
                    "leaf": true,
                    "name": "Bracelets",
                    "var": false
                  },
                  {
                    "categoryId": 10001670,
                    "children": [],
                    "leaf": true,
                    "name": "Brooches",
                    "var": false
                  },
                  {
                    "categoryId": 10001676,
                    "children": [],
                    "leaf": true,
                    "name": "DIY accessories and others",
                    "var": false
                  },
                  {
                    "categoryId": 10001668,
                    "children": [
                      {
                        "categoryId": 10001793,
                        "children": [],
                        "leaf": true,
                        "name": "Clip earrings",
                        "var": false
                      },
                      {
                        "categoryId": 10001794,
                        "children": [],
                        "leaf": true,
                        "name": "Drop earrings",
                        "var": false
                      },
                      {
                        "categoryId": 10001795,
                        "children": [],
                        "leaf": true,
                        "name": "Hoop earrings",
                        "var": false
                      },
                      {
                        "categoryId": 10001796,
                        "children": [],
                        "leaf": true,
                        "name": "Stud earrings",
                        "var": false
                      }
                    ],
                    "leaf": false,
                    "name": "Earrings",
                    "var": false
                  },
                  {
                    "categoryId": 10001672,
                    "children": [],
                    "leaf": true,
                    "name": "Hair accessories",
                    "var": false
                  },
                  {
                    "categoryId": 10001674,
                    "children": [],
                    "leaf": true,
                    "name": "Jewellery sets",
                    "var": false
                  },
                  {
                    "categoryId": 10001675,
                    "children": [],
                    "leaf": true,
                    "name": "Jewellery storage",
                    "var": false
                  },
                  {
                    "categoryId": 10001665,
                    "children": [],
                    "leaf": true,
                    "name": "Necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 10001666,
                    "children": [],
                    "leaf": true,
                    "name": "Pendants",
                    "var": false
                  },
                  {
                    "categoryId": 10001669,
                    "children": [],
                    "leaf": true,
                    "name": "Rings",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Fashion Jewellery",
                "var": false
              },
              {
                "categoryId": 7723,
                "children": [
                  {
                    "categoryId": 7729,
                    "children": [],
                    "leaf": true,
                    "name": "Anklets",
                    "var": false
                  },
                  {
                    "categoryId": 7728,
                    "children": [],
                    "leaf": true,
                    "name": "Body jewellery",
                    "var": false
                  },
                  {
                    "categoryId": 7725,
                    "children": [],
                    "leaf": false,
                    "name": "Bridal jewellery sets",
                    "var": false
                  },
                  {
                    "categoryId": 7726,
                    "children": [],
                    "leaf": true,
                    "name": "Brooches",
                    "var": false
                  },
                  {
                    "categoryId": 7727,
                    "children": [],
                    "leaf": true,
                    "name": "Hair jewellery",
                    "var": false
                  },
                  {
                    "categoryId": 7724,
                    "children": [],
                    "leaf": true,
                    "name": "Jewellery sets",
                    "var": false
                  },
                  {
                    "categoryId": 7730,
                    "children": [],
                    "leaf": true,
                    "name": "Key chains",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Jewellery sets & Others",
                "var": false
              },
              {
                "categoryId": 7714,
                "children": [
                  {
                    "categoryId": 7716,
                    "children": [],
                    "leaf": true,
                    "name": "Chain necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 7718,
                    "children": [],
                    "leaf": true,
                    "name": "Choker necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 7720,
                    "children": [],
                    "leaf": true,
                    "name": "Others",
                    "var": false
                  },
                  {
                    "categoryId": 7715,
                    "children": [],
                    "leaf": true,
                    "name": "Pendant necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 7717,
                    "children": [],
                    "leaf": true,
                    "name": "Pendants",
                    "var": false
                  },
                  {
                    "categoryId": 7721,
                    "children": [],
                    "leaf": true,
                    "name": "Power necklaces",
                    "var": false
                  },
                  {
                    "categoryId": 7719,
                    "children": [],
                    "leaf": true,
                    "name": "Torques",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Necklaces & Pendants",
                "var": false
              },
              {
                "categoryId": 7722,
                "children": [],
                "leaf": true,
                "name": "Rings",
                "var": false
              },
              {
                "categoryId": 7738,
                "children": [
                  {
                    "categoryId": 7739,
                    "children": [],
                    "leaf": true,
                    "name": "Bridal Jewellery Sets",
                    "var": false
                  },
                  {
                    "categoryId": 7740,
                    "children": [],
                    "leaf": true,
                    "name": "Engagement Rings",
                    "var": false
                  },
                  {
                    "categoryId": 7741,
                    "children": [],
                    "leaf": true,
                    "name": "Wedding Bands",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Wedding & Engagement Jewellery",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Women",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Jewellery",
        "var": false
      },
      {
        "categoryId": 7755,
        "children": [
          {
            "categoryId": 7769,
            "children": [
              {
                "categoryId": 7770,
                "children": [],
                "leaf": true,
                "name": "Boys",
                "var": false
              },
              {
                "categoryId": 7771,
                "children": [],
                "leaf": true,
                "name": "Girls",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Kids",
            "var": false
          },
          {
            "categoryId": 7762,
            "children": [
              {
                "categoryId": 7768,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 7764,
                "children": [],
                "leaf": true,
                "name": "Business",
                "var": false
              },
              {
                "categoryId": 7763,
                "children": [],
                "leaf": true,
                "name": "Casual",
                "var": false
              },
              {
                "categoryId": 7765,
                "children": [],
                "leaf": true,
                "name": "Fashion",
                "var": false
              },
              {
                "categoryId": 7766,
                "children": [],
                "leaf": true,
                "name": "Sports",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Men",
            "var": false
          },
          {
            "categoryId": 10001008,
            "children": [
              {
                "categoryId": 10001248,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 10001244,
                "children": [],
                "leaf": true,
                "name": "Business",
                "var": false
              },
              {
                "categoryId": 10001245,
                "children": [],
                "leaf": true,
                "name": "Casual",
                "var": false
              },
              {
                "categoryId": 10001246,
                "children": [],
                "leaf": true,
                "name": "Fashion",
                "var": false
              },
              {
                "categoryId": 10001247,
                "children": [],
                "leaf": true,
                "name": "Sports",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Unisex",
            "var": false
          },
          {
            "categoryId": 7756,
            "children": [
              {
                "categoryId": 7761,
                "children": [],
                "leaf": true,
                "name": "Accessories",
                "var": false
              },
              {
                "categoryId": 7758,
                "children": [],
                "leaf": true,
                "name": "Business",
                "var": false
              },
              {
                "categoryId": 7757,
                "children": [],
                "leaf": true,
                "name": "Casual",
                "var": false
              },
              {
                "categoryId": 7759,
                "children": [],
                "leaf": true,
                "name": "Fashion",
                "var": false
              },
              {
                "categoryId": 7760,
                "children": [],
                "leaf": true,
                "name": "Sports",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Women",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Watches",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Watches Sunglasses Jewellery",
    "var": false
  },
  {
    "categoryId": 20000030,
    "children": [
      {
        "categoryId": 20000031,
        "children": [
          {
            "categoryId": 20000039,
            "children": [],
            "leaf": true,
            "name": "Cameras",
            "var": false
          },
          {
            "categoryId": 20000040,
            "children": [],
            "leaf": true,
            "name": "Computers & Laptops",
            "var": false
          },
          {
            "categoryId": 20000036,
            "children": [],
            "leaf": true,
            "name": "Household Appliances",
            "var": false
          },
          {
            "categoryId": 20000035,
            "children": [],
            "leaf": true,
            "name": "Mobiles & Tablets",
            "var": false
          },
          {
            "categoryId": 20000037,
            "children": [],
            "leaf": true,
            "name": "TV Audio and Video Amenities",
            "var": false
          },
          {
            "categoryId": 20000038,
            "children": [],
            "leaf": true,
            "name": "Wearables and Accessories",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Electronics",
        "var": false
      },
      {
        "categoryId": 20000034,
        "children": [
          {
            "categoryId": 20000050,
            "children": [],
            "leaf": true,
            "name": "Groceries",
            "var": false
          },
          {
            "categoryId": 20000049,
            "children": [],
            "leaf": true,
            "name": "Health and Beauty",
            "var": false
          },
          {
            "categoryId": 20000051,
            "children": [],
            "leaf": true,
            "name": "Mother and Baby",
            "var": false
          }
        ],
        "leaf": false,
        "name": "FMCG",
        "var": false
      },
      {
        "categoryId": 20000033,
        "children": [
          {
            "categoryId": 20000047,
            "children": [],
            "leaf": true,
            "name": "Kids Fashion",
            "var": false
          },
          {
            "categoryId": 20000045,
            "children": [],
            "leaf": true,
            "name": "Mens Fashion",
            "var": false
          },
          {
            "categoryId": 20000048,
            "children": [],
            "leaf": true,
            "name": "Watches and Accessories",
            "var": false
          },
          {
            "categoryId": 20000046,
            "children": [],
            "leaf": true,
            "name": "Womens Fashion",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Fashion",
        "var": false
      },
      {
        "categoryId": 20000032,
        "children": [
          {
            "categoryId": 20000041,
            "children": [],
            "leaf": true,
            "name": "Automotive Peripherals",
            "var": false
          },
          {
            "categoryId": 20000043,
            "children": [],
            "leaf": true,
            "name": "Furniture & Décor",
            "var": false
          },
          {
            "categoryId": 20000042,
            "children": [],
            "leaf": true,
            "name": "Kitchen and Dining",
            "var": false
          },
          {
            "categoryId": 20000044,
            "children": [],
            "leaf": true,
            "name": "Stationaries",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Home and Lifestyle",
        "var": false
      },
      {
        "categoryId": 20000054,
        "children": [
          {
            "categoryId": 20000056,
            "children": [
              {
                "categoryId": 20000067,
                "children": [],
                "leaf": true,
                "name": "Brick",
                "var": false
              },
              {
                "categoryId": 20000070,
                "children": [],
                "leaf": true,
                "name": "Cement",
                "var": false
              },
              {
                "categoryId": 20000071,
                "children": [],
                "leaf": true,
                "name": "Ready Mix",
                "var": false
              },
              {
                "categoryId": 20000068,
                "children": [],
                "leaf": true,
                "name": "Rock",
                "var": false
              },
              {
                "categoryId": 20000069,
                "children": [],
                "leaf": true,
                "name": "Sand",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Concrete",
            "var": false
          },
          {
            "categoryId": 20000057,
            "children": [
              {
                "categoryId": 20000072,
                "children": [
                  {
                    "categoryId": 20000073,
                    "children": [],
                    "leaf": true,
                    "name": "Installed Wiring Devices Wall Switches and Receptacles",
                    "var": false
                  },
                  {
                    "categoryId": 20000074,
                    "children": [],
                    "leaf": true,
                    "name": "Plugs and Connectors",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Electrical Supplies",
                "var": false
              },
              {
                "categoryId": 20000076,
                "children": [],
                "leaf": true,
                "name": "Industrial Controls Automation and Machine Safety",
                "var": false
              },
              {
                "categoryId": 20000077,
                "children": [],
                "leaf": true,
                "name": "Power Management - Circuit Protection and Distribution",
                "var": false
              },
              {
                "categoryId": 20000075,
                "children": [],
                "leaf": true,
                "name": "Wire and Cable",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Electrical Equipment",
            "var": false
          },
          {
            "categoryId": 20000058,
            "children": [
              {
                "categoryId": 20000079,
                "children": [],
                "leaf": true,
                "name": "Calendars and Planners",
                "var": false
              },
              {
                "categoryId": 20000078,
                "children": [],
                "leaf": true,
                "name": "Clips Push Pins and Rubber Bands",
                "var": false
              },
              {
                "categoryId": 20000081,
                "children": [],
                "leaf": true,
                "name": "Office Machines and Equipment",
                "var": false
              },
              {
                "categoryId": 20000080,
                "children": [],
                "leaf": true,
                "name": "Paper and Notebooks",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Office Supplies",
            "var": false
          },
          {
            "categoryId": 20000059,
            "children": [
              {
                "categoryId": 20000083,
                "children": [],
                "leaf": true,
                "name": "Brushes and Rollers",
                "var": false
              },
              {
                "categoryId": 20000082,
                "children": [],
                "leaf": true,
                "name": "Exterior Paints and Stains",
                "var": false
              },
              {
                "categoryId": 20000084,
                "children": [],
                "leaf": true,
                "name": "Interior Paints and Stains",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Paints Equipment and Supplies",
            "var": false
          },
          {
            "categoryId": 20000060,
            "children": [
              {
                "categoryId": 20000085,
                "children": [],
                "leaf": true,
                "name": "Bathroom Hardware",
                "var": false
              },
              {
                "categoryId": 20000088,
                "children": [],
                "leaf": true,
                "name": "Insulation",
                "var": false
              },
              {
                "categoryId": 20000086,
                "children": [],
                "leaf": true,
                "name": "Pipe Tubing and Fittings",
                "var": false
              },
              {
                "categoryId": 20000087,
                "children": [],
                "leaf": true,
                "name": "Tubs and Showers",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Plumbing",
            "var": false
          },
          {
            "categoryId": 20000055,
            "children": [
              {
                "categoryId": 20000061,
                "children": [],
                "leaf": true,
                "name": "Alloy Steel Rod Stock",
                "var": false
              },
              {
                "categoryId": 20000062,
                "children": [],
                "leaf": true,
                "name": "Metal Tin",
                "var": false
              },
              {
                "categoryId": 20000063,
                "children": [],
                "leaf": true,
                "name": "Plastic",
                "var": false
              },
              {
                "categoryId": 20000064,
                "children": [
                  {
                    "categoryId": 20000066,
                    "children": [],
                    "leaf": true,
                    "name": "Floor Tiles",
                    "var": false
                  },
                  {
                    "categoryId": 20000065,
                    "children": [],
                    "leaf": true,
                    "name": "Wall Tiles",
                    "var": false
                  }
                ],
                "leaf": false,
                "name": "Tiles",
                "var": false
              }
            ],
            "leaf": false,
            "name": "Raw Materials",
            "var": false
          }
        ],
        "leaf": false,
        "name": "Industrial Goods",
        "var": false
      }
    ],
    "leaf": false,
    "name": "Wholesale",
    "var": false
  }
]
