import client from 'utils/api-client';
import Qs from 'qs';

export const getCouriers = (filter = null) => {
  return client.get('/couriers', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createCourier = (data) => {
  return client.post(`/couriers`, data);
};

export const updateCourier = (courier_id, data) => {
  return client.put(`/couriers/${courier_id}`, data);
};

export const deleteCouriers = (courier_id) => {
  return client.delete(`/couriers/${courier_id}`);
};

export const getCourierPackages = (courier_id) => client.get(`/couriers/${courier_id}/packages`);
