import Qs from 'qs';
import client from 'utils/api-client';

export const getCountries = (filter = {}) => {
    return client.get('/countries', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
}

export const createCountry = (data) => {
    return client.post('/countries', data);
}

export const getCountryDetails = (id) => {
    return client.get(`/countries/${id}`);
}

export const updateCountry = (id, data) => {
    return client.put(`/countries/${id}`, data);
}

export const removeCountry = (id) => {
    return client.delete(`/countries/${id}`)
}
