import * as actionTypes from 'app/actionTypes/profile';
import produce from 'immer';

const initialState = {
  profile: null,
  role: null,
  permissions: null,
  loading: true,
  error: null,
}

export default function profile(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.GET_PROFILE_REQUESTED:
        draft.loading = true;
        break;
      case actionTypes.GET_PROFILE_SUCCEEDED:
        draft.profile = action.data.profile;
        draft.role = action.data.role;
        draft.permissions = action.data.permissions;
        draft.loading = false;
        break;
      case actionTypes.GET_PROFILE_FAILED:
        draft.error = action.message
        draft.loading = false;
        break;
      default:
        return state
    }
  })

}
