const routePaths = {
  orders: {
    list: '/orders',
    create: `/create-manual-order`,
    details: (id=':id') => `/orders/${id}`,
    edit: (id=':id') =>  `/orders/${id}/edit`,
    itemEdit: (id=':id') =>  `/item/edit/${id}`,
    addItem: (id=':id') => `/orders/${id}/add-item`,
    invoice: (id=':id') => `/orders/${id}/invoice`
  },
  cart: {
    list: `/cart-items`,
    create: ``,
    details: ``,
    edit: (id=':id') => `/cart-item/edit/${id}`,
    addItem: `/cart-items/add`,

  },
  products: {
    list: `/products`,
    add: '/products/add',
    edit: (id=`:id`) => `/products/${id}/edit`
  }
}
export default routePaths;
