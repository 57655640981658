import Qs from 'qs';
import client from 'utils/api-client';

export const getMoveOnCategories = (filter = {}) => {
  return client.get('/shipping-tags/moveon-categories', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const moveOnCostCalculator = (filter = {}) => {
  return client.get('/shipping-tags/moveon-cost-calculator', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const getMvnWarehouses = (filter = {}) => {
  return client.get('/shipping-tags/moveon-warehouses', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const syncMoveOnShippingTags = (data) => {
  return client.post('/shipping-tags/sync-tags', data)
}

