import Qs from 'qs';
import client from 'utils/api-client';

export const getCurrencies = (filter = {}) => {
    return client.get('/currencies', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
}

export const createCurrency = (data) => {
    return client.post('/currencies', data);
}

export const getCurrencyDetails = (id) => {
    return client.get(`/currencies/${id}`);
}

export const updateCurrency = (id, data) => {
    return client.put(`/currencies/${id}`, data);
}

export const removeCurrency = (id) => {
    return client.delete(`/currencies/${id}`)
}
