import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/withdrawals';
import {getAllWithdrawals} from "services/transactions";

const getApi = async (filter) => {
  return getAllWithdrawals(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchWithdrawals(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_WITHDRAWALS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_WITHDRAWALS_FAILED, message: e?.response });
  }
}

function* withdrawalsSaga() {
  yield all([
    takeEvery(types.GET_WITHDRAWALS_REQUESTED, fetchWithdrawals),
  ]);
}

export default withdrawalsSaga;
