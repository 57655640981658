import { call, put, takeEvery, all } from 'redux-saga/effects'
import {getProductSearchValue} from "services/order-items";
import * as types from 'app/actionTypes/productReceived';

const getApi = async (filter) => {
  return getProductSearchValue(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchProductSearch(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_PRODUCT_RECEIVED_ITEM_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_PRODUCT_RECEIVED_ITEM_FAILED, message: e?.response });
  }
}

function* productReceivedSaga() {
  yield all([
    takeEvery(types.GET_PRODUCT_RECEIVED_ITEM_REQUESTED, fetchProductSearch),
  ]);
}

export default productReceivedSaga;
