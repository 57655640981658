export const GET_ORDER_ITEMS_REQUESTED                      = 'GET_ORDER_ITEMS_REQUESTED';
export const GET_ORDER_ITEMS_SUCCESS                        = 'GET_ORDER_ITEMS_SUCCESS';
export const GET_ORDER_ITEMS_FAILED                         = 'GET_ORDER_ITEMS_FAILED';
export const RESET_ORDER_ITEMS                              = 'RESET_ORDER_ITEMS';

export const GET_ORDER_ITEMS_WITH_RELATIONSHIPS_REQUESTED   = 'GET_ORDER_ITEMS_WITH_RELATIONSHIPS_REQUESTED';
export const GET_ORDER_ITEMS_WITH_RELATIONSHIPS_SUCCESS     = 'GET_ORDER_ITEMS_WITH_RELATIONSHIPS_SUCCESS';
export const GET_ORDER_ITEMS_WITH_RELATIONSHIPS_FAILED      = 'GET_ORDER_ITEMS_WITH_RELATIONSHIPS_FAILED';
export const UPDATE_ORDER_ITEMS_WITH_RELATIONSHIPS          = 'UPDATE_ORDER_ITEMS_WITH_RELATIONSHIPS';
export const RESET_ORDER_ITEMS_WITH_RELATIONSHIPS           = 'RESET_ORDER_ITEMS_WITH_RELATIONSHIPS';
export const DELETE_ORDER_ITEMS_WITH_RELATIONSHIPS          = 'DELETE_ORDER_ITEMS_WITH_RELATIONSHIPS';

export const GET_PROCESSABLE_ORDER_REQUESTED                = 'GET_PROCESSABLE_ORDER_REQUESTED';
export const GET_PROCESSABLE_ORDER_SUCCESS                  = 'GET_PROCESSABLE_ORDER_SUCCESS';
export const GET_PROCESSABLE_ORDER_FAILED                   = 'GET_PROCESSABLE_ORDER_FAILED';
export const UPDATE_GET_PROCESSABLE_ORDER                   = 'UPDATE_GET_PROCESSABLE_ORDER';

export const DELETE_PROCESSABLE_ORDER                       = 'DELETE_PROCESSABLE_ORDER';

export const GET_ORDER_ITEMS_DETAILS_REQUESTED              = 'GET_ORDER_ITEMS_DETAILS_REQUESTED';
export const GET_ORDER_ITEMS_DETAILS_SUCCESS                = 'GET_ORDER_ITEMS_DETAILS_SUCCESS';
export const GET_ORDER_ITEMS_DETAILS_FAILED                 = 'GET_ORDER_ITEMS_DETAILS_FAILED';

export const GET_DISPUTE_ORDER_ITEMS_REQUESTED                      = 'GET_DISPUTE_ORDER_ITEMS_REQUESTED';
export const GET_DISPUTE_ORDER_ITEMS_SUCCESS                        = 'GET_DISPUTE_ORDER_ITEMS_SUCCESS';
export const GET_DISPUTE_ORDER_ITEMS_FAILED                         = 'GET_DISPUTE_ORDER_ITEMS_FAILED';
