import * as actionTypes from 'app/actionTypes/resourceSearch';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getResourceSearch as resourceSearchClient } from 'services/resourceSearch';

const getResourceSearchApi = async (q) => {
    try {
        const res = await resourceSearchClient({resource: q.resource, q: q.q});
        return res.data;
    }
    catch({response}) {
        return response;
    }
}

function* getResourceSearch(action) {
    const { payload } = action;
    try {
        const data = yield call(() => getResourceSearchApi(payload));
        yield put({type: actionTypes.GET_RESOURCE_SEARCH_SUCCEEDED, payload: {data, resource: payload.resource}});
    } catch({response}) {
        yield put({type: actionTypes.GET_RESOURCE_SEARCH_FAILED, payload: response})
    }
}

function* resourceSearchSaga() {
    yield all([
        takeEvery(actionTypes.GET_RESOURCE_SEARCH_REQUESTED, getResourceSearch)
    ])
}

export default resourceSearchSaga;
