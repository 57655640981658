import React from 'react';
import { Tag } from 'antd';
import {getLocalData} from "utils/helper";
import {STATUS_LABEL} from "configs/storage";

const StatusTag = (props) => {
  let { text, slug } = props;
  let color = 'default';

  let getStatusLabels = getLocalData(STATUS_LABEL)

  let orderStatus = getStatusLabels?.order?.statuses
  let orderStatusColor = getStatusLabels?.order?.statuses_color
  let transStatus = getStatusLabels?.transaction?.statuses
  let transStatusColor = getStatusLabels?.transaction?.statuses_color
  let productStatuses = getStatusLabels?.order?.product_statuses
  let productStatusesColor = getStatusLabels?.order?.product_statuses_color
  let invoiceStatuses = getStatusLabels?.order?.invoice_statuses

  if (text === "approved"){
    text = transStatus?.approved
  } else if (text === "fraud"){
    text = transStatus?.fraud
  } else if (text === "pending"){
    text = transStatus?.pending
  } else if (text === "rejected"){
    text = transStatus?.rejected
  } if (text === 'cancelled'){
    text = productStatuses?.cancelled
  } else if (text === "awaiting_for_delivery"){
    text = orderStatus?.awaiting_for_delivery
  } else if (text === "delivery_processing"){
    text = productStatuses?.delivery_processing
  } else if (text === "delivery_ready"){
    text = productStatuses?.delivery_ready
  } else if (text === "in_transit"){
    text = productStatuses?.in_transit
  } else if (text === "moveon_sfm_approved"){
    text = productStatuses?.moveon_sfm_approved
  } else if (text === "moveon_sfm_rejected"){
    text = productStatuses?.moveon_sfm_rejected
  } else if (text === "moveon_sfm_waiting"){
    text = productStatuses?.moveon_sfm_waiting
  } else if (text === "not_purchased"){
    text = productStatuses?.not_purchased
  } else if (text === "purchase_processing"){
    text = productStatuses?.purchase_processing
  } else if (text === "purchased"){
    text = productStatuses?.purchased
  } else if (text === "refund_processing"){
    text = productStatuses?.refund_processing
  } else if (text === "requested_for_delivery"){
    text = productStatuses?.requested_for_delivery
  }  else if (text === "refund_processing"){
    text = productStatuses?.refund_processing
  } else if (text === 'delivered'){
    text = orderStatus?.delivered
  }else if (text === 'partially_refunded'){
    text = orderStatus?.partially_refunded
  }else if (text === 'pending'){
    text = productStatuses?.pending
  }else if (text === 'preparing_for_delivery'){
    text = orderStatus?.preparing_for_delivery
  }else if (text === 'processing'){
    text = productStatuses?.processing
  }else if (text === 'ready_for_delivery'){
    text = orderStatus?.ready_for_delivery
  } else if (text === 'refunded'){
    text = productStatuses?.refunded
  }else if (text === 'shipped'){
    text = orderStatus?.shipped
  }else if (text === 'unpaid'){
    text = invoiceStatuses?.unpaid
  }else if (text === 'returned'){
    text = productStatuses?.returned
  }else if (text === 'returned_to_origin'){
    text = productStatuses?.returned_to_origin
  }else if (text === 'overpaid'){
    text = invoiceStatuses?.overpaid
  }else if (text === 'received_in_origin'){
    text = productStatuses?.received_in_origin
  }else if (text === 'paid'){
    text = invoiceStatuses?.paid
  }else if (text === 'received_in_country'){
    text = productStatuses?.received_in_country
  }else if (text === 'shipped_to_customer'){
    text = productStatuses?.shipped_to_customer
  }else if (text === 'partial'){
    text = invoiceStatuses?.partial
  }else if (text === 'seller_shipped'){
    text = productStatuses?.seller_shipped
  }else if (text === 'in_stock'){
    text = "In Stock"
  }else if (text === 'active'){
    text = "Active"
  }else if (text === 'inactive'){
    text = "Inactive"
  }else if (text === 'completed'){
    text = "Completed"
  }else if(text === 'resolved'){
    text = "Resolved"
  }else if (text === 'initial'){
    text = "Initial"
  }else if (text === 'canceled'){
    text = "Canceled"
  }else if (text === 'success'){
    text = "Success"
  }else if (text === 'current'){
    text = "Current"
  }else if (text === 'ready') {
    text = "Ready"
  }else if (text === 'processed') {
    text = "Processed"
  } else if (text === 'available') {
    text = "Available"
  } else if (text === 'unavailable') {
    text = "Unavailable"
  }


  switch (slug) {
    case 'approved':
      color = transStatusColor.approved
      break;
    case 'fraud':
      color = transStatusColor.fraud
      break;
    case 'pending':
      color = transStatusColor.pending
      break;
    case 'rejected':
      color = transStatusColor.rejected
      break;
    case 'cancelled':
      color = productStatusesColor.cancelled
      break;
    case 'delivered':
      color = productStatusesColor.delivered
      break;
    case 'delivery_processing':
      color = productStatusesColor.delivery_processing
      break;
    case 'delivery_ready':
      color = productStatusesColor.delivery_ready
      break;
    case 'in_transit':
      color = productStatusesColor.in_transit
      break;
    case 'moveon_sfm_approved':
      color = productStatusesColor.moveon_sfm_approved
      break;
    case 'moveon_sfm_rejected':
      color = productStatusesColor.moveon_sfm_rejected
      break;
    case 'moveon_sfm_waiting':
      color = productStatusesColor.moveon_sfm_waiting
      break;
    case 'not_purchased':
      color = productStatusesColor.not_purchased
      break;
    case 'processing':
      color = productStatusesColor.processing
      break;
    case 'purchase_preparing':
      color = productStatusesColor.purchase_preparing
      break;
    case 'purchase_processing':
      color = productStatusesColor.purchase_processing
      break;
    case 'purchased':
      color = productStatusesColor.purchased
      break;
    case 'received_in_country':
      color = productStatusesColor.received_in_country
      break;
    case 'received_in_origin':
      color = productStatusesColor.received_in_origin
      break;
    case 'refund_processing':
      color = productStatusesColor.refund_processing
      break;
    case 'refunded':
      color = productStatusesColor.refunded
      break;
    case 'requested_for_delivery':
      color = productStatusesColor.requested_for_delivery
      break;
    case 'returned':
      color = productStatusesColor.returned
      break;
    case 'returned_to_origin':
      color = productStatusesColor.returned_to_origin
      break;
    case 'seller_shipped':
      color = productStatusesColor.seller_shipped
      break;
    case 'shipped_to_customer':
      color = productStatusesColor.shipped_to_customer
      break;
    case 'preparing_for_delivery':
    case 'awaiting_for_delivery':
      color = orderStatusColor.awaiting_for_delivery
      break;
    case 'awaiting_for_payment':
      color = orderStatusColor.awaiting_for_payment
      break;
    case 'partially_refunded':
      color = orderStatusColor.cancelled
      break;
    case 'ready_for_delivery':
      color = orderStatusColor.ready_for_delivery
      break;
    case 'shipped':
      color = orderStatusColor.shipped
      break;
    case 'unavailable':
    case 'unpaid':
    case 'canceled':
    case 'PLACE_ORDER_SUCCESS':
      color = 'error'
      break;
    case 'overpaid':
    case 'initial':
    case 'WAIT_BUYER_ACCEPT_GOODS':
    case 'WAIT_SELLER_SEND_GOODS':
      color = 'warning'
      break;
    case 'available':
    case 'paid':
    case 'in_stock':
    case 'active':
    case 'completed':
    case 'resolved':
    case 'FINISH':
    case 'running':
    case 'success':
    case 'current':
    case 'ready':
    case 'processed':
      color = 'success'
      break;
    case 'partial':
    case 'inactive':
      color = 'warning'
      break;
    default:
      break;
  }
  return <Tag color={color}>{text?.toUpperCase()}</Tag>;
};
export default StatusTag;
