import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as type from 'app/actionTypes/moveOnCategories';
import { getMoveOnCategories, getMvnWarehouses, moveOnCostCalculator } from 'services/moveon-categories';

const getMoveOnCatsApi = async (filter) => {
    return getMoveOnCategories(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchTags(action) {
    try {
        const data = yield call(() => getMoveOnCatsApi(action.payload));
        yield put({ type: type.GET_MOVEON_CATS_SUCCESS, payload: data });
    } catch (e) {
        yield put({ type: type.GET_MOVEON_CATS_FAILED, message: e?.response });
    }
}

const getMoveOnCostCalcApi = async (filter) => {
  return moveOnCostCalculator(filter)
      .then(res => res.data)
      .catch((error) => { throw error })
}

function* fetchMoveOnCostCalc(action) {
  try {
      const data = yield call(() => getMoveOnCostCalcApi(action.payload));
      yield put({ type: type.GET_MOVEON_COST_CALC_SUCCESS, payload: data });
  } catch (e) {
      yield put({ type: type.GET_MOVEON_COST_CALC_FAILED, message: e?.response });
  }
}

const getMvnWarehousesApi = async (filter) => {
  return getMvnWarehouses(filter)
      .then(res => res.data)
      .catch((error) => { throw error })
}

function* fetchMvnWarehouses(action) {
  try {
      const data = yield call(() => getMvnWarehousesApi(action.payload));
      yield put({ type: type.GET_MVN_WAREHOUSE_SUCCESS, payload: data });
  } catch (e) {
      yield put({ type: type.GET_MVN_WAREHOUSE_FAILED, message: e?.response });
  }
}

function* moveOnCategoriesSaga() {
    yield all([
        takeEvery(type.GET_MOVEON_CATS_REQUESTED, fetchTags),
        takeEvery(type.GET_MOVEON_COST_CALC_REQUESTED, fetchMoveOnCostCalc),
        takeEvery(type.GET_MVN_WAREHOUSE_REQUESTED, fetchMvnWarehouses),
    ]);
}

export default moveOnCategoriesSaga;
