import Qs from 'qs';
import client from 'utils/api-client';

export const getWidgets = (filter = {}) => {
  return client.get('/builder/widgets', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getBuilders = (filter = {}) => {
  return client.get('/builder', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getBuilder = (id) => {
  return client.get(`/builder/${id}`);
};

export const createBuilder = (data) => {
  return client.post('/builder/create', data)
}

export const updateBuilder = (id, data) => {
  return client.post(`/builder/${id}`, data);
};

export const removeBuilder = (id) => {
  return client.delete(`/builder/${id}`);
};
