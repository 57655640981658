import client from 'utils/api-client';
import Qs from 'qs';

export const getItemRefunds = (filter = {}) => {
  return client.get('/item-refunds', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const requestForRefund = (data) => client.post(`/item-refunds/create`, data);

export const processRefund = (id, data) => client.post(`/item-refunds/${id}/process`, data);

export const approveRefund = (id, data) => client.put(`/item-refunds/${id}`, data);

export const refundDetails = (id) => client.get(`/item-refunds/${id}`);

export const rejectRefund = (id, data) => client.post(`/item-refunds/${id}/process`, data);

export const fixRefundTransaction = (item_id, data) => client.post(`/order-items/${item_id}/refund`, data);

export const getIssue = (filter = {}) => {
  return client.get('/issues', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createDirectRefund = (data) => {
  return client.post('/item-refunds', data)
}
