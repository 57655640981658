import client from 'utils/api-client';
import Qs from 'qs';

export const getMenus = (filter = null) => {
  return client.get('/menus', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getMenu = (id) => {
  return client.get(`/menus/${id}`);
};

export const addMenuInfo = (data) => {
  return client.post(`/menus`, data);
};

export const updateMenuInfo = (data, id) => {
  return client.put(`/menus/${id}`, data);
};

export const getAllMenuItems = (filter = null) => {
  return client.get('/menu-items', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getMenuInfoItems = (id) => {
  return client.get(`/menu-items/${id}`);
};

export const addMenuItem = (menuId, data) => {
  return client.post(`/menus/${menuId}/add`, data);
};

export const updateMenuItem = (data, id) => {
  return client.put(`/menu-items/${id}`, data);
};

export const deleteMenuItem = (id) => {
  return client.delete(`/menu-items/${id}`);
};

export const updateMenuItemsOrder = (menuId, items) => {
  return client.post(`/menus/${menuId}/update-order`, items);
}
