import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getOrders } from 'services/order';
import { updateOrderItem } from 'services/order-items';
import * as type from 'app/actionTypes/orders';

const getApi = async (filter) => {
    return getOrders(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchOrders(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_ORDERS_SUCCESS, orders: data });
    } catch (e) {
        yield put({ type: type.GET_ORDERS_FAILED, message: e?.response });
    }
}

const updateOrderItemApi = async (id, data) => {
    return updateOrderItem(id, data)
    .then(res => ({id, data}))
    .catch((error) => { throw error })
}

function* setUpdateOrderItem(action) {
    try {
        const data = yield call(() => updateOrderItemApi(action.payload.id, action.payload.data));
        yield put({ type: type.ITEM_UPDATE_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: type.ITEM_UPDATE_FAILED, message: e?.response });
    }
}

function* ordersSaga() {
    yield all([
        takeEvery(type.GET_ORDERS_REQUESTED, fetchOrders),
        takeEvery(type.ITEM_UPDATE_REQUESTED, setUpdateOrderItem),
    ]);
}

export default ordersSaga;
