import Qs from 'qs';
import client from 'utils/api-client';

export const getCampaigns = (filter = {}) => {
  return client.get('/campaigns', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createCampaign = (data) =>  {
  return client.post('/campaigns', data)
}

export const updateCampaign = (id, data) => {
  return client.put(`/campaigns/${id}`, data);
};

export const deleteCampaigns = (id) => {
  return client.delete(`/campaigns/${id}`);
};

export const getCampaignProducts = (id, filter) => {
  return client.get(`/campaigns/${id}`, {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const updateCampaignProduct = (id, data) => {
  return client.post(`/campaigns/${id}`, data);
};

export const removeCampaignProducts = (id, data) => {
  return client.post(`/campaigns/${id}/remove-product`, data);
}

export const assignCampaignProducts = (id, data) => {
  return client.post(`/campaigns/${id}/add-product`, data);
}

