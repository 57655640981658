export const GET_PARCELS_REQUESTED  = 'GET_PARCELS_REQUESTED';
export const GET_PARCELS_SUCCESS    = 'GET_PARCELS_SUCCESS';
export const GET_PARCELS_FAIL       = 'GET_PARCELS_FAIL';
export const RESET_PARCELS          = 'RESET_PARCELS';

export const GET_PARCELS_STATUS_REQUESTED   = 'GET_PARCELS_STATUS_REQUESTED';
export const GET_PARCELS_STATUS_SUCCESS     = 'GET_PARCELS_STATUS_SUCCESS';
export const GET_PARCELS_STATUS_FAIL        = 'GET_PARCELS_STATUS_FAIL';
export const UPDATE_PARCEL_ITEM             = 'UPDATE_PARCEL_ITEM';
export const SET_PARCELS                    = 'SET_PARCELS';

export const GET_PARCELS_DETAILS_REQUESTED  = 'GET_PARCELS_DETAILS_REQUESTED';
export const GET_PARCELS_DETAILS_SUCCESS    = 'GET_PARCELS_DETAILS_SUCCESS';
export const GET_PARCELS_DETAILS_FAIL       = 'GET_PARCELS_DETAILS_FAIL';
