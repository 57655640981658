import { all } from 'redux-saga/effects'
import parcelsSaga from './parcelsSaga'
import orderSaga from './orderSaga'
import ordersSaga from './ordersSaga'
import customersSaga from './customersSaga'
import purchaseProductsSaga from './purchaseProductsSaga'
import liveCartSaga from './liveCartSaga'
import customerDetailsSaga from './customerDetailsSaga'
import cartItemsSaga from './cartItemsSaga'
import transactionsSaga from './transactionsSaga'
import orderItemsSaga from './orderItemsSaga'
import menusSaga from './menusSaga'
import gatewaysSaga from './gatewaysSaga'
import couriersSaga from './couriersSaga'
import configurationsSaga from './configurationsSaga'
import filesSaga from './filesSaga'
import requestsSaga from './requestsSaga'
import shopsSaga from './shopsSaga'
import shippingCategoriesSaga from './shippingCategoriesSaga'
import productsSaga from './products'
import campaignsSaga from './campaignsSaga'
import storeAccountSaga from "./storeAccountSaga";
import itemRefundsSaga from './itemRefundsSaga'
import campaignProductsSaga from './campaignProductsSaga'
import contentsSaga from './contents'
import issuesSaga from './issues'
import rolesPermissions from './rolesPermissions'
import usersSaga from './usersSaga'
import importOrdersSaga from "./importOrdersSaga";
import purchasedOrdersSaga from "app/saga/purchasedOrdersSaga";
import resourceSearchSaga from './resourceSearchSaga';
import countriesSaga from './countries'
import currenciesSaga from './currencies'
import builderSaga from './builder'
import profileSaga from './profileSaga'
import supportSaga from "./supportSaga";
import walletSaga from "./walletSaga";
import withdrawalsSaga from "./withdrawalsSaga";
import tagsSaga from './tagsSaga'
import shippingTagsSaga from './shippingTagsSaga'
import moveOnCategoriesSaga from './moveOnCategoriesSaga'
import productReceivedSaga from "./productReceivedSaga";
import productDetailsSaga from "./productsDetailsSaga";

export default function* rootSaga() {
  yield all([
    parcelsSaga(),
    orderSaga(),
    ordersSaga(),
    customersSaga(),
    purchaseProductsSaga(),
    liveCartSaga(),
    customerDetailsSaga(),
    cartItemsSaga(),
    transactionsSaga(),
    orderItemsSaga(),
    menusSaga(),
    gatewaysSaga(),
    couriersSaga(),
    configurationsSaga(),
    filesSaga(),
    requestsSaga(),
    shopsSaga(),
    shippingCategoriesSaga(),
    productsSaga(),
    campaignsSaga(),
    storeAccountSaga(),
    itemRefundsSaga(),
    campaignProductsSaga(),
    contentsSaga(),
    issuesSaga(),
    rolesPermissions(),
    usersSaga(),
    importOrdersSaga(),
    purchasedOrdersSaga(),
    resourceSearchSaga(),
    countriesSaga(),
    currenciesSaga(),
    builderSaga(),
    profileSaga(),
    supportSaga(),
    walletSaga(),
    withdrawalsSaga(),
    tagsSaga(),
    shippingTagsSaga(),
    moveOnCategoriesSaga(),
    productReceivedSaga(),
    productDetailsSaga(),
  ])
}
