import client from 'utils/api-client';
import Qs from 'qs';

export const getTransactions = (filter = {}) => {
  return client.get('/transactions', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getTransaction = (id) => client.get(`/transactions/${id}`);

export const updateTransaction = (id, data) => client.put(`/transactions/${id}`, data);


export const getWalletTransition = (filter = {}) => {
  return client.get('/wallets', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getWalletDetails = (id) => client.get(`/wallets/${id}`);

export const getAllWithdrawals = (filter = {}) => {
  return client.get('/withdrawals', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const updateWithdrawals = (id, data) => client.put(`/withdrawals/${id}`, data);

export const getAllWithdrawalsReport = (filter) => {
  return client.get('/withdrawals', {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      // 'Accept': 'application/pdf'
    },
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createTransaction = (data) => client.post(`/transactions`, data);
