export const GET_STORE_ACCOUNT_REQUESTED    = 'GET_STORE_ACCOUNT_REQUESTED';
export const GET_STORE_ACCOUNT_SUCCESS      = 'GET_STORE_ACCOUNT_SUCCESS';
export const GET_STORE_ACCOUNT_FAILED       = 'GET_STORE_ACCOUNT_FAILED';
export const STORE_ACCOUNT_RESET            = 'STORE_ACCOUNT_RESET';

export const GET_STORE_ACCOUNT_DETAILS_REQUEST          = 'GET_STORE_ACCOUNT_DETAILS_REQUEST';
export const GET_STORE_ACCOUNT_DETAILS_SUCCESS          = 'GET_STORE_ACCOUNT_DETAILS_SUCCESS';
export const GET_STORE_ACCOUNT_DETAILS_FAILED           = 'GET_STORE_ACCOUNT_DETAILS_FAILED';

export const GET_SEARCHABLE_ACCOUNT_ID                  = 'GET_SEARCHABLE_ACCOUNT_ID';
