import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as actionTypes from 'app/actionTypes/users';
import {getMoveOnUsers, getUserDetails, getUsers} from "services/users";

const getUsersApi = async (filter) => {
    return getUsers(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getMoveOnUsersApi = async (filter) => {
  return getMoveOnUsers(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchUsers(action) {
    try {
        const data = yield call(() => getUsersApi(action.payload));
        yield put({ type: actionTypes.GET_USERS_SUCCEEDED, payload: data });
    } catch (e) {
        yield put({ type: actionTypes.GET_USERS_FAILED, message: e?.response });
    }
}

function* fetchMoveOnUsers(action) {
  try {
    const data = yield call(() => getMoveOnUsersApi(action.payload));
    yield put({ type: actionTypes.GET_MOVEON_USERS_SUCCEEDED, payload: data });
  } catch (e) {
    yield put({ type: actionTypes.GET_MOVEON_USERS_FAILED, message: e?.response });
  }
}

const getUserDetailsApi = async (id) => {
    return getUserDetails(id)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchUserDetails(action) {
    try {
        const data = yield call(() => getUserDetailsApi(action.payload));
        yield put({ type: actionTypes.USER_DETAILS_SUCCEEDED, payload: data });
    } catch (e) {
        yield put({ type: actionTypes.USER_DETAILS_FAILED, message: e?.response });
    }
}

function* usersSaga() {
    yield all([
        takeEvery(actionTypes.GET_USERS_REQUESTED, fetchUsers),
        takeEvery(actionTypes.USER_DETAILS_REQUESTED, fetchUserDetails),
        takeEvery(actionTypes.GET_MOVEON_USERS_REQUESTED, fetchMoveOnUsers),
    ]);
}

export default usersSaga;
