import * as orderType from 'app/actionTypes/order';
import produce from "immer";

const initialState = {
  order: {
    items: [],
  },
  payments: null,
  loading: true,
  error: null,
  charges: {
    data: null,
    meta: null,
    filter: null
  },
  isChargesLoading: true,
  shipping: null,
  isShippingLoading: true,
  tracking: {
    data: null,
    meta: null,
    filter: null
  },
  isTrackingLoading: true,
  errorTracking: null,
  isOrderFetched: false,
}

export default function order(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case orderType.GET_ORDER_REQUESTED:
      return produce(state, draft => {
        draft.loading = true
        draft.isOrderFetched = false
      })
    case orderType.GET_ORDER_SUCCESS:
      return produce(state, draft => {
        draft.loading = false
        draft.order = action.order.data.data
        draft.payments = action.order.data.payments
        draft.isOrderFetched = true
      })
    case orderType.GET_ORDER_FAIL:
      return produce(state, draft => {
        draft.loading = false
        draft.error = action.message
        draft.isOrderFetched = false
      })
    case orderType.GET_CHARGES_REQUESTED:
      return produce(state, draft => {
        draft.isChargesLoading = true
      })
    case orderType.GET_CHARGES_SUCCESS:
      return produce(state, draft => {
        draft.charges = action.items
        draft.isChargesLoading = false
      })
    case orderType.GET_CHARGES_FAIL:
      return produce(state, draft => {
        draft.isChargesLoading = false
        draft.error = action.message
      })
    case orderType.GET_SHIPPING_DETAILS_REQUESTED:
      return produce(state, draft => {
        draft.isShippingLoading = true
      })
    case orderType.GET_SHIPPING_DETAILS_SUCCESS:
      return produce(state, draft => {
        draft.isShippingLoading = false
        draft.shipping = action.shipping.data.data
      })
    case orderType.GET_SHIPPING_DETAILS_FAIL:
      return produce(state, draft => {
        draft.isShippingLoading = false
        draft.error = action.message
      })
    case orderType.GET_MULTI_TRACKING_REQUESTED:
      return produce(state, draft => {
        draft.isTrackingLoading = true
      })
    case orderType.GET_MULTI_TRACKING_SUCCESS:
      return produce(state, draft => {
        draft.tracking = action.tracking
        draft.isTrackingLoading = false
      })
    case orderType.GET_MULTI_TRACKING_FAIL:
      return produce(state, draft => {
        draft.isTrackingLoading = false
        draft.errorTracking = action.message
      })
    case orderType.ADD_ORDER_ITEM:
      return produce(state, draft => {
        if (payload.id) {
          // If it is existing order then push item to the order items
          draft?.order?.items?.push(payload.itemData)
        } else {
          // if it is new order, admin added item(s) already then push the item to it (order items).
          if (state.order?.items) {
            draft?.order?.items?.push(payload.itemData)
          } else {
            // if no item added to create order by admin then add items with new item.
            draft.order = {
              ...draft.order,
              items: [payload.itemData]
            }
          }
        }
      });
    case orderType.SET_ORDER_ITEM:
      let copiedItems = [...state.order.items];
      copiedItems[payload.idx] = payload.item;

      return {
        ...state,
        order: {
          ...state.order,
          items: copiedItems,
        }
      }

    case orderType.UPDATE_ORDER_ITEM: {
      return produce(state, draft => {
        const { key, value, idx } = payload;
        draft.order.items[idx] = {
          ...state.order.items[idx],
          [key]: value,
        };
      })
    }
    case orderType.REMOVE_ORDER_ITEM:
      return produce(state, draft => {
        let updatedItems = draft.order.items?.filter((item) => item.inv_pid !== payload.inv_pid)
        draft.order.items = updatedItems;
      })
    case orderType.RESET_MANUAL_ORDER_ITEMS:
      return produce(state, draft => {
        draft.order.items = [];
      })
    case orderType.RESET_MANUAL_ORDER:
      return initialState;
    default:
      return state
  }
}
