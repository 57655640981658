import React, {useState, useLayoutEffect, useEffect} from "react";
import * as authClient from "utils/auth-client";
import {LOCAL_STORAGE_USER, STATUS_LABEL} from "configs/storage";
import {getProfileInfo} from "utils/auth-client";
import {message} from "antd";
import {getStatusLabels} from "services/labels";
import {getLocalData} from "utils/helper";

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = {user: null},
    error,
    isRejected,
    isSettled,
  } = props;

  let getStatusLabelsValue = getLocalData(STATUS_LABEL)

  const fetchLabels = React.useCallback(
    () => {
      getStatusLabels()
        .then(res => {
          localStorage.setItem(STATUS_LABEL, JSON.stringify(res.data.models));
        })
        .catch(() => {

        })
    },
    [],
  )

  useEffect(() => {
    if (!getStatusLabelsValue){
      fetchLabels()
    }
  }, [getStatusLabelsValue, fetchLabels]);


  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);

      if (data) {
        window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(data.user));
      }
    }
  }, [isSettled, data]);

  if (!firstAttemptFinished) {
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const getProfileData = (token) => {
    getProfileInfo(token).then(acc => {
      const profileData = acc?.data?.profile
      window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(profileData))
    })
      .catch(() => {
        message.error("Profile not found")
      })
  }


  const login = (form) => authClient.loginAdmin(form);

  const logout = () => authClient.logout();
  const loggedIn = () => authClient.loggedIn();

  return <AuthContext.Provider value={{data, login, loggedIn, logout, getProfileData}} {...props} />;
}

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
