export const CONTENTS_REQUESTED = 'CONTENTS_REQUESTED';
export const CONTENTS_SUCCEEDED = 'CONTENTS_SUCCEEDED';
export const CONTENTS_FAILED = 'CONTENTS_FAILED';

export const CONTENT_REQUESTED = 'CONTENT_REQUESTED';
export const CONTENT_SUCCEEDED = 'CONTENT_SUCCEEDED';
export const CONTENT_FAILED = 'CONTENT_FAILED';

export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const REMOVE_CONTENT = 'REMOVE_CONTENT';
export const CLEAR_CONTENT_FILTER = 'CLEAR_CONTENT_FILTER';
