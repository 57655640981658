import * as Types from 'app/actionTypes/menus';
import { nanoid } from 'nanoid';

const initialState = {
    items: {
        data: null,
        meta: null,
        extras: null,
    },
    loading: false,
    error: null,
    menuItems: [],
    isMenuDetailsLoading: false,
}

function formateMenus(list, parent=null) {
  return list.map((cat, idx) => {
    cat['key'] = `${cat.id}:${nanoid(6)}`;
    cat['parent_id'] = parent?.id || null;
    if (cat.children) {
      cat['hasChildren'] = true;
      cat['pathIdx'] = parent?.pathIdx ? parent?.pathIdx + '-' + idx : `${idx}`;
      cat['pathId'] = parent?.pathId ? parent?.pathId + '-' + cat.id : cat.id;
      cat['children'] = formateMenus(cat.children, cat);
    } else {
      cat['hasChildren'] = false;
    }
    return cat;
  }, [])
}

export default function menus(state = initialState, action) {
    switch (action.type) {
        case Types.GET_MENU_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case Types.GET_MENU_SUCCESS:
            return {
                ...state,
                items: action.items,
                loading: false
            }
        case Types.GET_MENU_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        case Types.GET_MENU_DETAILS_SUCCESS:
            const itemIndex = state.menuItems.findIndex((value) => value.id === action.data.id);
            let newItems = state.menuItems
            let catItems = formateMenus(action.data.items);
            let actionData = {
              ...action.data,
              items: catItems,
            }
            if (itemIndex > -1) {
                newItems[itemIndex] = actionData;
            } else {
                newItems = [...state.menuItems, actionData];
            }
            return {
                ...state,
                isMenuDetailsLoading: false,
                menuItems: newItems
            }
        default:
            return state
    }
}
