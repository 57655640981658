import React from 'react';
import {
	Route,
	Redirect
} from 'react-router-dom';
import { loggedIn } from "utils/auth-client";

const RouteWithBaseLayout = (props: any) => {
	const { layout: Layout, component: Component, ...rest } = props;
	const isLoggedIn = loggedIn();
	return (
		<Route
			{...rest}
			render={matchProps => {
				if (!isLoggedIn) {
					return (
						<Redirect
							to={{
								pathname: "/auth/login",
							}}
						/>
					)
				}
				return (
					<Layout>
						<Component {...matchProps} />
					</Layout>
				)
			}}
		/>
	);
};

export default RouteWithBaseLayout;
