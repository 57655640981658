import React, {useState} from 'react'
import {Form, Input, Button, Card, Row, Col, Checkbox, Alert} from 'antd'
import {UserOutlined, LockOutlined} from '@ant-design/icons'
import {useAuth} from 'context/auth-context'
import {LOCAL_STORAGE_KEY} from 'configs/storage'
import {Link} from "react-router-dom";

const Login = () => {
  const {login, getProfileData} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [ errors, setErrors ] = useState<any>(null);

  const onSubmit = (values: any) => {
    setIsLoading(true)
    login(values)
      .then((res: any) => {
        setIsLoading(false)
        window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
        getProfileData(res.data.token)
        setTimeout(() => {
          window.location.href = '/'
        }, 500);
      }).catch((err: any) => {
        setIsLoading(false)
        setErrors(err.response);
    })
  }

  return (
    <Row justify="space-around" align="middle">
      <Col xs={24} md={6} sm={8}>
        <div style={{
          textAlign: 'center',
          padding: '30px',
          marginTop: '50px',
          marginBottom: '20px'
        }}
        >
          {/* <Link to={"/auth/login"}>
                        <Logo />
                    </Link> */}
        </div>

        <Card>
          <Form
            name="normal_login"
            className="login-form"
            onFinish={onSubmit}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon"/>}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember_me" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Link to={"/auth/forget-password"} className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={isLoading}
                block
              >
                Log in
              </Button>
            </Form.Item>
          </Form>

          {
              errors && errors?.data && errors?.data?.message &&
              <Alert message={errors?.data?.message} type="error" showIcon />
          }
        </Card>
      </Col>
    </Row>
  );
};

export default Login
