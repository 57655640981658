import * as Types from 'app/actionTypes/shops';

export const getAllShops = () => {
  return {
    type: Types.GET_ALL_SHOPS_REQUESTED,
  }
}

export const getShops = (filter = {}) => {
  return {
    type: Types.GET_SHOPS_REQUESTED,
    payload: filter
  }
}

export const updateShopsItem = (id, data = {}) => ({
  type: Types.UPDATE_SHOPS_ITEM,
  payload: { id, data },
})

export const getCurrency = (filter = {}) => {
  return {
    type: Types.GET_CURRENCY_REQUESTED,
    payload: filter
  }
}

export const updateCurrencyItem = (id, data = {}) => ({
  type: Types.UPDATE_CURRENCY_ITEM,
  payload: { id, data },
})
