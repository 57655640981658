import { call, put, takeEvery } from 'redux-saga/effects'
import { getGateways } from 'services/gateways';
import * as types from 'app/actionTypes/gateways';

const getApi = async (filters = {}) => {
    return getGateways(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchGateways(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_GATEWAYS_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_GATEWAYS_FAILED, message: e?.response });
    }
}

function* gatewaysSaga() {
    yield takeEvery(types.GET_GATEWAYS_REQUESTED, fetchGateways);
}

export default gatewaysSaga;
