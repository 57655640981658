export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const USER_DETAILS_REQUESTED = 'USER_DETAILS_REQUESTED';
export const USER_DETAILS_SUCCEEDED = 'USER_DETAILS_SUCCEEDED';
export const USER_DETAILS_FAILED = 'USER_DETAILS_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const GET_MOVEON_USERS_REQUESTED     = 'GET_MOVEON_USERS_REQUESTED';
export const GET_MOVEON_USERS_SUCCEEDED     = 'GET_MOVEON_USERS_SUCCEEDED';
export const GET_MOVEON_USERS_FAILED        = 'GET_MOVEON_USERS_FAILED';
