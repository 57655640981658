import client from 'utils/api-client';
import Qs from 'qs';

export const getFiles = (filter = null) => {
  return client.get('/files', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const uploadImage = (data) => {
  return client.post(`/files`, data);
};

export const updateFile = (id, data) => {
  return client.put(`/files/${id}`, data);
};

export const deleteFile = (uuid) => {
  return client.delete(`/files/${uuid}`);
};
