import * as type from 'app/actionTypes/customers';
import produce from 'immer';

const initialState = {
    customers: {
        data: null,
        meta: null,
        filters: null,
    },
    loading: false,
    error: null
}

export default function customers(state = initialState, action) {
    const {payload} = action;

    return produce(state, draft => {
        switch (action.type) {
            case type.GET_CUSTOMER_REQUESTED:
                draft.loading = true;
                break;
            case type.GET_CUSTOMER_SUCCESS:
                draft.customers = action.customers;
                draft.loading = false;
                break;
            case type.GET_CUSTOMER_FAILED:
                draft.error = action.message
                draft.loading = false;
                break;
            case type.UPDATE_CUSTOMER:
                draft.customers.data = draft.customers.data.map(ctm => {
                    if (ctm?.id === payload.id) {
                        ctm = {
                            ...ctm,
                            ...payload.data,
                        }
                    }
                    return ctm;
                })
                break;
            default:
                return state
        }
    })

}
