import client from 'utils/api-client';
import Qs from 'qs';

export const getUsers = (filter = null) => {
  return client.get('/users', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getUserDetails = (user_id) => {
  return client.get(`/users/${user_id}`);
};

export const createUser = (data) => {
  return client.post(`/users`, data);
};

export const updateUser = (user_id, data) => {
  return client.put(`/users/${user_id}`, data);
};

export const deleteUser = (user_id) => {
  return client.delete(`/users/${user_id}`);
};

export const createMoveOnUser = (data) => {
  return client.post(`/moveon-accounts`, data);
};

export const updateMoveOnUser = (id, data) => {
  return client.put(`/moveon-accounts/${id}`, data);
};

export const getMoveOnUsers = (filter = null) => {
  return client.get('/moveon-accounts', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};
