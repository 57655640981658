import client from 'utils/api-client';
import Qs from 'qs';
import publicInstance from 'utils/public-client';

export const getShippingCategories = (filter = {}) => {
  return client.get('/shipping-categories', {
    params: {
      ...filter,
      roots: 'yes',
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getShippingCatChildren = (id) => client.get(`shipping-categories?parent_id=${id}`);

export const deleteCategory = (id) => client.delete(`shipping-categories/${id}`);

export const createCategory = (data) => client.post('/shipping-categories', data);

export const updateShippingCategory = (id, data) => client.put(`/shipping-categories/${id}`, data);

export const getTaggedCategories = (id, filters) => {
  return client.get(`/shipping-categories/${id}/tags`, {
    params: {
      ...filters,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  })
};

export const makeTags = (data) => client.post(`/shipping-categories/make-tags`, data);

export const getFrieghtCategories = (filters) => {
  return publicInstance.get(`/products/shipping-categories`, {
    params: {
      ...filters,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const getMvnCategories = () => {
  return client.get(`/services/moveon/categories`);
}

export const getShippingRates = () => {
  return client.get(`/services/moveon/shipping-rates`);
}

export const getAdminFrightCategories = (filters) => {
  return client.get('/shipping-categories', {
    params: filters,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const getLatestShippingCategory = (filters) => {
  return client.get('/shipping-tags/get-latest-tag', {
    params: filters,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const getShippingMethods = (params) => {
  return publicInstance.get(`/products/shipping`, {
      params: { ...params },
      paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
      },
  })
}
