import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as type from 'app/actionTypes/tags';
import {getMoveOnTracking, getTags} from "services/tags";

const getTagsApi = async (filter) => {
    return getTags(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getTrackingApi = async (filter) => {
  return getMoveOnTracking(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchTags(action) {
    try {
        const data = yield call(() => getTagsApi(action.payload));
        yield put({ type: type.GET_TAGS_SUCCESS, payload: data });
    } catch (e) {
        yield put({ type: type.GET_TAGS_FAILED, message: e?.response });
    }
}

function* fetchMoveOnTracking(action) {
  try {
    const data = yield call(() => getTrackingApi(action.payload));
    yield put({ type: type.GET_MOVEON_TRACKING_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: type.GET_MOVEON_TRACKING_FAILED, message: e?.response });
  }
}

function* tagsSaga() {
    yield all([
        takeEvery(type.GET_TAGS_REQUESTED, fetchTags),
        takeEvery(type.GET_MOVEON_TRACKING_REQUEST, fetchMoveOnTracking),
    ]);
}

export default tagsSaga;
