import produce from "immer";
import * as Types from 'app/actionTypes/transactions';

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null,
  itemsDetails: null,
  itemsDetailsLoading: true,
  errorDetails: null,
}

export default function transactions(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_TRANSACTIONS_REQUESTED:
        draft.loading = true
        draft.items = {
          data: null,
          meta: null,
          filters: null,
        }
        break
      case Types.GET_TRANSACTIONS_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case Types.GET_TRANSACTIONS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.RESET_TRANSACTIONS:
        draft.loading = false
        break
      case Types.GET_TRANSACTIONS_DETAILS_REQUESTED:
        draft.itemsDetailsLoading = true
        break
      case Types.GET_TRANSACTIONS_DETAILS_SUCCESS:
        draft.itemsDetails = action.itemsDetails.data
        draft.itemsDetailsLoading = false
        break
      case Types.GET_TRANSACTIONS_DETAILS_FAILED:
        draft.itemsDetailsLoading = false
        draft.errorDetails = action.message
        break
      case Types.UPDATE_TRANSACTION:
        const index = draft.itemsDetails.id === action.payload.id
        if (index) draft.itemsDetails = { ...draft.itemsDetails, ...action.payload.data }
        break
      default:
        return state
    }
  })
}
