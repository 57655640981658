import * as types from 'app/actionTypes/purchaseProducts';

const initialState = {
  products: {
    data: null,
    meta: null
  },
  loading: false,
  error: null
}

export default function purchaseProducts(state = initialState, action) {
  switch (action.type) {
    case types.GET_PURCHASE_PRODUCTS_REQUESTED:
      return {
        ...state,
        products: {
          data: null,
          meta: null
        },
        loading: true
      }
    case types.GET_PURCHASE_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        loading: false,
      }
    case types.GET_PURCHASE_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message
      }
    case types.PURCHASE_PRODUCTS_RESET:
      return initialState
    default:
      return state
  }
}
