import produce from "immer";
import * as Types from 'app/actionTypes/tags';

const initialState = {
  list: {
    data: null,
    meta: null,
    filters: null,
  },
  moveTracking: {
    data: null,
    meta: null,
    filters: null,
  },
  isTrackingLoading: true,
  loading: false,
  error: null
}

export default function tags(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_TAGS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_TAGS_SUCCESS:
        let list = {
          ...action.payload,
          data: action.payload?.data?.map(item => {
            return {
              ...item,
              key: item.id,
              label: item.name,
            }
          })
        }
        draft.list = list;
        draft.loading = false
        break
      case Types.GET_TAGS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.GET_MOVEON_TRACKING_REQUEST:
        draft.isTrackingLoading = true
        break
      case Types.GET_MOVEON_TRACKING_SUCCESS:
        draft.moveTracking = action.payload
        draft.isTrackingLoading = false
        break
      case Types.GET_MOVEON_TRACKING_FAILED:
        draft.isTrackingLoading = false
        draft.error = action.message
        break
      default:
        return state
    }
  })
}
