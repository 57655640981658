import client from 'utils/api-client';
import Qs from 'qs';

export const fetchProducts = (filter = {}) => {
  return client.get('/products', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};


export const importProducts = (params = {}) => {
  return client.post('/products', params)
}

export const fetchProduct = (id, filter = {}) => {
  return client.get(`/products/${id}`, {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const updateProduct = (id, data) => {
  return client.put(`/products/${id}`, data)
}

export const updateProductService = (data, id=null) => {
  if (id) {
    return client.put(`/products/${id}`, data)
  }

  return client.post('/products', data);
}

export const removeProduct = id => client.delete(`/products/${id}`);


export const fetchInventoryProducts = (filter = {}) => {
  return client.get('/inventory-products', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};
