import * as actionTypes from 'app/actionTypes/countries';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getCountries, getCountryDetails } from 'services/countries';

const getCountriesApi = async (filter = {}) => {
    try {
        const res = await getCountries(filter);
        return res.data;
    } catch({response}) {
        return response;
    }
}

function* fetchCountries(action) {
    const {payload} = action;
    try {
        const data = yield call(() => getCountriesApi(payload))
        yield put({type: actionTypes.GET_COUNTRIES_SUCCEEDED, payload: data})
    } catch(e) {
        yield put({type: actionTypes.GET_COUNTRIES_FAILED, message: e?.response})
    }
}

const getCountryDetailsApi = async (id) => {
    try {
        const res = await getCountryDetails(id);
        return res.data;
    } catch({response}) {
        return response;
    }
}

function* fetchCountryDetails(action) {
    const {payload} = action;
    try {
        const data = yield call(() => getCountryDetailsApi(payload));
        yield put({type: actionTypes.GET_COUNTRY_SUCCEEDED, payload: data})
    } catch(e) {
        yield put({type: actionTypes.GET_COUNTRY_FAILED, message: e?.response})
    }
}

function* countriesSaga() {
    yield all([
        takeEvery(actionTypes.GET_COUNTRIES_REQUESTED, fetchCountries),
        takeEvery(actionTypes.GET_COUNTRY_REQUESTED, fetchCountryDetails)
    ])
}

export default countriesSaga;
