import {all, call, put, takeEvery} from "redux-saga/effects"
import * as types from 'app/actionTypes/productDetails';
import {getUrlProductDetails} from "services/public";

const getApi = async (filter = null) => {
  return getUrlProductDetails(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchProductDetails(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.GET_DETAILS_FAILED, message: e.message });
  }
}

function* productDetailsSaga() {
  yield all([
    takeEvery(types.GET_DETAILS_REQUESTED, fetchProductDetails),
  ]);
}

export default productDetailsSaga;
