import * as types from 'app/actionTypes/wallet';
import produce from "immer";

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null,
  walletDetails: null,
  walletDetailsLoading: true,
}

export default function wallet(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_WALLET_REQUESTED:
        draft.loading = true
        break
      case types.GET_WALLET_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case types.GET_WALLET_FAILED:
        draft.error = action.message
        draft.loading = false
        break
      case types.GET_WALLET_DETAILS_REQUESTED:
        draft.walletDetailsLoading = true
        break
      case types.GET_WALLET_DETAILS_SUCCESS:
        draft.walletDetails = action.walletDetails.data.wallet
        draft.walletDetailsLoading = false
        break
      case types.GET_WALLET_DETAILS_FAILED:
        draft.error = action.message
        draft.walletDetailsLoading = false
        break
      default:
        return state
    }
  })
}
