import * as types from 'app/actionTypes/importOrders';
import produce from "immer";

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: true,
  error: null,
  itemDetails: null,
  itemDetailsLoading: true
}

export default function importOrders(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_IMPORT_ORDER_REQUESTED:
        draft.loading = true
        // draft.items = {
        //   data: null,
        //   meta: null,
        //   filters: null,
        // }
        break
      case types.GET_IMPORT_ORDER_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case types.GET_IMPORT_ORDER_FAILED:
        draft.error = action.message
        draft.loading = false
        break
      case types.GET_IMPORT_ORDER_DETAILS_REQUESTED:
        draft.itemDetailsLoading = true
        break
      case types.GET_IMPORT_ORDER_DETAILS_SUCCESS:
        draft.itemDetails = action.items.data
        draft.itemDetailsLoading = false
        break
      case types.GTE_IMPORT_ORDER_DETAILS_FAILED:
        draft.itemDetailsLoading = false
        draft.error = action.message
        break
      // case types.UPDATE_ORDER_ITEMS_WITH_RELATIONSHIPS:
      //   const index = draft.itemsWithRelations.data.findIndex(itm => itm.id === action.payload.id)
      //   if (index !== -1) draft.itemsWithRelations.data[index] = { ...draft.itemsWithRelations.data[index], ...action.payload.data }
      //   break
      default:
        return state
    }
  })
}
