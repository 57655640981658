export const GET_SHIPPING_CATEGORIES_REQUESTED  = 'GET_SHIPPING_CATEGORIES_REQUESTED';
export const GET_SHIPPING_CATEGORIES_SUCCESS    = 'GET_SHIPPING_CATEGORIES_SUCCESS';
export const GET_SHIPPING_CATEGORIES_FAILED     = 'GET_SHIPPING_CATEGORIES_FAILED';
export const UPDATE_SHIPPING_CATEGORY           = 'UPDATE_SHIPPING_CATEGORY';
export const SET_SHIPPING_CATEGORIES_DATA       = 'SET_SHIPPING_CATEGORIES_DATA';

export const SHIPPING_CHILDREN_REQUESTED  = 'SHIPPING_CHILDREN_REQUESTED';
export const SHIPPING_CHILDREN_SUCCEEDED  = 'SHIPPING_CHILDREN_SUCCEEDED';
export const SHIPPING_CHILDREN_FAILED  = 'SHIPPING_CHILDREN_FAILED';

export const ADD_SHIPPING_CAT  = 'ADD_SHIPPING_CAT';
export const REMOVE_SHIPPING_CAT  = 'REMOVE_SHIPPING_CAT';
export const SET_SHIPPING_CHILDREN_CATS  = 'SET_SHIPPING_CHILDREN_CATS';

export const GET_FREIGHT_CATEGORIES_REQUESTED  = 'GET_FREIGHT_CATEGORIES_REQUESTED';
export const GET_FREIGHT_CATEGORIES_SUCCESS    = 'GET_FREIGHT_CATEGORIES_SUCCESS';
export const GET_FREIGHT_CATEGORIES_FAILED     = 'GET_FREIGHT_CATEGORIES_FAILED';

export const GET_MVN_CATEGORIES_REQUESTED = 'GET_MVN_CATEGORIES_REQUESTED';
export const GET_MVN_CATEGORIES_SUCCEEDED = 'GET_MVN_CATEGORIES_SUCCEEDED';
export const GET_MVN_CATEGORIES_FAILED = 'GET_MVN_CATEGORIES_FAILED';

export const GET_MVN_SHIPPING_RATES_REQUESTED = 'GET_MVN_SHIPPING_RATES_REQUESTED';
export const GET_MVN_SHIPPING_RATES_SUCCEEDED = 'GET_MVN_SHIPPING_RATES_SUCCEEDED';
export const GET_MVN_SHIPPING_RATES_FAILED = 'GET_MVN_SHIPPING_RATES_FAILED';

export const SHIPPING_TAGGED_CATS_REQUESTED = 'SHIPPING_TAGGED_CATS_REQUESTED';
export const SHIPPING_TAGGED_CATS_SUCCEEDED = 'SHIPPING_TAGGED_CATS_SUCCEEDED';
export const SHIPPING_TAGGED_CATS_FAILED = 'SHIPPING_TAGGED_CATS_FAILED';

export const GET_ADMIN_FREIGHT_CATEGORIES_REQUESTED  = 'GET_ADMIN_FREIGHT_CATEGORIES_REQUESTED';
export const GET_ADMIN_FREIGHT_CATEGORIES_SUCCESS    = 'GET_ADMIN_FREIGHT_CATEGORIES_SUCCESS';
export const GET_ADMIN_FREIGHT_CATEGORIES_FAILED     = 'GET_ADMIN_FREIGHT_CATEGORIES_FAILED';

export const GET_LATEST_SHIPPING_CATEGORIES_REQUESTED  = 'GET_LATEST_SHIPPING_CATEGORIES_REQUESTED';
export const GET_LATEST_SHIPPING_CATEGORIES_SUCCESS    = 'GET_LATEST_SHIPPING_CATEGORIES_SUCCESS';
export const GET_LATEST_SHIPPING_CATEGORIES_FAILED     = 'GET_LATEST_SHIPPING_CATEGORIES_FAILED';
export const RESET_LATEST_SHIPPING                     = 'RESET_LATEST_SHIPPING';
