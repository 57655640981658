import * as actionTypes from 'app/actionTypes/contents';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getContentDetails, getContents } from 'services/contents';

const getContentsApi = async (filter = {}) => {
    try {
        const res = await getContents(filter);
        return res.data;
    } catch({response}) {
        return response;
    }
}

function* fetchContents(action) {
    const {payload} = action;
    try {
        const data = yield call(() => getContentsApi(payload))
        yield put({type: actionTypes.CONTENTS_SUCCEEDED, payload: data})
    } catch(e) {
        yield put({type: actionTypes.CONTENTS_FAILED, message: e?.response})
    }
}

const getContentDetailsApi = async (id) => {
    try {
        const res = await getContentDetails(id);
        return res.data;
    } catch({response}) {
        return response;
    }
}

function* fetchContentDetails(action) {
    const {payload} = action;
    try {
        const data = yield call(() => getContentDetailsApi(payload));
        yield put({type: actionTypes.CONTENT_SUCCEEDED, payload: data})
    } catch(e) {
        yield put({type: actionTypes.CONTENT_FAILED, message: e?.response})
    }
}

function* contentsSaga() {
    yield all([
        takeEvery(actionTypes.CONTENTS_REQUESTED, fetchContents),
        takeEvery(actionTypes.CONTENT_REQUESTED, fetchContentDetails)
    ])
}

export default contentsSaga;
