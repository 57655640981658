import * as actionTypes from "app/actionTypes/users";
import produce from "immer";

const initialState = {
  users: {
    data: null,
    meta: null,
    filters: null,
  },
  details: {
    data: null,
    loading: true,
    error: null,
  },
  loading: true,
  error: null,
  moveonUser: {
    data: null,
    meta: null,
    filters: null,
  },
  moveonUserLoading: true,
}

export default function users(state = initialState, action) {
  const {payload} = action;

  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.GET_USERS_REQUESTED:
        draft.loading = true;
        break;
      case actionTypes.GET_USERS_SUCCEEDED:
        draft.users = payload;
        draft.loading = false;
        break;
      case actionTypes.GET_USERS_FAILED:
        draft.error = action.message
        draft.loading = false;
        break;
      case actionTypes.UPDATE_USER:
        draft.users.data = draft.users.data.map(ctm => {
          if (ctm?.id === payload.id) {
            ctm = {
              ...ctm,
              ...payload.data,
            }
          }
          return ctm;
        })
        break;
      case actionTypes.REMOVE_USER:
        draft.users.data = draft.users.data?.filter(user => user.id !== payload);
        break;
      case actionTypes.USER_DETAILS_REQUESTED:
        draft.details.loading = true;
        break;
      case actionTypes.USER_DETAILS_SUCCEEDED:
        draft.details.data = payload?.data;
        draft.details.loading = false;
        break;
      case actionTypes.USER_DETAILS_FAILED:
        draft.error = action.message
        draft.details.loading = false;
        break;
      case actionTypes.GET_MOVEON_USERS_REQUESTED:
        draft.moveonUserLoading = true;
        break;
      case actionTypes.GET_MOVEON_USERS_SUCCEEDED:
        draft.moveonUser = payload;
        draft.moveonUserLoading = false;
        break;
      case actionTypes.GET_MOVEON_USERS_FAILED:
        draft.error = action.message
        draft.moveonUserLoading = false;
        break;
      default:
        return state
    }
  })

}
