export const GET_PRODUCTS_REQUESTED = 'GET_PRODUCTS_REQUESTED';
export const GET_PRODUCTS_SUCCEDED = 'GET_PRODUCTS_SUCCEDED';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_PRODUCT_REQUESTED = 'GET_PRODUCT_REQUESTED';
export const GET_PRODUCT_SUCCEDED = 'GET_PRODUCT_SUCCEDED';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED';
export const UPDATE_PRODUCT_SUCCEEDED = 'UPDATE_PRODUCT_SUCCEEDED';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const PRODUCTS_SEARCH_REQUESTED = 'PRODUCTS_SEARCH_REQUESTED';
export const PRODUCTS_SEARCH_SUCCEEDED = 'PRODUCTS_SEARCH_SUCCEEDED';
export const PRODUCTS_SEARCH_FAILED = 'PRODUCTS_SEARCH_FAILED';
export const CLEAR_PRODUCTS_SEARCH = 'CLEAR_PRODUCTS_SEARCH';

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'; 

// Inv = Inventory
export const INV_PRODUCTS_SEARCH_REQUESTED = 'INV_PRODUCTS_SEARCH_REQUESTED';
export const INV_PRODUCTS_SEARCH_SUCCEEDED = 'INV_PRODUCTS_SEARCH_SUCCEEDED';
export const INV_PRODUCTS_SEARCH_FAILED = 'INV_PRODUCTS_SEARCH_FAILED';
export const CLEAR_INV_PRODUCTS_SEARCH = 'CLEAR_INV_PRODUCTS_SEARCH';
