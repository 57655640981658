import * as orderType from 'app/actionTypes/orders';
import { message } from 'antd';
import produce from "immer";

const initialState = {
    orders: {
        data: null,
        meta: null,
        filters: null,
    },
    loading: false,
    itemIsUpdating: false,
    itemUpdateError: null,
    error: null
}

export default function orders(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case orderType.GET_ORDERS_REQUESTED:
        draft.loading = true
        break
      case orderType.GET_ORDERS_SUCCESS:
        draft.orders = action.orders
        draft.loading = false
        break
      case orderType.GET_ORDERS_FAILED:
        draft.loading = true
        draft.error = action.message
        break
      case orderType.UPDATE_ORDERS:
        const index = draft.orders.data.findIndex(itm => itm.id === action.payload.id)
        if (index !== -1) draft.orders.data[index] = { ...draft.orders.data[index], ...action.payload.data }
        break
      case orderType.ITEM_UPDATE_REQUESTED:
        return {
          ...state,
          itemIsUpdating: true,
        }
      case orderType.ITEM_UPDATE_SUCCESS:
        message.success('Item updated');

        let orderIndex, itemIndex;

        for (let index = 0; index < state.orders.data.length; index++) {
          const element = state.orders.data[index];
          for (let j = 0; j < element.items.length; j++) {
            const itemElement = element.items[j];
            if (itemElement.id === action.data.id) {
              orderIndex = index;
              itemIndex = j;
              break;
            }
          }
        }

        const oldOrders = state.orders.data
        const updatedOrders = oldOrders.map((order, orderIdx) => {
          if (orderIdx === orderIndex) {
            const newItems = order.items.map((orderItem, itemIdx) => {
              if (itemIdx === itemIndex) {
                return {...orderItem, ...action.data.data};
              }
              return orderItem;
            })
            return {...order, ...{items: newItems}};
          }
          return order;
        });

        return {
          ...state,
          orders: {
            ...state.orders,
            data: updatedOrders
          },
          itemIsUpdating: false,
        }

      case orderType.ITEM_UPDATE_FAILED:
        return {
          ...state,
          itemIsUpdating: false,
          itemUpdateError: action.error
        }
      case orderType.RESET_ORDERS:
        return {
          ...initialState,
          loading: state.loading
        }
      default:
        return state
    }
  })
}
