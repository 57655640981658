import * as actionTypes from 'app/actionTypes/campaignProducts';
import produce from 'immer';

const initialState = {
    products: {
        data: null,
        meta: null,
        campaign: null,
    },
    loading: true,
    error: null,
}

export default function campaignProducts(state = initialState, action) {
    const { type, payload } = action

    return produce(state, draft => {
        switch (type) {
            case actionTypes.CAMPAIGN_PRODUCTS_REQUESTED:
                draft.loading = true;
                break;
            case actionTypes.CAMPAIGN_PRODUCTS_SUCCEEDED:
                draft.products = payload;
                draft.loading = false;
                break;
            case actionTypes.CAMPAIGN_PRODUCTS_FAILED:
                draft.loading = false;
                draft.error = action.message
                break;
            case actionTypes.UPDATE_CAMPAIGN_PRODUCTS:
                const index = draft.products.data.findIndex(item => item.id === payload.id)
                if (index !== -1) draft.products.data[index] = { ...draft.products.data[index], ...payload.data }
                break;
            case actionTypes.DELETE_CAMPAIGN_PRODUCT:
                draft.products.data = draft.products.data.filter(item => !payload.includes(item.id));
                break;
            default:
                return state;
        }
    })


}
