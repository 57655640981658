import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getFiles } from 'services/files';
import * as type from 'app/actionTypes/files';

const getApi = async (filter) => {
    return getFiles(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchFiles(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_FILES_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: type.GET_FILES_FAILED, message: e?.response });
    }
}

function* filesSaga() {
    yield all([
        takeEvery(type.GET_FILES_REQUESTED, fetchFiles),
    ]);
}

export default filesSaga;
