import produce from "immer";
import * as Types from 'app/actionTypes/shops';

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null,
  all: null,
  allShopsLoading: false,
  currency: {
    data: null,
    meta: null,
    filters: null,
  },
  currencyLoading: false,
  errorCurrency: null,
}

export default function shops(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_ALL_SHOPS_REQUESTED:
        draft.allShopsLoading = true
        break
      case Types.GET_ALL_SHOPS_SUCCESS:
        draft.all = action.data
        draft.allShopsLoading = false
        break
      case Types.GET_ALL_SHOPS_FAILED:
        draft.allShopsLoading = false
        draft.error = action.message
        break
      case Types.GET_SHOPS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_SHOPS_SUCCESS:
        draft.items = action.data
        draft.loading = false
        break
      case Types.GET_SHOPS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.UPDATE_SHOPS_ITEM:
        const index = draft.items.data.findIndex(itm => itm.id === action.payload.id)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data.value }
        if (index === -1) draft.items.data[0] = { ...draft.items.data[0], ...action.payload.data.value }
        break
      case Types.GET_CURRENCY_REQUESTED:
        draft.currencyLoading = true
        break
      case Types.GET_CURRENCY_SUCCESS:
        draft.currency = action.data
        draft.currencyLoading = false
        break
      case Types.GET_CURRENCY_FAILED:
        draft.currencyLoading = false
        draft.errorCurrency = action.message
        break
      case Types.UPDATE_CURRENCY_ITEM:
        const indexCurrency = draft.currency.data.findIndex(itm => itm.id === action.payload.id)
        if (indexCurrency !== -1) draft.currency.data[indexCurrency] = { ...draft.currency.data[indexCurrency], ...action.payload.data.value }
        if (indexCurrency === -1) draft.currency.data[0] = { ...draft.currency.data[0], ...action.payload.data.value }
        break
      default:
        return state
    }
  })
}
