export const GET_REFUNDS_REQUESTED  = 'GET_REFUNDS_REQUESTED';
export const GET_REFUNDS_SUCCESS    = 'GET_REFUNDS_SUCCESS';
export const GET_REFUNDS_FAILED     = 'GET_REFUNDS_FAILED';
export const RESET_REFUNDS          = 'RESET_REFUNDS';
export const UPDATE_REFUND          = 'UPDATE_REFUND';

export const UPDATE_REFUND_DETAILS   = 'UPDATE_REFUND_DETAILS';

export const GET_REFUNDS_DETAILS_REQUESTED  = 'GET_REFUNDS_DETAILS_REQUESTED';
export const GET_REFUNDS_DETAILS_SUCCESS    = 'GET_REFUNDS_DETAILS_SUCCESS';
export const GET_REFUNDS_DETAILS_FAILED     = 'GET_REFUNDS_DETAILS_FAILED';

export const ISSUE_ABLE_DETAILS_REQUEST       = 'ISSUE_ABLE_DETAILS_REQUEST';
export const ISSUE_ABLE_DETAILS_SUCCESS       = 'ISSUE_ABLE_DETAILS_SUCCESS';
export const ISSUE_ABLE_DETAILS_FAILED        = 'ISSUE_ABLE_DETAILS_FAILED';
export const UPDATE_ISSUE_ABLE_DETAILS        = 'UPDATE_ISSUE_ABLE_DETAILS';
