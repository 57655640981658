import Qs from 'qs';
import client from 'utils/api-client';

export const getTags = (filter = {}) => {
  return client.get('/campaign-tags', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createTag = (data) => {
  return client.post('/campaign-tags', data)
}

export const updateTag = (id, data) => {
  return client.put(`/campaign-tags/${id}`, data);
};

export const removeTag = (id) => {
  return client.delete(`/campaign-tags/${id}`);
};

export const getMoveOnTracking = (filter = {}) => {
  return client.get(`/services/moveon/tracking`, {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};
