import Qs from 'qs';
import client from 'utils/api-client';

export const getShippingTags = (filter = {}) => {
  return client.get('/shipping-tags', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createShippingTag = (data) => {
  return client.post('/shipping-tags', data)
}

export const updateShippingTag = (id, data) => {
  return client.put(`/shipping-tags/${id}`, data);
};

export const removeShippingTag = (id) => {
  return client.delete(`/shipping-tags/${id}`);
};

export const getLatestShippingTag = (filter = {}) => {
  return client.get('/shipping-tags/get-latest-tag', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};
