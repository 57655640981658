import * as actionTypes from 'app/actionTypes/rolesPermissions';
import produce from 'immer';

const initialState = {
    data: null,
    tableColumns: [],
    tableData: [],
    groupedPermission: null,
    loading: true,
    error: null,
    attachedPermissionsByRole: [],
    roles: {
      data: null,
      meta: null,
      filters: null,
    },
    rolesLoading: true,
}

const getAttachedPermissionsByRole = (tableColumns, permission_role) => {
    return tableColumns?.map(item => {

        item['permissions'] = permission_role?.reduce((acc, pmr) => {
            if (pmr.role_id === item.id) {
                acc.push(pmr.permission_id);
            }
            return acc;
        }, []);

        return item;
    })

}

const getRowsData = (groupedPermission, tableColumns) => {
    return Object.keys(groupedPermission)?.map((objKey) => {
        let newItem = {};

        let childrenData = groupedPermission[objKey]?.map(item => {
            let chItem = {};
            for (let column of tableColumns) {
                let keyName = column.dataIndex;
                if (keyName === 'permissions') {
                    chItem[keyName] = item.name;
                } else {
                    let isChecked = column.permissions?.includes(item.id);

                    chItem[keyName] = {
                        ...item,
                        roleId: column.id,
                        roleKey: keyName,
                        isChecked,
                    }
                }

            }
            chItem['key'] = item.id;

            return chItem;
        })
        for (let column of tableColumns) {
            let keyName = column.dataIndex;
            if (keyName === 'permissions') {
                newItem[keyName] = objKey.replaceAll('_', ' ');
            }
            else {
                let items = childrenData.map(item => item[keyName].id);
                let checkedItems = childrenData.reduce((acc, item) => {
                    if (item[keyName].isChecked) {
                        acc.push(item[keyName].id)
                    }
                    return acc;
                }, []);

                newItem[keyName] = {
                    checkedItems,
                    items,
                    isChecked: items.length === checkedItems.length,
                    isGroupCell: true,
                    roleId: column.id,
                    roleKey: keyName,
                }
            }
        }
        newItem['key'] = objKey;
        newItem['children'] = childrenData;

        return newItem;
    })
}

export default function rolesPermissions(state = initialState, action) {
    const { type, payload } = action;

    return produce(state, draft => {
        switch (type) {
            case actionTypes.GET_PERMINSSIONS_REQUESTED:
                draft.loading = true;
                break;
            case actionTypes.GET_PERMINSSIONS_SUCCEEDED:
                draft.data = payload;
                let groupedPermission = payload.permissions.reduce((acc, obj) => {
                    const property = obj['group'];
                    acc[property] = acc[property] || [];
                    acc[property].push(obj);
                    return acc;
                }, {})
                draft.groupedPermission = groupedPermission;
                let rolecolumns = [
                    {
                        id: 'pms-1',
                        name: 'Permissions',
                        key: 'permissions',
                    },
                    ...payload.roles
                ]
                let tableColumns = rolecolumns.map(item => {
                    return {
                        ...item,
                        title: item.name,
                        dataIndex: item.key,
                        key: item.key,
                    }
                })
                draft.tableColumns = tableColumns;
                let attachedPermissionsByRole = getAttachedPermissionsByRole(tableColumns, payload.permission_role);
                draft.attachedPermissionsByRole = attachedPermissionsByRole;
                let rowsData = getRowsData(groupedPermission, attachedPermissionsByRole);
                draft.tableData = rowsData;
                draft.loading = false;
                break;
            case actionTypes.GET_PERMINSSIONS_FAILED:
                draft.loading = false;
                draft.error = payload;
                break;
            case actionTypes.ATTACH_ROLE_PERMISSIONS: {
                let attachedPermissionsByRole = draft.attachedPermissionsByRole.map(role => {
                    if (role.id === payload.role_id) {
                        role.permissions = [
                            ...role.permissions,
                            ...payload.permission_ids,
                        ]
                    }
                    return role;
                })
                draft.attachedPermissionsByRole = attachedPermissionsByRole;
                let rowsData = getRowsData(draft.groupedPermission, attachedPermissionsByRole);
                draft.tableData = rowsData;
                break;
            }
            case actionTypes.DETACH_ROLE_PERMISSIONS: {
                let attachedPermissionsByRole = draft.attachedPermissionsByRole.map(role => {
                    if (role.id === payload.role_id) {
                        role.permissions = role.permissions?.filter(pms => !payload.permission_ids?.includes(pms))
                    }
                    return role;
                })
                draft.attachedPermissionsByRole = attachedPermissionsByRole;
                let rowsData = getRowsData(draft.groupedPermission, attachedPermissionsByRole);
                draft.tableData = rowsData;
                break;
            }
          case actionTypes.GET_ROLES_REQUESTED:
            draft.rolesLoading = true
            break
          case actionTypes.GET_ROLES_SUCCEEDED:
            draft.roles = action.items
            draft.rolesLoading = false
            break
          case actionTypes.GET_ROLES_FAILED:
            draft.error = action.message
            draft.rolesLoading = false
            break
            default:
                return state;
        }
    })
}
