import * as actionTypes from 'app/actionTypes/issues';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {getIssue} from "services/item-refunds";

const getIssueAbleApi = async (filters = {}) => {
  return getIssue(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchIssues(action) {
    const { payload } = action;
    try {
        const data = yield call(() => getIssueAbleApi(payload));
        yield put({type: actionTypes.GET_ISSUES_SUCCEEDED, payload: data})
    } catch({response}) {
        yield put({type: actionTypes.GET_ISSUES_FAILED, message: response})
    }
}

function* issuesSaga() {
    yield all([
        takeEvery(actionTypes.GET_ISSUES_REQUESTED, fetchIssues)
    ])
}

export default issuesSaga;
