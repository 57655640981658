import client from 'utils/api-client';
import Qs from 'qs';

export const getShops = (filter = {}) => {
  return client.get('/shops', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getAllShops = () => client.get('/shops/all');

export const deleteShops = (id) => {
  return client.delete(`/shops/${id}`)
}

export const addShops = (data) => {
  return client.post('/shops', data)
}

export const updateShops = (id, data) => {
  return client.put(`/shops/${id}`, data)
}
