import client from 'utils/api-client';
import Qs from 'qs';
import axios from 'axios';

export const getRequests = (filter = {}) => {
  return client.get('/requests', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getRequest = (id) => client.get(`/requests/${id}`);

export const updateRequest = (id, data) => client.put(`/requests/${id}`, data);

export const bulkApproveOrderRequest = (data) => client.put('/requests/approve', data)

export const getRequestMetadata = (url) => {
  return axios.get(`https://inventory.ali2bd.com/api/v1/metadata?url=${url}`)
}

