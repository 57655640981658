import * as type from 'app/actionTypes/parcels';
import produce from "immer";

const initialState = {
    parcels: {
      data: null,
      meta: null
    },
    parcelsStatus: null,
    parcelsLoading: true,
    loading: true,
    error: null,
    parcelDetails: {
      data: null,
    },
    parcelsDetailsLoading: true,
}

export default function parcels(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.GET_PARCELS_REQUESTED:
        draft.loading = true
        break
      case type.GET_PARCELS_SUCCESS:
        draft.parcels = action.parcels
        draft.loading = false
        break
      case type.SET_PARCELS:
        draft.parcels.data = action.payload.data
        draft.parcels.meta = action.payload.meta
        break
      case type.GET_PARCELS_FAIL:
        draft.loading = false
        draft.error = action.parcels
        break
      case type.RESET_PARCELS:
        draft.loading = state.loading
        draft.parcels = {
          data: null,
          meta: null,
        }
        break
      case type.GET_PARCELS_STATUS_REQUESTED:
        draft.parcelsLoading = true
        break
      case type.GET_PARCELS_STATUS_SUCCESS:
        draft.parcelsStatus = action.parcelsStatus.data
        draft.parcelsLoading = false
        break
      case type.GET_PARCELS_STATUS_FAIL:
        draft.parcelsLoading = false
        draft.error = action.message
        break
      case type.UPDATE_PARCEL_ITEM:
        const index = draft.parcels.data.findIndex(itm => itm.id === action.payload.id)
        if (index !== -1) draft.parcels.data[index] = { ...draft.parcels.data[index], ...action.payload.data }
        if (index === -1) draft.parcels.data[0] = { ...draft.parcels.data[0], ...action.payload.data }
        break
      case type.GET_PARCELS_DETAILS_REQUESTED:
        draft.parcelsDetailsLoading = true
        break
      case type.GET_PARCELS_DETAILS_SUCCESS:
        draft.parcelDetails = action.parcelDetails
        draft.parcelsDetailsLoading = false
        break
      case type.GET_PARCELS_DETAILS_FAIL:
        draft.parcelsDetailsLoading = false
        draft.error = action.message
        break
      default:
        return state
    }
  })
}
