import * as Types from 'app/actionTypes/productDetails';
import produce from "immer";

const initialState = {
  data: null,
  loading: true,
  selectedProps: null
}

export default function productsDetails(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_DETAILS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_DETAILS_SUCCESS:
        draft.data = action.data
        draft.loading = false
        break
      case Types.GET_DETAILS_FAILED:
        draft.loading = false
        break
      case Types.RESET_DETAILS:
        draft.data = null;
        break;
      default:
        return state
    }
  })
}
