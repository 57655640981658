import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  getAdminFrightCategories,
  getFrieghtCategories, getLatestShippingCategory,
  getMvnCategories,
  getShippingCatChildren,
  getShippingCategories,
  getShippingRates,
  getTaggedCategories
} from "services/shipping-categories";
import * as types from 'app/actionTypes/shippingCategories';

const getShippingCategoriesApi = async (filters = {}) => {
    return getShippingCategories(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getLatestShippingCategoryAPI = async (filters = {}) => {
  return getLatestShippingCategory(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchLatestShippingCat(action) {
  try {
    const data = yield call(() => getLatestShippingCategoryAPI(action.payload));
    yield put({ type: types.GET_LATEST_SHIPPING_CATEGORIES_SUCCESS, payload: data });
  } catch ({response}) {
    yield put({ type: types.GET_LATEST_SHIPPING_CATEGORIES_FAILED, error: response });
  }
}

function* fetchShippingCategories(action) {
    try {
        const data = yield call(() => getShippingCategoriesApi(action.payload));
        yield put({ type: types.GET_SHIPPING_CATEGORIES_SUCCESS, payload: data });
    } catch (e) {
        yield put({ type: types.GET_SHIPPING_CATEGORIES_FAILED, message: e?.response });
    }
}

const geFreightCatstApi = async (filters = {}) => {
    return getFrieghtCategories(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getAdminFreightCatstApi = async (filters = {}) => {
  return getAdminFrightCategories(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchFreightCats(action) {
    try {
        const data = yield call(() => geFreightCatstApi(action.payload));
        yield put({ type: types.GET_FREIGHT_CATEGORIES_SUCCESS, payload: data });
    } catch (e) {
        yield put({ type: types.GET_FREIGHT_CATEGORIES_FAILED, message: e?.response });
    }
}

function* fetchAdminFreightCats(action) {
  try {
    const data = yield call(() => getAdminFreightCatstApi(action.payload));
    yield put({ type: types.GET_ADMIN_FREIGHT_CATEGORIES_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: types.GET_ADMIN_FREIGHT_CATEGORIES_FAILED, message: e?.response });
  }
}

const getShippingCatChildrenApi = (id) => {
    return getShippingCatChildren(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchShippingCatChildren(action) {
    try {
        const data = yield call(() => getShippingCatChildrenApi(action.payload.id));
        yield put({ type: types.SHIPPING_CHILDREN_SUCCEEDED, payload: {data, ...action.payload} });
    } catch (e) {
        yield put({ type: types.SHIPPING_CHILDREN_FAILED, message: e?.response });
    }
}

const getMvnCategoriesApi = () => {
    return getMvnCategories()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchMvnCategories(action) {
    try {
        const data = yield call(() => getMvnCategoriesApi());
        yield put({ type: types.GET_MVN_CATEGORIES_SUCCEEDED, payload: data });
    } catch (e) {
        yield put({ type: types.GET_MVN_CATEGORIES_FAILED, message: e?.response });
    }
}

const getShippingRatesApi = () => {
    return getShippingRates()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchShippingRates(action) {
    try {
        const data = yield call(() => getShippingRatesApi());
        yield put({ type: types.GET_MVN_SHIPPING_RATES_SUCCEEDED, payload: data });
    } catch (e) {
        yield put({ type: types.GET_MVN_SHIPPING_RATES_FAILED, message: e?.response });
    }
}

const getShippingTaggedCatsApi = (id, filters = {}) => {
    return getTaggedCategories(id, filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchShippingTaggedCats(action) {
    const { payload } = action
    try {
        const data = yield call(() => getShippingTaggedCatsApi(payload.id, payload.filters));
        yield put({ type: types.SHIPPING_TAGGED_CATS_SUCCEEDED, payload: data });
    } catch (e) {
        yield put({ type: types.SHIPPING_TAGGED_CATS_FAILED, message: e?.response });
    }
}

function* shippingCategoriesSaga() {
    yield all([
        takeEvery(types.GET_SHIPPING_CATEGORIES_REQUESTED, fetchShippingCategories),
        takeEvery(types.GET_FREIGHT_CATEGORIES_REQUESTED, fetchFreightCats),
        takeEvery(types.SHIPPING_CHILDREN_REQUESTED, fetchShippingCatChildren),
        takeEvery(types.GET_MVN_CATEGORIES_REQUESTED, fetchMvnCategories),
        takeEvery(types.GET_MVN_SHIPPING_RATES_REQUESTED, fetchShippingRates),
        takeEvery(types.SHIPPING_TAGGED_CATS_REQUESTED, fetchShippingTaggedCats),
        takeEvery(types.GET_ADMIN_FREIGHT_CATEGORIES_REQUESTED, fetchAdminFreightCats),
        takeEvery(types.GET_LATEST_SHIPPING_CATEGORIES_REQUESTED, fetchLatestShippingCat),
    ])
}

export default shippingCategoriesSaga;
