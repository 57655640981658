import produce from "immer";
import * as Types from 'app/actionTypes/moveOnCategories';

const initialState = {
  list: {
    data: null,
    meta: null,
    filters: null,
    treeData: null,
  },
  loading: false,
  error: null,
  costCalc: {
    data: null,
    loading: false,
    error: null,
  },
  warehouse: {
    data: null,
    loading: false,
    error: null
  }
}

const formattedTreeData = (list) => {

  return list.map(item => {
    const { childs_recursive, ...restItem } = item;
    let newItem = {...restItem};
    newItem['title'] = newItem.name;
    newItem['disabled'] = !newItem.isLast;
    newItem['value'] = newItem?.isLast ? newItem?.id : newItem.name;
    if (childs_recursive?.length) {
      newItem['children'] = formattedTreeData(childs_recursive)
    }
    return newItem;
  })

}

export default function moveOnCategories(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_MOVEON_CATS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_MOVEON_CATS_SUCCESS:
        let list = {
          ...action.payload,
          data: action.payload?.data?.map(item => {
            return {
              ...item,
              key: item.id,
              label: item.name,
            }
          })
        }
        draft.list = list;
        let treeData = formattedTreeData(action.payload?.data);
        draft.list.treeData = treeData;
        draft.loading = false
        break
      case Types.GET_MOVEON_CATS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.GET_MOVEON_COST_CALC_REQUESTED:
        draft.costCalc.loading = true;
        break;
      case Types.GET_MOVEON_COST_CALC_SUCCESS:
        draft.costCalc = {
          ...draft.costCalc,
          ...action.payload
        };
        draft.costCalc.loading = false;
        break;
      case Types.GET_MOVEON_COST_CALC_FAILED:
        draft.costCalc.loading = false;
        break;
        case Types.GET_MVN_WAREHOUSE_REQUESTED:
          draft.warehouse.loading = true;
          break;
        case Types.GET_MVN_WAREHOUSE_SUCCESS:
          draft.warehouse = {
            ...draft.warehouse,
            ...action.payload
          };
          draft.costCalc.loading = false;
          break;
        case Types.GET_MVN_WAREHOUSE_FAILED:
          draft.warehouse.loading = false;
          break;
      default:
        return state
    }
  })
}
