import { call, put, takeEvery, all } from 'redux-saga/effects'
import {getCartItem, getCartItems} from 'services/cart-items';
import * as types from 'app/actionTypes/cartItems';

const getApi = async (filter) => {
  return getCartItems(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getDetailsApi = async (id) => {
  return getCartItem(id)
      .then(res => res.data)
      .catch((error) => { throw error })
}

function* fetchCartItems(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_CART_ITEMS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_CART_ITEMS_FAILED, message: e?.response });
  }
}

function* fetchCartItemDetails(action) {
  try {
    const data = yield call(() => getDetailsApi(action.payload));
    yield put({ type: types.GET_CART_ITEMS_DETAILS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_CART_ITEMS_DETAILS_FAILED, message: e?.response });
  }
}

function* cartItemsSaga() {
  yield all([
    takeEvery(types.GET_CART_ITEMS_REQUESTED, fetchCartItems),
    takeEvery(types.GET_CART_ITEMS_DETAILS_REQUESTED, fetchCartItemDetails),
  ]);
}

export default cartItemsSaga;
