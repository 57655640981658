import client from 'utils/api-client';
import Qs from 'qs';

export const getStoreAccounts = (filter = {}) => {
  return client.get('/shop-accounts', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const addStoreAccounts = (data) => {
  return client.post('/shop-accounts', data)
}

export const updateStoreAccounts = (id, data) => {
  return client.put(`/shop-accounts/${id}`, data)
}

export const deleteStoreAccounts = (id) => {
  return client.delete(`/shop-accounts/${id}`)
}

export const storeAccountsDetails = (id) => {
  return client.get(`/shop-accounts/${id}`)
}

export const updateAccountPermission = (data) => {
  return client.post(`/shop-accounts/user-update`, data)
}
