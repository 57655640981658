import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getCustomers } from 'services/customer-client';
import * as type from 'app/actionTypes/customers';

const getApi = async (filter) => {
    return getCustomers(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchCustomers(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_CUSTOMER_SUCCESS, customers: data });
    } catch (e) {
        yield put({ type: type.GET_CUSTOMER_FAILED, message: e?.response });
    }
}

function* ordersSaga() {
    yield all([
        takeEvery(type.GET_CUSTOMER_REQUESTED, fetchCustomers),
    ]);
}

export default ordersSaga;
