import * as React from 'react';

const BuilderLayout = ({ children }: any) => {
    return (
        <div className="container">
            <React.Suspense fallback={<div className="flex items-center justify-center h-screen w-full"><span>Loading...</span></div>}>
                {children}
            </React.Suspense>
        </div>
    )
}

export default BuilderLayout;
