import client from 'utils/api-client';
import Qs from 'qs';

export const getOrderItems = (filter = {}) => {
  return client.get('/order-items', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getProductSearchValue = (filter = {}) => {
  return client.get('/order-items/product-search', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getDisputeOrderItems = (filter = {}) => {
  return client.get('/order-items/dispute-orders', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getOrderItemsWithRelationship = (filter = {}) => {
  return client.get('/order-items/with-relationships', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getOrderItem = (orderId) => client.get(`/order-items/${orderId}`);

/**
 * Update order item
 *
 * @param {number} id item id
 * @param {object} data
 * @returns Promise
 */
export const updateOrderItem = (id, data) => client.put(`/order-items/${id}`, data);

/**
 * Show meta values of order item
 *
 * @param {number} id item id
 * @returns Promise
 */
export const getMetaValues = (id, filter = {}) => {
  return client.get(`/order-items/${id}/meta-values`, {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};


export const getWholeSaleMeta = (id) => {
  return client.get(`/order-items/${id}`);
}


/**
 * Add meta to order item
 *
 * @param {number} id item id
 * @param {object} data
 * @returns Promise
 */
export const addMetaValues = (id, data) => client.post(`/order-items/${id}/meta-values`, data);

export const getOrderItemIssues = (id) => {
  return client.get(`/issues?issuable_id=${id}`);
}

export const updateorderItemIssue = (id, data = {}) => {
  return client.put(`/issues/${id}`, data);
}

export const createOrderItemIssue = (data = {}) => {
  return client.post(`/issues`, data)
}

export const getProcessableOrder = (filter = {}) => {
  return client.get('/order-items/processable', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const takeActionIssue = (id, data = {}) => {
  return client.post(`/process-issue/${id}`, data);
}

export const sendToMoveOn = (data) => client.post(`/order-items/send-to-moveon`, data);

export const updateShippingCat = (id, data={}) => client.post(`/order-items/${id}/update-shipping`, data);

export const updateShippingRate = (id, data) => client.post(`/order-items/${id}/update-shipping-rate`, data)
