import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/profile';
import { getProfile } from "services/profile";

const getApi = async () => {
  return getProfile()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchProfileData(action) {
  try {
    const data = yield call(() => getApi());
    yield put({ type: types.GET_PROFILE_SUCCEEDED, data });
  } catch (e) {
    yield put({ type: types.GET_PROFILE_FAILED, message: e?.response });
  }
}

function* getProfileSaga() {
  yield all([
    takeEvery(types.GET_PROFILE_REQUESTED, fetchProfileData),
  ]);
}

export default getProfileSaga;
