import produce from "immer";
import * as actionTypes from 'app/actionTypes/products';

const initialState = {
  products: {
    data: null,
    meta: null,
    filters: null,
  },
  searchedProducts: {
    data: null,
    meta: null,
    filter: null,
    isFetched: false,
    loading: true,
    error: null,
  },
  inventoryProducts: {
    items: null,
    loading: true,
    isFetched: false,
    error: null,
  },
  isFetched: false,
  loading: true,
  error: null
}

export default function products(state = initialState, action) {
  const { type, payload } = action;
  return produce(state, draft => {
    switch (type) {
      case actionTypes.GET_PRODUCTS_REQUESTED:
        draft.loading = true;
        draft.isFetched = false;
        break
      case actionTypes.GET_PRODUCTS_SUCCEDED:
        draft.products = payload;
        draft.loading = false;
        draft.isFetched = true;
        break
      case actionTypes.GET_PRODUCTS_FAILED:
        draft.loading = false;
        draft.error = action.message
        break
      case actionTypes.GET_PRODUCT_REQUESTED:
        draft.loading = true;
        draft.isFetched = false;
        break
      case actionTypes.GET_PRODUCT_SUCCEDED:
        draft.product = payload.data;
        draft.loading = false;
        draft.isFetched = true;
        break
      case actionTypes.GET_PRODUCT_FAILED:
        draft.loading = false;
        draft.error = action.message
        break
      case actionTypes.UPDATE_PRODUCT_REQUESTED:
        draft.loading = true;
        break
      case actionTypes.UPDATE_PRODUCT_SUCCEEDED:
        const index = draft.products.data.findIndex(item => item.id === payload.id)
        if (index !== -1) draft.products.data[index] = { ...draft.products.data[index], ...payload.data }
        draft.loading = false;
        break
      case actionTypes.UPDATE_PRODUCT_FAILED:
        draft.loading = false;
        draft.error = action.message
        break
      case actionTypes.PRODUCTS_SEARCH_REQUESTED:
        draft.searchedProducts.loading = true;
        break;
      case actionTypes.PRODUCTS_SEARCH_SUCCEEDED:
        draft.searchedProducts.data = payload.data;
        draft.searchedProducts.meta = payload.meta;
        draft.searchedProducts.loading = false;
        draft.searchedProducts.isFetched = true;
        break;
      case actionTypes.PRODUCTS_SEARCH_FAILED:
        draft.searchedProducts.loading = false;
        draft.searchedProducts.error = payload;
        break;
      case actionTypes.REMOVE_PRODUCT:
        draft.products.data = draft.products.data?.filter(item => item.id !== payload);
        break;
      case actionTypes.INV_PRODUCTS_SEARCH_REQUESTED:
        draft.inventoryProducts.loading = true;
        break;
      case actionTypes.INV_PRODUCTS_SEARCH_SUCCEEDED:
        draft.inventoryProducts.items = payload.items;
        draft.inventoryProducts.loading = false;
        draft.inventoryProducts.isFetched = true;
        break;
      case actionTypes.INV_PRODUCTS_SEARCH_FAILED:
        draft.inventoryProducts.loading = false;
        draft.inventoryProducts.error = payload;
        break;
      default:
        return state
    }
  })
}
