import * as actionTypes from 'app/actionTypes/builder';
import produce from 'immer';

const initialState = {
    widgets: {
        data: null,
        loading: true,
        error: null,
    },
    list: {
        data: null,
        meta: null,
        loading: true,
        error: null,
    },
    builder: {
        data: null,
        loading: true,
        error: null,
    },
    loading: true,
    error: null
}

export default function builder(state = initialState, action) {
    const { type, payload } = action

    return produce(state, draft => {
        switch (type) {
            case actionTypes.GET_WIDGETS_REQUESTED:
                draft.widgets.loading = true;
                break;
            case actionTypes.GET_WIDGETS_SUCCEEDED:
                draft.widgets.data = payload.widgets;
                draft.widgets.loading = false;
                break;
            case actionTypes.GET_WIDGETS_FALIED:
                draft.widgets.loading = false;
                draft.widgets.error = payload;
                break;
            case actionTypes.GET_BUILDER_LIST_REQUESTED:
                draft.list.loading = true;
                break;
            case actionTypes.GET_BUILDER_LIST_SUCCEEDED:
                draft.list.data = payload.data;
                draft.list.loading = false;
                break;
            case actionTypes.GET_BUILDER_LIST_FAILED:
                draft.list.loading = false;
                break;
            case actionTypes.GET_BUILDER_REQUESTED:
                draft.builder.loading = true;
                break;
            case actionTypes.GET_BUILDER_SUCCEEDED:
                draft.builder.data = payload;
                draft.builder.loading = false;
                break;
            case actionTypes.GET_BUILDER_FAILED:
                draft.builder.loading = false;
                break;
            case actionTypes.UPDATE_BUILDER:
                break;
            default:
                return state;
        }
    })


}
