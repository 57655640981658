import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as type from 'app/actionTypes/shippingTags';
import { getShippingTags } from 'services/shipping-tags';

const getShippingTagsApi = async (filter) => {
    return getShippingTags(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchTags(action) {
    try {
        const data = yield call(() => getShippingTagsApi(action.payload));
        yield put({ type: type.GET_SHIPPING_TAGS_SUCCESS, payload: data });
    } catch (e) {
        yield put({ type: type.GET_SHIPPING_TAGS_FAILED, message: e?.response });
    }
}

function* shippingTagsSaga() {
    yield all([
        takeEvery(type.GET_SHIPPING_TAGS_REQUESTED, fetchTags),
    ]);
}

export default shippingTagsSaga;
