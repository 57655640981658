import * as types from 'app/actionTypes/liveCart';
import produce from "immer";

const initialState = {
  items: {
    items: null,
    meta: null
  },
  loading: false,
  error: null
}

export default function liveCart(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_LIVE_CART_REQUESTED:
        draft.loading = true
        break
      case types.GET_LIVE_CART_SUCCESS:
        draft.items.items = action.items.items
        draft.meta = null
        draft.loading = false
        break
      case types.GET_LIVE_CART_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case types.RESET_LIVE_CART:
        return initialState
      default:
        return state
    }
  })
}
