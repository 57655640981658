import * as actionTypes from 'app/actionTypes/countries';
import produce from 'immer';

const initialState = {
    countries: {
        data: null,
        meta: null,
        error: null,
        loading: true,
    },
    details: {
        data: null,
        loading: true,
        error: null,
    }
}

export default function countries(state = initialState, action) {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case actionTypes.GET_COUNTRIES_REQUESTED:
                draft.countries.loading = true;
                break;
            case actionTypes.GET_COUNTRIES_SUCCEEDED:
                draft.countries = {
                    ...draft.countries,
                    ...payload,
                    loading: false,
                };
                break;
          case actionTypes.GET_COUNTRIES_FAILED:
                draft.countries.loading = false;
                draft.countries.error = action.message
                break;

            case actionTypes.UPDATED_COUNTRY:
                const index = draft.countries.data.findIndex(item => item.id === payload.id)
                if (index !== -1) draft.countries.data[index] = { ...draft.countries.data[index], ...payload.data }
                break;

            case actionTypes.REMOVE_COUNTRY:
                draft.countries.data = draft.countries.data?.filter(item => item.id !== payload);
                break;

            default:
                return state;
        }
    })
}
