import produce from "immer";
import * as Types from 'app/actionTypes/files';

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null
}

export default function files(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_FILES_REQUESTED:
        draft.loading = true
        break
      case Types.GET_FILES_SUCCESS:
        draft.items = action.data
        draft.loading = false
        break
      case Types.GET_FILES_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.UPDATE_FILE:
        const index = draft.items.data.findIndex(item => item.uuid === action.payload.uuid)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data }
        break
      case Types.DELETE_FILE:
        const itemIndex = draft.items.data.findIndex(item => item.uuid === action.payload.uuid)
        draft.items.data.splice(itemIndex, 1)
        break
      default:
        return state
    }
  })
}
