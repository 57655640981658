import * as actionTypes from 'app/actionTypes/currencies';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getCurrencies } from 'services/currencies';

const getCurrenciesApi = async (filter = {}) => {
    try {
        const res = await getCurrencies(filter);
        return res.data;
    } catch ({ response }) {
        return response;
    }
}

function* fetchCurrencies(action) {
    const { payload } = action;
    try {
        const data = yield call(() => getCurrenciesApi(payload))
        yield put({ type: actionTypes.GET_CURRENCIES_SUCCEEDED, payload: data })
    } catch (e) {
        yield put({ type: actionTypes.GET_CURRENCIES_FAILED, message: e?.response })
    }
}

function* currenciesSaga() {
    yield all([
        takeEvery(actionTypes.GET_CURRENCIES_REQUESTED, fetchCurrencies),
    ])
}

export default currenciesSaga;
