import * as actionTypes from 'app/actionTypes/currencies';
import produce from 'immer';

const initialState = {
    currencies: {
        data: null,
        meta: null,
        error: null,
        loading: true,
    },
    details: {
        data: null,
        loading: true,
        error: null,
    }
}

export default function currencies(state = initialState, action) {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case actionTypes.GET_CURRENCIES_REQUESTED:
                draft.currencies.loading = true;
                break;
            case actionTypes.GET_CURRENCIES_SUCCEEDED:
                draft.currencies = {
                    ...draft.currencies,
                    ...payload,
                    loading: false,
                };
                break;
          case actionTypes.GET_CURRENCIES_FAILED:
                draft.currencies.loading = false;
                draft.currencies.error = action.message
                break;

            case actionTypes.UPDATED_CURRENCY:
                const index = draft.currencies.data.findIndex(item => item.id === payload.id)
                if (index !== -1) draft.currencies.data[index] = { ...draft.currencies.data[index], ...payload.data }
                break;

            case actionTypes.REMOVE_CURRENCY:
                draft.currencies.data = draft.currencies.data?.filter(item => item.id !== payload);
                break;

            default:
                return state;
        }
    })
}
