import produce from "immer";
import * as Types from 'app/actionTypes/requests';

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  loading: false,
  error: null
}

export default function requests(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_REQUESTS_REQUESTED:
        draft.loading = true
        break
      case Types.GET_REQUESTS_SUCCESS:
        draft.items = action.data
        draft.loading = false
        break
      case Types.GET_REQUESTS_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      case Types.RESET_REQUEST:
        draft.items = {
          data: null,
          meta: null,
          filters: null,
        }
        draft.loading = false
        break
      case Types.UPDATE_REQUEST:
        const index = draft.items.data.findIndex(trnx => trnx.id === action.payload.id)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data }
        break
      default:
        return state
    }
  })
}
