import client from './api-client';
import { LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER } from 'configs/storage';

const loginAdmin = (data) => {
  return client.post('auth/login', data);
};

const getToken = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY);
};

const loggedIn = () => {
  const token = getToken();
  return !!token;
};

const logout = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(LOCAL_STORAGE_USER);
  return client.get('auth/logout');
};

const getProfileInfo = (token) => {
  return client.get('/account/me', {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
  });
};

const updateProfile = (data) => {
  return client.post(`/account/me`, data);
};

export { loginAdmin, loggedIn, logout, getProfileInfo, updateProfile };
