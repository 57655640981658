import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getConfigurations } from 'services/configurations';
import * as type from 'app/actionTypes/configurations';

const getApi = async (filter) => {
    return getConfigurations(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchConfigurations(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_CONFIGURATIONS_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: type.GET_CONFIGURATIONS_FAILED, message: e?.response });
    }
}

function* configurationsSaga() {
    yield all([
        takeEvery(type.GET_CONFIGURATIONS_REQUESTED, fetchConfigurations),
    ]);
}

export default configurationsSaga;
