import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getOrderItems } from 'services/order-items';
import * as types from 'app/actionTypes/purchaseProducts';

const getApi = async (filter) => {
    return getOrderItems(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchPurchaseProducts(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_PURCHASE_PRODUCTS_SUCCESS, products: data });
    } catch (e) {
        yield put({ type: types.GET_PURCHASE_PRODUCTS_FAILED, message: e?.response });
    }
}

function* purchaseProductsSaga() {
    yield all([
        takeEvery(types.GET_PURCHASE_PRODUCTS_REQUESTED, fetchPurchaseProducts),
    ]);
}

export default purchaseProductsSaga;
