import client from 'utils/api-client';
import Qs from 'qs';

export const getAllParcels = (filter) => {
  return client.get('/parcels', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getAllParcelsReport = (filter) => {
  return client.get('/parcels', {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      // 'Accept': 'application/pdf'
    },
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getParcelsInfo = (parcel_id) => {
  return client.get(`/parcels/${parcel_id}`);
};

export const addParcelsInfo = (data) => {
  return client.post(`/parcels`, data);
};

export const updateParcelsInfo = (parcel_id, data ) => {
  return client.put(`/parcels/${parcel_id}`, data);
};

export const addOrderItemCharges = (item_id, data ) => {
  return client.put(`/order-items/${item_id}`, data);
};

export const deleteOrderItemCharges = (item_id) => {
  return client.delete(`/order-charges/${item_id}`);
};

export const deleteParcel = (parcel_id) => {
  return client.delete(`/parcels/${parcel_id}`);
};

export const getAllParcelStatus = () => {
  return client.get('parcels/parcel-statuses');
};

export const updateParcelTracking = (parcel_id, data) => {
 return client.post(`parcels/${parcel_id}/add-tracking`, data);
};

export const addOrderCharges = (data) => {
  return client.post(`/order-charges`, data);
};

export const postAutoDelivery = () => {
  return client.post(`parcels/auto-delivery`);
};

export const getAllCharges = (filter) => {
  return client.get('/order-charges', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const printLabel = (data) => {
  return client.post(`/parcels/label`, data, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
  });
};

export const addParcelsBatchProcess = (data) => {
  return client.post(`/parcels/batch-process`, data);
};

export const getMultiTracking = (filter) => {
  return client.get('/order-item-tracking', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const addMultiTracking = (data) => {
  return client.post(`/order-item-tracking`, data);
};

export const updateMultiTracking = (id,data) => {
  return client.put(`/order-item-tracking/${id}`, data);
};

export const sendToCourier = (parcel_id, data) => client.post(`/parcels/${parcel_id}/send-to-courier`, data);

/**
 * Cancel courier order
 *
 * @param {int} parcel_id parcel id
 * @param {object} data data to be sent, like {reason: 'some reason'}
 * @returns Promise
 */
export const cancelCourierOrder = (parcel_id, data) => client.post(`/parcels/${parcel_id}/cancel-order`, data);

/**
 * Get courier tracking
 *
 * @param {*} parcel_id parcel id
 * @returns
 */
export const getCourierTracking = (parcel_id) => client.get(`/parcels/get-track/${parcel_id}`);
