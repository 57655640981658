const productSkuParseFromUrl = (url) => {
  if (url) {
    let breakUrl = new URL(url)
    if (breakUrl?.origin?.indexOf("aliexpress.com") !== -1) {
      return url?.split("?")[0].substring(url.lastIndexOf("/") + 1).replace(".html", "")
    }else if (breakUrl?.origin?.indexOf("taobao.com") !== -1) {
      return url?.split("?")[1].replace("id=", "")
    }
  }
  return null
}

export {productSkuParseFromUrl};
