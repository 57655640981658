import client from 'utils/api-client';
import Qs from 'qs';

export const getCartItems = (filter = {}) => {
  return client.get('/cart-items', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const updateCartItem =(id, data) => client.put(`/cart-items/${id}`, data);

export const getCartItem = (id) => client.get(`/cart-items/${id}`);
