import { call, put, takeEvery, all } from "redux-saga/effects";
import * as type from '../actionTypes/campaigns';
import { getCampaigns } from "services/campaigns";

const getCampaignsApi = async (filter) => {
    return getCampaigns(filter)
    .then(res => res.data)
    .catch(err => {throw err})
}

function* fetchCampaignsRequest(action) {
    try {
        const data = yield call(() => getCampaignsApi(action.payload))
        yield put({type: type.GET_CAMPAIGNS_SUCCEEDED, payload: data})
    }
    catch(e) {
        yield put({type: type.GET_CAMPAIGNS_FAILED, message: e?.response})
    }
}

function* campaignsSaga() {
    yield all([
        takeEvery(type.GET_CAMPAIGNS_REQUESTED, fetchCampaignsRequest),
    ])
}

export default campaignsSaga;
