import { call, put, takeEvery, all } from "redux-saga/effects";
import * as type from '../actionTypes/campaignProducts';
import { getCampaignProducts } from "services/campaigns";

const fetchCampaignProductsApi = async (id, filters) => {
    return getCampaignProducts(id, filters)
    .then(res => res.data)
    .catch(err => {throw err})
}

function* fetchCampaignProducts(action) {
    const { payload } = action;
    try {
        const data = yield call(() => fetchCampaignProductsApi(payload.id, payload.filters))
        yield put({type: type.CAMPAIGN_PRODUCTS_SUCCEEDED, payload: data})
    }
    catch(e) {
        yield put({type: type.CAMPAIGN_PRODUCTS_FAILED, message: e?.response})
    }
}

function* campaignProductsSaga() {
    yield all([
        takeEvery(type.CAMPAIGN_PRODUCTS_REQUESTED, fetchCampaignProducts),
    ])
}

export default campaignProductsSaga;
