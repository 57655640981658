import * as actionTypes from 'app/actionTypes/campaigns';
import produce from 'immer';

const initialState = {
    campaigns: {
        data: null,
        meta: null,
    },
    loading: true,
    error: null
}

export default function campaigns(state = initialState, action) {
    const { type, payload } = action

    return produce(state, draft => {
        switch (type) {
            case actionTypes.GET_CAMPAIGNS_REQUESTED:
                draft.loading = true;
                break;
            case actionTypes.GET_CAMPAIGNS_SUCCEEDED:
                draft.campaigns = payload;
                draft.loading = false;
                break;
            case actionTypes.GET_CAMPAIGNS_FAILED:
                draft.loading = false;
                draft.error = action.message
                break;
            case actionTypes.UPDATE_CAMPAIGNS:
                const index = draft.campaigns.data.findIndex(item => item.id === payload.id)
                if (index !== -1) draft.campaigns.data[index] = { ...draft.campaigns.data[index], ...payload.data }
                break;
            default:
                return state;
        }
    })


}
