import client from 'utils/api-client';
import Qs from 'qs';
import axios from 'axios';

export const getQuickResult = (query = {}) => {
    return client.get(`/support/find/?query=${query}`)
}

export const customers = (filter = {}, customer,resource='orders') => {
    return client.get(`/support/customer/${resource}?id=${customer}`, {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    })
}

export const orders = (order) => {
    return client.get(`/support/order/?id=${order}`)
}

export const transactions = (key) => {
    return client.get(`/support/transaction?key=${encodeURIComponent(key)}`)
}

export const storeTransaction = (data) => {
    return client.post(`/support/transaction`, data);
}

export const supportCODOrder = (filter = {}) => {
  return client.get('/support/review-orders', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const orderParcel = (order) => {
  return client.get(`/support/parcels/order?id=${order}`);
}


export const refreshFindByURL = (URL) => {
  return client({url: `/products/find-by-url?url=${URL}&refresh=yes`, baseURL: `${process.env.REACT_APP_API_URL}/public/v1`})
}
export const refreshFindByID = (ID) => {
  return client({url: `/products/find-by-id?id=${ID}&refresh=yes`, baseURL: `${process.env.REACT_APP_API_URL}/public/v1`})
}

export const refreshProductFindBy = (filter = {}, findByTpye="url") => {
  return axios.get(`${process.env.REACT_APP_API_URL}/public/v1/products/find-by-${findByTpye}`, {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}
