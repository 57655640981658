import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getCustomerDetails } from 'app/actions/customerDetails';
import {Avatar, Button, Card, Col, Menu, Modal, PageHeader, Row, Space, Tooltip, Typography} from 'antd';
import {AccountBookOutlined, EditOutlined, SettingOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import StatusTag from "components/StatusTag";
import ProDefaultLayout from 'layouts/ProDefaultLayout';
import PanelAccess from "pages/customers/components/PanelAccess";

const { Text } = Typography;

const CustomerLayout = ({ children }: any) => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state: any) => state.customerDetails);
  const [selectedMenu, setSelectedMenu] = useState('');
  let { url } = useRouteMatch();

  const [selectedCustomer, setSelectedCustomer] = useState<{} | null>(null);
  const [showPanelAccess, setShowPanelAccess] = useState(false);

  useEffect(() => {
    if (url) {
      if (url.includes('cart')) {
        setSelectedMenu('cart')
      } else if (url.includes('transactions')) {
        setSelectedMenu('transactions');
      } else if (url.includes('requested')) {
        setSelectedMenu('requested');
      } else {
        setSelectedMenu('orders')
      }
    }
  }, [url])

  useEffect(() => {
    dispatch(getCustomerDetails(id));
  }, [id, dispatch]);

  const handleSelectedMenu = (e:any) => {
    setSelectedMenu(e.key);
  };

  return (
    <ProDefaultLayout>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title=""
        subTitle=""
        ghost={false}
        extra={<Space>
          <Space>
            <Tooltip title="Access dashboard">
              <Button
                danger
                type="primary"
                icon={<AccountBookOutlined />}
                size="small"
                onClick={() => {
                  setSelectedCustomer(data);
                  setShowPanelAccess(true);
                }}
              >Go to Customer Panel</Button>
            </Tooltip>
          </Space>
        </Space>}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={6}>
            <Card
              style={{ width: 300 }}
              cover={
                <img
                  alt="example"
                  src="/assets/logo512.png"
                />
              }
              actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
              ]}
            >
              <Meta
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title={<span>{data?.first_name} {data?.last_name}</span>}
                description={<Space direction="vertical">
                  <span><Text copyable={data?.mobile}>{data?.mobile}</Text></span>
                  <span><Text copyable={data?.email}>{data?.email}</Text></span>
                </Space>}
              />
              <Space style={{ marginTop: '10%' }} wrap>
                <span>Status: {data?.status && <StatusTag text={data.status} slug={data.status} />}</span>
                <span>Country: {data?.country?.name}</span>
                <span>Currency: {data?.currency?.name}</span>
                {
                  data?.dob &&
                  <span>Date of birth: {data?.dob}</span>
                }
                {
                  data?.username &&
                  <span>Username: {data?.username}</span>
                }
                {
                  data?.gender &&
                  <span>Gender: {data?.gender}</span>
                }
              </Space>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={18}>
            <Menu
              onClick={handleSelectedMenu}
              selectedKeys={[selectedMenu]}
              mode="horizontal"
              style={{marginBottom: '10px'}}
            >
              <Menu.Item key="orders">
                <Link to={`/customers/${id}`}>
                  Orders
                </Link>
              </Menu.Item>
              <Menu.Item key="cart">
                <Link to={`/customers/${id}/cart`}>
                  Cart
                </Link>
              </Menu.Item>
              <Menu.Item key="requested">
                <Link to={`/customers/${id}/requested`}>
                  Requested Items
                </Link>
              </Menu.Item>
              <Menu.Item key="transactions">
                <Link to={`/customers/${id}/transactions`}>
                  Transactions
                </Link>
              </Menu.Item>
            </Menu>
            <>
              {children}
            </>
          </Col>
        </Row>
      </PageHeader>

      <Modal
        visible={showPanelAccess}
        onCancel={() => setShowPanelAccess(false)}
        footer={null}
        destroyOnClose={true}
      >
        <PanelAccess selectedCustomer={selectedCustomer} setShowPanelAccess={setShowPanelAccess} />
      </Modal>
    </ProDefaultLayout>

  );
};

export default CustomerLayout;
