import produce from "immer";
import * as Types from 'app/actionTypes/shippingCategories';
import { nanoid } from "nanoid";

const initialState = {
  items: {
    data: null,
    meta: null,
    filters: null,
  },
  children: {
    data: null,
    loading: false,
    error: null,
  },
  freightCats: {
    data: null,
    meta: null,
    filters: null,
    error: null,
    loading: true,
  },
  loading: false,
  error: null,
  mvnCategories: {
    data: null,
    loading: true,
    error: null,
  },
  shippingRates: {
    data: null,
    taggedIDs: [],
    loading: true,
    error: null,
  },
  taggedCategories: {
    data: null,
    loading: true,
    error: null,
  },
  adminCat: {
    data: null,
    meta: null,
    filters: null,
  },
  adminCatLoading: false,
  adminCatError: null,
  latestAdminCat: {
    data: null,
    status: null,
  },
  latestAdminCatLoading: false,
  latestAdminCatError: null,
}

function updateTreeData(list, id, children) {
  return list.map((node) => {
    if (node.id === id) {
      return { ...node, children };
    }
    if (node.children) {
      return { ...node, children: updateTreeData(node.children, id, children) };
    }
    return node;
  });
}

function removeShipingCat(list, id) {
  return list.reduce((acc, current) => {
    if (current.id !== id) {
      acc.push(current);
    }
    if (current.children) {
      current['children'] = removeShipingCat(current.children, id)
    }
    return acc;
  }, [])
}

function updateShipingCat(list, payload) {
  return list.map(cat => {
    if (cat.id === payload.id) {
      cat = {
        ...cat,
        ...payload.data,
        title: payload.data.name,
      }
    }
    if (cat.children) {
      cat['children'] = updateShipingCat(cat.children, payload)
    }
    return cat;
  }, [])
}

function flattenFreightCats(list, parentItem = null) {
  let flattenItems = [];
  for (const cat of list) {
    cat['value'] = cat.id;
    cat['name'] = parentItem ? `${parentItem.name} > ${cat.name}` : cat.name;
    cat['label'] = parentItem ? `${parentItem.name} > ${cat.name}` : cat.name;
    if (cat.children) {
      let children = flattenFreightCats(cat.children, cat);
      if (children) {
        flattenItems = flattenItems.concat(children);
      }
    }
    flattenItems.push(cat)
  }
  return flattenItems;
}

export default function shippingCategories(state = initialState, action) {
  const { payload } = action;
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_SHIPPING_CATEGORIES_REQUESTED:
        draft.loading = true
        break
      case Types.GET_SHIPPING_CATEGORIES_SUCCESS:
        let formattedPayload = {
          ...payload,
          data: payload.data?.map((cat, idx) => {
            cat['key'] = `${cat.id}-${cat.parent_id}:${nanoid(6)}`;
            cat['title'] = cat.name;
            cat['hasChildren'] = false;
            return cat;
          })
        }
        draft.items = formattedPayload
        draft.loading = false
        break
      case Types.GET_SHIPPING_CATEGORIES_FAILED:
        draft.loading = false
        draft.error = action.error
        break
      case Types.SET_SHIPPING_CATEGORIES_DATA:
        draft.items.data = payload
        break
      case Types.SHIPPING_CHILDREN_REQUESTED:
        draft.children.loading = true;
        break;
      case Types.SHIPPING_CHILDREN_SUCCEEDED:
        let formattedChildren = {
          ...payload,
          data: payload.data?.data?.map((cat, idx) => {
            cat['key'] = `${cat.id}-${cat.parent_id}:${nanoid(6)}`;
            cat['title'] = cat.name;
            cat['hasChildren'] = true;
            cat['children'] = [];
            return cat;
          })
        }
        draft.children.data = formattedChildren;
        draft.items.data = updateTreeData(draft.items.data, formattedChildren.id, formattedChildren.data);
        formattedChildren.callback();
        draft.children.loading = false;
        break;
      case Types.SHIPPING_CHILDREN_FAILED:
        draft.children.loading = false;
        break;
      case Types.UPDATE_SHIPPING_CATEGORY:
        draft.items.data = updateShipingCat(draft.items.data, payload);
        break;
      case Types.REMOVE_SHIPPING_CAT:
        draft.items.data = removeShipingCat(draft.items.data, payload);
        break;
      case Types.GET_MVN_CATEGORIES_REQUESTED:
        draft.mvnCategories.loading = true;
        break;
      case Types.GET_MVN_CATEGORIES_SUCCEEDED:
        function formattedMvnCategories(list) {
          return list.map(item => {
            item['key'] = `${item.id}-${item.parent_id}:${nanoid(6)}`;
            item['title'] = item.name;

            if (item.childs_recursive) {
              item['children'] = formattedMvnCategories(item.childs_recursive);
            }
            return item;
          })
        }
        let formattedMvnCats = formattedMvnCategories(payload.data);
        draft.mvnCategories.data = formattedMvnCats;
        draft.mvnCategories.loading = false;
        break;
      case Types.GET_MVN_CATEGORIES_FAILED:
        draft.mvnCategories.loading = false;
        break;
      case Types.GET_MVN_SHIPPING_RATES_REQUESTED:
        draft.shippingRates.loading = true;
        break;
      case Types.GET_MVN_SHIPPING_RATES_SUCCEEDED:
        draft.shippingRates.data = payload.data.map(item => {
          item['key'] = `${item.category_id}:${nanoid(6)}`;
          item['title'] = item.category_name;
          return item;
        });
        draft.shippingRates.loading = false;
        break;
      case Types.GET_MVN_SHIPPING_RATES_FAILED:
        draft.shippingRates.loading = false;
        break;
      case Types.SHIPPING_TAGGED_CATS_REQUESTED:
        draft.taggedCategories.loading = true;
        break;
      case Types.SHIPPING_TAGGED_CATS_SUCCEEDED:
        draft.taggedCategories.data = {
          ...payload,
          data: payload.data.map(item => {
            item.key = item.id;
            return item;
          })
        };
        
        draft.taggedCategories.taggedIDs = payload.data.map(item => item.foreign_id);
        draft.taggedCategories.loading = false;
        break;
      case Types.SHIPPING_TAGGED_CATS_FAILED:
        draft.taggedCategories.loading = false;
        break;
      case Types.GET_FREIGHT_CATEGORIES_REQUESTED:
        draft.freightCats.loading = true
        break
      case Types.GET_FREIGHT_CATEGORIES_SUCCESS:
        let flattenCats = flattenFreightCats(payload.data)
        draft.freightCats.data = flattenCats;
        draft.freightCats.loading = false
        break
      case Types.GET_FREIGHT_CATEGORIES_FAILED:
        draft.freightCats.loading = false
        draft.freightCats.error = action.error
        break
      case Types.GET_ADMIN_FREIGHT_CATEGORIES_REQUESTED:
        draft.adminCatLoading = true
        break
      case Types.GET_ADMIN_FREIGHT_CATEGORIES_SUCCESS:
        draft.adminCat = payload;
        draft.adminCatLoading = false
        break
      case Types.GET_ADMIN_FREIGHT_CATEGORIES_FAILED:
        draft.adminCatLoading = false
        draft.adminCatError = action.message
        break
      case Types.GET_LATEST_SHIPPING_CATEGORIES_REQUESTED:
        draft.latestAdminCatLoading = true
        draft.latestAdminCatError = null;
        break
      case Types.GET_LATEST_SHIPPING_CATEGORIES_SUCCESS:
        draft.latestAdminCat = payload;
        draft.latestAdminCatLoading = false
        break
      case Types.GET_LATEST_SHIPPING_CATEGORIES_FAILED:
        draft.latestAdminCatLoading = false
        draft.latestAdminCatError = action.error
        break
      case Types.RESET_LATEST_SHIPPING:
        draft.latestAdminCat = {
          data: null,
          status: null,
        }
        draft.latestAdminCatLoading = false
        break
      default:
        return state
    }
  })
  
}
