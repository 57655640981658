import * as actionTypes from 'app/actionTypes/contents';
import produce from 'immer';

const initialState = {
    contents: {
        data: null,
        meta: null,
        error: null,
        loading: true,
    },
    details: {
        data: null,
        loading: true,
        error: null,
    },
}

export default function contents(state = initialState, action) {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case actionTypes.CONTENTS_REQUESTED:
                draft.contents.loading = true;
                break;
            case actionTypes.CONTENTS_SUCCEEDED:
                draft.contents = {
                    ...draft.contents,
                    ...payload,
                    loading: false,
                };
                break;
          case actionTypes.CONTENTS_FAILED:
                draft.contents.loading = false;
                draft.contents.error = action.message
                break;

            case actionTypes.CONTENT_REQUESTED:
                draft.details.loading = true;
                break;
            case actionTypes.CONTENT_SUCCEEDED:
                draft.details = {
                    ...draft.details,
                    ...payload,
                    loading: false,
                };
                break;
          case actionTypes.CONTENT_FAILED:
                draft.details.loading = false;
                draft.details.error = action.message
                break;

            case actionTypes.UPDATE_CONTENT:
                const index = draft.contents.data.findIndex(item => item.id === payload.id)
                if (index !== -1) draft.contents.data[index] = { ...draft.contents.data[index], ...payload.data }
                break;

            case actionTypes.REMOVE_CONTENT:
                draft.contents.data = draft.contents.data?.filter(item => item.id !== payload);
                break;

            default:
                return state;
        }
    })
}
