import React  from 'react';
import {Card, Col, Row, Statistic} from "antd";
import {ArrowUpOutlined, ArrowDownOutlined, LikeOutlined} from "@ant-design/icons";

const { Countdown } = Statistic;

const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

function onFinish() {
  console.log('finished!');
}

const onChange = (val: any) => {
  if (4.95 * 1000 < val && val < 5 * 1000) {
    console.log('changed!');
  }
}

const Homepage = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Statistic
              title="Active"
              value={11.28}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Statistic
              title="Idle"
              value={9.3}
              precision={2}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Statistic title="Feedback" value={1128} prefix={<LikeOutlined />} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Statistic title="Unmerged" value={93} suffix="/ 100" />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Countdown title="Countdown" value={deadline} onFinish={onFinish} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Countdown title="Million Seconds" value={deadline} format="HH:mm:ss:SSS" />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Countdown title="Day Level" value={deadline} format="D 天 H 时 m 分 s 秒" />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <Card>
            <Countdown title="Countdown" value={Date.now() + 10 * 1000} onChange={onChange} />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Homepage;
