import {
  UserOutlined,
  SwitcherOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  GoldOutlined,
  ShoppingOutlined,
  ContactsOutlined,
  CustomerServiceOutlined,
  UsergroupAddOutlined,
  AccountBookOutlined,
  SolutionOutlined,
  ShopOutlined,
  ProfileOutlined,
  MedicineBoxOutlined,
  NodeExpandOutlined
} from '@ant-design/icons'
import routePaths from './route-paths'

const menuItems = [
  {
    title: 'Customers',
    icon: <UserSwitchOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    subMenus: [
      {
        path: '/customers',
        title: 'Customers',
        permission: 'view_any_customer',
        requirePermission: true,
        icon: null,
      }
    ]
  },
  {
    title: 'Payment',
    icon: <SwitcherOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    subMenus: [
      {
        path: '/transactions',
        title: 'Transactions',
        permission: 'view_any_transaction',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/gateways',
        title: 'Gateways',
        permission: 'view_any_gateway',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/refunds',
        title: 'Refunds',
        permission: 'view_any_item_refund',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/wallet-transactions',
        title: 'Wallet',
        permission: 'view_any_wallet',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/withdraw-transactions',
        title: 'Withdrawal',
        permission: 'view_any_withdrawal',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <UserOutlined />,
    title: 'Cart',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {
        path: '/cart-items',
        title: 'Cart items',
        permission: 'view_any_cart_item',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <SolutionOutlined />,
    title: 'Requested Products',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {
        path: '/requests',
        title: 'Requested Products',
        permission: 'view_any_product_request',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <GoldOutlined />,
    title: 'Orders',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {
        path: routePaths.orders.list,
        title: 'Orders',
        permission: 'view_any_order',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/bulk',
        title: 'Products (Bulk)',
        permission: 'view_any_order',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/dispute-orders',
        title: 'Dispute Orders',
        permission: 'view_any_order',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/issues',
        title: 'Issues',
        permission: 'view_any_issue',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/charges',
        title: 'Charges',
        permission: 'view_any_charges',
        requirePermission: true,
        icon: null,
      },
      {
        path: routePaths.orders.create,
        title: 'Create Order',
        permission: 'create_order',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <AccountBookOutlined />,
    title: 'Purchase Panel',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/import-orders',
        title: 'Import Orders',
        permission: 'import_orders',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/purchased-orders',
        title: 'Purchased Orders',
        permission: 'view_any_item_purchase',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <ShopOutlined />,
    title: 'Shop',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {
        path: '/shop-accounts',
        title: 'Accounts',
        permission: 'view_any_shop_account',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/shops',
        title: 'Shops',
        requirePermission: true,
        permission: 'view_any_shop',
        icon: null,
      },
      {
        path: '/currency-rates',
        title: 'Currencies',
        permission: 'view_any_currency',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <ShoppingOutlined />,
    title: 'Logistics',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {
        path: '/parcels',
        title: 'Parcels',
        requirePermission: true,
        permission: 'view_any_parcel',
        icon: null,
      },
      {
        path: '/parcels/parcel-updater',
        title: 'Paid Parcels Updater',
        permission: 'update_parcel',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/cod/parcels/parcel-updater',
        title: 'COD Product Updater',
        permission: 'update_parcel',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/product/receiver',
        title: 'Product Receiver',
        permission: 'update_parcel',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/couriers',
        title: 'Couriers',
        permission: 'view_any_courier',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/shipping-categories',
        title: 'Shipping categories',
        permission: 'view_any_shipping_category',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/shipping-tags',
        title: 'Shipping Tags',
        permission: 'view_any_shipping_category_tag',
        requirePermission: true,
        icon: null,
      }
    ]
  },
  {
    icon: <ContactsOutlined />,
    title: 'Products',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/products',
        title: 'Products',
        permission: 'view_any_product',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/tags',
        title: 'Tags',
        permission: 'view_any_product',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/products/edit',
        title: 'Create Product',
        permission: 'view_any_product',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <ProfileOutlined />,
    title: 'Campaigns',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/campaigns',
        title: 'Campaigns',
        permission: 'view_any_campaign',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {

    icon: <CustomerServiceOutlined />,
    title: 'Support',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {

        path: '/support/find',
        title: 'Search',
        permission: 'view_any_support_information',
        requirePermission: true,
        icon: null,
      },
      {

        path: '/cod-orders',
        title: 'COD Orders',
        permission: 'view_any_support_information',
        requirePermission: true,
        icon: null,
      },
      {

        path: '/support/refresh-product',
        title: 'Refresh Product',
        permission: 'view_any_support_information',
        requirePermission: true,
        icon: null,
      },
      {
        path: routePaths.orders.create,
        title: 'Create Order',
        permission: 'create_order',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <SettingOutlined />,
    title: 'Config',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/configurations',
        title: 'Configurations',
        requirePermission: true,
        permission: 'view_any_configuration',
        icon: null,
      },
      {
        path: '/menus',
        title: 'Menus',
        permission: 'view_any_menu',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/files',
        title: 'Files',
        permission: 'view_any_file',
        requirePermission: true,
        icon: null,
      }
    ]
  },
  {
    icon: <MedicineBoxOutlined />,
    title: 'Roles & Permissions',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/roles-permissions',
        title: 'Roles & Permissions',
        permission: 'view_any_permission',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/roles',
        title: 'Roles',
        permission: 'view_any_permission',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <UsergroupAddOutlined />,
    title: 'Users',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/users',
        title: 'Users',
        permission: 'view_any_user',
        requirePermission: true,
        icon: null,
      },
      {
        path: '/moveon-account',
        title: 'MoveOn Account',
        permission: 'view_any_user',
        requirePermission: true,
        icon: null,
      },
    ]
  },
  {
    icon: <NodeExpandOutlined />,
    title: 'Contents',
    requirePermission: true,
    hasSubMenu: true,
    subMenus: [
      {
        path: '/contents',
        title: 'Contents',
        requirePermission: true,
        permission: 'view_any_content',
        icon: null,
      },
    ]
  },
  {
      icon: <UsergroupAddOutlined />,
      title: 'Homepage Editor',
      requirePermission: false,
      hasSubMenu: true,
      subMenus: [
          {
              path: '/builders',
              title: 'Settings',
              requirePermission: true,
              permission: 'view_any_content',
              icon: null,
          },
      ]
  },
  {
    icon: <UserOutlined />,
    title: 'Profile',
    hasSubMenu: true,
    subMenus: [
      {
        path: '/profile',
        title: 'Profile',
        permission: 'view_any_user',
        requirePermission: false,
        icon: null,
      },
    ]
  },
]

export default menuItems
