import {all, call, put, takeEvery} from "redux-saga/effects"
import {getOrder, getShippingDetails} from "services/order";
import * as type from 'app/actionTypes/order';
import {getAllCharges, getMultiTracking} from "services/parcels-client";

const getApi = async (id) => {
    return getOrder(id)
    .then(res => res)
    .catch((error) => { throw error })
}

const getChargesApi = async (filter) => {
  return getAllCharges(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getShippingDetailsApi = async (id) => {
  return getShippingDetails(id)
    .then(res => res)
    .catch((error) => { throw error })
}

const getMultiTrackingApi = async (filter) => {
  return getMultiTracking(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchOrder(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_ORDER_SUCCESS, order: data });
    } catch (e) {
        yield put({ type: type.GET_ORDER_FAIL, message: e?.response });
    }
}

function* fetchCharges(action) {
  try {
    const data = yield call(() => getChargesApi(action.payload));
    yield put({ type: type.GET_CHARGES_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: type.GET_CHARGES_FAIL, message: e?.response });
  }
}

function* fetchShippingDetails(action) {
  try {
    const data = yield call(() => getShippingDetailsApi(action.payload));
    yield put({ type: type.GET_SHIPPING_DETAILS_SUCCESS, shipping: data });
  } catch (e) {
    yield put({ type: type.GET_SHIPPING_DETAILS_FAIL, message: e?.response });
  }
}

function* fetchMultiTracking(action) {
  try {
    const data = yield call(() => getMultiTrackingApi(action.payload));
    yield put({ type: type.GET_MULTI_TRACKING_SUCCESS, tracking: data });
  } catch (e) {
    yield put({ type: type.GET_MULTI_TRACKING_FAIL, message: e?.response });
  }
}

function* orderSaga() {
  yield all([
    yield takeEvery(type.GET_ORDER_REQUESTED, fetchOrder),
    yield takeEvery(type.GET_CHARGES_REQUESTED, fetchCharges),
    yield takeEvery(type.GET_SHIPPING_DETAILS_REQUESTED, fetchShippingDetails),
    yield takeEvery(type.GET_MULTI_TRACKING_REQUESTED, fetchMultiTracking),
  ]);
}

export default orderSaga;
