import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/purchasedOrders'
import {getPurchasedOrder, getPurchasedOrderDetails} from "services/live-cart";

const getPurchasedOrderApi = async (filter) => {
  return getPurchasedOrder(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getPurchasedOrderDetailsApi = async (id) => {
  return getPurchasedOrderDetails(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchPurchasedOrder(action) {
  try {
    const data = yield call(() => getPurchasedOrderApi(action.payload));
    yield put({ type: types.GET_PURCHASED_ORDER_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_PURCHASED_ORDER_FAILED, message: e?.response });
  }
}

function* fetchPurchasedOrderDetails(action) {
  try {
    const data = yield call(() => getPurchasedOrderDetailsApi(action.payload));
    yield put({ type: types.GET_PURCHASED_ORDER_DETAILS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GTE_PURCHASED_ORDER_DETAILS_FAILED, message: e?.response });
  }
}

function* purchasedOrdersSaga() {
  yield all([
    takeEvery(types.GET_PURCHASED_ORDER_REQUESTED, fetchPurchasedOrder),
    takeEvery(types.GET_PURCHASED_ORDER_DETAILS_REQUESTED, fetchPurchasedOrderDetails),
  ]);
}

export default purchasedOrdersSaga;
