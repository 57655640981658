import * as actionTypes from 'app/actionTypes/issues';
import produce from 'immer';

const initialState = {
    issues: {
        data: null,
        meta: null,
    },
    loading: true,
    error: null
}

export default function issues(state = initialState, action) {
    const { type, payload } = action;

    return produce(state, draft => {
        switch(type) {
            case actionTypes.GET_ISSUES_REQUESTED:
                draft.loading = true;
                break;
            case actionTypes.GET_ISSUES_SUCCEEDED:
                draft.issues = payload;
                draft.loading = false;
                break;
            case actionTypes.GET_ISSUES_FAILED:
                draft.loading = false;
                draft.error = action.message
                break;
            case actionTypes.UPDATE_ISSUE:
                draft.issues.data = draft.issues.data?.map(issue => {
                    if (issue.id === payload.id) {
                        issue = {
                            ...issue,
                            ...payload.data,
                        }
                    }
                    return issue;
                })
                break;
            default:
                return state;
        }
    })

}
