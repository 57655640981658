import Qs from 'qs';
import client from 'utils/api-client';

export const getContents = (filter = {}) => {
    return client.get('/contents', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
}

export const createContent = (data) => {
    return client.post('/contents', data);
}

export const getContentDetails = (id) => {
    return client.get(`/contents/${id}`);
}

export const updateContent = (id, data) => {
    return client.put(`/contents/${id}`, data);
}

export const removeContent = (id) => {
    return client.delete(`/contents/${id}`)
}
