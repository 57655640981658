import client from 'utils/public-client';
import Qs from 'qs';

export const getUrlProductDetails = (filter = null) => {
  return client.get('/products/find-by-url', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getStateCityFindByCode = (filter = {}) => {
  return client.get(`/system/address/find-by-code`, {
      params: {...filter},
      paramsSerializer: function (params) {
          return Qs.stringify(params, {arrayFormat: 'brackets'})
      },
  })
}
