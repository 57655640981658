import * as types from 'app/actionTypes/productReceived';
import produce from "immer";

const initialState = {
  items: {
    data: null,
    filters: null,
    issue_types: null,
    meta: null,
    tracking_items: null
  },
  loading: false,
  error: null,
}

export default function productReceived(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_PRODUCT_RECEIVED_ITEM_REQUESTED:
        draft.loading = true;
        break
      case types.GET_PRODUCT_RECEIVED_ITEM_SUCCESS:
        draft.items = action.items
        draft.loading = false
        break
      case types.GET_PRODUCT_RECEIVED_ITEM_FAILED:
        draft.error = action.message
        draft.loading = false
        break
      case types.UPDATE_PRODUCT_RECEIVED:
        const index = draft.items.data.findIndex(itm => itm.id === action.payload.id)
        if (index !== -1) draft.items.data[index] = { ...draft.items.data[index], ...action.payload.data }
        break
      default:
        return state
    }
  })
}
