export const GET_SHOPS_REQUESTED      = 'GET_SHOPS_REQUESTED';
export const GET_SHOPS_SUCCESS        = 'GET_SHOPS_SUCCESS';
export const GET_SHOPS_FAILED         = 'GET_SHOPS_FAILED';
export const GET_ALL_SHOPS_REQUESTED  = 'GET_ALL_SHOPS_REQUESTED';
export const GET_ALL_SHOPS_SUCCESS    = 'GET_ALL_SHOPS_SUCCESS';
export const GET_ALL_SHOPS_FAILED     = 'GET__ALLSHOPS_FAILED';
export const UPDATE_SHOPS_ITEM        = 'UPDATE_SHOPS_ITEM';

export const GET_CURRENCY_REQUESTED      = 'GET_CURRENCY_REQUESTED';
export const GET_CURRENCY_SUCCESS        = 'GET_CURRENCY_SUCCESS';
export const GET_CURRENCY_FAILED         = 'GET_CURRENCY_FAILED';
export const UPDATE_CURRENCY_ITEM        = 'UPDATE_CURRENCY_ITEM';
