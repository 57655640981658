import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/importOrders';
import {getImportOrder, getImportOrderDetails} from "services/live-cart";

const getImportOrderApi = async (filter) => {
  return getImportOrder(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getImportOrderDetailsApi = async (id) => {
  return getImportOrderDetails(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchImportOrder(action) {
  try {
    const data = yield call(() => getImportOrderApi(action.payload));
    yield put({ type: types.GET_IMPORT_ORDER_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GET_IMPORT_ORDER_FAILED, message: e?.response });
  }
}

function* fetchImportOrderDetails(action) {
  try {
    const data = yield call(() => getImportOrderDetailsApi(action.payload));
    yield put({ type: types.GET_IMPORT_ORDER_DETAILS_SUCCESS, items: data });
  } catch (e) {
    yield put({ type: types.GTE_IMPORT_ORDER_DETAILS_FAILED, message: e?.response });
  }
}

function* importOrdersSaga() {
  yield all([
    takeEvery(types.GET_IMPORT_ORDER_REQUESTED, fetchImportOrder),
    takeEvery(types.GET_IMPORT_ORDER_DETAILS_REQUESTED, fetchImportOrderDetails),
  ]);
}

export default importOrdersSaga;
