import client from 'utils/api-client';
import Qs from 'qs';

const getCustomers = (filter = null) => {
  return client.get('/customers', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

const getCustomerDetails = (customer_id) => {
  return client.get(`/customers/${customer_id}`);
};

const createCustomer = (data) => {
  return client.post(`/customers`, data);
};

const updateCustomerInfo = (customer_id, data) => {
  return client.put(`/customers/${customer_id}`, data);
};

const deleteCustomer = (customer_id) => {
  return client.delete(`/customers/${customer_id}`);
};

const getAccessToken = (customer_id) => {
  return client.get(`/customers/${customer_id}/access-token`);
}

const getCountry = () => {
  return client.get(`/countries`);
};

const getCurrency = () => {
  return client.get(`/currencies`);
};

const changePassword = (customer_id, data) => {
  return client.post(`/customers/${customer_id}/reset-password`, data);
};

const addCustomerAddress = (customer_id, data) => {
  return  client.post(`/customers/${customer_id}/addresses`, data);
}

const customerAdrsListFetcher = (filter={}) => {
  return client.get('/addresses', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
}

export const sendOTP = (data) => client.post(`/customers/send-otp`, data)

export {
  getCustomers,
  getCustomerDetails,
  createCustomer,
  updateCustomerInfo,
  deleteCustomer,
  getAccessToken,
  getCountry,
  getCurrency,
  changePassword,
  addCustomerAddress,
  customerAdrsListFetcher,
};
