import { call, put, takeEvery } from 'redux-saga/effects'
import { getCustomerDetails } from 'services/customer-client';
import * as types from 'app/actionTypes/customerDetails';

const getApi = async (id) => {
    return getCustomerDetails(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchCustomerDetails(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_CUSTOMER_DETAILS_SUCCESS, data: data.data });
    } catch (e) {
        yield put({ type: types.GET_CUSTOMER_DETAILS_FAILED, message: e?.response });
    }
}

function* orderSaga() {
    yield takeEvery(types.GET_CUSTOMER_DETAILS_REQUESTED, fetchCustomerDetails);
}

export default orderSaga;
