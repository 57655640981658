import {all, call, put, takeEvery} from "redux-saga/effects"
import {getAllParcels, getAllParcelStatus, getParcelsInfo} from "services/parcels-client";
import * as type from '../actionTypes/parcels';

const getApi = async (filters = {}) => {
    return getAllParcels(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getParcelStatus = async () => {
  return getAllParcelStatus()
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getParcelsInfoDetails = async (id) => {
  return getParcelsInfo(id)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchParcelDetails(action) {
  try {
    const data = yield call(() => getParcelsInfoDetails(action.payload));
    yield put({ type: type.GET_PARCELS_DETAILS_SUCCESS, parcelDetails: data.data });
  } catch (e) {
    yield put({ type: type.GET_PARCELS_DETAILS_FAIL, message: e?.response });
  }
}

function* fetchParcels(action) {
    try {
        const parcels = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_PARCELS_SUCCESS, parcels: parcels });
    } catch (e) {
        yield put({ type: type.GET_PARCELS_FAIL, message: e?.response });
    }
}

function* fetchParcelsStatus() {
  try {
    const parcelsStatus = yield call(() => getParcelStatus());
    yield put({ type: type.GET_PARCELS_STATUS_SUCCESS, parcelsStatus: parcelsStatus });
  } catch (e) {
    yield put({ type: type.GET_PARCELS_STATUS_FAIL, message: e?.response });
  }
}

function* parcelsSaga() {
  yield all([
    takeEvery(type.GET_PARCELS_REQUESTED, fetchParcels),
    takeEvery(type.GET_PARCELS_STATUS_REQUESTED, fetchParcelsStatus),
    takeEvery(type.GET_PARCELS_DETAILS_REQUESTED, fetchParcelDetails),
  ]);
}

export default parcelsSaga;
