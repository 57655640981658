import client from 'utils/api-client';
import Qs from 'qs';

export const getConfigurations = (filter = null) => {
  return client.get('/configurations', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const createConfiguration = (data) => {
  return client.post(`/configurations`, data);
};

export const updateConfiguration = (id, data) => {
  return client.put(`/configurations/${id}`, data);
};

export const deleteConfiguration = (id) => {
  return client.delete(`/configurations/${id}`);
};

export const batchUpdate = (data) => client.post(`/configurations/batch-update`, data);
