import client from 'utils/api-client';
import Qs from 'qs';

export const getResourceSearch = (filter = {}) => {
  return client.get('/search-for', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};
