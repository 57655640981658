import client from 'utils/api-client';
import Qs from "qs";

export const getPermissionsWithRoles = () => {
    return client.get('/permissions/with-roles');
}

export const attachPermission = (data) => {
    return client.post('/roles/update-permissions', data);
}

export const addNewRoles = (data) => {
  return client.post('/roles', data);
}

export const editRoles = (id, data) => {
  return client.put(`/roles/${id}`, data);
}

export const getAllRoles = (filter = {}) => {
  return client.get('/roles', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const deleteRoles = (id) => {
  return client.delete(`/roles/${id}`);
}
