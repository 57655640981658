export const GET_ORDER_REQUESTED  = 'GET_ORDER_REQUESTED';
export const GET_ORDER_SUCCESS    = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL       = 'GET_ORDER_FAIL';

export const GET_CHARGES_REQUESTED  = 'GET_CHARGES_REQUESTED';
export const GET_CHARGES_SUCCESS    = 'GET_CHARGES_SUCCESS';
export const GET_CHARGES_FAIL       = 'GET_CHARGES_FAIL';

export const GET_SHIPPING_DETAILS_REQUESTED  = 'GET_SHIPPING_DETAILS_REQUESTED';
export const GET_SHIPPING_DETAILS_SUCCESS    = 'GET_SHIPPING_DETAILS_SUCCESS';
export const GET_SHIPPING_DETAILS_FAIL       = 'GET_SHIPPING_DETAILS_FAIL';

export const GET_MULTI_TRACKING_REQUESTED  = 'GET_MULTI_TRACKING_REQUESTED';
export const GET_MULTI_TRACKING_SUCCESS    = 'GET_MULTI_TRACKING_SUCCESS';
export const GET_MULTI_TRACKING_FAIL       = 'GET_MULTI_TRACKING_FAIL';

export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
export const SET_ORDER_ITEM = 'SET_ORDER_ITEM';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';
export const RESET_MANUAL_ORDER = 'RESET_MANUAL_ORDER';
export const RESET_MANUAL_ORDER_ITEMS = 'RESET_MANUAL_ORDER_ITEMS';
