export const GET_ROLES_REQUESTED = 'GET_ROLES_REQUESTED';
export const GET_ROLES_SUCCEEDED = 'GET_ROLES_SUCCEEDED';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const GET_PERMINSSIONS_REQUESTED = 'GET_PERMINSSIONS_REQUESTED';
export const GET_PERMINSSIONS_SUCCEEDED = 'GET_PERMINSSIONS_SUCCEEDED';
export const GET_PERMINSSIONS_FAILED = 'GET_PERMINSSIONS_FAILED';

export const ATTACH_ROLE_PERMISSIONS = 'ATTACH_ROLE_PERMISSIONS';
export const DETACH_ROLE_PERMISSIONS = 'DETACH_ROLE_PERMISSIONS';

