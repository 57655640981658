import produce from "immer";
import * as Types from 'app/actionTypes/resourceSearch';

const initialState = {
  items: {},
  loading: false,
  error: null
}

export default function requests(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_RESOURCE_SEARCH_REQUESTED:
        draft.loading = true
        break
      case Types.GET_RESOURCE_SEARCH_SUCCEEDED:
        draft.items[action.payload.resource] = action.payload.data.data
        draft.loading = false
        break
      case Types.GET_RESOURCE_SEARCH_FAILED:
        draft.loading = false
        draft.error = action.message
        break
      default:
        return state
    }
  })
}
