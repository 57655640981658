import React, { Suspense, useEffect, useState } from 'react';
import {Layout, Menu, Dropdown, Row, Col, Button, Space, Badge} from 'antd';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
  BellOutlined,
  SettingOutlined,
  RightCircleOutlined
} from '@ant-design/icons';
import { logout } from "utils/auth-client";
import { getLocalData } from "utils/helper";
import { LOCAL_STORAGE_USER } from "configs/storage";
import Sidebar from './Sidebar';
import {useDispatch, useSelector} from 'react-redux';
import { getProfile } from "app/actions/profile";
import {useHistory} from "react-router-dom";
import PageHead from 'components/PageHead';
import siteConfig from "consts/site-configs";

const { Header, Content, Footer } = Layout;

const ProDefaultLayout = (props: any) => {
  const { children } = props
  let history = useHistory()

  const dispatch = useDispatch()

  const [collapsed, setCollapsed] = useState(false);
  const { profile: profileData } = useSelector((state: any) => state.profile);

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const handleAccountMenuClick = ({ key }: any) => {
    if (key === 'logout') {
      logout()
        .finally(() => {
          window.location.reload()
        })
    }
  }

  const userAccountMenu = (
    <Menu>
      <Menu.Item key="profile" icon={<UserOutlined />} onClick={() => history.push(`/profile`)}>
        <span>Profile</span>
      </Menu.Item>
      <Menu.Item key="settings" icon={<SettingOutlined />} onClick={() => history.push(`/profile`)}>
        <span>Settings</span>
      </Menu.Item>
      <Menu.Item key="logout" icon={<RightCircleOutlined />} onClick={handleAccountMenuClick}>
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const profile = getLocalData(LOCAL_STORAGE_USER)

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <PageHead />
      <Sidebar toggleCollapsed={toggleCollapsed} collapsed={collapsed}  />
      <Layout className={collapsed ? "layout-on-slider-collapse site-layout" : "layout-on-slider-expand site-layout"}>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between">
            <Col span={4} style={{ paddingLeft: '1%' }}>
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggleCollapsed,
              })}
            </Col>
            <Col span={4}>
              <Row justify="end" style={{ marginRight: '24px' }}>
                <Dropdown overlay={userAccountMenu}>
                  <Space align="center">
                    <Badge count={2} size="small">
                      <Button icon={<BellOutlined />} size="small"/>
                    </Badge>
                    <Button  icon={<UserOutlined />} size="small">{profile?.first_name ? profile?.first_name : profileData?.first_name} <DownOutlined /></Button>
                  </Space>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: 24 }}>
          <Suspense fallback={<div>Loading</div>}>
            {children}
          </Suspense>
        </Content>
        <Footer style={{ textAlign: 'center' }}>{siteConfig.brandName ? siteConfig.brandName : "Ali2BD"}</Footer>
      </Layout>
    </Layout>
  );
};

export default ProDefaultLayout
