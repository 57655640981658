import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getRequests } from 'services/requests';
import * as type from 'app/actionTypes/requests';

const getApi = async (filter) => {
    return getRequests(filter)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchRequests(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: type.GET_REQUESTS_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: type.GET_REQUESTS_FAILED, message: e?.response });
    }
}

function* requestsSaga() {
    yield all([
        takeEvery(type.GET_REQUESTS_REQUESTED, fetchRequests),
    ]);
}

export default requestsSaga;
