import * as types from 'app/actionTypes/customerDetails';

const initialState = {
    data: null,
    loading: false,
    error: null
}

export default function customerDetails(state = initialState, action) {
    switch (action.type) {
        case types.GET_CUSTOMER_DETAILS_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case types.GET_CUSTOMER_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false
            }
        case types.GET_CUSTOMER_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message
            }
        default:
            return state
    }
}
