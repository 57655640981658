import { call, put, takeEvery, all } from 'redux-saga/effects'
import * as types from 'app/actionTypes/liveCart';
import {getLiveCart} from "services/live-cart";

const getApi = async (id) => {
    return getLiveCart(id)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchLiveCart(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_LIVE_CART_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_LIVE_CART_FAILED, message: e?.response });
    }
}

function* liveCartSaga() {
    yield all([
        takeEvery(types.GET_LIVE_CART_REQUESTED, fetchLiveCart),
    ]);
}

export default liveCartSaga;
