import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import menuItems from 'configs/menu-items'
import { SUPER_ADMIN } from 'configs/role'
import { useSelector } from 'react-redux';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props: any) => {
  const { permissions, role } = useSelector((state: any) => state.profile)
  const { collapsed, toggleCollapsed } = props
  let history = useHistory();
  const { id }: any = useParams();

  let isMatch = false

  if (history.location.pathname === `/orders/${id}/invoice`) {
    isMatch = true
  } else if (history.location.pathname === `/parcels/${id}/deed`) {
    isMatch = true
  }

  const isSuperAdmin = role ? role.key === SUPER_ADMIN : false

  let sideBarMenu = null

  if (permissions) {
    sideBarMenu = menuItems
    if (!isSuperAdmin) {
      sideBarMenu = sideBarMenu.map((top: any) => {
        if (top.subMenus) {
          top.subMenus = top.subMenus.filter((sub: any) => !sub.requirePermission || (permissions && permissions.includes(sub.permission)))
        }
        return top
      }).filter((item: any) => item.subMenus && item.subMenus.length > 0)
    }
  }

  return (
    <Sider
      trigger={null}
      breakpoint="lg"
      collapsedWidth="0"
      collapsed={isMatch ? isMatch : collapsed}
      onCollapse={toggleCollapsed}
      collapsible
      width={250}
      className="slider-menu"
    >
      <div className="logo">
        Ali2BD
      </div>

      <Menu theme="dark" mode="inline">
        {
          sideBarMenu && sideBarMenu.map((menuItem, idx) => {
            if (menuItem.hasSubMenu) {
              return (
                <SubMenu key={`submenu-${idx}`} icon={menuItem.icon} title={menuItem.title} >
                  {
                    menuItem.subMenus && menuItem.subMenus.map((subMenu: any, subIdx: number) => {
                      return <Menu.Item key={`sub-${idx}-${subIdx}`}>
                        <Link to={subMenu.path} >{subMenu.title}</Link>
                      </Menu.Item>
                    })
                  }
                </SubMenu>

              )
            } else {
              return (<Menu.Item key={`menu-${idx}`} icon={menuItem.icon}>
                {menuItem.title}
              </Menu.Item>
              )
            }

          })
        }
      </Menu>
    </Sider>
  )
}

export default Sidebar
