import { call, put, takeEvery, all } from 'redux-saga/effects'
import { getMenus, getMenu } from 'services/menu-client';
import * as types from 'app/actionTypes/menus';

const getApi = async (filters = {}) => {
    return getMenus(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetcMenus(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_MENU_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_MENU_FAILED, message: e?.response });
    }
}

const getMenuDetailsApi = (async (menuId) => {
    return getMenu(menuId)
    .then(res => res.data.data)
    .catch((error) => { throw error })
})

function* fetchMenuDetails(action) {
    try {
        const data = yield call(() => getMenuDetailsApi(action.payload.id));
        yield put({ type: types.GET_MENU_DETAILS_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: types.GET_MENU_DETAILS_FAILED, message: e?.response });
    }
}

function* menusSaga() {
    yield all([
        takeEvery(types.GET_MENU_REQUESTED, fetcMenus),
        takeEvery(types.GET_MENU_DETAILS_REQUESTED, fetchMenuDetails),
    ]);
}

export default menusSaga;
