import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CustomProductEditProvider } from './useCustomProductEdit';
import CreateProduct from './CreateProduct';
import { updateProductService, fetchProduct as fetchProductService } from 'services/products';
import { toastErrors } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getShops } from 'app/actions/shops';

let api_url = process.env.REACT_APP_API_URL

if (process.env.NODE_ENV === 'production') {
    api_url = process.env.REACT_APP_API_URL
}

const localStorageKey = '__ali2bd_admin_token__';

const EditCustomProduct = (props) => {
  let { id: productID } = useParams();
  let isEdit = !!productID;
  const dispatch = useDispatch();
  const stores = useSelector(state => state.shops?.items?.data);

  useEffect(() => {
    dispatch(getShops({per_page: 100}))
  }, [dispatch])


  return (
    <CustomProductEditProvider
      fetchProductForEdit={fetchProductService}
      updateProductService={updateProductService}
      toastErrors={toastErrors}
      storeList={stores}
      productID={productID}
      isEdit={isEdit}
      imageUploadActionURL={api_url}
      imageUploadActionToken={localStorageKey}
    >
      <CreateProduct />
    </CustomProductEditProvider>
  );
};

export default EditCustomProduct;
